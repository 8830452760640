import selectComponent from "components/select-component"
import {Survey} from "models"
const {withAdminSelectContainer: AdminSelectSurveys, withInputContainer: SurveysSelectWithContainer, withMemo: ComponentsSurveysSelect} = selectComponent({
  className: "components--surveys--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Survey

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Survey: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const surveys = await query.toArray()

    return surveys.map((survey) => ({
      text: survey.name(),
      value: survey.id()
    }))
  }
})

export default ComponentsSurveysSelect
export {AdminSelectSurveys, SurveysSelectWithContainer}
