import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent, Text} from "shared/base"
import {Pressable, StyleSheet} from "react-native"
import React, {useMemo} from "react"

const styles = StyleSheet.create({
  button: {
    paddingVertical: 30,
    paddingHorizontal: 96,
    backgroundColor: "#6c757d",
    borderRadius: 40
  },
  buttonPrimary: {
    backgroundColor: "#5253ff"
  },
  text: {
    color: "#000",
    fontWeight: "bold",
    textAlign: "center"
  },
  textPrimary: {
    color: "#fff"
  }
})

export default memo(shapeComponent(class ChalkyLayoutButton extends ShapeComponent {
  static propTypes = propTypesExact({
    onPress: PropTypes.func.isRequired,
    primary: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  })

  render() {
    const {primary, title} = this.p
    const {buttonStyle, textStyle} = useMemo(() => {
      const buttonStyles = [styles.button]
      const textStyles = [styles.text]

      if (primary) {
        buttonStyles.push(styles.buttonPrimary)
        textStyles.push(styles.textPrimary)
      }

      return {
        buttonStyle: buttonStyles,
        textStyle: textStyles
      }
    }, [primary])

    return (
      <Pressable style={buttonStyle}>
        <Text style={textStyle}>
          {title}
        </Text>
      </Pressable>
    )
  }
}))
