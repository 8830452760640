import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {RolesSelectWithContainer} from "components/roles/select"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {UserRole} from "models"
import {UsersSelectWithContainer} from "components/users/select"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserRolesEdit extends ShapeComponent {
  static propTypes = {
    userRole: PropTypes.instanceOf(UserRole),
    userRoleId: PropTypes.string
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.user_roles.edit"})
    const params = useParams()
    const {userRole, userRoleId} = useModel(UserRole, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        UserRole: ["id", "roleId", "userId"]
      }
    })

    this.setInstance({t, userRole, userRoleId})
    useAdminLayout()?.setState({active: "users", headerTitle: this.headerTitle()})
  }

  render() {
    const {onSubmit, userRole} = this.tt

    return (
      <View dataSet={{route: "admin--user-roles--edit"}}>
        {userRole &&
          <form onSubmit={onSubmit}>
            <UsersSelectWithContainer attribute="userId" label={UserRole.humanAttributeName("user")} model={userRole} />
            <RolesSelectWithContainer attribute="roleId" label={UserRole.humanAttributeName("role")} model={userRole} />
            <Button primary save />
          </form>
        }
      </View>
    )
  }

  headerTitle() {
    const {t, userRole} = this.tt

    if (userRole?.isNewRecord()) {
      return t(".add_new_user_role")
    } else if (userRole?.isPersisted()) {
      return t(".edit_user_role")
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {userRole} = this.tt

    if (userRole.isNewRecord()) {
      formData.append("user_role[id]", userRole.id())
    }

    try {
      await userRole.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_user_role_was_saved"))
      AppHistory.push(Routes.adminUserUserRolesPath(userRole.userId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
