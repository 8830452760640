import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import {View} from "react-native"
export default memo(shapeComponent(class ComponentsUtilsCode extends ShapeComponent {
  static propTypes = propTypesExact({
    children: PropTypes.node,
    textProps: PropTypes.object,
    viewProps: PropTypes.object
  })

  render() {
    const {textProps = {}, viewProps = {}} = this.props
    const {style: viewStyle, ...restViewProps} = viewProps
    const actualViewStyle = Object.assign(
      {
        padding: 20,
        backgroundColor: "#dcdcdc",
        borderRadius: 7
      },
      viewStyle
    )
    const {style: textStyle, ...restTextProps} = textProps
    const actualTextStyle = Object.assign({fontFamily: "monospace"}, textStyle)

    return (
      <View style={actualViewStyle} {...restViewProps}>
        <Text style={actualTextStyle} {...restTextProps}>
          {this.props.children}
        </Text>
      </View>
    )
  }
}))
