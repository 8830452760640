import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import InfoIcon from "components/user/unicorn/info-icon"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserUnicornProfileBox extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.components.user.unicorn.profile_box"})
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("components--user--unicorn--profile-box", className)} {...restProps}>
        <div style={{display: "flex"}}>
          <InfoIcon className="info-icon" style={{marginLeft: "auto"}}>
            Profile box
          </InfoIcon>
        </div>
        <div className="profile-box-header">
          {t(".profile")}
        </div>
        <div className="profile-box-score-factor" style={{marginBottom: "76px"}}>
          {t(".independent")}
        </div>
        <div className="profile-box-score-factor">
          {t(".active")}
        </div>
      </div>
    )
  }
}))
