import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import ScoreFactorLink from "components/score-factors/link"
import SurveyStepElementOptionLink from "components/survey-step-element-options/link"
import {SurveyStepElementOptionScore} from "models"
export default memo(shapeComponent(class ComponentsSurveyStepElementOptionScoresTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--survey-step-element-option-scores--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={SurveyStepElementOptionScore}
        preloads={["option", "score_factor"]}
        select={{
          ScoreFactor: ["id", "name"],
          SurveyStepElementOption: ["id", "name"],
          SurveyStepElementOptionScore: ["id", "score"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.scoreFactorContent,
      label: SurveyStepElementOptionScore.humanAttributeName("scoreFactor"),
      sortKey: "scoreFactorCurrentTranslationName"
    },
    {
      content: this.tt.optionContent,
      label: SurveyStepElementOptionScore.humanAttributeName("option"),
      sortKey: "optionCurrentTranslationName"
    },
    {
      attribute: "score",
      sortKey: "score"
    }
  ]

  editModelPath = ({surveyStepElementOptionScore}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSurveyStepElementOptionScorePath(surveyStepElementOptionScore.id())
  }

  viewModelPath = ({surveyStepElementOptionScore}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSurveyStepElementOptionScorePath(surveyStepElementOptionScore.id())
  }

  optionContent = ({surveyStepElementOptionScore}) => surveyStepElementOptionScore.option() &&
    <SurveyStepElementOptionLink surveyStepElementOption={surveyStepElementOptionScore.option()} {...linkProps(this.props)} />

  scoreFactorContent = ({surveyStepElementOptionScore}) => surveyStepElementOptionScore.scoreFactor() &&
    <ScoreFactorLink scoreFactor={surveyStepElementOptionScore.scoreFactor()} {...linkProps(this.props)} />
}))
