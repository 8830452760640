import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminSchoolClassesNav from "./nav"
import AuditsPage from "components/admin/audits/page"
import memo from "set-state-compare/src/memo"
import React from "react"
import {SchoolClass} from "models"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassesAudits extends ShapeComponent {
  setup() {
    const {schoolClass} = useModel(SchoolClass, {match: this.p.match})

    this.schoolClass = schoolClass

    useAdminLayout()?.setState({active: "school-classes", headerTitle: SchoolClass.humanAttributeName("audits")})
  }

  render() {
    const {schoolClass} = this.tt

    return (
      <View dataSet={{route: "admin--school-classes--audits"}}>
        {schoolClass &&
          <>
            <AdminSchoolClassesNav active="audits" schoolClass={schoolClass} style={{marginBottom: 15}} />
            <AuditsPage resource={schoolClass} />
          </>
        }
      </View>
    )
  }
}))
