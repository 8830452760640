import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import {NotificationType} from "models"
import NotificationTypesTable from "components/notification-types/table"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNotificationTypesIndex extends ShapeComponent {
  setup() {
    const canCan = useCanCan(() => [[NotificationType, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.notification_types.index"})

    this.setInstance({canCan, t})
    useAdminLayout()?.setState({actions: this.actions(), active: "notification-types", headerTitle: NotificationType.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin-notification-types--index"}}>
        <NotificationTypesTable adminLayout />
      </View>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", NotificationType) &&
          <Button
            className="new-notification-type-button"
            icon="plus"
            label={this.t(".add_new_notification_type")}
            to={Routes.newAdminNotificationTypePath()}
          />
        }
      </>
    )
  }
}))
