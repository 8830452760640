import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import React from "react"
import {SchoolClass} from "models"
import {useFrontLayout} from "components/front-layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
export default memo(shapeComponent(class RoutesUserSchoolClassesShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {schoolClass, schoolClassNotFound} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["invite"]
      },
      match: {params},
      select: {
        SchoolClass: ["createdAt", "id", "interpretedName", "name", "schoolId", "updatedAt"]
      }
    })

    this.setInstance({schoolClass, schoolClassNotFound})
    useFrontLayout()?.setState({
      headerTitle: schoolClass?.interpretedName()
    })
  }

  render() {
    const {schoolClass} = this.tt

    return (
      <div className="routes--user--school-classes--show">
        {schoolClass &&
          <AttributeRows attributes={["name", "createdAt", "updatedAt"]} model={schoolClass} />
        }
      </div>
    )
  }
}))
