import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {AccountsSelectWithContainer} from "components/accounts/select"
import {AccountUser} from "models"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import Select from "components/inputs/select"
import setLayout from "shared/set-layout"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {UsersSelectWithContainer} from "components/users/select"
import {v4 as uuidv4} from "uuid"

export default memo(shapeComponent(class RoutesAdminAccountUsersEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {locale, t} = useI18n({namespace: "js.routes.admin.account_users.edit"})
    const {accountUser, accountUserId} = useModel(AccountUser, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        SchoolClass: ["schoolId"],
        AccountUser: ["id", "role", "schoolClassId", "userId"]
      }
    })

    const roleOptions = useMemo(() => TranslatedCollections.get(AccountUser, "role").map(({translation, value}) => ({text: translation, value})), [locale])

    this.setInstance({
      accountUser,
      accountUserId,
      roleOptions,
      t
    })
  }

  render() {
    const {accountUser, roleOptions, onSubmit} = this.tt

    return (
      <AdminLayout active="school-classes" className="routes--admin--account-users--edit" headerTitle={this.headerTitle()}>
        {accountUser &&
          <form onSubmit={onSubmit}>
            <AccountsSelectWithContainer attribute="accountId" label={AccountUser.humanAttributeName("account")} model={accountUser} />
            <UsersSelectWithContainer attribute="userId" label={AccountUser.humanAttributeName("user")} model={accountUser} />
            <Select attribute="role" model={accountUser} options={roleOptions} type="hayaSelect" />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {accountUser} = this.tt

    if (accountUser?.isNewRecord()) {
      return this.t(".add_new_account_user")
    } else if (accountUser?.isPersisted()) {
      return this.t(".edit_account_user")
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {accountUser} = this.tt

    if (accountUser.isNewRecord()) formData.append("account_user[new_id]", accountUser.id())

    try {
      await accountUser.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_account_user_was_saved"))
      AppHistory.push(Routes.adminAccountUserPath(accountUser.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
