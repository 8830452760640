import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {Styling} from "models"
import StylingsTable from "components/stylings/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminStylingsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.stylings.index"})

    this.canCan = useCanCan(() => [[Styling, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="stylings"
        className="routes--admin-stylings--index"
        headerTitle={Styling.modelName().human({count: 2})}
      >
        <StylingsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", Styling) &&
          <Button
            className="new-styling-button"
            icon="plus"
            label={t(".add_new_styling")}
            to={Routes.newAdminStylingPath()}
          />
        }
      </>
    )
  }
}))
