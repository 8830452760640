import {AccountSchool, School} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountSchoolsTable from "components/account-schools/table"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/schools/nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolsAccountSchools extends ShapeComponent {
  setup() {
    this.canCan = useCanCan(() => [[AccountSchool, ["create"]]])

    const params = useParams()
    const {t} = useI18n({namespace: "js.routes.admin.schools.account_schools"})
    const {school, schoolId} = useModel(School, {match: {params}})

    this.setInstance({
      accountSchoolsQuery: useMemo(
        () => AccountSchool.ransack({
          school_id_eq: schoolId
        }),
        [schoolId]
      ),
      school,
      schoolId,
      t
    })

    useAdminLayout()?.setState({actions: this.actions(), active: "schools", headerTitle: School.humanAttributeName("accountSchools")})
  }

  render() {
    const {accountSchoolsQuery, school} = this.tt

    return (
      <View dataSet={{route: "admin--schools--account-schools"}}>
        {school &&
          <Nav active="account-schools" school={school} style={{marginBottom: 15}} />
        }
        <AccountSchoolsTable adminLayout collection={accountSchoolsQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, schoolId, t} = this.tt

    return (
      <>
        {canCan?.can("create", AccountSchool) &&
          <Button
            className="new-account-school-button"
            icon="plus"
            label={t(".new_account_for_school")}
            to={Routes.newAdminAccountSchoolPath({account_school: {school_id: schoolId}})}
          />
        }
      </>
    )
  }
}))
