import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import EmailTemplateLink from "components/email-templates/link"
import I18n from "shared/i18n"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplate} from "models"
import NewsletterTemplateLink from "components/newsletter-templates/link"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNewsletterTemplates extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props
    const {columns, editModelPath, viewModelPath} = this.tt

    return (
      <ApiMakerTable
        className={classNames("components--newsletter-templates--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={NewsletterTemplate}
        preload={["email_template"]}
        select={{
          EmailTemplate: ["id", "name"],
          NewsletterTemplate: ["sendAtWithoutTimeZone", "translatedState"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.newsletterTemplateContent,
      label: NewsletterTemplate.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "state",
      content: this.tt.stateContent,
      sortKey: "state"
    },
    {
      attribute: "sendAt",
      content: this.tt.sendAtContent,
      sortKey: "sendAt"
    },
    {
      attribute: "name",
      content: this.tt.emailTemplateContent,
      label: NewsletterTemplate.humanAttributeName("emailTemplate"),
      path: ["emailTemplate"],
      sortKey: "emailTemplateCurrentTranslationName"
    }
  ]

  editModelPath = ({newsletterTemplate}) => {
    if (this.p.adminLayout) return Routes.editAdminNewsletterTemplatePath(newsletterTemplate.id())
  }

  emailTemplateContent = ({newsletterTemplate}) =>
    newsletterTemplate.emailTemplate() && <EmailTemplateLink adminLayout emailTemplate={newsletterTemplate.emailTemplate()} />

  newsletterTemplateContent = ({newsletterTemplate}) => <NewsletterTemplateLink newsletterTemplate={newsletterTemplate} {...linkProps(this.props)} />

  sendAtContent = ({newsletterTemplate}) => newsletterTemplate.hasSendAtWithoutTimeZone() &&
    I18n.l("time.formats.default", newsletterTemplate.sendAtWithoutTimeZone())

  stateContent = ({newsletterTemplate}) => newsletterTemplate.translatedState()

  viewModelPath = ({newsletterTemplate}) => {
    if (this.p.adminLayout) return Routes.adminNewsletterTemplatePath(newsletterTemplate.id())
  }
}))
