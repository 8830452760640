import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import Services from "@kaspernj/api-maker/build/services"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminRoutesOverviewRoute extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.routes_overview.routes_overview"})

    useAdminLayout()?.setState({active: "routes-overview", headerTitle: t(".routes_overview")})

    this.useStates({
      routes: null
    })

    useMemo(() => {
      this.loadRoutes()
    }, [])
  }

  async loadRoutes() {
    const result = await Services.current().sendRequest("RoutesOverview")

    this.setState({routes: digg(result, "routes")})
  }

  render() {
    return (
      <View dataSet={{route: "admin/routes-overview"}}>
        <UtilsCard>
          {this.s.routes?.map((route) =>
            <Text dataSet={{class: "route-item"}} key={route}>
              {route}
            </Text>
          )}
        </UtilsCard>
      </View>
    )
  }
}))
