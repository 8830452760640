import {Image, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FlashMessage from "shared/flash-message"
import HelloDog from "./hello-dog.png"
import InputValidator from "shared/input-validator"
import memo from "set-state-compare/src/memo"
import Overlay from "components/layout/overlay"
import PageContentsPresentation from "components/page-contents/presentation"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {SchoolContactRequest} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"

export default memo(shapeComponent(class ComponentsUnicornUpgradeOverlayContactMySchool extends ShapeComponent {
  static propTypes = propTypesExact({
    onRequestClose: PropTypes.func.isRequired,
    onCloseWindowPressed: PropTypes.func.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.unicorn.upgrade_overlay.contact_my_school"})

    this.setInstance({t})
    this.useStates({
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
      mode: "form"
    })
  }

  render() {
    const {onRequestClose} = this.props
    const {mode} = this.s

    return (
      <Overlay color="light" onRequestClose={onRequestClose}>
        <Image source={HelloDog} style={{position: "absolute", top: -77, left: -121, width: 395, height: 494}} />
        {mode == "form" && this.formContent()}
        {mode == "success" && this.successContent()}
      </Overlay>
    )
  }

  formContent() {
    const {t} = this.tt

    return (
      <View
        dataSet={{component: "unicorn--upgrade-overlay--contact-my-school"}}
        style={{width: 600, marginLeft: "auto", marginRight: "auto", textAlign: "center"}}
      >
        <PageContentsPresentation identifier="unicorn/upgrade-overlay/contact-my-school/form-content" />
        <View style={{width: 350, marginLeft: "auto", marginRight: "auto"}}>
          <UtilsInput
            dataSet={{class: "name-input"}}
            defaultValue={this.s.name}
            label={t(".name_of_your_school_leader")}
            stateComponent={this}
            stateName="name"
            style={{marginBottom: 10}}
          />
          <UtilsInput
            dataSet={{class: "phone-number-input"}}
            defaultValue={this.s.phoneNumber}
            label={t(".phone_number_of_your_school_leader")}
            stateComponent={this}
            stateName="phoneNumber"
            style={{marginBottom: 10}}
          />
          <UtilsInput
            dataSet={{class: "email-input"}}
            defaultValue={this.s.email}
            label={t(".email_of_your_school_leader")}
            stateComponent={this}
            stateName="email"
            style={{marginBottom: 10}}
          />
          <UtilsInput
            autoRows
            dataSet={{class: "message-input"}}
            defaultValue={this.s.message}
            label={t(".optional_message_to_your_school_leader")}
            minimumRows={4}
            multiline={true}
            stateComponent={this}
            stateName="message"
            style={{marginBottom: 10}}
          />
          <UtilsButton color="green" dataSet={{class: "continue-button"}} onPress={this.tt.onContinuePressed} title={t(".continue")} />
        </View>
      </View>
    )
  }

  onCloseWindowPressed = () => this.p.onCloseWindowPressed()

  onContinuePressed = async () => {
    try {
      const {t} = this.tt
      const {name} = this.s
      const inputValidator = new InputValidator({
        inputs: {name},
        translations: {
          name: t(".name_of_your_school_leader")
        }
      })

      inputValidator.validate("name", {presence: true})

      if (inputValidator.isInvalid()) {
        FlashMessage.alert(inputValidator.getErrorMessages().join(" "))
      } else {
        await SchoolContactRequest.contactMySchool({
          school_contact_request: {
            name: this.s.name,
            email: this.s.email,
            phone_number: this.s.phoneNumber,
            message: this.s.message
          }
        })
        FlashMessage.success(t(".thank_you_we_will_contact_your_school_leader"))
        this.setState({mode: "success"})
      }
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  successContent() {
    const {t} = this.tt

    return (
      <>
        <PageContentsPresentation identifier="unicorn/upgrade-overlay/contact-my-school/success-content" />
        <UtilsButton color="green" onPress={this.tt.onCloseWindowPressed} title={t(".close_window")} />
      </>
    )
  }
}))
