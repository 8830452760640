import {ClassStatusExpansion, SchoolClass} from "models"
import {dig, digg} from "diggerize"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ClassStatusRow from "components/user/class-status/row"
import DashboardWeekBar from "components/user/dashboard/week-bar"
import DateHeader from "components/user/dashboard/date-header"
import I18n from "shared/i18n"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import Routes from "shared/routes"
import {sortedByFunction} from "sorted-by"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import uniqunize from "uniqunize"
import useAnythingUnicorn from "components/subscriptions/use-anything-unicorn"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"
import WelcomeMessage from "components/user/dashboard/welcome-message"

export default memo(shapeComponent(class RoutesUserClassStatus extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.class_status"})
    const currentUser = useCurrentUser()
    const {anythingUnicorn} = useAnythingUnicorn()
    const queryParams = useQueryParams()
    const dateFrom = useMemo(() => {
      if (queryParams.date_from) {
        return moment(queryParams.date_from).toDate()
      } else {
        return moment().isoWeekday(1).startOf("day").toDate()
      }
    }, [queryParams.date_from])
    const dateTo = useMemo(() => {
      if (queryParams.date_to) {
        return moment(queryParams.date_to).toDate()
      } else {
        return moment().isoWeekday(1).endOf("isoWeek").toDate()
      }
    }, [queryParams.date_to])

    this.setInstance({
      anythingUnicorn,
      currentUser,
      dateFrom,
      dateTo,
      t
    })
    this.useStates({
      classStatusData: undefined,
      expanded: null,
      schoolClasses: undefined,
      schoolClassesForClassSteps: undefined,
      schoolClassIds: undefined,
      schoolClassSteps: undefined,
      schoolIds: undefined,
      schoolsData: undefined,
      schools: undefined
    })
    const {schoolClassIds, schoolIds} = this.s

    useMemo(
      () => {
        if (currentUser) {
          this.loadExpanded()
          this.loadSchoolClasses()
        } else {
          this.setState({schoolClasses: undefined})
        }
      },
      [currentUser?.id()]
    )

    useMemo(
      () => {
        if (anythingUnicorn && currentUser && dateFrom && dateTo && schoolClassIds && schoolIds) {
          this.tt.countDataForSchoolClasses()
        } else {
          this.setState({classStatusData: undefined, schoolsData: undefined})
        }
      },
      [anythingUnicorn, currentUser?.id(), dateFrom, dateTo, schoolClassIds?.join("-"), schoolIds?.join("-")]
    )

    useFrontLayout()?.setState({active: "user-class-status", className: null, headerTitle: t(".class_status")})
  }

  loadSchoolClasses = async () => {
    await loading(this.t(".loading_schools_and_classes"), async () => {
      const schoolClasses = await SchoolClass
        .ransack({
          s: "current_translation_name",
          with_contact_id: this.tt.currentUser.contactId()
        })
        .preload(["class_step", "school"])
        .select({
          ClassStep: ["id", "name", "primarySchoolStep"],
          School: ["hasUnicornSubscription", "id", "name"],
          SchoolClass: ["classStepId", "hasUnicornSubscription", "id", "interpretedName", "schoolId"]
        })
        .groupBy("id")
        .toArray()

      const classSteps = {}
      const schoolsFromClasses = schoolClasses.map((schoolClass) => schoolClass.school()).filter((school) => Boolean(school))
      const uniqueSchools = uniqunize(schoolsFromClasses, (school) => school.id())
      const schools = sortedByFunction(uniqueSchools, "name")
      const schoolIds = schools.map((school) => school.id())
      const schoolClassesForClassSteps = {}
      const schoolClassIds = schoolClasses?.map((schoolClass) => schoolClass.id())
      const schoolClassSteps = {}

      for (const schoolClass of schoolClasses) {
        const schoolClassId = schoolClass.id()
        const classStep = schoolClass.classStep()
        const classStepId = schoolClass.classStepId() || "no-class-step"
        const schoolId = schoolClass.schoolId()

        if (!(schoolClass.schoolId() in schoolClassesForClassSteps)) schoolClassesForClassSteps[schoolId] = {}
        if (!(classStepId in schoolClassesForClassSteps[schoolId])) schoolClassesForClassSteps[schoolId][classStepId] = {}
        if (!(schoolClassId in schoolClassesForClassSteps[schoolId][classStepId])) {
          schoolClassesForClassSteps[schoolId][classStepId][schoolClassId] = schoolClass
        }

        if (!(schoolId in schoolClassSteps)) schoolClassSteps[schoolId] = {}
        if (!(classStep in schoolClassSteps[schoolId])) schoolClassSteps[schoolId][classStepId] = classStep
        if (!(classStepId in classSteps)) classSteps[classStepId] = classStep
      }

      this.setState({schoolClasses, schoolClassesForClassSteps, schoolClassIds, schoolClassSteps, schoolIds, schools})
    })
  }

  countDataForSchoolClasses = async () => {
    await loading(this.t(".class_status_data"), async () => {
      const result = await SchoolClass.classStatusData({
        date_from: this.tt.dateFrom,
        date_to: this.tt.dateTo,
        school_class_ids: this.s.schoolClassIds,
        school_ids: this.s.schoolIds
      })

      const classStatusData = digg(result, "class_status_data")
      const schoolsData = digg(result, "schools_data")

      this.setState({classStatusData, schoolsData})
    })
  }

  render() {
    const {anythingUnicorn, dateFrom, dateTo, t} = this.tt
    const {classStatusData, expanded, schools, schoolsData} = this.s
    const startDate = useMemo(() => moment().subtract(1, "months").toDate(), [])
    const endDate = useMemo(() => moment().toDate(), [])

    return (
      <View dataSet={{route: "user/class-status"}}>
        {anythingUnicorn === false &&
          <UnicornUpgradeOverlay />
        }
        <View
          dataSet={{class: "greetings-container"}}
          style={{
            marginBottom: 30
          }}
        >
          <DateHeader
            style={{
              fontSize: 32,
              fontWeight: "bold",
              textAlign: "center"
            }}
          />
          <WelcomeMessage
            style={{
              fontSize: 32,
              fontWeight: "bold",
              textAlign: "center"
            }}
          />
        </View>
        <DashboardWeekBar dateFrom={dateFrom} dateTo={dateTo} weekNavigation />
        <div className="schools-container">
          <table cellPadding="0" style={{width: "100%", borderCollapse: "separate", borderSpacing: "0 10px"}}>
            <tbody>
              {classStatusData && expanded !== null && schoolsData && schools?.map((school) =>
                <ClassStatusRow
                  brainBreaksCount={digg(this.averageMoodForSchool(school), "brainBreaksCount")}
                  cardDesign={false}
                  challengesCount={digg(this.averageMoodForSchool(school), "challengesCount")}
                  className="school-container"
                  data-school-id={school.id()}
                  expanded={`school-${school.id()}` in expanded ? expanded[`school-${school.id()}`] : true}
                  hasUnicorn={school.hasUnicornSubscription()}
                  identifier={`school-${school.id()}`}
                  key={school.id()}
                  label={school.name()}
                  model={school}
                  modelClass="School"
                  modelId={school.id()}
                  mood={digg(this.averageMoodForSchool(school), "moodAverage")}
                  moodLabel={t(".latest_mood")}
                  onCollapse={this.tt.onCollapse}
                  onExpand={this.tt.onExpand}
                  unicornEffectsCount={digg(this.averageMoodForSchool(school), "unicornEffectsCount")}
                  unicornPath={
                    Routes.userUnicornPath({
                      date_from: I18n.strftime("%Y-%m-%d", startDate),
                      date_to: I18n.strftime("%Y-%m-%d", endDate),
                      schools: [school.id()]
                    })
                  }
                  woofs={digg(this.averageMoodForSchool(school), "woofsCount")}
                >
                  {this.classStepsForSchool(school).map((classStep) =>
                    <ClassStatusRow
                      brainBreaksCount={digg(this.averageMoodForSchoolAndClassStep(school, classStep), "brainBreaksCount")}
                      challengesCount={digg(this.averageMoodForSchoolAndClassStep(school, classStep), "challengesCount")}
                      className="class-step-container"
                      data-class-step-id={classStep?.id() || "no-class-step"}
                      expanded={expanded[`school-${school.id()}-class-step-${classStep?.id() || "no-class-step"}`]}
                      hasUnicorn={school.hasUnicornSubscription()}
                      identifier={`school-${school.id()}-class-step-${classStep?.id() || "no-class-step"}`}
                      indentationLevel={2}
                      key={classStep?.id() || "no-class-step"}
                      label={classStep?.name() || t(".without_class_step")}
                      model={classStep}
                      modelClass="ClassStep"
                      modelId={classStep?.id() || "no-class-step"}
                      mood={this.averageMoodForSchoolAndClassStep(school, classStep).moodAverage}
                      moodLabel={t(".latest_mood")}
                      onCollapse={this.tt.onCollapse}
                      onExpand={this.tt.onExpand}
                      unicornEffectsCount={digg(this.averageMoodForSchoolAndClassStep(school, classStep), "unicornEffectsCount")}
                      unicornPath={
                        classStep ? Routes.userUnicornPath({
                          date_from: I18n.strftime("%Y-%m-%d", startDate),
                          date_to: I18n.strftime("%Y-%m-%d", endDate),
                          class_steps: [classStep.id()],
                          schools: [school.id()]
                        }) : null
                      }
                      woofs={digg(this.averageMoodForSchoolAndClassStep(school, classStep), "woofsCount")}
                    >
                      {this.schoolClassForSchoolAndClassStep(school, classStep?.id() || "no-class-step")?.map((schoolClass) =>
                        <ClassStatusRow
                          brainBreaksCount={digg(classStatusData, schoolClass.id(), "brain_breaks_this_week")}
                          challengesCount={digg(classStatusData, schoolClass.id(), "challenges_this_week")}
                          className="school-class-container"
                          data-school-class-id={schoolClass.id()}
                          expanded={expanded[`school-${school.id()}-class-step-${classStep?.id() || "no-class-step"}-school-class-${schoolClass.id()}`]}
                          hasUnicorn={schoolClass.hasUnicornSubscription()}
                          identifier={`school-${school.id()}-class-step-${classStep?.id() || "no-class-step"}-school-class-${schoolClass.id()}`}
                          indentationLevel={3}
                          key={schoolClass.id()}
                          label={schoolClass.interpretedName()}
                          model={schoolClass}
                          modelClass="SchoolClass"
                          modelId={schoolClass.id()}
                          mood={classStatusData[schoolClass.id()].mood_average}
                          moodLabel={t(".latest_mood")}
                          onCollapse={this.tt.onCollapse}
                          onExpand={this.tt.onExpand}
                          unicornEffectsCount={digg(classStatusData, schoolClass.id(), "unicorn_effects_this_week")}
                          unicornPath={
                            Routes.userUnicornPath({
                              date_from: I18n.strftime("%Y-%m-%d", startDate),
                              date_to: I18n.strftime("%Y-%m-%d", endDate),
                              school_classes: [schoolClass.id()]
                            })
                          }
                          woofs={digg(classStatusData, schoolClass.id(), "woofs_this_week")}
                        />
                      )}
                    </ClassStatusRow>
                  )}
                </ClassStatusRow>
              )}
            </tbody>
          </table>
        </div>
      </View>
    )
  }

  averageMoodForSchool(school) {
    const classSteps = this.classStepsForSchool(school)
    let brainBreaksCount = 0
    let challengesCount = 0
    let moodsSum = 0.0
    let moodsCount = 0
    let woofsCount = 0
    let unicornEffectsCount = 0

    for (const classStep of classSteps) {
      const {
        brainBreaksCount: classStepsBrainBreaksCount,
        challengesCount: classStepsChallengesCount,
        moodAverage: classStepsMoodAverage,
        woofsCount: classStepsWoofsCount,
        unicornEffectsCount: classStepsUnicornEffectsCount
      } = this.averageMoodForSchoolAndClassStep(school, classStep)

      if (classStepsMoodAverage) {
        moodsSum += classStepsMoodAverage
        moodsCount++
      }

      brainBreaksCount += classStepsBrainBreaksCount
      challengesCount += classStepsChallengesCount
      woofsCount += classStepsWoofsCount
      unicornEffectsCount += classStepsUnicornEffectsCount
    }

    return {
      brainBreaksCount,
      challengesCount,
      moodAverage: moodsCount > 0 ? moodsSum / moodsCount : null,
      woofsCount,
      unicornEffectsCount
    }
  }

  averageMoodForSchoolAndClassStep(school, classStep) {
    const {classStatusData} = this.s
    const schoolClasses = this.schoolClassForSchoolAndClassStep(school, classStep?.id() || "no-class-step")
    let brainBreaksCount = 0
    let challengesCount = 0
    let moodsSum = 0.0
    let moodsCount = 0
    let woofsCount = 0
    let unicornEffectsCount = 0

    for (const schoolClass of schoolClasses) {
      const schoolClassBrainBreaksCount = digg(classStatusData[schoolClass.id()], "brain_breaks_this_week")
      const schoolClassChallengesCount = digg(classStatusData[schoolClass.id()], "challenges_this_week")
      const schoolClassMoodAverage = digg(classStatusData[schoolClass.id()], "mood_average")
      const schoolClassWoofsCount = digg(classStatusData[schoolClass.id()], "woofs_this_week")
      const schoolClassUnicornEffectsCount = digg(classStatusData[schoolClass.id()], "unicorn_effects_this_week")

      if (schoolClassMoodAverage) {
        moodsCount++
        moodsSum += schoolClassMoodAverage
      }

      brainBreaksCount += schoolClassBrainBreaksCount
      challengesCount += schoolClassChallengesCount
      woofsCount += schoolClassWoofsCount
      unicornEffectsCount += schoolClassUnicornEffectsCount
    }

    return {
      brainBreaksCount,
      challengesCount,
      moodAverage: moodsCount > 0 ? moodsSum / moodsCount : null,
      woofsCount,
      unicornEffectsCount
    }
  }

  classStepsForSchool(school) {
    const classSteps = Object.values(this.s.schoolClassSteps[school.id()])

    return classSteps.sort((classStep1, classStep2) => classStep1?.primarySchoolStep() - classStep2?.primarySchoolStep())
  }

  loadExpanded = async () => {
    const classStatusExpansions = await ClassStatusExpansion.ransack({user_id_eq: this.tt.currentUser.id()}).toArray()
    const newExpanded = {}

    for (const classStatusExpansion of classStatusExpansions) {
      newExpanded[classStatusExpansion.identifier()] = classStatusExpansion.expanded()
    }

    this.setState((prevState) => ({expanded: Object.assign({}, prevState.expanded, newExpanded)}))
  }

  schoolClassForSchoolAndClassStep = (school, classStepId) => {
    const {schoolClassesForClassSteps} = this.s
    const schoolClassesHash = dig(schoolClassesForClassSteps, school.id(), classStepId)

    if (schoolClassesHash) {
      return Object.values(schoolClassesHash)
    }
  }

  updateExpanded = async ({expanded, identifier, modelClass, modelId}) => {
    let classStatusExpansion = await ClassStatusExpansion
      .ransack({identifier_eq: identifier, resource_id_eq: modelId, resource_type_eq: modelClass, user_id_eq: this.tt.currentUser.id()})
      .first()

    if (!classStatusExpansion) {
      classStatusExpansion = new ClassStatusExpansion({
        identifier,
        resource_id: modelId,
        resource_type: modelClass,
        user_id: this.tt.currentUser.id()
      })
    }

    classStatusExpansion.assignAttributes({expanded})
    await classStatusExpansion.save()
  }

  onCollapse = ({identifier, modelClass, modelId}) => {
    const newExpanded = {}

    newExpanded[identifier] = false

    this.setState((prevState) => ({expanded: Object.assign({}, prevState.expanded, newExpanded)}))
    this.updateExpanded({expanded: false, identifier, modelClass, modelId})
  }

  onExpand = ({identifier, modelClass, modelId}) => {
    const newExpanded = {}

    newExpanded[identifier] = true

    this.setState((prevState) => ({expanded: Object.assign({}, prevState.expanded, newExpanded)}))
    this.updateExpanded({expanded: true, identifier, modelClass, modelId})
  }
}))
