import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectClassSteps} from "components/class-steps/select"
import {AdminSelectSchools} from "components/schools/select"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedSchoolClassAttributes = ["classStepId", "id", "interpretedName", "name", "schoolId"]

for (const locale of Locales.availableLocales()) {
  selectedSchoolClassAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSchoolClassesEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_classes.edit"})
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        SchoolClass: selectedSchoolClassAttributes
      }
    })

    this.setInstance({schoolClass, schoolClassId, t})
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "school-classes", headerTitle: this.headerTitle()})
  }

  render() {
    const {schoolClass, onSubmit} = this.tt

    return (
      <View dataSet={{route: "admin--school-classes--edit"}}>
        {schoolClass &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <AdminInput
                attribute={`name${inflection.camelize(locale)}`}
                containerProps={{style: {marginBottom: 15}}}
                key={locale}
                label={`${SchoolClass.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={schoolClass}
              />
            )}
            <View style={{marginBottom: 15}}>
              <AdminSelectSchools attribute="schoolId" label={SchoolClass.humanAttributeName("school")} model={schoolClass} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectClassSteps attribute="classStepId" label={SchoolClass.humanAttributeName("classStep")} model={schoolClass} />
            </View>
            <AdminButton primary save style={{marginTop: 15}} submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {schoolClass, t} = this.tt

    if (schoolClass?.isNewRecord()) {
      return t(".add_new_school_class")
    } else if (schoolClass?.isPersisted()) {
      return t(".edit_school_class", {name: schoolClass.interpretedName()})
    }
  }

  onSubmit = async () => {
    const {schoolClass, t} = this.tt
    const formData = this.s.form.asObject()

    if (schoolClass.isNewRecord()) {
      formData.school_class.id = schoolClass.id()
    }

    try {
      await schoolClass.saveRaw(formData)
      FlashMessage.success(t(".the_school_class_was_saved"))
      AppHistory.push(Routes.adminSchoolClassPath(schoolClass.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
