import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import {View} from "react-native"
export default memo(shapeComponent(class ContentsErrors extends ShapeComponent {
  static propTypes = propTypesExact({
    errors: PropTypes.array.isRequired
  })

  render() {
    const {errors} = this.p

    return (
      <View dataSet={{component: "contents/errors"}}>
        {errors.map((error, errorIndex) =>
          <Text key={`${errorIndex}-${error.message}`} style={{color: "red"}}>
            {error.message}
          </Text>
        )}
      </View>
    )
  }
}))
