import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import {AdminSelectContacts} from "components/contacts/select"
import AppHistory from "shared/history"
import {Dialogue} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import Select from "components/inputs/select"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminDialoguesEdit extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.dialogues.edit"})
    const params = useParams()
    const {dialogue, dialogueId} = useModel(Dialogue, {
      match: {params},
      newIfNoId: {
        defaults: () => ({
          id: uuidv4()
        })
      },
      select: {
        Dialogue: ["body", "id", "dialogueType", "contactId"]
      }
    })
    const dialogTypeOptions = useMemo(
      () => TranslatedCollections.get(Dialogue, "dialogue_type").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )

    this.useStates({
      form: null
    })
    this.setInstance({
      dialogue,
      dialogueId,
      dialogTypeOptions,
      t
    })

    useAdminLayout().setState({active: "dialogues", headerTitle: this.headerTitle()})
  }

  render() {
    const {dialogue, dialogTypeOptions, t} = this.tt

    return (
      <View dataSet={{route: "admin/dialogues/edit"}}>
        {dialogue &&
          <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            <Input attribute="body" model={dialogue} type="ckeditor" />
            <Select attribute="dialogueType" model={dialogue} options={dialogTypeOptions} type="hayaSelect" />
            <View style={{marginVertical: 10}}>
              <AdminSelectContacts attribute="contactId" label={Dialogue.humanAttributeName("contact")} model={dialogue} />
            </View>
            <AdminButton primary submit title={t("js.shared.save")} />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {dialogue, t} = this.tt

    if (dialogue?.isNewRecord()) {
      return t(".add_new_dialogue")
    } else if (dialogue?.isPersisted()) {
      return t(".edit_dialogue", {name: dialogue.id()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {dialogue, t} = this.tt

    if (dialogue.isNewRecord()) {
      formData.dialogue.new_id = dialogue.id()
    }

    try {
      await dialogue.saveRaw(formData)
      FlashMessage.success(t(".the_dialogue_was_saved"))
      AppHistory.push(Routes.adminDialoguePath(dialogue.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
