import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import ContentsEditor from "components/contents/editor"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {SurveyStepElement} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsAdminSurveyStepElementsPartialTypesFixedContent extends ShapeComponent {
  static propTypes = {
    surveyStepElement: PropTypes.instanceOf(SurveyStepElement).isRequired
  }

  render() {
    const {t} = useI18n({namespace: "js.routes.admin.survey_step_elements.edit"})
    const {className, surveyStepElement, ...restProps} = this.props

    return (
      <div className={classNames("components--admin--survey-step-elements--partial-types--fixed-content", className)} {...restProps}>
        <ContentsEditor
          attributeName="content"
          inputProps={{
            id: "survey_step_element_content",
            label: t(".fixed_content")
          }}
          name="survey_step_element[contents_attributes][0]"
          resource={surveyStepElement}
          surveyStep={surveyStepElement.step()}
        />
      </div>
    )
  }
}))
