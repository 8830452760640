import * as models from "models"
import {Audit, NotificationType} from "models"
import {dig, digg} from "diggerize"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Params from "@kaspernj/api-maker/build/params"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNotificationTypesAuditTest extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.notification_types.audit_test"})
    const params = useParams()
    const {notificationType, notificationTypeId} = useModel(NotificationType, {
      match: {params},
      preload: ["audit_triggers"]
    })
    const queryParams = useQueryParams()
    const auditTriggers = notificationType?.auditTriggers()?.loaded()
    let defaultAuditClassName

    if (auditTriggers?.length > 0) {
      const auditTrigger = auditTriggers[0]

      defaultAuditClassName = `${auditTrigger.auditableType()}Audit`
    }

    const auditClassName = queryParams.audit_class || defaultAuditClassName || "Audit"
    const auditClass = digg(models, auditClassName)
    const auditModelClasses = useMemo(
      () => Object.values(models).filter((modelClass) => modelClass.modelClassData().name.endsWith("Audit")),
      []
    )

    this.auditModelClassesOptions = useMemo(
      () => auditModelClasses.map((auditModelClass) => ({
        text: auditModelClass.modelClassData().name,
        value: auditModelClass.modelClassData().name
      })),
      []
    )

    this.setInstance({auditClass, auditClassName, notificationType, notificationTypeId, queryParams, t})
    this.useStates({
      id: queryParams.audit_id,
      result: null
    })
  }

  render() {
    const {auditClass, auditModelClassesOptions, notificationType, t} = this.tt
    const auditClassValues = [auditClass.modelClassData().name]

    return (
      <View dataSet={{route: "admin/notification-types/audit-test"}}>
        {notificationType &&
          <Nav active="audit-test" className="mb-4" notificationType={notificationType} />
        }
        <Form onSubmit={this.tt.onSubmit}>
          <HayaSelect
            id="audit_model_class"
            onChange={this.tt.onAuditModelClassChanged}
            options={auditModelClassesOptions}
            styles={{
              main: {
                marginBottom: 20,
                maxWidth: 400
              }
            }}
            values={auditClassValues}
          />
          <AdminInput
            dataSet={{class: "audit-id-input"}}
            label={`${Audit.modelName().human()} ${Audit.humanAttributeName("id")}`}
            onChangeText={this.tt.onChangeId}
            style={{marginBottom: 15}}
            value={this.s.id}
          />
          <AdminButton dataSet={{class: "submit-audit-test-button"}} submit title={t("js.shared.submit")} />
        </Form>
        {this.s.result &&
          <UtilsCard dataSet={{class: "result-card"}} style={{marginTop: 25}}>
            <Text>
              {JSON.stringify(this.s.result, null, 2)}
            </Text>
          </UtilsCard>
        }
      </View>
    )
  }

  onAuditModelClassChanged = (args) => {
    const newAuditClassName = dig(args, "options", 0, "value")

    Params.changeParams({audit_class: newAuditClassName})
  }

  onChangeId = (newId) => {
    Params.changeParams({audit_id: newId})
    this.setStates.id(newId)
  }

  onSubmit = async () => {
    try {
      const result = await this.tt.notificationType.auditTest({
        audit_class: this.tt.auditClassName,
        id: this.s.id
      })

      this.setState({result})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
