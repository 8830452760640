import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {Course} from "models"
import CoursesTable from "components/courses/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCoursesIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.courses.index"})

    this.t = t

    useAdminLayout()?.setState({actions: this.actions(), active: "courses", headerTitle: Course.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin-courses--index"}}>
        <CoursesTable adminLayout />
      </View>
    )
  }

  actions() {
    return (
      <Button
        className="action-button new-course-button"
        icon="plus"
        label={this.t(".add_new_course")}
        to={Routes.newAdminCoursePath()}
      />
    )
  }
}))
