import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminSelect from "components/admin/select"
import {AdminSelectPlans} from "components/plans/select"
import {AdminSelectSchoolClasses} from "components/school-classes/select"
import {AdminSelectSchools} from "components/schools/select"
import {AdminSelectUsers} from "components/users/select"
import AppHistory from "shared/history"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {Subscription} from "models"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSubscriptionsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.subscriptions.edit"})
    const params = useParams()
    const {subscription, subscriptionId} = useModel(Subscription, {
      match: {params},
      newIfNoId: true,
      select: {
        Subscription: ["id", "planId", "resourceId", "resourceType"]
      }
    })

    this.setInstance({
      subscription,
      subscriptionId,
      t
    })

    this.useStates({
      form: null,
      resourceType: () => subscription?.resourceType()
    })

    useMemo(() => {
      if (subscription) {
        this.setState({
          resourceType: subscription.resourceType()
        })
      }
    }, [subscription?.id()])

    useAdminLayout()?.setState({active: "subscriptions", headerTitle: this.headerTitle()})
  }

  render() {
    const {onSubmit, subscription} = this.tt
    const {resourceType} = this.s

    return (
      <View dataSet={{route: "admin--subscriptions--edit"}}>
        {subscription &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <AdminSelectPlans attribute="planId" label={Subscription.humanAttributeName("plan")} model={subscription} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelect
                attribute="resourceType"
                model={subscription}
                onChange={this.onResourceTypeChanged}
                options={this.resourceTypesOptions()}
                type="hayaSelect"
              />
            </View>
            {resourceType == "School" &&
              <View style={{marginBottom: 15}}>
                <AdminSelectSchools attribute="resourceId" label={Subscription.humanAttributeName("resource")} model={subscription} />
              </View>
            }
            {resourceType == "SchoolClass" &&
              <View style={{marginBottom: 15}}>
                <AdminSelectSchoolClasses attribute="resourceId" label={Subscription.humanAttributeName("resource")} model={subscription} />
              </View>
            }
            {resourceType == "User" &&
              <View style={{marginBottom: 15}}>
                <AdminSelectUsers attribute="resourceId" label={Subscription.humanAttributeName("resource")} model={subscription} />
              </View>
            }
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {subscription} = this.tt

    if (subscription?.isNewRecord()) {
      return this.t(".add_new_subscription")
    } else if (subscription?.isPersisted()) {
      return this.t(".edit_subscription")
    }
  }

  onResourceTypeChanged = ({options}) => {
    const resourceType = dig(options, 0, "value")

    this.setState({resourceType})
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {subscription, t} = this.tt

    try {
      await subscription.saveRaw(formData)
      FlashMessage.success(t(".the_subscription_was_saved"))
      AppHistory.push(Routes.adminSubscriptionPath(subscription.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  resourceTypesOptions = () => TranslatedCollections.get(Subscription, "resource_type").map(({translation, value}) => ({text: translation, value}))
}))
