import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplate} from "models"
import NewsletterTemplatesTable from "components/newsletter-templates/table"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.newsletter_templates.index"})

    this.canCan = useCanCan(() => [[NewsletterTemplate, ["create"]]])
    this.t = t

    useAdminLayout()?.setState({actions: this.actions(), active: "newsletter-templates", headerTitle: NewsletterTemplate.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin-newsletter-templates--index"}}>
        <NewsletterTemplatesTable adminLayout />
      </View>
    )
  }

  actions = () => <>
    {this.canCan?.can("create", NewsletterTemplate) &&
      <Button
        className="new-newsletter-template-button"
        icon="plus"
        label={this.t(".add_new_newsletter_template")}
        to={Routes.newAdminNewsletterTemplatePath()}
      />
    }
  </>
}))
