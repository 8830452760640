import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AdminSelect from "components/admin/select"
import {AdminSelectEmailTemplates} from "components/email-templates/select"
import AppHistory from "shared/history"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import {NotificationTypeAction} from "models"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const notificationTypeActionSelectedAttributes = ["actionType", "delay", "emailTemplateId", "id", "guardLiquid", "name", "notificationTypeId", "pointsLiquid"]

export default memo(shapeComponent(class RoutesAdminNotificationTypeActionsEdit extends ShapeComponent {
  static propTypes = {
    notificationTypeAction: PropTypes.instanceOf(NotificationTypeAction),
    notificationTypeActionId: PropTypes.string
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.notification_type_actions.edit"})
    const params = useParams()
    const {notificationTypeAction, notificationTypeActionId} = useModel(NotificationTypeAction, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        NotificationTypeAction: notificationTypeActionSelectedAttributes
      }
    })

    this.setInstance({
      actionTypesCollection: useMemo(
        () => TranslatedCollections.get(NotificationTypeAction, "action_type").map(({translation, value}) => ({text: translation, value})),
        [locale]
      ),
      notificationTypeAction,
      notificationTypeActionId,
      t
    })
    this.useStates({
      actionType: undefined,
      form: null
    })

    useMemo(() => {
      if (notificationTypeAction) {
        this.setState({actionType: notificationTypeAction.actionType()})
      }
    }, [notificationTypeAction?.id()])

    useAdminLayout()?.setState({active: "notification-types", headerTitle: this.headerTitle()})
  }

  render() {
    const {actionTypesCollection, notificationTypeAction, onSubmit} = this.tt
    const {actionType} = this.s

    return (
      <View dataSet={{route: "admin--notification-type-actions--edit"}}>
        {notificationTypeAction &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <AdminInput attribute="name" model={notificationTypeAction} style={{marginBottom: 15}} />
            <View style={{marginBottom: 15}}>
              <AdminSelect
                attribute="actionType"
                model={notificationTypeAction}
                onChange={this.tt.onActionTypeChanged}
                options={actionTypesCollection}
              />
            </View>
            {actionType == "email" &&
              <View style={{marginBottom: 15}}>
                <AdminSelectEmailTemplates
                  attribute="emailTemplateId"
                  label={NotificationTypeAction.humanAttributeName("emailTemplate")}
                  model={notificationTypeAction}
                />
              </View>
            }
            {actionType == "points" &&
              <AdminInput attribute="pointsLiquid" autoRows model={notificationTypeAction} multiline style={{marginBottom: 15}} />
            }
            <AdminInput attribute="delay" model={notificationTypeAction} style={{marginBottom: 15}} />
            <AdminInput attribute="guardLiquid" autoRows model={notificationTypeAction} multiline style={{marginBottom: 15}} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {notificationTypeAction, t} = this.tt

    if (notificationTypeAction?.isNewRecord()) {
      return t(".add_new_notification_type_action")
    } else if (notificationTypeAction?.isPersisted()) {
      return t(".edit_notification_type_action", {name: notificationTypeAction.id()})
    }
  }

  onActionTypeChanged = ({options}) => {
    this.setState({
      actionType: dig(options, 0, "value")
    })
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {notificationTypeAction, t} = this.tt

    if (notificationTypeAction.isNewRecord()) {
      formData.notification_type_action.new_id = notificationTypeAction.id()
      formData.notification_type_action.notification_type_id = notificationTypeAction.notificationTypeId()
    }

    if (formData.notification_type_action.action_type == "points") {
      formData.notification_type_action.email_template_id = null
    }

    try {
      await notificationTypeAction.saveRaw(formData)
      FlashMessage.success(t(".the_notification_type_action_was_saved"))
      AppHistory.push(Routes.actionsAdminNotificationTypePath(notificationTypeAction.notificationTypeId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
