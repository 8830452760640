import {AccountSchool, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountLink from "components/accounts/link"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SchoolLink from "components/schools/link"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"

export default memo(shapeComponent(class ComponentsAccountSchools extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    currentUser: PropTypes.instanceOf(User)
  }

  setup() {
    this.currentUser = useCurrentUser()
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--account-schools--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={AccountSchool}
        preloads={["account", "school"]}
        select={{
          Account: ["id", "name"],
          AccountSchool: ["id"],
          School: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        content: this.tt.accountContent,
        label: AccountSchool.humanAttributeName("account"),
        sortKey: "name"
      },
      {
        content: this.tt.schoolContent,
        label: AccountSchool.humanAttributeName("school"),
        sortKey: "currentTranslationName"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.tt.currentUser)

    return columns
  }

  editModelPath = ({accountSchool}) => {
    if (this.p.adminLayout) return Routes.editAdminAccountSchoolPath(accountSchool.id())
  }

  viewModelPath = ({accountSchool}) => {
    if (this.p.adminLayout) return Routes.adminAccountSchoolPath(accountSchool.id())
  }

  accountContent = ({accountSchool}) => accountSchool.account() && <AccountLink account={accountSchool.account()} {...linkProps(this.props)} />
  schoolContent = ({accountSchool}) => accountSchool.school() && <SchoolLink school={accountSchool.school()} {...linkProps(this.props)} />
}))
