import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import currentDate from "shared/current-date"
import memo from "set-state-compare/src/memo"
import React from "react"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
export default memo(shapeComponent(class ComponentsUserDashboardWelcomeMessage extends ShapeComponent {
  date = currentDate()

  render() {
    const {t} = useI18n({namespace: "js.components.user.dashboard.welcome_message"})
    const {className, ...restProps} = this.props
    const {date} = this.tt
    const currentUser = useCurrentUser()
    const hour = date.getHours()
    const userName = currentUser?.firstName() || User.modelName().human()

    return (
      <div className={classNames("components--user--dashboard--welcome-message", className)} {...restProps}>
        {(() => {
          if (hour >= 4 && hour < 10) {
            return t(".good_morning_name", {name: userName})
          } else if (hour >= 10 && hour < 12) {
            return t(".good_morning_10_name", {name: userName})
          } else if (hour >= 12 && hour < 17) {
            return t(".good_afternoon_name", {name: userName})
          } else if ((hour >= 17 && hour <= 23) || (hour < 4)) {
            return t(".good_evening_name", {name: userName})
          }
        })()}
      </div>
    )
  }
}))
