import {digg} from "diggerize"
import {SchoolClass} from "models"
import unsupportedProps from "components/utils/unsupported-props"
const insightsTopScoreFactor = async ({mode, schoolClassCheckInsQuery, teamSurveysQuery, ...restProps}) => {
  unsupportedProps(restProps)

  if (!mode) throw new Error("Required argument 'mode' not given")
  if (!schoolClassCheckInsQuery && !teamSurveysQuery) throw new Error("Required argument 'schoolClassCheckInsQuery' or 'teamSurveysQuery' not given")
  if (!["negative", "positive"].includes(mode)) throw new Error(`Invalid mode given: ${mode}`)

  const response = await SchoolClass.insightsTopScoreFactor({
    mode,
    school_class_check_ins_query: schoolClassCheckInsQuery,
    team_surveys_query: teamSurveysQuery
  })
  const topScoreFactorReducedModel = digg(response, "top_score_factor")

  return topScoreFactorReducedModel
}

export default insightsTopScoreFactor
