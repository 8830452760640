import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
export default memo(shapeComponent(class RoutesFrontAccountsCreated extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.front.accounts.created"})
    // const {account, accountId} = useModel(Account, {match: props.match})

    this.t = t

    useFrontLayout()?.setState({headTitle: t(".account_created")})
  }

  render() {
    const {t} = this.tt
    const queryParams = useQueryParams()
    const schoolId = digg(queryParams, "school_id")

    return (
      <div className="routes--front--accounts--created">
        <div style={{textAlign: "center"}}>
          <h1>{t(".you_have_been_created")}</h1>
          <Link className="create-first-class-link" to={Routes.newUserSchoolClassPath({school_class: {school_id: schoolId}})}>
            {t(".click_here_to_create_your_first_school_class")}
          </Link>
        </div>
      </div>
    )
  }
}))
