import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {CheckIn} from "models"
import CheckInsTable from "components/check-ins/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCheckInsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.check_ins.index"})

    this.canCan = useCanCan(() => [[CheckIn, ["create"]]])
    this.t = t

    useAdminLayout()?.setState({actions: this.actions(), active: "check-ins", headerTitle: CheckIn.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin/check-ins/index"}}>
        <CheckInsTable adminLayout />
      </View>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", CheckIn) &&
          <Button
            className="new-check-in-button"
            icon="plus"
            label={this.t(".add_new_check_in")}
            to={Routes.newAdminCheckInPath()}
          />
        }
      </>
    )
  }
}))
