import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {SurveyStep, SurveyStepElement} from "models"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Button from "components/inputs/button"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import SurveyLink from "components/surveys/link"
import SurveyStepElementsTable from "components/survey-step-elements/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedSurveyStepAttributes = ["copyable", "createdAt", "id", "liquidConditions", "name", "position", "surveyStepElementsCount", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedSurveyStepAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSurveyStepsShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.survey_steps.show"})
    const params = useParams()
    const {surveyStep, surveyStepId} = useModel(SurveyStep, {
      abilities: {
        SurveyStep: ["destroy", "edit"]
      },
      match: {params},
      preload: ["survey"],
      select: {
        Survey: ["id", "name"],
        SurveyStep: selectedSurveyStepAttributes
      }
    })

    this.setInstance({
      canCan: useCanCan(() => [[SurveyStepElement, ["create"]]]),
      surveyStep,
      surveyStepId,
      surveyStepElementsQuery: useMemo(
        () => SurveyStepElement.ransack({
          step_id_eq: surveyStepId
        }),
        [surveyStepId]
      ),
      t
    })

    useAdminLayout()?.setState({
      actions: this.actions(),
      active: "surveys",
      headerTitle: surveyStep?.name()
    })
  }

  render() {
    const {surveyStepElementsQuery, surveyStep} = this.tt

    return (
      <View dataSet={{route: "admin--survey-steps--show"}}>
        {surveyStep &&
          <>
            <AttributeRows attributes={["id", "position", "surveyStepElementsCount", "copyable", "createdAt", "updatedAt"]} model={surveyStep} />
            <AttributeRow attribute="survey" model={surveyStep}>
              {surveyStep.survey() &&
                <SurveyLink adminLayout survey={surveyStep.survey()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <AttributeRow
                attribute={`name${inflection.camelize(locale)}`}
                key={locale}
                label={`${SurveyStep.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={surveyStep}
              />
            )}
            <AttributeRow attribute="liquidConditions" model={surveyStep}>
              <pre>
                {surveyStep.liquidConditions()}
              </pre>
            </AttributeRow>
          </>
        }
        <SurveyStepElementsTable adminLayout className="mt-4" collection={surveyStepElementsQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, surveyStep, surveyStepId} = this.tt

    return (
      <>
        {canCan?.can("create", SurveyStepElement) &&
          <Button
            className="new-element-button"
            icon="plus"
            label={this.t(".add_new_element")}
            to={Routes.newAdminSurveyStepElementPath({survey_step_element: {survey_step_id: surveyStepId}})}
          />
        }
        <AdminShowActions model={surveyStep} />
      </>
    )
  }
}))
