import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {TextKey, TextValue} from "models"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsInput from "components/utils/input"
import UtilsSelect from "components/utils/select"
export default memo(shapeComponent(class TextKeyAndValue extends ShapeComponent {
  static propTypes = propTypesExact({
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    textKey: PropTypes.instanceOf(TextKey).isRequired,
    textValue: PropTypes.instanceOf(TextValue).isRequired
  })

  setup() {
    const {textValue} = this.p
    const {locale} = useI18n({namespace: "js.components.text_keys.partial.text_key_and_value"})
    const valueTypesCollection = useMemo(
      () => TranslatedCollections.get(TextValue, "value_type").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )

    this.setInstance({valueTypesCollection})
    this.useStates({
      value: textValue.value(),
      valueType: textValue.valueType()
    })
  }

  render() {
    const {valueTypesCollection} = this.tt
    const {locale, textKey, textValue} = this.p
    const {value, valueType} = this.s
    const key = textKey.key()

    if (!textValue) {
      throw new Error(`No textValue for ${locale} and key ${textKey.key()}`)
    } else if (!valueType) {
      throw new Error(`No valueType for ${locale} and key ${textKey.key()}`)
    }

    return (
      <div>
        <div style={{fontWeight: "bold"}}>
          {textKey.key()} ({Locales.labelForLocale(locale)})
        </div>
        <UtilsSelect
          id={`text_value_type_${locale}_${key}`}
          label={TextValue.humanAttributeName("valueType")}
          onChangeSelected={this.tt.onValueTypeChanged}
          options={valueTypesCollection}
          values={[valueType]}
        />
        {valueType == "ckeditor" &&
          <Input
            attribute="value"
            defaultValue={value}
            id={`text_value_${locale}`}
            label={null}
            model={textValue}
            onChange={this.tt.onChange}
            type="ckeditor"
          />
        }
        {valueType == "plain" &&
          <UtilsInput
            autoRows
            dataSet={{class: `text-value-${locale}`, key}}
            label={null}
            multiline
            onChangeText={this.tt.onChangeValue}
            value={value}
          />
        }
      </div>
    )
  }

  onChange = ({value}) => this.onChangeValue(value)

  onChangeValue = (value) => {
    this.setState({value})
    this.p.textValue.assignAttributes({value})
    this.p.onChange()
  }

  onValueTypeChanged = (valueType) => this.setState({valueType})
}))
