import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useWoofRouter} from "shared/woof-router"
export default memo(shapeComponent(class RoutesAdminContactsRoute extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="contacts">
        <Route component="edit" exact path="new" />
        <Route exact path=":contact_id/dialogue" />
        <Route exact path=":contact_id/edit" />
        <Route exact path=":contact_id/emails" />
        <Route component="phone-numbers" exact path=":contact_id/phone_numbers" />
        <Route component="relationships" exact path=":contact_id/relationships" />
        <Route component="show" exact path=":contact_id" />
        <Route component="index" exact />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))
