import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolClassesDeleteModal extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.delete_modal"})

    this.setInstance({t})
  }

  render() {
    const {onCancelDeleteClassPressed, onDeleteClassPressed, t} = this.tt
    const {schoolClass, ...restProps} = this.props

    return (
      <Modal {...restProps}>
        <View>
          <Text style={{marginBottom: 20, fontSize: 32, textAlign: "center"}}>
            {t("js.shared.are_you_sure")}
          </Text>
        </View>
        <View>
          <Text style={{marginBottom: 20, fontSize: 20, textAlign: "center"}}>
            {t(".if_you_delete_the_class_then")}
          </Text>
        </View>
        <View style={{marginBottom: 15}}>
          <Button
            color="red"
            dataSet={{class: "yes-delete-the-class-button"}}
            fontStyle={{fontSize: 20}}
            onPress={onDeleteClassPressed}
            title={t(".yes_delete_the_class")}
          />
        </View>
        <View>
          <Button fontStyle={{fontSize: 20}} onPress={onCancelDeleteClassPressed} title={t(".no_keep_the_class")} />
        </View>
      </Modal>
    )
  }

  onCancelDeleteClassPressed = () => this.p.onRequestClose()

  onDeleteClassPressed = async () => {
    try {
      await loading(this.t(".deleting_class"), async () => {
        await this.p.schoolClass.destroy()
      })
      FlashMessage.success(this.t(".the_class_was_deleted"))
      this.p.onRequestClose()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
