import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import ScoreFactorLink from "components/score-factors/link"
import SurveyStepElementOptionLink from "components/survey-step-element-options/link"
import {SurveyStepElementOptionScore} from "models"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"
const selectedSurveyStepElementOptionScoreAttributes = ["createdAt", "id", "score", "updatedAt"]

export default memo(shapeComponent(class RoutesAdminSurveyStepElementOptionScoresShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {surveyStepElementOptionScore, surveyStepElementOptionScoreId} = useModel(SurveyStepElementOptionScore, {
      abilities: {
        SurveyStepElementOptionScore: ["destroy", "edit"]
      },
      match: {params},
      preload: ["option", "score_factor"],
      select: {
        ScoreFactor: ["id", "name"],
        SurveyStepElementOption: ["id", "name"],
        SurveyStepElementOptionScore: selectedSurveyStepElementOptionScoreAttributes
      }
    })

    this.setInstance({surveyStepElementOptionScore, surveyStepElementOptionScoreId})

    const actions = useMemo(
      () => <AdminShowActions model={surveyStepElementOptionScore} />,
      [surveyStepElementOptionScore?.id()]
    )

    useAdminLayout()?.setState({actions, active: "surveys", headerTitle: surveyStepElementOptionScore?.scoreFactor()?.name()})
  }

  render() {
    const {surveyStepElementOptionScore} = this.tt

    return (
      <View className="routes--admin--survey-step-element-option-scores--show">
        {surveyStepElementOptionScore &&
          <>
            <AttributeRows attributes={["id", "score", "createdAt", "updatedAt"]} model={surveyStepElementOptionScore} />
            <AttributeRow attribute="option" model={surveyStepElementOptionScore}>
              {surveyStepElementOptionScore.option() &&
                <SurveyStepElementOptionLink adminLayout surveyStepElementOption={surveyStepElementOptionScore.option()} />
              }
            </AttributeRow>
            <AttributeRow attribute="scoreFactor" model={surveyStepElementOptionScore}>
              {surveyStepElementOptionScore.scoreFactor() &&
                <ScoreFactorLink adminLayout scoreFactor={surveyStepElementOptionScore.scoreFactor()} />
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }
}))
