import {ContactRelationship, School, User} from "models"
import Cycler, {colors} from "shared/cycler"
import {HoverMenu, HoverMenuItem} from "components/hover-menu"
import {Image, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import EllipsisHSolid from "images/icons/ellipsis-v-solid.png"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import SchoolRolesNewRoleModal from "components/school-roles/new-role-modal"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserCircle from "components/school-roles/user-circle"

const SchoolItem = memo(shapeComponent(class SchoolItem extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.admin.users"})

    this.t = t
  }

  render() {
    return (
      <View dataSet={{class: "school-item", schoolId: this.p.school.id()}}>
        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Heading noMargin>
            {this.p.school.name()}
          </Heading>
          <HoverMenu content={this.tt.hoverMenuContent}>
            <Image
              dataSet={{"class": "options-button", "school-id": this.p.school.id()}}
              source={EllipsisHSolid}
              style={{width: 20, height: 20, opacity: "15%"}}
            />
          </HoverMenu>
        </View>
        <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          {this.p.schoolRolesForSchool(this.p.school)?.map((schoolRole) =>
            <UserCircle
              backgroundColor={schoolRole.child().user().color() || this.p.colorCycler.cycle()}
              key={schoolRole.id()}
              schoolRole={schoolRole}
              style={{marginRight: 10, marginBottom: 10}}
            />
          )}
        </View>
      </View>
    )
  }

  onNewUserPressed = () => this.p.onNewUserPressed({school: this.p.school})

  hoverMenuContent = () => <>
    <HoverMenuItem dataSet={{class: "new-school-role-menu-item"}} label={this.t(".add_user_to_school")} onPress={this.tt.onNewUserPressed} />
  </>
}))

export default memo(shapeComponent(class RoutesUserAdminUsers extends ShapeComponent {
  setup() {
    const currentUser = useCurrentUser()
    const schoolsCollection = useMemo(() => {
      return School
        .ransack({contact_children_relationships_child_id_eq: currentUser?.contactId() || "no-user-uuid"})
        .groupBy("id")
        .select({School: ["contactId", "id", "name"]})
    }, [currentUser?.id()])

    const schoolRolesCollection = useMemo(() => {
      return ContactRelationship
        .ransack({
          parent_children_relationships_child_id_eq: currentUser?.contactId() || "no-user-uuid",
          child_user_id_not_null: 1
        })
        .abilities({
          ContactRelationship: ["destroy", "edit"]
        })
        .preload(["child.user", "contact_relationship_type"])
        .select({
          Contact: ["id"],
          ContactRelationship: ["id", "parentId"],
          ContactRelationshipType: ["identifier", "translatedIdentifier"],
          User: ["color", "initials", "name"]
        })
        .groupBy("id")
    }, [currentUser?.id()])

    const {schools} = useCollection({
      collection: schoolsCollection,
      modelClass: School
    })

    const {contactRelationships: schoolRoles} = useCollection({
      collection: schoolRolesCollection,
      modelClass: ContactRelationship
    })

    this.setInstance({
      colorCycler: new Cycler(colors),
      schools,
      schoolRoles
    })
    this.useStates({showNewRoleModalForSchool: null})

    useFrontLayout().setState({active: "user-admin-users", headerTitle: User.modelName().human({count: 2})})
  }

  render() {
    const {colorCycler, schools} = this.tt

    return (
      <View dataSet={{route: "user/admin/users"}}>
        {this.s.showNewRoleModalForSchool &&
          <SchoolRolesNewRoleModal onRequestClose={this.tt.onRequestCloseNewRoleModal} school={this.s.showNewRoleModalForSchool} />
        }
        {schools?.map((school) =>
          <SchoolItem
            colorCycler={colorCycler}
            key={school.id()}
            onNewUserPressed={this.tt.onNewUserPressed}
            school={school}
            schoolRolesForSchool={this.tt.schoolRolesForSchool}
          />
        )}
      </View>
    )
  }

  schoolRolesForSchool = (school) => this.tt.schoolRoles?.filter((schoolRole) => schoolRole.parentId() == school.contactId())
  onNewUserPressed = ({school}) => this.setState({showNewRoleModalForSchool: school})
  onRequestCloseNewRoleModal = () => this.setState({showNewRoleModalForSchool: null})
}))
