import {EmailTemplate, EmailTemplatePreview, EmailTemplatePreviewLiquidVariable} from "models"
import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import LiquidVariable from "./edit/liquid-variable"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Text} from "shared/base"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import UtilsButton from "components/utils/button"
import UtilsHeading from "components/utils/heading"
import {v4 as uuidv4} from "uuid"

const selectedEmailTemplateAttributes = ["id", "name"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminEmailTemplatesPreviewsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.email_templates.previews.edit"})
    const {emailTemplate, emailTemplateId} = useModel(EmailTemplate, {
      match: this.p.match,
      select: {
        EmailTemplate: ["id"]
      }
    })
    const {emailTemplatePreview, emailTemplatePreviewId} = useModel(EmailTemplatePreview, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["liquid_variables"],
      select: {
        EmailTemplatePreview: selectedEmailTemplateAttributes,
        EmailTemplatePreviewLiquidVariable: ["automatic", "droppableRecordId", "droppableRecordType", "id", "name", "stringValue", "variableType"]
      }
    })

    this.formRef = useRef()
    this.setInstance({emailTemplate, emailTemplateId, emailTemplatePreview, emailTemplatePreviewId, t})
    this.useStates({
      lastUpdate: new Date(),
      liquidVariables: undefined
    })

    useMemo(() => {
      if (emailTemplate && emailTemplatePreview) {
        this.loadLiquidVariables()
      }
    }, [emailTemplate?.cacheKey(), emailTemplatePreview?.cacheKey()])
  }

  async loadLiquidVariables() {
    const {emailTemplate, emailTemplatePreview} = this.tt
    const liquidVariablesResult = await emailTemplate.liquidVariablesList()
    const liquidVariablesList = digg(liquidVariablesResult, "liquid_variables_list")
    const liquidVariables = emailTemplatePreview.liquidVariables().loaded()

    for (const liquidVariableData of Object.values(liquidVariablesList)) {
      let liquidVariable = emailTemplatePreview.liquidVariables().loaded().find((liquidVariable) =>
        liquidVariable.name() == digg(liquidVariableData, "name")
      )

      if (!liquidVariable) {
        liquidVariable = new EmailTemplatePreviewLiquidVariable({
          a: {
            automatic: true,
            id: uuidv4(),
            name: digg(liquidVariableData, "name")
          },
          isNewRecord: true
        })

        liquidVariables.push(liquidVariable)
      }
    }

    this.setState({liquidVariables})
  }

  render() {
    const {emailTemplatePreview, formRef, onSubmit, t} = this.tt
    const {liquidVariables} = this.s

    return (
      <AdminLayout active="email-templates" className="routes--admin--email-templates--previews--edit" headerTitle={this.headerTitle()}>
        {emailTemplatePreview &&
          <form onSubmit={onSubmit} ref={formRef}>
            {Locales.availableLocales().map((locale) =>
              <Input
                attribute={`name${inflection.camelize(locale)}`}
                key={locale}
                label={`${EmailTemplatePreview.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={emailTemplatePreview}
              />
            )}
            <UtilsHeading level={3} style={{marginTop: 20}}>
              {t(".liquid_variables")}
            </UtilsHeading>
            <UtilsButton
              dataSet={{class: "new-liquid-variable-button"}}
              onPress={this.tt.onNewLiquidVariablePressed}
              style={{marginBottom: 25}}
              title={t(".add_new_variable")}
            />
            {liquidVariables?.map((liquidVariable, liquidVariableIndex) =>
              <LiquidVariable key={liquidVariable.id()} liquidVariable={liquidVariable} liquidVariableIndex={liquidVariableIndex} />
            )}
            {liquidVariables?.length === 0 &&
              <Text>
                {t(".no_variables_found")}
              </Text>
            }
            <UtilsButton
              dataSet={{class: "save-button"}}
              onPress={this.tt.onSavePressed}
              primary
              save
              style={{marginTop: 25}}
              title={t("js.shared.save")}
            />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {emailTemplatePreview} = this.tt

    if (emailTemplatePreview?.isNewRecord()) {
      return this.t(".add_new_email_template_preview")
    } else if (emailTemplatePreview?.isPersisted()) {
      return this.t(".edit_email_template_preview", {name: emailTemplatePreview.name()})
    }
  }

  onNewLiquidVariablePressed = () => {
    const liquidVariable = new EmailTemplatePreviewLiquidVariable({
      a: {
        automatic: false,
        id: uuidv4(),
        name: ""
      },
      isNewRecord: true
    })

    this.setState({
      liquidVariables: this.s.liquidVariables.concat([liquidVariable])
    })
  }

  onSavePressed = () => this.save()

  onSubmit = async (e) => {
    e.preventDefault()
    await this.save()
  }

  save = async () => {
    const form = this.tt.formRef.current
    const formData = new FormData(form)
    const {emailTemplate, emailTemplatePreview} = this.tt

    if (emailTemplatePreview.isNewRecord()) {
      formData.append("email_template_preview[new_id]", emailTemplatePreview.id())
      formData.append("email_template_preview[email_template_id]", emailTemplate.id())
    }

    try {
      await emailTemplatePreview.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_email_template_preview_was_saved"))
      AppHistory.push(Routes.adminEmailTemplatePreviewPath(emailTemplate.id(), emailTemplatePreview.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
