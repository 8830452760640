import {School, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import CountryLink from "components/countries/link"
import isUserA from "components/users/is-a"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SchoolLink from "components/schools/link"
import SubscriptionLink from "components/subscriptions/link"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

const preloads = [
  "schools"
]

const select = {
  Country: ["id"],
  School: ["id", "name"],
  Subscription: ["id", "name"],
  User: ["createdAt", "email", "id", "firstName", "lastName", "name", "updatedAt"]
}

export default memo(shapeComponent(class ComponentsUsersTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.users.table"})

    this.currentUser = useCurrentUser()
    this.t = t
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--users--table", className)}
        columns={this.tt.columns}
        editModelPath={this.tt.editModelPath}
        modelClass={User}
        preloads={preloads}
        select={select}
        viewModelPath={this.tt.viewModelPath}
        workplace={isUserA(this.tt.currentUser, ["admin", "hacker"])}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        attribute: "firstName",
        path: ["contact"],
        sortKey: "contactFirstName"
      },
      {
        attribute: "lastName",
        path: ["contact"],
        sortKey: "contactLastName"
      },
      {
        attribute: "phoneNumber",
        path: ["contact", "primaryPhoneNumber"],
        sortKey: "contactPrimaryPhoneNumberphoneNumber"
      },
      {
        attribute: "email",
        path: ["contact", "primaryEmail"],
        sortKey: "contactPrimaryEmailEmail"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt",
        textRight: true
      },
      {
        attribute: "updatedAt",
        sortKey: "updatedAt",
        textRight: true
      },
      {
        attribute: "createdAt",
        identifier: "latest-dialogue",
        label: User.humanAttributeName("latestDialogue"),
        path: ["latestDialogue"],
        sortKey: "latestDialogueCreatedAt",
        textRight: true
      },
      {
        attribute: "timeZoneOffset",
        sortKey: "timeZoneOffset",
        textRight: true
      },
      {
        attribute: "name",
        content: this.tt.currentCountryContent,
        label: User.humanAttributeName("currentCountry"),
        path: ["currentCountry"],
        sortKey: "currentCountryCurrentTranslationName"
      },
      {
        attribute: "name",
        content: this.tt.activeSubscriptionsContent,
        label: User.humanAttributeName("activeSubscriptions"),
        path: ["active_subscriptions", "plan"],
        sortKey: "activeSubscriptionsPlanCurrentTranslationName"
      },
      {
        content: this.tt.schoolsContent,
        label: School.modelName().human({count: 2}),
        sortKey: "schoolsCurrentTranslationName"
      },
      {
        attribute: "schoolClassCheckInsCount",
        sortKey: "schoolClassCheckInsCount"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.tt.currentUser)

    return columns
  }

  activeSubscriptionsContent = ({user}) => <>
    {user.activeSubscriptions().loaded().map((subscription, subscriptionIndex) =>
      <React.Fragment key={subscription.id()}>
        <SubscriptionLink subscription={subscription} {...linkProps(this.props)} />{subscriptionIndex > 0 && ", "}
      </React.Fragment>
    )}
  </>

  currentCountryContent = ({user}) => user.currentCountry() && <CountryLink country={user.currentCountry()} {...linkProps(this.props)} />

  editModelPath = ({user}) => {
    if (this.p.adminLayout) return Routes.editAdminUserPath(user.id())
  }

  schoolsContent = ({user}) => <>
    {user.schools().loaded().map((school, schoolIndex) =>
      <React.Fragment key={school.id()}>
        <SchoolLink school={school} {...linkProps(this.props)} />{schoolIndex > 0 && ", "}
      </React.Fragment>
    )}
  </>

  viewModelPath = ({user}) => {
    if (this.p.adminLayout) return Routes.adminUserPath(user.id())
  }
}))
