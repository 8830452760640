import {Currency} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: CurrenciesSelectWithContainer, withMemo: ComponentsCurrenciesSelect} = selectComponent({
  className: "components--currencies--select",
  optionsCallback: async ({query, searchValue, valueCallback, values}) => {
    query = query?.clone() || Currency

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Currency: ["currencyCode", "id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_or_identifier_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const currencies = await query.toArray()

    return currencies.map((currency) => ({
      text: currency.name(),
      value: valueCallback?.({currency}) || currency.id()
    }))
  }
})

export default ComponentsCurrenciesSelect
export {CurrenciesSelectWithContainer}
