import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import useEventListener from "@kaspernj/api-maker/build/use-event-listener"

class ComponentsPopupMenuItem extends BaseComponent {
  render() {
    const {className, to, ...restProps} = this.props

    return (
      <Link className={classNames("components--popup-menu--menu-item", className)} to={to || "#"} {...restProps} />
    )
  }
}

class ComponentsPopupMenu extends BaseComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("components--popup-menu", className)} {...restProps} />
    )
  }
}

const ComponentsPopupMenuLink = memo(shapeComponent(class ComponentsPopupMenuLink extends ShapeComponent {
  static defaultProps = {
    align: "left"
  }

  static propTypes = {
    align: PropTypes.string.isRequired,
    menu: PropTypes.node.isRequired
  }

  setup() {
    this.rootRef = useRef()
    this.useStates({active: false})

    useEventListener(window, "mouseup", this.tt.onWindowMouseUp)
  }

  render() {
    const {onClick, rootRef} = this.tt
    const {active} = this.s
    const {align, className, children, menu, ...restProps} = this.props

    return (
      <div className={classNames("components-popup-menu-link", className)} data-align={align} ref={rootRef} {...restProps}>
        <a className="popup-menu-link" href="#" onClick={onClick}>
          {children}
        </a>
        <div style={{position: "relative"}}>
          {active && menu}
        </div>
      </div>
    )
  }

  onClick = (e) => {
    e.preventDefault()
    this.shape.set({active: !this.shape.active})
  }

  onWindowMouseUp = (e) => {
    const {rootRef} = this.t
    const {active} = this.s

    // Close the header actions menu if clicked happened outside
    if (active && rootRef.current && !rootRef.current.contains(e.target)) this.shape.set({active: false})
  }
}))

export {
  ComponentsPopupMenu as PopupMenu,
  ComponentsPopupMenuItem as PopupMenuItem,
  ComponentsPopupMenuLink as PopupMenuLink
}
