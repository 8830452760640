import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn, TeamSurvey} from "models"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import MoodAverage from "components/score-factor-groups/mood-average"
import MoodSimpleLineChart from "components/score-factor-groups/mood-simple-line-chart"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserSchoolClassesInsightsMoodChartSlide extends ShapeComponent {
  static propTypes = propTypesExact({
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.insights"})

    this.setInstance({t})
    this.useStates({
      lastSchoolClassCheckIn: undefined,
      lastSchoolClassCheckInTeamSurveysQuery: undefined
    })

    useMemo(() => {
      this.loadLastSchoolClassCheckIn()
    }, [])
  }

  async loadLastSchoolClassCheckIn() {
    const {schoolClass} = this.p
    const lastSchoolClassCheckIn = await SchoolClassCheckIn
      .ransack({
        s: "created_at DESC",
        school_class_id_eq: schoolClass.id(),
        state_eq: "closed"
      })
      .select({SchoolClassCheckIn: ["createdAt", "id"]})
      .first()

    this.setState({
      lastSchoolClassCheckIn,
      lastSchoolClassCheckInTeamSurveysQuery: TeamSurvey.ransack({
        preview_false: true,
        school_class_check_in_id_eq: lastSchoolClassCheckIn?.id() || "no-last-school-class-check-in-fake-uuid",
        state_eq: "completed"
      })
    })
  }

  render() {
    const {dateFrom, dateTo, schoolClass} = this.p
    const {lastSchoolClassCheckIn, lastSchoolClassCheckInTeamSurveysQuery} = this.s
    const moodCircleActive = Boolean(lastSchoolClassCheckIn)

    return (
      <View dataSet={{component: "user--school-classes--insights--mood-class-slide"}}>
        <div className="grid grid-gap-10 grid-cols-3" style={{width: "100%"}}>
          <div className="col-span-1 col-span-xl-1">
            {lastSchoolClassCheckInTeamSurveysQuery &&
              <MoodAverage
                className="three-latest-woofs-mood-average"
                defaultAverage={moodCircleActive ? undefined : 3.0}
                subTitle={I18n.strftime("%A d. %e. %B", lastSchoolClassCheckIn?.createdAt())}
                teamSurveysQuery={lastSchoolClassCheckInTeamSurveysQuery}
              />
            }
          </div>
          <div className="col-span-1 col-span-xl-2">
            {schoolClass &&
              <MoodSimpleLineChart dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
            }
          </div>
        </div>
      </View>
    )
  }
}))
