import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import {ElementTypeSelectWithContainer} from "components/survey-step-elements/element-type-select"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorsSelectWithContainer} from "components/score-factors/select"
import {SurveyStepElement} from "models"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const partialTypesRequireContext = require.context("components/admin/survey-step-elements/partial-types", true, /(.+)\.jsx$/)

const selectedSurveyStepElementAttributes = [
  "content",
  "contentPieceTypeId",
  "editable",
  "elementType",
  "id",
  "identifier",
  "maxChoices",
  "minChoices",
  "name",
  "position",
  "scoreFactorId",
  "state",
  "surveyStepId"
].concat(translatedAttributes(["mainContent", "name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminSurveyStepElementsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.survey_step_elements.edit"})
    const {surveyStepElement, surveyStepElementId} = useModel(SurveyStepElement, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["contents.absolute_containers", "contents.text_keys.text_values", "step"],
      select: {
        Content: ["attributeName", "body", "bodyType", "detectedLiquidVariables", "id"],
        ContentAbsoluteContainer: ["id"],
        SurveyStep: ["id"],
        SurveyStepElement: selectedSurveyStepElementAttributes,
        TextKey: ["attributeName", "id", "key"],
        TextValue: ["id", "locale", "value", "valueType"]
      }
    })

    this.setInstance({surveyStepElement, surveyStepElementId, t})
    this.useStates({
      elementType: undefined
    })
    useAdminLayout()?.setState({active: "surveys", headerTitle: this.headerTitle()})
  }

  render() {
    const {onElementTypeChanged, onSubmit, surveyStepElement} = this.tt
    const elementType = this.s.elementType || surveyStepElement?.elementType()
    let PartialElementType

    if (elementType) {
      try {
        PartialElementType = partialTypesRequireContext(`./${inflection.dasherize(elementType)}.jsx`).default
      } catch (error) {
        if (!error.message.includes("Cannot find module")) throw error
      }
    }

    return (
      <View dataSet={{route: "admin--survey-step-elements--edit"}}>
        {surveyStepElement &&
          <form onSubmit={onSubmit}>
            {(surveyStepElement.isNewRecord() || surveyStepElement.state() == "draft") &&
              <input name="survey_step_element[state]" type="hidden" value="done" />
            }
            {surveyStepElement.isNewRecord() && surveyStepElement.hasSurveyStepId() &&
              <input
                name="survey_step_element[survey_step_id]"
                type="hidden"
                value={surveyStepElement.surveyStepId()}
              />
            }
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${SurveyStepElement.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={surveyStepElement}
                />
                {surveyStepElement.elementType() != "fixed_content" &&
                  <Input
                    attribute={`mainContent${inflection.camelize(locale)}`}
                    label={`${SurveyStepElement.humanAttributeName("mainContent")} (${Locales.labelForLocale(locale)})`}
                    model={surveyStepElement}
                    type="ckeditor"
                  />
                }
              </React.Fragment>
            )}
            <Input attribute="identifier" model={surveyStepElement} />
            <Input attribute="position" model={surveyStepElement} />
            <ElementTypeSelectWithContainer
              attribute="elementType"
              defaultValue={elementType}
              model={surveyStepElement}
              onChange={onElementTypeChanged}
            />
            {["image_options", "radio_buttons", "slider"].includes(elementType) &&
              <>
                <Input attribute="minChoices" model={surveyStepElement} />
                <Input attribute="maxChoices" model={surveyStepElement} />
              </>
            }
            {elementType == "development_in_score_factor_chart" &&
              <ScoreFactorsSelectWithContainer
                attribute="scoreFactorId"
                label={SurveyStepElement.humanAttributeName("scoreFactor")}
                model={surveyStepElement}
              />
            }
            {PartialElementType &&
              <PartialElementType className="mt-4" surveyStepElement={surveyStepElement} />
            }
            <Button className="mt-4" primary save />
          </form>
        }
      </View>
    )
  }

  headerTitle() {
    const {surveyStepElement} = this.tt

    if (surveyStepElement?.isNewRecord()) {
      return this.t(".add_new_element")
    } else if (surveyStepElement?.isPersisted()) {
      return this.t(".edit_element", {name: surveyStepElement.name()})
    }
  }

  onElementTypeChanged = (args) => this.setState({elementType: digg(args, "options", 0, "value")})

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {surveyStepElement} = this.tt

    if (surveyStepElement.isNewRecord()) {
      formData.append("survey_step_element[new_id]", surveyStepElement.id())
    }

    try {
      await surveyStepElement.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_element_was_saved"))
      AppHistory.push(Routes.adminSurveyStepElementPath(surveyStepElement.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
