import {NewsletterTemplate} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: NewsletterTemplatesSelectWithContainer, withMemo: ComponentsNewsletterTemplatesSelect} = selectComponent({
  className: "components--newsletter-templates--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || NewsletterTemplate

    query = query
      .ransack({s: "position asc"})
      .select({NewsletterTemplate: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const newsletterTemplates = await query.toArray()

    return newsletterTemplates.map((newsletterTemplate) => ({
      text: newsletterTemplate.name(),
      value: newsletterTemplate.id()
    }))
  }
})

export default ComponentsNewsletterTemplatesSelect
export {NewsletterTemplatesSelectWithContainer}
