import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import {ClassStep} from "models"
import ClassStepsTable from "components/class-steps/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminClassStepsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.class_steps.index"})

    this.canCan = useCanCan(() => [[ClassStep, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="class-steps"
        className="routes--admin-class-steps--index"
        headerTitle={ClassStep.modelName().human({count: 2})}
      >
        <ClassStepsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", ClassStep) &&
          <Button
            className="new-class-step-button"
            icon="plus"
            label={t(".add_new_class_step")}
            to={Routes.newAdminClassStepPath()}
          />
        }
      </>
    )
  }
}))
