import {SchoolClassGroup} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: SchoolClassGroupsSelectWithContainer, withMemo: ComponentsSchoolClassGroupsSelect} = selectComponent({
  className: "components--school-class-groups--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || SchoolClassGroup

    query = query
      .ransack({s: "position asc"})
      .select({SchoolClassGroup: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const schoolClassGroups = await query.toArray()

    return schoolClassGroups.map((schoolClassGroup) => ({
      text: schoolClassGroup.name(),
      value: schoolClassGroup.id()
    }))
  }
})

export default ComponentsSchoolClassGroupsSelect
export {SchoolClassGroupsSelectWithContainer}
