import React, {useCallback} from "react"
import {Content} from "models"
import ContentsEditor from "components/contents/editor"
import {useForm} from "@kaspernj/api-maker/build/form"
import {v4 as uuidv4} from "uuid"

export default {
  edit: {
    attributes: [
      {attribute: "identifier"},
      {
        attribute: "content",
        content: ({inputProps, onChangeValue}) => {
          const {model: pageContent} = inputProps
          const form = useForm()
          const onChange = useCallback((newValue) => {
            form.setValue("contents_attributes[0][body]", newValue)
          }, [onChangeValue])

          return (
            <ContentsEditor
              attributeName="content"
              inputProps={{
                id: "page_content_content",
                label: Content.modelName().human()
              }}
              name="contents_attributes[0]"
              onChange={onChange}
              resource={pageContent}
            />
          )
        }
      }
    ],
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    },
    preload: ["contents.absolute_containers", "contents.text_keys.text_values"],
    select: {
      Content: ["attributeName", "body", "id"],
      ContentAbsoluteContainer: ["id"],
      TextKey: ["attributeName", "id", "key"],
      TextValue: ["id", "locale", "value"]
    }
  }
}
