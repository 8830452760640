import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import countryCodes from "./country-codes.json"
import CountryFlag from "react-native-country-flag"
import memo from "set-state-compare/src/memo"
import React from "react"
import {Text} from "shared/base"
import UtilsSelect from "components/utils/select"
import {View} from "react-native"

const countryOptions = countryCodes.map((data) => ({
  content: () => (
    <View style={{flexDirection: "row", alignItems: "center"}}>
      <CountryFlag isoCode={data.code} size={15} style={{marginRight: 5}} />
      <Text numberOfLines={1}>
        {data.name}
      </Text>
      <Text style={{marginLeft: "auto"}}>
        {data.dial_code}
      </Text>
    </View>
  ),
  currentContent: () => (
    <View style={{flexDirection: "row", alignItems: "center"}}>
      <CountryFlag isoCode={data.code} size={15} style={{marginRight: 5}} />
      <Text style={{marginLeft: "auto"}}>
        {data.dial_code}
      </Text>
    </View>
  ),
  code: data.code,
  dialCode: data.dial_code,
  name: data.name,
  text: `${data.name} ${data.dial_code}`,
  value: data.code
}))

export {countryOptions}

export default memo(shapeComponent(class UtilsCountriesSelect extends ShapeComponent {
  render() {
    return (
      <UtilsSelect options={countryOptions} {...this.props} />
    )
  }
}))
