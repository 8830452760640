import {ContactType} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectContactTypes,
  withInputContainer: ContactTypesSelectWithContainer,
  withMemo: ComponentsContactTypesSelect
} = selectComponent({
  className: "components--contact-types--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ContactType

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({ContactType: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const contactTypes = await query.toArray()

    return contactTypes.map((contactType) => ({
      text: contactType.name(),
      value: contactType.id()
    }))
  }
})

export default ComponentsContactTypesSelect
export {AdminSelectContactTypes, ContactTypesSelectWithContainer}
