import {Locale} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: LocalesSelectWithContainer, withMemo: ComponentsLocalesSelect} = selectComponent({
  className: "components--locales--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Locale

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Locale: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_or_locale_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const locales = await query.toArray()

    return locales.map((locale) => ({
      locale,
      text: locale.name(),
      value: locale.id()
    }))
  }
})

export default ComponentsLocalesSelect
export {LocalesSelectWithContainer}
