import {Course, SchoolClass, SchoolClassCheckIn, TeamSurvey} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BrainBreakButton from "components/user/school-classes/digital-class-room/brain-break-button"
import Button from "components/inputs/button"
import ChallengeButton from "components/user/school-classes/digital-class-room/challenge-button"
import classStepsDogImage from "components/class-steps/dog-image"
import ComingSoon from "components/coming-soon"
import {dig} from "diggerize"
import I18n from "shared/i18n"
import Instructions from "components/user/school-classes/digital-class-room/instructions"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import MoodAverage from "components/score-factor-groups/mood-average"
import Recommended from "components/user/school-classes/digital-class-room/recommended"
import Routes from "shared/routes"
import SchoolClassesCreateCheckInButton from "components/school-classes/create-check-in-button"
import setLayout from "shared/set-layout"
import Strategies from "components/user/school-classes/digital-class-room/strategies"
import TopScoreFactor from "components/user/insights/top-score-factor"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"

const RecommendedCourse = memo(shapeComponent(class RecommendedCourse extends ShapeComponent {
  render() {
    setLayout("user")

    const {course, identifier, schoolClass, schoolClassCourse} = this.props
    const {t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})

    return (
      <ComingSoon active={!course}>
        <div className="col-span-3 col-span-lg-1">
          {schoolClass &&
            <>
              <Recommended
                className={`recommended-course-${identifier}`}
                course={course}
                schoolClass={schoolClass}
                schoolClassCourse={schoolClassCourse}
              />
              <div className="mt-1">
                {identifier == "effort" &&
                  <Link className="text-black" to={Routes.userSchoolClassAvailableCoursesPath(schoolClass.id())}>
                    {t(".view_all_effort_courses_here")}
                  </Link>
                }
                {identifier == "culture" &&
                  <Link className="text-black" to={Routes.userSchoolClassAvailableCoursesPath(schoolClass.id())}>
                    {t(".view_all_culture_courses_here")}
                  </Link>
                }
              </div>
            </>
          }
        </div>
      </ComingSoon>
    )
  }
}))

export default memo(shapeComponent(class RoutesUserSchoolClassesDigitalClassRoom extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})
    const params = useParams()
    const {schoolClass, schoolClassId, schoolClassNotFound} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["createCheckIn", "invite"]
      },
      match: {params},
      preload: ["class_step"],
      select: {
        ClassStep: ["primarySchoolStep"],
        SchoolClass: ["id", "interpretedName", "schoolClassCheckInsCount", "schoolId"]
      }
    })

    this.setInstance({schoolClass, schoolClassId, schoolClassNotFound, t})
    this.useStates({
      lastSchoolClassCheckIn: undefined,
      lastSchoolClassCheckInLoaded: false,
      lastSchoolClassCheckInTeamSurveysQuery: undefined,
      recommendedCourses: undefined,
      recommendedCoursesLoaded: false,
      schoolClassCheckInsCount: undefined
    })

    useMemo(() => {
      if (schoolClass) {
        this.loadRecommendedCourses()
      } else {
        // School class is removed - like when signing out
        this.setState({recommendedCourses: undefined, recommendedCoursesLoaded: false})
      }
    }, [schoolClass?.id()])

    useMemo(() => {
      this.loadLastSchoolClassCheckIn()
      this.countSchoolClassCheckIns()
    }, [schoolClass?.id()])

    useFrontLayout()?.setState({
      headTitle: t(".digital_class_room_for_class", {class_name: schoolClass?.interpretedName()})
    })
  }

  async loadRecommendedCourses() {
    const query = await Course.ransack().select({Course: ["backgroundImageUrl", "courseModulesCount", "id", "name"]})
    const result = await this.tt.schoolClass.digitalClassRoomCourses({query})
    const recommendedCourses = []

    for (const {course_id: courseId, identifier, school_class_course: schoolClassCourse} of result.courses) {
      const course = result.courses_collection.find((course) => course.id() == courseId)

      recommendedCourses.push({course, identifier, schoolClassCourse})
    }

    this.setState({recommendedCourses, recommendedCoursesLoaded: true})
  }

  async countSchoolClassCheckIns() {
    const {schoolClassId} = this.tt
    const schoolClassCheckInsCount = await SchoolClassCheckIn
      .ransack({school_class_id_eq: schoolClassId})
      .count()

    this.setState({schoolClassCheckInsCount})
  }

  async loadLastSchoolClassCheckIn() {
    const lastSchoolClassCheckIn = await SchoolClassCheckIn
      .ransack({
        s: "created_at DESC",
        school_class_id_eq: this.tt.schoolClassId,
        state_eq: "closed"
      })
      .select({SchoolClassCheckIn: ["createdAt", "id"]})
      .first()

    let lastSchoolClassCheckInTeamSurveysQuery

    if (lastSchoolClassCheckIn) {
      lastSchoolClassCheckInTeamSurveysQuery = TeamSurvey.ransack({
        preview_false: true,
        school_class_check_in_id_eq: lastSchoolClassCheckIn.id(),
        state_eq: "completed"
      })
    }

    this.setState({
      lastSchoolClassCheckIn,
      lastSchoolClassCheckInLoaded: true,
      lastSchoolClassCheckInTeamSurveysQuery
    })
  }

  render() {
    const {schoolClass} = this.tt
    const {lastSchoolClassCheckIn, recommendedCourses, recommendedCoursesLoaded} = this.s
    const moodCircleActive = Boolean(lastSchoolClassCheckIn)

    return (
      <div
        className="routes--user--school-classes--digital-class-room"
        data-last-school-class-check-in-id={this.s.lastSchoolClassCheckIn?.id()}
        data-recommended-courses-loaded={recommendedCoursesLoaded}
        data-school-class-loaded={Boolean(schoolClass)}
      >
        <div className="grid grid-gap-10 grid-cols-3" style={{width: "100%", marginTop: "70px"}}>
          <Instructions />
          <div className="col-span-3 col-span-xl-1 action-container">
            {schoolClass?.can("createCheckIn") &&
              <SchoolClassesCreateCheckInButton schoolClass={schoolClass} />
            }
            {schoolClass &&
              <>
                <ChallengeButton className="ml-4" schoolClass={schoolClass} />
                <BrainBreakButton className="ml-4" schoolClass={schoolClass} />
              </>
            }
          </div>
          <div className="col-span-3 col-span-xl-1 mood-column-with-dog">
            <div style={{position: "relative"}}>
              {this.dogImage()}
            </div>
            <MoodAverage
              actions={this.moodActions()}
              defaultAverage={moodCircleActive ? undefined : 3.0}
              subTitle={I18n.strftime("%A d. %e. %B", lastSchoolClassCheckIn?.createdAt())}
              teamSurveysQuery={this.s.lastSchoolClassCheckInTeamSurveysQuery}
            />
          </div>
          <RecommendedCourse
            course={recommendedCourses && dig(recommendedCourses, 0, "course")}
            identifier={recommendedCourses && dig(recommendedCourses, 0, "identifier") || "effort"}
            schoolClass={schoolClass}
            schoolClassCourse={recommendedCourses && dig(recommendedCourses, 0, "schoolClassCourse")}
          />
          <RecommendedCourse
            course={recommendedCourses && dig(recommendedCourses, 1, "course")}
            identifier={recommendedCourses && dig(recommendedCourses, 1, "identifier") || "culture"}
            schoolClass={schoolClass}
            schoolClassCourse={recommendedCourses && dig(recommendedCourses, 1, "schoolClassCourse")}
          />
          <div className="col-span-3 col-span-lg-1">
            {this.s.lastSchoolClassCheckInLoaded &&
              <ComingSoon active={this.s.schoolClassCheckInsCount !== undefined && this.s.schoolClassCheckInsCount == 0}>
                <div className="grid grid-cols-2" style={{width: "100%"}}>
                  {this.positiveColumn()}
                  {this.negativeColumn()}
                </div>
              </ComingSoon>
            }
          </div>
          <div className="col-span-3 col-span-xl-2">
            {schoolClass &&
              <Strategies schoolClass={schoolClass} />
            }
          </div>
        </div>
      </div>
    )
  }

  dogImage() {
    if (this.tt.schoolClass) {
      const dogImage = classStepsDogImage(this.tt.schoolClass.classStep())

      return (
        <div
          className="dog-image"
          style={{
            backgroundImage: `url('${dogImage}')`
          }}
        />
      )
    }
  }

  moodActions() {
    if (this.tt.schoolClass) {
      return (
        <Button
          label={I18n.t("js.user.school_classes.digital_class_room.insights")}
          primary
          to={Routes.userSchoolClassInsightsPath(this.tt.schoolClass?.id())}
        />
      )
    }
  }

  positiveColumn() {
    return (
      <div className="col-span-1 top-positive-column">
        <div style={{display: "flex", justifyContent: "center"}}>
          <i className="fa fa-fw fa-thumbs-up top-positive-negative-icon" />
        </div>
        <div className="top-positive-negative-label">
          {this.t(".top_positive")}
        </div>
        <div className="top-score-factor-name-container">
          <TopScoreFactor
            card={false}
            mode="positive"
            style={{
              marginRight: 5,
              color: "#fff",
              fontSize: 25,
              fontWeight: "bold"
            }}
            teamSurveysQuery={this.s.lastSchoolClassCheckInTeamSurveysQuery}
          />
        </div>
      </div>
    )
  }

  negativeColumn() {
    return (
      <div className="col-span-1 top-negative-column">
        <div style={{display: "flex", justifyContent: "center"}}>
          <i className="fa fa-fw fa-thumbs-down top-positive-negative-icon" />
        </div>
        <div className="top-positive-negative-label">
          {this.t(".top_negative")}
        </div>
        <div className="top-score-factor-name-container">
          <TopScoreFactor
            card={false}
            mode="negative"
            style={{
              marginLeft: 5,
              color: "#fff",
              fontSize: 25,
              fontWeight: "bold"
            }}
            teamSurveysQuery={this.s.lastSchoolClassCheckInTeamSurveysQuery}
          />
        </div>
      </div>
    )
  }
}))
