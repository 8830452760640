import React, {useMemo} from "react"
import {SchoolClassGroup, SchoolClassGroupClassStep} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/school-class-groups/nav"
import Routes from "shared/routes"
import SchoolClassGroupClassStepsTable from "components/school-class-group-class-steps/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminSchoolClassGroupClassSteps extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_class_groups.class_steps"})
    const {schoolClassGroup, schoolClassGroupId} = useModel(SchoolClassGroup, {match: this.props.match})

    this.setInstance({
      canCan: useCanCan(() => [[SchoolClassGroupClassStep, ["create"]]]),
      schoolClassGroup,
      schoolClassGroupId,
      schoolClassGroupClassStepsQuery: useMemo(
        () => SchoolClassGroupClassStep.ransack({
          school_class_group_id_eq: schoolClassGroupId
        }),
        []
      ),
      t
    })
  }

  render() {
    const {schoolClassGroup} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="school-class-groups"
        className="routes--admin--school-class-groups--school-class-roles"
        headerTitle={SchoolClassGroup.humanAttributeName("classSteps")}
      >
        {schoolClassGroup &&
          <Nav active="class-steps" className="mb-4" schoolClassGroup={schoolClassGroup} />
        }
        <SchoolClassGroupClassStepsTable
          adminLayout
          collection={this.tt.schoolClassGroupClassStepsQuery}
        />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", SchoolClassGroupClassStep) &&
          <Button
            className="new-school-class-group-class-step-button"
            icon="plus"
            label={t(".add_new_class_step")}
            to={Routes.newAdminSchoolClassGroupClassStepPath({school_class_group_class_step: {school_class_group_id: this.tt.schoolClassGroupId}})}
          />
        }
      </>
    )
  }
}))
