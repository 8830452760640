import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import React from "react"
import {SchoolClass} from "models"
import SchoolClassesInviteForm from "components/school-class-roles/invite-form"

export default memo(shapeComponent(class ComponentsSchoolClassesCardAddClassRoleCircleButton extends ShapeComponent {
  static propTypes = {
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    this.useStates({
      showNewRoleModal: false
    })
  }

  render() {
    const {className, schoolClass, ...restProps} = this.props

    return (
      <>
        {this.s.showNewRoleModal &&
          <Modal className="send-invitation-modal" onRequestClose={this.tt.onRequestCloseNewRoleModal}>
            <SchoolClassesInviteForm onInviteSent={this.tt.onInviteSent} schoolClass={schoolClass} />
          </Modal>
        }
        <a
          className={classNames("components--school-classes--card--add-class-role-circle-button", className)}
          href="#"
          onClick={this.tt.onAddClassRoleClicked}
          {...restProps}
        >
          <i className="fa fa-plus" />
        </a>
      </>
    )
  }

  onAddClassRoleClicked = (e) => {
    e.preventDefault()
    this.setState({showNewRoleModal: true})
  }

  onInviteSent = () => this.setState({showNewRoleModal: false})
  onRequestCloseNewRoleModal = () => this.setState({showNewRoleModal: false})
}))
