import {Course, SchoolClass, SchoolClassCourse} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import CourseModuleBox from "components/user/school-class-courses/show/course-module-box"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useHasUnicorn from "components/subscriptions/use-has-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassCoursesShow extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.user.school_class_courses.show"})
    const currentUser = useCurrentUser()
    const params = useParams()
    const queryParams = useQueryParams()
    const {course} = useModel(Course, {
      cacheArgs: [currentUser?.id()],
      match: {params},
      preload: ["course_modules"],
      select: {
        Course: ["backgroundImageUrl", "description", "id", "name"],
        CourseModule: ["appetizer", "expectedMinutes", "id", "name", "position"]
      }
    })
    const {schoolClass: loadedSchoolClass} = useModel(SchoolClass, {
      active: Boolean(queryParams.school_class_id),
      cacheArgs: [currentUser?.id()],
      loadByQueryParam: ({queryParams}) => queryParams.school_class_id,
      select: {
        SchoolClass: ["id", "schoolId"]
      }
    })
    const {schoolClassCourse} = useModel(SchoolClassCourse, {
      active: Boolean(queryParams.school_class_course_id),
      cacheArgs: [currentUser?.id()],
      loadByQueryParam: ({queryParams}) => queryParams.school_class_course_id,
      preload: ["school_class", "school_class_course_modules.team_survey.survey.steps"],
      select: {
        SchoolClass: ["id", "schoolId"],
        SchoolClassCourse: ["id", "schoolClassId"],
        SchoolClassCourseModule: ["courseModuleId", "id", "state"],
        Survey: ["id"],
        SurveyStep: ["id", "position"],
        TeamSurvey: ["id", "preview"]
      }
    })

    const schoolClass = loadedSchoolClass || schoolClassCourse?.schoolClass()
    const {hasUnicorn} = useHasUnicorn({schoolClassId: schoolClass?.id(), user: currentUser})

    this.setInstance({
      course,
      currentUser,
      hasUnicorn,
      locale,
      schoolClass,
      schoolClassCourse,
      t
    })

    const topLeft = useMemo(() => schoolClass && (
      <Link className="text-white" to={Routes.userSchoolClassDigitalClassRoomPath(schoolClass.id())}>
        <FontAwesomeIcon name="chevron-left" style={{marginRight: 5, color: "#fff"}} />
        {t(".go_to_the_space_of_the_class")}
      </Link>
    ), [locale, schoolClass?.id()])

    useFrontLayout()?.setState({
      background: course?.backgroundImageUrl(),
      headerStyle: {color: "#fff"},
      headerTitle: course?.name() || Course.modelName().human(),
      headerTitleCentered: true,
      requireUser: true,
      schoolClass,
      topLeft,
      unicornTrialTopBar: false
    })
  }

  render() {
    const {course, hasUnicorn, schoolClass, schoolClassCourse} = this.tt

    const sortedCourseModules = course?.courseModules()?.loaded()?.sort((courseModule1, courseModule2) =>
      courseModule1.position() - courseModule2.position()
    )

    return (
      <View dataSet={{route: "user--courses--show"}}>
        {!hasUnicorn && schoolClass &&
          <UnicornUpgradeOverlay schoolClass={schoolClass} />
        }
        {hasUnicorn && course?.hasDescription() &&
          <CkeditorPresentation html={course.description()} style={{color: "#fff", textAlign: "center"}} />
        }
        <div className="grid grid-cols-lg-4 grid-gap-10 course-modules-grid">
          {hasUnicorn && sortedCourseModules?.map((courseModule) =>
            <div className="col-span-1 course-module-box-container" key={courseModule.id()}>
              <CourseModuleBox course={course} courseModule={courseModule} schoolClass={schoolClass} schoolClassCourse={schoolClassCourse} />
            </div>
          )}
        </div>
      </View>
    )
  }
}))
