import formatNumber from "format-number"
import I18n from "shared/i18n"

export default function numberFormat(number, args) {
  const formatOptions = {
    decimal: I18n.t("number.currency.format.separator"),
    integerSeparator: I18n.t("number.currency.format.delimiter")
  }

  if (args?.decimals) number = number.toFixed(args.decimals)

  return formatNumber(formatOptions)(number)
}
