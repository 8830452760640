import {HoverMenu, HoverMenuItem} from "components/hover-menu"
import {Image, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import DeleteModal from "components/school-classes/delete-modal"
import EllipsisHSolid from "images/icons/ellipsis-v-solid.png"
import memo from "set-state-compare/src/memo"
import PlansSignUpModal from "components/plans/sign-up-modal"
import React from "react"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class OptionsButton extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.admin_card"})

    this.setInstance({t})
    this.useStates({
      showDeleteClassModal: false,
      showSignUpModal: false
    })
  }

  render() {
    const {schoolClass} = this.p

    return (
      <View style={{position: "absolute", zIndex: 1, right: 14}}>
        {this.s.showDeleteClassModal &&
          <DeleteModal onRequestClose={this.tt.onRequestCloseDeleteModal} schoolClass={schoolClass} />
        }
        {this.s.showSignUpModal &&
          <PlansSignUpModal onRequestClose={this.tt.onRequestClosePlansSignUpModal} schoolClass={schoolClass} />
        }
        <HoverMenu content={this.tt.hoverMenuContent}>
          <Image
            dataSet={{class: "admin-card--options-button", schoolClassId: schoolClass.id()}}
            source={EllipsisHSolid}
            style={{width: 20, height: 20, opacity: "15%"}}
          />
        </HoverMenu>
      </View>
    )
  }

  hoverMenuContent = () => {
    const {t} = this.tt
    const {schoolClass} = this.p

    return (
      <>
        <HoverMenuItem label={t(".go_to_woof_space")} to={Routes.userSchoolClassDigitalClassRoomPath(schoolClass.id())} />
        <HoverMenuItem label={t(".go_to_analysis")} to={Routes.userUnicornPath({school_classes: [schoolClass.id()]})} />
        <HoverMenuItem dataSet={{class: "add-teacher-option"}} label={t(".add_teacher")} to={Routes.newUserAdminSchoolClassRolePath(schoolClass.id())} />
        <HoverMenuItem label={t(".edit_users")} to={Routes.userAdminSchoolClassRolesPath(schoolClass.id())} />
        <HoverMenuItem label={t(".upgrade_to_paid_license")} onPress={this.tt.onUpgradeToPaidLicenseClicked} />
        <HoverMenuItem dataSet={{class: "delete-school-class-option"}} label={t(".delete_class")} onPress={this.tt.onDeleteSchoolClassPressed} />
      </>
    )
  }

  onDeleteSchoolClassPressed = () => this.setState({showDeleteClassModal: true})
  onRequestCloseDeleteModal = () => this.setState({showDeleteClassModal: false})
  onUpgradeToPaidLicenseClicked = () => this.setState({showSignUpModal: true})
  onRequestClosePlansSignUpModal = () => this.setState({showSignUpModal: false})
}))
