import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroup} from "models"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {v4 as uuidv4} from "uuid"
import withModel from "components/wooftech-with-model"
class RoutesAdminSchoolClassGroupsEdit extends PageComponent {
  static propTypes = {
    schoolClassGroup: PropTypes.instanceOf(SchoolClassGroup),
    schoolClassGroupId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {schoolClassGroup} = this.p

    return (
      <AdminLayout active="school-class-groups" className="routes--admin--school-class-groups--edit" headerTitle={this.headerTitle()}>
        {schoolClassGroup &&
          <form onSubmit={onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${SchoolClassGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={schoolClassGroup}
                />
              </React.Fragment>
            )}
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {schoolClassGroup} = this.p

    if (schoolClassGroup?.isNewRecord()) {
      return I18n.t("js.routes.admin.school_class_groups.edit.add_new_school_class_group")
    } else if (schoolClassGroup?.isPersisted()) {
      return I18n.t("js.routes.admin.school_class_groups.edit.edit_school_class_group", {name: schoolClassGroup.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {schoolClassGroup} = this.p

    if (schoolClassGroup.isNewRecord()) formData.append("school_class_group[id]", schoolClassGroup.id())

    try {
      await schoolClassGroup.saveRaw(formData, {form})
      FlashMessage.success(I18n.t("js.routes.admin.school_class_groups.edit.the_school_class_group_was_saved"))
      AppHistory.push(Routes.adminSchoolClassGroupPath(schoolClassGroup.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedSchoolClassGroupAttributes = ["id", "name"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default withModel(
  RoutesAdminSchoolClassGroupsEdit,
  SchoolClassGroup,
  {
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    },
    select: {
      SchoolClassGroup: selectedSchoolClassGroupAttributes
    }
  }
)
