import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
export default memo(shapeComponent(class ComponentsAdminLayoutMenuMenuItem extends ShapeComponent {
  static propTypes = {
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node
  }

  setup() {
    this.useStates({
      hover: false
    })
  }

  render() {
    const {active, children, className, icon, identifier, label, to, ...restProps} = this.props
    const {hover} = this.s
    const isActive = active === true || active == identifier
    const linkStyle = {
      display: "flex",
      width: "80%",
      alignItems: "center",
      marginTop: "6px",
      paddingTop: "10px",
      paddingRight: "14px",
      paddingBottom: "10px",
      paddingLeft: "14px",
      marginRight: "auto",
      marginLeft: "auto",
      textDecoration: "none"
    }
    const textStyle = {}
    const iconStyle = {
      marginRight: 4,
      fontSize: 12
    }

    if (isActive || this.s.hover) {
      linkStyle.color = "#b9b9bb"
      linkStyle.borderRadius = "7px"
      linkStyle.backgroundColor = "#323435"

      textStyle.color = "#b9b9bb"
      iconStyle.color = "#b9b9bb"
    } else {
      linkStyle.color = "#6f6f71"
      textStyle.color = "#6f6f71"
      iconStyle.color = "#6f6f71"
    }

    return (
      <Link
        dataSet={{
          active: isActive,
          class: className,
          component: "admin--layout--menu--menu-item",
          hover,
          identifier
        }}
        onPointerEnter={this.tt.onPointerEnter}
        onPointerLeave={this.tt.onPointerLeave}
        style={linkStyle}
        to={to || "#"}
        {...restProps}
      >
        <FontAwesomeIcon name={icon} style={iconStyle} />
        <Text style={textStyle}>
          {children || label}
        </Text>
      </Link>
    )
  }

  onPointerEnter = () => this.setState({hover: true})
  onPointerLeave = () => this.setState({hover: false})
}))
