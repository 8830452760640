import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn, ScoreFactorGroup, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import MoodAverage from "components/score-factor-groups/mood-average"
import PageContentsPresentation from "components/page-contents/presentation"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import ScoreFactorGroupsMoodBarChart from "components/score-factor-groups/mood-bar-chart"
import ScoreFactorGroupsTagCloud from "components/score-factor-groups/tag-cloud"
import useModel from "@kaspernj/api-maker/build/use-model"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassesSchoolClassCheckInsSlide1 extends ShapeComponent {
  static propTypes = propTypesExact({
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    schoolClassCheckIn: PropTypes.instanceOf(SchoolClassCheckIn).isRequired
  })

  setup() {
    const queryParams = useQueryParams()
    const {schoolClass, schoolClassCheckIn} = this.p
    const {scoreFactorGroup: tagCloudScoreFactorGroup} = useModel(ScoreFactorGroup, {
      query: ScoreFactorGroup.ransack({identifier_eq: "tag_cloud"}),
      select: {
        ScoreFactorGroup: ["id", "name"]
      }
    })

    this.setInstance({
      liquidVariables: useMemo(() => ({
        schoolClass,
        schoolClassCheckIn
      }), [schoolClass.id(), schoolClassCheckIn.id()]),
      schoolClassCheckInsQuery: useMemo(() => SchoolClassCheckIn.ransack({id_eq: schoolClassCheckIn.id()}), [schoolClassCheckIn.id()]),
      tagCloudScoreFactorGroup,
      teamSurveysQuery: useMemo(
        () => TeamSurvey
          .ransack({
            preview_false: true,
            school_class_check_in_id_eq: schoolClassCheckIn.id(),
            state_eq: "completed",
            team_school_class_id_eq: schoolClass.id()
          })
          .ransack(queryParams.team_surveys_q),
        [schoolClassCheckIn.id(), queryParams.team_surveys_q]
      )
    })
  }

  render() {
    const {schoolClass, schoolClassCheckIn} = this.p
    const {liquidVariables, tagCloudScoreFactorGroup, teamSurveysQuery} = this.tt

    return (
      <>
        <View dataSet={{component: "routes/user/school-classes/school-class-check-ins/slide-1"}} style={{flexDirection: "row", width: "100%"}}>
          <View style={{width: "25%"}}>
            <PageContentsPresentation identifier="user/school-classes/school-class-check-ins/insights/top-left" liquidVariables={liquidVariables} />
          </View>
          <View style={{width: "50%"}}>
            <PageContentsPresentation identifier="user/school-classes/school-class-check-ins/insights/top-center" liquidVariables={liquidVariables} />
          </View>
          <View style={{width: "25%"}}>
            <PageContentsPresentation identifier="user/school-classes/school-class-check-ins/insights/top-right" liquidVariables={liquidVariables} />
          </View>
        </View>
        <View style={{flexDirection: "row", width: "100%"}}>
          <View style={{width: "25%"}}>
            <MoodAverage
              style={{padding: 35}}
              subTitle={I18n.strftime("%A d. %e. %B", schoolClassCheckIn?.createdAt())}
              teamSurveysQuery={teamSurveysQuery}
            />
          </View>
          <View style={{width: "50%", paddingHorizontal: 40}}>
            {schoolClass &&
              <UtilsCard>
                <View style={{width: "calc(100% + 50px)", marginLeft: -50}}>
                  <ScoreFactorGroupsMoodBarChart schoolClassCheckInsQuery={this.tt.schoolClassCheckInsQuery} />
                </View>
              </UtilsCard>
            }
          </View>
          <View style={{width: "25%"}}>
            {tagCloudScoreFactorGroup &&
              <ScoreFactorGroupsTagCloud
                scoreFactorGroup={tagCloudScoreFactorGroup}
                teamSurveysQuery={teamSurveysQuery}
              />
            }
          </View>
        </View>
      </>
    )
  }
}))
