import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import {Challenge} from "models"
import ChallengesTable from "components/challenges/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminChallengesIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const canCan = useCanCan(() => [[Challenge, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.challenges.index"})

    this.setInstance({canCan, t})
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="challenges"
        className="routes--admin-challenges--index"
        headerTitle={Challenge.modelName().human({count: 2})}
      >
        <ChallengesTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", Challenge) &&
          <Button
            className="new-challenge-button"
            icon="plus"
            label={this.t(".add_new_challenge")}
            to={Routes.newAdminChallengePath()}
          />
        }
      </>
    )
  }
}))
