import {BrainBreak, ClassStep} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import SurveyLink from "components/surveys/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
const selectedBrainBreakAttributes = ["createdAt", "id", "name", "public", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedBrainBreakAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminBrainBreaksShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.brain_breaks.show"})
    const {brainBreak, brainBreakId} = useModel(BrainBreak, {
      abilities: {
        BrainBreak: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["brain_break_class_steps.class_step", "survey"],
      select: {
        BrainBreak: selectedBrainBreakAttributes,
        BrainBreakClassStep: ["id"],
        ClassStep: ["name"],
        Survey: ["id", "name"]
      }
    })

    this.setInstance({brainBreak, brainBreakId, t})
  }

  render() {
    const {brainBreak} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="brain-breaks"
        className="routes--admin--brain-breaks--show"
        headerTitle={brainBreak?.name()}
      >
        {brainBreak &&
          <>
            <AttributeRows attributes={["id", "public", "createdAt", "updatedAt"]} model={brainBreak} />
            <AttributeRow attribute={"survey"} model={brainBreak}>
              {brainBreak.survey() &&
                <SurveyLink adminLayout survey={brainBreak.survey()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${BrainBreak.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={brainBreak}
                />
              </React.Fragment>
            )}
            <AttributeRow label={ClassStep.modelName().human({count: 2})}>
              {brainBreak.brainBreakClassSteps().map((brainBreakClassStep) =>
                <div key={brainBreakClassStep.id()}>
                  {brainBreakClassStep.classStep().name()}
                </div>
              )}
              {brainBreak.brainBreakClassSteps().loaded().length == 0 &&
                this.t("js.shared.none")
              }
            </AttributeRow>
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {brainBreak} = this.tt

    return (
      <AdminShowActions model={brainBreak} />
    )
  }
}))
