import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import React from "react"
import ReactToggle from "react-toggle"

export default memo(shapeComponent(class ComponentsToggleButton extends ShapeComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <ReactToggle
        className={classNames("components--toggle-button", className)}
        icons={false}
        {...restProps}
      />
    )
  }
}))
