import React, {useMemo} from "react"
import classNames from "classnames"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import {SurveyStepElement} from "models"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useInput from "@kaspernj/api-maker/build/use-input"
import {withInputContainer} from "components/inputs/input-container"
const ComponentsSurveyStepElementsElementTypeSelect = (props) => {
  const {inputProps, restProps} = useInput({props})
  const {ref, ...restInputProps} = inputProps
  const {className, ...actualRestProps} = restProps
  const {locale} = useI18n({namespace: "js.components.survey_step_elements.element_type_select"})
  const localeOptions = useMemo(
    () => TranslatedCollections.get(SurveyStepElement, "element_type").map(({translation, value}) => ({text: translation, value})),
    [locale]
  )

  return (
    <HayaSelect
      className={classNames("components--survey-step-elements--element-type-select", className)}
      options={localeOptions}
      {...restInputProps}
      {...actualRestProps}
    />
  )
}

const ElementTypeSelectWithContainer = withInputContainer(memo(ComponentsSurveyStepElementsElementTypeSelect))

export default memo(ComponentsSurveyStepElementsElementTypeSelect)
export {ElementTypeSelectWithContainer}
