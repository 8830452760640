import memo from "set-state-compare/src/memo"
import React from "react"
import {View} from "react-native"
const WrappedList = ({style, ...restProps}) => {
  const viewStyle = Object.assign({flex: 1, flexDirection: "row", flexWrap: "wrap"}, style)

  return (
    <View style={viewStyle} {...restProps} />
  )
}

export default memo(WrappedList)
