import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import {CourseGroup} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import React from "react"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedCourseGroupAttributes = ["createdAt", "id", "identifier", "name", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedCourseGroupAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminCourseGroupsShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {courseGroup, courseGroupId} = useModel(CourseGroup, {
      abilities: {
        CourseGroup: ["destroy", "edit"]
      },
      match: {params},
      select: {
        CourseGroup: selectedCourseGroupAttributes
      }
    })

    this.setInstance({courseGroup, courseGroupId})
    useAdminLayout()?.setState({actions: this.actions(), active: "course-groups", headerTitle: courseGroup?.name()})
  }

  render() {
    const {courseGroup} = this.tt

    return (
      <View dataSet={{route: "admin--course-groups--show"}}>
        {courseGroup &&
          <>
            <Nav active="general" courseGroup={courseGroup} style={{marginBottom: 15}} />
            <AttributeRows attributes={["id", "identifier", "createdAt", "updatedAt"]} model={courseGroup} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${CourseGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={courseGroup}
                />
              </React.Fragment>
            )}
          </>
        }
      </View>
    )
  }

  actions() {
    const {courseGroup} = this.tt

    return (
      <AdminShowActions model={courseGroup} />
    )
  }
}))
