import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {EmailTemplatePreviewLiquidVariable} from "models"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import Select from "components/inputs/select"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsButton from "components/utils/button"
import UtilsCheckbox from "components/utils/checkbox"
import UtilsInput from "components/utils/input"
import {View} from "react-native"
export default memo(shapeComponent(class LiquidVariable extends ShapeComponent {
  static propTypes = propTypesExact({
    liquidVariable: PropTypes.instanceOf(EmailTemplatePreviewLiquidVariable).isRequired,
    liquidVariableIndex: PropTypes.number.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.email_templates.previews.edit.liquid_variable"})
    const {liquidVariable} = this.p

    this.t = t
    this.useStates({
      automatic: liquidVariable.automatic(),
      destroyed: liquidVariable.markedForDestruction(),
      name: liquidVariable.name(),
      variableType: liquidVariable.variableType() || "string"
    })
  }

  render() {
    const {t} = this.tt
    const {liquidVariable, liquidVariableIndex} = this.p
    const {automatic, variableType} = this.s
    const variableTypeOptions = TranslatedCollections
      .get(EmailTemplatePreviewLiquidVariable, "variable_type")
      .map(({translation, value}) => ({text: translation, value}))

    return (
      <View dataSet={{component: "routes--admin--email-templates--previews--edit--liquid-variable", liquidVariableId: liquidVariable.id()}}>
        {liquidVariable.markedForDestruction() && liquidVariable.isPersisted() &&
          <input
            className="liquid-variable-destroyed-input"
            name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][_destroy]`}
            type="hidden"
            value="1"
          />
        }
        {!liquidVariable.markedForDestruction() && liquidVariable.isNewRecord() &&
          <input
            name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][new_id]`}
            type="hidden"
            value={liquidVariable.id()}
          />
        }
        {liquidVariable.isPersisted() &&
          <input
            name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][id]`}
            type="hidden"
            value={liquidVariable.id()}
          />
        }
        {!liquidVariable.markedForDestruction() &&
          <>
            <input
              name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][name]`}
              type="hidden"
              value={this.s.name}
            />
            <input
              name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][automatic]`}
              type="hidden"
              value={automatic ? 1 : 0}
            />
            <UtilsInput
              dataSet={{class: "name-input"}}
              label={EmailTemplatePreviewLiquidVariable.humanAttributeName("name")}
              onChangeText={this.setStates.name}
              style={{marginBottom: 10}}
              value={this.s.name}
            />
            <Select
              attribute="variableType"
              defaultValue={variableType}
              id={`liquid_variable_type_${liquidVariableIndex}`}
              model={liquidVariable}
              name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][variable_type]`}
              onChange={this.tt.onVariableTypeChanged}
              options={variableTypeOptions}
              type="hayaSelect"
            />
            {variableType == "droppable_record" &&
              <>
                <Input
                  attribute="droppableRecordType"
                  model={liquidVariable}
                  name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][droppable_record_type]`}
                />
                <Input
                  attribute="droppableRecordId"
                  model={liquidVariable}
                  name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][droppable_record_id]`}
                />
              </>
            }
            {variableType == "string" &&
              <Input
                attribute="stringValue"
                id={`liquid_variable_${liquidVariable.name()}_string_value`}
                model={liquidVariable}
                name={`email_template_preview[liquid_variables_attributes][${liquidVariableIndex}][string_value]`}
              />
            }
            <UtilsCheckbox
              checked={automatic}
              label={EmailTemplatePreviewLiquidVariable.humanAttributeName("automatic")}
              onChangeChecked={this.setStates.automatic}
              style={{marginTop: 10, marginBottom: 10}}
            />
            <UtilsButton
              color="red"
              dataSet={{class: "delete-liquid-varaiable-button"}}
              onPress={this.tt.onDestroyedPressed}
              title={t("js.shared.delete")}
            />
          </>
        }
      </View>
    )
  }

  onDestroyedPressed = () => {
    const {liquidVariable} = this.p

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    liquidVariable.markForDestruction()
    this.setState({destroyed: true})
  }

  onVariableTypeChanged = ({options}) => {
    const newVariableType = options && options[0]?.value

    this.setState({variableType: newVariableType})
  }
}))
