import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import React, {useMemo} from "react"
import {ScoreFactor, ScoreFactorGroup} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import InfoIcon from "components/user/unicorn/info-icon"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import SanitizedHtml from "shared/sanitized-html"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsScoreFactorMoodDevelopmentLineChart extends ShapeComponent {
  static defaultProps = {
    classStepIds: null,
    dateFrom: null,
    dateTo: null,
    schoolClassIds: null,
    schoolClassGroupIds: null,
    schoolIds: null
  }

  static propTypes = propTypesExact({
    className: PropTypes.string,
    classStepIds: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    schoolClassIds: PropTypes.array,
    schoolClassGroupIds: PropTypes.array,
    schoolIds: PropTypes.array
  })

  setup() {
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const {t} = useI18n({namespace: "js.components.score_factors.mood_development_line_chart"})
    const filterQueryParams = [classStepIds?.join("--"), dateFrom, dateTo, schoolClassIds?.join("--"), schoolClassGroupIds?.join("--"), schoolIds?.join("--")]
    const {scoreFactorGroup: moodScoreFactorGroup} = useModel(ScoreFactorGroup, {
      query: ScoreFactorGroup.ransack({identifier_eq: "mood"}),
      select: {
        ScoreFactorGroup: ["id", "name"]
      }
    })

    this.setInstance({
      moodScoreFactorGroup,
      queryParams: useQueryParams(),
      t
    })
    this.useStates({chartData: undefined})

    useMemo(() => {
      if (this.hasRequiredParams()) {
        this.loadChartData()
      } else {
        this.unloadChartData()
      }
    }, filterQueryParams)
  }

  render() {
    const {moodScoreFactorGroup, t} = this.tt
    const {className} = this.props

    return (
      <UtilsCard
        dataSet={{
          class: className,
          component: "score-factor/mood-development-line-chart"
        }}
      >
        <View>
          <InfoIcon style={{marginLeft: "auto"}}>
            <SanitizedHtml html={t(".info_box")} />
          </InfoIcon>
        </View>
        <View style={{alignItems: "center"}}>
          <Text style={{marginBottom: 32, fontSize: 32, textAlign: "center"}}>
            {t(".mood")}
          </Text>
        </View>
        <View style={{height: 300}}>
          {this.s.chartData &&
            <ResponsiveContainer height="100%" width="100%">
              <LineChart
                data={this.s.chartData}
                height={300}
                margin={{
                  bottom: 5,
                  left: 20,
                  right: 30,
                  top: 5
                }}
                width={500}
              >
                <CartesianGrid horizontal={false} strokeDasharray="1 0" />
                <XAxis dataKey="date_label" />
                <YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} type="number" />
                <Line
                  activeDot={{stroke: "#205a96"}}
                  connectNulls
                  dataKey={moodScoreFactorGroup?.name()}
                  dot={false}
                  stroke="#4c93ff"
                  strokeWidth={5}
                  type="monotone"
                />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          }
        </View>
      </UtilsCard>
    )
  }

  hasRequiredParams = () => {
    if (this.p.dateFrom && this.p.dateTo && (this.p.classStepIds || this.p.schoolIds || this.p.schoolClassIds || this.p.schoolClassGroupIds)) {
      return true
    }
  }

  loadChartData = async () => {
    await loading(this.t(".mood_development_line_chart"), async () => {
      const result = await ScoreFactor.moodDevelopmentChartData({
        date_from: this.p.dateFrom,
        date_to: this.p.dateTo,
        class_step_ids: this.p.classStepIds,
        school_ids: this.p.schoolIds,
        school_class_ids: this.p.schoolClassIds,
        school_class_group_ids: this.p.schoolClassGroupIds
      })
      const chartData = digg(result, "chart_data")

      this.setState({chartData})
    })
  }

  unloadChartData = () => this.setState({chartData: undefined})
}))
