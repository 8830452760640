import {EmailTemplate, EmailTemplatePreview} from "models"
import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import EmailTemplatePreviewLink from "components/email-template-previews/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default class ComponentsEmailTemplatePreviews extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--email-templates--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={EmailTemplatePreview}
        select={{EmailTemplatePreview: ["emailTemplateId", "id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.emailTemplatePreviewContent,
      label: EmailTemplate.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    }
  ]

  editModelPath = ({emailTemplatePreview}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminEmailTemplatePreviewPath(emailTemplatePreview.emailTemplateId(), emailTemplatePreview.id())
  }

  viewModelPath = ({emailTemplatePreview}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminEmailTemplatePreviewPath(emailTemplatePreview.emailTemplateId(), emailTemplatePreview.id())
  }

  emailTemplatePreviewContent = ({emailTemplatePreview}) => <EmailTemplatePreviewLink emailTemplatePreview={emailTemplatePreview} {...linkProps(this.props)} />
}
