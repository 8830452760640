import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import PlanLink from "components/plans/link"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import {Subscription} from "models"
import SubscriptionLink from "components/subscriptions/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSubscriptionsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.subscriptions.table"})

    this.baseQuery = useMemo(() => Subscription.ransack({select_number_of_completed_woofs: 1}), [])
    this.t = t
  }

  render() {
    const {baseQuery, columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--subscriptions--table", className)}
        collection={baseQuery}
        columns={columns}
        defaultParams={{s: "created_at desc"}}
        editModelPath={editModelPath}
        modelClass={Subscription}
        select={{
          Plan: ["id", "name"],
          Subscription: ["id", "resource", "translatedState"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      content: this.tt.idContent,
      sortKey: "id"
    },
    {
      content: this.tt.resourceContent,
      identifier: "resource",
      label: Subscription.humanAttributeName("resource"),
      sortKey: "resourceName"
    },
    {
      attribute: "name",
      content: this.tt.planContent,
      label: Subscription.humanAttributeName("plan"),
      path: ["plan"],
      sortKey: "planCurrentTranslationName"
    },
    {
      attribute: "state",
      content: this.tt.stateContent,
      sortKey: "state"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    },
    {
      attribute: "numberOfCompletedWoofs",
      label: this.t(".number_of_completed_woofs"),
      sortKey: "numberOfCompletedWoofs"
    }
  ]

  editModelPath = ({subscription}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSubscriptionPath(subscription.id())
  }

  viewModelPath = ({subscription}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSubscriptionPath(subscription.id())
  }

  idContent = ({subscription}) => <SubscriptionLink children={subscription.id()} subscription={subscription} {...linkProps(this.props)} />
  planContent = ({subscription}) => subscription.plan() && <PlanLink plan={subscription.plan()} {...linkProps(this.props)} />
  resourceContent = ({subscription}) => subscription.resource() && <ModelLink model={subscription.resource()} {...linkProps(this.props)} />
  stateContent = ({subscription}) => subscription.translatedState()
}))
