import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AdminSelect from "components/admin/select"
import {AdminSelectEmailTemplates} from "components/email-templates/select"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import FormDataObjectizer from "form-data-objectizer"
import getTimeZoneOffset from "shared/get-time-zone-offset"
import {incorporate} from "incorporator"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplate} from "models"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Text} from "shared/base"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedNewsletterTemplateAttributes = ["cron", "emailTemplateId", "id", "liquidSearch", "name", "sendAt", "state"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {locale, t} = useI18n({namespace: "js.routes.admin.newsletter_templates.edit"})
    const {newsletterTemplate} = useModel(NewsletterTemplate, {
      abilities: {
        NewsletterTemplate: ["edit"]
      },
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        NewsletterTemplate: selectedNewsletterTemplateAttributes
      }
    })

    this.setInstance({
      formRef: useRef(),
      locale,
      newsletterTemplate,
      t
    })
    this.useStates({
      form: null,
      liquidSearch: ""
    })

    useMemo(() => {
      this.setState({
        liquidSearch: newsletterTemplate?.liquidSearch() || ""
      })
    }, [newsletterTemplate?.id()])

    const headerTitle = useMemo(() => {
      if (this.tt.newsletterTemplate?.isNewRecord()) {
        return t(".add_new_newsletter_template")
      } else if (this.tt.newsletterTemplate?.isPersisted()) {
        return t(".edit_newsletter_template", {name: this.tt.newsletterTemplate.name()})
      }
    }, [locale, newsletterTemplate?.id()])

    useAdminLayout()?.setState({active: "newsletter-templates", headerTitle})
  }

  render() {
    const {locale, newsletterTemplate, t} = this.tt
    const defaultSendAt = useMemo(
      () => {
        if (!this.tt.newsletterTemplate?.sendAt()) return null

        const offset = getTimeZoneOffset()
        const defaultSendAt = new Date(this.tt.newsletterTemplate.sendAt().getTime() - (offset * 1000))

        return defaultSendAt
      },
      [newsletterTemplate?.id()]
    )

    const stateOptions = useMemo(
      () => TranslatedCollections.get(NewsletterTemplate, "state").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )

    return (
      <View dataSet={{route: "admin--newsletter-templates--edit"}}>
        {newsletterTemplate?.isPersisted() && !newsletterTemplate.can("edit") &&
          <Text dataSet={{class: "no-edit-ability"}}>
            {t("js.shared.no_edit_ability")}
          </Text>
        }
        {(newsletterTemplate?.isNewRecord() || newsletterTemplate?.can("edit")) &&
          <Form formRef={this.tt.formRef} onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  containerProps={{style: {marginBottom: 15}}}
                  label={`${NewsletterTemplate.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={newsletterTemplate}
                />
              </React.Fragment>
            )}
            <Input
              attribute="sendAt"
              defaultValue={defaultSendAt}
              model={newsletterTemplate}
              name="newsletter_template[send_at_without_time_zone]"
              type="datetime-local"
            />
            <AdminInput attribute="cron" containerProps={{style: {marginVertical: 15}}} model={newsletterTemplate} />
            <View style={{marginBottom: 15}}>
              <AdminSelectEmailTemplates
                attribute="emailTemplateId"
                label={NewsletterTemplate.humanAttributeName("emailTemplate")}
                model={newsletterTemplate}
              />
            </View>
            <AdminInput
              autoRows
              containerProps={{style: {marginBottom: 15}}}
              label={NewsletterTemplate.humanAttributeName("liquidSearch")}
              multiline
              name="newsletter_template[liquid_search]"
              onChangeText={this.setStates.liquidSearch}
              value={this.s.liquidSearch}
            />
            <View style={{marginBottom: 15}}>
              <AdminSelect
                attribute="state"
                model={newsletterTemplate}
                options={stateOptions}
              />
            </View>
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  onSubmit = async () => {
    const form = this.tt.formRef.current
    const formData = new FormData(form)
    const data = incorporate({}, this.s.form.asObject(), FormDataObjectizer.toObject(formData))

    if (this.tt.newsletterTemplate.isNewRecord()) {
      data.newsletter_template.new_id = this.tt.newsletterTemplate.id()
    }

    try {
      await this.tt.newsletterTemplate.saveRaw(data, {form})
      FlashMessage.success(this.t(".the_newsletter_template_was_saved"))
      AppHistory.push(Routes.adminNewsletterTemplatePath(this.tt.newsletterTemplate.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
