import {EmailTemplate, EmailTemplatePreview} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import EmailTemplatePreviewsTable from "components/email-template-previews/table"
import memo from "set-state-compare/src/memo"
import Nav from "routes/admin/email-templates/nav"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminEmailTemplatesPreviewsIndex extends ShapeComponent {
  static propTypes = {
    emailTemplate: PropTypes.instanceOf(EmailTemplate),
    emailTemplateId: PropTypes.string.isRequired
  }

  setup() {
    setLayout("admin")

    const canCan = useCanCan(() => [[EmailTemplatePreview, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.email_templates.previews.index"})
    const {emailTemplate, emailTemplateId} = useModel(EmailTemplate, {
      match: this.p.match,
      select: {
        EmailTemplate: ["id"]
      }
    })

    this.setInstance({canCan, emailTemplate, emailTemplateId, t})
    this.useStates({
      previewsCollection: () => EmailTemplatePreview.ransack({email_template_id_eq: emailTemplateId})
    })
  }

  render() {
    const {emailTemplate} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="email-templates"
        className="routes--admin--email-templates--previews--index"
        headerTitle={EmailTemplate.modelName().human({count: 2})}
      >
        {emailTemplate &&
          <Nav active="previews" className="mb-4" emailTemplate={emailTemplate} />
        }
        <EmailTemplatePreviewsTable adminLayout collection={this.s.previewsCollection} />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, emailTemplateId} = this.tt

    return (
      <>
        {canCan?.can("create", EmailTemplatePreview) &&
          <Button
            className="new-email-template-preview-button"
            icon="plus"
            label={this.t(".add_new_email_template_preview")}
            to={Routes.newAdminEmailTemplatePreviewPath(emailTemplateId)}
          />
        }
      </>
    )
  }
}))
