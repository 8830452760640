import React, {useMemo} from "react"
import Route, {useParams} from "@kaspernj/api-maker/build/router/route"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useWoofRouter} from "shared/woof-router"

export default memo(shapeComponent(class RoutesUserSchoolClasses extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.user.school_classes.digital_class_room"})
    const params = useParams()
    const {schoolClass, schoolClassId, schoolClassNotFound} = useModel(SchoolClass, {
      match: {params},
      select: {
        SchoolClass: ["id", "interpretedName", "schoolId"]
      }
    })

    this.woofRouter = useWoofRouter()
    this.setInstance({schoolClass, schoolClassId, schoolClassNotFound, t})

    useFrontLayout()?.setState({
      schoolClass,
      topRight: useMemo(() => this.topRight(), [schoolClass?.id()])
    })
  }

  render() {
    const {schoolClassNotFound, t} = this.tt

    return (
      <>
        {schoolClassNotFound &&
          <div className="school-class-not-found-container">
            {t(".the_school_class_couldnt_be_found")}
          </div>
        }
        {!schoolClassNotFound &&
          <Switch name="school-classes">
            <Route component="available-courses" exact path="available_courses" />
            <Route component="digital-class-room" exact path="digital_class_room" />
            <Route component="edit" exact path="edit" />
            <Route component="edit-name" exact path="edit_name" />
            <Route component="insights" exact path="insights" />
            <Route component="roles/invite" exact path="roles/invite" />
            <Route component="roles/index" exact path="roles" />
            <Route component="school-class-courses/index" exact path="school_class_courses" />
            <Route component="school-class-check-ins/insights" exact path="school_class_check_ins/:school_class_check_in_id/insights" />
            <Route component="show" exact />
            <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
          </Switch>
        }
      </>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)

  topRight() {
    const {schoolClass, t} = this.tt

    if (schoolClass?.hasInterpretedName()) {
      return (
        <Link
          className="go-to-digital-class-room-for-school-class-link"
          style={{fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap"}}
          to={Routes.userSchoolClassDigitalClassRoomPath(schoolClass.id())}
        >
          {schoolClass.interpretedName()}
        </Link>
      )
    }

    if (schoolClass) {
      return (
        <Button
          className="go-to-edit-name-page-for-school-class-button"
          label={t(".name_your_class")}
          primary
          to={Routes.editNameUserSchoolClassPath(schoolClass.id())}
        />
      )
    }
  }
}))
