import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import MergeModal from "components/schools/merge-modal"
import Routes from "shared/routes"
import {School} from "models"
import SchoolsTable from "components/schools/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminSchoolsIndex extends ShapeComponent {
  setup() {
    this.canCan = useCanCan(() => [[School, ["create"]]])

    const {locale, t} = useI18n({namespace: "js.routes.admin.schools.index"})
    const actions = useMemo(
      () => <>
        {this.canCan?.can("create", School) &&
          <Button className="new-school-button" icon="plus" label={t(".add_new_school")} to={Routes.newAdminSchoolPath()} />
        }
        <Button className="merge-schools-button" label={t("js.shared.merge")} onClick={this.tt.onMergeSchoolsClicked} />
      </>,
      [this.canCan, locale]
    )

    this.setInstance({t})
    this.useStates({
      showMergeModal: false
    })

    useAdminLayout()?.setState({actions, active: "schools", headerTitle: School.modelName().human({count: 2})})
  }

  render() {
    return (
      <div className="routes--admin-schools--index">
        {this.s.showMergeModal &&
          <MergeModal onRequestClose={this.tt.onRequestCloseMergeModal} />
        }
        <SchoolsTable adminLayout />
      </div>
    )
  }

  onMergeSchoolsClicked = () => {
    this.setState({showMergeModal: true})
  }

  onRequestCloseMergeModal = () => {
    this.setState({showMergeModal: false})
  }
}))
