import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {Subscription} from "models"
import SubscriptionsTable from "components/subscriptions/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"

export default memo(shapeComponent(class RoutesAdminSubscriptionsIndex extends ShapeComponent {
  setup() {
    this.canCan = useCanCan(() => [[Subscription, ["create"]]])

    useAdminLayout()?.setState({
      actions: this.actions(),
      active: "subscriptions",
      headerTitle: Subscription.modelName().human({count: 2})
    })
  }

  render() {
    return (
      <div className="routes--admin-subscriptions--index">
        <SubscriptionsTable adminLayout />
      </div>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", Subscription) &&
          <Button
            className="new-subscription-button"
            icon="plus"
            label={I18n.t("js.routes.admin.subscriptions.index.add_new_subscription")}
            to={Routes.newAdminSubscriptionPath()}
          />
        }
      </>
    )
  }
}))
