import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"

export default memo(shapeComponent(class ComponentsNavItem extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    identifier: PropTypes.string,
    label: PropTypes.node,
    to: PropTypes.string
  }

  render() {
    const {active, children, className, identifier, label, to, ...restProps} = this.props

    return (
      <Link
        className={classNames("components--nav--item", className)}
        data-active={active == identifier}
        data-identifier={identifier}
        to={to || "#"}
        {...restProps}
      >
        {label || children}
      </Link>
    )
  }
}))
