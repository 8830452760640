import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import {ScoreFactorGroup} from "models"
import ScoreFactorGroupsHalfCircleAverage from "components/score-factor-groups/half-circle-average"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class ComponentsScoreFactorGroupsMoodAverage extends ShapeComponent {
  setup() {
    const {scoreFactorGroup} = useModel(ScoreFactorGroup, {
      query: ScoreFactorGroup.ransack({identifier_eq: "mood"}),
      select: {
        ScoreFactorGroup: ["id", "name"]
      }
    })

    this.moodScoreFactorGroup = scoreFactorGroup
  }

  render() {
    const {moodScoreFactorGroup} = this.tt
    const {defaultAverage, ...restProps} = this.props

    return (
      <>
        {moodScoreFactorGroup &&
          <ScoreFactorGroupsHalfCircleAverage
            addition={-1}
            max={4.0}
            scoreFactorGroup={moodScoreFactorGroup}
            {...restProps}
          />
        }
      </>
    )
  }
}))
