import {Plan} from "models"
import selectComponent from "components/select-component"
const {withAdminSelectContainer: AdminSelectPlans, withInputContainer: PlansSelectWithContainer, withMemo: ComponentsPlansSelect} = selectComponent({
  className: "components--plans--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = (query?.clone() || Plan)
      .ransack({s: "current_translation_name asc"})
      .select({Plan: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_or_identifier_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const plans = await query.toArray()

    return plans.map((plan) => ({
      text: plan.name(),
      value: plan.id()
    }))
  }
})

export default ComponentsPlansSelect
export {AdminSelectPlans, PlansSelectWithContainer}
