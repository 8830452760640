import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {Country} from "models"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsCountriesLink extends ShapeComponent {
  static propTypes = {
    adminLayout: PropTypes.bool,
    className: PropTypes.string,
    superAdminLayout: PropTypes.bool,
    country: PropTypes.instanceOf(Country).isRequired,
    userLayout: PropTypes.bool
  }

  render() {
    const {adminLayout = false, children, className, country, superAdminLayout = false, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--countries--link", className)
    let to

    if (adminLayout || superAdminLayout) {
      to = Routes.superAdminPath({model: "Country", model_id: country.id()})
    } else {
      to = "#"
    }

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || country.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || country.name()}
      </span>
    )
  }
}))
