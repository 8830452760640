import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CloneButton from "components/notification-types/clone-button"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import {NotificationType} from "models"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedNotificationTypeAttributes = [
  "active",
  "createdAt",
  "id",
  "name",
  "notificationTypeActionsCount",
  "notificationTypeAuditTriggersCount",
  "updatedAt"
].concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminNotificationTypesShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {locale} = useI18n({namespace: "js.routes.admin.notification_types.show"})
    const params = useParams()
    const {notificationType, notificationTypeId} = useModel(NotificationType, {
      abilities: {
        NotificationType: ["clone", "edit", "destroy"]
      },
      match: {params},
      select: {
        NotificationType: selectedNotificationTypeAttributes
      }
    })

    const actions = useMemo(
      () => <>
        {notificationType?.can("clone") &&
          <CloneButton notificationType={notificationType} onCloned={this.tt.onNotificationTypeCloned} />
        }
        <AdminShowActions model={notificationType} />
      </>,
      [locale, notificationType?.id()]
    )

    this.setInstance({notificationType, notificationTypeId})
    useAdminLayout()?.setState({actions, active: "notification-types", headerTitle: notificationType?.name()})
  }

  render() {
    const {notificationType} = this.tt

    return (
      <View dataSet={{route: "admin--notification-types--show"}}>
        {notificationType &&
          <>
            <Nav active="general" className="mb-4" notificationType={notificationType} />
            <AttributeRows attributes={["id", "active", "createdAt", "updatedAt"]} model={notificationType} />
            {Locales.availableLocales().map((locale) =>
              <AttributeRow
                attribute={`name${inflection.camelize(locale)}`}
                key={locale}
                label={`${NotificationType.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={notificationType}
              />
            )}
          </>
        }
      </View>
    )
  }

  onNotificationTypeCloned = ({clonedNotificationType}) => AppHistory.push(Routes.adminNotificationTypePath(clonedNotificationType.id()))
}))
