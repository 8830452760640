import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import {ContactEmail} from "models"
import {ContactsSelectWithContainer} from "components/contacts/select"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UtilsButton from "components/utils/button"
import UtilsCheckbox from "components/utils/checkbox"
import UtilsInput from "components/utils/input"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactEmailsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.contact_emails.edit"})
    const params = useParams()
    const {contactEmail, contactEmailId} = useModel(ContactEmail, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        ContactEmail: ["contactId", "email", "id"]
      }
    })

    this.setInstance({
      contactEmail,
      contactEmailId,
      t
    })
    this.useStates({
      contactId: contactEmail?.contactId(),
      email: contactEmail?.email() || "",
      form: null,
      primary: contactEmail?.primary() || false
    })

    useMemo(() => {
      this.setState({
        contactId: contactEmail?.contactId(),
        email: contactEmail?.email() || "",
        primary: contactEmail?.primary() || false
      })
    }, [contactEmail?.id()])

    useAdminLayout()?.setState({active: "contacts", headerTitle: this.headerTitle()})
  }

  render() {
    const {contactEmail, onSubmit, t} = this.tt

    return (
      <View dataSet={{route: "admin--contact-emails--edit"}}>
        {contactEmail &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <ContactsSelectWithContainer
                attribute="contactId"
                id="contact_email_contact_id"
                label={ContactEmail.humanAttributeName("contact")}
                name="contact_email[contact_id]"
                onChange={this.tt.onChangeContact}
                values={this.s.contactId ? [this.s.contactId] : null}
              />
            </View>
            <UtilsInput
              dataSet={{class: "contact-email-email-input"}}
              label={ContactEmail.humanAttributeName("email")}
              name="contact_email[email]"
              onChangeText={this.setStates.email}
              style={{marginBottom: 15}}
              value={this.s.email}
            />
            <UtilsCheckbox
              checked={this.s.primary}
              dataSet={{class: "contact-email-primary"}}
              label={ContactEmail.humanAttributeName("primary")}
              name="contact_email[primary]"
              onChangeChecked={this.setStates.primary}
              style={{marginBottom: 15}}
            />
            <UtilsButton dataSet={{class: "save-email-button"}} primary submit title={t("js.shared.save")} />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {contactEmail} = this.tt

    if (contactEmail?.isNewRecord()) {
      return this.t(".add_new_contact_email")
    } else if (contactEmail?.isPersisted()) {
      return this.t(".edit_contact_email")
    }
  }

  onChangeContact = ({options}) => this.setState({contactId: dig(options, 0, "value")})

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {contactEmail} = this.tt

    if (contactEmail.isNewRecord()) {
      formData.contact_email.new_id = contactEmail.id()
    }

    try {
      await contactEmail.saveRaw(formData)
      FlashMessage.success(this.t(".the_contact_email_was_saved"))
      AppHistory.push(Routes.adminContactEmailsPath(contactEmail.contactId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
