import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import PlanLink from "components/plans/link"
import Routes from "shared/routes"
import SubscriptionLink from "components/subscriptions/link"
import {SubscriptionPeriod} from "models"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSubscriptionPeriodsShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {subscriptionPeriod, subscriptionPeriodId} = useModel(SubscriptionPeriod, {
      abilities: {
        SubscriptionPeriod: ["destroy", "edit"]
      },
      match: {params},
      onDestroyed: this.tt.onDestroyed,
      preload: ["subscription.plan"],
      select: {
        Plan: ["id", "name"],
        SubscriptionPeriod: ["createdAt", "endsAt", "id", "startsAt", "updatedAt"],
        Subscription: ["id", "name", "resource"]
      }
    })
    const subscription = subscriptionPeriod?.subscription()

    this.setInstance({subscription, subscriptionPeriod, subscriptionPeriodId})

    const actions = useMemo(() => <AdminShowActions model={subscriptionPeriod} />, [subscriptionPeriod?.cacheKey()])

    useAdminLayout()?.setState({actions, active: "subscriptions", headerTitle: SubscriptionPeriod.modelName().human()})
  }

  render() {
    const {subscription, subscriptionPeriod} = this.tt

    return (
      <View dataSet={{route: "admin--subscription-periods--show"}}>
        {subscriptionPeriod &&
          <>
            <AttributeRows attributes={["id", "startsAt", "endsAt", "createdAt", "updatedAt"]} model={subscriptionPeriod} />
            <AttributeRow attribute="plan" model={subscription}>
              {subscription.plan() &&
                <PlanLink adminLayout plan={subscription.plan()} />
              }
            </AttributeRow>
            <AttributeRow attribute="subscription" model={subscriptionPeriod}>
              {subscription &&
                <SubscriptionLink adminLayout subscription={subscription} />
              }
            </AttributeRow>
            <AttributeRow attribute="resource" model={subscription}>
              {subscription.resource() &&
                <ModelLink adminLayout model={subscription.resource()} />
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  onDestroyed = ({subscriptionPeriod}) => {
    AppHistory.push(Routes.adminSubscriptionPath(subscriptionPeriod.subscriptionId()))
  }
}))
