import AdminLayout, {useAdminLayout} from "components/admin/layout"
import {MD3LightTheme as DefaultTheme, PaperProvider} from "react-native-paper"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import Route from "@kaspernj/api-maker/build/router/route"
import setLayout from "shared/set-layout"
import Switch from "@kaspernj/api-maker/build/router/switch"
import usePath from "on-location-changed/build/use-path"
import {useWoofRouter} from "shared/woof-router"

const DefaultSetter = memo(shapeComponent(class DefaultSetter extends ShapeComponent {
  setup() {
    setLayout("admin")

    const adminLayout = useAdminLayout()
    const path = usePath()

    useMemo(() => {
      // Reset admin layout so settings doesn't survive switching pages
      adminLayout?.setState({
        actions: null,
        active: null,
        className: null,
        headerTitle: null,
        menuTriggered: false,
        noAccess: false
      })
    }, [path])
  }

  render() {
    return this.p.children
  }
}))

export default memo(shapeComponent(class AdminRoute extends ShapeComponent {
  setup() {
    setLayout("admin")

    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <AdminLayout className="routes--admin-route">
        <PaperProvider theme={DefaultTheme}>
          <DefaultSetter>
            <Switch name="admin">
              <Route path="audits" />
              <Route component="check-ins" path="check_ins">
                <Switch name="check-ins">
                  <Route component="edit" exact path="new" />
                  <Route exact path=":check_in_id/edit" />
                  <Route component="show" exact path=":check_in_id" />
                  <Route component="index" exact />
                </Switch>
              </Route>
              <Route path="contacts" />
              <Route path="courses" />
              <Route path="crm">
                <Switch name="crm">
                  <Route component="index" exact />
                </Switch>
              </Route>
              <Route component="course-groups" path="course_groups" />
              <Route component="course-group-courses" path="course_group_courses" />
              <Route component="course-modules" path="course_modules" />
              <Route component="contact-emails" path="contact_emails" />
              <Route component="contact-phone-numbers" path="contact_phone_numbers" />
              <Route component="contact-relationships" path="contact_relationships" />
              <Route path="dialogues">
                <Switch name="dialogues">
                  <Route component="edit" exact path="new" />
                  <Route exact path=":dialogue_id/edit" />
                  <Route component="show" exact path=":dialogue_id" />
                </Switch>
              </Route>
              <Route component="email-templates" path="email_templates" />
              <Route component="evaluation-rules" path="evaluation_rules">
                <Switch name="evaluation-rules">
                  <Route component="test" path=":evaluation_rule_id/test" />
                </Switch>
              </Route>
              <Route component="liquid-drops" path="liquid_drops" />
              <Route component="liquid-tester" path="liquid_tester" />
              <Route component="newsletter-templates" path="newsletter_templates" />
              <Route component="notification-type-actions" path="notification_type_actions">
                <Switch name="notification-type-actions">
                  <Route component="edit" exact path="new" />
                  <Route exact path=":notification_type_action_id/edit" />
                </Switch>
              </Route>
              <Route component="notification-types" path="notification_types" />
              <Route component="notification-type-audit-triggers" path="notification_type_audit_triggers">
                <Switch name="notification-type-audit-triggers">
                  <Route component="edit" exact path="new" />
                  <Route exact path=":notification_type_audit_trigger_id/edit" />
                </Switch>
              </Route>
              <Route component="routes-overview" path="routes_overview" />
              <Route path="schools" />
              <Route component="school-classes" path="school_classes" />
              <Route path="subscriptions" />
              <Route component="subscription-periods" path="subscription_periods" />
              <Route path="surveys" />
              <Route component="survey-steps" path="survey_steps" />
              <Route component="survey-step-elements" path="survey_step_elements" />
              <Route component="survey-step-element-options" path="survey_step_element_options" />
              <Route component="survey-step-element-option-scores" path="survey_step_element_option_scores" />
              <Route path="users" />
              <Route component="user-roles" path="user_roles" />
              <Route component="dashboard" exact />
              <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
            </Switch>
          </DefaultSetter>
        </PaperProvider>
      </AdminLayout>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))
