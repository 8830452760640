import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import OptionsButton from "./options-button"
import PageContentsPresentation from "components/page-contents/presentation"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {SchoolClass} from "models"
import {Text} from "shared/base"
import UpgradeLicenseButton from "components/school-classes/upgrade-license-button"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class AdminCard extends ShapeComponent {
  static propTypes = propTypesExact({
    hasUnicorn: PropTypes.bool.isRequired,
    onUpgradeSchoolClassPressed: PropTypes.func.isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    style: PropTypes.object
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.admin_card"})

    this.t = t
  }

  render() {
    const {hasUnicorn, schoolClass, style} = this.props
    const {t} = this.tt
    const viewStyle = {
      position: "relative",
      width: 350,
      padding: "25px",
      backgroundColor: "#fff",
      borderRadius: 10
    }
    const combinedViewStyle = Object.assign(viewStyle, style)
    const teachers = schoolClass.teachers().loaded()

    return (
      <View dataSet={{class: "school-class-admin-card", schoolClassId: schoolClass.id()}} style={combinedViewStyle}>
        <OptionsButton schoolClass={schoolClass} />
        <Text style={{color: "#5e6068", fontSize: 28, fontWeight: 700, textAlign: "center"}}>
          &quot;{schoolClass.interpretedName()}&quot;
        </Text>
        <Text style={{color: "#a3a4a8", fontSize: "16px", textAlign: "center"}}>
          {t(".teachers")}&nbsp;
          {teachers.map((teacher, teacherIndex) =>
            <React.Fragment key={teacher.id()}>
              {teacher.name()}{teacherIndex < teachers.length - 1 ? ", " : ""}
            </React.Fragment>
          )}
        </Text>
        <UpgradeLicenseButton
          hasUnicorn={hasUnicorn}
          onUpgradeSchoolClassPressed={this.p.onUpgradeSchoolClassPressed}
          schoolClass={schoolClass}
          style={{top: "20px"}}
        />
        <PageContentsPresentation
          defaultContent={
            <Text style={{marginTop: "40px", color: "#a3a4a8", fontSize: "16px", textAlign: "center"}}>
              {t(".you_can_upgrade_this_class")}
            </Text>
          }
          identifier="school-classes/admin-card/bottom-content"
          liquidVariables={{hasUnicorn, schoolClass, schoolClassHasUnicornSubscription: schoolClass.hasUnicornSubscription()}}
        />
      </View>
    )
  }
}))
