import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorGroupScoreFactor} from "models"
import {ScoreFactorGroupsSelectWithContainer} from "components/score-factor-groups/select"
import {ScoreFactorsSelectWithContainer} from "components/score-factors/select"
import withModel from "components/wooftech-with-model"

class RoutesAdminScoreFactorGroupScoreFactorsEdit extends PageComponent {
  static propTypes = {
    scoreFactorGroupScoreFactor: PropTypes.instanceOf(ScoreFactorGroupScoreFactor),
    scoreFactorGroupScoreFactorId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {scoreFactorGroupScoreFactor} = this.p

    return (
      <AdminLayout active="score-factor-groups" className="routes--admin--score-factor-group-score-factors--edit" headerTitle={this.headerTitle()}>
        {scoreFactorGroupScoreFactor &&
          <form onSubmit={onSubmit}>
            <ScoreFactorGroupsSelectWithContainer attribute="scoreFactorGroupId" model={scoreFactorGroupScoreFactor} />
            <ScoreFactorsSelectWithContainer attribute="scoreFactorId" model={scoreFactorGroupScoreFactor} />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {scoreFactorGroupScoreFactor} = this.p

    if (scoreFactorGroupScoreFactor?.isNewRecord()) {
      return I18n.t("js.routes.admin.score_factor_group_score_factors.edit.add_score_factor_to_group")
    } else if (scoreFactorGroupScoreFactor?.isPersisted()) {
      return I18n.t("js.routes.admin.score_factor_group_score_factors.edit.edit_score_factor_in_group")
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {scoreFactorGroupScoreFactor} = this.p

    try {
      await scoreFactorGroupScoreFactor.saveRaw(form)
      FlashMessage.success(I18n.t("js.routes.admin.score_factor_group_score_factors.edit.the_score_factor_group_was_saved"))
      AppHistory.push(Routes.adminScoreFactorGroupScoreFactorsPath(scoreFactorGroupScoreFactor.scoreFactorGroupId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedScoreFactorGroupScoreFactorAttributes = ["id", "scoreFactorId", "scoreFactorGroupId"]

export default withModel(
  RoutesAdminScoreFactorGroupScoreFactorsEdit,
  ScoreFactorGroupScoreFactor,
  {
    newIfNoId: true,
    select: {
      ScoreFactorGroupScoreFactor: selectedScoreFactorGroupScoreFactorAttributes
    }
  }
)
