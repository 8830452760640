import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent} from "shared/base"
import {Pressable, StyleSheet, View} from "react-native"
import React from "react"
import {WithDefaultStyle} from "@kaspernj/api-maker/build/utils/default-style"

const styles = StyleSheet.create({
  defaultText: {
    color: "#716bff",
    fontWeight: "bold"
  },
  pressable: {flexDirection: "row", alignItems: "center"},
  leftDashes: {flex: 1, marginRight: 10, borderTopWidth: 1, borderStyle: "dashed", borderColor: "#b7b8ff"},
  rightDashes: {flex: 1, marginLeft: 10, borderTopWidth: 1, borderStyle: "dashed", borderColor: "#b7b8ff"}
})

const defaultStyle = {
  Text: [styles.defaultText]
}

export default memo(shapeComponent(class ChalkyLayoutStripedPressable extends ShapeComponent {
  static propTypes = propTypesExact({
    children: PropTypes.node.isRequired,
    onPress: PropTypes.func.isRequired
  })

  render() {
    const {children, onPress} = this.p

    return (
      <Pressable onPress={onPress} style={styles.pressable}>
        <View style={styles.leftDashes} />
        <View>
          <WithDefaultStyle style={defaultStyle}>
            {children}
          </WithDefaultStyle>
        </View>
        <View style={styles.rightDashes} />
      </Pressable>
    )
  }
}))
