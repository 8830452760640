import {Contact, ContactEmail, ContactPhoneNumber, SchoolClassCheckIn, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import ContactLink from "components/contacts/link"
import CountryLink from "components/countries/link"
import CurrencyLink from "components/currencies/link"
import Devise from "@kaspernj/api-maker/build/devise"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/users/nav"
import Routes from "shared/routes"
import SignInAsButton from "components/users/sign-in-as-button"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUsersShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.users.show"})
    const params = useParams()
    const {user, userId} = useModel(User, {
      abilities: {
        User: ["destroy", "edit", "signInAs"]
      },
      match: {params},
      preload: [
        "contact.parent_relationships.parent.school",
        "contact.parent_relationships.parent.school_class",
        "contact.primary_email",
        "contact.primary_phone_number",
        "current_country",
        "currency",
        "notification_setting",
        "school_classes",
        "schools"
      ],
      select: {
        Contact: ["firstName", "id", "lastName", "name"],
        ContactEmail: ["email"],
        ContactPhoneNumber: ["phoneNumber"],
        ContactRelationship: ["id"],
        Country: ["id", "name"],
        Currency: ["id", "name"],
        NotificationSetting: ["acceptMarketing", "acceptTerms"],
        School: ["id"],
        SchoolClass: ["id"],
        User: [
          "accountUsersCount",
          "contactId",
          "createdAt",
          "color",
          "email",
          "id",
          "initials",
          "locale",
          "name",
          "phoneNumber",
          "signInCount",
          "timeZoneOffset",
          "subscriptionsCount",
          "updatedAt",
          "userRolesCount"
        ]
      }
    })
    const adminLayout = useAdminLayout()
    const actions = useMemo(
      () => <>
        {user?.can("signInAs") &&
          <SignInAsButton onSignedIn={this.tt.onSignedInAs} user={user} />
        }
        <AdminShowActions model={user} />
      </>,
      [user?.cacheKey()]
    )

    adminLayout?.setState({actions, active: "users", className: "routes--admin--users--edit", headerTitle: user?.name()})

    this.setInstance({
      contact: user?.contact() || new Contact(),
      email: user?.contact()?.primaryEmail() || new ContactEmail(),
      phoneNumber: user?.contact()?.primaryPhoneNumber() || new ContactPhoneNumber(),
      t,
      user,
      userId
    })
    this.useStates({
      numberOfWoofsThroughClasses: null
    })

    useMemo(() => {
      if (user) {
        this.loadNumberOfWoofs()
      }
    }, [user?.id()])
  }

  async loadNumberOfWoofs() {
    const numberOfWoofsThroughClasses = await SchoolClassCheckIn
      .ransack({
        school_class_contact_children_relationships_child_id_eq: this.tt.user.contactId()
      })
      .count()

    this.setState({numberOfWoofsThroughClasses})
  }

  render() {
    const {contact, email, phoneNumber, t, user} = this.tt

    return (
      <View dataSet={{route: "admin--users--show"}}>
        {user &&
          <>
            <Nav active="general" style={{marginBottom: 15}} user={user} />
            <AttributeRows attributes={["id"]} model={user} />
            <AttributeRows attributes={["firstName", "lastName"]} model={contact} />
            <AttributeRows attributes={["email"]} model={email} />
            <AttributeRows attributes={["phoneNumber"]} model={phoneNumber} />
            <AttributeRow attribute="contact" model={user}>
              {user.contact() &&
                <ContactLink adminLayout contact={user.contact()} />
              }
            </AttributeRow>
            <AttributeRows
              attributes={[
                "createdAt",
                "updatedAt",
                "color",
                "initials",
                "locale",
                "signInCount",
                "timeZoneOffset"
              ]}
              model={user}
            />
            {user.notificationSetting() &&
              <AttributeRows
                attributes={[
                  "acceptTerms",
                  "acceptMarketing"
                ]}
                model={user.notificationSetting()}
              />
            }
            <AttributeRow attribute="currentCountry" model={user}>
              {user.currentCountry() &&
                <CountryLink adminLayout country={user.currentCountry()} />
              }
            </AttributeRow>
            <AttributeRow attribute="currency" model={user}>
              {user.currency() &&
                <CurrencyLink adminLayout currency={user.currency()} />
              }
            </AttributeRow>
            {this.s.numberOfWoofsThroughClasses !== null &&
              <AttributeRow label={t(".number_of_woofs_through_classes")}>
                {this.s.numberOfWoofsThroughClasses}
              </AttributeRow>
            }
          </>
        }
      </View>
    )
  }

  onSignedInAs = () => {
    const user = Devise.currentUser()

    if (isUserA(user, ["admin", "hacker", "sales_rep"])) {
      AppHistory.push(Routes.adminRootPath())
    } else if (isUserA(user, ["student", "teacher"])) {
      AppHistory.push(Routes.userRootPath())
    } else {
      throw new Error("Couldn't figure out where to redirect the user after sign-in-as")
    }
  }
}))
