const railsEnv = {
  "API_CABLE_URL": "ws://app.wooftech.development:7001/cable",
  "API_URL": "http://app.wooftech.development:7001",
  "PEAKFLOW_JS_AUTH_TOKEN": null,
  "PLAY_DOMAIN": "play.wooftech.development",
  "RAILS_ENV": "development"
}
const env = {}

for (const key in railsEnv) {
  env[key] = global[key] || railsEnv[key]
}

export default env
