import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Account} from "models"
import AccountsTable from "components/accounts/table"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminAccountsIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const canCan = useCanCan(() => [[Account, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.accounts.index"})

    this.setInstance({canCan, t})
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="accounts"
        className="routes--admin-accounts--index"
        headerTitle={Account.modelName().human({count: 2})}
      >
        <AccountsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", Account) &&
          <Button
            className="new-account-button"
            icon="plus"
            label={this.t(".add_new_account")}
            to={Routes.newAdminAccountPath()}
          />
        }
      </>
    )
  }
}))
