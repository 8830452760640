import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import NewsletterTemplateLink from "components/newsletter-templates/link"
import {NewsletterTemplateTimeZoneState} from "models"
import SendButton from "components/newsletter-time-zone-states/send-button"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

const selectedNewsletterTemplateTimeZoneStateAttributes = [
  "createdAt",
  "id",
  "state",
  "translatedState",
  "updatedAt"
]

export default memo(shapeComponent(class RoutesAdminNewsletterTemplateTimeZoneStatesShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {locale, t} = useI18n({namespace: "js.routes.admin.newsletter_template_time_zone_states.show"})
    const {newsletterTemplateTimeZoneState, newsletterTemplateTimeZoneStateId} = useModel(NewsletterTemplateTimeZoneState, {
      abilities: {NewsletterTemplateTimeZoneState: ["destroy", "edit", "send"]},
      eventUpdated: true,
      match: this.p.match,
      preload: ["newsletter_template"],
      select: {
        NewsletterTemplate: ["id", "name"],
        NewsletterTemplateTimeZoneState: selectedNewsletterTemplateTimeZoneStateAttributes
      }
    })

    this.setInstance({locale, newsletterTemplateTimeZoneState, newsletterTemplateTimeZoneStateId, t})
  }

  render() {
    const {locale, newsletterTemplateTimeZoneState} = this.tt
    const actions = useMemo(
      () => <>
        {newsletterTemplateTimeZoneState?.can("send") &&
          <SendButton newsletterTemplateTimeZoneState={newsletterTemplateTimeZoneState} />
        }
        <AdminShowActions model={newsletterTemplateTimeZoneState} />
      </>,
      [locale, newsletterTemplateTimeZoneState?.id(), newsletterTemplateTimeZoneState?.can("send")]
    )

    return (
      <AdminLayout
        actions={actions}
        active="newsletter-templates"
        className="routes--admin--newsletter-template-time-zone-states--show"
        headerTitle={NewsletterTemplateTimeZoneState.modelName().human()}
      >
        {newsletterTemplateTimeZoneState &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={newsletterTemplateTimeZoneState} />
            <AttributeRow attribute="state" model={newsletterTemplateTimeZoneState}>
              {newsletterTemplateTimeZoneState.translatedState()}
            </AttributeRow>
            <AttributeRow attribute="newsletterTemplate" model={newsletterTemplateTimeZoneState}>
              {newsletterTemplateTimeZoneState.newsletterTemplate() &&
                <NewsletterTemplateLink adminLayout newsletterTemplate={newsletterTemplateTimeZoneState.newsletterTemplate()} />
              }
            </AttributeRow>
          </>
        }
      </AdminLayout>
    )
  }
}))
