import BaseComponent from "components/base-component"
import classNames from "classnames"
import {Portal} from "conjointment"
import PropTypes from "prop-types"
import React from "react"
import Shape from "set-state-compare/src/shape"

export default class ComponentsUserUnicornInfoIcon extends BaseComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  }

  hoverContentRef = React.createRef()
  rootRef = React.createRef()
  shape = new Shape(this, {
    left: undefined,
    show: false,
    top: undefined,
    visibility: "hidden"
  })

  render() {
    const {children, className, ...restProps} = this.props
    const {left, show, top, visibility} = this.s
    const {hoverContentRef, onMouseMove, onMouseOver, onMouseOut, rootRef} = this.t

    return (
      <>
        <i
          className={classNames("components--user--unicorn--info-icon", "fa", "fa-circle-info", className)}
          onMouseMove={onMouseMove}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          ref={rootRef}
          {...restProps}
        />
        {show &&
          <Portal name="user-unicorn-info-icon">
            <div className="info-icon-hover-content" ref={hoverContentRef} style={{left, top, visibility}}>
              {children}
            </div>
          </Portal>
        }
      </>
    )
  }

  onMouseMove = (e) => setTimeout(() => this.t.updatePosition({left: e.pageX, top: e.pageY}), 0)
  onMouseOver = (e) =>
    this.shape.set(
      {left: e.pageX, show: true, top: e.pageY, visibility: "hidden"},
      () => setTimeout(() => this.t.updatePosition({left: e.pageX, top: e.pageY}), 0)
    )

  onMouseOut = () => this.shape.set({show: false})

  updatePosition = ({left, top}) => {
    const hoverContent = this.t.hoverContentRef.current

    if (!hoverContent) return

    const width = hoverContent.offsetWidth
    const height = hoverContent.offsetHeight

    let actualLeft = left + 10
    let actualTop = top + 10

    if ((width + left - document.documentElement.scrollLeft) > window.innerWidth) actualLeft = left - width - 10
    if ((height + top - document.documentElement.scrollTop) > window.innerHeight) actualTop = top - height - 10

    this.shape.set({left: actualLeft, top: actualTop, visibility: "visible"})
  }
}
