import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useWoofRouter} from "shared/woof-router"
export default memo(shapeComponent(class RoutesLocale extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="locale">
        <Route component="add-credit-card" path="add_credit_card" />
        <Route path="admin" />
        <Route path="front" />
        <Route path="unsubscribed" />
        <Route path="user" />
        <Route component="super-admin" path="super_admin" />
        <Route component="front/welcome" exact />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => {
    this.woofRouter.setPageNotFound(true)
  }
}))
