import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BackLink from "components/front-layout/back-link"
import currentDate from "shared/current-date"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import MoodBarChartSlide from "components/user/school-classes/insights/mood-bar-chart-slide"
import MoodChartSlide from "components/user/school-classes/insights/mood-chart-slide"
import Params from "@kaspernj/api-maker/build/params"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import ScoreFactorChartSlide from "components/user/school-classes/insights/score-factor-chart-slide"
import Slide from "components/slider/slide"
import Slider from "components/slider"
import TextComponentReplace from "shared/text-component-replace"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassesInsights extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.insights"})
    const params = useParams()
    const {schoolClass, schoolClassId, schoolClassNotFound} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["invite"]
      },
      match: {params},
      select: {
        SchoolClass: ["id", "interpretedName", "schoolId"]
      }
    })

    this.setInstance({
      dateFrom: useMemo(() => moment().subtract(4, "weeks").startOf("day").toDate(), []),
      dateTo: useMemo(() => moment(currentDate()).endOf("day").toDate(), []),
      queryParams: useQueryParams(),
      schoolClass,
      schoolClassId,
      schoolClassNotFound,
      t
    })
    this.useStates({
      slide1loaded: false,
      slide2loaded: false,
      slide3loaded: false
    })

    useFrontLayout()?.setState({
      headerTitle: t(".insights_for_class", {class_name: schoolClass?.interpretedName()}),
      headerTitleCentered: true,
      subHeader: this.subHeader(),
      topLeft: this.topLeft()
    })
  }

  render() {
    const {dateFrom, dateTo, schoolClass} = this.tt
    const activeSlide = this.activeSlide()

    if (activeSlide == 0) {
      this.setState({slide1loaded: true})
    } else if (activeSlide == 1) {
      this.setState({slide2loaded: true})
    } else if (activeSlide == 2) {
      this.setState({slide3loaded: true})
    }

    return (
      <View dataSet={{route: "user--school-classes--insights"}}>
        <Slider active={activeSlide} onSlideChanged={this.tt.onSlideChanged} total={3}>
          <Slide active={activeSlide == 0}>
            {this.s.slide1loaded && schoolClass &&
              <MoodChartSlide dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
            }
          </Slide>
          <Slide active={activeSlide == 1}>
            {this.s.slide2loaded && schoolClass &&
              <MoodBarChartSlide schoolClass={schoolClass} />
            }
          </Slide>
          <Slide active={activeSlide == 2}>
            {this.s.slide3loaded && schoolClass &&
              <ScoreFactorChartSlide dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
            }
          </Slide>
        </Slider>
      </View>
    )
  }

  activeSlide = () => Number(this.tt.queryParams.slide || 0)
  onSlideChanged = ({number}) => Params.changeParams({slide: number})

  subHeader() {
    const {queryParams} = this.tt
    const activeSlide = this.activeSlide()

    if (activeSlide == 0) {
      return this.t(".sub_header")
    } else if (activeSlide == 1 && !queryParams.active_week) {
      return this.t(".sub_header_mood_bar_chart_slide_no_active_week")
    } else if (activeSlide == 1 && queryParams.active_week) {
      return this.t(".sub_header_mood_bar_chart_slide_active_week")
    } else if (activeSlide == 2) {
      const replaces = [
        {
          component: (
            <i className="fa fa-thumbs-up" key="thumbs-up" />
          ),
          text: "%{thumbs_up}"
        },
        {
          component: (
            <i className="fa fa-thumbs-down" key="thumbs-down" />
          ),
          text: "%{thumbs_down}"
        }
      ]

      return (
        <TextComponentReplace
          replaces={replaces}
          text={this.t(".sub_header_score_factor_chart_slide")}
        />
      )
    }
  }

  topLeft() {
    const {schoolClassId} = this.tt

    return (
      <BackLink to={Routes.userSchoolClassDigitalClassRoomPath(schoolClassId)}>
        {this.t(".back_link")}
      </BackLink>
    )
  }
}))
