import {EmailTemplatePreview, Workplace, WorkplaceLink} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import {EmailTemplatePreviewsSelectWithContainer} from "components/email-template-previews/select"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUsersSendEmailPreviewButton extends ShapeComponent {
  static propTypes = {
    disabled: PropTypes.bool
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.users.send_email_preview_button"})

    this.setInstance({t})
    this.useStates({
      disabled: false,
      showSendEmailPreview: false,
      usersCount: undefined
    })
  }

  render() {
    const {t} = this.tt
    const {showSendEmailPreview, usersCount} = this.s

    return (
      <>
        {showSendEmailPreview &&
          <Modal onRequestClose={this.tt.onRequestCloseModal}>
            {usersCount == undefined &&
              <LoadingSpinner />
            }
            {usersCount !== undefined &&
              <>
                <div className="mb-4">
                  {t(".send_to_count_users", {count: usersCount})}
                </div>
                <form onSubmit={this.tt.onSubmit}>
                  <EmailTemplatePreviewsSelectWithContainer
                    id="email_template_preview_id"
                    label={EmailTemplatePreview.modelName().human()}
                    name="email_template_preview_id"
                  />
                  <Button label={t(".send_emails")} primary />
                </form>
              </>
            }
          </Modal>
        }
        <Button
          className="components--users--send-email-preview-button"
          disabled={this.s.disabled || this.props.disabled}
          icon="envelope"
          label={t(".send_email_preview")}
          onClick={this.tt.onSendEmailPreviewClicked}
        />
      </>
    )
  }

  onRequestCloseModal = () => this.setState({showSendEmailPreview: false, usersCount: undefined})

  countUsersSelected = async () => {
    const currentWorkplaceResult = await Workplace.current()
    const currentWorkplace = digg(currentWorkplaceResult, "current", 0)
    const usersCount = await WorkplaceLink
      .ransack({
        resource_type_eq: "User",
        workplace_id_eq: currentWorkplace.id()
      })
      .count()

    this.setState({usersCount})
  }

  onSendEmailPreviewClicked = () => {
    this.countUsersSelected()
    this.setState({showSendEmailPreview: true})
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const emailTemplatePreviewId = formData.get("email_template_preview_id")
    const emailTemplatePreview = await EmailTemplatePreview.find(emailTemplatePreviewId)

    try {
      this.setState({disabled: true})
      await loading(this.t("js.shared.loading"), async () => {
        await emailTemplatePreview.sendEmailsToWorkplaceUsers()
        this.setState({showSendEmailPreview: false})
      })
      FlashMessage.success(this.t(".the_email_previews_has_been_sent"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
