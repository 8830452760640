import {Contact, School} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/schools/nav"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolsShow extends ShapeComponent {
  setup() {
    const selectedSchoolAttributes = ["createdAt", "email", "id", "name", "principalName", "updatedAt"]
      .concat(translatedAttributes(["name"], Locales.availableLocales()))

    const params = useParams()
    const {school} = useModel(School, {
      abilities: {
        School: ["destroy", "edit"]
      },
      onDestroyed: this.tt.onDestroyed,
      match: {params},
      select: {
        School: selectedSchoolAttributes
      }
    })

    const actions = useMemo(() => <AdminShowActions model={school} />, [school?.id()])

    this.setInstance({school})

    useAdminLayout()?.setState({actions, active: "schools", headerTitle: school?.name()})
  }

  render() {
    const {school} = this.tt

    return (
      <View dataSet={{route: "admin--schools--show"}}>
        {school &&
          <>
            <Nav active="general" school={school} style={{marginBottom: 15}} />
            <AttributeRows attributes={["id", "email", "principalName", "createdAt", "updatedAt"]} model={school} />
            {Locales.availableLocales().map((locale) =>
              <AttributeRow
                attribute={`name${inflection.camelize(locale)}`}
                key={locale}
                label={`${Contact.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={school}
              />
            )}
          </>
        }
      </View>
    )
  }

  onDestroyed = () => {
    AppHistory.push(Routes.adminSchoolsPath())
  }
}))
