import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {dig} from "diggerize"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import {SchoolClass} from "models"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useQueryParams from "on-location-changed/build/use-query-params"
export default memo(shapeComponent(class RoutesUserClassProfileRouteSchoolClassSelect extends ShapeComponent {
  setup() {
    const queryParams = useQueryParams()
    const schoolClassId = queryParams.school_class_id

    this.setInstance({
      currentUser: useCurrentUser(),
      schoolClassId
    })
  }

  render() {
    const {schoolClassId} = this.tt
    const values = useMemo(() => (schoolClassId ? [schoolClassId] : []), [schoolClassId])

    return (
      <HayaSelect
        id="school-class-select"
        onChange={this.tt.onSchoolClassesChanged}
        options={this.tt.schoolClassesOptions}
        values={values}
        woofInputStyling
      />
    )
  }

  onSchoolClassesChanged = ({options}) => {
    const schoolClassId = dig(options, 0, "value")

    Params.changeParams({school_class_id: schoolClassId})
  }

  schoolClassesOptions = async ({searchValue, values}) => {
    const {currentUser} = this.tt
    const query = SchoolClass
      .ransack({
        s: ["school_current_translation_name", "current_translation_name"],
        with_contact_id: currentUser.contactId()
      })
      .preload("school")
      .select({
        School: ["name"],
        SchoolClass: ["hasUnicornSubscription", "id", "interpretedName"]
      })
      .groupBy("id")
      .limit(30)

    if (searchValue) {
      query.ransack({school_current_translation_name_or_current_translation_name_cont_any_word: searchValue})
    }

    if (Array.isArray(values) && values.length == 0) {
      return []
    } else if (values) {
      query.ransack({id_eq_any: values})
    }

    const schoolClasses = await query.toArray()
    const result = schoolClasses.map((schoolClass) => ({
      disabled: !schoolClass.hasUnicornSubscription(),
      text: `${schoolClass.school().name()} - ${schoolClass.interpretedName()}`,
      value: schoolClass.id()
    }))

    return result
  }
}))
