import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import instanceOfClassName from "@kaspernj/api-maker/build/instance-of-class-name"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsSurveyStepElementsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    surveyStepElement: instanceOfClassName("SurveyStepElement").isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, surveyStepElement, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--survey-step-elements--link", className)
    const to = this.to()

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {this.content()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {this.content()}
      </span>
    )
  }

  content() {
    const {children, surveyStepElement} = this.props

    return children || surveyStepElement.name() || surveyStepElement.id()
  }

  to() {
    const {adminLayout, surveyStepElement, userLayout} = this.p

    if (adminLayout) {
      return Routes.adminSurveyStepElementPath(surveyStepElement.id())
    } else if (userLayout) {
      return Routes.userSurveyStepElementPath(surveyStepElement.id())
    }
  }
}))
