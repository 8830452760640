import {ContactEmail, ContactRelationship, User} from "models"
import isUserA, {isCurrentUserA} from "components/users/is-a"
import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import classNames from "classnames"
import Devise from "@kaspernj/api-maker/build/devise"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import Link from "@kaspernj/api-maker/build/link"
import LocaleSelect from "components/front-layout/locale-select"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUsersSignIn extends ShapeComponent {
  static propTypes = {
    redirectAfterSignIn: PropTypes.bool
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.users.sign_in"})

    this.currentUser = useCurrentUser()
    this.rememberMeRef = useRef()
    this.t = t
  }

  render() {
    const {currentUser, onSubmit, rememberMeRef, t, uniLoginContent} = this.tt
    const {className = false, redirectAfterSignIn, ...restProps} = this.props

    return (
      <MainCard className={classNames("components--users--sign-in", className)} footer={uniLoginContent()} {...restProps}>
        {currentUser &&
          <div className="signed-in-as-content">
            {t(".you_are_signed_in_as", {name: currentUser.name()})}
          </div>
        }
        {!currentUser &&
          <form onSubmit={onSubmit}>
            <Input className="email-input" icon="at" label={ContactEmail.humanAttributeName("email")} name="email" />
            <Input className="password-input" icon="lock" label={t("js.shared.password")} name="password" type="password" />

            <div className="mt-3">
              <Button className="sign-in-button" label={t(".sign_in")} primary style={{width: "100%"}}/>
            </div>
            <div className="mt-4" style={{display: "flex"}}>
              <div>
                <Checkbox inputRef={rememberMeRef} label={t(".remember_me")} />
              </div>
              <div style={{marginLeft: "auto"}}>
                <Link
                  children={t(".forgot_password")}
                  className="forgot-password-button"
                  to={Routes.frontUsersForgotPasswordPath()}
                />
              </div>
            </div>
            <div className="mt-4" style={{textAlign: "center"}}>
              <Link
                children={t(".resend_confirmation_email")}
                className="missing-confirmation-button"
                to={Routes.frontUsersResendConfirmationEmailPath()}
              />
            </div>
            <div className="or-container">
              {t(".dash_or_dash")}
            </div>
            <div className="mt-4" style={{textAlign: "center"}}>
              <Button className="new-user-link" to={Routes.frontNewAccountPath()}>
                {t("js.routes.front.welcome.create_new_user")}
              </Button>
            </div>
          </form>
        }
        {isUserA(currentUser, "tester") &&
          <div style={{marginTop: "10px"}}>
            <LocaleSelect />
          </div>
        }
      </MainCard>
    )
  }

  uniLoginContent = () => {
    // Disabled until we implement UNI login
    return null

    /*
    return (
      <div className="uni-login-content">
        <div style={{textAlign: "center"}}>
          {t(".or_sign_in_with_uni_login")}
        </div>
        <Button className="mt-2" darkPrimary label={t(".uni_login")} style={{width: "100%"}} to="#" />
      </div>
    )
    */
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const email = formData.get("email")
    const password = formData.get("password")
    const rememberMe = digg(this.tt.rememberMeRef, "current", "checked")

    try {
      await Devise.signIn(
        email,
        password,
        {
          loadQuery: User.ransack().preload("user_roles.role"),
          rememberMe
        }
      )

      FlashMessage.success(this.t(".you_were_signed_in"))

      if (this.props.redirectAfterSignIn) this.redirect()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  redirect = async () => {
    const schoolLeaderRole = await ContactRelationship
      .ransack({
        contact_relationship_type_identifier_eq: "school_leader",
        child_id_eq: Devise.currentUser().contactId()
      })
      .first()

    if (schoolLeaderRole) {
      AppHistory.push(Routes.userClassStatusPath())
    } else if (isCurrentUserA("admin", "sales_rep", "hacker")) {
      AppHistory.push(Routes.adminRootPath())
    } else if (isCurrentUserA(["student", "teacher"])) {
      AppHistory.push(Routes.userRootPath())
    }
  }
}))
