import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {School} from "models"
export default memo(shapeComponent(class ComponentsSchoolsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    superAdminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    school: PropTypes.instanceOf(School).isRequired,
    superAdminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, school, superAdminLayout, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--schools--link", className)
    const to = this.to()

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || school.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || school.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, school, superAdminLayout} = this.p

    if (adminLayout) {
      return Routes.adminSchoolPath(school.id())
    } else if (superAdminLayout) {
      return Routes.superAdminPath({model: "School", model_id: school.id()})
    }

    return "#"
  }
}))
