import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNavSegmented from "components/admin/nav-segmented"
import classNames from "classnames"
import {CourseGroup} from "models"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminCourseGroupsNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    courseGroup: PropTypes.instanceOf(CourseGroup).isRequired
  }

  setup() {
    const {courseGroup} = this.p
    const {locale, t} = useI18n({namespace: "js.components.admin.course_groups.nav"})

    this.t = t
    this.buttons = useMemo(
      () => [
        {
          value: "general",
          label: this.t("js.shared.general"),
          to: Routes.adminCourseGroupPath(courseGroup.id())
        },
        {
          value: "courses",
          label: CourseGroup.humanAttributeName("courses"),
          to: Routes.adminCourseGroupCourseGroupCoursesPath(courseGroup.id())
        }
      ],
      [locale]
    )
  }

  render() {
    const {active, className, courseGroup, ...restProps} = this.props

    return (
      <AdminNavSegmented
        active={active}
        buttons={this.tt.buttons}
        dataSet={{class: classNames("components--course-groups--nav", className)}}
        {...restProps}
      />
    )
  }
}))
