import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import instanceOfClassName from "@kaspernj/api-maker/build/instance-of-class-name"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsSchoolClassGroupsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    schoolClassGroup: instanceOfClassName("SchoolClassGroup").isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, schoolClassGroup, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--school-class-groups--link", className)
    const to = this.to()

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || schoolClassGroup.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || schoolClassGroup.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, schoolClassGroup} = this.p

    if (adminLayout) return Routes.adminSchoolClassGroupPath(schoolClassGroup.id())
  }
}))
