import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {incorporate} from "incorporator"
import memo from "set-state-compare/src/memo"
import {Platform} from "react-native"
import PropTypes from "prop-types"
import React from "react"
import RenderHtml from "shared/render-html"
import xss from "xss"

const whiteList = {
  div: ["class"],
  figure: ["class", "style"],
  i: ["class"],
  img: ["class", "data-controller", "data-remove-link", "height", "style", "width"],
  p: ["class", "style"]
}
const defaultWhiteList = xss.whiteList
const extendedWhiteList = incorporate(whiteList, defaultWhiteList)
const options = {whiteList: extendedWhiteList}
const sanitizeHtml = (html) => xss(html, options)

const SanitizedHtml = memo(shapeComponent(class SanitizedHtml extends ShapeComponent {
  static propTypes = {
    html: PropTypes.string.isRequired
  }

  render() {
    const {className, html, ...restProps} = this.props
    const sanitizedHtml = sanitizeHtml(html)

    if (Platform.OS == "web") {
      return (
        <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} data-class={className} data-component="shared/sanitized-html" {...restProps} />
      )
    }

    return (
      <RenderHtml dataSet={{class: className, component: "shared/sanitized-html"}} source={sanitizedHtml} />
    )
  }
}))

export {options, sanitizeHtml}
export default SanitizedHtml
