import ApiMakerAttachment from "@kaspernj/api-maker/build/inputs/attachment"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useInput from "@kaspernj/api-maker/build/use-input"
import {withInputContainer} from "components/inputs/input-container"

const ComponentsInputsAttachment = (props) => {
  const {inputProps, restProps, wrapperOpts} = useInput({props})
  const {className, ...actualRestProps} = restProps

  return (
    <ApiMakerAttachment
      className={("components--inputs-attachment", "components--inputs--input", className)}
      inputProps={inputProps}
      wrapperOpts={wrapperOpts}
      {...actualRestProps}
    />
  )
}

ComponentsInputsAttachment.propTypes = {
  className: PropTypes.string,
  model: PropTypes.object.isRequired
}

export default withInputContainer(memo(ComponentsInputsAttachment))
