import {Pressable, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AddClassRoleCircleButton from "./add-class-role-circle-button"
import Button from "components/inputs/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import isUserA from "components/users/is-a"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import {Text} from "shared/base"
import uniqunize from "uniqunize"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UsersCircleIcon from "components/users/circle-icon"

export default memo(shapeComponent(class ComponentsSchoolClassesCard extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    onChangeSubscriptionPressed: PropTypes.func,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.card"})

    this.setInstance({
      activeSubscriptions: this.calculateActiveSubscriptions(),
      currentUser: useCurrentUser(),
      t
    })
  }

  calculateActiveSubscriptions() {
    const allSubscriptions = this.p.schoolClass.activeSubscriptions().loaded().concat(this.p.schoolClass.school().activeSubscriptions().loaded())
    const uniqueSubscriptions = uniqunize(allSubscriptions, (subscription) => subscription.plan().identifier())

    return uniqueSubscriptions
  }

  render() {
    const {activeSubscriptions, currentUser, t} = this.tt
    const {className, onChangeSubscriptionPressed, schoolClass, ...restProps} = this.props

    return (
      <div className={classNames("components--school-classes--card", className)} data-school-class-id={schoolClass.id()} {...restProps}>
        <View style={{position: "absolute", top: 20, left: 20}}>
          <View style={{display: "flex", flexDirection: "row"}}>
            {activeSubscriptions.length == 0 &&
              <SubscriptionBadge t={t} />
            }
            {activeSubscriptions.map((subscription) =>
              <SubscriptionBadge key={subscription.id()} subscription={subscription} t={t} />
            )}
          </View>
        </View>
        <div className="school-class-card-actions-container">
          {schoolClass.can("edit") &&
            <Link className="school-class-action-icon edit-icon" to={Routes.editUserSchoolClassPath(schoolClass.id())}>
              <i className="fa fa-pencil" />
            </Link>
          }
          {schoolClass.can("adminDestroy") &&
            <a className="school-class-action-icon destroy-icon" href="#" onClick={this.tt.onDestroyClicked}>
              <i className="fa fa-trash" />
            </a>
          }
          {isUserA(currentUser, "teacher") &&
            <Link className="school-class-action-icon" to={Routes.userSchoolClassRolesPath(schoolClass.id())}>
              <i className="fa fa-users" />
            </Link>
          }
        </div>
        <div className="school-class-card-name-container">
          {schoolClass.hasInterpretedName() ? <>&quot;{schoolClass.interpretedName()}&quot;</> : <>&nbsp;&nbsp;</>}
        </div>
        {schoolClass.school() &&
          <div className="school-container">
            {schoolClass.school().name()}
          </div>
        }
        <Button
          primary
          style={{marginTop: "30px", width: "100%"}}
          to={Routes.userSchoolClassDigitalClassRoomPath(schoolClass.id())}
        >
          {t(".start_digital_class_room")}
        </Button>
        {isUserA(currentUser, "tester") &&
          <Pressable dataSet={{class: "change-subscription-pressable"}} onPress={this.tt.onChangeSubscriptionPressed}>
            <Text style={{marginTop: 10, marginBottom: 20, textAlign: "center", textDecoration: "underline"}}>
              {t(".change_subscription")}
            </Text>
          </Pressable>
        }
        {schoolClass.can("invite") &&
          <div className="teacher-circle-icons">
            {schoolClass.teachers().map((teacher) =>
              <UsersCircleIcon key={teacher.id()} user={teacher} />
            )}
            <AddClassRoleCircleButton schoolClass={schoolClass} />
          </div>
        }
      </div>
    )
  }

  onDestroyClicked = async (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    try {
      await this.p.schoolClass.adminDestroy()
      FlashMessage.success(this.t(".the_class_was_deleted"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onChangeSubscriptionPressed = () => {
    this.p.onChangeSubscriptionPressed({schoolClass: this.p.schoolClass})
  }
}))

const SubscriptionBadge = memo(shapeComponent(class SubscriptionBadge extends ShapeComponent {
  render() {
    const {subscription, t} = this.props
    const plan = subscription?.plan()
    const style = {
      marginRight: 6,
      paddingTop: 5,
      paddingRight: 11,
      paddingBottom: 5,
      paddingLeft: 11,
      borderRadius: 5
    }
    const textStyle = {fontSize: 12}
    const dataSet = {class: "subscription-badge"}

    if (subscription) {
      dataSet.subscriptionType = plan.identifier()
      style.backgroundColor = "#c555d5"
      textStyle.color = "#fff"
    } else {
      dataSet.subscriptionType = "free"
      style.backgroundColor = "#fff"
      style.border = "3px solid #5f93f7"
    }

    return (
      <View dataSet={dataSet} style={style}>
        <Text style={textStyle}>
          {plan?.name()}
          {!subscription && t(".free")}
        </Text>
      </View>
    )
  }
}))
