import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import {CourseGroupCourse} from "models"
import CourseGroupLink from "components/course-groups/link"
import CourseLink from "components/courses/link"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import ScoreFactorLink from "components/score-factors/link"
export default memo(shapeComponent(class ComponentsCourseGroupCourses extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--course-group-courses--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={CourseGroupCourse}
        preloads={["course", "course_group", "score_factor"]}
        select={{
          Course: ["id", "name"],
          CourseGroup: ["id", "name"],
          CourseGroupCourse: ["id"],
          ScoreFactor: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.courseGroupContent,
      label: CourseGroupCourse.humanAttributeName("courseGroup"),
      sortKey: "courseGroupCurrentTranslationName"
    },
    {
      content: this.tt.courseContent,
      label: CourseGroupCourse.humanAttributeName("course"),
      sortKey: "courseCurrentTranslationName"
    },
    {
      content: this.tt.scoreFactorContent,
      label: CourseGroupCourse.humanAttributeName("scoreFactor"),
      sortKey: "scoreFactorCurrentTranslationName"
    },
    {
      attribute: "highlight",
      sortKey: "highlight"
    }
  ]

  editModelPath = ({courseGroupCourse}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminCourseGroupCoursePath(courseGroupCourse.id())
  }

  courseContent = ({courseGroupCourse}) => courseGroupCourse.course() &&
    <CourseLink course={courseGroupCourse.course()} {...linkProps(this.props)} />

  courseGroupContent = ({courseGroupCourse}) => courseGroupCourse.courseGroup() &&
    <CourseGroupLink courseGroup={courseGroupCourse.courseGroup()} {...linkProps(this.props)} />

  scoreFactorContent = ({courseGroupCourse}) => courseGroupCourse.scoreFactor() &&
    <ScoreFactorLink scoreFactor={courseGroupCourse.scoreFactor()} {...linkProps(this.props)} />
}))
