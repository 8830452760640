import React, {useMemo} from "react"
import {SchoolClassChallenge, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Background from "components/user/challenges/show/background.jpg"
import {digg} from "diggerize"
import EventEmitter from "events"
import I18n from "shared/i18n"
import {incorporate} from "incorporator"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import SurveysBottomBar from "components/surveys/bottom-bar"
import SurveysPageContainer from "components/surveys/page-container"
import SurveysPresentation from "components/surveys/presentation"
import SurveysSelectedAttributes from "components/surveys/selected-attributes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useHiddenTawkWidget from "shared/use-hidden-tawk-widget"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"

export default memo(shapeComponent(class RoutesUserSchoolClassChallengesShow extends ShapeComponent {
  surveyStepsPresentationEvents = new EventEmitter()

  setup() {
    const currentUser = useCurrentUser()
    const params = useParams()
    const {schoolClassChallenge, schoolClassChallengeId} = useModel(SchoolClassChallenge, {
      match: {params},
      preload: ["challenge", "school_class"],
      select: {
        Challenge: ["name"],
        SchoolClass: ["id", "schoolId"],
        SchoolClassChallenge: ["id", "schoolClassId"]
      }
    })
    const schoolClass = schoolClassChallenge?.schoolClass()

    this.setInstance({
      queryParams: useQueryParams(),
      schoolClassChallenge,
      schoolClassChallengeId
    })
    this.useStates({
      surveyStep: undefined,
      teamSurvey: undefined,
      teamSurveyStep: undefined
    })

    useMemo(() => {
      this.loadTeamSurvey()
    }, [currentUser?.id()])

    useHiddenTawkWidget()

    useFrontLayout()?.setState({
      background: Background,
      bottomBar: this.bottomBar(),
      contentCentered: true,
      schoolClass,
      topLeft: this.topLeft(),
      unicornTrialTopBar: false
    })
  }

  async loadTeamSurvey() {
    const {queryParams, schoolClassChallengeId} = this.tt
    const teamSurveyId = digg(queryParams, "team_survey_id")

    const preload = [
      "survey.steps.elements.options",
      "survey.steps.elements.score_factor",
      "team_survey_steps"
    ]

    for (const surveyPreload of SurveysSelectedAttributes.preload) {
      preload.push(
        `survey.steps.${surveyPreload}`
      )
    }

    const pageSelect = {
      SchoolClass: ["id", "schoolId"],
      SchoolClassChallenge: ["id", "schoolClassId"],
      Survey: ["id", "surveyStepsCount"],
      TeamSurvey: ["id", "preview", "state"],
      TeamSurveyStep: ["surveyStepId"]
    }

    const select = incorporate(pageSelect, SurveysSelectedAttributes.select)

    const teamSurvey = await TeamSurvey
      .ransack({
        id_eq: teamSurveyId,
        school_class_challenge_id_eq: schoolClassChallengeId
      })
      .preload(preload)
      .select(select)
      .first()

    this.setState({teamSurvey})
  }

  render() {
    const {schoolClassChallenge} = this.tt
    const {teamSurvey} = this.s
    const schoolClass = schoolClassChallenge?.schoolClass()
    const survey = teamSurvey?.survey()

    return (
      <div className="routes--user--school-class-challenges--show">
        {schoolClass && survey && teamSurvey &&
          <SurveysPageContainer>
            <SurveysPresentation
              className="w-100"
              events={this.tt.surveyStepsPresentationEvents}
              onSurveyCompleted={this.tt.onSurveyCompleted}
              onSurveyStepLoaded={this.tt.onSurveyStepLoaded}
              onTeamSurveyStepLoaded={this.tt.onTeamSurveyStepLoaded}
              schoolClass={schoolClass}
              submitButton={false}
              survey={survey}
              teamSurvey={teamSurvey}
            />
          </SurveysPageContainer>
        }
      </div>
    )
  }

  bottomBar() {
    const {onCancelClicked, onEndClicked, onNextStepClicked, onPreviousStepClicked} = this.tt
    const {surveyStep, teamSurvey, teamSurveyStep} = this.s
    const survey = teamSurvey?.survey()

    return (
      <>
        {survey && surveyStep && teamSurveyStep &&
          <SurveysBottomBar
            color="white"
            onCancelClicked={onCancelClicked}
            onEndClicked={onEndClicked}
            onNextStepClicked={onNextStepClicked}
            onPreviousStepClicked={onPreviousStepClicked}
            survey={survey}
            surveyStep={surveyStep}
            teamSurvey={teamSurvey}
            teamSurveyStep={teamSurveyStep}
          />
        }
      </>
    )
  }

  topLeft() {
    const {schoolClassChallenge} = this.tt

    return (
      <>
        {schoolClassChallenge &&
          <Link className="text-white" to={Routes.userSchoolClassDigitalClassRoomPath(schoolClassChallenge?.schoolClassId())}>
            <i className="fa fa-chevron-left mr-1" />
            {I18n.t("js.routes.user.school_class_courses.show.go_to_the_space_of_the_class")}
          </Link>
        }
      </>
    )
  }

  onCancelClicked = () => {
    AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClassChallenge.schoolClassId()))
  }

  onEndClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "end"})
  }

  onNextStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "nextStep"})
  }

  onPreviousStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "previousStep"})
  }

  onSurveyStepLoaded = (surveyStep) => this.setState({surveyStep})
  onTeamSurveyStepLoaded = (teamSurveyStep) => this.setState({teamSurveyStep})
  onSurveyCompleted = () => AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClassChallenge.schoolClassId()))
}))
