import {Newsletter, NewsletterTemplate, NewsletterTemplateTimeZoneState, TableSearch} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    newsletterTemplate: PropTypes.instanceOf(NewsletterTemplate).isRequired
  }

  render() {
    const {active, className, newsletterTemplate, ...restProps} = this.props
    const {t} = useI18n({namespace: "js.components.admin.newsletter_templates.nav"})

    return (
      <Nav className={classNames("routes--admin--newsletter-templates--nav", className)} {...restProps}>
        <NavItem active={active} identifier="general" label={I18n.t("js.shared.general")} to={Routes.adminNewsletterTemplatePath(newsletterTemplate.id())} />
        <NavItem
          active={active}
          identifier="table-searches"
          label={TableSearch.modelName().human({count: 2})}
          to={Routes.adminNewsletterTemplateTableSearchesPath(newsletterTemplate.id())}
        />
        <NavItem
          active={active}
          identifier="audits"
          label={NewsletterTemplate.humanAttributeName("audits")}
          to={Routes.adminNewsletterTemplateAuditsPath(newsletterTemplate.id())}
        />
        <NavItem
          active={active}
          identifier="users-to-receive"
          label={t(".users_to_receive")}
          to={Routes.adminNewsletterTemplateUsersToReceivePath(newsletterTemplate.id())}
        />
        <NavItem
          active={active}
          identifier="newsletter-time-zone-states"
          label={NewsletterTemplateTimeZoneState.modelName().human({count: 2})}
          to={Routes.adminNewsletterTemplateNewsletterTemplateTimeZoneStatesPath(newsletterTemplate.id())}
        />
        <NavItem
          active={active}
          identifier="newsletters"
          label={Newsletter.modelName().human({count: 2})}
          to={Routes.adminNewsletterTemplateNewslettersPath(newsletterTemplate.id())}
        />
      </Nav>
    )
  }
}))
