import {Pressable, View} from "react-native"
import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import memo from "set-state-compare/src/memo"
import {Portal} from "conjointment"
import {Text} from "shared/base"
import useGlobalClick from "components/utils/use-global-click"

const HoverMenuItem = memo(shapeComponent(class HoverMenuItem extends ShapeComponent {
  render() {
    const {onPress, to, ...restProps} = this.props

    return (
      <Pressable onPress={this.tt.onPressed} {...restProps}>
        <Text style={{marginTop: 4, marginBottom: 4, color: "black", fontSize: "16px"}}>
          {this.p.label}
        </Text>
      </Pressable>
    )
  }

  onPressed = () => {
    if (this.props.onPress) {
      this.p.onPress()
    } else if (this.props.to) {
      AppHistory.push(this.p.to)
    } else {
      throw new Error("'onPress' and 'to' wasn't given")
    }
  }
}))

const HoverMenu = memo(shapeComponent(class HoverMenu extends ShapeComponent {
  setup() {
    this.useStates({
      clientX: undefined,
      clientY: undefined,
      popupMenuLeft: undefined,
      popupMenuTop: undefined,
      showMenu: undefined
    })
  }

  render() {
    const {onMouseMove, onPress} = this.tt
    const {children, content} = this.props
    const viewRef = useRef()

    useGlobalClick((e) => {
      if (this.s.showMenu && viewRef.current && !viewRef.current.contains(e.target)) {
        this.setState({showMenu: false})
      } else if (this.s.showMenu) {
        setTimeout(() => this.setState({showMenu: false}), 0)
      }
    }, [])

    return (
      <>
        {this.s.showMenu &&
          <Portal name="hover-menu">
            <View
              ref={viewRef}
              style={{
                position: "absolute",
                zIndex: 2,
                left: this.s.popupMenuLeft,
                top: this.s.popupMenuTop,
                padding: 20,
                width: 300,
                backgroundColor: "#fff",
                border: "1px solid #f3f3f3",
                borderRadius: 7,
                boxShadow: "0px 8px 10px 0px rgba(0, 0, 0, 0.0706)"
              }}
            >
              {content()}
            </View>
          </Portal>
        }
        <Pressable onMouseDown={onMouseMove} onMouseMove={onMouseMove} onPress={onPress}>
          {children}
        </Pressable>
      </>
    )
  }

  onMouseMove = (e) => {
    this.setState({
      clientX: e.clientX + document.documentElement.scrollLeft,
      clientY: e.clientY + document.documentElement.scrollTop
    })
  }

  onPress = () => {
    this.setState({
      popupMenuLeft: this.s.clientX,
      popupMenuTop: this.s.clientY,
      showMenu: true
    })
  }
}))

export {HoverMenu, HoverMenuItem}
