import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import I18n from "shared/i18n"
import InfoIcon from "components/user/unicorn/info-icon"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import SanitizedHtml from "shared/sanitized-html"
import {SchoolClassCheckIn} from "models"
import {Text} from "shared/base"
import TopScoreFactor from "components/user/insights/top-score-factor"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserUniCornFrequencyFactors extends ShapeComponent {
  static defaultProps = {
    classStepIds: null,
    dateFrom: null,
    dateTo: null,
    schoolClassIds: null,
    schoolClassGroupIds: null,
    schoolIds: null
  }

  static propTypes = propTypesExact({
    className: PropTypes.string,
    classStepIds: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    schoolClassIds: PropTypes.array,
    schoolClassGroupIds: PropTypes.array,
    schoolIds: PropTypes.array
  })

  setup() {
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const filterQueryParams = [classStepIds?.join("--"), dateFrom, dateTo, schoolClassIds?.join("--"), schoolClassGroupIds?.join("--"), schoolIds?.join("--")]
    const {t} = useI18n({namespace: "js.components.user.unicorn.frequency_factors"})
    const schoolClassCheckInsQuery = useMemo(
      () => this.newSchoolClassCheckInsQuery(),
      filterQueryParams
    )

    this.setInstance({t, schoolClassCheckInsQuery})
  }

  newSchoolClassCheckInsQuery() {
    const {classStepIds, dateFrom, dateTo, schoolClassGroupIds, schoolClassIds, schoolIds} = this.p

    return SchoolClassCheckIn.ransack({
      created_at_gteq: I18n.strftime("%Y-%m-%d %H:%M:%S", dateFrom),
      created_at_lteq: I18n.strftime("%Y-%m-%d %H:%M:%S", dateTo),
      school_class_id_eq_any: schoolClassIds,
      school_class_class_step_id_eq_any: classStepIds,
      school_class_school_id_eq_any: schoolIds,
      school_class_class_step_school_class_group_class_steps_school_class_group_id_eq_any: schoolClassGroupIds,
      state_eq: "closed"
    })
  }

  render() {
    const {className} = this.props
    const {schoolClassCheckInsQuery, t} = this.tt

    return (
      <View
        dataSet={{
          class: className,
          component: "user/unicorn/frequency-factors"
        }}
        style={{
          height: "100%",
          padding: 30,
          borderRadius: 10,
          backgroundColor: "#fff"
        }}
      >
        <View>
          <InfoIcon className="info-icon" style={{marginLeft: "auto"}}>
            <SanitizedHtml html={t(".info_box")} />
          </InfoIcon>
        </View>
        <View style={{alignItems: "center"}}>
          <Text
            dataSet={{class: "frequency-factors-header"}}
            style={{
              marginBottom: 19,
              color: "#59adff",
              fontSize: 22,
              fontWeight: "bold"
            }}
          >
            {t(".frequency_factors")}
          </Text>
        </View>
        <View>
          <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <Text
              dataSet={{class: "top-positive-negative-header"}}
              style={{
                color: "#666",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {t(".top_positive")}
            </Text>
            <FontAwesomeIcon
              dataSet={{class: "top-positive-negative-icon"}}
              name="thumbs-up"
              style={{
                marginLeft: 4,
                color: "#666",
                fontSize: 20
              }}
            />
          </View>
          <View dataSet={{class: "top-score-factor-container"}}>
            {schoolClassCheckInsQuery &&
              <TopScoreFactor
                card={false}
                mode="positive"
                schoolClassCheckInsQuery={schoolClassCheckInsQuery}
                style={{
                  marginTop: 6,
                  color: "#4c93ff",
                  fontSize: 40,
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              />
            }
          </View>
        </View>
        <View style={{marginTop: 40}}>
          <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <Text
              dataSet={{class: "top-positive-negative-header"}}
              style={{
                color: "#666",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {t(".top_negative")}
            </Text>
            <FontAwesomeIcon
              dataSet={{class: "top-positive-negative-icon"}}
              name="thumbs-down"
              style={{
                marginLeft: 4,
                color: "#666",
                fontSize: 20
              }}
            />
          </View>
          <View dataSet={{class: "top-score-factor-container"}}>
            {schoolClassCheckInsQuery &&
              <TopScoreFactor
                card={false}
                mode="negative"
                schoolClassCheckInsQuery={schoolClassCheckInsQuery}
                style={{
                  marginTop: 6,
                  color: "#4c93ff",
                  fontSize: 40,
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              />
            }
          </View>
        </View>
      </View>
    )
  }
}))
