import React, {createContext, useContext, useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import CommandsPool from "@kaspernj/api-maker/build/commands-pool"
import Header from "./header"
import I18n from "shared/i18n"
import isUserA from "components/users/is-a"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import Menu from "./menu"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Routes from "shared/routes"
import {Text} from "shared/base"
import TextComponentReplace from "shared/text-component-replace"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
import {View} from "react-native"

const AdminLayoutContext = createContext(null)
const UsersSignIn = React.lazy(() => import("components/users/sign-in"))
const NoAccess = React.lazy(() => import("./no-access"))

const useAdminLayout = () => {
  const adminLayoutContext = useContext(AdminLayoutContext)

  return adminLayoutContext
}

export {useAdminLayout}

export default memo(shapeComponent(class ComponentsAdminLayout extends ShapeComponent {
  static defaultProps = {
    noAccess: undefined,
    requireAdmin: true
  }

  static propTypes = propTypesExact({
    actions: PropTypes.node,
    active: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    currentCustomer: PropTypes.instanceOf(User),
    currentCustomerId: PropTypes.string,
    currentUser: PropTypes.instanceOf(User),
    headTitle: PropTypes.string,
    headerTitle: PropTypes.string,
    noAccess: PropTypes.bool,
    requireAdmin: PropTypes.bool.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.admin.layout"})
    const breakpoint = useBreakpoint()

    this.setInstance({
      breakpoint,
      currentUser: useCurrentUser(),
      t
    })
    this.useStates({
      actions: null,
      active: null,
      className: null,
      headerTitle: null,
      menuTriggered: false,
      noAccess: false
    })

    useMemo(() => {
      CommandsPool.current().globalRequestData.layout = "admin"
      CommandsPool.current().globalRequestData.locale = I18n.locale
    }, [])
  }

  render() {
    const {breakpoint, currentUser, t} = this.tt
    const {
      actions,
      active,
      children,
      className,
      currentCustomer,
      currentCustomerId,
      headerTitle,
      headTitle,
      menu,
      noAccess,
      requireAdmin,
      ...restProps
    } = this.props

    useMemo(() => {
      const headTitle = this.props.headTitle || this.props.headerTitle || this.s.headerTitle

      if (headTitle) {
        document.title = headTitle
      } else {
        document.title = "Wooftech"
      }
    }, [headTitle, headerTitle, this.s.headerTitle])

    const hasNoAccess = this.calculateNoAccess()
    const adminLayoutContext = useMemo(() => ({
      setState: (states) => {
        this.setState(states)
      }
    }), [])

    const appLayoutContentContainerStyle = {
      paddingTop: 130,
      paddingRight: 30,
      paddingBottom: 30,
      paddingLeft: 30,
      minHeight: "100vh",
      backgroundColor: "#f7f7f7"
    }

    if (breakpoint.lgUp) {
      appLayoutContentContainerStyle.paddingLeft = 320
    } else if (breakpoint.mdUp) {
      appLayoutContentContainerStyle.paddingLeft = 280
    }

    return (
      <View
        dataSet={{
          class: classNames(className, this.s.className),
          component: "admin/layout",
          menuTriggered: this.s.menuTriggered
        }}
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#fff"
        }}
        {...restProps}
      >
        <Menu
          active={active || this.s.active}
          noAccess={hasNoAccess}
          onRequestMenuClose={this.tt.onRequestMenuClose}
          triggered={this.s.menuTriggered}
        />
        <View
          dataSet={{class: "app-layout-content-container"}}
          style={appLayoutContentContainerStyle}
        >
          {hasNoAccess &&
            <>
              <NoAccess />
              {currentUser &&
                <>
                  <Text style={{marginBottom: 15}}>
                    {t(".try_signing_out_and_in_with_a_different_user")}
                  </Text>
                  {(isUserA(currentUser, ["teacher", "student"])) &&
                    <Text style={{marginBottom: 15}}>
                      {this.clickHereToAccessTheUserUniverse()}
                    </Text>
                  }
                </>
              }
              {!currentUser &&
                <>
                  <Text style={{marginBottom: 15}}>
                    {t(".try_signing_in")}
                  </Text>
                  <UsersSignIn />
                </>
              }
            </>
          }
          {!hasNoAccess &&
            <AdminLayoutContext.Provider value={adminLayoutContext}>
              {children}
            </AdminLayoutContext.Provider>
          }
        </View>
        <Header actions={actions || this.s.actions} onTriggerMenu={this.tt.onTriggerMenu} title={headerTitle || this.s.headerTitle} />
      </View>
    )
  }

  clickHereToAccessTheUserUniverse = () => {
    const {t} = this.tt
    const replaces = [
      {
        component: (
          <Link key="here-user-universe-link" to={Routes.userRootPath()}>
            {t(".here")}
          </Link>
        ),
        text: "%{here}"
      },
      {
        component: (
          <Link key="user-universe-link" to={Routes.userRootPath()}>
            {t(".user_universe")}
          </Link>
        ),
        text: "%{user_universe}"
      }
    ]

    return (
      <TextComponentReplace
        replaces={replaces}
        text={t(".click_here_to_access_the_user_universe")}
      />
    )
  }

  onRequestMenuClose = () => this.setState({menuTriggered: false})

  onTriggerMenu = (e) => {
    e.preventDefault()

    this.setState({menuTriggered: !this.s.menuTriggered})
  }

  calculateNoAccess = () => {
    if (this.p.noAccess || this.s.noAccess) return true
    if (this.p.requireAdmin && this.tt.currentUser && !isUserA(this.tt.currentUser, ["admin", "hacker", "sales_rep"])) return true
    if (this.p.requireAdmin && !this.tt.currentUser) return true

    return false
  }
}))
