import {Country} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectCountries,
  withInputContainer: CountriesSelectWithContainer,
  withMemo: ComponentsCountriesSelect
} = selectComponent({
  className: "components--countries--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Country

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Country: ["countryCode", "id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const countries = await query.toArray()

    return countries.map((country) => ({
      country,
      text: country.name(),
      value: country.id()
    }))
  }
})

export default ComponentsCountriesSelect
export {AdminSelectCountries, CountriesSelectWithContainer}
