import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import React from "react"
export default memo(shapeComponent(class ComponentsFrontLayoutBackLink extends ShapeComponent {
  render() {
    const {children, className, ...restProps} = this.props

    return (
      <Link className={classNames("components--front-layout--back-link", className)} {...restProps}>
        <i className="fa fa-chevron-left mr-1" />
        {children}
      </Link>
    )
  }
}))
