import {EmailTemplate, EmailTemplatePreview} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CkeditorPresentation from "ckeditor/presentation"
import ContentsErrors from "components/contents/errors"
import {digg} from "diggerize"
import Heading from "components/utils/heading"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Text} from "shared/base"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedEmailTemplatePreviewAttributes = ["createdAt", "emailTemplateId", "id", "name", "updatedAt"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminEmailTemplatesPreviewsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.email_templates.previews.show"})
    const {emailTemplate, emailTemplateId} = useModel(EmailTemplate, {
      match: this.p.match,
      select: {
        EmailTemplate: ["id"]
      }
    })
    const {emailTemplatePreview, emailTemplatePreviewId} = useModel(EmailTemplatePreview, {
      abilities: {
        EmailTemplatePreview: ["destroy", "edit"]
      },
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        EmailTemplatePreview: selectedEmailTemplatePreviewAttributes
      }
    })

    this.setInstance({emailTemplate, emailTemplateId, emailTemplatePreview, emailTemplatePreviewId, t})
    this.useStates({
      errors: undefined,
      parsedContent: undefined
    })

    useMemo(() => {
      if (emailTemplatePreview) {
        this.loadParsedContent()
      }
    }, [emailTemplatePreview?.id()])
  }

  async loadParsedContent() {
    const {emailTemplatePreview} = this.tt
    const result = await emailTemplatePreview.customParsedContent({include_errors: true})
    const parsedContent = digg(result, "parsed_content")
    const errors = digg(result, "errors")

    this.setState({
      errors,
      parsedContent
    })
  }

  render() {
    const {emailTemplatePreview, t} = this.tt
    const {errors, parsedContent} = this.s

    return (
      <AdminLayout
        actions={this.actions()}
        active="email-templates"
        className="routes--admin--email-templates--previews--show"
        headerTitle={emailTemplatePreview?.name()}
      >
        {emailTemplatePreview &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={emailTemplatePreview} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${EmailTemplate.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={emailTemplatePreview}
                />
              </React.Fragment>
            )}
            <Heading>{t(".preview")}</Heading>
            {errors?.length > 0 &&
              <View dataSet={{class: "liquid-errors"}} style={{marginTop: 40, marginBottom: 40}}>
                <Text style={{color: "red", fontSize: 24, fontWeight: "bold"}}>
                  {t(".error_occurred_while_generating_the_preview")}
                </Text>
                <ContentsErrors errors={errors} />
              </View>
            }
            {parsedContent &&
              <CkeditorPresentation html={parsedContent} />
            }
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {emailTemplatePreview} = this.tt

    return (
      <AdminShowActions
        editPath={Routes.editAdminEmailTemplatePreviewPath(emailTemplatePreview?.emailTemplateId(), emailTemplatePreview?.id())}
        model={emailTemplatePreview}
      />
    )
  }
}))
