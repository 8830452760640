import {HelperText, TextInput} from "react-native-paper"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsAdminInput extends ShapeComponent {
  static defaultProps = {
    autoRows: false,
    required: false
  }

  static propTypes = {
    autoRows: PropTypes.bool.isRequired,
    containerProps: PropTypes.object,
    label: PropTypes.string,
    minimumRows: PropTypes.number,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onChangeText: PropTypes.func,
    required: PropTypes.bool.isRequired,
    style: PropTypes.object,
    styles: PropTypes.object
  }

  setup() {
    const {inputProps, restProps: restPropsFromUseInput, wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "input"}})
    const {name} = inputProps
    const {errors} = digs(wrapperOpts, "errors")

    this.setInstance({errors, inputProps, restPropsFromUseInput, wrapperOpts})
    this.form = useForm()
    this.useStates({
      value: inputProps.value || inputProps.defaultValue || ""
    })

    // Set initial value in form
    useMemo(() => {
      if (this.form && name) {
        this.form.setValue(name, this.s.value)
      }
    }, [])
  }

  render() {
    const {errors, inputProps, restPropsFromUseInput, wrapperOpts} = this.tt
    const {
      autoRows,
      attribute,
      containerProps,
      defaultValue,
      label,
      minimumRows,
      model,
      name,
      onChangeText,
      required,
      style,
      styles,
      value,
      ...restProps
    } = restPropsFromUseInput
    const actualStyle = this.stylingFor("textInput", Object.assign(
      {},
      style
    ))
    const textInputExtraProps = {}
    const actualValue = this.value()

    if (autoRows && this.props.multiline && !("rows" in this.props)) {
      // If no rows prop is passed, calculate the number of rows based on the value
      let rows = actualValue ? actualValue.split("\n").length : 1

      if (minimumRows && minimumRows > rows) {
        rows = minimumRows
      }

      if (!("whiteSpace" in actualStyle)) {
        actualStyle.whiteSpace = "nowrap"
      }

      textInputExtraProps.rows = rows
    }

    return (
      <View {...containerProps}>
        <TextInput
          dataSet={{id: inputProps.id}}
          error={errors.length > 0}
          label={`${wrapperOpts.label}${required ? "*" : ""}`}
          onChangeText={this.tt.onChangeText}
          placeholderTextColor="#b0b0b0"
          style={actualStyle}
          value={actualValue}
          {...textInputExtraProps}
          {...restProps}
        />
        {errors.length > 0 &&
          <HelperText type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  errorMessages () {
    const {errors} = this.tt
    const errorMessages = []

    for (const error of errors) {
      for (const errorMessage of error.getErrorMessages()) {
        errorMessages.push(errorMessage)
      }
    }

    return errorMessages
  }

  onChangeText = (value) => {
    const {form, inputProps} = this.tt
    const {onChangeText} = this.props
    const {name} = inputProps

    this.setState({value})

    if (form && name) {
      form.setValue(name, value)
    }

    if (onChangeText) {
      onChangeText(value)
    }
  }

  value() {
    if ("value" in this.props) {
      return this.p.value
    }

    return this.s.value
  }
}))
