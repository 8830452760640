import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroup} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsAdminSchoolClassGroupsNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    schoolClassGroup: PropTypes.instanceOf(SchoolClassGroup).isRequired
  }

  render() {
    const {t} = useI18n({namespace: "js.components.admin.school_class_groups.nav"})
    const {active, className, schoolClassGroup, ...restProps} = this.props

    return (
      <Nav className={classNames("components--admin--school-class-groups--nav", className)} {...restProps}>
        <NavItem active={active} identifier="general" label={t("js.shared.general")} to={Routes.adminSchoolClassGroupPath(schoolClassGroup.id())} />
        <NavItem
          active={active}
          identifier="class-steps"
          label={SchoolClassGroup.humanAttributeName("classSteps")}
          to={Routes.adminSchoolClassGroupClassStepsPath(schoolClassGroup.id())}
        />
      </Nav>
    )
  }
}))
