import React, {useMemo} from "react"
import {School, Workplace, WorkplaceLink} from "models"
import {Shape, shapeComponent} from "set-state-compare/src/use-shape.js"
import Button from "components/utils/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSchoolsMergeModal extends Shape {
  setup() {
    const {t} = useI18n({namespace: "js.components.schools.merge_modal"})

    this.t = t

    useMemo(() => {
      this.loadSchoolsCount()
    }, [])

    this.useStates({
      schoolsCount: undefined
    })
  }

  async loadSchoolsCount() {
    const currentWorkplaceResult = await Workplace.current()
    const currentWorkplace = digg(currentWorkplaceResult, "current", 0)
    const schoolsCount = await WorkplaceLink
      .ransack({
        resource_type_eq: "School",
        workplace_id_eq: currentWorkplace.id()
      })
      .count()

    this.set({schoolsCount})
  }

  render() {
    const {t} = this

    return (
      <Modal {...this.props}>
        {this.s.schoolsCount === undefined &&
          <LoadingSpinner />
        }
        {this.s.schoolsCount !== undefined && this.s.schoolsCount < 2 &&
          <Text>{t(".you_havent_chosen_enough_schools")}</Text>
        }
        {this.s.schoolsCount !== undefined && this.s.schoolsCount >= 2 &&
          <>
            <Text dataSet={{class: "about-to-merge-schools-text"}}>
              {t(".you_are_about_to_merge_count_schools", {count: this.s.schoolsCount})}
            </Text>
            <Button dataSet={{class: "merge-schools-button"}} onPress={this.onMergeSchoolsPressed} title={t("js.shared.merge")} />
          </>
        }
      </Modal>
    )
  }

  onMergeSchoolsPressed = async () => {
    const {t} = this

    await loading(t(".merging_schools"), async () => {
      await School.merge()
      FlashMessage.success(t(".the_schools_were_merged"))
      this.p.onRequestClose()
    })
  }
}))
