import {Account} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: AccountsSelectWithContainer, withMemo: ComponentsAccountsSelect} = selectComponent({
  className: "components--accounts--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Account

    query = query
      .ransack({s: "name asc"})
      .select({Account: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const accounts = await query.toArray()

    return accounts.map((account) => ({
      text: account.name(),
      value: account.id()
    }))
  }
})

export default ComponentsAccountsSelect
export {AccountsSelectWithContainer}
