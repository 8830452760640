import {ContactRelationship, SchoolClassInvitation} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import RadioButton from "components/utils/radio-button"
import {Text} from "shared/base"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolRolesChangeRoleModal extends ShapeComponent {
  static propTypes = propTypesExact({
    onRequestClose: PropTypes.func.isRequired,
    schoolRole: PropTypes.instanceOf(ContactRelationship).isRequired
  })

  setup() {
    const {locale, t} = useI18n({namespace: "js.components.school_roles.change_role_modal"})

    this.useStates({
      role: this.p.schoolRole.contactRelationshipType().identifier()
    })

    this.translatedRolesCollection = useMemo(
      () => TranslatedCollections.get(SchoolClassInvitation, "role"),
      [locale]
    )

    this.setInstance({locale, t})
  }

  render() {
    const {translatedRolesCollection, t} = this.tt
    const {className, schoolRole, ...restProps} = this.props

    return (
      <Modal className={classNames("components--school-roles--change-role-modal", className)} {...restProps}>
        <View>
          <Text style={{marginBottom: 30, fontSize: 32, textAlign: "center"}}>
            {t(".change_role")}
          </Text>
        </View>
        <View>
          <Text style={{marginBottom: 10, fontSize: 20, textAlign: "center"}}>
            {t(".you_can_change_the_role_for_below")}
          </Text>
        </View>
        <View>
          <Text style={{marginBottom: 30, fontSize: 20, fontWeight: "bold", textAlign: "center"}}>
            {schoolRole.child().user().name()}
          </Text>
        </View>
        <View style={{marginBottom: 30}}>
          <View style={{marginRight: "auto", marginLeft: "auto"}}>
            {translatedRolesCollection.map(({translation, value}) =>
              <View key={value}>
                <RadioButton
                  checked={value == this.s.role}
                  dataSet={{class: "role-radio-button", value}}
                  fontStyle={{fontSize: 20}}
                  label={translation}
                  onChange={this.tt.onRoleChanged}
                  style={{marginBottom: 10}}
                  value={value}
                />
              </View>
            )}
          </View>
        </View>
        <Button
          dataSet={{class: "update-role-button"}}
          fontStyle={{fontSize: 20}}
          onPress={this.tt.onUpdateRolePressed}
          style={{marginBottom: 15}}
          title={t(".update_role")}
        />
        <Button color="grey" fontStyle={{fontSize: 20}} onPress={this.tt.onGoBackPressed} title={t(".go_back")} />
      </Modal>
    )
  }

  onGoBackPressed = () => this.p.onRequestClose()
  onRoleChanged = ({value}) => this.setState({role: value})

  onUpdateRolePressed = async () => {
    try {
      await this.p.schoolRole.update({relationshipType: this.s.role})
      FlashMessage.success(this.t(".the_role_was_updated"))
      this.p.onRequestClose()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
