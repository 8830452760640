import {Account, ClassStep} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
export default memo(shapeComponent(class RoutesAdminAccountsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.accounts.edit"})
    const {account, accountId} = useModel(Account, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        Account: ["id", "name"]
      }
    })

    this.setInstance({account, accountId, t})
    this.useStates({classStepsCollection: undefined})

    useMemo(() => {
      this.loadClassSteps()
    }, [])
  }

  async loadClassSteps() {
    const classSteps = await ClassStep
      .ransack({s: "position"})
      .select({ClassStep: ["id", "name"]})
      .toArray()

    this.setState({
      classStepsCollection: classSteps.map((classStep) => [classStep.name(), classStep.id()])
    })
  }

  render() {
    const {account} = this.tt

    return (
      <AdminLayout active="accounts" className="routes--admin--accounts--edit" headerTitle={this.headerTitle()}>
        {account &&
          <form onSubmit={this.tt.onSubmit}>
            <Input
              attribute="name"
              model={account}
            />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {account} = this.tt

    if (account?.isNewRecord()) {
      return this.t(".add_new_account")
    } else if (account?.isPersisted()) {
      return this.t(".edit_account", {name: account.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {account} = this.tt

    if (account.isNewRecord()) formData.append("account[new_id]", account.id())

    try {
      await account.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_account_was_saved"))
      AppHistory.push(Routes.adminAccountPath(account.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
