import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroup} from "models"
import SchoolClassGroupsTable from "components/school-class-groups/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminSchoolClassGroupsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_class_groups.index"})

    this.canCan = useCanCan(() => [[SchoolClassGroup, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="school-class-groups"
        className="routes--admin-school-class-groups--index"
        headerTitle={SchoolClassGroup.modelName().human({count: 2})}
      >
        <SchoolClassGroupsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", SchoolClassGroup) &&
          <Button
            className="new-school-class-group-button"
            icon="plus"
            label={t(".add_new_school_class_group")}
            to={Routes.newAdminSchoolClassGroupPath()}
          />
        }
      </>
    )
  }
}))
