import {NewsletterTemplate, NewsletterTemplateTableSearch} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplatesSelectWithContainer} from "components/newsletter-templates/select"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {TableSearchesSelectWithContainer} from "components/table-searches/select"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplateTableSearchsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {newsletterTemplate, newsletterTemplateId} = useModel(NewsletterTemplate, {match: this.p.match})
    const {newsletterTemplateTableSearch, newsletterTemplateTableSearchId} = useModel(
      NewsletterTemplateTableSearch,
      {
        match: this.p.match,
        newIfNoId: {
          defaults: () => ({
            id: uuidv4(),
            newsletterTemplateId
          })
        },
        select: {
          NewsletterTemplateTableSearch: ["newsletterTemplateId", "id", "tableSearchId"]
        }
      }
    )

    this.setInstance({newsletterTemplate, newsletterTemplateId, newsletterTemplateTableSearch, newsletterTemplateTableSearchId})
  }

  render() {
    const {newsletterTemplateTableSearch, onSubmit} = this.tt

    return (
      <AdminLayout active="newsletter-templates" className="routes--admin--newsletter-templates--table-searches--edit" headerTitle={this.headerTitle()}>
        {newsletterTemplateTableSearch &&
          <form onSubmit={onSubmit}>
            <NewsletterTemplatesSelectWithContainer
              attribute="newsletterTemplateId"
              label={NewsletterTemplateTableSearch.humanAttributeName("newsletterTemplate")}
              model={newsletterTemplateTableSearch}
            />
            <TableSearchesSelectWithContainer
              attribute="tableSearchId"
              label={NewsletterTemplateTableSearch.humanAttributeName("tableSearch")}
              model={newsletterTemplateTableSearch}
            />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {newsletterTemplateTableSearch, newsletterTemplateTableSearchId} = this.tt

    if (newsletterTemplateTableSearch?.isNewRecord()) {
      return I18n.t("js.routes.admin.newsletter_templates.table_searches.edit.add_table_search_to_newsletter_template")
    } else if (newsletterTemplateTableSearch?.isPersisted()) {
      const name = newsletterTemplateTableSearchId

      return I18n.t("js.routes.admin.newsletter_templates.table_searches.edit.edit_table_search_on_newsletter_template", {name})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {newsletterTemplateId, newsletterTemplateTableSearch} = this.tt

    if (newsletterTemplateTableSearch.isNewRecord()) {
      formData.append("newsletter_template_table_search[new_id]", newsletterTemplateTableSearch.id())
      formData.append("newsletter_template_table_search[newsletter_template_id]", newsletterTemplateId)
    }

    try {
      await newsletterTemplateTableSearch.saveRaw(formData, {form})
      FlashMessage.success(I18n.t("js.routes.admin.newsletter_templates.table_searches.edit.the_table_search_on_the_newsletter_template_was_saved"))
      AppHistory.push(Routes.adminNewsletterTemplateTableSearchesPath(newsletterTemplateTableSearch.newsletterTemplateId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
