import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import {ContentPieceType} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import useModel from "@kaspernj/api-maker/build/use-model"
const selectedContentPieceTypeAttributes = ["createdAt", "icon", "id", "name", "showOnDigitalClassRoom", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedContentPieceTypeAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminContentPieceTypesShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {contentPieceType, contentPieceTypeId} = useModel(ContentPieceType, {
      abilities: {
        ContentPieceType: ["destroy", "edit"]
      },
      match: this.p.match,
      select: {
        ContentPieceType: selectedContentPieceTypeAttributes
      }
    })

    this.setInstance({contentPieceType, contentPieceTypeId})
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="content-piece-types"
        className="routes--admin--content-piece-types--show"
        headerTitle={this.tt.contentPieceType?.name()}
      >
        {this.tt.contentPieceType &&
          <>
            <AttributeRows attributes={["id", "name", "icon", "showOnDigitalClassRoom", "createdAt", "updatedAt"]} model={this.tt.contentPieceType} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ContentPieceType.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={this.tt.contentPieceType}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {contentPieceType} = this.tt

    return (
      <AdminShowActions model={contentPieceType} />
    )
  }
}))
