import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import AppHistory from "shared/history"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import {Notification} from "models"
import NotificationTypeLink from "components/notification-types/link"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"

export default memo(shapeComponent(class ComponentsNotificationsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.notifications.table"})

    this.t = t
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components--notifications--table", className)}
        columns={this.tt.columns}
        modelClass={Notification}
        select={{
          Notification: ["id"],
          NotificationType: ["id"],
          User: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.notificationTypeContent,
      label: Notification.humanAttributeName("notificationType"),
      path: ["notificationType"],
      sortKey: "notificationTypeCurrentTranslationName"
    },
    {
      attribute: "firstName",
      content: this.tt.userContent,
      label: Notification.humanAttributeName("user"),
      path: ["user", "contact"],
      sortKey: "userContactFirstName"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  notificationTypeContent = ({notification}) => notification.notificationType() &&
    <NotificationTypeLink notificationType={notification.notificationType()} {...linkProps(this.props)} />

  userContent = ({notification}) => notification.user() && <UserLink user={notification.user()} {...linkProps(this.props)} />
}))
