import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactor} from "models"
import ScoreFactorsTable from "components/score-factors/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminScoreFactorsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.score_factors.index"})

    this.canCan = useCanCan(() => [[ScoreFactor, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="score-factors"
        className="routes--admin-score-factors--index"
        headerTitle={ScoreFactor.modelName().human({count: 2})}
      >
        <ScoreFactorsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", ScoreFactor) &&
          <Button
            className="new-score-factor-button"
            icon="plus"
            label={t(".add_new_score_factor")}
            to={Routes.newAdminScoreFactorPath()}
          />
        }
      </>
    )
  }
}))
