import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Account} from "models"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import React from "react"
import setLayout from "shared/set-layout"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminAccountsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {account, accountId} = useModel(Account, {
      abilities: {
        Account: ["destroy", "edit"]
      },
      match: this.p.match,
      select: {
        Account: ["createdAt", "id", "name", "updatedAt"]
      }
    })

    this.setInstance({account, accountId})
  }

  render() {
    const {account} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="accounts"
        className="routes--admin--accounts--show"
        headerTitle={account?.name()}
      >
        {account &&
          <>
            <Nav account={account} active="account-users" className="mb-4" />
            <AttributeRows attributes={["id", "name", "createdAt", "updatedAt"]} model={account} />
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {account} = this.tt

    return (
      <AdminShowActions model={account} />
    )
  }
}))
