import {PlanResourcePeriod} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: PlanResourcePeriodsSelectWithContainer, withMemo: ComponentsPlanResourcePeriodsSelect} = selectComponent({
  className: "components--plan-resources--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || PlanResourcePeriod

    query = query
      .ransack({s: "months asc"})
      .select({PlanResourcePeriod: ["id", "months"]})
      .limit(20)

    if (searchValue) query.ransack({resource_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const planResourcePeriods = await query.toArray()

    return planResourcePeriods.map((planResourcePeriod) => ({
      text: planResourcePeriod.months(),
      value: planResourcePeriod.id()
    }))
  }
})

export default ComponentsPlanResourcePeriodsSelect
export {PlanResourcePeriodsSelectWithContainer}
