import {Role} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: RolesSelectWithContainer, withMemo: ComponentsRolesSelect} = selectComponent({
  className: "components--roles--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Role

    query = query
      .ransack({s: "identifier asc"})
      .select({Role: ["id", "translatedIdentifier"]})
      .limit(20)

    if (searchValue) query.ransack({identifier_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const roles = await query.toArray()

    return roles.map((role) => ({
      text: role.translatedIdentifier(),
      value: role.id()
    }))
  }
})

export default ComponentsRolesSelect
export {RolesSelectWithContainer}
