import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountLink from "components/accounts/link"
import {AccountUser} from "models"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import UserLink from "components/users/link"

export default memo(shapeComponent(class ComponentsAccountUsers extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    this.currentUser = useCurrentUser()
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--account-users--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={AccountUser}
        preloads={["account", "user"]}
        select={{
          Account: ["id", "name"],
          AccountUser: ["id"],
          User: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        content: this.tt.accountContent,
        label: AccountUser.humanAttributeName("account"),
        sortKey: "currentTranslationName"
      },
      {
        content: this.tt.userContent,
        label: AccountUser.humanAttributeName("user"),
        sortKey: "userContactfirstName"
      },
      {
        attribute: "translatedRole",
        label: AccountUser.humanAttributeName("role"),
        sortKey: "role"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.tt.currentUser)

    return columns
  }

  editModelPath = ({accountUser}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminAccountUserPath(accountUser.id())
  }

  viewModelPath = ({accountUser}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminAccountUserPath(accountUser.id())
  }

  accountContent = ({accountUser}) => accountUser.account() && <AccountLink account={accountUser.account()} {...linkProps(this.props)} />
  userContent = ({accountUser}) => accountUser.user() && <UserLink user={accountUser.user()} {...linkProps(this.props)} />
}))
