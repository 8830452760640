import {BrainBreak, Challenge, CourseModule, SurveyStepElement, TeamSurvey} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import Select from "components/inputs/select"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"
export default memo(shapeComponent(class RoutesAdminSurveyStepElementsPreview extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.survey_step_elements.preview"})
    const {surveyStepElement, surveyStepElementId} = useModel(SurveyStepElement, {
      abilities: {
        SurveyStepElement: ["adminPreview"]
      },
      match: this.p.match,
      select: {
        SurveyStepElement: ["id", "name"]
      }
    })

    const asOptions = useMemo(
      () => [
        {
          text: BrainBreak.modelName().human(),
          value: "brain_break"
        },
        {
          text: Challenge.modelName().human(),
          value: "challenge"
        },
        {
          text: CourseModule.modelName().human(),
          value: "course_module"
        },
        {
          text: TeamSurvey.modelName().human(),
          value: "team_survey"
        }
      ],
      [locale]
    )

    this.setInstance({asOptions, surveyStepElement, surveyStepElementId, t})
    useAdminLayout()?.setState({
      active: "surveys",
      headerTitle: surveyStepElement?.name(),
      noAccess: surveyStepElement && !surveyStepElement.can("adminPreview")
    })
  }

  render() {
    const {surveyStepElement} = this.tt

    return (
      <View dataSet={{route: "admin--survey-step-elements--preview"}}>
        {surveyStepElement?.can("adminPreview") && this.content()}
      </View>
    )
  }

  content() {
    return (
      <form onSubmit={this.tt.onSubmit}>
        <Select
          attribute="as"
          id="preview_as"
          label={this.t(".preview_as")}
          name="preview_as"
          options={this.tt.asOptions}
          type="hayaSelect"
        />
        <Button label={this.t(".preview")} primary />
      </form>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {surveyStepElement} = this.tt
    const form = digg(e, "target")
    const formData = new FormData(form)

    try {
      await loading(this.t("js.shared.loading"), async () => {
        const result = await surveyStepElement.adminPreview(formData)
        const url = digg(result, "url")

        AppHistory.push(url)
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
