import {CountryState} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectCountryStates,
  withInputContainer: CountryStatesSelectWithContainer,
  withMemo: ComponentsCountryStatesSelect
} = selectComponent({
  className: "components--country-states--select",
  optionsCallback: async ({limit, query, searchValue, values}) => {
    query = query?.clone() || CountryState

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({CountryState: ["id", "name"]})

    if (limit !== null) {
      query.limit(limit)
    }

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const countryStateStates = await query.toArray()

    return countryStateStates.map((countryState) => ({
      text: countryState.name(),
      value: countryState.id()
    }))
  }
})

export default ComponentsCountryStatesSelect
export {AdminSelectCountryStates, CountryStatesSelectWithContainer}
