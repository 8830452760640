import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import {ContentPieceType} from "models"
import ContentPieceTypesTable from "components/content-piece-types/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminContentPieceTypesIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.content_piece_types.index"})

    this.canCan = useCanCan(() => [[ContentPieceType, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="content-piece-types"
        className="routes--admin-content-piece-types--index"
        headerTitle={ContentPieceType.modelName().human({count: 2})}
      >
        <ContentPieceTypesTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", ContentPieceType) &&
          <Button
            className="new-content-piece-type-button"
            icon="plus"
            label={t(".add_new_content_piece_type")}
            to={Routes.newAdminContentPieceTypePath()}
          />
        }
      </>
    )
  }
}))
