import React, {useCallback} from "react"
import {Country} from "models"
import {CurrenciesSelectWithContainer} from "components/currencies/select"
import Link from "@kaspernj/api-maker/build/link"
import {LocalesSelectWithContainer} from "components/locales/select"
import Routes from "shared/routes"
import {StripeTaxRatesSelectWithContainer} from "components/stripe-tax-rates/select"

const DefaultCurrency = ({country}) => country.defaultCurrency() &&
  <Link to={Routes.superAdminPath({model: "Currency", model_id: country.defaultCurrencyId()})}>
    {country.defaultCurrency().name()}
  </Link>

export default {
  edit: {
    attributes: [
      {attribute: "countryCode"},
      {attribute: "firstSchoolDate"},
      {attribute: "name", translated: true},
      {
        attribute: "defaultCurrencyId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <CurrenciesSelectWithContainer
              label={Country.humanAttributeName("defaultCurrency")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        },
        label: Country.humanAttributeName("defaultCurrency")
      },
      {
        attribute: "defaultLocaleId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <LocalesSelectWithContainer
              label={Country.humanAttributeName("defaultLocale")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        },
        label: Country.humanAttributeName("defaultLocale")
      },
      {
        attribute: "stripeTaxRateId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <StripeTaxRatesSelectWithContainer
              label={Country.humanAttributeName("stripeTaxRate")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        },
        label: Country.humanAttributeName("stripeTaxRate")
      }
    ]
  },
  show: {
    attributesToShow: () => [
      "id",
      {attribute: "defaultCurrencyId", content: DefaultCurrency},
      "name",
      "firstSchoolDate"
    ],
    relationships: ["default_currency"],
    extraSelect: {
      Country: ["name"],
      Currency: ["id", "name"]
    }
  }
}
