import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import instanceOfClassName from "@kaspernj/api-maker/build/instance-of-class-name"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsSurveyStepsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    surveyStep: instanceOfClassName("SurveyStep").isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, surveyStep, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--survey-steps--link", className)

    if (adminLayout) {
      return (
        <Link className={generatedClassName} to={this.to()} {...restProps}>
          {children || surveyStep.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || surveyStep.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, surveyStep} = this.p

    if (adminLayout) {
      return Routes.adminSurveyStepPath(surveyStep.id())
    }

    return "#"
  }
}))
