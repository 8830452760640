import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import {NotificationType} from "models"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsNotificationTypesCloneButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    onCloned: PropTypes.func,
    notificationType: PropTypes.instanceOf(NotificationType).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.notification_types.clone_button"})

    this.t = t
    this.useStates({
      disabled: false
    })
  }

  render() {
    const {className, onCloned, notificationType, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--notification-types--clone-button", "action-button", className)}
        disabled={this.s.disabled}
        icon="clone"
        label={this.t(".clone_notification_type")}
        onClick={this.tt.onCloneButtonClicked}
        {...restProps}
      />
    )
  }

  onCloneButtonClicked = async (e) => {
    e.preventDefault()

    const {notificationType} = this.p

    try {
      this.setState({disabled: true})

      await loading(this.t(".cloning_notification_type"), async () => {
        const result = await notificationType.clone()
        const clonedNotificationType = digg(result, "cloned_notification_type")

        FlashMessage.success(this.t(".the_notification_type_was_cloned"))
        if (this.props.onCloned) this.props.onCloned({clonedNotificationType})
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
