import {SchoolClass, SchoolClassBrainBreak} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import CircleIconButton from "components/circle-icon-button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useHasUnicorn from "components/subscriptions/use-has-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserSchoolClassesDigitalClassRoomBrainBreakButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})

    this.currentUser = useCurrentUser()
    this.useStates({
      brainBreaksLastWeekCount: undefined,
      disabled: false,
      unicornSubscriptionModal: false
    })

    const {hasUnicorn} = useHasUnicorn({schoolClassId: this.p.schoolClass.id(), user: this.currentUser})

    this.setInstance({hasUnicorn, t})
  }

  render() {
    const {className, disabled, schoolClass, ...restProps} = this.props
    const {unicornSubscriptionModal} = this.s
    const {hasUnicorn, t} = this.tt

    return (
      <>
        {unicornSubscriptionModal &&
          <UnicornUpgradeOverlay schoolClass={schoolClass} />
        }
        {hasUnicorn !== undefined &&
          <CircleIconButton
            className={classNames("components--user--school-classes--digital-class-room--brain-break-button", className)}
            color="magenta"
            data-has-unicorn={hasUnicorn}
            disabled={this.s.disabled || disabled || false}
            icon="brain"
            label={t(".brain_break")}
            onClick={this.tt.onClick}
            {...restProps}
          />
        }
      </>
    )
  }

  async countBrainBreaksLastWeek() {
    const weekAgo = moment().subtract(1, "week").toDate()
    const brainBreaksLastWeekCount = await SchoolClassBrainBreak
      .ransack({
        created_at_gteq: weekAgo,
        school_class_id_eq: this.p.schoolClass.id()
      })
      .count()

    this.setState({brainBreaksLastWeekCount})
  }

  onClick = async (e) => {
    e.preventDefault()

    await this.countBrainBreaksLastWeek()

    const {brainBreaksLastWeekCount} = this.s

    if (this.tt.hasUnicorn === false && brainBreaksLastWeekCount >= 1) {
      this.setState({unicornSubscriptionModal: true})
    } else {
      try {
        this.setState({disabled: true})

        const result = await this.p.schoolClass.randomBrainBreak()
        const schoolClassBrainBreak = digg(result, "school_class_brain_break")
        const teamSurvey = digg(result, "team_survey")

        AppHistory.push(Routes.userSchoolClassBrainBreakPath(schoolClassBrainBreak.id(), {team_survey_id: teamSurvey.id()}))
      } catch (error) {
        FlashMessage.errorResponse(error)
      } finally {
        this.setState({disabled: false})
      }
    }
  }

  onUnicornSubscriptionOverlayRequestClose = () => {
    this.setState({unicornSubscriptionModal: false})
  }
}))
