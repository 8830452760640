import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import ContactLink from "components/contacts/link"
import {ContactRelationship} from "models"
import ContactRelationshipTypeLink from "components/contact-relationship-types/link"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsContactRelationshipsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--contact-relationships--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={ContactRelationship}
        preload={["child", "parent"]}
        select={{
          Contact: ["firstName", "id", "lastName"],
          ContactRelationship: ["id"],
          ContactRelationshipType: ["id"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.parentContent,
      label: ContactRelationship.humanAttributeName("parent"),
      path: ["parent"],
      sortKey: "parentName"
    },
    {
      attribute: "name",
      content: this.tt.childContent,
      label: ContactRelationship.humanAttributeName("child"),
      path: ["child"],
      sortKey: "childName"
    },
    {
      attribute: "name",
      content: this.tt.contactRelationshipTypeContent,
      label: ContactRelationship.humanAttributeName("contactRelationshipType"),
      path: ["contactRelationshipType"],
      sortKey: "contactRelationshipTypeName"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  childContent = ({contactRelationship}) => <ContactLink contact={contactRelationship.child()} {...linkProps(this.props)} />
  contactRelationshipTypeContent = ({contactRelationship}) =>
    <ContactRelationshipTypeLink
      adminLayout
      contactRelationshipType={contactRelationship.contactRelationshipType()}
    />

  parentContent = ({contactRelationship}) => <ContactLink contact={contactRelationship.parent()} {...linkProps(this.props)} />

  editModelPath = ({contactRelationship}) => {
    if (this.p.adminLayout) return Routes.editAdminContactRelationshipPath(contactRelationship.id())
  }
}))
