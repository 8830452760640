import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import insightsTopScoreFactor from "components/school-classes/insights-top-score-factor"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {ScoreFactor} from "models"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserInsightsTopScoreFactor extends ShapeComponent {
  static defaultProps = {
    card: true,
    schoolClassCheckInsQuery: null,
    teamSurveysQuery: null
  }

  static propTypes = {
    card: PropTypes.bool.isRequired,
    className: PropTypes.string,
    mode: PropTypes.oneOf(["negative", "positive"]).isRequired,
    schoolClassCheckInsQuery: PropTypes.object,
    teamSurveysQuery: PropTypes.object
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.user.insights.top_score_factor"})

    this.setInstance({t})
    this.useStates({
      topScoreFactor: undefined
    })

    useMemo(() => {
      if (this.p.schoolClassCheckInsQuery || this.p.teamSurveysQuery) {
        this.calculateTopScoreFactor()
      } else {
        this.setState({topScoreFactor: undefined})
      }
    }, [this.p.schoolClassCheckInsQuery, this.p.teamSurveysQuery])
  }

  async calculateTopScoreFactor() {
    const {mode, schoolClassCheckInsQuery, teamSurveysQuery} = this.p
    let loadingText

    if (mode == "negative") {
      loadingText = this.t(".top_score_factor_negative")
    } else if (mode == "positive") {
      loadingText = this.t(".top_score_factor_positive")
    } else {
      throw new Error(`Unhandled mode: ${mode}`)
    }

    await loading(loadingText, async () => {
      const topScoreFactorReducedModel = await insightsTopScoreFactor({mode, schoolClassCheckInsQuery, teamSurveysQuery})
      let topScoreFactor

      if (topScoreFactorReducedModel) {
        topScoreFactor = await ScoreFactor
          .ransack({id_eq: topScoreFactorReducedModel.id()})
          .select({ScoreFactor: ["name"]})
          .first()
      }

      this.setState({topScoreFactor})
    })
  }

  render() {
    const {card, className, mode, schoolClassCheckInsQuery, teamSurveysQuery, ...restProps} = this.props
    const {topScoreFactor} = this.s
    const mainElementProps = {
      dataSet: {
        class: classNames("components--user--insights--top-score-factor", className),
        mode
      },
      ...restProps
    }

    if (card) {
      return (
        <UtilsCard {...mainElementProps}>
          <View
            dataSet={{class: "top-score-factor-header"}}
            style={{flexDirection: "row", alignItems: "center", marginBottom: 11}}
          >
            <Text
              style={{
                color: "#666",
                fontSize: 32,
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {this.cardHeader()}
            </Text>
            {mode == "positive" &&
              <FontAwesomeIcon
                name="thumbs-up"
                style={{
                  marginLeft: 9,
                  color: "#666",
                  fontSize: 32,
                  fontWeight: "bold"
                }}
              />
            }
            {mode == "negative" &&
              <FontAwesomeIcon
                name="thumbs-down"
                style={{
                  marginLeft: 9,
                  color: "#666",
                  fontSize: 32,
                  fontWeight: "bold"
                }}
              />
            }
          </View>
          {this.content()}
        </UtilsCard>
      )
    }

    return (
      <Text {...mainElementProps}>
        {topScoreFactor?.name() || "-"}
      </Text>
    )
  }

  content() {
    return (
      <Text
        dataSet={{class: "top-score-factor-container"}}
        style={{
          color: "#4c93ff",
          fontSize: 50,
          fontWeight: "bold",
          textAlign: "center"
        }}
      >
        {this.s.topScoreFactor?.name() || "-"}
      </Text>
    )
  }

  cardHeader() {
    if (this.p.mode == "negative") return this.t("js.routes.user.insights.top_negative")
    if (this.p.mode == "positive") return this.t("js.routes.user.insights.top_positive")

    throw new Error(`Unhandled mode: ${this.p.mode}`)
  }
}))
