import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
import usersUpdateSession from "components/users/update-session"

export default memo(shapeComponent(class ComponentsUsersCloneButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    onSignedIn: PropTypes.func,
    user: PropTypes.instanceOf(User).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.users.sign_in_as_button"})

    this.t = t
    this.useStates({
      disabled: false
    })
  }

  render() {
    const {className, onSignedIn, user, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--users--sign-in-as-button", "action-button", className)}
        disabled={this.s.disabled}
        icon="user"
        onClick={this.tt.onSignInAsUserClicked}
        {...restProps}
      >
        {this.t(".sign_in_as_user")}
      </Button>
    )
  }

  onSignInAsUserClicked = async (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    try {
      this.setState({disabled: true})
      await this.p.user.signInAs()
      await usersUpdateSession(this.p.user.id())
      FlashMessage.success(this.t(".you_were_signed_in_as_the_user"))
      if (this.props.onSignedIn) this.props.onSignedIn()
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
