import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import {User} from "models"
export default memo(shapeComponent(class ComponentsUsersConfirmation extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.users.confirmation"})

    this.currentUser = useCurrentUser()
    this.queryParams = useQueryParams()
    this.t = t

    this.useStates({
      state: "confirming"
    })

    useMemo(() => {
      this.confirmEmail()
    }, [])
  }

  render() {
    const {currentUser, t} = this.tt
    const {className, ...restProps} = this.props
    let mainCardHeader
    let mainCardSubHeader

    if (this.s.state == "confirmed") {
      mainCardHeader = t(".your_user_has_been_activated_welcome")
      mainCardSubHeader = t(".we_wish_you_a_good_experience")
    }

    return (
      <div className={classNames("components--users--confirmation", className)} {...restProps}>
        <MainCard header={mainCardHeader} subHeader={mainCardSubHeader}>
          {this.s.state == "confirming" &&
            <div className="confirming-container">
              {t(".please_wait_while_we_confirm_your_email")}
            </div>
          }
          {this.s.state == "confirmed" &&
            <div className="confirmed-container">
              {!currentUser &&
                <Button primary style={{marginTop: "20px", width: "100%"}} to={Routes.rootPath()}>
                  {t(".go_to_sign_in")}
                </Button>
              }
              {currentUser &&
                <Button primary style={{marginTop: "20px", width: "100%"}} to={Routes.userRootPath()}>
                  {t(".go_to_dashboard")}
                </Button>
              }
            </div>
          }
          {this.s.state == "error" &&
            <div className="error-container">
              {t(".an_error_occurred_while_confirming_your_email")}
            </div>
          }
        </MainCard>
      </div>
    )
  }

  confirmEmail = async () => {
    try {
      await User.confirmEmail({confirmation_token: this.tt.queryParams.confirmation_token})
      this.setState({state: "confirmed"})
    } catch (error) {
      FlashMessage.errorResponse(error)
      this.setState({state: "error"})
    }
  }
}))
