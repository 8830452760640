import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import {EmailTemplate} from "models"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsEmailTemplatesCloneButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    onCloned: PropTypes.func,
    emailTemplate: PropTypes.instanceOf(EmailTemplate).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.email_templates.clone_button"})

    this.t = t
    this.useStates({
      disabled: false
    })
  }

  render() {
    const {className, onCloned, emailTemplate, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--email-templates--clone-button", "action-button", className)}
        disabled={this.s.disabled}
        icon="clone"
        label={this.t(".clone_email_template")}
        onClick={this.tt.onCloneButtonClicked}
        {...restProps}
      />
    )
  }

  onCloneButtonClicked = async (e) => {
    e.preventDefault()

    const {emailTemplate} = this.p

    try {
      this.setState({disabled: true})

      await loading(this.t(".cloning_email_template"), async () => {
        const result = await emailTemplate.clone()
        const clonedEmailTemplate = digg(result, "cloned_email_template")

        FlashMessage.success(this.t(".the_email_template_was_cloned"))
        if (this.props.onCloned) this.props.onCloned({clonedEmailTemplate})
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
