import selectComponent from "components/select-component"
import {Styling} from "models"
const {withInputContainer: StylingsSelectWithContainer, withMemo: ComponentsStylingsSelect} = selectComponent({
  className: "components--stylings--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Styling

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Styling: ["id", "name", "styling"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const stylings = await query.toArray()

    return stylings.map((styling) => ({
      styling,
      text: styling.name(),
      value: styling.id()
    }))
  }
})

export default ComponentsStylingsSelect
export {StylingsSelectWithContainer}
