import {FormInputs, useForm} from "@kaspernj/api-maker/build/form"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {TextKey} from "models"
export default memo(shapeComponent(class ComponentsTextKeysInputs extends ShapeComponent {
  static propTypes = propTypesExact({
    cacheKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    textKey: PropTypes.instanceOf(TextKey).isRequired
  })

  setup() {
    this.form = useForm() || new FormInputs()
  }

  render() {
    const {form} = this.tt
    const {name, textKey} = this.p
    const key = textKey.uniqueKey()
    const textKeyInputId = `${name}[text_keys_attributes][${key}]`

    if (!textKey.id()) throw new Error("No 'id' was given on TextKey")

    return (
      <div className="components--text-keys--inputs">
        {textKey.isNewRecord() && form.setValueWithHidden(`${textKeyInputId}[new_id]`, textKey.id())}
        {textKey.isPersisted() && form.setValueWithHidden(`${textKeyInputId}[id]`, textKey.id())}
        {form.setValueWithHidden(`${textKeyInputId}[attribute_name]`, textKey.attributeName())}
        {form.setValueWithHidden(`${textKeyInputId}[key]`, textKey.key())}
        {textKey.textValues().map((textValue) =>
          <React.Fragment key={textValue.uniqueKey()}>
            {textValue.isNewRecord() && this.shouldSaveTextValue(textValue) &&
              form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][new_id]`, textValue.id())
            }
            {textValue.isPersisted() &&
              <>
                {form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][id]`, textValue.id())}
                {!this.shouldSaveTextValue(textValue) &&
                  form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][_destroy]`, 1)
                }
              </>
            }
            {this.shouldSaveTextValue(textValue) &&
              <>
                {form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][locale]`, textValue.locale())}
                {form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][text_key_id]`, textKey.id())}
                {form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][value]`, textValue.value())}
                {form.setValueWithHidden(`${textKeyInputId}[text_values_attributes][${textValue.uniqueKey()}][value_type]`, textValue.valueType())}
              </>
            }
          </React.Fragment>
        )}
      </div>
    )
  }

  shouldSaveTextValue(textValue) {
    const value = textValue.value()

    if (value && value != "" && value != "<div></div>") {
      return true
    }
  }
}))
