import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {Contact} from "models"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import presence from "shared/presence"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsContactsLink extends ShapeComponent {
  static propTypes = {
    adminLayout: PropTypes.bool,
    className: PropTypes.string,
    superAdminLayout: PropTypes.bool,
    contact: PropTypes.instanceOf(Contact).isRequired,
    userLayout: PropTypes.bool
  }

  render() {
    const {adminLayout = false, children, className, contact, superAdminLayout = false, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--contacts--link", className)
    const name = presence(contact.name()) || `${contact.firstName()} ${contact.lastName()}`
    let to

    if (adminLayout) {
      to = Routes.adminContactPath(contact.id())
    } else if (superAdminLayout) {
      to = Routes.superAdminPath({model: "Contact", model_id: contact.id()})
    } else {
      to = "#"
    }

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || name}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || name}
      </span>
    )
  }
}))
