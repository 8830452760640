import {Elements, ElementsConsumer, PaymentElement} from "@stripe/react-stripe-js"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {StripeCard, StripeSubscription} from "models"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import env from "shared/env"
import {loadStripe} from "@stripe/stripe-js"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

const CheckoutForm = memo(shapeComponent(class CheckoutForm extends ShapeComponent {
  static propTypes = {
    elements: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
    stripe: PropTypes.object.isRequired
  }

  setup() {
    this.useStates({
      errorMessage: undefined
    })
  }

  render() {
    const {errorMessage} = this.s

    return (
      <form className="components--stripe--form--checkout-form" onSubmit={this.tt.handleSubmit}>
        {errorMessage &&
          <div className="mb-4">
            {errorMessage}
          </div>
        }
        <PaymentElement />
        <Button className="mt-3 w-100" primary save />
        {(env.RAILS_ENV == "development" || env.RAILS_ENV == "test") &&
          <Button className="fake-pay-test-button mt-2 w-100" label="Fake pay!" onClick={this.tt.onFakePayClicked} />
        }
      </form>
    )
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const {elements, returnUrl, stripe} = this.p
    const {error} = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl
      }
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      this.setState({errorMessage: error.message})
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  onFakePayClicked = (e) => {
    e.preventDefault()

    location.href = `${this.p.returnUrl}&setup_intent=test-setup-intent-id`
  }
}))

export default memo(shapeComponent(class ComponentsStripeForm extends ShapeComponent {
  static propTypes = {
    returnUrl: PropTypes.string.isRequired
  }

  setup() {
    const currentUser = useCurrentUser()
    const {locale, t} = useI18n({namespace: "js.components.stripe.form"})

    this.setInstance({
      locale,
      t
    })
    this.useStates({
      clientSecret: undefined,
      options: undefined,
      stripe: undefined
    })

    useMemo(() => {
      if (currentUser && !this.s.clientSecret) {
        this.loadStripeTag()
      }
    }, [currentUser?.id(), locale])
  }

  async loadStripeTag() {
    const response = await StripeCard.publishableKey()
    const publicKey = digg(response, "stripe_publishable_key")
    const stripe = await loadStripe(publicKey)
    const clientSecretResult = await StripeSubscription.clientSecret()
    const clientSecret = digg(clientSecretResult, "client_secret")
    const options = {
      appearance: {
        theme: "night"
      },
      clientSecret,
      locale: this.tt.locale
    }

    this.setState({clientSecret, options, stripe})
  }

  render() {
    const {returnUrl} = this.p
    const {clientSecret, options, stripe} = this.s

    return (
      <div className="components--stripe--form">
        {!clientSecret &&
          "Loading Stripe..."
        }
        {clientSecret && options &&
          <Elements options={options} stripe={stripe}>
            <ElementsConsumer>
              {({stripe, elements}) => (
                <CheckoutForm clientSecret={clientSecret} elements={elements} returnUrl={returnUrl} stripe={stripe} />
              )}
            </ElementsConsumer>
          </Elements>
        }
      </div>
    )
  }
}))
