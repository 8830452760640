import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import currentDate from "shared/current-date"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PageContentsPresentation from "components/page-contents/presentation"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import ScoreFactorChartSlide from "components/user/school-classes/insights/score-factor-chart-slide"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassesSchoolClassCheckInsSide3 extends ShapeComponent {
  static propTypes = propTypesExact({
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    schoolClassCheckIn: PropTypes.instanceOf(SchoolClassCheckIn).isRequired
  })

  setup() {
    this.setInstance({
      dateFrom: useMemo(() => moment().subtract(4, "weeks").startOf("day").toDate(), []),
      dateTo: useMemo(() => moment(currentDate()).endOf("day").toDate(), [])
    })
  }

  render() {
    const {schoolClass, schoolClassCheckIn} = this.p

    return (
      <View dataSet={{component: "routes--user--school-classes--school-class-check-ins--slide-3"}}>
        <PageContentsPresentation
          identifier="user/school-classes/school-class-check-ins/slide-3"
          liquidVariables={{schoolClass, schoolClassCheckIn}}
        />
        <ScoreFactorChartSlide dateFrom={this.tt.dateFrom} dateTo={this.tt.dateTo} schoolClass={schoolClass} />
      </View>
    )
  }
}))
