import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import React from "react"
import {SurveyStep} from "models"
import {SurveyStepsSelectWithContainer} from "components/survey-steps/select"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

const surveyStepsQuery = SurveyStep.ransack({copyable_true: 1})

export default memo(shapeComponent(class ComponentsSurveysCopyStepButton extends ShapeComponent {
  static propTypes = {
    surveyId: PropTypes.string.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.surveys.surveys.copy_step_button"})

    this.t = t
    this.useStates({
      disabled: false,
      showModal: false
    })
  }

  render() {
    return (
      <>
        {this.s.showModal &&
          <Modal onRequestClose={this.tt.onRequestModalClose}>
            <form onSubmit={this.tt.onSubmit}>
              <SurveyStepsSelectWithContainer id="survey_step_id" label={SurveyStep.modelName().human()} name="survey_step_id" query={surveyStepsQuery} />
              <Button primary save />
            </form>
          </Modal>
        }
        <Button
          className="action-button components--surveys--copy-step-button"
          disabled={this.s.disabled}
          icon="copy"
          label={this.t(".copy_step")}
          onClick={this.tt.onCopyStepClicked}
        />
      </>
    )
  }

  onCopyStepClicked = (e) => {
    e.preventDefault()
    this.setState({showModal: true})
  }

  onRequestModalClose = () => this.setState({showModal: false})

  onSubmit = async (e) => {
    e.preventDefault()

    const {surveyId} = this.p
    const formData = new FormData(e.target)
    const surveyStepId = formData.get("survey_step_id")
    const surveyStep = await SurveyStep.find(surveyStepId)

    try {
      this.setState({disabled: true})
      await surveyStep.copy({survey_id: surveyId})
      FlashMessage.success(this.t(".the_step_was_copied"))
      this.setState({showModal: false})
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
