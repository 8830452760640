import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {ScoreFactorGroup} from "models"
import {TagCloud} from "react-tagcloud"

export default memo(shapeComponent(class ComponentsScoreFactorGroupsTagCloud extends ShapeComponent {
  static propTypes = {
    scoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup).isRequired,
    teamSurveysQuery: PropTypes.object
  }

  setup() {
    this.useStates({
      tagCloudData: undefined
    })

    useMemo(() => {
      this.loadTagCloudData()
    }, [])
  }

  async loadTagCloudData() {
    const {scoreFactorGroup, teamSurveysQuery} = this.p
    const response = await scoreFactorGroup.tagCloud({team_surveys_query: teamSurveysQuery})
    const tagCloudData = digg(response, "tag_cloud_data")

    this.setState({tagCloudData})
  }

  render() {
    const {className, scoreFactorGroup, teamSurveysQuery, ...restProps} = this.props
    const {tagCloudData} = this.s

    return (
      <Card className={classNames("components--score-factor-groups--tag-cloud", className)} data-tag-cloud={JSON.stringify(tagCloudData)} {...restProps}>
        <div style={{color: "#4c93ff"}}>
          {tagCloudData &&
            <TagCloud
              disableRandomColor
              maxSize={50}
              minSize={20}
              tags={tagCloudData}
            />
          }
        </div>
      </Card>
    )
  }
}))
