import {ClassStep, School, SchoolClass, SchoolClassGroup} from "models"
import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import {hasFilterInQueryParams} from "components/user/unicorn/filter-changed"
import HayaSelect from "components/woof-haya-select"
import I18n from "shared/i18n"
import Input from "components/inputs/input"
import isUserA from "components/users/is-a"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useQueryParams from "on-location-changed/build/use-query-params"

const schoolClassGroupsEnabled = window.RAILS_ENV == "test"

export default memo(shapeComponent(class ComponentsUserUnicornFilter extends ShapeComponent {
  static defaultProps = {
    classStepIds: null,
    dateFrom: null,
    dateTo: null,
    schoolClassIds: null,
    schoolClassGroupIds: null,
    schoolIds: null
  }

  static propTypes = propTypesExact({
    classStepIds: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    schoolClassIds: PropTypes.array,
    schoolClassGroupIds: PropTypes.array,
    schoolIds: PropTypes.array
  })

  setup() {
    this.currentUser = useCurrentUser()
    this.queryParams = useQueryParams()
    this.dateFromInputRef = useRef()
    this.dateToInputRef = useRef()

    this.useStates({
      dateFrom: () => this.p.dateFrom,
      dateTo: () => this.p.dateTo,
      schoolClassesChanges: undefined,
      defaultIds: this.loadDefaultIds(),
      classSteps: undefined,
      schools: undefined,
      schoolClasses: undefined,
      schoolClassGroups: undefined
    })

    useMemo(() => {
      this.loadClassSteps()
      this.loadSchools()
      this.loadSchoolClasses()

      if (schoolClassGroupsEnabled) {
        this.loadSchoolClassGroups()
      }
    }, [])

    useMemo(() => {
      if (this.queryParams.date_from) {
        this.setState({dateFrom: new Date(this.queryParams.date_from)})
      }
    }, [this.queryParams.date_from])

    useMemo(() => {
      if (this.queryParams.date_to) {
        this.setState({dateTo: new Date(this.queryParams.date_to)})
      }
    }, [this.queryParams.date_to])
  }

  loadClassSteps = async () => {
    const loadingLabel = ClassStep.modelName().human({count: 2})

    await loading(loadingLabel, async () => {
      const classSteps = await ClassStep
        .ransack({s: "primary_school_step"})
        .select({ClassStep: ["id", "name"]})
        .toArray()

      this.setState({classSteps})
    })
  }

  loadDefaultIds = () => {
    const {classStepIds, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const defaultIds = [...classStepIds || [], ...schoolClassIds || [], ...schoolClassGroupIds || [], ...schoolIds || []]

    return defaultIds
  }

  loadSchools = async () => {
    const loadingLabel = School.modelName().human({count: 2})

    await loading(loadingLabel, async () => {
      const schools = await School
        .ransack({
          s: "current_translation_name",
          with_contact_id: this.tt.currentUser.contactId()
        })
        .select({School: ["hasUnicornSubscription", "id", "name"]})
        .groupBy("id")
        .toArray()

      this.setState({schools})
    })
  }

  loadSchoolClasses = async () => {
    const loadingLabel = SchoolClass.modelName().human({count: 2})

    await loading(loadingLabel, async () => {
      const schoolClasses = await SchoolClass
        .ransack({
          s: "current_translation_name",
          with_contact_id: this.tt.currentUser.contactId()
        })
        .select({SchoolClass: ["hasUnicornSubscription", "id", "interpretedName"]})
        .groupBy("id")
        .toArray()

      this.setState({schoolClasses})
    })
  }

  loadSchoolClassGroups = async () => {
    const loadingLabel = SchoolClassGroup.modelName().human({count: 2})

    await loading(loadingLabel, async () => {
      const schoolClassGroups = await SchoolClassGroup
        .ransack({s: "current_translation_name"})
        .select({SchoolClassGroup: ["id", "name"]})
        .toArray()

      this.setState({schoolClassGroups})
    })
  }

  render() {
    return (
      <div className="components--user--unicorn--filter">
        <form onSubmit={this.tt.onSubmit}>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <div className="school-class-select-container">
              {this.s.schoolClasses && (!schoolClassGroupsEnabled || this.s.schoolClassGroups) && this.s.defaultIds !== undefined &&
                <HayaSelect
                  className="school-classes-select"
                  defaultValues={this.s.defaultIds}
                  id="school-classes-select"
                  multiple
                  onChange={this.tt.onSchoolClassesChanged}
                  options={this.schoolClassesOptions()}
                  woofInputStyling
                />
              }
            </div>
            <div className="date-from-container">
              <Input
                id="date_from"
                inputRef={this.dateFromInputRef}
                onChange={this.tt.onDateFromChanged}
                style={{width: "100%"}}
                type="date"
                value={this.s.dateFrom && I18n.strftime("%Y-%m-%d", this.s.dateFrom)}
              />
            </div>
            <div className="date-to-container">
              <Input
                id="date_to"
                inputRef={this.dateToInputRef}
                onChange={this.tt.onDateToChanged}
                style={{width: "100%"}}
                type="date"
                value={this.s.dateTo && I18n.strftime("%Y-%m-%d", this.s.dateTo)}
              />
            </div>
            <div className="search-submit-container">
              <Button className="search-submit" label={I18n.t("js.shared.search")} primary />
            </div>
          </div>
        </form>
      </div>
    )
  }

  onDateFromChanged = (e) => {
    const dateFrom = new Date(digg(e, "target", "value"))

    this.setState({dateFrom})
  }

  onDateToChanged = (e) => {
    const dateTo = new Date(digg(e, "target", "value"))

    this.setState({dateTo})
  }

  onSchoolClassesChanged = ({options}) => {
    this.setState({schoolClassesChanges: options})
  }

  onSubmit = (e) => {
    e.preventDefault()

    const newParams = {}
    const dateFrom = this.s.dateFrom
    const dateTo = this.s.dateTo

    // Get class steps, school classes, schools and school class groups
    let options

    if (this.s.schoolClassesChanges) {
      options = this.s.schoolClassesChanges
    } else if (!hasFilterInQueryParams(this.queryParams)) {
      // Insert defaults into params if this is the first filtering
      options = this.selectedOptionsFromDefaultIds()
    }

    if (options) {
      const classStepIds = options.filter((option) => option.model == "class-step").map((option) => option.value)
      const schoolIds = options.filter((option) => option.model == "school").map((option) => option.value)
      const schoolClassesIds = options.filter((option) => option.model == "school-class").map((option) => option.value)
      let schoolClassGroupsIds

      if (schoolClassGroupsEnabled) {
        schoolClassGroupsIds = options.filter((option) => option.model == "school-class-group").map((option) => option.value)
      }

      this.setState({schoolClassesChanges: undefined})

      newParams.class_steps = classStepIds
      newParams.schools = schoolIds
      newParams.school_classes = schoolClassesIds

      if (schoolClassGroupsEnabled) {
        newParams.school_class_groups = schoolClassGroupsIds
      }
    }

    // Date filters
    if (dateFrom) {
      newParams.date_from = I18n.strftime("%Y-%m-%d", new Date(dateFrom))
    } else {
      newParams.date_from = undefined
    }

    if (dateTo) {
      newParams.date_to = I18n.strftime("%Y-%m-%d", new Date(dateTo))
    } else {
      newParams.date_to = undefined
    }

    if (Object.keys(newParams).length > 0) {
      Params.changeParams(newParams)
    }
  }

  selectedOptionsFromDefaultIds = () => {
    const options = []

    for (const defaultId of this.s.defaultIds) {
      const classStep = this.s.classSteps.find((classStep) => classStep.id() == defaultId)
      const school = this.s.schools.find((school) => school.id() == defaultId)
      const schoolClass = this.s.schoolClasses.find((schoolClass) => schoolClass.id() == defaultId)
      let schoolClassGroup

      if (schoolClassGroupsEnabled) {
        schoolClassGroup = this.s.schoolClassGroups.find((schoolClassGroup) => schoolClassGroup.id() == defaultId)
      }

      let model

      if (classStep) {
        model = "class-step"
      } else if (school) {
        model = "school"
      } else if (schoolClass) {
        model = "school-class"
      } else if (schoolClassGroup) {
        model = "school-class-group"
      }

      if (model) options.push({model, value: defaultId})
    }

    return options
  }

  schoolClassesOptions = () => {
    const options = []

    options.push({
      key: "schools-group",
      text: School.modelName().human({count: 2}),
      type: "group"
    })

    for (const school of this.s.schools) {
      options.push({
        disabled: !isUserA(this.currentUser, "admin") && !school.hasUnicornSubscription(),
        model: "school",
        text: school.name(),
        value: school.id()
      })
    }

    options.push({
      key: "class-steps-group",
      text: ClassStep.modelName().human({count: 2}),
      type: "group"
    })

    for (const classStep of this.s.classSteps) {
      options.push({
        model: "class-step",
        text: classStep.name(),
        value: classStep.id()
      })
    }

    if (schoolClassGroupsEnabled) {
      options.push({
        key: "school-class-groups",
        text: SchoolClassGroup.modelName().human({count: 2}),
        type: "group"
      })

      if (this.s.schoolClassGroups) {
        options.push(...this.s.schoolClassGroups.map((schoolClassGroup) => ({
          model: "school-class-group",
          text: schoolClassGroup.name(),
          value: schoolClassGroup.id()
        })))
      }
    }

    options.push({
      key: "school-classes",
      text: SchoolClass.modelName().human({count: 2}),
      type: "group"
    })

    if (this.s.schoolClasses) {
      options.push(...this.s.schoolClasses.map((schoolClass) => ({
        disabled: !isUserA(this.currentUser, "admin") && !schoolClass.hasUnicornSubscription(),
        model: "school-class",
        text: schoolClass.interpretedName(),
        value: schoolClass.id()
      })))
    }

    return options
  }
}))
