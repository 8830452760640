import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {Survey, SurveyStep} from "models"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Button from "components/inputs/button"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import SurveysCloneButton from "components/surveys/clone-button"
import SurveysCopyStepButton from "components/surveys/copy-step-button"
import SurveyStepsTable from "components/survey-steps/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useDestroyedEvent from "@kaspernj/api-maker/build/use-destroyed-event"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedSurveyAttributes = ["createdAt", "id", "name", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedSurveyAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSurveysShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.surveys.show"})
    const params = useParams()
    const {survey, surveyId} = useModel(Survey, {
      abilities: {
        Survey: ["clone", "destroy", "edit"]
      },
      match: {params},
      select: {
        Survey: selectedSurveyAttributes
      }
    })
    const canCan = useCanCan(() => [[SurveyStep, ["create"]]])

    this.setInstance({
      canCan,
      stepsCollection: useMemo(
        () => SurveyStep.ransack({s: "position asc", survey_id_eq: surveyId}),
        [surveyId]
      ),
      survey,
      surveyId,
      t
    })
    useDestroyedEvent(survey, this.tt.onSurveyDestroyed)

    useAdminLayout()?.setState({
      actions: this.actions(),
      active: "surveys",
      headerTitle: survey?.name()
    })
  }

  render() {
    const {stepsCollection, survey} = this.tt

    return (
      <View dataSet={{route: "admin--surveys--show"}}>
        {survey &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={survey} />
            {Locales.availableLocales().map((locale) =>
              <AttributeRow
                attribute={`name${inflection.camelize(locale)}`}
                identifier={`name-${locale}`}
                key={locale}
                label={`${Survey.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={survey}
              />
            )}
          </>
        }
        <SurveyStepsTable adminLayout collection={stepsCollection} />
      </View>
    )
  }

  actions() {
    const {canCan, survey, surveyId} = this.tt

    return (
      <>
        {canCan?.can("create", SurveyStep) &&
          <Button
            className="new-step-button"
            icon="plus"
            label={this.t(".add_new_step")}
            to={Routes.newAdminSurveyStepPath({survey_step: {survey_id: surveyId}})}
          />
        }
        {survey &&
          <SurveysCopyStepButton surveyId={survey.id()} />
        }
        {survey?.can("clone") &&
          <SurveysCloneButton onCloned={this.tt.onSurveyCloned} survey={survey} />
        }
        <AdminShowActions model={survey} />
      </>
    )
  }

  onSurveyCloned = ({clonedSurvey}) => AppHistory.push(Routes.adminSurveyPath(clonedSurvey.id()))
  onSurveyDestroyed = () => {
    FlashMessage.success(this.t(".the_survey_was_deleted"))
    AppHistory.push(Routes.adminSurveysPath())
  }
}))
