import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {ContactRelationship} from "models"
import {ContactRelationshipTypesSelectWithContainer} from "components/contact-relationship-types/select"
import {ContactsSelectWithContainer} from "components/contacts/select"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactRelationshipsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.contact_relationships.edit"})
    const params = useParams()
    const {contactRelationship, contactRelationshipId} = useModel(ContactRelationship, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        ContactRelationship: ["childId", "contactRelationshipTypeId", "id", "parentId"]
      }
    })

    this.setInstance({
      contactRelationship,
      contactRelationshipId,
      formRef: useRef(),
      t
    })

    useAdminLayout()?.setState({active: "contacts", headerTitle: this.headerTitle()})
  }

  render() {
    const {contactRelationship} = this.tt

    return (
      <View dataSet={{route: "admin--contact-relationships--edit"}}>
        {contactRelationship &&
          <Form formRef={this.tt.formRef} onSubmit={this.tt.onSubmit}>
            <ContactsSelectWithContainer
              attribute="parentId"
              id="contact_relationship_parent_id"
              label={ContactRelationship.humanAttributeName("parent")}
              model={contactRelationship}
            />
            <ContactsSelectWithContainer
              attribute="childId"
              id="contact_relationship_child_id"
              label={ContactRelationship.humanAttributeName("child")}
              model={contactRelationship}
            />
            <ContactRelationshipTypesSelectWithContainer
              attribute="contactRelationshipTypeId"
              id="contact_relationship_contact_relationship_type_id"
              label={ContactRelationship.humanAttributeName("contactRelationshipType")}
              model={contactRelationship}
            />
            <Button primary save />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {contactRelationship} = this.tt

    if (contactRelationship?.isNewRecord()) {
      return this.t(".add_new_contact_relationship")
    } else if (contactRelationship?.isPersisted()) {
      return this.t(".edit_contact_relationship")
    }
  }

  onSubmit = async () => {
    const form = this.tt.formRef.current
    const formData = new FormData(form)
    const {contactRelationship} = this.tt

    if (contactRelationship.isNewRecord()) {
      formData.append("contact_relationship[new_id]", contactRelationship.id())
    }

    try {
      await contactRelationship.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_contact_relationship_was_saved"))
      AppHistory.push(Routes.adminContactRelationshipsPath(contactRelationship.parentId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
