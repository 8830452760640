import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsUserDashbarWeekBar extends ShapeComponent {
  static defaultProps = {
    dateFrom: null,
    dateTo: null,
    weekNavigation: false
  }

  static propTypes = propTypesExact({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    weekNavigation: PropTypes.bool.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.user.dashboard.week_bar"})

    this.currentUser = useCurrentUser()
    this.t = t

    this.useStates({
      numberOfWoofsOnTheSchool: undefined,
      numberOfWoofsInYourClasses: undefined
    })

    useMemo(() => {
      if (this.currentUser) {
        this.loadNumbers()
      }
    }, [this.currentUser?.id(), this.p.dateFrom, this.p.dateTo])
  }

  async loadNumbers() {
    const result = await this.tt.currentUser.weekBar({
      date_from: this.p.dateFrom,
      date_to: this.p.dateTo
    })

    this.setState({
      numberOfWoofsOnTheSchool: digg(result, "number_of_woofs_on_the_school"),
      numberOfWoofsInYourClasses: digg(result, "number_of_woofs_in_your_classes")
    })
  }

  render() {
    const {t} = this.tt
    const currentDate = moment()
    const fromDate = moment(this.p.dateFrom)
    let sameWeek = false
    let dateFromText, dateToText

    if (!this.p.dateFrom || (currentDate.year() == fromDate.year() && currentDate.isoWeek() == fromDate.isoWeek())) {
      sameWeek = true
    }

    if (this.p.dateFrom) {
      dateFromText = I18n.strftime("%Y-%m-%d", this.p.dateFrom)
    }

    if (this.p.dateTo) {
      dateToText = I18n.strftime("%Y-%m-%d", this.p.dateTo)
    }

    return (
      <View
        dataSet={{component: "user/dashboard/week-bar"}}
        style={{
          flexDirection: "row",
          flexShrink: 1,
          alignItems: "center",
          marginRight: "auto",
          marginBottom: 40,
          marginLeft: "auto",
          paddingVertical: 14,
          paddingHorizontal: 30,
          borderRadius: 30,
          backgroundColor: "#fff"
        }}
      >
        {this.p.weekNavigation &&
          <>
            <Pressable
              dataSet={{class: "previous-week-button"}}
              onPress={this.tt.onPreviousWeekPress}
              style={{
                position: "absolute",
                top: 20,
                left: -25
              }}
            >
              <FontAwesomeIcon name="chevron-left" style={{fontSize: 20}} />
            </Pressable>
            <Pressable
              dataSet={{class: "next-week-button"}}
              onPress={this.tt.onNextWeekPress}
              style={{
                position: "absolute",
                top: 20,
                right: -25
              }}
            >
              <FontAwesomeIcon name="chevron-right" style={{fontSize: 20}} />
            </Pressable>
          </>
        }
        <Text>
          {(() => {
            if (sameWeek) {
              return t(".your_week")
            } else if (this.p.dateFrom && this.p.dateTo) {
              return t(".week_with_number_and_dates", {week: fromDate.isoWeek(), date_from: dateFromText, date_to: dateToText})
            } else {
              return t(".week_with_number", {week: fromDate.isoWeek()})
            }
          })()}
        </Text>
        <View
          dataSet={{class: "your-week-spacer"}}
          style={{
            width: 2,
            height: 24,
            marginRight: 14,
            marginLeft: 35,
            backgroundColor: "#0091ff"
          }}
        />
        <View
          dataSet={{class: "paw-circle-icon"}}
          style={{
            width: 30,
            height: 30,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: "#0091ff"
          }}
        >
          <FontAwesomeIcon name="paw" style={{color: "#fff"}} />
        </View>
        <View
          dataSet={{class: "number-of-woofs-on-the-school-container"}}
          style={{
            marginRight: 24,
            marginLeft: 5
          }}
        >
          {this.s.numberOfWoofsOnTheSchool !== undefined &&
            <Text style={{color: "#a3a3a3", fontWeight: "bold"}}>
              {t(".number_woofs_on_the_school", {number: this.s.numberOfWoofsOnTheSchool})}
            </Text>
          }
        </View>
        <View dataSet={{class: "number-of-woofs-in-your-classes-container"}}>
          {this.s.numberOfWoofsInYourClasses !== undefined &&
            <Text style={{color: "#a3a3a3", fontWeight: "bold"}}>
              {t(".number_woofs_in_your_classes", {number: this.s.numberOfWoofsInYourClasses})}
            </Text>
          }
        </View>
      </View>
    )
  }

  onNextWeekPress = () => {
    const nextWeekStart = moment(this.p.dateFrom).add(1, "week").toDate()
    const nextWeekEnd = moment(nextWeekStart).endOf("isoWeek").toDate()

    Params.changeParams({
      date_from: I18n.strftime("%Y-%m-%d", nextWeekStart),
      date_to: I18n.strftime("%Y-%m-%d", nextWeekEnd)
    })
  }

  onPreviousWeekPress = () => {
    const previousWeekStart = moment(this.p.dateFrom).subtract(1, "week").toDate()
    const previousWeekEnd = moment(previousWeekStart).endOf("isoWeek").toDate()

    Params.changeParams({
      date_from: I18n.strftime("%Y-%m-%d", previousWeekStart),
      date_to: I18n.strftime("%Y-%m-%d", previousWeekEnd)
    })
  }
}))
