import {EmailTemplatePreview} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: EmailTemplatePreviewsSelectWithContainer, withMemo: ComponentsEmailTemplatePreviewsSelect} = selectComponent({
  className: "components--email-template-previews--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || EmailTemplatePreview

    query = query
      .ransack({s: ["email_template_current_translation_name asc", "current_translation_name asc"]})
      .preload("email_template")
      .select({
        EmailTemplate: ["name"],
        EmailTemplatePreview: ["id", "name"]
      })
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const emailTemplatePreviews = await query.toArray()

    return emailTemplatePreviews.map((emailTemplatePreview) => ({
      text: `${emailTemplatePreview.emailTemplate().name()} - ${emailTemplatePreview.name()}`,
      value: emailTemplatePreview.id()
    }))
  }
})

export default ComponentsEmailTemplatePreviewsSelect
export {EmailTemplatePreviewsSelectWithContainer}
