import {School} from "models"
import selectComponent from "components/select-component"
const {withAdminSelectContainer: AdminSelectSchools, withInputContainer: SchoolsSelectWithContainer, withMemo: ComponentsSchoolsSelect} = selectComponent({
  className: "components--schools--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || School

    query = query
      .ransack({s: "contact_current_translation_name asc"})
      .select({School: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({contact_current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const schools = await query.toArray()

    return schools.map((school) => ({
      school,
      text: school.name(),
      value: school.id()
    }))
  }
})

export default ComponentsSchoolsSelect
export {AdminSelectSchools, SchoolsSelectWithContainer}
