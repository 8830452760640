import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import {NotificationTypeAuditTrigger} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNotificationTypeAuditTriggersTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--notification-type-audit-triggers--table", className)}
        columns={this.tt.columns}
        editModelPath={this.tt.editModelPath}
        modelClass={NotificationTypeAuditTrigger}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "auditAction",
      sortKey: "auditAction"
    },
    {
      attribute: "auditableType",
      sortKey: "auditableType"
    }
  ]

  editModelPath = ({notificationTypeAuditTrigger}) => {
    if (this.p.adminLayout) return Routes.editAdminNotificationTypeAuditTriggerPath(notificationTypeAuditTrigger.id())
  }
}))
