import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import numberFormat from "shared/number-format"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {ScoreFactorGroup} from "models"
import {Text} from "shared/base"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsScoreFactorGroupsHalfCircleAverage extends ShapeComponent {
  static propTypes = propTypesExact({
    actions: PropTypes.node,
    addition: PropTypes.number,
    className: PropTypes.string,
    defaultAverage: PropTypes.number,
    header: PropTypes.string,
    max: PropTypes.number.isRequired,
    scoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup).isRequired,
    style: PropTypes.object,
    subTitle: PropTypes.node,
    teamSurveysQuery: PropTypes.object
  })

  setup() {
    this.useStates({
      average: this.props.defaultAverage,
      height: 300
    })

    useMemo(() => {
      if (this.props.teamSurveysQuery) {
        this.calculateScoreFactorGroupAverage()
      } else {
        this.setState({average: this.props.defaultAverage})
      }
    }, [this.props.teamSurveysQuery])
  }

  async calculateScoreFactorGroupAverage() {
    const {scoreFactorGroup, teamSurveysQuery} = this.p
    const result = await scoreFactorGroup.average({team_surveys_query: teamSurveysQuery})
    const average = digg(result, "average_score")

    if (average) {
      this.setState({average})
    }
  }

  render() {
    const {actions, addition, className, header, max, scoreFactorGroup, style, subTitle} = this.props
    const {average, height} = this.s
    let averageToUse = average

    if (average && addition !== undefined) averageToUse += addition

    const chartValue = averageToUse / max
    const data = [
      {name: "Group A", value: chartValue},
      {name: "Group B", value: 1.0 - chartValue}
    ]
    const colors = ["#4c93ff", "#b2d1ff"]

    return (
      <UtilsCard dataSet={{class: classNames("components--score-factor-groups--half-circle-average", className)}} header={header} style={style}>
        <View style={{height: `${height / 2}px`}}>
          {actions &&
            <View
              dataSet={{class: "half-circle-actions"}}
              style={{
                position: "absolute",
                zIndex: 1,
                top: 0,
                right: 0
              }}
            >
              {actions}
            </View>
          }
          <View
            dataSet={{class: "text-content-container"}}
            style={{
              position: "absolute",
              right: 0,
              bottom: -3,
              left: 0,
              marginRight: "auto",
              marginLeft: "auto"
            }}
          >
            <Text
              dataSet={{class: "average-container"}}
              style={{
                marginBottom: -14,
                color: "#3092ff",
                fontSize: 50,
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {average !== undefined && average !== null && numberFormat(average, {decimals: 1})}
            </Text>
            <Text
              dataSet={{class: "score-factor-group-name"}}
              style={{
                color: "#4c93ff",
                fontSize: 24,
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {scoreFactorGroup.name()}
            </Text>
            {subTitle &&
              <Text
                dataSet={{class: "sub-title"}}
                style={{
                  color: "#4c93ff",
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                {subTitle}
              </Text>
            }
          </View>
          <View
            dataSet={{class: "pie-chart-container"}}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              marginRight: "auto",
              marginLeft: "auto",
              width: "100%",
              height: `${height}px`
            }}
          >
            <ResponsiveContainer height={height} width="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  endAngle={0}
                  innerRadius="80%"
                  outerRadius="100%"
                  paddingAngle={5}
                  startAngle={180}
                >
                  {data.map((entry, index) => (
                    <Cell dataKey="asd" fill={colors[index % colors.length]} key={`cell-${index}`} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </View>
        </View>
      </UtilsCard>
    )
  }
}))
