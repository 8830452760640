import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import instanceOfClassName from "@kaspernj/api-maker/build/instance-of-class-name"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsContentPieceTypesLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    contentPieceType: instanceOfClassName("ContentPieceType").isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, contentPieceType, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--content-piece-types--link", className)
    const to = this.to()

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || contentPieceType.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || contentPieceType.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, contentPieceType} = this.p

    if (adminLayout) return Routes.adminContentPieceTypePath(contentPieceType.id())
  }
}))
