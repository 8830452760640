import * as models from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AuditsTable from "components/audits/table"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsAuditsPage extends ShapeComponent {
  static propTypes = propTypesExact({
    resource: PropTypes.object.isRequired
  })

  setup() {
    if (!this.p.resource) throw new Error("No resource given")

    const {t} = useI18n({namespace: "js.admin.audits.page"})
    const modelName = this.p.resource.modelClassData().name

    this.t = t
    this.queryParams = useQueryParams()
    this.auditClass = models[`${modelName}Audit`]
    this.auditsQuery = useMemo(() => this.currentAuditsQuery(this.queryParams), [this.p.resource.id(), this.active])
  }

  currentAuditsQuery() {
    const {auditClass} = this.tt
    const paramKey = digg(this.p.resource.constructor.modelName().paramKey())
    const ransack = {}

    ransack[`${paramKey}_id_eq`] = this.p.resource.id()

    return auditClass.ransack(ransack)
  }

  render() {
    const {auditClass, auditsQuery} = this.tt

    return (
      <View dataSet={{component: "audits--page"}}>
        <AuditsTable
          adminLayout
          auditClass={auditClass}
          collection={auditsQuery}
        />
      </View>
    )
  }

  onWhatHappenedToClicked = (e) => {
    e.preventDefault()
    Params.changeParams({active: undefined})
  }

  onWhatDidClicked = (e) => {
    e.preventDefault()
    Params.changeParams({active: "what-did"})
  }
}))
