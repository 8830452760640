import React, {useCallback} from "react"
import {CountriesSelectWithContainer} from "components/countries/select"
import {CountryState} from "models"

export default {
  edit: {
    attributes: [
      {
        attribute: "countryId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <CountriesSelectWithContainer
              label={CountryState.humanAttributeName("country")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        },
        label: CountryState.humanAttributeName("country")
      },
      {attribute: "stateCode"},
      {attribute: "name", translated: true}
    ]
  },
  show: {
    attributesToShow: () => [
      "id",
      {attribute: "stateCode"},
      "name"
    ],
    relationships: ["country"],
    extraSelect: {
      CountryState: ["name"]
    }
  }
}
