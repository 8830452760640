import {Checkbox, HelperText} from "react-native-paper"
import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

export default memo(shapeComponent(class ComponentsAdminCheckbox extends ShapeComponent {
  static defaultProps = {
    required: false,
    value: undefined
  }

  static propTypes = propTypesExact({
    attribute: PropTypes.string,
    checked: PropTypes.bool,
    containerProps: PropTypes.object,
    dataSet: PropTypes.object,
    fontStyle: PropTypes.object,
    hint: PropTypes.node,
    label: PropTypes.string,
    labelContent: PropTypes.node,
    model: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChangeChecked: PropTypes.func,
    required: PropTypes.bool.isRequired,
    style: PropTypes.object,
    value: PropTypes.object
  })

  setup() {
    const {inputProps, restProps: restPropsFromUseInput, wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "checkbox"}})
    const {defaultChecked, name} = inputProps
    const {checked} = this.props
    const {errors} = digs(wrapperOpts, "errors")

    this.setInstance({errors, inputProps, restPropsFromUseInput, wrapperOpts})
    this.useStates({checked: defaultChecked || checked})
    this.form = useForm()

    // Set initial value in form
    useMemo(() => {
      if (this.form && name) {
        this.form.setValue(name, this.s.checked)
      }
    }, [])
  }

  render() {
    const {errors, inputProps, wrapperOpts} = this.tt
    const {containerProps, dataSet, fontStyle, hint, labelContent, required, style} = this.props
    const actualDataSet = Object.assign({}, dataSet, {checked: this.isChecked()})
    const actualStyle = Object.assign({}, style, containerProps?.style)

    let label = wrapperOpts.label

    if (required) {
      label += "*"
    }

    return (
      <View dataSet={actualDataSet} style={actualStyle}>
        <Pressable
          dataSet={{class: "checkbox-pressable", id: inputProps.id}}
          onPress={this.tt.onRadioButtonPressed}
          style={{display: "flex", flexDirection: "row", alignItems: "center"}}
        >
          <Checkbox status={this.isChecked() ? "checked" : "unchecked"} />
          {(label || labelContent) &&
            <View>
              {label &&
                <Text style={fontStyle}>
                  {label}
                </Text>
              }
              {labelContent}
            </View>
          }
        </Pressable>
        {hint &&
          <View style={{marginTop: 4}}>
            {hint}
          </View>
        }
        {errors.length > 0 &&
          <HelperText type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  errorMessages () {
    const {errors} = this.tt
    const errorMessages = []

    for (const error of errors) {
      for (const errorMessage of error.getErrorMessages()) {
        errorMessages.push(errorMessage)
      }
    }

    return errorMessages
  }

  isChecked() {
    if ("checked" in this.props) {
      return this.p.checked
    }

    return this.s.checked
  }

  onRadioButtonPressed = () => {
    const {inputProps, form} = this.tt
    const {name} = inputProps
    const newChecked = !this.isChecked()

    if (form && name) {
      form.setValue(name, newChecked)
    }

    if (this.props.onChange) {
      this.p.onChange({
        checked: newChecked,
        label: this.p.label,
        value: this.p.value
      })
    }

    if (this.props.onChangeChecked) {
      this.p.onChangeChecked(newChecked)
    }

    this.setState({checked: newChecked})
  }
}))
