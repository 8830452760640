import {Account, AccountSchool, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountSchoolsTable from "components/account-schools/table"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminAccountsAccountSchools extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.accounts.account_schools"})
    const canCan = useCanCan(() => [[AccountSchool, ["create"]]])
    const {account, accountId} = useModel(Account, {
      abilities: {
        Account: ["destroy", "edit"]
      },
      match: this.p.match
    })

    this.setInstance({
      account,
      accountId,
      accountSchoolsQuery: useMemo(() => AccountSchool.ransack({
        account_id_eq: accountId
      }, [accountId])),
      canCan,
      t
    })
  }

  render() {
    const {account} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="users"
        className="routes--admin--accounts--account-schools"
        headerTitle={User.humanAttributeName("AccountSchools")}
      >
        {account &&
          <Nav account={account} active="account-schools" className="mb-4" />
        }
        <AccountSchoolsTable
          adminLayout
          collection={this.t.AccountSchoolsQuery}
        />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, account, accountId} = this.tt

    return (
      <>
        {canCan?.can("create", AccountSchool) &&
          <Button
            className="new-account-school-button"
            icon="plus"
            label={this.t(".new_school_for_account")}
            to={Routes.newAdminAccountSchoolPath({account_school: {account_id: accountId}})}
          />
        }
        <AdminShowActions model={account} />
      </>
    )
  }
}))
