import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
export default memo(shapeComponent(class ComponentsSchoolClassesLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    superAdminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    superAdminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, schoolClass, superAdminLayout, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--school-classes--link", className)
    const to = this.to()

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || schoolClass.interpretedName()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || schoolClass.interpretedName()}
      </span>
    )
  }

  to() {
    const {adminLayout, schoolClass, superAdminLayout, userLayout} = this.p

    if (adminLayout) return Routes.adminSchoolClassPath(schoolClass.id())
    if (superAdminLayout) return Routes.superAdminPath({model: "SchoolClass", model_id: schoolClass.id()})
    if (userLayout) return Routes.userSchoolClassPath(schoolClass.id())
  }
}))
