import {Contact, Dialogue} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import DialoguesTable from "components/dialogues/table"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUsersDialogue extends ShapeComponent {
  setup() {
    const params = useParams()
    const {t} = useI18n({namespace: "js.routes.admin.contacts.dialogue"})
    const {contact, contactId} = useModel(Contact, {match: {params}})

    this.canCan = useCanCan(() => [[Dialogue, ["create"]]])
    this.setInstance({
      dialoguesQuery: useMemo(
        () => Dialogue.ransack({
          contact_id_eq: contactId
        }),
        [contactId]
      ),
      contact,
      contactId,
      t
    })

    useAdminLayout().setState({
      actions: this.actions(),
      active: "contacts",
      headerTitle: t(".dialogue")
    })
  }

  render() {
    const {contact, dialoguesQuery} = this.tt

    return (
      <View dataSet={{route: "admin/contacts/dialogue"}}>
        {contact &&
          <Nav active="dialogue" contact={contact} style={{marginBottom: 15}} />
        }
        <DialoguesTable adminLayout collection={dialoguesQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, contactId, t} = this.tt

    return (
      <>
        {canCan?.can("create", Dialogue) &&
          <Button
            className="new-dialogue-button"
            icon="plus"
            label={t(".new_dialogue_for_contact")}
            to={Routes.newAdminDialoguePath({dialogue: {contact_id: contactId}})}
          />
        }
      </>
    )
  }
}))
