import {ScoreFactor} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectScoreFactors,
  withInputContainer: ScoreFactorsSelectWithContainer,
  withMemo: ComponentsScoreFactorsSelect
} = selectComponent({
  className: "components--score-factor--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ScoreFactor

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({ScoreFactor: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const scoreFactors = await query.toArray()

    return scoreFactors.map((scoreFactor) => ({
      text: scoreFactor.name(),
      value: scoreFactor.id()
    }))
  }
})

export default ComponentsScoreFactorsSelect
export {AdminSelectScoreFactors, ScoreFactorsSelectWithContainer}
