import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {ClassStep} from "models"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {v4 as uuidv4} from "uuid"
import withModel from "components/wooftech-with-model"
class RoutesAdminClassStepsEdit extends PageComponent {
  static propTypes = {
    classStep: PropTypes.instanceOf(ClassStep),
    classStepId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {classStep} = this.p

    return (
      <AdminLayout active="class-steps" className="routes--admin--class-steps--edit" headerTitle={this.headerTitle()}>
        {classStep &&
          <form onSubmit={onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ClassStep.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={classStep}
                />
              </React.Fragment>
            )}
            <Input attribute="primarySchoolStep" model={classStep} />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {classStep} = this.p

    if (classStep?.isNewRecord()) {
      return I18n.t("js.routes.admin.class_steps.edit.add_new_class_step")
    } else if (classStep?.isPersisted()) {
      return I18n.t("js.routes.admin.class_steps.edit.edit_class_step", {name: classStep.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {classStep} = this.p

    if (classStep.isNewRecord()) formData.append("class_step[id]", classStep.id())

    try {
      await classStep.saveRaw(formData, {form})
      FlashMessage.success(I18n.t("js.routes.admin.class_steps.edit.the_class_step_was_saved"))
      AppHistory.push(Routes.adminClassStepPath(classStep.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedClassStepAttributes = ["id", "name", "primarySchoolStep"]

for (const locale of Locales.availableLocales()) {
  selectedClassStepAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default withModel(
  RoutesAdminClassStepsEdit,
  ClassStep,
  {
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    },
    select: {
      ClassStep: selectedClassStepAttributes
    }
  }
)
