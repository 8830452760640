import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNavSegmented from "components/admin/nav-segmented"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"

export default memo(shapeComponent(class ComponentsAdminUsersNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    user: PropTypes.instanceOf(User).isRequired
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.components.admin.users.nav"})
    const {user} = this.p
    const schoolClassRoles = user.contact().parentRelationships().loaded().filter((contactRelationship) => contactRelationship.parent().schoolClass())
    const schoolRoles = user.contact().parentRelationships().loaded().filter((contactRelationship) => contactRelationship.parent().school())

    this.buttons = useMemo(
      () => [
        {
          value: "general",
          label: t("js.shared.general"),
          to: Routes.adminUserPath(user.id())
        },
        {
          value: "account-users",
          label: `${User.humanAttributeName("accountUsers")} (${user.accountUsersCount()})`,
          to: Routes.adminUserAccountUsersPath(user.id())
        },
        {
          value: "audits",
          label: User.humanAttributeName("audits"),
          to: Routes.adminUserAuditsPath(user.id())
        },
        {
          value: "school-roles",
          label: `${t("js.shared.school_roles")} (${schoolRoles.length})`,
          to: Routes.adminUserSchoolRolesPath(user.id())
        },
        {
          value: "school-class-roles",
          label: `${t("js.shared.school_class_roles")} (${schoolClassRoles.length})`,
          to: Routes.adminUserSchoolClassRolesPath(user.id())
        },
        {
          value: "subscriptions",
          label: `${User.humanAttributeName("subscriptions")} (${user.subscriptionsCount()})`,
          to: Routes.adminUserSubscriptionsPath(user.id())
        },
        {
          value: "user-roles",
          label: `${User.humanAttributeName("userRoles")} (${user.userRolesCount()})`,
          to: Routes.adminUserUserRolesPath(user.id())
        }
      ],
      [locale]
    )
  }

  render() {
    const {buttons} = this.tt
    const {active, className, user, ...restProps} = this.props

    return (
      <AdminNavSegmented
        active={active}
        buttons={buttons}
        dataSet={{class: classNames("components--admin--users--nav", className)}}
        {...restProps}
      />
    )
  }
}))
