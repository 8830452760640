import {ActivityIndicator, DataTable} from "react-native-paper"
import {digg, digs} from "diggerize"
import React, {useEffect, useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import {NewsletterTemplate} from "models"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesUsersToReceive extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.newsletter_templates.users_to_receive"})
    const {newsletterTemplate} = useModel(NewsletterTemplate, {
      abilities: {
        NewsletterTemplate: ["usersToReceive"]
      },
      match: this.p.match,
      select: {
        NewsletterTemplate: ["id"]
      }
    })

    this.setInstance({newsletterTemplate, t})
    this.useStates({
      errors: null,
      itemsPerPage: 50,
      loading: false,
      numberOfItemsPerPageList: [50, 100, 200, 300],
      page: 0,
      usersToReceive: null
    })

    useMemo(() => {
      if (newsletterTemplate) {
        this.loadUsersToReceive()
      }
    }, [newsletterTemplate?.id()])

    useEffect(() => {
      this.setState({page: 0})
    }, [this.s.itemsPerPage])

    useAdminLayout()?.setState({active: "newsletter-templates", headerTitle: NewsletterTemplate.modelName().human({count: 2})})
  }

  async loadUsersToReceive() {
    try {
      this.setState({loading: true})

      const result = await this.tt.newsletterTemplate.usersToReceive()
      const errors = digg(result, "errors")
      const usersToReceive = digg(result, "users_to_receive")

      this.setState({errors, usersToReceive})
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({loading: false})
    }
  }

  render() {
    const {newsletterTemplate, t} = this.tt
    const {page, itemsPerPage, numberOfItemsPerPageList, usersToReceive} = this.s
    let from, to

    if (usersToReceive !== null) {
      from = page * itemsPerPage
      to = Math.min((page + 1) * itemsPerPage, usersToReceive.length)
    }

    return (
      <View dataSet={{route: "admin-newsletter-templates/users-to-receive"}}>
        {newsletterTemplate &&
          <Nav active="users-to-receive" className="mb-4" newsletterTemplate={newsletterTemplate} />
        }
        {this.s.errors?.map(({lineNumber, message}, errorIndex) =>
          <UtilsCard header={t("js.shared.error")} key={`${message}:${lineNumber}:${errorIndex}`} style={{marginTop: 15}}>
            <Text>
              {t(".error_on_line", {line_number: lineNumber, message})}
            </Text>
          </UtilsCard>
        )}
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>{t(".email")}</DataTable.Title>
            <DataTable.Title>{t(".name")}</DataTable.Title>
            <DataTable.Title>{t(".locale")}</DataTable.Title>
            <DataTable.Title>{t(".time_zone")}</DataTable.Title>
          </DataTable.Header>
          {this.s.usersToReceive?.slice(from, to)?.map(({email, locale, name, time_zone_name: timeZoneName}) =>
            <DataTable.Row dataSet={{class: "user-to-receive-row"}} key={email}>
              <DataTable.Cell dataSet={{class: "email-cell"}}>
                {email}
              </DataTable.Cell>
              <DataTable.Cell dataSet={{class: "name-cell"}}>
                {name}
              </DataTable.Cell>
              <DataTable.Cell dataSet={{class: "locale-cell"}}>
                {locale}
              </DataTable.Cell>
              <DataTable.Cell dataSet={{class: "time-zone-name-cell"}}>
                {timeZoneName}
              </DataTable.Cell>
            </DataTable.Row>
          )}
          {this.s.usersToReceive !== null &&
            <DataTable.Pagination
              label={t("js.react_native_paper.data_table.from_of_to", {from: from + 1, to, of: usersToReceive.length})}
              numberOfItemsPerPage={itemsPerPage}
              numberOfItemsPerPageList={numberOfItemsPerPageList}
              numberOfPages={Math.ceil(usersToReceive.length / itemsPerPage)}
              onItemsPerPageChange={this.tt.onItemsPerPageChange}
              onPageChange={this.tt.setPage}
              page={page}
              selectPageDropdownLabel={t("js.react_native_paper.data_table.rows_per_page")}
              showFastPaginationControls
            />
          }
        </DataTable>
        {this.s.loading &&
          <ActivityIndicator size="large" style={{marginTop: 25}} />
        }
      </View>
    )
  }

  onItemsPerPageChange = (itemsPerPage) => this.setState({itemsPerPage})
  setPage = (page) => this.setState({page})

  renderItem = ({item}) => {
    const {email, time_zone_name: timeZoneName} = digs(item, "email", "time_zone_name")

    return (
      <View style={{flexDirection: "row"}}>
        <View style={{width: 200}}>
          <Text>
            {email}
          </Text>
        </View>
        <View style={{width: 200}}>
          <Text>
            {timeZoneName}
          </Text>
        </View>
      </View>
    )
  }
}))
