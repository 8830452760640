import {BrainBreak, ClassStep} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import Checkboxes from "components/inputs/checkboxes"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {SurveysSelectWithContainer} from "components/surveys/select"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"

const selectedBrainBreakAttributes = ["id", "name", "public", "surveyId"]

for (const locale of Locales.availableLocales()) {
  selectedBrainBreakAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminBrainBreaksEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.brain_breaks.edit"})
    const {brainBreak, brainBreakId} = useModel(BrainBreak, {
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      match: this.p.match,
      preload: ["brain_break_class_steps"],
      select: {
        BrainBreak: selectedBrainBreakAttributes,
        BrainBreakClassStep: ["class_step_id"]
      }
    })

    this.setInstance({brainBreak, brainBreakId, t})
    this.useStates({classStepsCollection: undefined})

    useMemo(() => {
      this.loadClassSteps()
    }, [])
  }

  async loadClassSteps() {
    const classSteps = await ClassStep
      .ransack({s: "position"})
      .select({ClassStep: ["id", "name"]})
      .toArray()

    this.setState({
      classStepsCollection: classSteps.map((classStep) => [classStep.name(), classStep.id()])
    })
  }

  render() {
    const {brainBreak} = this.tt

    return (
      <AdminLayout active="brain-breaks" className="routes--admin--brain-breaks--edit" headerTitle={this.headerTitle()}>
        {brainBreak &&
          <form onSubmit={this.tt.onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${BrainBreak.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={brainBreak}
                />
              </React.Fragment>
            )}
            {this.s.classStepsCollection &&
              <Checkboxes
                defaultValue={
                  brainBreak.isPersisted() ? brainBreak.brainBreakClassSteps().map((brainBreakClassStep) => brainBreakClassStep.classStepId()) : null
                }
                label={ClassStep.modelName().human({count: 2})}
                name="brain_break[class_step_ids]"
                options={this.s.classStepsCollection}
              />
            }
            <SurveysSelectWithContainer attribute="surveyId" label={BrainBreak.humanAttributeName("survey")} model={brainBreak} />
            <Checkbox attribute="public" model={brainBreak} />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {brainBreak} = this.tt

    if (brainBreak?.isNewRecord()) {
      return this.t(".add_new_brain_break")
    } else if (brainBreak?.isPersisted()) {
      return this.t(".edit_brain_break", {name: brainBreak.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {brainBreak} = this.tt

    if (brainBreak.isNewRecord()) formData.append("brain_break[id]", brainBreak.id())

    try {
      await brainBreak.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_brain_break_was_saved"))
      AppHistory.push(Routes.adminBrainBreakPath(brainBreak.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
