import React, {useCallback, useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import AppHistory from "shared/history"
import classNames from "classnames"
import EmailTemplateLink from "components/email-templates/link"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import {Newsletter} from "models"
import NewsletterLink from "components/newsletters/link"
import NewsletterTemplateLink from "components/newsletter-templates/link"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"
import useShape from "set-state-compare/src/use-shape"
export default memo(shapeComponent(class ComponentsNewslettersTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const s = useShape(this.props)
    const {t} = useI18n({namespace: "js.components.newsletters.table"})
    const {adminLayout, className, userLayout, ...restProps} = this.props

    const editModelPath = useCallback(({newsletter}) => s.p.adminLayout && Routes.editAdminNewsletterPath(newsletter.id()), [])
    const viewModelPath = useCallback(({newsletter}) => s.p.adminLayout && Routes.adminNewsletterPath(newsletter.id()), [])

    const emailTemplateContent = useCallback(
      ({newsletter}) => newsletter.emailTemplate() && <EmailTemplateLink emailTemplate={newsletter.emailTemplate()} {...linkProps(s.props)} />,
      []
    )
    const idContent = useCallback(
      ({newsletter}) => <NewsletterLink children={newsletter.id()} newsletter={newsletter} {...linkProps(s.props)} />,
      []
    )
    const newsletterTemplateContent = useCallback(
      ({newsletter}) => newsletter.newsletterTemplate() &&
        <NewsletterTemplateLink newsletterTemplate={newsletter.newsletterTemplate()} {...linkProps(s.props)} />,
      []
    )
    const userContent = useCallback(
      ({newsletter}) => newsletter.user() && <UserLink user={newsletter.user()} {...linkProps(s.props)} />,
      []
    )

    const columns = useMemo(
      () => [
        {
          attribute: "id",
          content: idContent,
          sortKey: "id"
        },
        {
          attribute: "name",
          content: newsletterTemplateContent,
          label: Newsletter.humanAttributeName("newsletterTemplate"),
          path: ["newsletterTemplate"],
          sortKey: "newsletterTemplateCurrentTranslationName"
        },
        {
          attribute: "name",
          content: emailTemplateContent,
          label: Newsletter.humanAttributeName("emailTemplate"),
          path: ["emailTemplate"],
          sortKey: "emailTemplateCurrentTranslationName"
        },
        {
          attribute: "firstName",
          content: userContent,
          label: Newsletter.humanAttributeName("user"),
          path: ["user", "contact"],
          sortKey: "userContactFirstName"
        },
        {
          attribute: "createdAt",
          sortKey: "createdAt"
        }
      ],
      []
    )

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components--newsletters--table", className)}
        columns={columns}
        destroyMessage={t(".the_newsletter_was_deleted")}
        editModelPath={editModelPath}
        modelClass={Newsletter}
        select={{
          EmailTemplate: ["id"],
          Newsletter: ["id"],
          NewsletterTemplate: ["id"],
          User: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }
}))
