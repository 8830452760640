import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {SegmentedButtons} from "react-native-paper"

export default memo(shapeComponent(class ComponentsAdminNavSegmented extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.string.isRequired,
    buttons: PropTypes.array.isRequired,
    dataSet: PropTypes.object,
    style: PropTypes.object
  })

  render() {
    const {active, buttons} = this.p
    const {dataSet, style} = this.props
    const actualDataSet = Object.assign(
      {component: "admin--nav-segmented"},
      dataSet
    )

    return (
      <SegmentedButtons
        buttons={buttons.map((button) => ({label: button.label, testID: button.value, value: button.value}))}
        dataSet={actualDataSet}
        onValueChange={this.tt.onValueChange}
        style={style}
        value={active}
      />
    )
  }

  onValueChange = (newValue) => {
    const activeButton = this.p.buttons.find((button) => button.value == newValue)

    AppHistory.push(activeButton.to)
  }
}))
