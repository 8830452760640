import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroup} from "models"
import SchoolClassGroupLink from "components/school-class-groups/link"
import SurveyLink from "components/surveys/link"
export default memo(shapeComponent(class ComponentsSchoolClassGroups extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--school-class-groups--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={SchoolClassGroup}
        select={{
          SchoolClassGroup: ["id", "name"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.schoolClassGroupContent,
      label: SchoolClassGroup.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    }
  ]

  editModelPath = ({schoolClassGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSchoolClassGroupPath(schoolClassGroup.id())
  }

  viewModelPath = ({schoolClassGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSchoolClassGroupPath(schoolClassGroup.id())
  }

  schoolClassGroupContent = ({schoolClassGroup}) => <SchoolClassGroupLink schoolClassGroup={schoolClassGroup} {...linkProps(this.props)} />
  surveyContent = ({schoolClassGroup}) => schoolClassGroup.survey() && <SurveyLink survey={schoolClassGroup.survey()} {...linkProps(this.props)} />
}))
