import React, {useMemo} from "react"
import {SchoolClass, Subscription} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import SubscriptionsTable from "components/subscriptions/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassSubscriptions extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_classes.subscriptions"})
    const currentUser = useCurrentUser()
    const canCan = useCanCan(() => [[Subscription, ["create"]]], [currentUser?.id()])
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {match: this.p.match})

    const subscriptionsQuery = useMemo(
      () => Subscription.ransack({
        resource_type_eq: "SchoolClass",
        resource_id_eq: schoolClassId
      }),
      [schoolClassId]
    )
    const actions = useMemo(
      () => <>
        {canCan?.can("create", Subscription) &&
          <Button
            className="new-subscription-button"
            icon="plus"
            label={t(".add_new_subscription")}
            to={Routes.newAdminSubscriptionPath({subscription: {resource_id: schoolClassId, resource_type: "SchoolClass"}})}
          />
        }
      </>,
      [canCan, schoolClassId]
    )

    this.setInstance({canCan, schoolClass, schoolClassId, subscriptionsQuery, t})
    useAdminLayout()?.setState({actions, active: "school-classes", headerTitle: SchoolClass.humanAttributeName("subscriptions")})
  }

  render() {
    const {schoolClass, subscriptionsQuery} = this.tt

    return (
      <View dataSet={{route: "admin--school-classes--subscriptions"}}>
        {schoolClass &&
          <Nav active="subscriptions" schoolClass={schoolClass} style={{marginBottom: 15}} />
        }
        <SubscriptionsTable
          adminLayout
          collection={subscriptionsQuery}
        />
      </View>
    )
  }
}))
