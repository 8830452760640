import {CheckIn, ClassStep} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import SurveyLink from "components/surveys/link"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"
const selectedCheckInAttributes = ["createdAt", "id", "name", "public", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedCheckInAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminCheckInsShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.check_ins.show"})
    const params = useParams()
    const {checkIn, checkInId} = useModel(CheckIn, {
      abilities: {
        CheckIn: ["destroy", "edit"]
      },
      match: {params},
      preload: ["check_in_class_steps.class_step", "survey"],
      select: {
        CheckIn: selectedCheckInAttributes,
        CheckInClassStep: ["id"],
        ClassStep: ["name"],
        Survey: ["id", "name"]
      }
    })

    this.setInstance({checkIn, checkInId, t})

    useAdminLayout().setState({actions: this.actions(), active: "check-ins", headerTitle: checkIn?.name()})
  }

  render() {
    const {checkIn} = this.tt

    return (
      <View dataSet={{route: "admin/check-ins/show"}}>
        {checkIn &&
          <>
            <AttributeRows attributes={["id", "public", "createdAt", "updatedAt"]} model={checkIn} />
            <AttributeRow attribute={"survey"} model={checkIn}>
              {checkIn.survey() &&
                <SurveyLink adminLayout survey={checkIn.survey()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${CheckIn.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={checkIn}
                />
              </React.Fragment>
            )}
            <AttributeRow label={ClassStep.modelName().human({count: 2})}>
              {checkIn.checkInClassSteps().map((checkInClassStep) =>
                <div key={checkInClassStep.id()}>
                  {checkInClassStep.classStep().name()}
                </div>
              )}
              {checkIn.checkInClassSteps().loaded().length == 0 &&
                this.t("js.shared.none")
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  actions() {
    const {checkIn} = this.tt

    return (
      <AdminShowActions model={checkIn} />
    )
  }
}))
