import selectComponent from "components/select-component"
import {StripeTaxRate} from "models"
const {withInputContainer: StripeTaxRatesSelectWithContainer, withMemo: ComponentsStripeTaxRatesSelect} = selectComponent({
  className: "components--stripe-tax-rates--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || StripeTaxRate

    query = query
      .ransack({s: "display_name asc"})
      .select({StripeTaxRate: ["description", "displayName", "id"]})
      .limit(20)

    if (searchValue) query.ransack({display_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const stripeTaxRates = await query.toArray()

    return stripeTaxRates.map((stripeTaxRate) => ({
      text: `${stripeTaxRate.displayName()}${stripeTaxRate.hasDescription() ? ` - ${stripeTaxRate.description()}` : ""}`,
      value: stripeTaxRate.id()
    }))
  }
})

export default ComponentsStripeTaxRatesSelect
export {StripeTaxRatesSelectWithContainer}
