import {ContactEmail, SchoolClass} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class SchoolClassesSchoolInviteForm extends ShapeComponent {
  static defaultProps = {
    onInviteSent: undefined
  }

  static propTypes = {
    onInviteSent: PropTypes.func,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.roles.invite"})

    this.t = t
  }

  render() {
    const {className, onInviteSent, schoolClass, ...restProps} = this.props

    return (
      <form className={classNames("components--school-class-roles--invite-form", className)} onSubmit={this.tt.onInviteSubmit} {...restProps}>
        <Input className="email-input" label={ContactEmail.humanAttributeName("email")} name="email" />
        <Button label={this.t(".send_invitation")} primary />
      </form>
    )
  }

  onInviteSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)

    try {
      await this.p.schoolClass.invite(formData)
      FlashMessage.success(this.t(".the_invitation_was_sent"))

      if (this.p.onInviteSent) this.p.onInviteSent()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
