import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {ContentPieceType} from "models"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {v4 as uuidv4} from "uuid"
import withModel from "components/wooftech-with-model"
class RoutesAdminContentPieceTypesEdit extends PageComponent {
  static propTypes = {
    contentPieceType: PropTypes.instanceOf(ContentPieceType),
    contentPieceTypeId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {contentPieceType} = this.p

    return (
      <AdminLayout active="content-piece-types" className="routes--admin--content-piece-types--edit" headerTitle={this.headerTitle()}>
        {contentPieceType &&
          <form onSubmit={onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ContentPieceType.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={contentPieceType}
                />
              </React.Fragment>
            )}
            <Checkbox attribute="showOnDigitalClassRoom" model={contentPieceType} />
            <Input attribute="icon" model={contentPieceType} />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {contentPieceType} = this.p

    if (contentPieceType?.isNewRecord()) {
      return I18n.t("js.routes.admin.content_piece_types.edit.add_new_content_piece_type")
    } else if (contentPieceType?.isPersisted()) {
      return I18n.t("js.routes.admin.content_piece_types.edit.edit_content_piece_type", {name: contentPieceType.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {contentPieceType} = this.p

    if (contentPieceType.isNewRecord()) formData.append("content_piece_type[id]", contentPieceType.id())

    try {
      await contentPieceType.saveRaw(formData, {form})
      FlashMessage.success(I18n.t("js.routes.admin.content_piece_types.edit.the_content_piece_type_was_saved"))
      AppHistory.push(Routes.adminContentPieceTypePath(contentPieceType.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedContentPieceTypeAttributes = ["icon", "id", "name", "showOnDigitalClassRoom"]

for (const locale of Locales.availableLocales()) {
  selectedContentPieceTypeAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default withModel(
  RoutesAdminContentPieceTypesEdit,
  ContentPieceType,
  {
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    },
    select: {
      ContentPieceType: selectedContentPieceTypeAttributes
    }
  }
)
