import React, {useCallback} from "react"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import inflection from "inflection"
import Locales from "shared/locales"
import {PlanResourcePeriod} from "models"
import {PlanResourcesSelectWithContainer} from "components/plan-resources/select"
import {StripePlansSelectWithContainer} from "components/stripe-plans/select"

export default {
  show: {
    extraContent: ({planResourcePeriod}) => (
      <>
        {Locales.availableLocales().map((locale) =>
          <AttributeRow key={locale} label={`${PlanResourcePeriod.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}>
            {planResourcePeriod[`name${inflection.camelize(locale)}`]()}
          </AttributeRow>
        )}
      </>
    ),
    extraSelect: {
      PlanResourcePeriod: Locales.availableLocales().map((locale) => `name${inflection.camelize(locale)}`)
    }
  },
  edit: {
    attributes: [
      {attribute: "name", translated: true},
      {
        attribute: "planResourceId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [onChangeValue])

          return (
            <PlanResourcesSelectWithContainer
              label={PlanResourcePeriod.humanAttributeName("planResource")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      },
      {
        attribute: "stripePlanId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [onChangeValue])

          return (
            <StripePlansSelectWithContainer
              label={PlanResourcePeriod.humanAttributeName("stripePlan")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      },
      {attribute: "months"}
    ]
  }
}
