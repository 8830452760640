import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import MoodAverage from "components/score-factor-groups/mood-average"
import MoodSimpleLineChart from "components/score-factor-groups/mood-simple-line-chart"
import PageContentsPresentation from "components/page-contents/presentation"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class Slide2 extends ShapeComponent {
  static propTypes = propTypesExact({
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    schoolClassCheckIn: PropTypes.instanceOf(SchoolClassCheckIn).isRequired
  })

  setup() {
    const {schoolClass} = this.p
    const {t} = useI18n({namespace: "js.routes.user.school_classes.school_class_check_ins.slide_2"})

    this.setInstance({
      dateFrom: useMemo(() => moment().subtract(4, "weeks").startOf("day").toDate(), []),
      dateTo: useMemo(() => new Date(), []),
      t
    })
    this.useStates({
      latestCheckIn: undefined,
      latestCheckInTeamSurveysQuery: undefined
    })

    useMemo(() => {
      if (schoolClass) {
        this.loadLastSchoolClassCheckIn()
      }
    }, [schoolClass?.id()])
  }

  async loadLastSchoolClassCheckIn() {
    const {schoolClass} = this.p
    const latestCheckIn = await SchoolClassCheckIn
      .ransack({
        s: "created_at DESC",
        school_class_id_eq: schoolClass.id(),
        state_eq: "closed"
      })
      .select({SchoolClassCheckIn: ["createdAt", "id"]})
      .first()

    this.setState({
      latestCheckIn,
      latestCheckInTeamSurveysQuery: TeamSurvey.ransack({
        preview_false: true,
        school_class_check_in_id_eq: latestCheckIn?.id() || "no-latest-check-in-fake-uuid"
      })
    })
  }

  render() {
    const {dateFrom, dateTo} = this.tt
    const {schoolClass, schoolClassCheckIn} = this.p
    const {latestCheckIn, latestCheckInTeamSurveysQuery} = this.s
    const moodCircleActive = latestCheckIn?.length > 0

    return (
      <View dataSet={{component: "routes--user--school-classes--school-class-check-ins--slide-2"}}>
        <PageContentsPresentation
          identifier="user/school-classes/school-class-check-ins/slide-2"
          liquidVariables={{schoolClass, schoolClassCheckIn}}
        />
        <View style={{flexDirection: "row", width: "100%"}}>
          <View style={{width: 600}}>
            {latestCheckInTeamSurveysQuery &&
              <MoodAverage
                className="latest-woofs-mood-average"
                defaultAverage={moodCircleActive ? undefined : 3.0}
                teamSurveysQuery={latestCheckInTeamSurveysQuery}
              />
            }
          </View>
          <View style={{flexGrow: 1, paddingLeft: 30}}>
            <MoodSimpleLineChart dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
          </View>
        </View>
      </View>
    )
  }
}))
