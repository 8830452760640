import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Image} from "react-native"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"

const iconsRequire = require.context("images/icons", true, /\/(.+)\.svg$/)

export default memo(shapeComponent(class ComponentsIcon extends ShapeComponent {
  static propTypes = propTypesExact({
    icon: PropTypes.string.isRequired,
    iconProps: PropTypes.object
  })

  setup() {
    const {icon} = this.p

    this.useStates({
      IconComponent: null,
      imageSource: null
    })

    useMemo(() => {
      this.loadIcon()
    }, [icon])
  }

  loadIcon() {
    const {icon} = this.p
    const IconComponent = iconsRequire(`./${icon}.svg`)

    this.setState({IconComponent})
  }

  render() {
    const {icon, style, ...restProps} = this.props
    const {IconComponent} = this.s
    const actualStyle = Object.assign(
      {
        width: 16,
        height: 16
      },
      style
    )

    if (!IconComponent) {
      return (
        <Text>Loading...</Text>
      )
    }

    return (
      <Image source={IconComponent.default} style={actualStyle} {...restProps} />
    )
  }
}))
