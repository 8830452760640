import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {User} from "models"

export default memo(shapeComponent(class ComponentsUsersLink extends ShapeComponent {
  static propTypes = {
    adminLayout: PropTypes.bool,
    className: PropTypes.string,
    superAdminLayout: PropTypes.bool,
    user: PropTypes.instanceOf(User).isRequired,
    userLayout: PropTypes.bool
  }

  render() {
    const {adminLayout = false, children, className, superAdminLayout = false, user, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--users--link", className)
    let to

    if (adminLayout) {
      to = Routes.adminUserPath(user.id())
    } else if (superAdminLayout) {
      to = Routes.superAdminPath({model: "User", model_id: user.id()})
    } else {
      to = "#"
    }

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || user.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || user.name()}
      </span>
    )
  }
}))
