import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FrontLayout from "components/front-layout"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import setLayout from "shared/set-layout"
import Switch from "@kaspernj/api-maker/build/router/switch"
export default memo(shapeComponent(class RoutesFront extends ShapeComponent {
  setup() {
    setLayout("front")
  }

  render() {
    return (
      <Switch name="front">
        <FrontLayout>
          <Route component="accounts" path="accounts" />
          <Route component="school-class-invitations/accept" exact path="school_class_invitations/accept" />
          <Route component="users/apply-forgotten-password-request" exact path="users/apply_forgotten_password_request" />
          <Route component="users/confirmation" exact path="users/confirmation/:user_id" />
          <Route component="users/forgot-password" exact path="users/forgot_password" />
          <Route component="users/resend-confirmation-email" exact path="users/resend_confirmation_email" />
          <Route component="welcome" exact />
        </FrontLayout>
      </Switch>
    )
  }
}))
