import {NotificationType, NotificationTypeAction} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import NotificationTypeActionsTable from "components/notification-type-actions/table"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNotificationTypesActions extends ShapeComponent {
  setup() {
    const canCan = useCanCan(() => [[NotificationTypeAction, ["create"]]])
    const params = useParams()
    const {t} = useI18n({namespace: "js.routes.admin.notificationTypes.notification_type_actions"})
    const {notificationType, notificationTypeId} = useModel(NotificationType, {match: {params}})
    const actionsQuery = useMemo(
      () => NotificationTypeAction.ransack({notification_type_id_eq: notificationTypeId}),
      [notificationTypeId]
    )

    this.setInstance({actionsQuery, canCan, notificationType, notificationTypeId, t})
    useAdminLayout()?.setState({actions: this.actions(), active: "notification-types", headerTitle: NotificationType.humanAttributeName("actions")})
  }

  render() {
    const {actionsQuery, notificationType} = this.tt

    return (
      <View dataSet={{route: "admin--notification-types--actions"}}>
        {notificationType &&
          <Nav active="actions" className="mb-4" notificationType={notificationType} />
        }
        <NotificationTypeActionsTable
          adminLayout
          collection={actionsQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, notificationTypeId} = this.tt

    return (
      <>
        {canCan?.can("create", NotificationTypeAction) &&
          <Button
            className="new-notification-type-action-button"
            icon="plus"
            label={this.t(".new_action_for_notification_type")}
            to={Routes.newAdminNotificationTypeActionPath({notification_type_action: {notification_type_id: notificationTypeId}})}
          />
        }
      </>
    )
  }
}))
