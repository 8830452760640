import {ContactRelationship, SchoolClass} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import {RolesSelectWithContainer} from "components/school-roles/roles-select"
import {SchoolClassesSelectWithContainer} from "components/school-classes/select"
import TeachersSelect from "components/user/admin/school-classes/edit/teachers-select"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolRolesNewRoleModal extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.components.school_roles.new_role_modal"})
    const schoolRole = useMemo(() => new ContactRelationship(), [])

    this.setInstance({locale, schoolRole, t})
  }

  render() {
    const {locale, schoolRole, t} = this.tt
    const {className, school, ...restProps} = this.props

    this.setInstance({schoolRole})
    this.useStates({
      createNewUser: false,
      role: undefined,
      schoolClassIds: [],
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userId: undefined
    })

    const headerTitle = useMemo(() => {
      if (schoolRole?.isNewRecord()) {
        return t(".add_user_to_school")
      } else if (schoolRole?.isPersisted()) {
        return t(".edit_role_on_school")
      }
    }, [locale, schoolRole?.id()])

    const schoolClassesQuery = useMemo(() => SchoolClass.ransack({school_id_eq: this.p.school.id()}), [])

    return (
      <Modal className={classNames("components--school-roles--new-role-modal", className)} {...restProps}>
        <Heading>{headerTitle}</Heading>
        <RolesSelectWithContainer id="role" label={t(".role")} onChange={this.tt.onRoleChanged} />
        <SchoolClassesSelectWithContainer
          id="school_classes"
          label={SchoolClass.modelName().human({count: 2})}
          multiple
          onChange={this.tt.onSchoolClassesChanged}
          query={schoolClassesQuery}
        />
        {school &&
          <TeachersSelect
            id="user_id"
            label={t(".assign_users")}
            onChange={this.tt.onUserChanged}
            school={school}
          />
        }
        {this.s.createNewUser &&
          <View style={{marginTop: 10}}>
            <UtilsInput
              dataSet={{class: "user-first-name-input"}}
              label={t(".first_name_of_user")}
              onChangeText={this.setStates.userFirstName}
              style={{marginBottom: 10}}
              value={this.s.userFirstName}
            />
            <UtilsInput
              dataSet={{class: "user-last-name-input"}}
              label={t(".last_name_of_user")}
              onChangeText={this.setStates.userLastName}
              style={{marginBottom: 10}}
              value={this.s.userLastName}
            />
            <UtilsInput
              dataSet={{class: "user-email-input"}}
              label={t(".email_of_user")}
              onChangeText={this.setStates.userEmail}
              style={{marginBottom: 10}}
              value={this.s.userEmail}
            />
          </View>
        }
        <UtilsButton
          dataSet={{class: "save-school-role-button"}}
          onPress={this.tt.onSavePressed}
          style={{marginTop: 10}}
          title={this.s.createNewUser ? t(".invite_user_and_assign_to_school") : t(".assign_user_to_school")}
        />
      </Modal>
    )
  }

  onRoleChanged = ({options}) => {
    const option = options[0]

    this.setState({role: option.value})
  }

  onSchoolClassesChanged = ({options}) => {
    this.setState({
      schoolClassIds: options.map((option) => option.value)
    })
  }

  onUserChanged = ({options}) => {
    const option = options[0]

    if (option?.optionType == "teacher") {
      this.setState({
        createNewUser: false,
        userId: option.value
      })
    } else if (option?.optionType == "create-new-teacher") {
      this.setState({
        createNewUser: true,
        userId: null
      })
    }
  }

  onSavePressed = async () => {
    try {
      const isNewSchoolRole = this.tt.schoolRole.isNewRecord()
      const saveData = {
        school_role: {
          role: this.s.role,
          school_id: this.p.school.id()
        }
      }

      if (this.s.userId) {
        saveData.school_role.user_id = this.s.userId
      }

      if (this.s.createNewUser) {
        const password = uuidv4()

        saveData.school_role.user_attributes = {
          email: this.s.userEmail,
          first_name: this.s.userFirstName,
          last_name: this.s.userLastName,
          password,
          password_confirmation: password
        }
      }

      saveData.school_classes = this.s.schoolClassIds

      await ContactRelationship.schoolLeaderCreate(saveData)
      FlashMessage.success(isNewSchoolRole ? this.t(".the_role_was_created") : this.t(".the_role_was_updated"))
      this.p.onRequestClose()
    } catch (e) {
      FlashMessage.errorResponse(e)
    }
  }
}))
