import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import FrontLayout from "components/front-layout"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import UserLayout from "components/user-layout"
const RoutesAdminDashboard = React.lazy(() => import("routes/admin/dashboard-route"))
const RoutesUserDashboard = React.lazy(() => import("routes/user/dashboard-route"))
const UsersSignIn = React.lazy(() => import("components/users/sign-in"))

export default memo(shapeComponent(class RoutesFrontWelcome extends ShapeComponent {
  render() {
    setLayout("front")

    const currentUser = useCurrentUser()

    if (isUserA(currentUser, ["admin", "hacker", "sales_rep"])) {
      return (
        <AdminLayout>
          <RoutesAdminDashboard />
        </AdminLayout>
      )
    } else if (isUserA(currentUser, "student")) {
      return (
        <FrontLayout className="routes--front--welcome">
          <UsersSignIn redirectAfterSignIn />
        </FrontLayout>
      )
    } else if (isUserA(currentUser, "teacher")) {
      return (
        <UserLayout className="routes--front--welcome">
          <RoutesUserDashboard />
        </UserLayout>
      )
    } else {
      return (
        <div className="align-center" style={{width: "100%", height: "100%"}}>
          <div className="routes--front--welcome">
            <UsersSignIn redirectAfterSignIn />
          </div>
        </div>
      )
    }
  }
}))
