import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import TextKeysDestroyed from "./destroyed"
import TextKeysInputs from "./inputs"

export default memo(shapeComponent(class ComponentsTextKeysHandle extends ShapeComponent {
  static propTypes = propTypesExact({
    cacheKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    resource: PropTypes.object.isRequired
  })

  render() {
    const {cacheKey, name, resource} = this.p

    return (
      <div className="components--text-keys--handle">
        <TextKeysDestroyed cacheKey={cacheKey} name={name} resource={resource} />
        {this.p.resource.textKeys().map((textKey) =>
          <TextKeysInputs
            cacheKey={cacheKey}
            key={textKey.uniqueKey()}
            name={name}
            textKey={textKey}
          />
        )}
      </div>
    )
  }
}))
