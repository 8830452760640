import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {AccountSchool} from "models"
import {AccountsSelectWithContainer} from "components/accounts/select"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SchoolsSelectWithContainer} from "components/schools/select"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"

export default memo(shapeComponent(class RoutesAdminAccountSchoolsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.account_schools.edit"})
    const {accountSchool, accountSchoolId} = useModel(AccountSchool, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        AccountSchool: ["accountId", "id", "schoolId"]
      }
    })

    this.setInstance({accountSchool, accountSchoolId, t})
  }

  render() {
    const {accountSchool, onSubmit} = this.tt

    return (
      <AdminLayout active="school-classes" className="routes--admin--account-schools--edit" headerTitle={this.headerTitle()}>
        {accountSchool &&
          <form onSubmit={onSubmit}>
            <AccountsSelectWithContainer
              attribute="accountId"
              label={AccountSchool.humanAttributeName("account")}
              model={accountSchool}
            />
            <SchoolsSelectWithContainer
              attribute="schoolId"
              label={AccountSchool.humanAttributeName("school")}
              model={accountSchool}
            />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {accountSchool} = this.tt

    if (accountSchool?.isNewRecord()) {
      return this.t(".add_new_account_school")
    } else if (accountSchool?.isPersisted()) {
      return this.t(".edit_account_school")
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {accountSchool} = this.tt

    if (accountSchool.isNewRecord()) formData.append("account_school[new_id]", accountSchool.id())

    try {
      await accountSchool.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_account_school_was_saved"))
      AppHistory.push(Routes.adminAccountSchoolPath(accountSchool.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
