import React, {useCallback} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminInput from "components/admin/input"
import {EvaluationGroupsSelectWithContainer} from "components/evaluation-groups/select"
import {EvaluationRule} from "models"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Routes from "shared/routes"
import Select from "components/inputs/select"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {v4 as uuidv4} from "uuid"

const Actions = memo(shapeComponent(class SuperAdminModelConfigsEvaluationRuleActions extends ShapeComponent {
  static propTypes = propTypesExact({
    evaluationRule: PropTypes.instanceOf(EvaluationRule).isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.super_admin.model_configs.evaluation_rule.actions"})

    this.t = t
  }

  render() {
    const {t} = this.tt
    const {evaluationRule} = this.p

    return (
      <Link to={Routes.adminEvaluationRuleTestPath(evaluationRule.id())}>
        {t(".test")}
      </Link>
    )
  }
}))

export default {
  actions: ({model}) => <Actions evaluationRule={model} />,
  edit: {
    attributes: [
      {
        attribute: "evaluationGroupId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <EvaluationGroupsSelectWithContainer
              label={EvaluationRule.humanAttributeName("evaluationGroup")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        },
        label: EvaluationRule.humanAttributeName("evaluationGroup")
      },
      {attribute: "name", translated: true},
      {attribute: "active"},
      {attribute: "position"},
      {
        attribute: "liquidRule",
        content: ({onChangeValue, value}) => (
          <AdminInput
            autoRows
            dataSet={{id: "evaluation_rule_liquid_rule"}}
            label={EvaluationRule.humanAttributeName("liquidRule")}
            multiline
            onChangeText={onChangeValue}
            value={value}
          />
        )
      },
      {
        attribute: "ruleType",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          const stateOptions = TranslatedCollections.get(EvaluationRule, "rule_type").map(({translation, value}) => ({text: translation, value}))

          return (
            <Select
              name={null}
              onChange={onChange}
              options={stateOptions}
              type="hayaSelect"
              {...inputProps}
            />
          )
        }
      }
    ],
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    }
  },
  layout: {
    select: {
      EvaluationRule: ["id", "name"]
    }
  }
}
