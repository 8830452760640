import {ContentAbsoluteContainer, SurveyStep, SurveyStepElement} from "models"
import {dig, digg} from "diggerize"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {ElementTypeSelectWithContainer} from "components/survey-step-elements/element-type-select"
import EventEmitter from "events"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Select from "components/inputs/select"
import {StylingsSelectWithContainer} from "components/stylings/select"
import TextKeysPartial from "components/text-keys/partial"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsCheckbox from "components/utils/checkbox"
import UtilsInput from "components/utils/input"
import UtilsSelect from "components/utils/select"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsContentsEditorAbsoluteContainerEditor extends ShapeComponent {
  static propTypes = propTypesExact({
    absoluteContainer: PropTypes.instanceOf(ContentAbsoluteContainer).isRequired,
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    onAbsoluteContainerChanged: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    surveyStep: PropTypes.instanceOf(SurveyStep)
  })

  setup() {
    const {absoluteContainer} = this.p
    const {locale, t} = useI18n({namespace: "js.components.contents.editor.absolute_container_editor"})
    const bodyTypesCollection = useMemo(
      () => TranslatedCollections.get(ContentAbsoluteContainer, "body_type").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )
    const horizontalAlignOptions = useMemo(
      () => TranslatedCollections.get(ContentAbsoluteContainer, "horizontal_align").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )
    const verticalAlignOptions = useMemo(
      () => TranslatedCollections.get(ContentAbsoluteContainer, "vertical_align").map(({translation, value}) => ({text: translation, value})),
      [locale]
    )

    this.setInstance({
      bodyTypesCollection,
      horizontalAlignOptions,
      verticalAlignOptions,
      t
    })
    this.useStates({
      body: absoluteContainer.body(),
      bodyType: absoluteContainer.bodyType(),
      elementType: absoluteContainer.surveyStepElement()?.elementType(),
      horizontalAlignOptions,
      liquidConditions: absoluteContainer.liquidConditions(),
      minimumHeight: absoluteContainer.minimumHeight(),
      minimumWidth: absoluteContainer.minimumWidth(),
      previewImageSrc: absoluteContainer.backgroundImageUrl(),
      verticalAlignOptions
    })
  }

  render() {
    const {bodyTypesCollection, horizontalAlignOptions, verticalAlignOptions} = this.tt
    const {absoluteContainer} = this.p
    const {body, bodyType, elementType, liquidConditions, previewImageSrc} = this.s

    return (
      <div className="components--contents--editor--absolute-container-editor">
        <Checkbox attribute="responsive" model={absoluteContainer} onChange={this.tt.onResponsiveChanged} />
        <Input
          attribute="backgroundImage"
          defaultValue={null}
          label={this.t(".background_image")}
          model={absoluteContainer}
          name={null}
          onChange={this.tt.onBackgroundImageChanged}
          type="file"
        />
        {this.props.surveyStep &&
          <ElementTypeSelectWithContainer
            defaultValue={elementType}
            id="element_type_select"
            label={SurveyStepElement.humanAttributeName("elementType")}
            onChange={this.tt.onElementTypeChanged}
          />
        }
        <StylingsSelectWithContainer
          attribute="stylingId"
          label={ContentAbsoluteContainer.humanAttributeName("styling")}
          model={absoluteContainer}
          onChange={this.tt.onStylingChanged}
        />
        <Select
          attribute="horizontalAlign"
          model={absoluteContainer}
          onChange={this.tt.onHorizontalAlignChanged}
          options={horizontalAlignOptions}
          type="hayaSelect"
        />
        <Select
          attribute="verticalAlign"
          model={absoluteContainer}
          onChange={this.tt.onVerticalAlignChanged}
          options={verticalAlignOptions}
          type="hayaSelect"
        />
        <UtilsCheckbox
          checked={this.s.minimumWidth}
          dataSet={{class: "minimum-width-checkbox"}}
          label={ContentAbsoluteContainer.humanAttributeName("minimumWidth")}
          onChange={this.tt.onMinimumWidthChanged}
          style={{marginTop: 12, marginBottom: 12}}
        />
        <UtilsCheckbox
          checked={this.s.minimumHeight}
          dataSet={{class: "minimum-height-checkbox"}}
          label={ContentAbsoluteContainer.humanAttributeName("minimumHeight")}
          onChange={this.tt.onMinimumHeightChanged}
          style={{marginTop: 12, marginBottom: 12}}
        />
        {previewImageSrc &&
          <img className="background-image-preview" src={previewImageSrc} style={{width: "50%"}} />
        }
        {!elementType &&
          <>
            <View style={{marginBottom: 10}}>
              <UtilsSelect
                id="content_absolute_container_body_type"
                label={ContentAbsoluteContainer.humanAttributeName("bodyType")}
                onChangeSelected={this.tt.onBodyTypeChanged}
                options={bodyTypesCollection}
                values={[bodyType]}
              />
            </View>
            {bodyType == "ckeditor" &&
              <Input attribute="body" defaultValue={body} model={absoluteContainer} onChange={this.tt.onBodyChanged} type="ckeditor" />
            }
            {bodyType == "plain" &&
              <UtilsInput
                autoRows
                label={ContentAbsoluteContainer.humanAttributeName("body")}
                multiline
                onChangeText={this.tt.onBodyValueChanged}
                value={body}
              />
            }
            <TextKeysPartial
              attributeName="body"
              content={body || ""}
              name="content_absolute_container"
              onTextKeysChanged={this.tt.onTextKeysChanged}
              resource={absoluteContainer}
            />
          </>
        }
        <UtilsInput
          autoRows
          label={ContentAbsoluteContainer.humanAttributeName("liquidConditions")}
          multiline
          onChangeText={this.tt.onLiquidConditionsChanged}
          value={liquidConditions}
        />
        <Button
          className="delete-absolute-container-button"
          icon="remove"
          label={this.t("js.shared.delete")}
          onClick={this.tt.onDeleteAbsoluteContainerClicked}
        />
      </div>
    )
  }

  contentCallback = () => document.getElementById("content_absolute_container_body").value

  onStylingChanged = ({options}) => {
    const option = options[0]
    const model = option?.styling

    this.p.absoluteContainer.assignAttributes({stylingId: option?.value})
    this.p.absoluteContainer.preloadRelationship("styling", model)
    this.sendAbsoluteContainerChangedEvent()
  }

  onBackgroundImageChanged = (e) => {
    const files = digg(e, "target", "files")
    const file = digg(files, 0)

    this.p.absoluteContainer.assignAttributes({backgroundImage: file})
    this.p.absoluteContainer.backgroundImageInputFiles = files

    const fileReader = new FileReader()

    fileReader.onload = (e) => {
      this.setState({previewImageSrc: e.target.result})
      this.sendAbsoluteContainerChangedEvent()
    }
    fileReader.readAsDataURL(file)
  }

  onBodyChanged = ({value}) => this.onBodyValueChanged(value)

  onBodyValueChanged = (value) => {
    this.setState({body: value})
    this.p.absoluteContainer.assignAttributes({body: value})
    this.sendAbsoluteContainerChangedEvent()
  }

  onBodyTypeChanged = (bodyType) => this.setState({bodyType})

  onDeleteAbsoluteContainerClicked = (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    this.p.absoluteContainer.markForDestruction()
    this.p.onRequestClose()
  }

  onElementTypeChanged = ({options}) => {
    const {absoluteContainer} = this.p
    const elementType = dig(options, 0, "value")

    if (!absoluteContainer.surveyStepElement()) {
      absoluteContainer.preloadRelationship("surveyStepElement", new SurveyStepElement())
    }

    if (!absoluteContainer.surveyStepElement()) {
      throw new Error("Expected element to be present but it wasn't")
    }

    absoluteContainer.surveyStepElement().assignAttributes({elementType})

    this.setState({elementType})
    this.sendAbsoluteContainerChangedEvent()
  }

  onLiquidConditionsChanged = (liquidConditions) => {
    this.setState({liquidConditions})
    this.p.absoluteContainer.assignAttributes({liquidConditions})
    this.sendAbsoluteContainerChangedEvent()
  }

  onHorizontalAlignChanged = ({options}) => {
    const horizontalAlign = dig(options, 0, "value")

    this.p.absoluteContainer.assignAttributes({horizontalAlign})
    this.sendAbsoluteContainerChangedEvent()
  }

  onMinimumHeightChanged = ({checked: minimumHeight}) => {
    this.p.absoluteContainer.assignAttributes({minimumHeight})
    this.setState({minimumHeight})
    this.sendAbsoluteContainerChangedEvent()
  }

  onMinimumWidthChanged = ({checked: minimumWidth}) => {
    this.p.absoluteContainer.assignAttributes({minimumWidth})
    this.setState({minimumWidth})
    this.sendAbsoluteContainerChangedEvent()
  }

  onResponsiveChanged = (e) => {
    this.p.absoluteContainer.assignAttributes({responsive: digg(e, "target", "checked")})
    this.sendAbsoluteContainerChangedEvent()
  }

  onTextKeysChanged = () => this.sendAbsoluteContainerChangedEvent()

  onVerticalAlignChanged = ({options}) => {
    const verticalAlign = dig(options, 0, "value")

    this.p.absoluteContainer.assignAttributes({verticalAlign})
    this.sendAbsoluteContainerChangedEvent()
  }

  sendAbsoluteContainerChangedEvent() {
    this.p.events.emit("absoluteContainerChanged", {
      cacheKey: this.p.absoluteContainer.fullCacheKey(),
      id: this.p.absoluteContainer.id(),
      previewImageSrc: this.s.previewImageSrc
    })
  }
}))
