import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import HayaTypeahead from "haya-typeahead/src/typeahead"
import memo from "set-state-compare/src/memo"
import React from "react"
import {School} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useInput from "@kaspernj/api-maker/build/use-input"
import {withInputContainer} from "components/inputs/input-container"

const ComponentsSchoolsTypeahead = memo(shapeComponent(class ComponentsSchoolsTypeahead extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.schools.typeahead"})

    this.t = t
  }

  render() {
    const {inputProps, restProps} = useInput({props: this.props})
    const {className, icon, label, query, style, ...actualRestProps} = restProps
    const actualStyle = Object.assign({zIndex: 1}, style)
    const actualInputProps = {...inputProps, style: {paddingTop: 17, paddingRight: 26, paddingBottom: 17, paddingLeft: 26}}

    return (
      <HayaTypeahead
        className={classNames("components--schools-typeahead", className)}
        inputProps={actualInputProps}
        optionActiveStyle={{backgroundColor: "#a9c6de"}}
        optionStyle={{padding: 5}}
        optionsCallback={this.tt.optionsCallback}
        optionsContainerStyle={{borderRadius: "0 0 5 5"}}
        style={actualStyle}
        {...actualRestProps}
      />
    )
  }

  optionsCallback = async ({searchValue, values}) => {
    let query = this.props.query?.clone() || School

    query = query
      .ransack({s: "contact_current_translation_name asc"})
      .select({School: ["id", "name"]})
      .limit(20)

    if (searchValue) query = query.ransack({contact_current_translation_name_cont_any_word: searchValue})
    if (values) query = query.ransack({id_eq_any: values})

    const schools = await query.toArray()

    return schools.map((school) => ({
      text: school.name() || this.t(".school_with_no_name"),
      value: school.id()
    }))
  }
}))

const SchoolsTypeaheadWithContainer = withInputContainer(ComponentsSchoolsTypeahead)

export default ComponentsSchoolsTypeahead
export {SchoolsTypeaheadWithContainer}
