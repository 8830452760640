import {Plan, Subscription} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/plans/nav"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import SubscriptionsTable from "components/subscriptions/table"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminPlansSubscriptions extends ShapeComponent {
  setup() {
    setLayout("admin")
  }

  render() {
    const {t} = useI18n({namespace: "js.routes.admin.plans.subscriptions"})
    const {plan, planId} = useModel(Plan, {match: this.p.match})
    const subscriptionsQuery = useMemo(() => Subscription.ransack({plan_id_eq: planId}), [planId])

    return (
      <AdminLayout className="routes--admin--plans--subscriptions" headerTitle={Plan.humanAttributeName("subscriptions")}>
        {plan &&
          <>
            <Nav active="subscriptions" className="mb-4" plan={plan} />
            <Button
              className="mb-4 new-subscription-button"
              label={t(".new_subscription")}
              to={Routes.newAdminSubscriptionPath({subscription: {plan_id: plan.id()}})}
            />
          </>
        }
        <SubscriptionsTable
          adminLayout
          collection={subscriptionsQuery}
        />
      </AdminLayout>
    )
  }
}))
