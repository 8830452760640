import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Translation} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
export default memo(shapeComponent(class RoutesAdminTranslationsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.translations.edit"})
    const {translation, translationId} = useModel(Translation, {
      match: this.p.match,
      newIfNoId: true,
      select: {
        Translation: ["approved", "id", "locale", "key", "value"]
      }
    })

    this.setInstance({translation, translationId, t})
  }

  render() {
    const {onSubmit, translation} = this.tt

    return (
      <AdminLayout active="surveys" className="routes--admin--translations--edit" headerTitle={this.headerTitle()}>
        {translation &&
          <form onSubmit={onSubmit}>
            <Checkbox attribute="approved" model={translation} />
            <Input attribute="key" model={translation} />
            <Input attribute="locale" model={translation} />
            <Input attribute="value" model={translation} type="textarea" />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {translation} = this.tt

    if (translation?.isNewRecord()) {
      return this.t(".add_new_translation")
    } else if (translation?.isPersisted()) {
      return this.t(".edit_translation", {name: translation.key()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {translation} = this.tt

    try {
      await translation.saveRaw(form)
      FlashMessage.success(this.t(".the_translation_was_saved"))
      AppHistory.push(Routes.adminTranslationPath(translation.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
