import {Contact, ContactRelationship} from "models"
import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import ContactRelationshipsTable from "components/contact-relationships/table"
import Icon from "components/icon"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"

export default memo(shapeComponent(class RoutesAdminContactsRelationships extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.contacts.relationships"})
    const params = useParams()
    const {contact, contactId} = useModel(Contact, {
      match: {params},
      select: {
        Contact: ["contactPointsCount", "id"]
      }
    })
    const parentsQuery = useMemo(() => ContactRelationship.ransack({child_id_eq: contactId}), [contactId])
    const childrenQuery = useMemo(() => ContactRelationship.ransack({parent_id_eq: contactId}), [contactId])

    this.setInstance({childrenQuery, contact, contactId, parentsQuery})

    useAdminLayout()?.setState({active: "contacts", headerTitle: t(".relationships")})
  }

  render() {
    const {childrenQuery, contact, parentsQuery} = this.tt

    return (
      <View dataSet={{route: "admin--contacts--relationships"}}>
        {contact &&
          <Nav active="relationships" contact={contact} style={{marginBottom: 10}} />
        }
        <View style={{flexDirection: "row", width: "100%"}}>
          <ContactRelationshipsTable
            adminLayout
            collection={parentsQuery}
            controls={this.tt.parentsControls}
            header={Contact.humanAttributeName("parents")}
            identifier="contact-relationships-parents"
            styles={{container: {width: "50%", marginRight: 7}}}
          />
          <ContactRelationshipsTable
            adminLayout
            collection={childrenQuery}
            controls={this.tt.childrenControls}
            header={Contact.humanAttributeName("children")}
            identifier="contact-relationships-children"
            styles={{container: {width: "50%", marginLeft: 7}}}
          />
        </View>
      </View>
    )
  }

  childrenControls = () => <>
    <Pressable dataSet={{class: "add-child-action"}} onPress={this.tt.onAddChildPress}>
      <Icon icon="pen-solid" />
    </Pressable>
  </>

  onAddChildPress = () => AppHistory.push(Routes.newAdminContactRelationshipPath({contact_relationship: {parent_id: this.tt.contactId}}))
  onAddParentPress = () => AppHistory.push(Routes.newAdminContactRelationshipPath({contact_relationship: {child_id: this.tt.contactId}}))

  parentsControls = () => <>
    <Pressable dataSet={{class: "add-parent-action"}} onPress={this.tt.onAddParentPress}>
      <Icon icon="pen-solid" />
    </Pressable>
  </>
}))
