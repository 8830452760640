import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {TextKey, TextValue} from "models"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {v4 as uuidv4} from "uuid"

export default memo(shapeComponent(class TextKeyPartial extends ShapeComponent {
  static propTypes = propTypesExact({
    locale: PropTypes.string.isRequired,
    textKey: PropTypes.instanceOf(TextKey).isRequired
  })

  setup() {
    this.useStates({
      textValue: () => this.initialTextValue()
    })
  }

  initialTextValue() {
    const {locale, textKey} = this.p
    let textValue = textKey.textValues().find((textValue) => textValue.locale() == locale)

    if (!textValue) {
      textValue = new TextValue({
        a: {
          id: uuidv4(),
          locale,
          text_key_id: textKey.id(),
          value: ""
        },
        isNewRecord: true
      })
    }

    return textValue
  }

  render() {
    const {locale, textKey} = this.p
    const {textValue} = this.s
    const inputName = `text_keys[${textKey.uniqueKey()}][text_values_attributes][${textValue.uniqueKey()}]`
    const idInputName = textValue.isNewRecord() ? "new_id" : "id"

    if (!textValue.hasId()) throw new Error("The 'id' wasn't set on the text value")
    if (!textValue.hasLocale()) throw new Error("The 'locale' wasn't set on the text value")

    return (
      <div className="text-key-partial" data-key={textKey.key()} data-locale={locale}>
        {textValue.isNewRecord() &&
          <input name={`${inputName}[${idInputName}]`} type="hidden" value={textValue.id()} />
        }
        <input name={`${inputName}[locale]`} type="hidden" value={textValue.locale()} />
        <Input
          defaultvalue={textValue.value() || ""}
          id={`${textKey.key()}_${locale}`}
          name={`${inputName}[value]`}
          resource={textKey}
          type="ckeditor"
        />
      </div>
    )
  }
}))
