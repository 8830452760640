import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsUserDashboardDateHeader extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.components.user.dashboard.date_header"})
    const {className, ...restProps} = this.props
    const now = moment()
    const lowerCasedDayName = now.format("dddd")
    const date = `${lowerCasedDayName.substring(0, 1).toUpperCase()}${lowerCasedDayName.substring(1)}`
    const day = now.date()
    const month = now.format("MMMM")
    const week = now.isoWeek()

    return (
      <div className={classNames("components--user--dashboard--date-header", className)} {...restProps}>
        {t(".day_name_at_date_in_monnth_in_week", {date, day, month, week})}
      </div>
    )
  }
}))
