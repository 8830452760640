import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import React from "react"

export default memo(shapeComponent(class ComponentsNav extends ShapeComponent {
  render() {
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("components--nav", className)} {...restProps} />
    )
  }
}))
