import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import {Plan} from "models"
import PlansTable from "components/plans/table"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminPlansIndex extends ShapeComponent {
  render() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.plans.index"})
    const canCan = useCanCan(() => [[Plan, ["create"]]])
    const actions = <>
      {canCan?.can("create", Plan) &&
        <Button
          className="mb-4 new-plan-button"
          icon="plus"
          label={t(".add_new_plan")}
          to={Routes.newAdminPlanPath()}
        />
      }
    </>

    return (
      <AdminLayout actions={actions} active="plans" className="routes--admin--plans--index" headerTitle={Plan.modelName().human({count: 2})}>
        <PlansTable adminLayout />
      </AdminLayout>
    )
  }
}))
