import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Select from "components/inputs/select"
import {TextKey} from "models"
import TextKeyPartial from "components/admin/text-keys/translate/text-key-partial"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminTextKeysTranslate extends ShapeComponent {
  static propTypes = {
    queryParams: PropTypes.object.isRequired
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.text_keys.translate"})

    this.localesOptions = useMemo(
      () => Locales.availableLocales().map((locale) => [Locales.labelForLocale(locale), locale]),
      [locale]
    )

    this.setInstance({t})
    this.useStates({
      locale,
      textKeys: undefined
    })

    useMemo(() => {
      this.loadTextKeys()
    }, [])
  }

  async loadTextKeys() {
    const {locale} = this.s
    const textKeys = await TextKey
      .ransack({with_missing_translation_in_locale: locale})
      .preload("text_values")
      .select({
        TextKey: ["id", "key"],
        TextValue: ["locale"]
      })
      .toArray()

    this.setState({textKeys})
  }

  render() {
    const {t} = this.tt
    const {locale, textKeys} = this.s

    return (
      <AdminLayout className="routes--admin--text-keys--translate">
        <form onSubmit={this.tt.onFilterSubmit}>
          <Select
            id="locale"
            label={t(".language")}
            name="locale"
            options={this.tt.localesOptions}
          />
          <Button
            className="filter-submit-button"
            label={t("js.shared.search")}
            primary
          />
        </form>
        {textKeys?.length > 0 &&
          <form onSubmit={this.tt.onTextKeysSubmit}>
            {textKeys?.map((textKey) =>
              <TextKeyPartial
                key={`${textKey.id()}-${locale}`}
                locale={locale}
                textKey={textKey}
              />
            )}
            <Button
              className="text-keys-submit-button"
              icon="save"
              label={t("js.shared.save")}
              primary
            />
          </form>
        }
        {textKeys?.length === 0 &&
          t(".no_untranslated_text_keys_were_found")
        }
      </AdminLayout>
    )
  }

  onFilterSubmit = (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const locale = formData.get("locale")

    this.setState({locale})
    this.loadTextKeys()
  }

  onTextKeysSubmit = async (e) => {
    e.preventDefault()

    const {t} = this.tt
    const form = digg(e, "target")

    try {
      await TextKey.translate(form)
      FlashMessage.success(t(".the_translations_were_saved"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
