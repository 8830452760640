import {ContactRelationship, SchoolClass} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Card from "components/utils/card"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import TeachersSelect from "components/user/admin/school-classes/edit/teachers-select"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserAdminSchoolClassesRolesEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.admin.school_classes.roles.edit"})
    const params = useParams()
    const {schoolClass} = useModel(SchoolClass, {
      match: {params},
      preload: ["school"]
    })
    const {contactRelationship} = useModel(ContactRelationship, {
      match: {params},
      newIfNoId: true
    })

    this.setInstance({schoolClass, contactRelationship, t})
    this.useStates({
      createNewTeacher: false,
      teacherFirstName: "",
      teacherLastName: "",
      teacherEmail: "",
      teacherId: undefined
    })

    useFrontLayout().setState({active: "user-admin-school-classes", headerTitle: this.headerTitle()})
  }

  render() {
    const {schoolClass, contactRelationship, t} = this.tt

    return (
      <div className="routes--user--admin--school-classes--roles--edit">
        {schoolClass && contactRelationship &&
          <Card style={{maxWidth: 490, marginLeft: "auto", marginRight: "auto"}}>
            <Heading>
              {t(".add_teacher_to_class")}
            </Heading>
            {schoolClass?.school() &&
              <TeachersSelect id="teacher_id" label={t(".assign_teachers")} onChange={this.tt.onTeacherChanged} school={schoolClass.school()} />
            }
            {this.s.createNewTeacher &&
              <View style={{marginTop: 10}}>
                <UtilsInput
                  dataSet={{class: "teacher-first-name-input"}}
                  label={t(".first_name_of_teacher")}
                  onChangeText={this.setStates.teacherFirstName}
                  style={{marginBottom: 10}}
                  value={this.s.teacherFirstName}
                />
                <UtilsInput
                  dataSet={{class: "teacher-last-name-input"}}
                  label={t(".last_name_of_teacher")}
                  onChangeText={this.setStates.teacherLastName}
                  style={{marginBottom: 10}}
                  value={this.s.teacherLastName}
                />
                <UtilsInput
                  dataSet={{class: "teacher-email-input"}}
                  label={t(".email_of_teacher")}
                  onChangeText={this.setStates.teacherEmail}
                  style={{marginBottom: 10}}
                  value={this.s.teacherEmail}
                />
              </View>
            }
            <UtilsButton
              dataSet={{class: "save-school-class-role-button"}}
              onPress={this.tt.onSaveClassPressed}
              style={{marginTop: 10}}
              title={this.s.createNewTeacher ? t(".invite_teacher_and_assign_to_class") : t(".assign_teacher_to_class")}
            />
          </Card>
        }
      </div>
    )
  }

  headerTitle = () => {
    if (this.tt.contactRelationship?.isNewRecord()) {
      return this.t(".add_teacher")
    } else if (this.tt.contactRelationship?.isPersisted()) {
      return this.t(".edit_role_on_class")
    }
  }

  onTeacherChanged = ({options}) => {
    const option = options[0]

    if (option?.optionType == "teacher") {
      this.setState({
        createNewTeacher: false,
        teacherId: option.value
      })
    } else if (option?.optionType == "create-new-teacher") {
      this.setState({
        createNewTeacher: true,
        teacherId: null
      })
    }
  }

  onSaveClassPressed = async () => {
    try {
      const isNewSchoolClassRole = this.tt.contactRelationship.isNewRecord()
      const saveData = {
        school_class_role: {
          role: "teacher",
          school_class_id: this.tt.schoolClass.id()
        }
      }

      if (this.s.teacherId) {
        saveData.school_class_role.user_id = this.s.teacherId
      }

      if (this.s.createNewTeacher) {
        const password = uuidv4()

        saveData.school_class_role.user_attributes = {
          email: this.s.teacherEmail,
          first_name: this.s.teacherFirstName,
          last_name: this.s.teacherLastName,
          password,
          password_confirmation: password
        }
      }

      await ContactRelationship.schoolLeaderCreate(saveData)
      FlashMessage.success(isNewSchoolClassRole ? this.t(".the_role_was_created") : this.t(".the_role_was_updated"))
      AppHistory.push(Routes.userAdminSchoolClassesPath())
    } catch (e) {
      FlashMessage.errorResponse(e)
    }
  }
}))
