import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {SurveyStepElement, SurveyStepElementOption} from "models"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Button from "components/inputs/button"
import CkeditorPresentation from "ckeditor/presentation"
import ContentPieceTypeLink from "components/content-piece-types/link"
import ContentsPresentation from "components/contents/presentation"
import I18n from "shared/i18n"
import inflection from "inflection"
import Link from "@kaspernj/api-maker/build/link"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import ScoreFactorLink from "components/score-factors/link"
import SurveyStepElementOptionsTable from "components/survey-step-element-options/table"
import SurveyStepLink from "components/survey-steps/link"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedSurveyStepElementAttributes = [
  "content",
  "createdAt",
  "editable",
  "elementType",
  "id",
  "identifier",
  "minChoices",
  "maxChoices",
  "name",
  "parsedContent",
  "position",
  "translatedElementType",
  "translatedState",
  "updatedAt"
]

for (const locale of Locales.availableLocales()) {
  selectedSurveyStepElementAttributes.push(
    `mainContent${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`,
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminSurveyStepElementsShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {surveyStepElement, surveyStepElementId} = useModel(SurveyStepElement, {
      abilities: {
        SurveyStepElement: ["adminPreview", "destroy", "edit"]
      },
      match: {params},
      preload: ["content_model.absolute_containers", "content_piece_type", "score_factor", "step"],
      select: {
        Content: ["id", "parsedContent"],
        ContentAbsoluteContainer: ["id"],
        ContentPieceType: ["id", "name"],
        ScoreFactor: ["id", "name"],
        SurveyStep: ["id", "name"],
        SurveyStepElement: selectedSurveyStepElementAttributes
      }
    })

    this.setInstance({
      canCan: useCanCan(() => [[SurveyStepElementOption, ["create"]]]),
      optionsQuery: useMemo(
        () => SurveyStepElementOption.ransack({
          survey_step_element_id_eq: surveyStepElementId
        }),
        [surveyStepElementId]
      ),
      surveyStepElement,
      surveyStepElementId
    })

    useAdminLayout()?.setState({actions: this.actions(), active: "surveys", headerTitle: surveyStepElement?.name()})
  }

  render() {
    const {optionsQuery, surveyStepElement} = this.tt
    const absoluteContainers = surveyStepElement?.contentModel()?.absoluteContainers()?.loaded()

    return (
      <View dataSet={{route: "admin--survey-step-elements--show"}}>
        {surveyStepElement &&
          <>
            <AttributeRows
              attributes={["id", "identifier", "minChoices", "maxChoices", "position", "editable", "createdAt", "updatedAt"]}
              model={surveyStepElement}
            />
            <AttributeRow attribute="state" model={surveyStepElement}>
              {surveyStepElement.translatedState()}
            </AttributeRow>
            <AttributeRow attribute="elementType" model={surveyStepElement}>
              {surveyStepElement.translatedElementType()}
            </AttributeRow>
            <AttributeRow attribute="contentPieceType" model={surveyStepElement}>
              {surveyStepElement.contentPieceType() &&
                <ContentPieceTypeLink adminLayout contentPieceType={surveyStepElement.contentPieceType()} />
              }
            </AttributeRow>
            <AttributeRow attribute="scoreFactor" model={surveyStepElement}>
              {surveyStepElement.scoreFactor() &&
                <ScoreFactorLink adminLayout scoreFactor={surveyStepElement.scoreFactor()} />
              }
            </AttributeRow>
            <AttributeRow attribute="step" model={surveyStepElement}>
              {surveyStepElement.step() &&
                <SurveyStepLink adminLayout surveyStep={surveyStepElement.step()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${SurveyStepElement.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={surveyStepElement}
                />
                {surveyStepElement.elementType() != "fixed_content" &&
                  <AttributeRow
                    attribute={`mainContent${inflection.camelize(locale)}`}
                    label={`${SurveyStepElement.humanAttributeName("mainContent")} (${Locales.labelForLocale(locale)})`}
                    model={surveyStepElement}
                  >
                    <CkeditorPresentation html={surveyStepElement[`mainContent${inflection.camelize(locale)}`]()} />
                  </AttributeRow>
                }
              </React.Fragment>
            )}
            {surveyStepElement.elementType() == "fixed_content" && surveyStepElement.hasContent() &&
              <AttributeRow
                attribute="content"
                label={I18n.t("js.routes.admin.survey_step_elements.show.content")}
                model={surveyStepElement}
              >
                <CkeditorPresentation html={surveyStepElement.content()} />
              </AttributeRow>
            }
            {surveyStepElement.elementType() == "fixed_content" && (surveyStepElement.hasContent() || absoluteContainers?.length > 0) &&
              <AttributeRow
                attribute="content"
                label={I18n.t("js.routes.admin.survey_step_elements.show.parsed_content")}
                model={surveyStepElement}
              >
                <ContentsPresentation content={surveyStepElement.contentModel()} debug editSurveyStepElements />
              </AttributeRow>
            }
          </>
        }
        {this.hasOptions() &&
          <SurveyStepElementOptionsTable
            adminLayout
            className="mt-4"
            collection={optionsQuery}
            defaultParams={{s: "position asc"}}
          />
        }
      </View>
    )
  }

  actions() {
    const {canCan, surveyStepElement, surveyStepElementId} = this.tt

    return (
      <>
        {this.hasOptions() && canCan?.can("create", SurveyStepElementOption) &&
          <Button
            className="new-option-button"
            icon="plus"
            label={I18n.t("js.routes.admin.survey_step_elements.show.add_new_option")}
            to={Routes.newAdminSurveyStepElementOptionPath({survey_step_element_option: {survey_step_element_id: surveyStepElementId}})}
          />
        }
        {surveyStepElement?.can("adminPreview") &&
          <Link
            className="action-button admin-preview-team-survey-element-button"
            to={Routes.previewAdminSurveyStepElementPath(surveyStepElementId)}
          >
            <i className="fa fa-magnifying-glass mr-1" />
            {I18n.t("js.shared.preview")}
          </Link>
        }
        <AdminShowActions model={surveyStepElement} />
      </>
    )
  }

  hasOptions() {
    const {surveyStepElement} = this.tt

    if (!surveyStepElement) return false

    const elementType = surveyStepElement.elementType()

    if (["image_options", "radio_buttons", "slider"].includes(elementType)) return true

    return false
  }
}))
