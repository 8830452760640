import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import Nav from "components/admin/subscriptions/nav"
import PaymentMethodLink from "components/payment-methods/link"
import PlanLink from "components/plans/link"
import React from "react"
import setLayout from "shared/set-layout"
import {Subscription} from "models"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSubscriptionsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {subscription, subscriptionId} = useModel(Subscription, {
      abilities: {
        Subscription: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["payment_method", "plan"],
      select: {
        PaymentMethod: ["id", "name"],
        Plan: ["id", "name"],
        Subscription: ["createdAt", "id", "price", "resource", "subscriptionPeriodsCount", "translatedState", "updatedAt"]
      }
    })

    this.setInstance({subscription, subscriptionId})
    useAdminLayout()?.setState({actions: this.actions(), active: "subscriptions", headerTitle: Subscription.modelName().human()})
  }

  render() {
    const {subscription} = this.tt

    return (
      <View dataSet={{class: "admin--subscriptions--show"}}>
        {subscription &&
          <>
            <Nav active="general" className="mb-4" subscription={subscription} />
            <AttributeRows attributes={["id", "price", "createdAt", "updatedAt"]} model={subscription} />
            <AttributeRow attribute="state" model={subscription}>
              {subscription.translatedState()}
            </AttributeRow>
            <AttributeRow attribute="plan" model={subscription}>
              {subscription.plan() &&
                <PlanLink adminLayout plan={subscription.plan()} />
              }
            </AttributeRow>
            <AttributeRow attribute="resource" model={subscription}>
              {subscription.resource() &&
                <ModelLink adminLayout model={subscription.resource()} />
              }
            </AttributeRow>
            <AttributeRow attribute="paymentMethod" model={subscription}>
              {subscription.paymentMethod() &&
                <PaymentMethodLink adminLayout paymentMethod={subscription.paymentMethod()} />
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  actions = () => {
    return (
      <AdminShowActions model={this.tt.subscription} />
    )
  }
}))
