import {Image, ImageBackground, StyleSheet, useWindowDimensions} from "react-native"
import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent, useBreakpoint, View} from "shared/base"
import React, {useMemo} from "react"
import background from "./background.png"
import logo from "./logo.png"
import {WithDefaultStyle} from "@kaspernj/api-maker/build/utils/default-style"

const styles = StyleSheet.create({
  defaultText: {
    fontFamily: "HankenGrotesk",
    lineHeight: "170%"
  },
  containerSmall: {
    padding: 10
  },
  containerLarge: {
    padding: 40
  },
  logoSmall: {
    width: 116,
    height: 40,
    marginHorizontal: "auto"
  },
  logoLarge: {
    width: 165,
    height: 57
  }
})

const defaultStyle = {
  Text: [styles.defaultText]
}

export default memo(shapeComponent(class ChalkyLayout extends ShapeComponent {
  static propTypes = propTypesExact({
    children: PropTypes.node,
    dataSet: PropTypes.object
  })

  setup() {
    const {height, width} = useWindowDimensions()

    this.setInstance(useBreakpoint())
    this.setInstance({mdUp: true, height, width})
  }

  render() {
    const {height, mdUp, width} = this.tt
    const {children, dataSet} = this.props
    const actualDataSet = useMemo(
      () => Object.assign(
        {component: "chalky-layout"},
        dataSet
      ),
      [dataSet]
    )
    const imageBackgroundStyle = useMemo(() => ({width: "100%", height: "100%", minWidth: width, minHeight: height}), [width, height])

    return (
      <WithDefaultStyle style={defaultStyle}>
        <ImageBackground
          dataSet={actualDataSet}
          resizeMode="cover"
          source={background}
          style={imageBackgroundStyle}
        >
          <View dataSet={{class: "chalky-layout-container"}} style={mdUp ? styles.containerLarge : styles.containerSmall}>
            <Image
              resizeMode="contain"
              source={logo}
              style={mdUp ? styles.logoLarge : styles.logoSmall}
            />
            {children}
          </View>
        </ImageBackground>
      </WithDefaultStyle>
    )
  }
}))
