import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import {ClassStep} from "models"
import ClassStepLink from "components/class-steps/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default class ComponentsClassSteps extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--class-steps--table", className)}
        columns={columns}
        defaultParams={{s: "primary_school_step"}}
        editModelPath={editModelPath}
        modelClass={ClassStep}
        select={{ClassStep: ["id", "name", "primarySchoolStep"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.classStepContent,
      label: ClassStep.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "primarySchoolStep",
      sortKey: "primarySchoolStep"
    }
  ]

  editModelPath = ({classStep}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminClassStepPath(classStep.id())
  }

  viewModelPath = ({classStep}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminClassStepPath(classStep.id())
  }

  classStepContent = ({classStep}) => <ClassStepLink classStep={classStep} {...linkProps(this.props)} />
}
