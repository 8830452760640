import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import SchoolClassesInviteForm from "components/school-class-roles/invite-form"
import setLayout from "shared/set-layout"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"

export default memo(shapeComponent(class RoutesUserSchoolClassesSchoolClassRolesInvite extends ShapeComponent {
  setup() {
    setLayout("user")

    const {t} = useI18n({namespace: "js.routes.user.school_classes.roles.invite"})
    const params = useParams()
    const {schoolClass} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["invite"]
      },
      match: {params},
      select: {
        SchoolClass: ["id", "interpretedName", "schoolId"]
      }
    })

    this.setInstance({schoolClass, t})
    useFrontLayout()?.setState({headerTitle: t(".invite_to_class")})
  }

  render() {
    const {schoolClass} = this.tt

    return (
      <div className="routes--user--school-classes--roles--invite">
        {schoolClass?.can("invite") &&
          <SchoolClassesInviteForm onInviteSent={this.tt.onInviteSent} schoolClass={schoolClass} />
        }
        {schoolClass && !schoolClass.can("invite") &&
          this.t(".you_dont_have_access_to_inviting")
        }
      </div>
    )
  }

  onInviteSent = () => AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClass.id()))
}))
