import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import SuperAdmin from "@kaspernj/api-maker/build/super-admin"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"

export default memo(shapeComponent(class RoutesSuperAdmin extends ShapeComponent {
  setup() {
    setLayout("admin")
  }

  render() {
    const currentUser = useCurrentUser()

    return (
      <SuperAdmin currentUser={currentUser} />
    )
  }
}))
