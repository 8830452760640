import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Card from "components/card"
import CkeditorPresentation from "ckeditor/presentation"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/plans/nav"
import {Plan} from "models"
import setLayout from "shared/set-layout"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

const selectedPlanAttributes = ["autoRenew", "createdAt", "id", "identifier", "name", "position", "showInSignUpModal", "updatedAt"]
  .concat(translatedAttributes(["description", "name", "subTitle"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminPlansShow extends ShapeComponent {
  setup() {
    setLayout("admin")
  }

  render() {
    const {locale} = useI18n({namespace: "js.routes.admin.plans.show"})
    const {plan, planId} = useModel(Plan, {
      abilities: {
        Plan: ["destroy", "edit"]
      },
      match: this.props.match,
      select: {
        Plan: selectedPlanAttributes
      }
    })

    this.setInstance({plan, planId})

    const actions = useMemo(
      () => <AdminShowActions model={plan} />,
      [locale, plan?.id()]
    )

    return (
      <AdminLayout actions={actions} active="plans" className="routes--admin--plans--show" headerTitle={plan?.name() || Plan.modelName().human()}>
        {plan &&
          <Nav active="general" className="mb-4" plan={plan} />
        }
        <Card className="mb-4" header={Plan.modelName().human()}>
          {plan &&
            <AttributeRows attributes={["id", "identifier", "position", "showInSignUpModal", "autoRenew", "createdAt", "updatedAt"]} model={plan} />
          }
          {plan && Locales.availableLocales().map((locale) =>
            <React.Fragment key={locale}>
              <AttributeRow
                attribute={`name${inflection.camelize(locale)}`}
                label={`${Plan.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={plan}
              />
              <AttributeRow
                attribute={`subTitle${inflection.camelize(locale)}`}
                label={`${Plan.humanAttributeName("subTitle")} (${Locales.labelForLocale(locale)})`}
                model={plan}
              >
                <CkeditorPresentation html={plan[`subTitle${inflection.camelize(locale)}`]()} />
              </AttributeRow>
              <AttributeRow
                attribute={`description${inflection.camelize(locale)}`}
                label={`${Plan.humanAttributeName("description")} (${Locales.labelForLocale(locale)})`}
                model={plan}
              >
                <CkeditorPresentation html={plan[`description${inflection.camelize(locale)}`]()} />
              </AttributeRow>
            </React.Fragment>
          )}
        </Card>
      </AdminLayout>
    )
  }
}))
