import {CheckIn, EvaluationRule} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import {AdminSelectSchoolClasses} from "components/school-classes/select"
import CheckInLink from "components/check-ins/link"
import ContentsErrors from "components/contents/errors"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import React from "react"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminEvaluationRuleTest extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.evaluation_rules.audit_test"})
    const params = useParams()
    const {evaluationRule, evaluationRuleId} = useModel(EvaluationRule, {
      match: {params}
    })
    const queryParams = useQueryParams()

    this.setInstance({evaluationRule, evaluationRuleId, queryParams, t})
    this.useStates({
      result: null,
      schoolClassId: queryParams.school_class_id
    })
    useAdminLayout()?.setState({headerTitle: t(".test_evaluation_rule")})
  }

  render() {
    const {t} = this.tt
    const {result, schoolClassId} = this.s

    return (
      <View dataSet={{route: "admin/evaluation-rules/test"}}>
        <Form onSubmit={this.tt.onSubmit}>
          <View style={{marginBottom: 15}}>
            <AdminSelectSchoolClasses
              id="school_class_id"
              onChange={this.tt.onSchoolClassChanged}
              values={schoolClassId ? [schoolClassId] : []}
            />
          </View>
          <AdminButton dataSet={{class: "submit-test-button"}} disabled={!this.s.schoolClassId} submit title={t("js.shared.submit")} />
        </Form>
        {result &&
          <UtilsCard dataSet={{class: "result-card"}} style={{marginTop: 25}}>
            {result.errors.length > 0 &&
              <View style={{marginBottom: 15}}>
                <ContentsErrors errors={result.errors} />
              </View>
            }
            {result.check_ins.map((checkIn, checkInIndex) =>
              <View
                dataSet={{class: "check-in-container", checkInId: checkIn.id()}}
                key={checkIn.id()}
                style={{flexDirection: "row", alignItems: "center", marginBottom: 15}}
              >
                <Text style={{width: 35}}>
                  {checkInIndex + 1}.
                </Text>
                <CheckInLink adminLayout checkIn={checkIn} />
              </View>
            )}
            {result.check_ins.length == 0 &&
              <Text>{t(".no_check_ins_were_found")}</Text>
            }
          </UtilsCard>
        }
      </View>
    )
  }

  onSchoolClassChanged = ({options}) => {
    const schoolClass = dig(options, 0, "schoolClass")

    this.setState({schoolClassId: schoolClass.id()})
    Params.changeParams({school_class_id: schoolClass.id()})
  }

  onSubmit = async () => {
    const {schoolClassId} = this.s

    if (!schoolClassId) {
      FlashMessage.alert(this.t(".please_choose_a_school_class_before_submitting"))

      return
    }

    try {
      const query = CheckIn
        .select({CheckIn: ["id", "name"]})
        .ransack({s: "current_translation_name"})

      const result = await this.tt.evaluationRule.adminTest({
        query,
        school_class_id: this.s.schoolClassId
      })

      this.setState({result})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
