import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {dig} from "diggerize"
import HayaSelect from "components/woof-haya-select"
import InputContainer from "components/inputs/input-container"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {Select} from "@kaspernj/api-maker/build/inputs/select"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

const ComponentsInputsSelect = memo(shapeComponent(class ComponentsInputsSelect extends ShapeComponent {
  static defaultProps = {
    resize: "vertical"
  }

  static propTypes = {
    resize: PropTypes.string.isRequired
  }

  setup() {
    const {options, type} = this.props
    const {inputProps} = useInput({props: this.props, wrapperOptions: {type: "select"}})

    this.inputProps = inputProps
    this.form = useForm()

    if (type != "hayaSelect") {
      useMemo(() => {
        if (this.form && inputProps.name) {
          if (inputProps.defaultValue) {
            // Default option has been registered - set that in form.
            this.form.setValue(inputProps.name, inputProps.defaultValue)
          } else if (options) {
            // No default value has been given, default to the first option value.
            const firstOptionValue = dig(options, 0, 1)

            this.form.setValue(inputProps.name, firstOptionValue)
          }
        }
      }, [])
    }
  }

  render() {
    const {inputProps, wrapperOpts} = useInput({props: this.props})
    const {className, hint, resize, type, ...restProps} = this.props

    if (type == "hayaSelect") {
      return (
        <HayaSelect
          defaultValue={inputProps.defaultValue}
          id={inputProps.id}
          name={inputProps.name}
          {...restProps}
        />
      )
    }

    return (
      <Select
        className={classNames("components--inputs--select", className)}
        data-invalid={wrapperOpts.errors?.length > 0}
        inputProps={inputProps}
        onChange={this.tt.onChange}
        placeholder={wrapperOpts.placeholder}
        wrapperOpts={wrapperOpts}
        {...restProps}
      />
    )
  }

  onChange = (e, ...restArgs) => {
    const {form, inputProps} = this.tt
    const value = e.target.value

    if (form && inputProps.name) {
      form.setValue(inputProps.name, value)
    }

    if (this.props.onChange) {
      this.props.onChange(e, ...restArgs)
    }
  }
}))

const ComponentsInputsSelectWithContainer = memo((props) => {
  const {inputProps, restProps, wrapperOpts} = useInput({props, wrapperOptions: {type: "select"}})

  return (
    <InputContainer hint={restProps.hint} inputProps={inputProps} wrapperOpts={wrapperOpts}>
      <ComponentsInputsSelect {...props} />
    </InputContainer>
  )
})

export default ComponentsInputsSelectWithContainer
