import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CkeditorPresentation from "ckeditor/presentation"
import ContactLink from "components/contacts/link"
import {Dialogue} from "models"
import memo from "set-state-compare/src/memo"
import React from "react"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"
export default memo(shapeComponent(class RoutesAdminDialoguesShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {dialogue, dialogueId} = useModel(Dialogue, {
      abilities: {
        Dialogue: ["destroy", "edit"]
      },
      match: {params},
      preload: ["contact"],
      select: {
        Contact: ["id", "name"],
        Dialogue: ["body", "contactId", "createdAt", "id", "translatedDialogueType", "updatedAt"]
      }
    })

    this.setInstance({dialogue, dialogueId})

    useAdminLayout().setState({actions: this.actions(), active: "crm", headerTitle: dialogue?.id()})
  }

  render() {
    const {dialogue} = this.tt

    return (
      <View dataSet={{route: "admin/dialogues/show"}}>
        {dialogue &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={dialogue} />
            <AttributeRow attribute="state" model={dialogue}>
              {dialogue.translatedDialogueType()}
            </AttributeRow>
            <AttributeRow attribute="contact" model={dialogue}>
              {dialogue.contact() &&
                <ContactLink adminLayout contact={dialogue.contact()} />
              }
            </AttributeRow>
            <AttributeRow attribute="body" model={dialogue}>
              <CkeditorPresentation html={dialogue.body()} />
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  actions() {
    const {dialogue} = this.tt

    return (
      <AdminShowActions model={dialogue} />
    )
  }
}))
