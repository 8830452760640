import selectComponent from "components/select-component"
import {TableSearch} from "models"
const {withInputContainer: TableSearchesSelectWithContainer, withMemo: ComponentsTableSearchesSelect} = selectComponent({
  className: "components--table-searches--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || TableSearch

    query = query
      .ransack({s: "position asc"})
      .select({TableSearch: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const tableSearches = await query.toArray()

    return tableSearches.map((tableSearch) => ({
      text: tableSearch.name(),
      value: tableSearch.id()
    }))
  }
})

export default ComponentsTableSearchesSelect
export {TableSearchesSelectWithContainer}
