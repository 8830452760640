import {Course, SchoolClass} from "models"
import React, {useCallback, useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BackLink from "components/front-layout/back-link"
import memo from "set-state-compare/src/memo"
import RecommendedCourse from "components/user/school-classes/digital-class-room/recommended"
import Routes from "shared/routes"
import {sortedByFunction} from "sorted-by"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useShape from "set-state-compare/src/use-shape"

export default memo(shapeComponent(class RoutesUserSchoolClassesAvailableCourses extends ShapeComponent {
  render() {
    const s = useShape()
    const currentUser = useCurrentUser()
    const {locale, t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})
    const params = useParams()
    const {schoolClass, schoolClassId} = useModel(
      SchoolClass,
      {
        abilities: {
          SchoolClass: ["invite"]
        },
        match: {params},
        preload: ["class_step"],
        select: {
          ClassStep: ["primarySchoolStep"],
          SchoolClass: ["id", "interpretedName", "schoolClassCheckInsCount", "schoolId"]
        }
      }
    )

    s.updateMeta({schoolClass, schoolClassId})
    s.useStates(["coursesForCourseGroups", "courseGroups"])

    const loadCourses = useCallback(async () => {
      const courses = await Course
        .ransack({
          for_school_class: s.m.schoolClassId,
          public_true: 1,
          s: "current_translation_name"
        })
        .preload("course_groups")
        .select({
          Course: ["backgroundImageUrl", "courseModulesCount", "id", "name"],
          CourseGroup: ["id", "name"]
        })
        .toArray()

      const coursesForCourseGroups = {}
      const courseGroupsMap = {}

      for (const course of courses) {
        for (const courseGroup of course.courseGroups().loaded()) {
          if (!(courseGroup.id() in coursesForCourseGroups)) coursesForCourseGroups[courseGroup.id()] = []
          if (!(courseGroup.id() in courseGroupsMap)) courseGroupsMap[courseGroup.id()] = courseGroup

          coursesForCourseGroups[courseGroup.id()].push(course)
        }
      }

      const courseGroups = Object.values(courseGroupsMap)
      const uniqueSortedCourseGroups = sortedByFunction(courseGroups, "name")

      s.set({
        coursesForCourseGroups,
        courseGroups: uniqueSortedCourseGroups
      })
    }, [])

    useMemo(() => {
      loadCourses()
    }, [currentUser?.id()])

    const topLeft = useMemo(
      () => <>
        <BackLink to={Routes.userSchoolClassDigitalClassRoomPath(s.m.schoolClassId)}>
          {t("js.routes.user.school_classes.insights.back_link")}
        </BackLink>
      </>,
      [locale, schoolClassId]
    )

    const schoolClassName = schoolClass?.interpretedName()

    useFrontLayout()?.setState({
      headTitle: t(".available_courses_for_class", {class_name: schoolClassName || ""}),
      topLeft
    })

    return (
      <div className="routes--user--school-classes--available-courses">
        {s.s.courseGroups?.map((courseGroup) =>
          <div className="course-group-outer" key={courseGroup.id()}>
            <div className="course-group-name-container">
              {courseGroup.name()}
            </div>
            <div style={{display: "flex"}}>
              {schoolClass && s.s.coursesForCourseGroups[courseGroup.id()].map((course) =>
                <div className="course-item" data-course-id={course.id()} key={course.id()}>
                  <RecommendedCourse actionButton course={course} schoolClass={schoolClass} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}))
