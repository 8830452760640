import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent, Text, useStyles, View} from "shared/base"
import {Pressable, StyleSheet} from "react-native"
import Card from "components/chalky-layout/card"
import Icon from "@kaspernj/api-maker/build/utils/icon"
import React from "react"

const styles = StyleSheet.create({
  cardHeader: {},
  cardHeaderSmDown: {
    flexDirection: "row",
    alignItems: "center"
  },

  cardHeaderText: {
    fontSize: 24,
    fontWeight: "bold"
  },
  cardHeaderTextSmDown: {
    marginLeft: 4
  },
  cardHeaderTextMdUp: {
    marginTop: 15
  },

  activeCheckIcon: {color: "#5253ff"},

  activeCheckContainer: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 998,
    right: 24,
    bottom: 24,
    width: 24,
    height: 24,
    backgroundColor: "#fff",
    borderRadius: "50%"
  },
  activeCheckContainerMdUp: {
    width: 40,
    height: 40
  },

  descriptionText: {marginTop: 15}
})

export default memo(shapeComponent(class RoutesUserCreateUserRoleCard extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    onCardPress: PropTypes.func.isRequired
  })

  render() {
    const {active, description, header, icon} = this.p
    const cardHeaderStyles = useStyles(styles, ["cardHeader"])
    const cardHeaderTextStyles = useStyles(styles, ["cardHeaderText"])
    const iconContainerStyles = useStyles(styles, ["activeCheckContainer"])

    return (
      <Pressable onPress={this.tt.onCardPress}>
        <Card active={active}>
          {active &&
            <View style={iconContainerStyles}>
              <Icon name="check" style={styles.activeCheckIcon} />
            </View>
          }
          <View style={cardHeaderStyles}>
            <Icon name={icon} size={32} />
            <Text style={cardHeaderTextStyles}>
              {header}
            </Text>
          </View>
          <Text style={styles.descriptionText}>
            {description}
          </Text>
        </Card>
      </Pressable>
    )
  }

  onCardPress = () => this.p.onCardPress({identifier: this.p.identifier})
}))
