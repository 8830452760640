import {HoverMenu, HoverMenuItem} from "components/hover-menu"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ChangeRoleModal from "components/school-class-roles/change-role-modal"
import memo from "set-state-compare/src/memo"
import React from "react"
import RemoveRoleModal from "components/school-class-roles/remove-role-modal"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserAdminSchoolClassesIndexUserCircleOptionsButton extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.user.admin.school_classes.index.user_circle.options_button"})

    this.setInstance({t})
    this.useStates({showChangeRoleModal: false, showRemoveRoleModal: false})
  }

  render() {
    const {schoolClassRole, style} = this.props
    const actualStyle = Object.assign({position: "relative"}, style)

    return (
      <View
        dataSet={{"class": "components--user--admin-school-classes--index--user-circle--options-button", "school-class-role-id": schoolClassRole.id()}}
        style={actualStyle}
      >
        {this.s.showChangeRoleModal && schoolClassRole.can("edit") &&
          <ChangeRoleModal onRequestClose={this.tt.onRequestCloseChangeRoleModal} schoolClassRole={schoolClassRole} />
        }
        {this.s.showRemoveRoleModal && schoolClassRole.can("destroy") &&
          <RemoveRoleModal onRequestClose={this.tt.onRequestCloseRemoveRoleModal} schoolClassRole={schoolClassRole} />
        }
        <HoverMenu content={this.tt.hoverMenuContent}>
          <View style={{display: "flex", alignItems: "center", justifyContent: "center", width: 25, height: 25}}>
            <i className="fa-solid fa-ellipsis-vertical" style={{fontSize: "20px"}} />
          </View>
        </HoverMenu>
      </View>
    )
  }

  onChangeRolePressed = () => {
    this.setState({showChangeRoleModal: true})
  }

  onRemoveRolePressed = () => {
    this.setState({showRemoveRoleModal: true})
  }

  onRequestCloseChangeRoleModal = () => {
    this.setState({showChangeRoleModal: false})
  }

  onRequestCloseRemoveRoleModal = () => {
    this.setState({showRemoveRoleModal: false})
  }

  hoverMenuContent = () => <>
    {this.p.schoolClassRole.can("edit") &&
      <HoverMenuItem dataSet={{class: "change-role-pressable"}} label={this.t(".change_role")} onPress={this.tt.onChangeRolePressed} />
    }
    {this.p.schoolClassRole.can("destroy") &&
      <HoverMenuItem
        dataSet={{class: "remove-attachment-from-class-pressable"}}
        label={this.t(".remove_attachment_from_class")}
        onPress={this.tt.onRemoveRolePressed}
      />
    }
  </>
}))
