import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SurveyStepElementOption} from "models"
import SurveyStepElementOptionLink from "components/survey-step-element-options/link"
export default memo(shapeComponent(class ComponentsSurveyStepElementOptionsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--survey-step-element-options--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={SurveyStepElementOption}
        select={{SurveyStepElementOption: ["hideLabel", "id", "name"]}}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.nameContent,
      sortKey: "currentTranslationName"
    },
    {
      attribute: "hideLabel",
      sortKey: "hideLabel"
    },
    {
      attribute: "position",
      sortKey: "position"
    }
  ]

  editModelPath = ({surveyStepElementOption}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSurveyStepElementOptionPath(surveyStepElementOption.id())
  }

  nameContent = ({surveyStepElementOption}) => <SurveyStepElementOptionLink surveyStepElementOption={surveyStepElementOption} {...linkProps(this.props)} />
}))
