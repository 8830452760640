import {Image, View} from "react-native"
import {School, SchoolClass, Subscription} from "models"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import ContactMySchool from "./contact-my-school"
import FlashMessage from "shared/flash-message"
import HelloDog from "./hello-dog.png"
import {loading} from "components/layout/loading-blocker"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import OptionBox from "./option-box"
import Overlay from "components/layout/overlay"
import PageContentsPresentation from "components/page-contents/presentation"
import PlansSignUpModal from "components/plans/sign-up-modal"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useHasUnicorn from "components/subscriptions/use-has-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useSideBarMenuOpened from "components/front-layout/use-side-bar-menu-opened"

export default memo(shapeComponent(class ComponentsUnicornUpgradeOverlay extends ShapeComponent {
  static defaultProps = {
    onRequestClose: null,
    school: null,
    schoolClass: null
  }

  static propTypes = propTypesExact({
    onRequestClose: PropTypes.func,
    school: PropTypes.instanceOf(School),
    schoolClass: PropTypes.instanceOf(SchoolClass)
  })

  setup() {
    const {school, schoolClass} = this.props
    const {t} = useI18n({namespace: "js.components.unicorn.upgrade_overlay"})
    const currentUser = useCurrentUser()
    const {expiredTrialSubscription, loaded} = useHasUnicorn({schoolId: school?.id(), schoolClassId: schoolClass?.id(), user: currentUser})

    this.setInstance({
      expiredTrialSubscription,
      loaded,
      sideBarMenuOpened: useSideBarMenuOpened(),
      t
    })
    this.useStates({mode: "initial"})
  }

  render() {
    const {loaded} = this.tt
    const {school, schoolClass} = this.props
    const {mode} = this.s

    if (!loaded) {
      return (
        <Overlay color="light">
          <View style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <LoadingSpinner color="#000" />
          </View>
        </Overlay>
      )
    } else if (mode == "initial") {
      return this.initialContent()
    } else if (mode == "sign-up") {
      return <PlansSignUpModal onRequestClose={this.tt.onPlansSignUpModalRequestClose} school={school} schoolClass={schoolClass} />
    } else if (mode == "contact-my-school") {
      return <ContactMySchool onCloseWindowPressed={this.tt.onContactMySchoolCloseWindowPressed} onRequestClose={this.tt.onContactMySchoolRequestClose} />
    }

    throw new Error(`Unhandled mode: ${mode}`)
  }

  initialContent() {
    const {expiredTrialSubscription, sideBarMenuOpened, t} = this.tt
    const {onRequestClose, school, schoolClass} = this.p

    return (
      <Overlay color="light" dataSet={{component: "user--unicorn--upgrade-overlay"}} onRequestClose={onRequestClose}>
        <Image
          source={HelloDog}
          style={{
            position: "absolute",
            top: -77,
            left: sideBarMenuOpened ? 120 : -121,
            width: 395,
            height: 494
          }}
        />
        <PageContentsPresentation
          identifier="unicorn/upgrade-overlay/main-content"
          liquidVariables={{school, schoolClass}}
        />
        <View style={{flexDirection: "row", marginTop: 50}}>
          {!expiredTrialSubscription &&
            <View style={{marginTop: "auto"}}>
              <OptionBox
                buttonProps={{dataSet: {class: "start-trial-button"}}}
                buttonText={t(".start_trial_period")}
                onButtonPress={this.tt.onStartTrialPressed}
              >
                <PageContentsPresentation
                  identifier="unicorn/upgrade-overlay/start-trial-content"
                  liquidVariables={{school, schoolClass}}
                />
              </OptionBox>
            </View>
          }
          <View style={{marginTop: "auto"}}>
            <OptionBox buttonText={t(".upgrade_now")} onButtonPress={this.tt.onUpgradeNowPressed}>
              <PageContentsPresentation
                identifier="unicorn/upgrade-overlay/upgrade-now-content"
                liquidVariables={{school, schoolClass}}
              />
            </OptionBox>
          </View>
          <View style={{marginTop: "auto"}}>
            <OptionBox
              buttonProps={{dataSet: {class: "contact-my-school-leader-button"}}}
              buttonText={t(".request_school_leader_for_upgrade")}
              onButtonPress={this.tt.onRequestSchoolLeaderForUpgradePressed}
            >
              <PageContentsPresentation
                identifier="unicorn/upgrade-overlay/contact-my-school-leader-content"
                liquidVariables={{school, schoolClass}}
              />
            </OptionBox>
          </View>
        </View>
      </Overlay>
    )
  }

  onContactMySchoolCloseWindowPressed = () => this.setState({mode: "initial"})
  onContactMySchoolRequestClose = () => this.setState({mode: "initial"})
  onPlansSignUpModalRequestClose = () => this.setState({mode: "initial"})
  onRequestSchoolLeaderForUpgradePressed = () => this.setState({mode: "contact-my-school"})

  onStartTrialPressed = async () => {
    try {
      await loading(this.t(".starting_trial"), async () => {
        await Subscription.startUnicornUserTrial()
        FlashMessage.success(this.t(".your_unicorn_trial_has_started"))
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onUpgradeNowPressed = () => this.setState({mode: "sign-up"})
}))
