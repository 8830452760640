import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import SchoolClassesTable from "components/school-classes/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassesIndex extends ShapeComponent {
  setup() {
    const canCan = useCanCan(() => [[SchoolClass, ["create"]]])
    const {locale, t} = useI18n({namespace: "js.routes.admin.school_classes.index"})
    const actions = useMemo(
      () => <>
        {canCan?.can("create", SchoolClass) &&
          <Button
            className="new-school-class-button"
            icon="plus"
            label={t(".add_new_school_class")}
            to={Routes.newAdminSchoolClassPath()}
          />
        }
      </>,
      [canCan, locale]
    )

    this.setInstance({canCan, locale, t})
    useAdminLayout()?.setState({actions, active: "school-classes", headerTitle: SchoolClass.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin--school-classes--index"}}>
        <SchoolClassesTable adminLayout />
      </View>
    )
  }
}))
