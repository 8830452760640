import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {User, UserRole} from "models"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/users/nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UserRolesTable from "components/user-roles/table"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserUserRoles extends ShapeComponent {
  setup() {
    const params = useParams()
    const {user, userId} = useModel(User, {
      match: {params},
      preload: [
        "contact.parent_relationships.parent.school",
        "contact.parent_relationships.parent.school_class",
        "school_classes",
        "schools"
      ],
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        School: ["id"],
        SchoolClass: ["id"],
        User: ["accountUsersCount", "id", "subscriptionsCount", "userRolesCount"]
      }
    })
    const {t} = useI18n({namespace: "js.routes.admin.users.user_roles"})

    this.canCan = useCanCan(() => [[UserRole, ["create"]]])
    this.t = t
    this.user = user
    this.userId = userId
    this.userRolesQuery = useMemo(
      () => UserRole.ransack({
        user_id_eq: userId
      }),
      [userId]
    )

    useAdminLayout()?.setState({
      actions: this.actions(),
      active: "users",
      headerTitle: User.humanAttributeName("userRoles")
    })
  }

  render() {
    const {user} = this.tt

    return (
      <View dataSet={{route: "admin--users--user-roles"}}>
        {user &&
          <Nav active="user-roles" style={{marginBottom: 15}} user={user} />
        }
        <UserRolesTable
          adminLayout
          collection={this.tt.userRolesQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, userId} = this.tt

    return (
      <>
        {canCan?.can("create", UserRole) &&
          <Button
            className="new-user-role-button"
            icon="plus"
            label={this.t(".add_new_user_role")}
            to={Routes.newAdminUserRolePath({user_role: {user_id: userId}})}
          />
        }
      </>
    )
  }
}))
