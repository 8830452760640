import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminSchoolsNav from "components/admin/schools/nav"
import AuditsPage from "components/admin/audits/page"
import memo from "set-state-compare/src/memo"
import React from "react"
import {School} from "models"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolsAudits extends ShapeComponent {
  setup() {
    setLayout("admin")

    const params = useParams()
    const {school} = useModel(School, {match: {params}})

    this.school = school

    useAdminLayout()?.setState({active: "schools", headerTitle: School.humanAttributeName("audits")})
  }

  render() {
    const {school} = this.tt

    return (
      <View dataSet={{route: "admin--schools--audits"}}>
        {school &&
          <>
            <AdminSchoolsNav active="audits" school={school} style={{marginBottom: 15}} />
            <AuditsPage resource={school} />
          </>
        }
      </View>
    )
  }
}))
