import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useInput from "@kaspernj/api-maker/build/use-input"
import {View} from "react-native"
import {withInputContainer} from "components/inputs/input-container"

const ColorText = memo(({color, text}) =>
  <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
    <View style={{width: 21, height: 21, backgroundColor: color, borderRadius: "50%"}} />
    <View style={{marginLeft: 5}}>
      <Text>
        {text}
      </Text>
    </View>
  </View>
)

const ComponentsUsersCircleColorSelectWithMemo = memo(shapeComponent(class ComponentsUsersCircleColorSelect extends ShapeComponent {
  render() {
    const {inputProps, restProps} = useInput({props: this.props})
    const {label, schoolId, ...actualRestProps} = restProps
    const {locale, t} = useI18n({namespace: "js.components.users.circle_color_select"})
    const {ref, type, ...restInputProps} = inputProps

    const colorOptions = useMemo(
      () => [
        {
          content: () => <ColorText color="#ea31e3" text={t(".pink")} />,
          text: t(".pink"),
          value: "#ea31e3"
        },
        {
          content: () => <ColorText color="#ea5231" text={t(".orange")} />,
          text: t(".orange"),
          value: "#ea5231"
        },
        {
          content: () => <ColorText color="#64bbec" text={t(".teal")} />,
          text: t(".teal"),
          value: "#64bbec"
        },
        {
          content: () => <ColorText color="#6a5eec" text={t(".purple")} />,
          text: t(".purple"),
          value: "#6a5eec"
        }
      ],
      [locale]
    )

    return (
      <HayaSelect
        options={colorOptions}
        {...restInputProps}
        {...actualRestProps}
      />
    )
  }
}))

const ComponentsUsersCircleColorSelectWithInputContainer = withInputContainer(ComponentsUsersCircleColorSelectWithMemo)

export {ComponentsUsersCircleColorSelectWithInputContainer as withInputContainer}
export default ComponentsUsersCircleColorSelectWithMemo
