import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Subscription, SubscriptionPeriod} from "models"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/subscriptions/nav"
import Routes from "shared/routes"
import SubscriptionPeriodsTable from "components/subscription-periods/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSubscriptionPeriods extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.subscriptions.subscription_periods"})
    const currentUser = useCurrentUser()
    const canCan = useCanCan(() => [[SubscriptionPeriod, ["create"]]], [currentUser?.id()])
    const params = useParams()
    const {subscription, subscriptionId} = useModel(Subscription, {match: {params}})

    const schoolRolesQuery = useMemo(
      () => SubscriptionPeriod.ransack({
        subscription_id_eq: subscriptionId
      }),
      [subscriptionId]
    )

    this.setInstance({canCan, schoolRolesQuery, subscription, subscriptionId, t})

    const actions = useMemo(
      () => <>
        {this.tt.canCan?.can("create", SubscriptionPeriod) &&
          <Button
            className="new-subscription-period-button"
            icon="plus"
            label={t(".add_new_subscription_period")}
            to={Routes.newAdminSubscriptionPeriodPath({subscription_period: {subscription_id: subscriptionId}})}
          />
        }
      </>,
      [canCan, locale, subscriptionId]
    )

    useAdminLayout()?.setState({actions, active: "subscriptions", headerTitle: Subscription.humanAttributeName("subscriptionPeriods")})
  }

  render() {
    const {schoolRolesQuery, subscription} = this.tt

    return (
      <View dataSet={{class: "routes--admin--subscriptions--periods"}}>
        {subscription &&
          <Nav active="periods" className="mb-4" subscription={subscription} />
        }
        <SubscriptionPeriodsTable
          adminLayout
          collection={schoolRolesQuery}
        />
      </View>
    )
  }
}))
