import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminCheckbox from "components/admin/checkbox"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {SurveyStep} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"
const selectedSurveyStepAttributes = ["copyable", "id", "liquidConditions", "name", "position", "surveyId"]

for (const locale of Locales.availableLocales()) {
  selectedSurveyStepAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSurveyStepsEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.survey_steps.edit"})
    const {surveyStep, surveyStepId} = useModel(SurveyStep, {
      match: this.p.match,
      newIfNoId: true,
      select: {
        SurveyStep: selectedSurveyStepAttributes
      }
    })

    this.setInstance({surveyStep, surveyStepId, t})
    this.useStates({
      form: null
    })

    useAdminLayout()?.setState({
      active: "surveys",
      headerTitle: this.headerTitle()
    })
  }

  render() {
    const {onSubmit, surveyStep} = this.tt

    return (
      <View dataSet={{route: "admin--survey-steps--edit"}}>
        {surveyStep &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <AdminInput
                attribute={`name${inflection.camelize(locale)}`}
                containerProps={{style: {marginBottom: 15}}}
                key={`input-name-${locale}`}
                label={`${SurveyStep.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={surveyStep}
              />
            )}
            <AdminInput attribute="position" containerProps={{style: {marginBottom: 15}}} model={surveyStep} />
            <AdminCheckbox attribute="copyable" containerProps={{style: {marginBottom: 15}}} model={surveyStep} />
            <AdminInput attribute="liquidConditions" autoRows containerProps={{style: {marginBottom: 15}}} model={surveyStep} multiline />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {surveyStep} = this.tt

    if (surveyStep?.isNewRecord()) {
      return this.t(".add_new_step")
    } else if (surveyStep?.isPersisted()) {
      return this.t(".edit_step", {name: surveyStep.name()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {surveyStep} = this.tt

    if (surveyStep.isNewRecord()) {
      formData.survey_step.survey_id = surveyStep.surveyId()
    }

    try {
      await surveyStep.saveRaw(formData)
      FlashMessage.success(this.t(".the_step_was_saved"))
      AppHistory.push(Routes.adminSurveyStepPath(surveyStep.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
