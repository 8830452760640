import {Contact, ContactEmail, ContactPhoneNumber, NotificationSetting, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminCheckbox from "components/admin/checkbox"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"
export default memo(shapeComponent(class RoutesAdminUsersEdit extends ShapeComponent {
  static propTypes = {
    user: PropTypes.instanceOf(User),
    userId: PropTypes.string
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.users.edit"})
    const {user} = useModel(User, {
      match: this.props.match,
      newIfNoId: true,
      preload: ["notification_setting"],
      select: {
        NotificationSetting: ["acceptTerms", "id"],
        User: ["email", "id", "firstName", "lastName", "name", "phoneNumber"]
      }
    })

    this.setInstance({
      notificationSetting: user?.notificationSetting() || new NotificationSetting(),
      t,
      user
    })
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "users", headerTitle: this.headerTitle()})
  }

  render() {
    const {notificationSetting, onSubmit, t, user} = this.tt

    return (
      <View dataSet={{route: "admin--users--edit"}}>
        {user &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <AdminInput
              attribute="firstName"
              containerProps={{style: {marginBottom: 15}}}
              label={Contact.humanAttributeName("firstName")}
              model={user}
            />
            <AdminInput
              attribute="lastName"
              containerProps={{style: {marginBottom: 15}}}
              label={Contact.humanAttributeName("lastName")}
              model={user}
            />
            <AdminInput
              attribute="phoneNumber"
              containerProps={{style: {marginBottom: 15}}}
              label={ContactPhoneNumber.humanAttributeName("phoneNumber")}
              model={user}
            />
            <AdminInput
              attribute="email"
              containerProps={{style: {marginBottom: 15}}}
              label={ContactEmail.humanAttributeName("email")}
              model={user}
            />
            <AdminInput
              attribute="password"
              containerProps={{style: {marginBottom: 15}}}
              defaultValue=""
              label={t("js.shared.password")}
              model={user}
              secureTextEntry={true}
            />
            <AdminInput
              attribute="passwordConfirmation"
              containerProps={{style: {marginBottom: 15}}}
              defaultValue=""
              label={t("js.shared.password_confirmation")}
              model={user}
              secureTextEntry={true}
            />
            <AdminCheckbox
              attribute="acceptTerms"
              containerProps={{style: {marginBottom: 15}}}
              model={notificationSetting}
              name="user[notification_setting_attributes][accept_terms]"
            />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {user} = this.tt

    if (user?.isNewRecord()) {
      return this.t(".add_new_user")
    } else if (user?.isPersisted()) {
      return this.t(".edit_user", {name: user.name()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {notificationSetting, user} = this.tt

    formData.user.skip_reconfirmation = 1

    if (notificationSetting.isPersisted()) {
      formData.user.notification_setting_attributes.id = notificationSetting.id()
    }

    try {
      await user.saveRaw(formData)
      FlashMessage.success(this.t(".the_user_was_saved"))
      AppHistory.push(Routes.adminUserPath(user.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
