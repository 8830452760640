import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import {Audit} from "models"
import AuditLink from "components/audits/link"
import classNames from "classnames"
import inflection from "inflection"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SchoolClassLink from "components/school-classes/link"
import SchoolLink from "components/schools/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"

export default memo(shapeComponent(class ComponentsAudits extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    auditClass: Audit,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    auditClass: PropTypes.func.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.audits.table"})

    this.t = t
  }

  render() {
    const {columns, viewModelPath} = this.tt
    const {adminLayout, auditClass, className, userLayout, ...restProps} = this.props
    const select = {
      AuditAction: ["action"],
      School: ["id", "name"],
      SchoolClass: ["id", "interpretedName"],
      User: ["id", "name"]
    }
    const modelClassName = auditClass.modelClassData().name
    const modelClassSelects = ["id"]

    if (modelClassName == "Audit") {
      modelClassSelects.push("auditableId", "auditableType")
    } else {
      modelClassSelects.push(`${inflection.camelize(auditClass.modelClassData().name.replace(/Audit$/, ""), true)}Id`)
    }

    select[modelClassName] = modelClassSelects

    return (
      <ApiMakerTable
        className={classNames("components--audits--table", className)}
        columns={columns}
        key={auditClass.modelClassData().name}
        modelClass={auditClass}
        preloads={["audit_action", "current_school", "current_school_class", "current_user"]}
        queryName="audits"
        select={select}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      content: this.tt.auditContent,
      sortKey: "id"
    },
    {
      attribute: "auditable",
      content: this.tt.auditableContent
    },
    {
      attribute: "action",
      path: ["auditAction"],
      sortKey: "auditActionAction"
    },
    {
      content: this.tt.currentUserContent,
      label: Audit.humanAttributeName("currentUser"),
      sortKey: "currentUserFirstName"
    },
    {
      content: this.tt.currentSchoolContent,
      label: Audit.humanAttributeName("currentUchool"),
      sortKey: "currentSchoolCurrentTranslationName"
    },
    {
      content: this.tt.currentSchoolClassContent,
      label: Audit.humanAttributeName("currentSchoolClass"),
      sortKey: "currentSchoolClassCurrentTranslationName"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    },
    {
      attribute: "params",
      content: this.tt.paramsContent
    }
  ]

  viewModelPath = ({model: audit}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminAuditPath(audit.id())
  }

  auditableContent = ({model: audit}) => {
    if (!audit.auditable()) {
      let attributeName
      let auditableType
      const auditName = audit.modelClassData().name

      if (auditName == "Audit") {
        auditableType = audit.auditableType()
        attributeName = "auditableId"
      } else {
        auditableType = auditName.replace(/Audit$/, "")
        attributeName = `${inflection.camelize(auditableType, true)}Id`
      }

      if (!(attributeName in audit)) {
        throw new Error(`No such method on ${auditName}: ${attributeName}`)
      }

      return this.t(".no_model_for_model_type", {model_id: audit[attributeName](), model_type: auditableType})
    }

    return <ModelLink model={audit.auditable()} {...linkProps(this.props)} />
  }

  auditContent = ({model: audit}) => <AuditLink audit={audit} {...linkProps(this.props)} />
  paramsContent = ({model: audit}) => <pre>{JSON.stringify(audit.params(), null, 2)}</pre>
  currentSchoolContent = ({model: audit}) => audit.currentSchool() && <SchoolLink school={audit.currentSchool()} {...linkProps(this.props)} />
  currentSchoolClassContent = ({model: audit}) => audit.currentSchoolClass() &&
    <SchoolClassLink schoolClass={audit.currentSchoolClass()} {...linkProps(this.props)} />

  currentUserContent = ({model: audit}) => audit.currentUser() && <UserLink user={audit.currentUser()} {...linkProps(this.props)} />
}))
