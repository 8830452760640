import {Locale, Translation} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import TranslationLink from "components/translations/link"
export default memo(shapeComponent(class ComponentsTranslationsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--translations--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={Translation}
        select={{Translation: ["id"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      label: Locale.modelName().human(),
      path: ["localeModel"],
      sortKey: "localeModelCurrentTranslationName"
    },
    {
      attribute: "key",
      sortKey: "key"
    },
    {
      attribute: "value",
      sortKey: "value"
    },
    {
      attribute: "approved",
      sortKey: "approved"
    },
    {
      attribute: "madeByAi",
      sortKey: "madeByAi"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  editModelPath = ({translation}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminTranslationPath(translation.id())
  }

  viewModelPath = ({translation}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminTranslationPath(translation.id())
  }

  translationContent = ({translation}) => <TranslationLink translation={translation} {...linkProps(this.props)} />
}))
