import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UsersForgotPassword from "components/users/forgot-password"

export default memo(shapeComponent(class RoutesFrontUsersForgotPassword extends ShapeComponent {
  setup() {
    setLayout("front")

    const {t} = useI18n({namespace: "js.routes.front.users.forgot_password"})

    useFrontLayout()?.setState({
      headTitle: t(".reset_password")
    })
  }

  render() {
    return (
      <div className="routes--front--users--forgot-password">
        <UsersForgotPassword />
      </div>
    )
  }
}))
