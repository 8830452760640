import React, {useMemo} from "react"
import {School, SchoolClass} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {ClassStepsSelectWithContainer} from "components/class-steps/select"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SchoolsSelectWithContainer} from "components/schools/select"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"

export default memo(shapeComponent(class RoutesUserSchoolClassesEdit extends ShapeComponent {
  setup() {
    this.currentUser = useCurrentUser()

    const {locale, t} = useI18n({namespace: "js.routes.user.school_classes.edit"})
    const params = useParams()
    const {schoolClass} = useModel(SchoolClass, {
      cacheArgs: [this.currentUser?.id()],
      match: {params},
      newIfNoId: true,
      select: {
        SchoolClass: ["classStepId", "id", "interpretedName", "name", "schoolId"]
      }
    })

    this.schoolClass = schoolClass
    this.t = t

    this.useStates({
      schools: undefined,
      schoolsLoaded: false
    })

    useMemo(() => {
      this.loadPage()
    }, [this.currentUser?.id(), locale])
  }

  loadPage() {
    this.setState({
      schools: undefined,
      schoolsLoaded: false
    })

    if (this.currentUser) {
      this.loadSchools()
    }
  }

  async loadSchools() {
    const schools = await School
      .ransack({
        s: "current_translation_name",
        with_contact_id: this.currentUser.contactId()
      })
      .select({School: ["id"]})
      .groupBy("id")
      .toArray()

    this.setState({schools, schoolsLoaded: true})
  }

  render() {
    const {schools, schoolsLoaded} = this.s
    const {onSubmit, schoolClass} = this.tt

    return (
      <div className="routes--user--school-classes--edit">
        {schoolClass &&
          <form onSubmit={onSubmit}>
            {schoolClass.isNewRecord() && schools?.length > 0 &&
              <input name="school_class[school_id]" type="hidden" value={schools[0]?.id()} />
            }
            <MainCard header={this.headerTitle()}>
              <Input
                attribute="name"
                label={this.t(".school_class_nickname")}
                model={schoolClass}
                placeholder={this.t(".give_the_class_a_name")}
              />
              {schoolsLoaded && schools?.length <= 0 &&
                <SchoolsSelectWithContainer
                  attribute="schoolId"
                  defaultValue={schoolClass?.schoolId() || schools[0]?.id()}
                  label={SchoolClass.humanAttributeName("school")}
                  model={schoolClass}
                />
              }
              <ClassStepsSelectWithContainer attribute="classStepId" label={SchoolClass.humanAttributeName("classStep")} model={schoolClass} />
              <Button primary save style={{width: "100%"}} />
            </MainCard>
          </form>
        }
      </div>
    )
  }

  headerTitle() {
    if (this.schoolClass?.isNewRecord()) return this.t(".add_new_school_class")
    if (this.schoolClass?.isPersisted()) return this.t(".edit_school_class")
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {schoolClass} = this.tt
    const isNewRecord = schoolClass.isNewRecord()

    try {
      if (isNewRecord) {
        const response = await SchoolClass.createNew(form)
        const createdSchoolClass = digg(response, "school_class")

        AppHistory.push(Routes.userRootPath(createdSchoolClass.id()))
      } else {
        await schoolClass.saveRaw(form)
        AppHistory.push(Routes.userRootPath(schoolClass.id()))
      }

      FlashMessage.success(this.t(".the_school_class_was_saved"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
