import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent, Text, View} from "shared/base"
import React, {useMemo} from "react"
import Icon from "@kaspernj/api-maker/build/utils/icon"
import {StyleSheet} from "react-native"

const styles = StyleSheet.create({
  icon: {
    color: "#fff"
  },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    backgroundColor: "#5253ff",
    borderRadius: "50%"
  },
  root: {
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    marginLeft: 4,
    fontSize: 10
  }
})

export default memo(shapeComponent(class ActiveStep extends ShapeComponent {
  static propTypes = propTypesExact({
    label: PropTypes.string.isRequired
  })

  render() {
    const {label} = this.p
    const dataSet = useMemo(() => ({component: "routes/user/create-user/active-step"}), [])

    return (
      <View dataSet={dataSet} style={styles.root}>
        <View style={styles.iconContainer}>
          <Icon name="chevron-right" style={styles.icon} />
        </View>
        <Text style={styles.text}>
          {label}
        </Text>
      </View>
    )
  }
}))
