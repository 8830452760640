import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import I18n from "shared/i18n"
import PageComponent from "components/page-component"
import React from "react"
import {User} from "models"
export default class RoutesAdminErrorTesting extends PageComponent {
  render() {
    return (
      <AdminLayout className="routes--admin--error-testing" headerTitle={I18n.t("js.routes.admin.error_testing.error_testing")}>
        <Button
          label={I18n.t("js.routes.admin.error_testing.javascript_error_test")}
          onClick={this.t.onJavascriptErrorTestClicked}
        />
        <Button
          className="ml-3"
          label={I18n.t("js.routes.admin.error_testing.rails_error_test")}
          onClick={this.t.onRailsErrorTestClicked}
        />
      </AdminLayout>
    )
  }

  onJavascriptErrorTestClicked = (e) => {
    e.preventDefault()

    throw new Error("Javascript error test")
  }

  onRailsErrorTestClicked = async (e) => {
    e.preventDefault()

    await User.errorTest()
  }
}
