import React, {useMemo} from "react"
import {SchoolClass, Subscription} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Heading from "components/utils/heading"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PageContentsPresentation from "components/page-contents/presentation"
import PlansSignUpModal from "components/plans/sign-up-modal"
import Routes from "shared/routes"
import SchoolClassesAdminCard from "components/school-classes/admin-card"
import {Text} from "shared/base"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import useCreatedEvent from "@kaspernj/api-maker/build/use-created-event"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"
import WrappedList from "components/utils/wrapped-list"

export default memo(shapeComponent(class RoutesUserAdminSchoolClasses extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.user.admin.school_classes"})

    this.currentUser = useCurrentUser()
    this.useStates({
      lastUpdate: new Date(),
      showSignUpModal: undefined
    })

    const canCan = useCanCan(() => [[SchoolClass, ["new"]]], [this.currentUser?.id()])

    const {schoolClasses} = useCollection(
      {
        modelClass: SchoolClass,
        preloads: ["school.account", "school.country", "teachers"],
        ransack: {
          school_contact_children_relationships_child_id_eq: this.currentUser?.contactId() || "no-user-id",
          school_contact_children_relationships_contact_relationship_type_identifier_eq: "school_leader"
        },
        select: {
          Account: ["id"],
          Country: ["countryCode"],
          School: ["name"],
          SchoolClass: ["hasUnicornSubscription", "id", "interpretedName"],
          User: ["id", "name"]
        }
      },
      [this.currentUser?.id(), this.s.lastUpdate]
    )

    useCreatedEvent(Subscription, this.tt.onSubscriptionCreated)

    this.setInstance({canCan, locale, schoolClasses, t})

    const actions = useMemo(
      () => <>
        {this.canCan?.can("new", SchoolClass) &&
          <Link className="action-button new-school-class-button" to={Routes.newUserAdminSchoolClassPath()}>
            {t(".add_new_school_class")}
          </Link>
        }
      </>,
      [this.canCan, this.locale, this.currentUser?.id()]
    )

    useFrontLayout()?.setState({actions, active: "user-admin-school-classes", headerTitle: SchoolClass.modelName().human({count: 2})})
  }

  render() {
    const {t} = this.tt
    const schoolClassesWithoutUnicorn = this.schoolClasses?.filter((schoolClass) => !schoolClass.hasUnicornSubscription())
    const schoolClassesWithUnicorn = this.schoolClasses?.filter((schoolClass) => schoolClass.hasUnicornSubscription())

    return (
      <div className="routes--user--admin--school-classes--index">
        {this.s.showSignUpModal &&
          <PlansSignUpModal onRequestClose={this.tt.onRequestClosePlansSignUpModal} schoolClass={this.s.showSignUpModal} />
        }
        {schoolClassesWithoutUnicorn?.length > 0 &&
          <>
            <PageContentsPresentation
              defaultContent={
                <Heading description={t(".with_limit_access_description")}>
                  {t(".limited_access")}
                </Heading>
              }
              identifier="user/admin/school-classes/index/limited-access"
            />
            <WrappedList dataSet={{class: "school-classes-with-limited-access-container"}}>
              {schoolClassesWithoutUnicorn?.map((schoolClass) =>
                <View key={schoolClass.id()}>
                  <SchoolClassesAdminCard
                    hasUnicorn={false}
                    onUpgradeSchoolClassPressed={this.tt.onUpgradeSchoolClassPressed}
                    schoolClass={schoolClass}
                    style={{marginRight: 15, marginBottom: 15}}
                  />
                </View>
              )}
            </WrappedList>
            {schoolClassesWithoutUnicorn?.length === 0 &&
              <PageContentsPresentation
                defaultContent={<Text>{t(".no_school_classes_without_unicorn_was_found")}</Text>}
                identifier="user/admin/school-classes/index/no-school-classes-without-unicorn-was-found"
              />
            }
          </>
        }
        <PageContentsPresentation
          defaultContent={
            <Heading description={t(".with_unicorn_access_description")} style={{marginTop: "50px"}}>
              {t(".unicorn_access")}
            </Heading>
          }
          identifier="user/admin/school-classes/index/unicorn-access"
        />
        <WrappedList dataSet={{class: "unicorn-school-classes-container"}}>
          {schoolClassesWithUnicorn?.map((schoolClass) =>
            <View key={schoolClass.id()} style={{marginRight: 15, marginBottom: 15}}>
              <SchoolClassesAdminCard
                hasUnicorn={true}
                onUpgradeSchoolClassPressed={this.tt.onUpgradeSchoolClassPressed}
                schoolClass={schoolClass}
              />
            </View>
          )}
        </WrappedList>
        {schoolClassesWithUnicorn?.length === 0 &&
          <PageContentsPresentation
            defaultContent={<Text>{t(".no_school_classes_with_unicorn_was_found")}</Text>}
            identifier="user/admin/school-classes/index/no-school-classes-with-unicorn-was-found"
          />
        }
      </div>
    )
  }

  onUpgradeSchoolClassPressed = ({schoolClass}) => this.setState({showSignUpModal: schoolClass})
  onRequestClosePlansSignUpModal = () => this.setState({showSignUpModal: undefined})

  // Reloads school classes when a matching subscription is created, so that the school class is moved from limit-access to Unicorn container
  onSubscriptionCreated = ({model: subscription}) => {
    const schoolClassWithSubscription = this.schoolClasses?.find((schoolClass) =>
      (subscription.resourceType() == "SchoolClass" && schoolClass.id() == subscription.resourceId()) ||
        (subscription.resourceType() == "School" && schoolClass.schoolId() == subscription.resourceId())
    )

    if (schoolClassWithSubscription || this.currentUser?.id() == subscription.resourceId()) {
      this.setState({lastUpdate: new Date()})
    }
  }
}))
