import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
export default memo(shapeComponent(class RoutesUserSchoolClassesEditName extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.edit"})
    const params = useParams()
    const {schoolClass} = useModel(SchoolClass, {
      match: {params},
      select: {
        SchoolClass: ["id", "name"]
      }
    })

    this.setInstance({schoolClass, t})
    useFrontLayout()?.setState({headerTitle: this.headerTitle()})
  }

  render() {
    const {onSubmit, schoolClass, t} = this.tt

    return (
      <div className="routes--user--school-classes--edit-name">
        {schoolClass &&
          <form onSubmit={onSubmit}>
            <MainCard header={this.headerTitle()}>
              <Input
                attribute="name"
                label={t(".school_class_nickname")}
                model={schoolClass}
                placeholder={t(".give_the_class_a_name")}
              />
              <Button primary save style={{width: "100%"}} />
            </MainCard>
          </form>
        }
      </div>
    )
  }

  headerTitle() {
    if (this.tt.schoolClass?.isPersisted()) return this.t(".choose_nickname")
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {schoolClass, t} = this.tt

    try {
      await schoolClass.saveRaw(form)
      AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(schoolClass.id()))
      FlashMessage.success(t(".the_school_class_was_saved"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
