import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import inflection from "inflection"
import Locales from "shared/locales"
import React from "react"
import {SchoolClass} from "models"
export default {
  show: {
    extraContent: ({schoolClass}) => (
      <>
        {Locales.availableLocales().map((locale) =>
          <AttributeRow key={locale} label={`${SchoolClass.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}>
            {schoolClass[`name${inflection.camelize(locale)}`]()}
          </AttributeRow>
        )}
      </>
    ),
    extraSelect: {
      SchoolClass: Locales.availableLocales().map((locale) => `name${inflection.camelize(locale)}`)
    }
  },
  table: {
    columns: () => [
      {
        attribute: "name",
        sortKey: "currentTranslationName"
      },
      {
        attribute: "schoolClassCheckInsCount",
        sortKey: "schoolClassCheckInsCount"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      },
      {
        attribute: "updatedAt",
        sortKey: "updatedAt"
      }
    ]
  }
}
