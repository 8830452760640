import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import instanceOfClassName from "@kaspernj/api-maker/build/instance-of-class-name"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useShape from "set-state-compare/src/use-shape"
const content = (s) => s.props.children || s.p.subscriptionPeriod.name() || s.p.subscriptionPeriod.id()

const getPath = (s) => {
  if (s.p.adminLayout) {
    return Routes.adminSubscriptionPeriodPath(s.p.subscriptionPeriod.id())
  } else if (s.p.superAdminLayout) {
    return Routes.superAdminPath({model: "SubscriptionPeriod", model_id: s.p.subscriptionPeriod.id()})
  } else if (s.p.userLayout) {
    return Routes.userSubscriptionPeriodPath(s.p.subscriptionPeriod.id())
  }
}

export default memo(shapeComponent(class ComponentsSubscriptionPeriodsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    superAdminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    subscriptionPeriod: instanceOfClassName("SubscriptionPeriod").isRequired,
    superAdminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const s = useShape(this.props)
    const {adminLayout, children, className, subscriptionPeriod, superAdminLayout, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--subscription-periods--link", className)
    const to = getPath(s)

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {content(s)}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {content(s)}
      </span>
    )
  }
}))
