import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import {digg, digs} from "diggerize"
import React, {useEffect} from "react"
import {SchoolClass, ScoreFactorGroup} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import I18n from "shared/i18n"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

const CustomTooltip = memo(shapeComponent(class CustomTooltip extends ShapeComponent {
  render() {
    const {active, payload} = this.props

    if (!active || !payload[1]) return null

    return (
      <div style={{backgroundColor: "#3B97FF", borderRadius: "49px", color: "#fff", padding: "29px 48px", textAlign: "center"}}>
        <div style={{fontSize: "18px", fontWeight: "bold"}}>
          {payload[1].name}
        </div>
        <div style={{fontSize: "28px", fontWeight: "bold"}}>
          {payload[1].value.toLocaleString(I18n.locale, {maximumFractionDigits: 1, minimumFractionDigits: 1})}
        </div>
      </div>
    )
  }
}))

export default memo(shapeComponent(class ComponentsScoreFactorGroupsSimpleLineChart extends ShapeComponent {
  static propTypes = propTypesExact({
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired,
    moodScoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup),
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  })

  setup() {
    const {dateFrom, dateTo, schoolClass} = this.p
    const {t} = useI18n({namespace: "js.components.score_factor_groups.mood_simple_line_chart"})
    const {scoreFactorGroup: moodScoreFactorGroup} = useModel(ScoreFactorGroup, {
      query: ScoreFactorGroup.ransack({identifier_eq: "mood"}),
      select: {
        ScoreFactorGroup: ["id", "name"]
      }
    })

    this.moodScoreFactorGroup = this.props.moodScoreFactorGroup || moodScoreFactorGroup
    this.t = t

    this.useStates({
      data: undefined,
      loading: true
    })

    useEffect(() => {
      if (this.tt.moodScoreFactorGroup) {
        this.loadData()
      }
    }, [dateFrom, dateTo, this.tt.moodScoreFactorGroup?.id(), schoolClass?.id()])
  }

  async loadData() {
    const {moodScoreFactorGroup} = this.tt
    const {dateFrom, dateTo, schoolClass} = this.p
    const result = await moodScoreFactorGroup.averageOverDays({
      date_from: dateFrom,
      date_to: dateTo,
      school_class_id: schoolClass.id()
    })
    const averageScoreOverDays = digg(result, "average_score_over_days")
    const moodLabel = moodScoreFactorGroup.name()
    const tendencyLabel = this.t(".tendency")
    const data = []
    let currentDate = moment(dateFrom)

    while (currentDate.toDate() <= dateTo) {
      const currentDateWithYear = currentDate.format("YYYY-MM-DD")
      const foundAverageData = averageScoreOverDays.find((dateData) => moment(dateData.date).format("YYYY-MM-DD") == currentDateWithYear)
      const dayName = moment(currentDate).format("dddd")
      const xLabel = `${dayName.substring(0, 1).toUpperCase()}${dayName.substring(1, 2)}`
      const dateChartData = {name: xLabel}

      if (foundAverageData) {
        const {average, tendency} = digs(foundAverageData, "average", "tendency")

        dateChartData[moodLabel] = average
        dateChartData[tendencyLabel] = tendency
      }

      data.push(dateChartData)

      currentDate = currentDate.add(1, "day")
    }

    this.setState({data, loading: false})
  }

  render() {
    const {moodScoreFactorGroup} = this.tt
    const {className} = this.props
    const {data, loading} = this.s
    const tendencyLabel = this.t(".tendency")

    return (
      <Card className={classNames("components--score-factor-groups--mood-simple-line-chart", className)}>
        <div style={{height: "300px"}}>
          {loading &&
            <LoadingSpinner />
          }
          {data &&
            <ResponsiveContainer height="100%" width="100%">
              <LineChart
                data={data}
                height={300}
                margin={{
                  bottom: 5,
                  left: 20,
                  right: 30,
                  top: 5
                }}
                width={500}
              >
                <CartesianGrid horizontal={false} strokeDasharray="1 0" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 5]} tick={false} ticks={[1, 2, 3, 4, 5]} type="number" />
                <Tooltip content={CustomTooltip} wrapperStyle={{outline: "none"}} />
                <Line activeDot={{stroke: "#205a96"}} connectNulls dataKey={tendencyLabel} dot={false} stroke="#4c93ff" strokeWidth={5} type="monotone" />
                <Line
                  activeDot={{r: 8, stroke: "#205a96"}}
                  dataKey={moodScoreFactorGroup.name()}
                  dot={{stroke: "#4c93ff", strokeWidth: 8}}
                  stroke="none"
                  type="monotone"
                />
              </LineChart>
            </ResponsiveContainer>
          }
        </div>
      </Card>
    )
  }
}))
