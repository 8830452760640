import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import {ContactPhoneNumber} from "models"
import {ContactsSelectWithContainer} from "components/contacts/select"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UtilsButton from "components/utils/button"
import UtilsCheckbox from "components/utils/checkbox"
import UtilsInput from "components/utils/input"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactPhoneNumbersEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.contact_phone_numbers.edit"})
    const params = useParams()
    const {contactPhoneNumber, contactPhoneNumberId} = useModel(ContactPhoneNumber, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        ContactPhoneNumber: ["contactId", "phoneNumber", "id"]
      }
    })

    this.setInstance({
      contactPhoneNumber,
      contactPhoneNumberId,
      t
    })
    this.useStates({
      contactId: contactPhoneNumber?.contactId(),
      phoneNumber: contactPhoneNumber?.phoneNumber() || "",
      form: null,
      primary: contactPhoneNumber?.primary() || false
    })

    useMemo(() => {
      this.setState({
        contactId: contactPhoneNumber?.contactId(),
        phoneNumber: contactPhoneNumber?.phoneNumber() || "",
        primary: contactPhoneNumber?.primary() || false
      })
    }, [contactPhoneNumber?.id()])

    useAdminLayout()?.setState({active: "contacts", headerTitle: this.headerTitle()})
  }

  render() {
    const {contactPhoneNumber, onSubmit, t} = this.tt

    return (
      <View dataSet={{route: "admin--contact-phone-numbers--edit"}}>
        {contactPhoneNumber &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <ContactsSelectWithContainer
                attribute="contactId"
                id="contact_phone_number_contact_id"
                label={ContactPhoneNumber.humanAttributeName("contact")}
                name="contact_phone_number[contact_id]"
                onChange={this.tt.onChangeContact}
                values={this.s.contactId ? [this.s.contactId] : null}
              />
            </View>
            <UtilsInput
              dataSet={{class: "contact-phone-number-phone-number-input"}}
              label={ContactPhoneNumber.humanAttributeName("phoneNumber")}
              name="contact_phone_number[phone_number]"
              onChangeText={this.setStates.phoneNumber}
              style={{marginBottom: 15}}
              value={this.s.phoneNumber}
            />
            <UtilsCheckbox
              checked={this.s.primary}
              dataSet={{class: "contact-phone-number-primary"}}
              label={ContactPhoneNumber.humanAttributeName("primary")}
              name="contact_phone_number[primary]"
              onChangeChecked={this.setStates.primary}
              style={{marginBottom: 15}}
            />
            <UtilsButton dataSet={{class: "save-phone-number-button"}} primary submit title={t("js.shared.save")} />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {contactPhoneNumber} = this.tt

    if (contactPhoneNumber?.isNewRecord()) {
      return this.t(".add_new_contact_phone_number")
    } else if (contactPhoneNumber?.isPersisted()) {
      return this.t(".edit_contact_phone_number")
    }
  }

  onChangeContact = ({options}) => this.setState({contactId: dig(options, 0, "value")})

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {contactPhoneNumber} = this.tt

    if (contactPhoneNumber.isNewRecord()) {
      formData.contact_phone_number.new_id = contactPhoneNumber.id()
    }

    try {
      await contactPhoneNumber.saveRaw(formData)
      FlashMessage.success(this.t(".the_contact_phone_number_was_saved"))
      AppHistory.push(Routes.adminContactPhoneNumbersPath(contactPhoneNumber.contactId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
