import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountLink from "components/accounts/link"
import {AccountSchool} from "models"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import React from "react"
import SchoolLink from "components/schools/link"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
export default memo(shapeComponent(class RoutesAdminAccountSchoolsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.account_schools.show"})
    const {accountSchool, accountSchoolId} = useModel(AccountSchool, {
      abilities: {
        AccountSchool: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["account", "school"],
      select: {
        Account: ["id", "name"],
        AccountSchool: ["createdAt", "id", "updatedAt"],
        School: ["id", "name"]
      }
    })

    this.setInstance({accountSchool, accountSchoolId, t})
  }

  render() {
    const {accountSchool} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="account-schools"
        className="routes--admin--account-schools--show"
        headerTitle={AccountSchool.modelName().human()}
      >
        {accountSchool &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={accountSchool} />
            <AttributeRow attribute="account" model={accountSchool}>
              {accountSchool.account() &&
                <AccountLink account={accountSchool.account()} adminLayout />
              }
            </AttributeRow>
            <AttributeRow attribute="school" model={accountSchool}>
              {accountSchool.school() &&
                <SchoolLink adminLayout school={accountSchool.school()} />
              }
            </AttributeRow>
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {accountSchool} = this.tt

    return (
      <AdminShowActions model={accountSchool} />
    )
  }
}))
