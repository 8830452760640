import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectContactTypes} from "components/contact-types/select"
import {AdminSelectCountries} from "components/countries/select"
import {AdminSelectUsers} from "components/users/select"
import AppHistory from "shared/history"
import {Contact} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import presence from "shared/presence"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.contacts.edit"})
    const {contact, contactId} = useModel(Contact, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["user"],
      select: {
        Contact: ["accountManagerId", "contactTypeId", "countryId", "firstName", "id", "lastName", "name", "phoneNumber"]
      }
    })

    this.setInstance({contact, contactId, t})
    this.useStates({
      accountManagerId: null,
      contactTypeId: null,
      countryId: null,
      firstName: "",
      form: null,
      lastName: "",
      name: ""
    })

    useMemo(() => {
      if (contact) {
        this.setState({
          accountManagerId: contact.accountManagerId(),
          contactTypeId: contact.contactTypeId(),
          countryId: contact.countryId(),
          firstName: contact.firstName() || "",
          lastName: contact.lastName() || "",
          name: contact.name() || ""
        })
      }
    }, [contact?.id()])

    useAdminLayout()?.setState({active: "contacts", headerTitle: this.headerTitle()})
  }

  render() {
    const {contact} = this.tt
    const {accountManagerId, contactTypeId, countryId} = this.s

    return (
      <View dataSet={{route: "admin/contacts/edit"}}>
        {contact &&
          <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            <AdminInput
              dataSet={{class: "contact-name-input"}}
              label={Contact.humanAttributeName("name")}
              model={contact}
              name="contact[name]"
              onChangeText={this.setStates.name}
              style={{marginBottom: 10}}
              value={this.s.name}
            />
            <AdminInput
              dataSet={{class: "contact-first-name-input"}}
              label={Contact.humanAttributeName("firstName")}
              model={contact}
              name="contact[first_name]"
              onChangeText={this.setStates.firstName}
              style={{marginBottom: 10}}
              value={this.s.firstName}
            />
            <AdminInput
              dataSet={{class: "contact-last-name-input"}}
              label={Contact.humanAttributeName("lastName")}
              model={contact}
              name="contact[last_name]"
              onChangeText={this.setStates.lastName}
              style={{marginBottom: 10}}
              value={this.s.lastName}
            />
            <View style={{marginTop: 10, marginBottom: 20}}>
              <AdminSelectContactTypes
                attribute="contactTypeId"
                id="contact_contact_type_id"
                label={Contact.humanAttributeName("contactType")}
                name="contact[contact_type_id]"
                values={contactTypeId ? [contactTypeId] : null}
              />
            </View>
            <View style={{marginVertical: 10}}>
              <AdminSelectUsers
                attribute="accountManagerId"
                id="contact_account_manager_id"
                label={Contact.humanAttributeName("accountManager")}
                name="contact[account_manager_id]"
                values={accountManagerId ? [accountManagerId] : null}
              />
            </View>
            <View style={{marginVertical: 10}}>
              <AdminSelectCountries
                attribute="countryId"
                id="country_id"
                label={Contact.humanAttributeName("country")}
                name="contact[country_id]"
                values={countryId ? [countryId] : null}
              />
            </View>
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {contact, t} = this.tt

    if (contact?.isNewRecord()) {
      return t(".add_new_contact")
    } else if (contact?.isPersisted()) {
      return t(".edit_contact", {name: presence(contact.name()) || `${contact.firstName()} ${contact.lastName()}`})
    }
  }

  onSubmit = async () => {
    const {contact, t} = this.tt
    const formData = this.s.form.asObject()

    if (contact.isNewRecord()) {
      formData.contact.new_id = contact.id()
    }

    try {
      await contact.saveRaw(formData)
      FlashMessage.success(t(".the_contact_was_saved"))
      AppHistory.push(Routes.adminContactPath(contact.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
