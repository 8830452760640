import Devise from "@kaspernj/api-maker/build/devise"
import I18n from "shared/i18n"
import {loading} from "components/layout/loading-blocker"
import {School} from "models"
export default async function componentsSchoolsCurrentSchools() {
  const contactId = Devise.currentUser()?.contactId()
  const loadingLabel = I18n.t("js.components.schools.current_schools.current_schools")
  let schools

  await loading(loadingLabel, async () => {
    schools = await School
      .ransack({
        s: "current_translation_name",
        with_contact_id: contactId
      })
      .select({School: ["hasUnicornSubscription", "id"]})
      .groupBy("id")
      .toArray()
  })

  return schools
}
