import React, {useMemo} from "react"
import {School, SchoolClass} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Card from "components/utils/card"
import {ClassStepsSelectWithContainer} from "components/class-steps/select"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SchoolsSelectWithContainer} from "components/schools/select"
import TeachersSelect from "components/user/admin/school-classes/edit/teachers-select"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserAdminSchoolClassesEdit extends ShapeComponent {
  setup() {
    const currentUser = useCurrentUser()
    const params = useParams()
    const {t} = useI18n({namespace: "js.routes.user.admin.school_classes.edit"})
    const {schoolClass} = useModel(SchoolClass, {
      match: {params},
      newIfNoId: {
        defaults: () => ({new_id: uuidv4()})
      }
    })
    const {schools} = useCollection({
      modelClass: School,
      ransack: {
        s: "current_translation_name asc",
        contact_children_relationships_child_id_eq: currentUser?.contactId() || "no-user-uuid",
        contact_children_relationships_contact_relationship_type_identifier_eq: "school_leader"
      },
      select: {
        School: ["contactId", "id", "name"]
      }
    })
    const schoolsCacheKey = schools?.map((school) => school.id())?.join("-") || "no-schools"
    const schoolsOptions = useMemo(
      () => schools?.map((school) => ({school, value: school.id(), text: school.name()})),
      [schoolsCacheKey]
    )
    const defaultSchool = useMemo(() => {
      if (schools?.length === 1) {
        return schools[0]
      }
    }, [schoolsCacheKey])

    this.setInstance({defaultSchool, schoolClass, schoolsOptions, t})
    this.useStates({
      classStep: null,
      createNewTeacher: null,
      schoolClassName: null,
      school: null,
      teacherId: null,
      teacherEmail: "",
      teacherFirstName: "",
      teacherLastName: ""
    })

    useMemo(() => {
      this.setState({
        classStep: schoolClass?.classStepId(),
        schoolClassName: schoolClass?.name() || "",
        school: this.s.school || defaultSchool
      })
    }, [schoolClass?.id(), schoolsCacheKey])

    useFrontLayout().setState({active: "user-admin-school-classes", contentCentered: true})
  }

  render() {
    const {defaultSchool, schoolClass, schoolsOptions, t} = this.tt

    return (
      <View dataSet={{route: "user--admin--school-classes--edit"}}>
        {schoolClass && schoolsOptions &&
          <Card style={{maxWidth: 490}}>
            <UtilsInput
              dataSet={{class: "class-name-input"}}
              label={t(".class_name")}
              onChangeText={digg(this, "setStates", "schoolClassName")}
              style={{marginBottom: 10}}
              value={this.s.schoolClassName}
            />
            {schoolsOptions &&
              <SchoolsSelectWithContainer
                defaultValue={defaultSchool?.id()}
                id="school_id"
                label={School.modelName().human()}
                onChange={this.tt.onSchoolChanged}
                options={schoolsOptions}
              />
            }
            <ClassStepsSelectWithContainer
              defaultValue={this.s.classStep}
              id="class_step_id"
              label={t(".class_step")}
              onChange={this.tt.onChangeClassStep}
            />
            {this.s.school &&
              <TeachersSelect
                id="teacher_id"
                label={t(".attached_teachers")}
                onChange={this.tt.onTeacherChanged}
                school={this.s.school}
              />
            }
            {this.s.createNewTeacher &&
              <>
                <UtilsInput
                  dataSet={{class: "teacher-first-name-input"}}
                  label={t(".first_name_of_teacher")}
                  onChangeText={digg(this, "setStates", "teacherFirstName")}
                  style={{marginBottom: 10}}
                  value={this.s.teacherFirstName}
                />
                <UtilsInput
                  dataSet={{class: "teacher-last-name-input"}}
                  label={t(".last_name_of_teacher")}
                  onChangeText={digg(this, "setStates", "teacherLastName")}
                  style={{marginBottom: 10}}
                  value={this.s.teacherLastName}
                />
                <UtilsInput
                  dataSet={{class: "teacher-email-input"}}
                  label={t(".email_of_teacher")}
                  onChangeText={digg(this, "setStates", "teacherEmail")}
                  style={{marginBottom: 10}}
                  value={this.s.teacherEmail}
                />
              </>
            }
            <UtilsButton
              dataSet={{class: "save-school-class-button"}}
              onPress={this.tt.onSaveClassPressed}
              style={{marginTop: 10}}
              title={schoolClass.isNewRecord() ? t(".create_class") : t(".update_class")}
            />
          </Card>
        }
      </View>
    )
  }

  onChangeClassStep = ({options}) => this.setState({classStep: options[0]?.value})
  onSchoolChanged = ({options}) => this.setState({school: options[0]?.school})

  onTeacherChanged = ({options}) => {
    const option = options[0]

    if (option?.optionType == "teacher") {
      this.setState({
        createNewTeacher: false,
        teacherId: option.value
      })
    } else if (option?.optionType == "create-new-teacher") {
      this.setState({
        createNewTeacher: true,
        teacherId: null
      })
    }
  }

  onSaveClassPressed = async () => {
    try {
      const isNewSchoolClass = this.tt.schoolClass.isNewRecord()
      const saveData = {
        school_class: {
          name: this.s.schoolClassName,
          class_step_id: this.s.classStep,
          school_id: this.s.school.id()
        }
      }

      if (this.s.teacherId) {
        saveData.school_class_role = {
          role: "teacher",
          user_id: this.s.teacherId
        }
      }

      if (this.s.createNewTeacher) {
        const password = uuidv4()

        saveData.school_class_role = {
          role: "teacher",
          user_attributes: {
            email: this.s.teacherEmail,
            first_name: this.s.teacherFirstName,
            last_name: this.s.teacherLastName,
            password,
            password_confirmation: password
          }
        }
      }

      await SchoolClass.schoolLeaderCreate(saveData)
      FlashMessage.success(isNewSchoolClass ? this.t(".the_school_class_was_created") : this.t(".the_school_class_was_updated"))
      AppHistory.push(Routes.userAdminSchoolClassesPath())
    } catch (e) {
      FlashMessage.errorResponse(e)
    }
  }
}))
