import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useWoofRouter} from "shared/woof-router"
export default memo(shapeComponent(class RoutesAdminUserRolesRoute extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="user-roles">
        <Route component="edit" exact path="new" />
        <Route exact path=":user_role_id/edit" />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))
