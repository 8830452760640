import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassSubscriptions extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.school_classes.unicorn_effects"})
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {match: this.p.match})

    this.setInstance({schoolClass, schoolClassId, t})
    this.useStates({unicornEffects: undefined})

    useMemo(() => {
      if (schoolClass) {
        this.loadUnicornEffects()
      } else {
        this.setState({unicornEffects: undefined})
      }
    }, [schoolClass?.id()])

    useAdminLayout()?.setState({active: "school-classes", headerTitle: t(".unicorn_effects")})
  }

  async loadUnicornEffects() {
    const result = await this.tt.schoolClass.unicornEffects()
    const sequences = digg(result, "sequences")

    this.setState({unicornEffects: JSON.stringify(sequences, this.tt.jsonReplacer, 2)})
  }

  render() {
    const {schoolClass} = this.tt
    const {unicornEffects} = this.s

    return (
      <View dataSet={{route: "admin--school-classes--unicorn-effects"}}>
        {schoolClass &&
          <Nav active="unicorn-effects" schoolClass={schoolClass} style={{marginBottom: 15}} />
        }
        {unicornEffects &&
          <pre className="unicorn-effects-content">
            {unicornEffects}
          </pre>
        }
      </View>
    )
  }

  jsonReplacer = (_key, value) => {
    if (value instanceof SchoolClassCheckIn) {
      return `${I18n.strftime("%Y-%m-%d", value.createdAt())} - ${value.id()}`
    }

    return value
  }
}))
