import React, {useCallback} from "react"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import {sortedByFunction} from "sorted-by"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useInput from "@kaspernj/api-maker/build/use-input"
import {User} from "models"
import {withInputContainer} from "components/inputs/input-container"
const ComponentsUserAdminSchoolClassesEditTeachersSelect = (props) => {
  const {inputProps, restProps} = useInput({props})
  const {label, school, ...actualRestProps} = restProps
  const {t} = useI18n({namespace: "js.components.user.admin.school_classes.edit.teachers_select"})
  const optionsCallback = useCallback(async () => {
    let teachers = await User
      .ransack({associated_with_school_ids: school.id()})
      .select({User: ["id", "name"]})
      .groupBy("id")
      .toArray()

    teachers = sortedByFunction(teachers, "name")

    const options = []

    options.push({
      optionType: "create-new-teacher",
      text: t(".create_new_teacher"),
      value: "create-new-teacher"
    })

    options.push({
      key: "teachers-group",
      text: t(".teachers"),
      type: "group"
    })

    for (const teacher of teachers) {
      options.push({
        optionType: "teacher",
        text: teacher.name(),
        value: teacher.id()
      })
    }

    return options
  }, [])

  return (
    <HayaSelect
      options={optionsCallback}
      {...inputProps}
      {...actualRestProps}
    />
  )
}

export default withInputContainer(memo(ComponentsUserAdminSchoolClassesEditTeachersSelect))
