import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Survey} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsSurveysCloneButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    onCloned: PropTypes.func,
    survey: PropTypes.instanceOf(Survey).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.surveys.clone_button"})

    this.t = t
    this.useStates({
      disabled: false
    })
  }

  render() {
    const {className, onCloned, survey, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--surveys--clone-button", "action-button", className)}
        disabled={this.s.disabled}
        icon="clone"
        label={this.t(".clone_survey")}
        onClick={this.tt.onCloneButtonClicked}
        {...restProps}
      />
    )
  }

  onCloneButtonClicked = async (e) => {
    e.preventDefault()

    const {survey} = this.p

    try {
      this.setState({disabled: true})

      await loading(this.t(".cloning_survey"), async () => {
        const result = await survey.clone()
        const clonedSurvey = digg(result, "cloned_survey")

        FlashMessage.success(this.t(".the_survey_was_cloned"))
        if (this.props.onCloned) this.props.onCloned({clonedSurvey})
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
