import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class RoutesAdminDashboard extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.dashboard"})

    useAdminLayout()?.setState({
      headerTitle: t(".dashboard")
    })
  }

  render() {
    return (
      <div className="routes--admin--dashboard">
        stub
      </div>
    )
  }
}))
