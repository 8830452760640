import {Contact, ContactEmail, Locale, NotificationSetting, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Card from "components/utils/card"
import changeLocale from "components/users/change-locale"
import {withInputContainer as CircleColorSelect} from "components/users/circle-color-select"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import {LocalesSelectWithContainer} from "components/locales/select"
import memo from "set-state-compare/src/memo"
import RadioButton from "components/utils/radio-button"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import usersUpdateSession from "components/users/update-session"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserProfile extends ShapeComponent {
  setup() {
    setLayout("user")

    const {t} = useI18n({namespace: "js.routes.user.profile"})
    const currentUser = useCurrentUser()

    this.setInstance({
      currentUser,
      localesQuery: useMemo(() => Locale.ransack().select({Locale: ["locale"]}), [])
    })
    this.useStates({
      acceptMarketing: true,
      activityInClass: true,
      color: undefined,
      email: "",
      firstName: "",
      getStarted: true,
      initials: "",
      lastName: "",
      locale: undefined,
      localeId: undefined,
      notificationSetting: undefined,
      notificationSettingLoaded: false,
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
      user: undefined,
      weeklyReportForYourClasses: true
    })

    useMemo(() => {
      this.loadUser()
    }, [currentUser?.id()])

    this.setInstance({t})
  }

  loadUser = async () => {
    const user = await User
      .ransack({id_eq: this.tt.currentUser?.id() || "no-user-uuid"})
      .preload(["locale_model", "notification_setting"])
      .first()

    const notificationSetting = user.notificationSetting() || new NotificationSetting()

    this.setState({
      acceptMarketing: notificationSetting?.acceptMarketing() || false,
      activityInClass: notificationSetting?.activityInClass() || false,
      color: user?.color(),
      currentPassword: "",
      email: user?.hasUnconfirmedEmail() ? user?.unconfirmedEmail() : user?.email(),
      firstName: user?.firstName() || "",
      getStarted: notificationSetting?.getStarted() || false,
      initials: user?.initials() || "",
      lastName: user?.lastName() || "",
      locale: user?.localeModel()?.locale() || I18n.locale,
      localeId: user?.localeModel()?.id(),
      notificationSetting,
      notificationSettingLoaded: true,
      password: "",
      passwordConfirmation: "",
      user,
      weeklyReportForYourClasses: notificationSetting?.weeklyReportForYourClasses() || false
    })
  }

  render() {
    const {t} = this.tt
    const {user} = this.s

    return (
      <div data-component="routes/user/profile">
        {user &&
          <View style={{width: "100%", maxWidth: 700}}>
            <Card header={t(".user_information")} style={{marginBottom: 10}}>
              <UtilsInput
                dataSet={{class: "email-input"}}
                label={ContactEmail.humanAttributeName("email")}
                onChangeText={this.setStates.email}
                style={{marginBottom: 10}}
                value={this.s.email}
              />
              <UtilsInput
                dataSet={{class: "first-name-input"}}
                label={Contact.humanAttributeName("firstName")}
                onChangeText={this.setStates.firstName}
                style={{marginBottom: 10}}
                value={this.s.firstName}
              />
              <UtilsInput
                dataSet={{class: "last-name-input"}}
                label={Contact.humanAttributeName("lastName")}
                onChangeText={this.setStates.lastName}
                style={{marginBottom: 10}}
                value={this.s.lastName}
              />
              <View style={{marginBottom: 15}}>
                <UtilsInput
                  dataSet={{class: "initials-input"}}
                  label={User.humanAttributeName("initials")}
                  onChangeText={this.setStates.initials}
                  value={this.s.initials}
                />
              </View>
              <View>
                <CircleColorSelect
                  defaultValue={this.s.color}
                  id="color"
                  label={User.humanAttributeName("color")}
                  onChange={this.tt.onChangeColor}
                />
              </View>
              <View style={{marginTop: 20}}>
                <LocalesSelectWithContainer
                  id="user_locale"
                  label={User.humanAttributeName("locale")}
                  onChange={this.tt.onLocaleChanged}
                  query={this.tt.localesQuery}
                  values={this.s.localeId ? [this.s.localeId] : undefined}
                />
              </View>
            </Card>
            <Card header={t(".new_password")} style={{marginBottom: 15}}>
              <UtilsInput
                dataSet={{class: "current-password-input"}}
                label={t(".current_password")}
                onChangeText={this.setStates.currentPassword}
                secureTextEntry={true}
                style={{marginBottom: 10}}
                value={this.s.currentPassword}
              />
              <UtilsInput
                dataSet={{class: "password-input"}}
                label={t(".password")}
                onChangeText={this.setStates.password}
                secureTextEntry={true}
                style={{marginBottom: 10}}
                value={this.s.password}
              />
              <UtilsInput
                dataSet={{class: "password-confirmation-input"}}
                label={t(".confirm_password")}
                onChangeText={this.setStates.passwordConfirmation}
                secureTextEntry={true}
                style={{marginBottom: 10}}
                value={this.s.passwordConfirmation}
              />
            </Card>
            <Card header={t(".notifications")} style={{marginBottom: 15}}>
              {this.s.notificationSettingLoaded &&
                <>
                  <RadioButton
                    checked={this.s.acceptMarketing}
                    dataSet={{class: "accept-marketing-radio-button"}}
                    label={NotificationSetting.humanAttributeName("acceptMarketing")}
                    onChangeChecked={this.setStates.acceptMarketing}
                    style={{marginVertical: 2}}
                  />
                  <RadioButton
                    checked={this.s.activityInClass}
                    dataSet={{class: "activity-in-class-radio-button"}}
                    label={NotificationSetting.humanAttributeName("activityInClass")}
                    onChangeChecked={this.setStates.activityInClass}
                    style={{marginVertical: 2}}
                  />
                  <RadioButton
                    checked={this.s.getStarted}
                    dataSet={{class: "get-started-radio-button"}}
                    label={NotificationSetting.humanAttributeName("getStarted")}
                    onChangeChecked={this.setStates.getStarted}
                    style={{marginVertical: 2}}
                  />
                  <RadioButton
                    checked={this.s.weeklyReportForYourClasses}
                    dataSet={{class: "weekly-report-for-your-classes-radio-button"}}
                    label={NotificationSetting.humanAttributeName("weeklyReportForYourClasses")}
                    onChangeChecked={this.setStates.weeklyReportForYourClasses}
                    style={{marginVertical: 2}}
                  />
                </>
              }
            </Card>
            <UtilsButton dataSet={{class: "save-profile-button"}} onPress={this.tt.onSavePressed} title={t("js.shared.save")} />
            <UtilsButton
              color="red"
              dataSet={{class: "delete-profile-button"}}
              onPress={this.tt.onDeletePressed}
              style={{marginTop: 10}}
              title={t(".delete_profile")}
            />
          </View>
        }
      </div>
    )
  }

  onChangeColor = ({options}) => this.setState({color: options[0]?.value})

  onDeletePressed = async () => {
    const {currentUser, t} = this.tt

    if (!confirm(t("js.shared.are_you_sure"))) return

    try {
      await currentUser.deleteProfile()
      await usersUpdateSession()
      FlashMessage.success(t(".your_profile_was_deleted"))
      AppHistory.push(Routes.rootPath())
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onLocaleChanged = ({options}) =>
    this.setState({
      locale: options[0]?.locale?.locale(),
      localeId: options[0]?.value
    })

  onSavePressed = async () => {
    const {currentUser, t} = this.tt
    const {locale} = this.s
    const userAttributes = {
      color: this.s.color,
      first_name: this.s.firstName,
      initials: this.s.initials,
      last_name: this.s.lastName,
      locale,
      current_password: this.s.currentPassword,
      password: this.s.password,
      password_confirmation: this.s.passwordConfirmation,
      notification_setting_attributes: {
        accept_marketing: this.s.acceptMarketing,
        activity_in_class: this.s.activityInClass,
        get_started: this.s.getStarted,
        id: this.s.notificationSetting.id(),
        weekly_report_for_your_classes: this.s.weeklyReportForYourClasses
      }
    }
    const email = this.s.email.toLowerCase().trim()

    if (email != currentUser.email() && email != currentUser.unconfirmedEmail()) {
      userAttributes.email_request = email
    }

    try {
      await User.profileSave({user: userAttributes})

      if (locale != I18n.locale) {
        await changeLocale(locale)
      }

      FlashMessage.success(t(".your_profile_was_saved"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
