import {ContactRelationship, School, SchoolClass, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SchoolClassLink from "components/school-classes/link"
import SchoolLink from "components/schools/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"
export default memo(shapeComponent(class ComponentsContactRelationshipsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.school_classes.roles_table"})

    this.t = t
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--contact-relationships--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={ContactRelationship}
        preloads={["child.user", "parent.school_class.school", "contact_relationship_type"]}
        select={{
          Contact: ["firstName", "id", "lastName"],
          ContactRelationship: ["id"],
          ContactRelationshipType: ["id", "translatedIdentifier"],
          School: ["id", "name"],
          SchoolClass: ["id", "interpretedName"],
          User: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.userContent,
      identifier: "user",
      label: User.modelName().human(),
      sortKey: "childName"
    },
    {
      content: this.tt.schoolClassContent,
      identifier: "school-class",
      label: SchoolClass.modelName().human(),
      sortKey: "parentCurrentTranslationName"
    },
    {
      content: this.tt.schoolContent,
      identifier: "school",
      label: School.modelName().human(),
      sortKey: "parentSchoolClassSchoolContactCurrentTranslationName"
    },
    {
      content: this.tt.roleContent,
      label: this.t(".role"),
      sortKey: "contactRelationshipTypeIdentifier"
    }
  ]

  editModelPath = ({contactRelationship}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminContactRelationshipPath(contactRelationship.id())
  }

  roleContent = ({contactRelationship}) => contactRelationship.contactRelationshipType()?.translatedIdentifier()

  schoolContent = ({contactRelationship}) => contactRelationship.parent()?.schoolClass()?.school() &&
    <SchoolLink school={contactRelationship.parent().schoolClass().school()} {...linkProps(this.props)} />

  schoolClassContent = ({contactRelationship}) => contactRelationship.parent()?.schoolClass() &&
    <SchoolClassLink schoolClass={contactRelationship.parent().schoolClass()} {...linkProps(this.props)} />

  userContent = ({contactRelationship}) => contactRelationship.child()?.user() &&
    <UserLink user={contactRelationship.child().user()} {...linkProps(this.props)} />

  viewModelPath = ({contactRelationship}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminContactRelationshipPath(contactRelationship.id())
  }
}))
