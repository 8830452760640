import {Course, SchoolClass, SchoolClassCourse} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Card from "components/card"
import classNames from "classnames"
import DesignCardImage from "./design-card.png"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserSchoolClassesDigitalClassRoomRecommended extends ShapeComponent {
  static propTypes = {
    actionButton: PropTypes.bool,
    className: PropTypes.string,
    course: PropTypes.instanceOf(Course),
    schoolClass: PropTypes.instanceOf(SchoolClass),
    schoolClassCourse: PropTypes.instanceOf(SchoolClassCourse)
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.user.school_classes.digital_class_room.recommended"})

    this.setInstance({t})
    this.useStates({
      schoolClassCourses: undefined
    })

    useMemo(() => {
      if (this.props.course && this.props.schoolClassCourse === undefined && this.props.schoolClass) this.loadSchoolClassCourses()
    }, [this.props.course?.id(), this.props.schoolClassCourse?.id(), this.props.schoolClass?.id()])
  }

  loadSchoolClassCourses = async () => {
    const schoolClassCourses = await SchoolClassCourse
      .ransack({
        course_id_eq: this.p.course.id(),
        school_class_id_eq: this.p.schoolClass.id()
      })
      .preload(["course", "school_class_course_modules.course_module"])
      .select({
        Course: ["backgroundImageUrl", "courseModulesCount", "id", "name"],
        CourseModule: ["position"],
        SchoolClassCourse: ["id", "state"],
        SchoolClassCourseModule: ["state"]
      })
      .toArray()

    this.setState({schoolClassCourses})
  }

  render() {
    const {actionButton, background, className, course, schoolClass, schoolClassCourse, ...restProps} = this.props

    return (
      <div
        className={classNames("components--user--school-classes--digital-class-room--recommended", className)}
        data-course-id={course?.id()}
        data-school-class-course-id={schoolClassCourse?.id()}
        {...restProps}
      >
        {this.withOrWithoutLink()}
      </div>
    )
  }

  isStarted = () => {
    if (this.props.schoolClassCourse) return this.p.schoolClassCourse.state() == "started"

    const ongoingSchoolClassCourse = this.s.schoolClassCourses?.find((schoolClassCourse) => schoolClassCourse.state() == "started")

    return Boolean(ongoingSchoolClassCourse)
  }

  getSchoolClassCourse = () => {
    const ongoing = this.s.schoolClassCourses?.find((schoolClassCourse) => ["new", "started"].includes(schoolClassCourse.state()))

    if (ongoing) return ongoing

    const completed = this.s.schoolClassCourses?.find((schoolClassCourse) => schoolClassCourse.state() == "completed")

    if (completed) return completed

    if (this.s.schoolClassCourses?.length > 0) return this.s.schoolClassCourses[0]
  }

  circleCheckMark = (moduleNumber) => {
    const schoolClassCourse = this.getSchoolClassCourse()
    const schoolClassCourseModule = schoolClassCourse
      ?.schoolClassCourseModules()
      ?.loaded()
      ?.sort((scCourseModule1, scCourseModule2) => scCourseModule2.courseModule().position() - scCourseModule1.courseModule().position())
      ?.find((_scCourseModule, index) => index == moduleNumber)

    if (schoolClassCourseModule?.state() == "completed") {
      return (
        <div className="circle-element-check-mark">
          <i className="fa fa-check" />
        </div>
      )
    }
  }

  footer = () => {
    return (
      <div className="recommended-course-footer">
        <Button className="start-course-button w-100" primary small style={{fontWeight: "bold"}}>
          {(() => {
            if (this.isStarted()) {
              return this.t(".continue_course")
            } else {
              return this.t(".start_course")
            }
          })()}
        </Button>
      </div>
    )
  }

  card = () => {
    const schoolClassCourse = this.getSchoolClassCourse()
    const background = this.p.course?.backgroundImageUrl() || DesignCardImage
    const numberOfModules = this.p.course ? this.p.course.courseModulesCount() : 4
    const moduleNumbers = []

    for (let i = 0; i < numberOfModules; i++) {
      moduleNumbers.push(i)
    }

    return (
      <Card
        childrenBottomRounded={false}
        childrenProps={{style: {padding: "9px"}}}
        footer={this.props.actionButton && this.footer()}
        footerBackground="#fff"
        footerPadding="27px 36px"
      >
        <div className="content-container" style={{backgroundImage: `url('${background}')`}}>
          {(() => {
            if (this.isStarted()) {
              return (
                <div className="ongoing-label">
                  {this.t(".ongoing")}
                </div>
              )
            } else if (schoolClassCourse?.state() == "completed") {
              return (
                <div className="completed-label">
                  {this.t(".completed")}
                </div>
              )
            }
          })()}
          <div className="recommended-header">
            {this.t(".recommended")}
            <i className="fa fa-thumbs-up" />
          </div>
          <div className="process-about">
            {this.t(".process_about")}
          </div>
          <div className="subject-container">
            {this.props.course && this.p.course.name()}
            {!this.props.course && this.t(".friends")}
          </div>
          <div className="circles-container">
            {moduleNumbers.map((moduleNumber) =>
              <div className="circle-element" data-module-number={moduleNumber} key={`module-number-${moduleNumber}`}>
                {this.circleCheckMark(moduleNumber)}
              </div>
            )}
          </div>
        </div>
      </Card>
    )
  }

  withOrWithoutLink = () => {
    if (this.props.schoolClassCourse) {
      return (
        <Link
          className="take-course-link"
          to={Routes.userCoursePath(this.p.schoolClassCourse.courseId(), {school_class_course_id: this.p.schoolClassCourse.id()})}
        >
          {this.card()}
        </Link>
      )
    } else if (this.props.course) {
      return (
        <a className="take-course-link" href="#" onClick={this.tt.onTakeCourseClicked}>
          {this.card()}
        </a>
      )
    }

    return this.card()
  }

  onTakeCourseClicked = () => AppHistory.push(Routes.userCoursePath(this.p.course.id(), {school_class_id: this.p.schoolClass.id()}))
}))
