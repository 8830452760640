import {ContactRelationshipType} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: ContactRelationshipTypesSelectWithContainer, withMemo: ComponentsContactRelationshipTypesSelect} = selectComponent({
  className: "components--contact-relationship-types--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ContactRelationshipType

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({ContactRelationshipType: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const contactRelationshipTypes = await query.toArray()

    return contactRelationshipTypes.map((contactRelationshipType) => ({
      text: contactRelationshipType.name(),
      value: contactRelationshipType.id()
    }))
  }
})

export default ComponentsContactRelationshipTypesSelect
export {ContactRelationshipTypesSelectWithContainer}
