import {ClassStep, Course} from "models"
import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminCheckbox from "components/admin/checkbox"
import AdminInput from "components/admin/input"
import {AdminSelectScoreFactors} from "components/score-factors/select"
import AppHistory from "shared/history"
import Attachment from "components/inputs/attachment"
import Checkboxes from "components/inputs/checkboxes"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import FormDataObjectizer from "form-data-objectizer"
import {incorporate} from "incorporator"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedCourseAttributes = ["backgroundImageContentType", "backgroundImageUrl", "id", "name", "public", "scoreFactorId", "valuePoints"]
  .concat(translatedAttributes(["description", "name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminCoursesEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.courses.edit"})
    const params = useParams()
    const {course, courseId} = useModel(Course, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["course_class_steps"],
      select: {
        Course: selectedCourseAttributes,
        CourseClassStep: ["classStepId"]
      }
    })

    this.setInstance({
      course,
      courseId,
      formRef: useRef(),
      t
    })
    this.useStates({classStepsCollection: undefined, form: undefined})

    useMemo(() => {
      this.loadClassSteps()
    }, [])
    useAdminLayout()?.setState({active: "courses", headerTitle: this.headerTitle()})
  }

  async loadClassSteps() {
    const classSteps = await ClassStep
      .ransack({s: "position"})
      .select({ClassStep: ["id", "name"]})
      .toArray()

    this.setState({
      classStepsCollection: classSteps.map((classStep) => [classStep.name(), classStep.id()])
    })
  }

  render() {
    const {course, onSubmit, t} = this.tt
    const {classStepsCollection} = this.s

    return (
      <View dataSet={{route: "admin--courses--edit"}}>
        {course &&
          <Form formRef={this.tt.formRef} onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <View dataSet={{class: "localized-inputs", locale}} key={`input-name-${locale}`}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  containerProps={{style: {marginBottom: 15}}}
                  label={`${Course.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={course}
                />
                <Input
                  attribute={`description${inflection.camelize(locale)}`}
                  label={`${Course.humanAttributeName("description")} (${Locales.labelForLocale(locale)})`}
                  model={course}
                  type="ckeditor"
                />
              </View>
            )}
            <View style={{marginBottom: 15}}>
              <AdminSelectScoreFactors attribute="scoreFactorId" label={Course.humanAttributeName("scoreFactor")} model={course} />
            </View>
            <AdminInput attribute="valuePoints" containerProps={{style: {marginBottom: 15}}} model={course} />
            {classStepsCollection &&
              <Checkboxes
                defaultValue={course.isPersisted() ? course.courseClassSteps().map((courseClassStep) => courseClassStep.classStepId()) : null}
                label={ClassStep.modelName().human({count: 2})}
                name="course[class_step_ids]"
                options={classStepsCollection}
              />
            }
            <Attachment attribute="backgroundImage" defaultValue={null} label={t("js.shared.background_image")} model={course} />
            <AdminCheckbox attribute="public" model={course} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {course} = this.tt

    if (course?.isNewRecord()) {
      return this.t(".add_new_course")
    } else if (course?.isPersisted()) {
      return this.t(".edit_course", {name: course.name()})
    }
  }

  onSubmit = async () => {
    const {course, t} = this.tt
    const formData = new FormData(this.tt.formRef.current)
    const data = incorporate({}, this.s.form.asObject(), FormDataObjectizer.toObject(formData))

    if (course.isNewRecord()) {
      data.course.new_id = course.id()
    }

    try {
      await course.saveRaw(data)
      FlashMessage.success(t(".the_course_was_saved"))
      AppHistory.push(Routes.adminCoursePath(course.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
