import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountLink from "components/accounts/link"
import {AccountUser} from "models"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import UserLink from "components/users/link"
export default memo(shapeComponent(class RoutesAdminAccountUsersShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {accountUser, accountUserId} = useModel(AccountUser, {
      abilities: {
        AccountUser: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["account", "user"],
      select: {
        Account: ["id", "name"],
        AccountUser: ["createdAt", "id", "translatedRole", "updatedAt"],
        User: ["id", "name"]
      }
    })

    this.setInstance({accountUser, accountUserId})
  }

  render() {
    const {accountUser} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="account-users"
        className="routes--admin--account-users--show"
        headerTitle={AccountUser.modelName().human()}
      >
        {accountUser &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={accountUser} />
            <AttributeRow attribute="role" model={accountUser}>
              {accountUser.translatedRole()}
            </AttributeRow>
            <AttributeRow attribute="account" model={accountUser}>
              {accountUser.account() &&
                <AccountLink account={accountUser.account()} adminLayout />
              }
            </AttributeRow>
            <AttributeRow attribute="user" model={accountUser}>
              {accountUser.user() &&
                <UserLink adminLayout user={accountUser.user()} />
              }
            </AttributeRow>
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {accountUser} = this.tt

    return (
      <AdminShowActions model={accountUser} />
    )
  }
}))
