import {ClassStep} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectClassSteps,
  withInputContainer: ClassStepsSelectWithContainer,
  withMemo: ComponentsClassStepsSelect
} = selectComponent({
  className: "components--class-steps--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ClassStep

    query = query
      .ransack({s: "position asc"})
      .select({ClassStep: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const classSteps = await query.toArray()

    return classSteps.map((classStep) => ({
      text: classStep.name(),
      value: classStep.id()
    }))
  }
})

export default ComponentsClassStepsSelect
export {AdminSelectClassSteps, ClassStepsSelectWithContainer}
