import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {CourseGroup} from "models"
import CourseGroupsTable from "components/course-groups/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCourseGroupsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.course_groups.index"})

    this.canCan = useCanCan(() => [[CourseGroup, ["create"]]])
    this.t = t

    useAdminLayout()?.setState({actions: this.actions(), active: "course-groups", headerTitle: CourseGroup.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin-course-groups--index"}}>
        <CourseGroupsTable adminLayout />
      </View>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", CourseGroup) &&
          <Button
            className="new-course-group-button"
            icon="plus"
            label={t(".add_new_course_group")}
            to={Routes.newAdminCourseGroupPath()}
          />
        }
      </>
    )
  }
}))
