import {Pressable, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
export default memo(shapeComponent(class ComponentsUnicornUpgradeOverlayOptionBox extends ShapeComponent {
  static defaultProps = {
    buttonProps: undefined
  }

  static propTypes = propTypesExact({
    buttonProps: PropTypes.object,
    buttonText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onButtonPress: PropTypes.func.isRequired
  })

  render() {
    const {buttonProps, buttonText, children, onButtonPress} = this.p

    return (
      <View
        dataSet={{component: "unicorn--upgrade-overlay--option-box"}}
        style={{
          marginRight: 20,
          marginLeft: 20,
          width: 283,
          padding: 33,
          border: "4px solid #50b14e",
          borderRadius: 10
        }}
      >
        <Pressable onPress={onButtonPress} style={{height: 56, backgroundColor: "#50b14e", borderRadius: 10}} {...buttonProps}>
          <Text style={{marginTop: "auto", marginBottom: "auto", padding: 10, color: "#fff", textAlign: "center"}}>
            {buttonText}
          </Text>
        </Pressable>
        <View style={{marginTop: 15}}>
          {children}
        </View>
      </View>
    )
  }
}))
