import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorGroup} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsAdminScoreFactorGroupsNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    scoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup).isRequired
  }

  render() {
    const {t} = useI18n({namespace: "js.components.admin.score_factor_groups.nav"})
    const {active, className, scoreFactorGroup, ...restProps} = this.props

    return (
      <Nav className={classNames("components--admin--score-factor-groups--nav", className)} {...restProps}>
        <NavItem active={active} identifier="general" label={t("js.shared.general")} to={Routes.adminScoreFactorGroupPath(scoreFactorGroup.id())} />
        <NavItem
          active={active}
          identifier="score-factors"
          label={ScoreFactorGroup.humanAttributeName("scoreFactors")}
          to={Routes.adminScoreFactorGroupScoreFactorsPath(scoreFactorGroup.id())}
        />
      </Nav>
    )
  }
}))
