import {FormInputs, useForm} from "@kaspernj/api-maker/build/form"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import {Platform} from "react-native"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
export default memo(shapeComponent(class ComponentsTextKeysDestroyed extends ShapeComponent {
  static propTypes = propTypesExact({
    cacheKey: PropTypes.string,
    name: PropTypes.string.isRequired,
    resource: PropTypes.object.isRequired
  })

  setup() {
    this.form = useForm() || new FormInputs()
  }

  render() {
    const {resource} = this.p

    return (
      <div className="components--text-keys--destroyed">
        {resource.isPersisted() && resource.textKeys().preloaded().map((textKey) =>
          this.destroyTextKeyContent(textKey)
        )}
      </div>
    )
  }

  destroyTextKeyContent(textKey) {
    const {form} = this.tt
    const {name, resource} = this.p
    const foundPreloadedTextKey = resource.textKeys().find((aTextKey) => aTextKey.key() == textKey.key())

    if (foundPreloadedTextKey) return null

    if (form) {
      form.setValue(`${name}[text_keys_attributes][${textKey.uniqueKey()}][_destroy]`, 1)
    }

    return (
      <React.Fragment key={textKey.id()}>
        {Platform.OS == "web" &&
          <>
            {form.setValueWithHidden(`${name}[text_keys_attributes][${textKey.uniqueKey()}][id]`, textKey.id())}
            <input
              className="text-key-destroy-input"
              data-text-key-id={textKey.id()}
              name={`${name}[text_keys_attributes][${textKey.uniqueKey()}][_destroy]`}
              type="hidden"
              value="1"
            />
          </>
        }
      </React.Fragment>
    )
  }
}))
