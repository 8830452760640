import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Button} from "react-native-paper"
import classNames from "classnames"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {useForm} from "@kaspernj/api-maker/build/form"

export default memo(shapeComponent(class ComponentsAdminButton extends ShapeComponent {
  static defaultProps = {
    primary: false,
    save: false,
    submit: false
  }

  static propTypes = {
    color: PropTypes.string,
    dataSet: PropTypes.object,
    disabled: PropTypes.bool,
    primary: PropTypes.bool.isRequired,
    save: PropTypes.bool.isRequired,
    submit: PropTypes.bool.isRequired,
    title: PropTypes.string
  }

  setup() {
    this.form = useForm()
  }

  render() {
    const {color, dataSet, disabled, icon, onPress, primary, save, submit, title, ...restProps} = this.props
    const actualDataSet = Object.assign({disabled}, dataSet, {
      component: classNames("admin/button", dataSet?.component),
      primary,
      save,
      submit
    })
    const buttonProps = {}

    if (disabled) {
      buttonProps.buttonColor = "#c8c8c8"
    } else if (color == "green") {
      buttonProps.buttonColor = "#50b14e"
    } else if (color == "grey") {
      buttonProps.buttonColor = "#a9afb9"
    } else if (color == "purple") {
      buttonProps.buttonColor = "#d648dc"
    } else if (color == "red") {
      buttonProps.buttonColor = "#ff4c4c"
    } else if (color == "white") {
      buttonProps.buttonColor = "#fff"
      buttonProps.textColor = "#000"
    } else if (color) {
      throw new Error(`Unknown color: ${color}`)
    }

    let iconToUse, label

    if (icon) {
      iconToUse = icon
    } else if (save) {
      iconToUse = "content-save"
    }

    if (title) {
      label = title
    } else if (save) {
      label = I18n.t("js.shared.save")
    } else {
      throw new Error("Couldn't figure out label")
    }

    if (primary) {
      buttonProps.mode = "contained"
    } else {
      buttonProps.mode = "outlined"
    }

    return (
      <Button
        dataSet={actualDataSet}
        icon={iconToUse}
        onPress={this.tt.onPress}
        {...buttonProps}
        {...restProps}
      >
        {label}
      </Button>
    )
  }

  onPress = () => {
    if (this.props.submit && this.form) {
      this.form.submit()
    }

    if (this.props.onPress) {
      this.props.onPress()
    }
  }
}))
