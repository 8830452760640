import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLayout from "components/user-layout"
export default memo(shapeComponent(class RoutesUserUnsubscribed extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.unsubscribed"})

    this.t = t
  }

  render() {
    const {t} = this.tt

    return (
      <UserLayout dataSet={{route: "unsubscribed"}} requireUser={false}>
        {t(".you_have_been_unsubscribed")}
      </UserLayout>
    )
  }
}))
