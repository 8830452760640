import {ContactRelationship, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import ContactRelationshipsTable from "components/contact-relationships/table"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/users/nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserSchoolRoles extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.users.school_roles"})
    const params = useParams()
    const {user, userId} = useModel(User, {
      match: {params},
      preload: ["contact.parent_relationships.parent.school", "contact.parent_relationships.parent.school_class"],
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        School: ["id"],
        SchoolClass: ["id"],
        User: ["accountUsersCount", "contactId", "id", "subscriptionsCount", "userRolesCount"]
      }
    })

    this.canCan = useCanCan(() => [[ContactRelationship, ["create"]]])
    this.setInstance({t, user, userId})
    this.schoolRolesQuery = useMemo(
      () => ContactRelationship.ransack({
        parent_school_id_not_null: 1,
        child_user_id_eq: userId
      }),
      [userId]
    )

    useAdminLayout().setState({
      actions: this.actions(),
      active: "users",
      headerTitle: User.humanAttributeName("SchoolRoles")
    })
  }

  render() {
    const {user} = this.tt

    return (
      <View dataSet={{route: "admin--users--school-roles"}}>
        {user &&
          <Nav active="school-roles" style={{marginBottom: 15}} user={user} />
        }
        <ContactRelationshipsTable
          adminLayout
          collection={this.tt.schoolRolesQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, user} = this.tt

    return (
      <>
        {canCan?.can("create", ContactRelationship) && user &&
          <Button
            className="new-school-role-button"
            icon="plus"
            label={this.t(".new_role_for_user")}
            to={Routes.newAdminContactRelationshipPath({contact_relationship: {child_id: user.contactId()}})}
          />
        }
      </>
    )
  }
}))
