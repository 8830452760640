import {Invoice, Subscription} from "models"
import React, {useCallback} from "react"
import I18n from "shared/i18n"
import ModelLink from "components/model-link"
import Select from "components/inputs/select"
import {Text} from "shared/base"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"

const subscriptionResourceContent = ({invoice}) => {
  if (invoice.subscriptionPeriod()) {
    const subscriptionPeriod = invoice.subscriptionPeriod()
    const subscription = subscriptionPeriod.subscription()
    const subscriptionResource = subscription.school() || subscription.schoolClass() || subscription.user()

    if (subscriptionResource) {
      return (
        <ModelLink model={subscriptionResource} superAdminLayout />
      )
    }
  }

  return null
}
const subscriptionResourceLabel = () => <Text>{Subscription.modelName().human()} {Subscription.humanAttributeName("resource")}</Text>
const tableResourceContent = ({invoice}) => invoice.resource() && <ModelLink model={invoice.resource()} superAdminLayout />
const tableStateContent = ({invoice}) => <Text>{invoice.translatedState()}</Text>

export default {
  edit: {
    attributes: [
      {
        attribute: "state",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          const stateOptions = TranslatedCollections.get(Invoice, "state").map(({translation, value}) => ({text: translation, value}))

          return (
            <Select
              label={Invoice.humanAttributeName("state")}
              name={null}
              onChange={onChange}
              options={stateOptions}
              type="hayaSelect"
              {...inputProps}
            />
          )
        }
      }
    ]
  },
  show: {
    attributesToShow: () => [
      "id",
      {content: subscriptionResourceContent, label: subscriptionResourceLabel(), key: "subscription-resource"},
      {content: tableResourceContent, label: Invoice.humanAttributeName("resource"), key: "table-resource"},
      {attribute: "state", content: tableStateContent, label: Invoice.humanAttributeName("state")},
      "createdAt",
      "updatedAt"
    ],
    preload: [
      "subscription_period.subscription.school",
      "subscription_period.subscription.school_class",
      "subscription_period.subscription.user"
    ],
    extraSelect: {
      Invoice: ["resource", "totalPrice", "translatedState"],
      School: ["id", "name"],
      SchoolClass: ["id", "interpretedName"],
      Subscription: ["id"],
      SubscriptionPeriod: ["id"],
      User: ["id", "name"]
    }
  },
  table: {
    columns: () => [
      {
        content: tableResourceContent,
        identifier: "resource",
        label: Invoice.humanAttributeName("resource")
      },
      {
        content: subscriptionResourceContent,
        identifier: "subscription-resource",
        label: subscriptionResourceLabel()
      },
      {
        attribute: "totalPrice",
        identifier: "totalPrice",
        label: I18n.t("js.super_admin.model_configs.invoice.total_price")
      },
      {
        attribute: "state",
        content: tableStateContent,
        sortKey: "state"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      }
    ],
    query: Invoice
      .select({
        Invoice: ["resource", "totalPrice", "translatedState"],
        School: ["id", "name"],
        SchoolClass: ["id", "interpretedName"],
        Subscription: ["id"],
        SubscriptionPeriod: ["id"],
        User: ["id", "name"]
      })
      .preload([
        "subscription_period.subscription.school",
        "subscription_period.subscription.school_class",
        "subscription_period.subscription.user"
      ])
  }
}
