import {Content, ContentAbsoluteContainer, SurveyStep} from "models"
import {FormInputs, useForm} from "@kaspernj/api-maker/build/form"
import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AbsoluteContainer from "./absolute-container"
import AbsoluteContainerEditor from "./absolute-container-editor"
import AdminInput from "components/admin/input"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import EventEmitter from "events"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import TextKeysHandle from "components/text-keys/handle"
import TextKeysPartial from "components/text-keys/partial"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import useResizeObserver from "@kaspernj/api-maker/build/use-resize-observer"
import UtilsCode from "components/utils/code"
import UtilsSelect from "components/utils/select"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsContentsEditor extends ShapeComponent {
  static defaultProps = {
    absoluteContainers: true,
    preview: true
  }

  static propTypes = propTypesExact({
    absoluteContainers: PropTypes.bool.isRequired,
    attributeName: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    preview: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    surveyStep: PropTypes.instanceOf(SurveyStep)
  })

  containerEvents = new EventEmitter()
  initialContentToUse = this.initialContent()

  setup() {
    const {locale, t} = useI18n({namespace: "js.components.contents.editor"})

    this.setInstance({
      bodyTypesCollection: useMemo(() => TranslatedCollections.get(Content, "body_type").map(({translation, value}) => ({text: translation, value})), [locale]),
      form: useForm() || new FormInputs(),
      previewContentRef: useRef(),
      queryParams: useQueryParams(),
      t
    })
    this.useStates({
      absoluteContainers: undefined,
      cacheKey: this.initialContentToUse.fullCacheKey(),
      content: this.initialContentToUse,
      contentBody: this.initialContentToUse.body(),
      contentBodyType: this.initialContentToUse.bodyType(),
      contentsEditorPreviewWidth: undefined,
      contentsEditorPreviewHeight: undefined,
      editAbsoluteContainer: undefined,
      nestedAbsoluteContainers: undefined
    })
    useResizeObserver(this.tt.previewContentRef.current, this.tt.onPreviewContentResize)

    useMemo(() => {
      this.loadAbsoluteContainersNestedSet()
      this.setContentsEditorPreviewSize()
    }, [])
  }

  initialAbsoluteContainers() {
    const {attributeName, resource} = this.p

    if (resource.isPersisted()) {
      const existingContent = resource.contents().loaded().find((content) => content.attributeName() == attributeName)

      if (existingContent) {
        return existingContent.absoluteContainers().loaded()
      }
    }

    return []
  }

  async loadAbsoluteContainersNestedSet() {
    const absoluteContainerIds = this.initialAbsoluteContainers().map((absoluteContainer) => absoluteContainer.id())
    const query = ContentAbsoluteContainer
      .ransack()
      .preload(["styling", "survey_step_element", "text_keys.text_values"])
      .select({
        ContentAbsoluteContainer: [
          "backgroundImageUrl",
          "body",
          "bodyType",
          "heightPercent",
          "heightPixels",
          "horizontalAlign",
          "id",
          "leftPercent",
          "leftPixels",
          "liquidConditions",
          "minimumHeight",
          "minimumWidth",
          "parentId",
          "responsive",
          "stylingId",
          "topPercent",
          "topPixels",
          "verticalAlign",
          "widthPercent",
          "widthPixels"
        ],
        Styling: ["id", "styling"],
        SurveyStepElement: ["elementType", "id", "translatedElementType"],
        TextKey: ["attributeName", "id", "key"],
        TextValue: ["id", "locale", "value", "valueType"]
      })
    const nestedAbsoluteContainersResult = await ContentAbsoluteContainer.nestedSet({absolute_container_ids: absoluteContainerIds, query})
    const nestedAbsoluteContainersList = digg(nestedAbsoluteContainersResult, "collection")
    const nestedAbsoluteContainers = {}

    for (const nestedAbsoluteContainer of nestedAbsoluteContainersList) {
      const parentId = nestedAbsoluteContainer.parentId()

      if (!(parentId in nestedAbsoluteContainers)) nestedAbsoluteContainers[parentId] = []

      nestedAbsoluteContainers[parentId].push(nestedAbsoluteContainer)
    }

    let rootAbsoluteContainers

    if (nestedAbsoluteContainers[null]) {
      rootAbsoluteContainers = [...nestedAbsoluteContainers[null]]
    } else {
      rootAbsoluteContainers = []
    }

    this.setState({
      absoluteContainers: rootAbsoluteContainers,
      nestedAbsoluteContainers
    })
  }

  initialContent() {
    const {attributeName, resource} = this.p

    if (resource.isPersisted()) {
      const existingContent = resource.contents().loaded().find((content) => content.attributeName() == attributeName)

      if (existingContent) return existingContent
    }

    return new Content({
      a: {
        body: null,
        body_type: "plain",
        id: uuidv4()
      },
      isNewRecord: true
    })
  }

  render() {
    const {bodyTypesCollection, form, queryParams} = this.tt
    const {className, inputProps} = this.props
    const {attributeName, name, resource} = this.p
    const {
      absoluteContainers,
      cacheKey,
      contentBody,
      contentBodyType,
      contentsEditorPreviewWidth,
      contentsEditorPreviewHeight,
      content,
      editAbsoluteContainer,
      nestedAbsoluteContainers
    } = this.s
    const previewClassNames = [
      "preview-content",
      "ck-content" // Needs to apply CKEditor specific styling to make it look right
    ]
    let absoluteContainerName

    if (name) {
      absoluteContainerName = `${name}[absolute_containers_attributes]`
    } else {
      absoluteContainerName = "absolute_containers_attributes"
    }

    const liquidVariables = content.detectedLiquidVariables() || queryParams.detected_liquid_variables

    return (
      <View dataSet={{component: "contents/editor", class: className}}>
        {this.p.absoluteContainers && editAbsoluteContainer &&
          <Modal onRequestClose={this.tt.onRequestCloseEditAbsoluteContainerModal}>
            <AbsoluteContainerEditor
              absoluteContainer={editAbsoluteContainer}
              events={this.tt.containerEvents}
              onAbsoluteContainerChanged={this.tt.onAbsoluteContainerChanged}
              onRequestClose={this.tt.onAbsoluteContainerEditorRequestClose}
              surveyStep={this.props.surveyStep}
            />
          </Modal>
        }
        {form.setValueWithHidden(`${name}[attribute_name]`, attributeName)}
        {content.isPersisted() && form.setValueWithHidden(`${name}[id]`, content.id())}
        {content.isNewRecord() && form.setValueWithHidden(`${name}[new_id]`, content.id())}
        {liquidVariables &&
          <>
            <Text>{Content.humanAttributeName("detectedLiquidVariables")}</Text>
            <UtilsCode viewProps={{dataSet: {class: "liquid-variables-container"}, style: {marginBottom: 10}}}>
              {JSON.stringify(liquidVariables, null, 2)}
            </UtilsCode>
          </>
        }
        <View style={{marginBottom: 10}}>
          <UtilsSelect
            id="content_body_type"
            label={Content.humanAttributeName("bodyType")}
            name={`${name}[body_type]`}
            onChangeSelected={this.tt.onContentBodyTypeChanged}
            options={bodyTypesCollection}
            values={[contentBodyType]}
          />
        </View>
        {form.setValueWithHidden(`${name}[body]`, contentBody)}
        {contentBodyType == "ckeditor" &&
          <Input
            defaultValue={contentBody}
            id={this.inputId()}
            model={resource}
            name={`${name}[body]`}
            onChange={this.tt.onBodyChanged}
            type="ckeditor"
            {...inputProps}
          />
        }
        {contentBodyType == "plain" &&
          <AdminInput
            autoRows
            dataSet={{class: "content-body"}}
            label={Content.humanAttributeName("body")}
            minimumRows={10}
            multiline
            name={`${name}[body]`}
            onChangeText={this.tt.onContentBodyChanged}
            value={contentBody}
          />
        }
        {this.p.preview &&
          <div className="contents-editor-preview" data-class="components--ckeditor--presentation" id="contents-editor-preview" ref={this.tt.previewContentRef}>
            {this.p.absoluteContainers &&
            contentsEditorPreviewWidth &&
            contentsEditorPreviewHeight &&
            nestedAbsoluteContainers &&
            absoluteContainers?.map((absoluteContainer) =>
              <AbsoluteContainer
                absoluteContainer={absoluteContainer}
                cacheKey={`${cacheKey}-${absoluteContainer.localCacheKey()}`}
                events={this.tt.containerEvents}
                key={absoluteContainer.uniqueKey()}
                name={`${absoluteContainerName}[${absoluteContainer.uniqueKey()}]`}
                nestedAbsoluteContainers={nestedAbsoluteContainers}
                onDoubleClick={this.tt.onAbsoluteContainerPreviewClicked}
                parentHeight={contentsEditorPreviewHeight}
                parentWidth={contentsEditorPreviewWidth}
                surveyStep={this.props.surveyStep}
              />
            )}
            <div className={classNames(previewClassNames)} dangerouslySetInnerHTML={{__html: contentBody}} />
          </div>
        }
        {this.p.absoluteContainers && absoluteContainers &&
          <Button
            className="add-absolute-container-button"
            icon="plus"
            label={this.t(".add_absolute_container")}
            onClick={this.tt.onAddAbsoluteContainerClicked}
          />
        }
        <TextKeysHandle cacheKey={cacheKey} name={name} resource={content} />
        <TextKeysPartial
          attributeName="body"
          cacheKey={cacheKey}
          content={contentBody || ""}
          name={name}
          onTextKeysChanged={this.tt.onTextKeysChanged}
          resource={content}
        />
      </View>
    )
  }

  inputId = () => this.props.inputProps?.id || "contents_editor_input"
  onAbsoluteContainerPreviewClicked = (e, absoluteContainer) => {
    e.stopPropagation()

    this.setState({editAbsoluteContainer: absoluteContainer})
  }

  onAddAbsoluteContainerClicked = (e) => {
    e.preventDefault()

    const contentsEditorPreview = digg(this.tt.previewContentRef, "current")
    const contentsEditorPreviewWidth = digg(contentsEditorPreview, "offsetWidth")
    const contentsEditorPreviewHeight = digg(contentsEditorPreview, "offsetHeight")

    const widthPixels = Number(contentsEditorPreviewWidth / 3)
    const heightPixels = Number(contentsEditorPreviewHeight / 3)
    const leftPixels = Number(contentsEditorPreviewWidth * 0.02)
    const topPixels = Number(contentsEditorPreviewHeight * 0.02)
    const heightPercent = (heightPixels / contentsEditorPreviewHeight) * 100
    const leftPercent = (leftPixels / contentsEditorPreviewWidth) * 100
    const topPercent = (topPixels / contentsEditorPreviewHeight) * 100
    const widthPercent = (widthPixels / contentsEditorPreviewWidth) * 100

    const newAbsoluteContainer = new ContentAbsoluteContainer({
      a: {
        body: "",
        bodyType: "plain",
        id: uuidv4(),
        heightPercent,
        heightPixels,
        leftPercent,
        leftPixels,
        topPercent,
        topPixels,
        widthPercent,
        widthPixels
      },
      isNewRecord: true
    })

    this.setState({
      absoluteContainers: this.s.absoluteContainers.concat([newAbsoluteContainer])
    })
  }

  onAbsoluteContainerChanged = () => this.setState({cacheKey: this.s.content.fullCacheKey()})
  onAbsoluteContainerEditorRequestClose = () => this.setState({editAbsoluteContainer: undefined})
  onBodyChanged = ({value}) => this.onContentBodyChanged(value)
  onContentBodyChanged = (value) => {
    this.setState({contentBody: value})

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  onContentBodyTypeChanged = (contentBodyType) => this.setState({contentBodyType})
  onPreviewContentResize = () => this.tt.setContentsEditorPreviewSize()
  onRequestCloseEditAbsoluteContainerModal = () => this.setState({editAbsoluteContainer: undefined})
  onTextKeysChanged = () => this.setState({cacheKey: this.s.content.fullCacheKey()})
  setContentsEditorPreviewSize = () => {
    const contentsEditorPreview = digg(this.tt.previewContentRef, "current")
    const width = contentsEditorPreview?.offsetWidth
    const height = contentsEditorPreview?.offsetHeight

    this.setState({
      contentsEditorPreviewWidth: width,
      contentsEditorPreviewHeight: height
    })
  }
}))
