import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"

export default memo(shapeComponent(class ComponentsCircleIconButton extends ShapeComponent {
  static defaultProps = {
    color: "blue",
    disabled: false,
    to: "#"
  }

  static propTypes = {
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string,
    tooltip: PropTypes.string
  }

  render() {
    const {className, color, disabled, icon, label, onClick, to, tooltip, ...restProps} = this.props

    return (
      <div className={classNames("components--circle-icon-button", className)} data-color={color} data-disabled={disabled} {...restProps}>
        <a href={disabled ? "#" : to} onClick={onClick}>
          <div style={{display: "flex", justifyContent: "center"}}>
            <div className="icon-container" tooltip={tooltip}>
              <div>
                <i className={`fa fa-fw fa-${icon}`} style={{color: "#fff", fontSize: "40px"}} />
              </div>
            </div>
          </div>
          <div className="circle-icon-button-label">
            {label}
          </div>
        </a>
      </div>
    )
  }
}))
