import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import {BrainBreak} from "models"
import BrainBreaksTable from "components/brain-breaks/table"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminBrainBreaksIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const canCan = useCanCan(() => [[BrainBreak, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.brain_breaks.index"})

    this.setInstance({canCan, t})
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="brain-breaks"
        className="routes--admin-brain-breaks--index"
        headerTitle={BrainBreak.modelName().human({count: 2})}
      >
        <BrainBreaksTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", BrainBreak) &&
          <Button
            className="new-brain-break-button"
            icon="plus"
            label={this.t(".add_new_brain_break")}
            to={Routes.newAdminBrainBreakPath()}
          />
        }
      </>
    )
  }
}))
