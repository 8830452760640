import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import CircleIconButton from "components/circle-icon-button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSchoolClassesCreateCheckInButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.create_check_in_button"})

    this.setInstance({t})
  }

  render() {
    const {onCreateCheckInButtonClicked, t} = this.tt
    const {className, schoolClass, ...restProps} = this.props

    return (
      <CircleIconButton
        className={classNames("components--school-classes--create-check-in-button", className)}
        icon="paw"
        label={t(".woof")}
        onClick={onCreateCheckInButtonClicked}
        tooltip={t(".check_in_with_the_school_class")}
        {...restProps}
      />
    )
  }

  onCreateCheckInButtonClicked = async (e) => {
    e.preventDefault()

    const {schoolClass} = this.p

    try {
      const response = await schoolClass.createCheckIn()
      const schoolClassCheckIn = digg(response, "school_class_check_in")

      AppHistory.push(Routes.userSchoolClassCheckInLobbyPath(schoolClassCheckIn.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
