import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsNewsletterTemplateTimeZoneStateSendButton extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.newsletter_template_time_zone_states.send_button"})

    this.t = t
    this.useStates({
      disabled: false
    })
  }

  render() {
    const {className, newsletterTemplateTimeZoneState, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--newsletter-template-time-zone-states--send-button", className)}
        disabled={this.s.disabled}
        icon="envelopes-bulk"
        label={this.t(".send")}
        onClick={this.tt.onClick}
        {...restProps}
      />
    )
  }

  onClick = async (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    try {
      this.setState({disabled: true})
      await this.p.newsletterTemplateTimeZoneState.send()
      FlashMessage.success(this.t(".sending_has_been_queued"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      this.setState({disabled: false})
    }
  }
}))
