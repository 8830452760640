import {EvaluationGroup} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectEvaluationGroups,
  withInputContainer: EvaluationGroupsSelectWithContainer,
  withMemo: ComponentsEvaluationGroupsSelect
} = selectComponent({
  className: "components--evaluation-groups--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || EvaluationGroup

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({EvaluationGroup: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const evaluationGroups = await query.toArray()

    return evaluationGroups.map((evaluationGroup) => ({
      text: evaluationGroup.name(),
      value: evaluationGroup.id()
    }))
  }
})

export default ComponentsEvaluationGroupsSelect
export {AdminSelectEvaluationGroups, EvaluationGroupsSelectWithContainer}
