import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import ClassStepLink from "components/class-steps/link"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroupClassStep} from "models"
import SchoolClassGroupLink from "components/school-class-groups/link"
export default memo(shapeComponent(class ComponentsSchoolClassGroupClassSteps extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--score-factor-group-score-factors--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={SchoolClassGroupClassStep}
        preloads={["class_step", "school_class_group"]}
        select={{
          ClassStep: ["id", "name"],
          SchoolClassGroup: ["id", "name"],
          SchoolClassGroupClassStep: ["id"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.schoolClassGroupContent,
      label: SchoolClassGroupClassStep.humanAttributeName("schoolClassGroup"),
      sortKey: "schoolClassGroupCurrentTranslationName"
    },
    {
      content: this.tt.classStepContent,
      label: SchoolClassGroupClassStep.humanAttributeName("classStep"),
      sortKey: "classStepCurrentTranslationName"
    }
  ]

  editModelPath = ({schoolClassGroupClassStep}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSchoolClassGroupClassStepPath(schoolClassGroupClassStep.id())
  }

  classStepContent = ({schoolClassGroupClassStep}) => schoolClassGroupClassStep.classStep() &&
    <ClassStepLink classStep={schoolClassGroupClassStep.classStep()} {...linkProps(this.props)} />

  schoolClassGroupContent = ({schoolClassGroupClassStep}) => schoolClassGroupClassStep.schoolClassGroup() &&
    <SchoolClassGroupLink schoolClassGroup={schoolClassGroupClassStep.schoolClassGroup()} {...linkProps(this.props)} />
}))
