import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {Survey} from "models"
import SurveysTable from "components/surveys/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSurveysIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.surveys.index"})

    this.canCan = useCanCan(() => [[Survey, ["create"]]])
    this.t = t

    useAdminLayout()?.setState({
      actions: this.actions(),
      active: "surveys",
      headerTitle: Survey.modelName().human({count: 2})
    })
  }

  render() {
    return (
      <View dataSet={{route: "admin--surveys--index"}}>
        <SurveysTable adminLayout />
      </View>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", Survey) &&
          <Button className="new-survey-button" icon="plus" label={t(".add_new_survey")} to={Routes.newAdminSurveyPath()} />
        }
      </>
    )
  }
}))
