export default {
  edit: {
    attributes: [
      {attribute: "name", translated: true},
      {attribute: "identifier"},
      {attribute: "showForUsers"}
    ]
  },
  table: {
    columns: () => [
      {
        attribute: "id",
        sortKey: "id"
      },
      {
        attribute: "name",
        sortKey: "currentTranslationName"
      },
      {
        attribute: "showForUsers",
        sortKey: "showForUsers"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      },
      {
        attribute: "updatedAt",
        sortKey: "updatedAt"
      }
    ]
  }
}
