import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import setLayout from "shared/set-layout"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
import {View} from "react-native"
export default memo(shapeComponent(class RoutesAdminLiquidDrops extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.components.admin.layout.menu.menu_content"})

    this.setInstance({
      currentUser: useCurrentUser(),
      t
    })
    this.useStates({
      liquidDrops: undefined
    })

    useMemo(
      () => {
        this.loadLiquidDrops()
      },
      [this.tt.currentUser?.id()]
    )

    useAdminLayout()?.setState({active: "liquid-drops", headerTitle: t(".liquid_drops")})
  }

  render() {
    return (
      <View dataSet={{route: "admin/liquid-drops"}}>
        {this.s.liquidDrops &&
          <Text dataSet={{class: "liquid-drops-container"}}>
            {JSON.stringify(this.s.liquidDrops, null, 2)}
          </Text>
        }
      </View>
    )
  }

  loadLiquidDrops = async () => {
    const liquidDrops = await User.liquidDrops()

    this.setState({liquidDrops: digg(liquidDrops, "all_drops_with_attributes")})
  }
}))
