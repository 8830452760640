import {Contact, School} from "models"
import React, {useCallback, useMemo} from "react"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import SchoolLink from "components/schools/link"
import useLocale from "i18n-on-steroids/src/use-locale.mjs"
import useShape from "set-state-compare/src/use-shape"
const ComponentsSchoolsTeachers = (props) => {
  const {adminLayout = false, className, userLayout = false, ...restProps} = props
  const s = useShape(Object.assign({adminLayout, userLayout}, props))
  const locale = useLocale()
  const schoolContent = useCallback(({school}) => <SchoolLink school={school} {...linkProps(s.props)} />, [])
  const columns = useMemo(() => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: schoolContent,
      label: Contact.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "schoolClassesCount",
      sortKey: "schoolClassesCount"
    }
  ], [locale])

  const editModelPath = useCallback(({school}) => {
    if (s.p.adminLayout) return Routes.editAdminSchoolPath(school.id())
  }, [])

  const viewModelPath = useCallback(({school}) => {
    if (s.p.adminLayout) return Routes.adminSchoolPath(school.id())
  }, [])

  return (
    <ApiMakerTable
      className={classNames("components--schools--table", className)}
      columns={columns}
      editModelPath={editModelPath}
      modelClass={School}
      select={{School: ["id", "name"]}}
      viewModelPath={viewModelPath}
      workplace
      {...restProps}
    />
  )
}

ComponentsSchoolsTeachers.propTypes = {
  adminLayout: PropTypes.bool,
  className: PropTypes.string,
  userLayout: PropTypes.bool
}

export default ComponentsSchoolsTeachers
