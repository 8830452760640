import {Platform, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AdminSelect from "components/admin/select"
import {digg} from "diggerize"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import RenderHtml from "shared/render-html"
import SanitizedHtml from "shared/sanitized-html"
import Services from "@kaspernj/api-maker/build/services"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsCard from "components/utils/card"

export default memo(shapeComponent(class RoutesAdminLiquidTester extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.liquid_tester"})

    this.setInstance({
      showOptions: useMemo(
        () => {
          const options = [
            {text: t(".text"), value: "text"},
            {text: t(".react_native"), value: "react-native"}
          ]

          if (Platform.OS == "web") {
            options.push({text: t(".html"), value: "html"})
          }

          return options
        },
        [locale]
      ),
      t
    })
    this.useStates({
      errors: null,
      liquidContent: "",
      form: null,
      result: null,
      showAs: "text"
    })

    useAdminLayout()?.setState({active: "liquid-tester", headerTitle: t(".liquid_tester")})
  }

  render() {
    const {t} = this.tt

    return (
      <View dataSet={{route: "admin/liquid-tester"}}>
        <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
          <AdminInput
            autoRows
            label={t(".liquid_code")}
            multiline
            name="liquid_content"
            onChangeText={this.setStates.liquidContent}
            style={{marginBottom: 15}}
            value={this.s.liquidContent}
          />
          <View style={{marginBottom: 15}}>
            <AdminSelect
              label={t(".show_as")}
              onChangeValue={this.setStates.showAs}
              options={this.tt.showOptions}
              values={this.s.showAs ? [this.s.showAs] : null}
            />
          </View>
          <AdminButton submit title={t("js.shared.submit")} />
        </Form>
        {this.s.errors?.map(({lineNumber, message}, errorIndex) =>
          <UtilsCard header={t("js.shared.error")} key={`${message}:${lineNumber}:${errorIndex}`} style={{marginTop: 15}}>
            <Text>
              {t(".error_on_line", {line_number: lineNumber, message})}
            </Text>
          </UtilsCard>
        )}
        {this.s.result &&
          <UtilsCard style={{marginTop: 15}}>
            {this.s.showAs == "text" &&
              <Text>{this.s.result}</Text>
            }
            {this.s.showAs == "react-native" &&
              <RenderHtml source={this.s.result} />
            }
            {this.s.showAs == "html" &&
              <SanitizedHtml html={this.s.result} />
            }
          </UtilsCard>
        }
      </View>
    )
  }

  onSubmit = async () => {
    this.setState({errors: null, result: null})

    const result = await Services.current().sendRequest("LiquidTester", {liquid_content: this.s.liquidContent})

    this.setState({
      errors: digg(result, "errors").map(({line_number: lineNumber, message}) => ({lineNumber, message})),
      result: digg(result, "result")
    })
  }
}))
