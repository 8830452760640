import {ContentPieceType, SchoolClass, SchoolClassContentPiece} from "models"
import {Pressable, View} from "react-native"
import React, {useMemo, useRef} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import CkeditorEditor from "ckeditor/editor"
import CkeditorPresentation from "ckeditor/presentation"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {Text} from "shared/base"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {v4 as uuidv4} from "uuid"

const StrategyValue = memo(shapeComponent(class StrategyValue extends ShapeComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    return (
      <Pressable
        dataSet={{
          active: this.p.active,
          class: "strategy-value",
          contentPieceTypeId: this.p.contentPieceType.id()
        }}
        onPress={this.tt.onClicked}
        style={{
          borderBottom: this.p.active ? "2px solid #fff" : undefined,
          flexDirection: "row",
          alignItems: "center",
          marginRight: 50
        }}
      >
        <Text
          style={{
            color: "#fff",
            fontSize: 24,
            fontWeight: "bold"
          }}
        >
          {this.p.contentPieceType.name()}
        </Text>
        {this.p.contentPieceType.hasIcon() &&
          <FontAwesomeIcon color="#fff" name={this.p.contentPieceType.icon()} size={24} style={{marginLeft: 5}} />
        }
      </Pressable>
    )
  }

  onClicked = (e) => {
    e.preventDefault()
    this.p.onClick({contentPieceType: this.p.contentPieceType})
  }
}))

export default memo(shapeComponent(class ComponentsUserSchoolClassesDigitalClassRoomStrategies extends ShapeComponent {
  static propTypes = {
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.user.school_classes.digital_class_room.strategies"})

    this.currentUser = useCurrentUser()
    this.formRef = useRef()
    this.t = t

    this.useStates({
      activeContentPieceType: undefined,
      contentPieceTypes: undefined,
      editActiveSchoolClassContentPiece: false,
      schoolClassContentPieces: undefined
    })

    useMemo(() => {
      this.loadContentPieceTypes()
      this.loadSchoolClassContentPieces()
    }, [this.tt.currentUser?.id()])
  }

  async loadContentPieceTypes() {
    const contentPieceTypes = await ContentPieceType
      .ransack({
        s: "current_translation_name",
        show_on_digital_class_room_true: 1
      })
      .select({ContentPieceType: ["icon", "id", "name"]})
      .toArray()

    this.setState({
      activeContentPieceType: contentPieceTypes[0],
      contentPieceTypes
    })
  }

  async loadSchoolClassContentPieces() {
    const schoolClassContentPieces = await SchoolClassContentPiece
      .ransack({
        school_class_id_eq: this.p.schoolClass.id(),
        latest_for_school_class: true
      })
      .toArray()

    this.setState({schoolClassContentPieces})
  }

  activeSchoolClassContentPiece = () => {
    if (!this.s.activeContentPieceType || !this.s.schoolClassContentPieces) return null

    const matchingOrderedSchoolClassContentPieces = this.s.schoolClassContentPieces
      .filter((schoolClassContentPiece) => schoolClassContentPiece.contentPieceTypeId() == this.s.activeContentPieceType.id())
      .sort((schoolClassContentPiece1, schoolClassContentPiece2) =>
        schoolClassContentPiece2.createdAt().getTime() - schoolClassContentPiece1.createdAt().getTime()
      )

    return matchingOrderedSchoolClassContentPieces[0]
  }

  render() {
    const {t} = this.tt
    const activeSchoolClassContentPiece = this.activeSchoolClassContentPiece()
    const {activeContentPieceType, editActiveSchoolClassContentPiece} = this.s

    return (
      <View dataSet={{activeContentPieceTypeId: activeContentPieceType?.id(), component: "user--school-classes--digital-class-room--strategies"}}>
        <View
          dataSet={{class: "strategies-container"}}
          style={{
            padding: 37,
            borderRadius: 10,
            backgroundColor: "#4c93ff"
          }}
        >
          <View
            dataSet={{class: "strategy-values-container"}}
            style={{
              flexDirection: "row",
              marginBottom: 36
            }}
          >
            {this.s.contentPieceTypes?.map((contentPieceType) =>
              <StrategyValue
                active={contentPieceType.id() == this.s.activeContentPieceType?.id()}
                contentPieceType={contentPieceType}
                key={contentPieceType.id()}
                onClick={this.tt.onContentPieceTypeClicked}
              />
            )}
          </View>
          <Pressable
            dataSet={{
              class: "strategy-action",
              editting: this.s.editActiveSchoolClassContentPiece,
              schoolClassContentPieceId: activeSchoolClassContentPiece?.id()
            }}
            onPress={this.tt.onActiveSchoolClassContentPieceClicked}
            style={{
              paddingVertical: 16,
              paddingHorizontal: 27,
              borderRadius: 15,
              marginRight: 24,
              backgroundColor: "#fff",
              color: "#4c93ff",
              fontSize: 24,
              fontWeight: "bold"
            }}
          >
            {editActiveSchoolClassContentPiece &&
              <form onSubmit={this.tt.onActiveSchoolClassContentPieceSubmit} ref={this.tt.formRef}>
                <CkeditorEditor
                  defaultValue={activeSchoolClassContentPiece?.content()}
                  id="school_class_content_piece_content"
                  name="school_class_content_piece[content]"
                  placeholder={t(".enter_new_content")}
                  resource={editActiveSchoolClassContentPiece}
                />
                <Button className="mt-2" primary save />
              </form>
            }
            {!this.s.editActiveSchoolClassContentPiece && activeSchoolClassContentPiece?.hasContent() &&
              <CkeditorPresentation html={activeSchoolClassContentPiece.content()} />
            }
            {!this.s.editActiveSchoolClassContentPiece && !activeSchoolClassContentPiece?.hasContent() &&
              <Text style={{fontStyle: "italic"}}>
                {t(".no_content")}
              </Text>
            }
          </Pressable>
        </View>
      </View>
    )
  }

  onActiveSchoolClassContentPieceClicked = () => {
    this.setState({
      editActiveSchoolClassContentPiece: new SchoolClassContentPiece({
        a: {
          id: uuidv4()
        },
        isNewRecord: true
      })
    })
  }

  onActiveSchoolClassContentPieceSubmit = (e) => {
    e.preventDefault()
    this.submit()
  }

  submit = async () => {
    const form = digg(this, "formRef", "current")
    const formData = new FormData(form)
    const {activeContentPieceType, editActiveSchoolClassContentPiece} = this.s

    formData.append("school_class_content_piece[new_id]", editActiveSchoolClassContentPiece.id())
    formData.append("school_class_content_piece[content_piece_type_id]", activeContentPieceType.id())
    formData.append("school_class_content_piece[school_class_id]", this.p.schoolClass.id())

    try {
      await editActiveSchoolClassContentPiece.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_content_was_saved"))
      this.setState({
        editActiveSchoolClassContentPiece: undefined,
        schoolClassContentPieces: this.s.schoolClassContentPieces.concat([editActiveSchoolClassContentPiece])
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onContentPieceTypeClicked = ({contentPieceType}) => this.setState({activeContentPieceType: contentPieceType, editActiveSchoolClassContentPiece: undefined})
}))
