import React, {useMemo} from "react"
import {SchoolClassBrainBreak, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Background from "components/user/brain-breaks/show/background.jpg"
import {digg} from "diggerize"
import EventEmitter from "events"
import I18n from "shared/i18n"
import {incorporate} from "incorporator"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import SurveysBottomBar from "components/surveys/bottom-bar"
import SurveysPageContainer from "components/surveys/page-container"
import SurveysPresentation from "components/surveys/presentation"
import SurveysSelectedAttributes from "components/surveys/selected-attributes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useHiddenTawkWidget from "shared/use-hidden-tawk-widget"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassBrainBreaksShow extends ShapeComponent {
  setup() {
    const currentUser = useCurrentUser()
    const params = useParams()
    const {schoolClassBrainBreak, schoolClassBrainBreakId} = useModel(SchoolClassBrainBreak, {
      match: {params},
      preload: ["school_class"],
      select: {
        SchoolClass: ["id", "schoolId"],
        SchoolClassBrainBreak: ["id", "schoolClassId"]
      }
    })
    const schoolClass = schoolClassBrainBreak?.schoolClass()

    this.setInstance({
      queryParams: useQueryParams(),
      schoolClassBrainBreak,
      schoolClassBrainBreakId
    })
    this.useStates({
      surveyStep: undefined,
      teamSurvey: undefined,
      teamSurveyStep: undefined
    })

    useMemo(() => {
      this.loadTeamSurvey()
    }, [currentUser?.id()])

    useHiddenTawkWidget()
    useFrontLayout()?.setState({
      background: Background,
      bottomBar: this.bottomBar(),
      contentCentered: true,
      schoolClass,
      topLeft: this.topLeft(),
      unicornTrialTopBar: false
    })
  }

  surveyStepsPresentationEvents = new EventEmitter()

  async loadTeamSurvey() {
    const {queryParams, schoolClassBrainBreakId} = this.tt
    const teamSurveyId = digg(queryParams, "team_survey_id")

    const preload = [
      "survey.steps.elements.options",
      "survey.steps.elements.score_factor",
      "team_survey_steps"
    ]

    for (const surveyPreload of SurveysSelectedAttributes.preload) {
      preload.push(
        `survey.steps.${surveyPreload}`
      )
    }

    const pageSelect = {
      SchoolClass: ["id", "schoolId"],
      SchoolClassBrainBreak: ["id", "schoolClassId"],
      Survey: ["id", "surveyStepsCount"],
      TeamSurvey: ["id", "preview", "state"],
      TeamSurveyStep: ["surveyStepId"]
    }

    const select = incorporate(pageSelect, SurveysSelectedAttributes.select)

    const teamSurvey = await TeamSurvey
      .ransack({
        id_eq: teamSurveyId,
        school_class_brain_break_id_eq: schoolClassBrainBreakId
      })
      .preload(preload)
      .select(select)
      .first()

    this.setState({teamSurvey})
  }

  render() {
    const {schoolClassBrainBreak} = this.tt
    const {teamSurvey} = this.s
    const schoolClass = schoolClassBrainBreak?.schoolClass()
    const survey = teamSurvey?.survey()

    return (
      <View dataSet={{route: "user/school-class-brain-breaks/show"}}>
        {schoolClass && survey && teamSurvey &&
          <SurveysPageContainer>
            <SurveysPresentation
              className="w-100"
              events={this.tt.surveyStepsPresentationEvents}
              onSurveyCompleted={this.tt.onSurveyCompleted}
              onSurveyStepLoaded={this.tt.onSurveyStepLoaded}
              onTeamSurveyStepLoaded={this.tt.onTeamSurveyStepLoaded}
              schoolClass={schoolClass}
              submitButton={false}
              survey={survey}
              teamSurvey={teamSurvey}
            />
          </SurveysPageContainer>
        }
      </View>
    )
  }

  bottomBar() {
    const {onCancelClicked, onEndClicked, onNextStepClicked, onPreviousStepClicked} = this.tt
    const {surveyStep, teamSurvey, teamSurveyStep} = this.s
    const survey = teamSurvey?.survey()

    return (
      <>
        {survey && surveyStep && teamSurveyStep &&
          <SurveysBottomBar
            color="white"
            onCancelClicked={onCancelClicked}
            onEndClicked={onEndClicked}
            onNextStepClicked={onNextStepClicked}
            onPreviousStepClicked={onPreviousStepClicked}
            survey={survey}
            surveyStep={surveyStep}
            teamSurvey={teamSurvey}
            teamSurveyStep={teamSurveyStep}
          />
        }
      </>
    )
  }

  topLeft() {
    const {schoolClassBrainBreak} = this.tt

    return (
      <>
        {schoolClassBrainBreak &&
          <Link className="text-white" to={Routes.userSchoolClassDigitalClassRoomPath(schoolClassBrainBreak?.schoolClassId())}>
            <i className="fa fa-chevron-left mr-1" />
            {I18n.t("js.routes.user.school_class_courses.show.go_to_the_space_of_the_class")}
          </Link>
        }
      </>
    )
  }

  onCancelClicked = () => {
    AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClassBrainBreak.schoolClassId()))
  }

  onEndClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "end"})
  }

  onNextStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "nextStep"})
  }

  onPreviousStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "previousStep"})
  }

  onSurveyStepLoaded = (surveyStep) => this.setState({surveyStep})
  onTeamSurveyStepLoaded = (teamSurveyStep) => this.setState({teamSurveyStep})
  onSurveyCompleted = () => AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClassBrainBreak.schoolClassId()))
}))
