import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import I18n from "shared/i18n"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import MoodBarChart from "components/score-factor-groups/mood-bar-chart"
import MoodBarChartWeekPicker from "components/score-factor-groups/mood-bar-chart/week-picker"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import React from "react"
import {SchoolClass} from "models"
import ToggleButton from "components/toggle-button"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"

export default memo(shapeComponent(class ComponentsUserSchoolClassesInsightsMoodBarChartSlide extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const currentUser = useCurrentUser()
    const {t} = useI18n({namespace: "js.routes.user.school_classes.insights"})
    const queryParams = useQueryParams()

    this.useStates({chartType: "bar-chart"})
    this.setInstance({currentUser, queryParams, t})
  }

  render() {
    const {currentUser, queryParams, t} = this.tt
    const {className, schoolClass, ...restProps} = this.props
    const chartType = queryParams.chart_type || "bar-chart"
    let activeDate, activeDates, weekNumber

    if (queryParams.active_week) {
      activeDate = moment(queryParams.active_week).toDate()
      activeDates = [activeDate]
      weekNumber = moment(activeDate).isoWeek()
    }

    return (
      <div className={classNames("components--user--school-classes--insights--mood-bar-chart-slite", className)} {...restProps}>
        {isUserA(currentUser, "tester") &&
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "20px"}}>
            <div className="chart-type-label" data-chart-type-label-active={chartType == "bar-chart"} style={{marginRight: "10px"}}>
              {t(".bar_chart")}
            </div>
            <div>
              <ToggleButton
                checked={chartType == "bubble-chart"}
                className="chart-type-toggle-button"
                onChange={this.tt.onChartTypeChanged}
              />
            </div>
            <div className="chart-type-label" data-chart-type-label-active={chartType == "bubble-chart"} style={{marginLeft: "10px"}}>
              {t(".bubble_chart")}
            </div>
          </div>
        }
        <div className="mood-bar-chart-flex-container">
          <div>
            <div style={{textAlign: "center"}}>
              <button
                className="view-the-latest-three-woofs-button"
                onClick={this.tt.onViewTheLatestThreeWoofsClicked}
              >
                {t(".view_the_latest_three_woofs")}
              </button>
            </div>
            <div style={{marginTop: "40px", marginBottom: "40px", color: "#595b60", textAlign: "center"}}>
              {t(".or_choose_a_week_below")}
            </div>
            <div className="week-picker-container">
              <MoodBarChartWeekPicker
                activeDate={activeDate}
                activeDates={activeDates}
                onSelectWeek={this.tt.onSelectWeek}
                schoolClass={schoolClass}
              />
            </div>
          </div>
          <div className="mood-bar-chart-container" style={{display: chartType == "bar-chart" ? "block" : "none"}}>
            <div className="mood-bar-chart-card">
              <MoodBarChart schoolClass={schoolClass} weekNumber={weekNumber} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  onChartTypeChanged = () => {
    const chartType = this.tt.queryParams.chart_type || "bar-chart"
    let newChartType

    if (chartType == "bar-chart") {
      newChartType = "bubble-chart"
    } else {
      newChartType = "bar-chart"
    }

    Params.changeParams({chart_type: newChartType})
  }

  onSelectWeek = ({weekDate}) => Params.changeParams({active_week: I18n.strftime("%Y-%m-%d", weekDate)})

  onViewTheLatestThreeWoofsClicked = (e) => {
    e.preventDefault()
    Params.changeParams({active_week: undefined})
  }
}))
