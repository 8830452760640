import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {SchoolClass} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class UpgradeLicnseButton extends ShapeComponent {
  static propTypes = {
    hasUnicorn: PropTypes.bool.isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.upgrade_license_button"})

    this.t = t
  }

  render() {
    const {hasUnicorn, schoolClass, ...restProps} = this.props

    return (
      <Button
        dataSet={{component: "upgrade-license-button", schoolClassId: schoolClass.id()}}
        onPress={this.tt.onPress}
        title={hasUnicorn ? this.t(".change_subscription") : this.t(".upgrade_license")}
        {...restProps}
      />
    )
  }

  onPress = () => {
    this.p.onUpgradeSchoolClassPressed({schoolClass: this.p.schoolClass})
  }
}))
