import {CheckIn, SchoolClassCheckIn, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import env from "shared/env"
import FlashMessage from "shared/flash-message"
import LayoutBase from "components/layout/base"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import UsersSignIn from "components/users/sign-in"

export default memo(shapeComponent(class RoutesUserSchoolClassCheckInsLobby extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.school_class_check_ins.lobby"})
    const {schoolClassCheckIn, schoolClassCheckInId} = useModel(SchoolClassCheckIn, {
      abilities: {
        SchoolClassCheckIn: ["stateEventCancel"]
      },
      eventUpdated: true,
      match: this.p.match,
      select: {
        SchoolClassCheckIn: ["code", "completedTeamSurveysCount", "id", "qrcodeUrlPath", "schoolClassId", "state", "stateMayCancel"]
      }
    })

    this.setInstance({
      currentUser: useCurrentUser(),
      schoolClassCheckIn,
      schoolClassCheckInId,
      t
    })
  }

  render() {
    const {currentUser, schoolClassCheckIn} = this.tt

    return (
      <LayoutBase dataSet={{route: "user/school-class-check-ins/lobby"}} headerTitle={CheckIn.modelName().human()} layout="user">
        {!currentUser &&
          <UsersSignIn />
        }
        {schoolClassCheckIn &&
          <div className="lobby-outer-container">
            <div className="lobby-container grid grid-cols-2">
              <div className="check-in-info col-span-2 col-span-lg-1">
                <div>
                  <div className="check-in-begins-container">
                    {this.t(".check_in_begins")}
                  </div>
                  {schoolClassCheckIn &&
                    <img className="qr-code-image" src={schoolClassCheckIn.qrcodeUrlPath()} />
                  }
                  <div className="enter-code-text">
                    {this.t(".go_to_play_wooftech_and_enter_this_code", {play_domain: digg(env, "PLAY_DOMAIN")})}
                  </div>
                  <div className="check-in-url-container">
                    {schoolClassCheckIn?.code()}
                  </div>
                </div>
              </div>
              <div className="check-in-status col-span-2 col-span-lg-1">
                <div>
                  <div className="check-in-done-count-container">
                    {schoolClassCheckIn.completedTeamSurveysCount()}
                  </div>
                  <div className="is-done-container">
                    {(schoolClassCheckIn.completedTeamSurveysCount() == 0 || schoolClassCheckIn.completedTeamSurveysCount() >= 2) &&
                      this.t(".are_done")
                    }
                    {schoolClassCheckIn.completedTeamSurveysCount() == 1 &&
                      this.t(".is_done")
                    }
                  </div>
                  <div className="close-woof-and-view-results-button-container">
                    {(() => {
                      if (schoolClassCheckIn.completedTeamSurveysCount() >= 4) {
                        return <Button
                          className="close-woof-and-view-results-button"
                          label={this.t(".close_woof_and_view_results")}
                          onClick={this.tt.closeWoofAndViewResultsClicked}
                          primary
                          white
                        />
                      } else {
                        return (
                          <>
                            <div>
                              {this.t(".not_enough_answers_to_view_results")}
                            </div>
                            {schoolClassCheckIn.can("stateEventCancel") && schoolClassCheckIn.stateMayCancel() &&
                              <a className="cancel-check-in-button" href="#" onClick={this.tt.onCancelCheckInClicked}>
                                {this.t(".click_here_to_cancel_woof")}
                              </a>
                            }
                          </>
                        )
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </LayoutBase>
    )
  }

  closeWoofAndViewResultsClicked = async (e) => {
    e.preventDefault()

    const {schoolClassCheckIn, schoolClassCheckInId, t} = this.tt
    const insightPath = Routes.userSchoolClassSchoolClassCheckInInsightsPath(schoolClassCheckIn.schoolClassId(), schoolClassCheckIn.id(), {
      team_surveys_q: {school_class_check_in_id_eq: schoolClassCheckInId}
    })

    if (schoolClassCheckIn.state() != "closed") {
      try {
        await User.stateEvent({
          model: schoolClassCheckIn,
          state_event: "close"
        })
        FlashMessage.success(t("js.components.school_class_check_ins.close_button.the_woof_was_closed"))
      } catch (error) {
        FlashMessage.errorResponse(error)
      }
    }

    AppHistory.push(insightPath)
  }

  onCancelCheckInClicked = async (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    const {schoolClassCheckIn} = this.tt

    try {
      await User.stateEvent({
        model: schoolClassCheckIn,
        state_event: "cancel"
      })
      FlashMessage.success(this.t("js.components.school_class_check_ins.close_button.the_woof_was_cancelled"))
      AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(schoolClassCheckIn.schoolClassId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
