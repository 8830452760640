import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplateTableSearch} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNewsletterTemplateTableSearchsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--notification-type-actions--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={NewsletterTemplateTableSearch}
        select={{NewsletterTemplateTableSearch: ["newsletterTemplateId"]}}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      path: ["newsletterTemplate"],
      sortKey: "newsletterTemplateCurrentTranslationName"
    },
    {
      attribute: "name",
      path: ["tableSearch"],
      sortKey: "tableSearchName"
    }
  ]

  editModelPath = ({newsletterTemplateTableSearch}) => {
    const {adminLayout} = this.p

    if (adminLayout) {
      return Routes.editAdminNewsletterTemplateTableSearchPath(newsletterTemplateTableSearch.newsletterTemplateId(), newsletterTemplateTableSearch.id())
    }
  }
}))
