import {CheckIn, ClassStep} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import Checkboxes from "components/inputs/checkboxes"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SurveysSelectWithContainer} from "components/surveys/select"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedCheckInAttributes = ["id", "name", "public", "surveyId"]

for (const locale of Locales.availableLocales()) {
  selectedCheckInAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminCheckInsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.check_ins.edit"})
    const params = useParams()
    const {checkIn, checkInId} = useModel(CheckIn, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["check_in_class_steps"],
      select: {
        CheckIn: selectedCheckInAttributes,
        CheckInClassStep: ["class_step_id"]
      }
    })

    this.useStates({classStepsCollection: undefined})
    this.setInstance({checkIn, checkInId, t})

    useMemo(() => {
      this.loadClassSteps()
    }, [])

    useAdminLayout()?.setState({active: "check-ins", headerTitle: this.headerTitle()})
  }

  async loadClassSteps() {
    const classSteps = await ClassStep
      .ransack({s: "position"})
      .select({ClassStep: ["id", "name"]})
      .toArray()

    this.setState({
      classStepsCollection: classSteps.map((classStep) => [classStep.name(), classStep.id()])
    })
  }

  render() {
    const {checkIn} = this.tt

    return (
      <View dataSet={{route: "admin/check-ins/edit"}}>
        {checkIn &&
          <form onSubmit={this.tt.onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${CheckIn.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={checkIn}
                />
              </React.Fragment>
            )}
            {this.s.classStepsCollection &&
              <Checkboxes
                defaultValue={checkIn.isPersisted() ? checkIn.checkInClassSteps().map((checkInClassStep) => checkInClassStep.classStepId()) : null}
                label={ClassStep.modelName().human({count: 2})}
                name="check_in[class_step_ids]"
                options={this.s.classStepsCollection}
              />
            }
            <SurveysSelectWithContainer attribute="surveyId" label={CheckIn.humanAttributeName("survey")} model={checkIn} />
            <Checkbox attribute="public" model={checkIn} />
            <Button primary save />
          </form>
        }
      </View>
    )
  }

  headerTitle() {
    const {checkIn} = this.tt

    if (checkIn?.isNewRecord()) {
      return this.t(".add_new_check_in")
    } else if (checkIn?.isPersisted()) {
      return this.t(".edit_check_in", {name: checkIn.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {checkIn} = this.tt

    if (checkIn.isNewRecord()) formData.append("check_in[id]", checkIn.id())

    try {
      await checkIn.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_check_in_was_saved"))
      AppHistory.push(Routes.adminCheckInPath(checkIn.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
