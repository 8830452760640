import {ContactRelationship, SchoolClass} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import ContactRelationshipsTable from "components/contact-relationships/table"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassSchoolClassRoles extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_classes.school_class_roles"})
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {match: this.p.match})
    const canCan = useCanCan(() => [[ContactRelationship, ["create"]]])
    const schoolClassRolesQuery = useMemo(
      () => ContactRelationship.ransack({parent_school_class_id_eq: schoolClassId}),
      [schoolClassId]
    )

    this.setInstance({canCan, schoolClass, schoolClassId, schoolClassRolesQuery, t})
    useAdminLayout().setState({actions: this.actions(), active: "school-classes", headerTitle: SchoolClass.humanAttributeName("schoolClassRoles")})
  }

  render() {
    const {schoolClass} = this.tt

    return (
      <View dataSet={{route: "admin--school-classes--school-class-roles"}}>
        {schoolClass &&
          <Nav active="school-class-roles" schoolClass={schoolClass} style={{marginBottom: 15}} />
        }
        <ContactRelationshipsTable adminLayout collection={this.tt.schoolClassRolesQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, schoolClass, t} = this.tt

    return (
      <>
        {canCan?.can("create", ContactRelationship) && schoolClass &&
          <Button
            className="new-school-class-role-button"
            icon="plus"
            label={t(".new_role_on_class")}
            to={Routes.newAdminContactRelationshipPath({contact_relationship: {parent_id: schoolClass.contactId()}})}
          />
        }
      </>
    )
  }
}))
