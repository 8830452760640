import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {Subscription, SubscriptionPeriod} from "models"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import PlanLink from "components/plans/link"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SubscriptionLink from "components/subscriptions/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSubscriptionPeriodsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.subscription_periods.table"})

    this.t = t
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--subscription-periods--table", className)}
        columns={columns}
        defaultParams={{s: "starts_at desc"}}
        editModelPath={editModelPath}
        modelClass={SubscriptionPeriod}
        preloads={["subscription.plan"]}
        select={{
          Plan: ["id", "name"],
          Subscription: ["id", "resource"],
          SubscriptionPeriod: ["id"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      content: this.tt.subscriptionContent,
      label: SubscriptionPeriod.humanAttributeName("subscription"),
      path: ["subscription"],
      sortKey: "subscriptionId"
    },
    {
      content: this.tt.resourceContent,
      identifier: "resource",
      label: Subscription.humanAttributeName("resource")
    },
    {
      attribute: "name",
      content: this.tt.planContent,
      label: Subscription.humanAttributeName("plan"),
      path: ["subscription", "plan"],
      sortKey: "subscriptionPlanCurrentTranslationName"
    },
    {
      attribute: "startsAt",
      sortKey: "startsAt"
    },
    {
      attribute: "endsAt",
      sortKey: "endsAt"
    },
    {
      attribute: "createdAt",
      defaultVisible: false,
      sortKey: "createdAt"
    },
    {
      attribute: "updatedAt",
      defaultVisible: false,
      sortKey: "updatedAt"
    }
  ]

  editModelPath = ({subscriptionPeriod}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSubscriptionPeriodPath(subscriptionPeriod.id())
  }

  viewModelPath = ({subscriptionPeriod}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSubscriptionPeriodPath(subscriptionPeriod.id())
  }

  planContent = ({subscriptionPeriod}) => subscriptionPeriod.subscription()?.plan() &&
    <PlanLink plan={subscriptionPeriod.subscription().plan()} {...linkProps(this.props)} />

  resourceContent = ({subscriptionPeriod}) => subscriptionPeriod.subscription()?.resource() &&
    <ModelLink model={subscriptionPeriod.subscription().resource()} {...linkProps(this.props)} />

  subscriptionContent = ({subscriptionPeriod}) => subscriptionPeriod.subscription() &&
    <SubscriptionLink children={subscriptionPeriod.subscription().id()} subscription={subscriptionPeriod.subscription()} {...linkProps(this.props)} />
}))
