import React, {useMemo} from "react"
import {SchoolClass, Subscription} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import DateHeader from "components/user/dashboard/date-header"
import isUserA from "components/users/is-a"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PlansSignUpModal from "components/plans/sign-up-modal"
import Routes from "shared/routes"
import SchoolClassCard from "components/school-classes/card"
import setLayout from "shared/set-layout"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import useCreatedEvent from "@kaspernj/api-maker/build/use-created-event"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import WeekBar from "components/user/dashboard/week-bar"
import WelcomeMessage from "components/user/dashboard/welcome-message"

export default memo(shapeComponent(class RoutesUserDashboard extends ShapeComponent {
  setup() {
    setLayout("user")

    const {t} = useI18n({namespace: "js.routes.user.dashboard"})
    const currentUser = useCurrentUser()

    this.useStates({
      changeSubscriptionForSchoolClass: undefined,
      lastUpdate: new Date()
    })

    const mySchoolClassesQuery = useMemo(() => {
      return SchoolClass.ransack({
        with_contact_id: currentUser?.contactId() || "no-user-uuid"
      })
    }, [currentUser?.id()])

    const {models} = useCollection({
      abilities: {
        SchoolClass: ["adminDestroy", "edit", "invite"]
      },
      collection: mySchoolClassesQuery,
      groupBy: ["id"],
      modelClass: SchoolClass,
      preloads: ["active_subscriptions.plan", "school.account", "school.country", "school.active_subscriptions.plan", "teachers"],
      select: {
        Account: ["id"],
        Country: ["countryCode"],
        Plan: ["identifier", "name"],
        School: ["id", "name"],
        SchoolClass: ["id", "interpretedName"],
        Subscription: ["id"],
        User: ["id", "initials", "name"]
      }
    }, [currentUser?.id(), this.s.lastUpdate])

    useCreatedEvent(Subscription, this.tt.onSubscriptionCreated)

    this.setInstance({
      currentUser,
      schoolClasses: models,
      t
    })

    useFrontLayout()?.setState({active: "user-dashboard", headerTitle: t(".dashboard")})
  }

  render() {
    const {currentUser, schoolClasses, t} = this.tt
    const {changeSubscriptionForSchoolClass} = this.s

    return (
      <div className="routes--user--dashboard">
        {changeSubscriptionForSchoolClass &&
          <PlansSignUpModal onRequestClose={this.tt.onRequestClosePlansSignUpModal} schoolClass={changeSubscriptionForSchoolClass} />
        }
        <div className="greetings-container">
          <DateHeader />
          <WelcomeMessage />
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <WeekBar />
        </div>
        <div className="explainer-container" dangerouslySetInnerHTML={{__html: t(".this_is_your_dashboard")}} />
        <div className="grid grid-cols-md-2 grid-cols-lg-3 grid-gap-10">
          {schoolClasses?.map((schoolClass) =>
            <SchoolClassCard
              className="col-span-1"
              key={schoolClass.id()}
              onChangeSubscriptionPressed={this.tt.onChangeSubscriptionPressed}
              schoolClass={schoolClass}
            />
          )}
          {isUserA(currentUser, "teacher") &&
            <Link className="col-span-1 new-school-class-card" to={Routes.newUserSchoolClassPath()}>
              <div className="icon-container">
                <i className="fa fa-plus" />
              </div>
              <div className="new-school-class-label">
                {t(".create_new_school_class")}
              </div>
            </Link>
          }
        </div>
      </div>
    )
  }

  onChangeSubscriptionPressed = ({schoolClass}) => {
    this.setState({changeSubscriptionForSchoolClass: schoolClass})
  }

  onRequestClosePlansSignUpModal = () => {
    this.setState({changeSubscriptionForSchoolClass: undefined})
  }

  onSubscriptionCreated = ({model: subscription}) => {
    const schoolClassWithSubscription = this.tt.schoolClasses?.find((schoolClass) =>
      (subscription.resourceType() == "SchoolClass" && subscription.resourceId() == schoolClass.id()) ||
        (subscription.resourceType() == "School" && subscription.resourceId() == schoolClass.schoolId())
    )

    if (schoolClassWithSubscription || this.currentUser?.id() == subscription.resourceId()) {
      this.setState({lastUpdate: new Date()})
    }
  }
}))
