import {Course, CourseModule, SchoolClass, SchoolClassCourse, TeamSurvey, TeamSurveyStep} from "models"
import {digg, digs} from "diggerize"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Card from "components/card"
import CkeditorPresentation from "ckeditor/presentation"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserSchoolClassCoursesShowCourseModuleBox extends ShapeComponent {
  static defaultProps = {
    schoolClassCourse: null
  }

  static propTypes = {
    className: PropTypes.string,
    course: PropTypes.instanceOf(Course).isRequired,
    courseModule: PropTypes.instanceOf(CourseModule).isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    schoolClassCourse: PropTypes.instanceOf(SchoolClassCourse)
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.user.school_class_courses.show.course_module_box"})

    this.setInstance({t})
  }

  render() {
    const {onContinueModuleClicked, onGoThroughModuleAgainClicked, onPreviewModuleClicked, onStartModuleClicked, t} = this.tt
    const {className, course, courseModule, schoolClass, schoolClassCourse, ...restProps} = this.props
    const activeSchoolClassCourseModule = this.activeSchoolClassCourseModuleForCourseModule(courseModule)
    const completedSchoolClassModule = this.completedSchoolClassModuleForCourseModule(courseModule)
    const buttonDisabled = this.isPreviousModuleIncomplete()

    return (
      <Card
        className={classNames("components--user--school-class-courses--show--course-module-box", className)}
        data-course-module-id={courseModule.id()}
        {...restProps}
      >
        <div className="course-module-flex-container">
          <div className="course-module-name">
            {courseModule.hasName() ? courseModule.name() : courseModule.id()}
          </div>
          {courseModule.expectedMinutes() !== null &&
            <div className="course-module-expected-minutes">
              <i className="fa fa-clock" />
              {courseModule.expectedMinutes() == 1 &&
                t(".amount_of_minutes_singular", {minutes: courseModule.expectedMinutes()})
              }
              {courseModule.expectedMinutes() > 1 &&
                t(".amount_of_minutes_plural", {minutes: courseModule.expectedMinutes()})
              }
            </div>
          }
          {courseModule.hasAppetizer() &&
            <CkeditorPresentation className="course-module-appetizer" html={courseModule.appetizer()} />
          }
          {completedSchoolClassModule &&
            <div className="course-module-completed-icon-container">
              <i className="fa fa-check" />
            </div>
          }
          <div className="course-module-actions">
            {(() => {
              if (activeSchoolClassCourseModule) {
                return (
                  <a className="continue-module-link" href="#" onClick={onContinueModuleClicked}>
                    {t(".continue_module")}
                  </a>
                )
              } else if (completedSchoolClassModule) {
                return (
                  <a className="go-through-the-module-again-link" href="#" onClick={onGoThroughModuleAgainClicked}>
                    {t(".go_through_the_module_again")}
                  </a>
                )
              } else {
                return (
                  <>
                    <Button
                      className="mb-4 start-module-button w-100"
                      data-course-module-id={courseModule.id()}
                      disabled={buttonDisabled}
                      label={t(".start_module")}
                      onClick={buttonDisabled ? undefined : onStartModuleClicked}
                      primary
                    />
                    <a className="preview-module-link" href="#" onClick={onPreviewModuleClicked}>
                      {t(".view_content_before_start")}
                    </a>
                  </>
                )
              }
            })()}
          </div>
        </div>
      </Card>
    )
  }

  activeSchoolClassCourseModuleForCourseModule(courseModule) {
    return this.p.schoolClassCourse?.schoolClassCourseModules()?.loaded()?.find((schoolClassCourseModule) =>
      schoolClassCourseModule.courseModuleId() == courseModule.id() &&
        schoolClassCourseModule.state() != "completed" &&
        !schoolClassCourseModule.teamSurvey().preview()
    )
  }

  completedSchoolClassModuleForCourseModule(courseModule) {
    return this.p.schoolClassCourse?.schoolClassCourseModules()?.loaded()?.find((schoolClassCourseModule) =>
      schoolClassCourseModule.courseModuleId() == courseModule.id() &&
        schoolClassCourseModule.state() == "completed" &&
        !schoolClassCourseModule.teamSurvey().preview()
    )
  }

  isPreviousModuleIncomplete() {
    const {course, courseModule} = this.p
    const previousCourseModules = course.courseModules().loaded().filter((anotherCourseModule) => anotherCourseModule.position() < courseModule.position())
    const incompleteCourseModule = previousCourseModules.find((anotherCourseModule) => !this.completedSchoolClassModuleForCourseModule(anotherCourseModule))

    if (incompleteCourseModule) return true
  }

  onContinueModuleClicked = async (e) => {
    e.preventDefault()

    const {courseModule} = this.p
    const activeSchoolClassCourseModule = this.activeSchoolClassCourseModuleForCourseModule(courseModule)
    const teamSurvey = activeSchoolClassCourseModule.teamSurvey()
    const orderedSurveySteps = teamSurvey
      .survey()
      .steps()
      .loaded()
      .sort((surveyStep1, surveyStep2) => surveyStep1.position() - surveyStep2.position())
    const surveyStep = orderedSurveySteps[0]
    const findOrCreateTeamSurveyStepResponse = await TeamSurvey.findOrCreateTeamSurveyStep({survey_step_id: surveyStep.id(), team_survey_id: teamSurvey.id()})
    const teamSurveyStep = digg(findOrCreateTeamSurveyStepResponse, "team_survey_step")

    AppHistory.push(Routes.userSchoolClassCourseModuleStepPath(activeSchoolClassCourseModule.id(), teamSurveyStep.id()))
  }

  onGoThroughModuleAgainClicked = async (e) => {
    e.preventDefault()

    const completedSchoolClassModule = this.completedSchoolClassModuleForCourseModule(this.p.courseModule)
    const firstTeamSurveyStep = await TeamSurveyStep
      .ransack({team_survey_id_eq: completedSchoolClassModule.teamSurvey().id(), s: "position asc"})
      .select({TeamSurveyStep: ["id"]})
      .first()

    FlashMessage.success(this.t(".the_module_was_started"))
    AppHistory.push(Routes.userSchoolClassCourseModuleStepPath(completedSchoolClassModule.id(), firstTeamSurveyStep.id()))
  }

  onPreviewModuleClicked = (e) => {
    e.preventDefault()
    this.startModule("preview")
  }

  onStartModuleClicked = (e) => {
    e.preventDefault()
    this.startModule()
  }

  async startModule(type) {
    const {courseModule} = this.p
    let schoolClassCourse = this.p.schoolClassCourse

    try {
      if (!this.p.schoolClassCourse) {
        const result = await this.p.course.take({school_class_id: this.p.schoolClass.id(), type})

        FlashMessage.success(this.t("js.routes.user.courses.show.the_class_have_started_the_course"))

        schoolClassCourse = digg(result, "school_class_course")
      }

      const result = await schoolClassCourse.startModule({course_module_id: courseModule.id(), type})
      const {school_class_course_module: schoolClassCourseModule, team_survey: teamSurvey} = digs(result, "school_class_course_module", "team_survey")
      const currentStepResult = await teamSurvey.currentStep()
      const {team_survey_step: teamSurveyStep} = digs(currentStepResult, "team_survey_step")

      if (!teamSurveyStep) throw new Error("No 'teamSurveyStep' given from 'currentStep'")

      FlashMessage.success(this.t(".the_module_was_started"))
      AppHistory.push(Routes.userSchoolClassCourseModuleStepPath(schoolClassCourseModule.id(), teamSurveyStep.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
