import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Translation} from "models"
import useDestroyedEvent from "@kaspernj/api-maker/build/use-destroyed-event"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
export default memo(shapeComponent(class RoutesAdminTranslationsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.translations.show"})
    const {translation, translationId} = useModel(Translation, {
      abilities: {
        Translation: ["destroy", "edit"]
      },
      match: this.p.match,
      select: {
        Translation: ["approved", "createdAt", "id", "key", "madeByAi", "locale", "updatedAt", "value"]
      }
    })

    this.setInstance({translation, translationId, t})
    useDestroyedEvent(translation, this.tt.onTranslationDestroyed)
  }

  render() {
    const {translation} = this.tt

    return (
      <AdminLayout actions={this.actions()} active="translations" className="routes--admin--translations--show" headerTitle={translation?.key()}>
        {translation &&
          <AttributeRows attributes={["id", "locale", "key", "value", "approved", "madeByAi", "createdAt", "updatedAt"]} model={translation} />
        }
      </AdminLayout>
    )
  }

  actions() {
    return (
      <AdminShowActions model={this.tt.translation} />
    )
  }

  onTranslationDestroyed = () => {
    FlashMessage.success(this.t(".the_translation_was_deleted"))
    AppHistory.push(Routes.adminTranslationsPath())
  }
}))
