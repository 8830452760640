import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import DestroyAction from "components/actions/destroy"
import {digg} from "diggerize"
import EditAction from "components/actions/edit"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import Routes from "shared/routes"
import SuperAdminAction from "components/actions/super-admin"
export default memo(shapeComponent(class ComponentsAdminShowActions extends ShapeComponent {
  static propTypes = propTypesExact({
    editPath: PropTypes.string,
    model: PropTypes.object
  })

  render() {
    const {model} = this.p

    return (
      <>
        {model?.can("edit") && this.showEditButton() &&
          <EditAction to={this.editPath()} />
        }
        {model?.can("destroy") &&
          <DestroyAction model={model} />
        }
        {model &&
          <SuperAdminAction to={this.superAdminPath()} />
        }
      </>
    )
  }

  editRouteMethod = () => Routes[`editAdmin${digg(this.p.model.modelClassData(), "name")}Path`]
  editPath = () => this.props.editPath || this.editRouteMethod()(this.p.model.id())

  showEditButton = () => {
    if (this.props.editPath) return true
    if (this.editRouteMethod()) return true
  }

  superAdminPath = () => Routes.superAdminPath({model: digg(this.p.model.modelClassData(), "name"), model_id: this.p.model.id()})
}))
