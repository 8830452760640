import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import {Plan} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsPlansLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    plan: PropTypes.instanceOf(Plan).isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, plan, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--plans--link", className)

    if (adminLayout) {
      return (
        <Link className={generatedClassName} to={this.to()} {...restProps}>
          {children || plan.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || plan.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, plan} = this.p

    if (adminLayout) {
      return Routes.adminPlanPath(plan.id())
    }

    return "#"
  }
}))
