import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import {CourseGroup} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"
const selectedCourseGroupAttributes = ["id", "identifier", "name"]

for (const locale of Locales.availableLocales()) {
  selectedCourseGroupAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminCourseGroupsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.course_groups.edit"})
    const params = useParams()
    const {courseGroup, courseGroupId} = useModel(CourseGroup, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        CourseGroup: selectedCourseGroupAttributes
      }
    })

    this.setInstance({courseGroup, courseGroupId, t})
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "course-groups", headerTitle: this.headerTitle()})
  }

  render() {
    const {courseGroup, onSubmit} = this.tt

    return (
      <View dataSet={{route: "admin--course-groups--edit"}}>
        {courseGroup &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  containerProps={{style: {marginBottom: 15}}}
                  label={`${CourseGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={courseGroup}
                />
              </React.Fragment>
            )}
            <AdminInput attribute="identifier" containerProps={{style: {marginBottom: 15}}} model={courseGroup} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {courseGroup, t} = this.tt

    if (courseGroup?.isNewRecord()) {
      return t(".add_new_course_group")
    } else if (courseGroup?.isPersisted()) {
      return t(".edit_course_group", {name: courseGroup.name()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {courseGroup, t} = this.tt

    if (courseGroup.isNewRecord()) {
      formData.course_group.id = courseGroup.id()
    }

    try {
      await courseGroup.saveRaw(formData)
      FlashMessage.success(t(".the_course_group_was_saved"))
      AppHistory.push(Routes.adminCourseGroupPath(courseGroup.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
