import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectCourses} from "components/courses/select"
import {AdminSelectSurveys} from "components/surveys/select"
import AppHistory from "shared/history"
import {CourseModule} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedCourseModuleAttributes = ["courseId", "expectedMinutes", "id", "name", "position", "surveyId"]
  .concat(translatedAttributes(["appetizer", "name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminCourseModulesEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.course_modules.edit"})
    const params = useParams()
    const {courseModule, courseModuleId} = useModel(CourseModule, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        CourseModule: selectedCourseModuleAttributes
      }
    })

    this.setInstance({courseModule, courseModuleId, t})
    this.useStates({form: null})

    useAdminLayout()?.setState({active: "course-modules", headerTitle: this.headerTitle()})
  }

  render() {
    const {courseModule, onSubmit} = this.tt

    return (
      <View dataSet={{route: "admin--course-modules--edit"}}>
        {courseModule &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  containerProps={{style: {marginBottom: 15}}}
                  label={`${CourseModule.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={courseModule}
                />
                <View style={{marginBottom: 15}}>
                  <Input
                    attribute={`appetizer${inflection.camelize(locale)}`}
                    label={`${CourseModule.humanAttributeName("appetizer")} (${Locales.labelForLocale(locale)})`}
                    model={courseModule}
                    type="ckeditor"
                  />
                </View>
              </React.Fragment>
            )}
            <AdminInput
              attribute="expectedMinutes"
              containerProps={{style: {marginBottom: 15}}}
              model={courseModule}
            />
            <AdminInput
              attribute="position"
              containerProps={{style: {marginBottom: 15}}}
              model={courseModule}
            />
            <View style={{marginBottom: 15}}>
              <AdminSelectCourses attribute="courseId" label={CourseModule.humanAttributeName("course")} model={courseModule} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectSurveys attribute="surveyId" label={CourseModule.humanAttributeName("survey")} model={courseModule} />
            </View>
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {courseModule, t} = this.tt

    if (courseModule?.isNewRecord()) {
      return t(".add_new_course_module")
    } else if (courseModule?.isPersisted()) {
      return t(".edit_course_module", {name: courseModule.name()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {courseModule, t} = this.tt

    if (courseModule.isNewRecord()) {
      formData.course_module.id = courseModule.id()
    }

    try {
      await courseModule.saveRaw(formData)
      FlashMessage.success(t(".the_course_module_was_saved"))
      AppHistory.push(Routes.adminCourseModulePath(courseModule.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
