import {SchoolClass, SchoolClassCourse} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
export default memo(shapeComponent(class RoutesUserSchoolClassSchoolClassCoursesIndex extends ShapeComponent {
  setup() {
    setLayout("user")

    const {t} = useI18n({namespace: "js.routes.user.school_class_courses.show"})
    const params = useParams()
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {
      match: {params},
      select: {
        SchoolClass: ["id", "schoolId"]
      }
    })
    const {schoolClassCourses} = useCollection({
      modelClass: SchoolClassCourse,
      preloads: ["course"],
      select: {
        Course: ["name"]
      }
    })

    this.setInstance({schoolClass, schoolClassId, schoolClassCourses, t})
    useFrontLayout()?.setState({
      headerTitle: SchoolClassCourse.modelName().human({count: 2}),
      headerTitleCentered: true,
      topLeft: this.topLeft()
    })
  }

  render() {
    const {schoolClassCourses} = this.tt

    return (
      <div className="routes--user--school-class--school-class-courses--index">
        <table>
          <tbody>
            {schoolClassCourses?.map((schoolClassCourse) =>
              <tr key={schoolClassCourse.id()}>
                <td>
                  <Link to={Routes.userCoursePath(schoolClassCourse.courseId(), {school_class_corse_id: schoolClassCourse.id()})}>
                    {schoolClassCourse.course().name()}
                  </Link>
                </td>
                <td>
                  {schoolClassCourse.translatedState()}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  topLeft() {
    const {schoolClassId} = this.tt

    return (
      <Link className="text-white" to={Routes.userSchoolClassDigitalClassRoomPath(schoolClassId)}>
        <i className="fa fa-chevron-left mr-1" />
        {this.t(".go_to_the_space_of_the_class")}
      </Link>
    )
  }
}))
