import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSurveysPageContainer extends ShapeComponent {
  setup() {
    this.breakpoint = useBreakpoint()
  }

  render() {
    const {className, ...restProps} = this.props
    const {breakpoint} = this.tt
    const style = {
      flexShrink: 1
    }

    if (breakpoint.xlUp) {
      style.minWidth = "1300px"
      style.width = "min-content"
    } else if (breakpoint.lgUp) {
      style.minWidth = "850px"
      style.width = "min-content"
    } else {
      style.width = "100%"
    }

    return (
      <View dataSet={{class: className, component: "surveys/page-container"}} style={style} {...restProps} />
    )
  }
}))
