import {useEffect} from "react"
const useGlobalClick = (callback, args) => {
  useEffect(() => {
    window.addEventListener("mouseup", callback)

    return () => {
      window.removeEventListener("mouseup", callback)
    }
  }, args)
}

export default useGlobalClick
