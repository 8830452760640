import {Course, CourseModule} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import CourseModulesTable from "components/course-modules/table"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCoursesCourseModules extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.courses.course_modules"})
    const params = useParams()
    const {course, courseId} = useModel(Course, {
      match: {params},
      select: {
        Course: ["courseModulesCount", "id"]
      }
    })

    this.setInstance({
      canCan: useCanCan(() => [[CourseModule, ["create"]]]),
      course,
      courseId,
      courseModulesQuery: useMemo(
        () => CourseModule.ransack({
          course_id_eq: courseId
        }),
        [courseId]
      ),
      t
    })
    useAdminLayout()?.setState({actions: this.actions(), active: "courses", headerTitle: Course.humanAttributeName("courseModules")})
  }

  render() {
    const {course} = this.tt

    return (
      <View dataSet={{route: "admin--courses--course-modules"}}>
        {course &&
          <Nav active="course-modules" course={course} style={{marginBottom: 15}} />
        }
        <CourseModulesTable
          adminLayout
          collection={this.tt.courseModulesQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, courseId} = this.tt

    return (
      <>
        {canCan?.can("create", CourseModule) &&
          <Button
            className="new-course-module-button"
            icon="plus"
            label={this.t(".new_course_module")}
            to={Routes.newAdminCourseModulePath({course_module: {course_id: courseId}})}
          />
        }
      </>
    )
  }
}))
