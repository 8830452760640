import React, {useMemo} from "react"
import {ScoreFactorGroup, ScoreFactorGroupScoreFactor} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/score-factor-groups/nav"
import Routes from "shared/routes"
import ScoreFactorGroupScoreFactorsTable from "components/score-factor-group-score-factors/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminScoreFactorGroupScoreFactors extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.score_factor_groups_score_factors"})
    const {scoreFactorGroup, scoreFactorGroupId} = useModel(ScoreFactorGroup, {match: this.props.match})

    this.canCan = useCanCan(() => [[ScoreFactorGroupScoreFactor, ["create"]]])
    this.scoreFactorGroupScoreFactorsQuery = useMemo(
      () => ScoreFactorGroupScoreFactor.ransack({
        score_factor_group_id_eq: scoreFactorGroupId
      }),
      [scoreFactorGroupId]
    )

    this.setInstance({scoreFactorGroup, scoreFactorGroupId, t})
  }

  render() {
    const {scoreFactorGroup} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="score-factor-groups"
        className="routes--admin--score-factor-groups--score-factors"
        headerTitle={ScoreFactorGroup.humanAttributeName("scoreFactors")}
      >
        {scoreFactorGroup &&
          <Nav active="score-factors" className="mb-4" scoreFactorGroup={scoreFactorGroup} />
        }
        <ScoreFactorGroupScoreFactorsTable
          adminLayout
          collection={this.t.scoreFactorGroupScoreFactorsQuery}
        />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, scoreFactorGroupId, t} = this.tt

    return (
      <>
        {canCan?.can("create", ScoreFactorGroupScoreFactor) &&
          <Button
            className="new-score-factor-group-score-factor-button"
            icon="plus"
            label={t(".new_score_factor")}
            to={Routes.newAdminScoreFactorGroupScoreFactorPath({score_factor_group_score_factor: {score_factor_group_id: scoreFactorGroupId}})}
          />
        }
      </>
    )
  }
}))
