import React, {useCallback} from "react"
import {CountriesSelectWithContainer} from "components/countries/select"
import {School} from "models"

export default {
  edit: {
    attributes: [
      {attribute: "name", translated: true},
      {attribute: "email"},
      {attribute: "principalName"},
      {
        attribute: "countryId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [onChangeValue])

          return (
            <CountriesSelectWithContainer
              label={School.humanAttributeName("country")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      }
    ]
  },
  table: {
    columns: () => [
      {
        attribute: "name",
        sortKey: "currentTranslationName"
      },
      {
        attribute: "schoolClassesCount",
        sortKey: "schoolClassesCount"
      },
      {
        attribute: "institutionNumber",
        sortKey: "institutionNumber"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      }
    ]
  }
}
