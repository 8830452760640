import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNavSegmented from "components/admin/nav-segmented"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminSchoolClassesNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.school_classes.nav"})
    const {schoolClass} = this.p

    this.t = t
    this.buttons = useMemo(
      () => [
        {
          value: "general",
          label: t("js.shared.general"),
          to: Routes.adminSchoolClassPath(schoolClass.id())
        },
        {
          value: "audits",
          label: SchoolClass.humanAttributeName("audits"),
          to: Routes.adminSchoolClassAuditsPath(schoolClass.id())
        },
        {
          value: "school-class-roles",
          label: SchoolClass.humanAttributeName("schoolClassRoles"),
          to: Routes.adminSchoolClassSchoolClassRolesPath(schoolClass.id())
        },
        {
          value: "subscriptions",
          label: SchoolClass.humanAttributeName("subscriptions"),
          to: Routes.adminSchoolClassSubscriptionsPath(schoolClass.id())
        },
        {
          value: "unicorn-effects",
          label: t(".unicorn_effects"),
          to: Routes.adminSchoolClassUnicornEffectsPath(schoolClass.id())
        }
      ],
      [locale]
    )
  }

  render() {
    const {buttons} = this.tt
    const {active, className, schoolClass, ...restProps} = this.props

    return (
      <AdminNavSegmented
        active={active}
        buttons={buttons}
        dataSet={{class: classNames("components--admin--school-classes--nav", className)}}
        {...restProps}
      />
    )
  }
}))
