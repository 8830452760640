import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNavSegmented from "components/admin/nav-segmented"
import {Contact} from "models"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminContactsNav extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.string.isRequired,
    contact: PropTypes.instanceOf(Contact).isRequired,
    style: PropTypes.object
  })

  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.contacts.nav"})
    const {contact} = this.p

    this.buttons = useMemo(
      () => [
        {
          value: "general",
          label: t(".general"),
          to: Routes.adminContactPath(contact.id())
        },
        {
          value: "dialogue",
          label: Contact.humanAttributeName("dialogues"),
          to: Routes.adminContactDialoguePath(contact.id())
        },
        {
          value: "emails",
          label: t(".emails"),
          to: Routes.adminContactEmailsPath(contact.id())
        },
        {
          value: "phone-numbers",
          label: t(".phone_numbers"),
          to: Routes.adminContactPhoneNumbersPath(contact.id())
        },
        {
          value: "relationships",
          label: t(".relationships"),
          to: Routes.adminContactRelationshipsPath(contact.id())
        },
        {
          value: "contact-points",
          label: `${Contact.humanAttributeName("contactPoints")} (${contact.contactPointsCount()})`,
          to: Routes.superAdminPath({model: "ContactPoint", contact_points_q: JSON.stringify({contact_id_eq: contact.id()})})
        }
      ],
      [locale]
    )
  }

  render() {
    const {buttons} = this.tt
    const {active, className, contact, ...restProps} = this.props

    return (
      <AdminNavSegmented
        active={active}
        buttons={buttons}
        dataSet={{class: className, component: "admin/contacts/nav"}}
        {...restProps}
      />
    )
  }
}))
