import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminCheckbox from "components/admin/checkbox"
import {AdminSelectCourseGroups} from "components/course-groups/select"
import {AdminSelectCourses} from "components/courses/select"
import {AdminSelectScoreFactors} from "components/score-factors/select"
import AppHistory from "shared/history"
import {CourseGroupCourse} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCourseGroupCoursesEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.course_group_course.edit"})
    const params = useParams()
    const {courseGroupCourse, courseGroupCourseId} = useModel(CourseGroupCourse, {
      match: {params},
      newIfNoId: true,
      select: {
        CourseGroupCourse: ["id", "courseId", "courseGroupId", "highlight", "scoreFactorId"]
      }
    })

    this.setInstance({courseGroupCourse, courseGroupCourseId, t})
    this.useStates({
      form: null,
      highlight: false
    })

    useMemo(() => {
      if (courseGroupCourse) {
        this.setState({highlight: courseGroupCourse.highlight() || false})
      }
    }, [courseGroupCourse?.id()])

    useAdminLayout()?.setState({active: "course-groups", headerTitle: this.headerTitle()})
  }

  render() {
    const {courseGroupCourse} = this.tt

    return (
      <View dataSet={{route: "admin--course-group-courses--edit"}}>
        {courseGroupCourse &&
          <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <AdminSelectCourseGroups attribute="courseGroupId" label={CourseGroupCourse.humanAttributeName("courseGroup")} model={courseGroupCourse} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectCourses attribute="courseId" label={CourseGroupCourse.humanAttributeName("course")} model={courseGroupCourse} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectScoreFactors attribute="scoreFactorId" label={CourseGroupCourse.humanAttributeName("scoreFactor")} model={courseGroupCourse} />
            </View>
            <AdminCheckbox
              checked={this.s.highlight}
              containerProps={{style: {marginBottom: 15}}}
              dataSet={{class: "highlight-checkbox"}}
              label={CourseGroupCourse.humanAttributeName("highlight")}
              onChangeChecked={this.tt.onChangeHightlight}
            />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    if (this.tt.courseGroupCourse?.isNewRecord()) {
      return this.t(".add_course_to_group")
    } else if (this.tt.courseGroupCourse?.isPersisted()) {
      return this.t(".edit_course_in_group")
    }
  }

  onChangeHightlight = (highlight) => this.setState({highlight})

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {courseGroupCourse, t} = this.tt

    formData.course_group_course.highlight = this.s.highlight

    try {
      await courseGroupCourse.saveRaw(formData)
      FlashMessage.success(t(".the_course_group_course_was_saved"))
      AppHistory.push(Routes.adminCourseGroupCourseGroupCoursesPath(courseGroupCourse.courseGroupId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
