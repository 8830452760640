import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import ContentsEditor from "components/contents/editor"
import {EmailTemplate} from "models"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import I18n from "shared/i18n"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const selectedEmailTemplateAttributes = ["id", "name"]
  .concat(translatedAttributes(["name", "subject"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminEmailTemplatesEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.email_templates.edit"})
    const params = useParams()
    const {emailTemplate, emailTemplateId} = useModel(EmailTemplate, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      preload: ["contents.absolute_containers", "contents.text_keys.text_values"],
      select: {
        Content: ["attributeName", "body", "bodyType", "detectedLiquidVariables", "id"],
        ContentAbsoluteContainer: ["id"],
        EmailTemplate: selectedEmailTemplateAttributes,
        TextKey: ["attributeName", "id", "key"],
        TextValue: ["id", "locale", "value", "valueType"]
      }
    })

    this.setInstance({emailTemplate, emailTemplateId, t})
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "email-templates", headerTitle: this.headerTitle()})
  }

  render() {
    const {emailTemplate} = this.tt

    return (
      <View dataSet={{route: "admin/email-templates/edit"}}>
        {emailTemplate &&
          <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${EmailTemplate.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={emailTemplate}
                  style={{marginBottom: 15}}
                />
                <AdminInput
                  attribute={`subject${inflection.camelize(locale)}`}
                  label={`${EmailTemplate.humanAttributeName("subject")} (${Locales.labelForLocale(locale)})`}
                  model={emailTemplate}
                  style={{marginBottom: 15}}
                />
              </React.Fragment>
            )}
            <ContentsEditor
              absoluteContainers={false}
              attributeName="body"
              inputProps={{
                id: "email_template_body",
                label: I18n.t("js.shared.email")
              }}
              name="email_template[contents_attributes][0]"
              preview={false}
              resource={emailTemplate}
            />
            <AdminButton dataSet={{class: "save-email-template-button"}} primary save style={{marginTop: 15}} submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {emailTemplate, t} = this.tt

    if (emailTemplate?.isNewRecord()) {
      return t(".add_new_email_template")
    } else if (emailTemplate?.isPersisted()) {
      return t(".edit_email_template", {name: emailTemplate.name()})
    }
  }

  onSubmit = async () => {
    const {emailTemplate, t} = this.tt
    const formData = this.s.form.asObject()

    if (emailTemplate.isNewRecord()) {
      formData.email_template.new_id = emailTemplate.id()
    }

    try {
      await emailTemplate.saveRaw(formData)
      FlashMessage.success(t(".the_email_template_was_saved"))
      AppHistory.push(Routes.adminEmailTemplatePath(emailTemplate.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
