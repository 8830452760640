import React, {useCallback} from "react"
import {CurrenciesSelectWithContainer} from "components/currencies/select"
import {PlanResourcePeriodPrice} from "models"
import {PlanResourcePeriodsSelectWithContainer} from "components/plan-resource-periods/select"

export default {
  edit: {
    attributes: [
      {
        attribute: "planResourcePeriodId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [onChangeValue])

          return (
            <PlanResourcePeriodsSelectWithContainer
              label={PlanResourcePeriodPrice.humanAttributeName("planResourcePeriod")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      },
      {attribute: "priceCents"},
      {
        attribute: "priceCurrency",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [])

          return (
            <CurrenciesSelectWithContainer
              name={null}
              onChange={onChange}
              valueCallback={({currency}) => currency.currencyCode()}
              {...inputProps}
            />
          )
        }
      }
    ]
  }
}
