import {
  Account, Audit, BrainBreak, Challenge, CheckIn, ClassStep, Contact, ContactRelationshipType, ContentPieceType, Course, CourseGroup, EmailTemplate,
  NewsletterTemplate, NotificationType, Plan, School, SchoolClass, SchoolClassGroup, ScoreFactor, ScoreFactorGroup, Styling, Subscription, Survey, TextKey,
  Translation, User
} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import MenuItem from "components/admin/layout/menu/menu-item"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import Routes from "shared/routes"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

const abilities = () => [
  [Account, ["index"]],
  [Audit, ["index"]],
  [BrainBreak, ["index"]],
  [Challenge, ["index"]],
  [CheckIn, ["index"]],
  [ClassStep, ["index"]],
  [Contact, ["index"]],
  [ContactRelationshipType, ["index"]],
  [ContentPieceType, ["index"]],
  [Course, ["index"]],
  [CourseGroup, ["index"]],
  [EmailTemplate, ["index"]],
  [NewsletterTemplate, ["index"]],
  [NotificationType, ["index"]],
  [Plan, ["index"]],
  [School, ["index"]],
  [SchoolClass, ["index"]],
  [SchoolClassGroup, ["index"]],
  [ScoreFactor, ["index"]],
  [ScoreFactorGroup, ["index"]],
  [Styling, ["index"]],
  [Subscription, ["index"]],
  [Survey, ["index"]],
  [TextKey, ["index"]],
  [Translation, ["index"]],
  [User, ["index"]]
]

export default memo(shapeComponent(class ComponentsAdminLayoutMenuContent extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.string,
    canCan: PropTypes.object
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.admin.layout.menu.menu_content"})

    this.t = t
  }

  render() { // eslint-disable-line complexity
    const {t} = this.tt
    const {active} = this.props
    const currentUser = useCurrentUser()
    const canCan = useCanCan(abilities, [currentUser?.id()])

    return (
      <>
        {canCan?.can("index", Account) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="accounts"
            label={Account.modelName().human({count: 2})}
            to={Routes.adminAccountsPath()}
          />
        }
        {canCan?.can("index", Audit) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="audits"
            label={Audit.modelName().human({count: 2})}
            to={Routes.adminAuditsPath()}
          />
        }
        {canCan?.can("index", BrainBreak) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="brain-breaks"
            label={BrainBreak.modelName().human({count: 2})}
            to={Routes.adminBrainBreaksPath()}
          />
        }
        {canCan?.can("index", Challenge) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="challenges"
            label={Challenge.modelName().human({count: 2})}
            to={Routes.adminChallengesPath()}
          />
        }
        {canCan?.can("index", CheckIn) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="check-ins"
            label={CheckIn.modelName().human({count: 2})}
            to={Routes.adminCheckInsPath()}
          />
        }
        {canCan?.can("index", ClassStep) && !isUserA(currentUser, "sales_rep") &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="class-steps"
            label={ClassStep.modelName().human({count: 2})}
            to={Routes.adminClassStepsPath()}
          />
        }
        {canCan?.can("index", Contact) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="contacts"
            label={Contact.modelName().human({count: 2})}
            to={Routes.adminContactsPath()}
          />
        }
        {canCan?.can("index", ContactRelationshipType) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="contact-relationship-types"
            label={ContactRelationshipType.modelName().human({count: 2})}
            to={Routes.superAdminPath({model: "ContactRelationshipType"})}
          />
        }
        {canCan?.can("index", EmailTemplate) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="email-templates"
            label={EmailTemplate.modelName().human({count: 2})}
            to={Routes.adminEmailTemplatesPath()}
          />
        }
        {canCan?.can("index", User) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="crm"
            label={t(".crm")}
            to={Routes.adminCrmPath()}
          />
        }
        {canCan?.can("index", Styling) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="colors"
            label={Styling.modelName().human({count: 2})}
            to={Routes.adminStylingsPath()}
          />
        }
        {canCan?.can("index", ContentPieceType) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="content-piece-types"
            label={ContentPieceType.modelName().human({count: 2})}
            to={Routes.adminContentPieceTypesPath()}
          />
        }
        {canCan?.can("index", Course) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="courses"
            label={Course.modelName().human({count: 2})}
            to={Routes.adminCoursesPath()}
          />
        }
        {canCan?.can("index", CourseGroup) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="course-groups"
            label={CourseGroup.modelName().human({count: 2})}
            to={Routes.adminCourseGroupsPath()}
          />
        }
        {!isUserA(currentUser, "sales_rep") &&
          <>
            <MenuItem
              active={active}
              icon="sitemap"
              identifier="liquid-drops"
              label={t(".liquid_drops")}
              to={Routes.adminLiquidDropsPath()}
            />
            <MenuItem
              active={active}
              icon="sitemap"
              identifier="liquid-tester"
              label={t(".liquid_tester")}
              to={Routes.adminLiquidTesterPath()}
            />
            <MenuItem
              active={active}
              icon="sitemap"
              identifier="routes-overview"
              label={t(".routes_overview")}
              to={Routes.adminRoutesOverviewPath()}
            />
          </>
        }
        {canCan?.can("index", NewsletterTemplate) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="newsletter-templates"
            label={NewsletterTemplate.modelName().human({count: 2})}
            to={Routes.adminNewsletterTemplatesPath()}
          />
        }
        {canCan?.can("index", NotificationType) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="notification-types"
            label={NotificationType.modelName().human({count: 2})}
            to={Routes.adminNotificationTypesPath()}
          />
        }
        {canCan?.can("index", Plan) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="plans"
            label={Plan.modelName().human({count: 2})}
            to={Routes.adminPlansPath()}
          />
        }
        {canCan?.can("index", School) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="schools"
            label={School.modelName().human({count: 2})}
            to={Routes.adminSchoolsPath()}
          />
        }
        {canCan?.can("index", SchoolClass) &&
          <MenuItem
            active={active}
            icon="sitemap"
            identifier="school-classes"
            label={SchoolClass.modelName().human({count: 2})}
            to={Routes.adminSchoolClassesPath()}
          />
        }
        {canCan?.can("index", SchoolClassGroup) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="school-class-groups"
            label={SchoolClassGroup.modelName().human({count: 2})}
            to={Routes.adminSchoolClassGroupsPath()}
          />
        }
        {canCan?.can("index", ScoreFactor) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="score-factors"
            label={ScoreFactor.modelName().human({count: 2})}
            to={Routes.adminScoreFactorsPath()}
          />
        }
        {canCan?.can("index", ScoreFactorGroup) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="score-factor-groups"
            label={ScoreFactorGroup.modelName().human({count: 2})}
            to={Routes.adminScoreFactorGroupsPath()}
          />
        }
        {canCan?.can("index", Subscription) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="subscriptions"
            label={Subscription.modelName().human({count: 2})}
            to={Routes.adminSubscriptionsPath()}
          />
        }
        {canCan?.can("index", Survey) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="surveys"
            label={Survey.modelName().human({count: 2})}
            to={Routes.adminSurveysPath()}
          />
        }
        {canCan?.can("index", TextKey) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="text-keys"
            label={t(".translate")}
            to={Routes.adminTextKeysTranslatePath()}
          />
        }
        {canCan?.can("index", Translation) &&
          <MenuItem
            active={active}
            icon="list-ol"
            identifier="translations"
            label={Translation.modelName().human({count: 2})}
            to={Routes.adminTranslationsPath()}
          />
        }
        {canCan?.can("index", User) && !isUserA(currentUser, "sales_rep") &&
          <MenuItem
            active={active}
            icon="users"
            identifier="users"
            label={User.modelName().human({count: 2})}
            to={Routes.adminUsersPath()}
          />
        }
      </>
    )
  }
}))
