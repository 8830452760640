import {ContentAbsoluteContainer, SurveyStep, SurveyStepElement} from "models"
import {digg, digs} from "diggerize"
import {FormInputs, useForm} from "@kaspernj/api-maker/build/form"
import React, {useEffect, useRef} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import EventEmitter from "events"
import FlashMessage from "shared/flash-message"
import InputFile from "./input-file"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import TextKeysHandle from "components/text-keys/handle"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"
import useEventListener from "@kaspernj/api-maker/build/use-event-listener"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const ComponentsContentsEditorAbsoluteContainer = memo(shapeComponent(class EditorAbsoluteContainer extends ShapeComponent {
  static propTypes = propTypesExact({
    absoluteContainer: PropTypes.instanceOf(ContentAbsoluteContainer).isRequired,
    cacheKey: PropTypes.string.isRequired,
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    name: PropTypes.string.isRequired,
    nestedAbsoluteContainers: PropTypes.object.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    parent: PropTypes.instanceOf(ContentAbsoluteContainer),
    parentWidth: PropTypes.number.isRequired,
    parentHeight: PropTypes.number.isRequired,
    surveyStep: PropTypes.instanceOf(SurveyStep)
  })

  setup() {
    const {absoluteContainer} = this.p
    const firstRender = useRef(true)

    this.setInstance({
      form: useForm() || new FormInputs(),
      outerRef: useRef()
    })
    this.useStates({
      absoluteContainers: () => this.initialAbsoluteContainers(),
      cacheKey: () => absoluteContainer.fullCacheKey(),
      heightPixels: absoluteContainer.heightPixels(),
      heightPercent: absoluteContainer.heightPercent(),
      leftPixels: absoluteContainer.leftPixels(),
      leftPercent: absoluteContainer.leftPercent(),
      liquidConditions: absoluteContainer.liquidConditions(),
      originalClientX: undefined,
      originalClientY: undefined,
      originalHeight: undefined,
      originalWidth: undefined,
      originalLeft: undefined,
      originalTop: undefined,
      positioning: false,
      previewImageSrc: undefined,
      resizing: false,
      topPixels: absoluteContainer.topPixels(),
      topPercent: absoluteContainer.topPercent(),
      widthPercent: absoluteContainer.widthPercent(),
      widthPixels: absoluteContainer.widthPixels()
    })
    useEffect(() => {
      // Don't update values on first call in order to not change them on each edit because of small changes in the individual browser
      if (firstRender.current) {
        firstRender.current = false
      } else {
        this.updateWidthPixelsAndHeightPixelsFromPercentage()
      }
    }, [this.p.parentHeight, this.p.parentWidth])

    useEventEmitter(this.p.events, "absoluteContainerChanged", this.tt.onAbsoluteContainerChanged)
    useEventListener(window, "mouseup", this.tt.onWindowMouseUp)
    useEventListener(window, "mousemove", this.tt.onWindowMouseMove)
  }

  updateWidthPixelsAndHeightPixelsFromPercentage() {
    const {parentHeight, parentWidth} = this.p
    const {heightPercent, leftPercent, topPercent, widthPercent} = this.s
    const widthPixels = parentWidth * (widthPercent / 100)
    const heightPixels = parentHeight * (heightPercent / 100)
    const leftPixels = parentWidth * (leftPercent / 100)
    const topPixels = parentHeight * (topPercent / 100)

    this.setState({heightPixels, leftPixels, topPixels, widthPixels})
    this.p.absoluteContainer.assignAttributes({heightPixels, leftPixels, topPixels, widthPixels})
  }

  initialAbsoluteContainers() {
    const {absoluteContainer, nestedAbsoluteContainers} = this.p

    if (absoluteContainer.isNewRecord()) return []

    return nestedAbsoluteContainers[absoluteContainer.id()] || []
  }

  render() {
    const {absoluteContainer, name, nestedAbsoluteContainers} = this.p
    const {absoluteContainers, heightPercent, heightPixels, leftPercent, previewImageSrc, topPercent, widthPercent, widthPixels} = this.s
    const style = {overflowX: "auto", overflowY: "scroll"}

    if (previewImageSrc) {
      style.backgroundImage = `url('${previewImageSrc}')`
    } else if (absoluteContainer.hasBackgroundImageUrl()) {
      style.backgroundImage = `url('${absoluteContainer.backgroundImageUrl()}')`
    }

    if (absoluteContainer.styling()?.styling()) {
      for (const key in absoluteContainer.styling().styling()) {
        const value = absoluteContainer.styling().styling()[key]

        style[key] = value
      }
    }

    const requiredAttributes = ["heightPercent", "heightPixels", "id", "leftPercent", "leftPixels", "topPercent", "topPixels", "widthPercent", "widthPixels"]

    for (const requiredAttribute of requiredAttributes) {
      if (!(requiredAttribute in absoluteContainer)) throw new Error(`AbsoluteContainer doesn't respond to ${requiredAttribute}`)

      const value = absoluteContainer[requiredAttribute]()

      if (value === null || value === undefined) throw new Error(`No '${requiredAttribute}' on absolute container ${absoluteContainer.id()}`)
    }

    return (
      <div
        className="components--contents--editor--absolute-container"
        data-absolute-container-id={absoluteContainer.id()}
        data-marked-for-destruction={absoluteContainer.markedForDestruction()}
        onDoubleClick={this.tt.onDoubleClick}
        onMouseDown={this.tt.onAbsoluteContainerMouseDown}
        ref={this.tt.outerRef}
        style={{
          height: `${heightPercent}%`,
          left: `${leftPercent}%`,
          top: `${topPercent}%`,
          width: `${widthPercent}%`
        }}
      >
        {this.inputs()}
        {!absoluteContainer.markedForDestruction() &&
          <>
            <a className="add-absolute-container-button" href="#" onClick={this.tt.onAddAbsoluteContainerClicked} style={{zIndex: 9}}>
              <i className="fa fa-plus" />
            </a>
            <View
              dataSet={{class: "bottom-right-corner"}}
              onMouseDown={this.tt.onResizeMouseDown}
              style={{position: "absolute", right: 24, bottom: 10, zIndex: 9}}
            >
              <i className="fa fa-maximize" />
            </View>
            <div style={{width: "100%", height: "100%"}}>
              <div
                className="absolute-container-inner"
                data-horizontal-align={absoluteContainer.horizontalAlign()}
                data-vertical-align={absoluteContainer.verticalAlign()}
                style={style}
              >
                {absoluteContainers.map((subAbsoluteContainer) =>
                  <ComponentsContentsEditorAbsoluteContainer
                    absoluteContainer={subAbsoluteContainer}
                    cacheKey={`${this.p.cacheKey}-${subAbsoluteContainer.fullCacheKey()}`}
                    events={this.p.events}
                    key={subAbsoluteContainer.cacheKey()}
                    name={`${name}[children_attributes][${subAbsoluteContainer.uniqueKey()}]`}
                    nestedAbsoluteContainers={nestedAbsoluteContainers}
                    onDoubleClick={this.p.onDoubleClick}
                    parent={absoluteContainer}
                    parentHeight={heightPixels}
                    parentWidth={widthPixels}
                    surveyStep={this.props.surveyStep}
                  />
                )}
                {absoluteContainer.surveyStepElement() &&
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                    {SurveyStepElement.modelName().human()} - {absoluteContainer.surveyStepElement().translatedElementType()}
                  </div>
                }
                {!absoluteContainer.surveyStepElement() && absoluteContainer.hasBody() &&
                  <CkeditorPresentation html={absoluteContainer.body()} />
                }
              </div>
            </div>
          </>
        }
      </div>
    )
  }

  inputs() {
    const {form} = this.tt
    const {absoluteContainer, name} = this.p

    return (
      <>
        {absoluteContainer.isNewRecord() &&
          form.setValueWithHidden(`${name}[new_id]`, absoluteContainer.id())
        }
        {absoluteContainer.isPersisted() &&
          form.setValueWithHidden(`${name}[id]`, absoluteContainer.id())
        }
        {absoluteContainer.markedForDestruction() && absoluteContainer.isPersisted() &&
          form.setValueWithHidden(`${name}[_destroy]`, 1)
        }
        {!absoluteContainer.markedForDestruction() &&
          <>
            <TextKeysHandle
              cacheKey={this.s.cacheKey}
              name={name}
              resource={absoluteContainer}
            />
            {["top", "left", "width", "height"].map((attributeName) =>
              <React.Fragment key={attributeName}>
                {form.setValueWithHidden(`${name}[${attributeName}_pixels]`, absoluteContainer[`${attributeName}Pixels`]())}
                {form.setValueWithHidden(`${name}[${attributeName}_percent]`, absoluteContainer[`${attributeName}Percent`]())}
              </React.Fragment>
            )}
            {form.setValueWithHidden(`${name}[body]`, absoluteContainer.body())}
            {form.setValueWithHidden(`${name}[body_type]`, absoluteContainer.bodyType())}
            {form.setValueWithHidden(`${name}[horizontal_align]`, absoluteContainer.horizontalAlign())}
            {form.setValueWithHidden(`${name}[liquid_conditions]`, absoluteContainer.liquidConditions())}
            {form.setValueWithHidden(`${name}[minimum_height]`, absoluteContainer.minimumHeight() ? 1 : 0)}
            {form.setValueWithHidden(`${name}[minimum_width]`, absoluteContainer.minimumWidth() ? 1 : 0)}
            {form.setValueWithHidden(`${name}[responsive]`, absoluteContainer.responsive() ? 1 : 0)}
            {form.setValueWithHidden(`${name}[styling_id]`, absoluteContainer.stylingId())}
            {absoluteContainer.surveyStepElement()?.elementType() &&
              <>
                {form.setValueWithHidden(`${name}[survey_step_element_attributes][element_type]`, absoluteContainer.surveyStepElement()?.elementType())}
                {form.setValueWithHidden(`${name}[survey_step_element_attributes][survey_step_id]`, this.p.surveyStep.id())}
              </>
            }
            {absoluteContainer.surveyStepElement()?.isPersisted() &&
              form.setValueWithHidden(`${name}[survey_step_element_attributes][id]`, absoluteContainer.surveyStepElement().id())
            }
            {form.setValueWithHidden(`${name}[vertical_align]`, absoluteContainer.verticalAlign())}
            {absoluteContainer.backgroundImageInputFiles &&
              <InputFile
                files={absoluteContainer.backgroundImageInputFiles}
                name={`${name}[background_image]`}
                style={{display: "none"}}
              />
            }
          </>
        }
      </>
    )
  }

  onAbsoluteContainerChanged = ({cacheKey, id, previewImageSrc}) => {
    const {absoluteContainer} = this.p

    if (id == absoluteContainer.id()) {
      // We should re-render if the absolute container is changed by something else (like the edit-absolute-container-modal)
      this.setState({cacheKey, previewImageSrc})
    }
  }

  onAbsoluteContainerMouseDown = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {leftPixels, topPixels} = this.s

    this.setState({
      originalClientX: digg(e, "clientX"),
      originalClientY: digg(e, "clientY"),
      originalLeft: leftPixels,
      originalTop: topPixels,
      positioning: true
    })
  }

  onAddAbsoluteContainerClicked = (e) => {
    e.preventDefault()

    const contentsEditorPreview = digg(this.tt.outerRef, "current")
    const parentWidth = digg(contentsEditorPreview, "offsetWidth")
    const parentHeight = digg(contentsEditorPreview, "offsetHeight")
    const widthPixels = Number(parentWidth / 3)
    const heightPixels = Number(parentHeight / 3)

    if (widthPixels < 70) {
      FlashMessage.alert("Not enough space to add an additional box")

      return
    }

    if (heightPixels < 70) {
      FlashMessage.alert("Not enough space to add an additional box")

      return
    }

    const leftPixels = Number(parentWidth * 0.02)
    const topPixels = Number(parentHeight * 0.02)
    const heightPercent = (heightPixels / parentHeight) * 100
    const leftPercent = (leftPixels / parentWidth) * 100
    const topPercent = (topPixels / parentHeight) * 100
    const widthPercent = (widthPixels / parentWidth) * 100

    const newAbsoluteContainer = new ContentAbsoluteContainer({
      a: {
        body: "",
        bodyType: "plain",
        id: uuidv4(),
        heightPercent,
        heightPixels,
        leftPercent,
        leftPixels,
        topPercent,
        topPixels,
        widthPercent,
        widthPixels
      },
      isNewRecord: true
    })

    this.setState({
      absoluteContainers: this.s.absoluteContainers.concat([newAbsoluteContainer])
    })
  }

  onDoubleClick = (e) => this.p.onDoubleClick(e, this.p.absoluteContainer)

  onWindowMouseMove = (e) => {
    const {positioning, resizing} = this.s

    if (positioning) this.reposition(e)
    if (resizing) this.resize(e)
  }

  reposition(e) {
    const {clientX, clientY} = digs(e, "clientX", "clientY")
    const {parentWidth, parentHeight} = this.p
    const {heightPixels, originalClientX, originalClientY, originalLeft, originalTop, widthPixels} = this.s
    const maxLeft = parentWidth - widthPixels
    const maxTop = parentHeight - heightPixels

    let newLeft = (clientX - originalClientX) + originalLeft
    let newTop = (clientY - originalClientY) + originalTop

    if (newLeft > maxLeft) newLeft = maxLeft
    if (newLeft < 0) newLeft = 0
    if (newTop > maxTop) newTop = maxTop
    if (newTop < 0) newTop = 0

    const leftPercent = (newLeft / parentWidth) * 100
    const topPercent = (newTop / parentHeight) * 100

    this.setState({
      leftPixels: newLeft,
      leftPercent,
      topPixels: newTop,
      topPercent
    })
  }

  resize(e) {
    const {clientX, clientY} = digs(e, "clientX", "clientY")
    const {parentWidth, parentHeight} = this.p
    const {leftPixels, originalClientX, originalClientY, originalHeight, originalWidth, topPixels} = this.s
    const maxWidth = parentWidth - leftPixels - 2
    const maxHeight = parentHeight - topPixels - 2
    let newWidth = (clientX - originalClientX) + originalWidth
    let newHeight = (clientY - originalClientY) + originalHeight

    if (newWidth > maxWidth) newWidth = maxWidth
    if (newWidth < 50) newWidth = 50
    if (newHeight > maxHeight) newHeight = maxHeight
    if (newHeight < 50) newHeight = 50

    const heightPercent = (newHeight / parentHeight) * 100
    const widthPercent = (newWidth / parentWidth) * 100

    this.setState({
      heightPixels: newHeight,
      heightPercent,
      widthPixels: newWidth,
      widthPercent
    })
  }

  onResizeMouseDown = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {parentHeight, parentWidth} = this.p
    const {widthPercent, heightPercent} = this.s

    const originalWidth = parentWidth * (widthPercent / 100)
    const originalHeight = parentHeight * (heightPercent / 100)

    this.setState({
      resizing: true,
      originalClientX: digg(e, "clientX"),
      originalClientY: digg(e, "clientY"),
      originalHeight,
      originalWidth
    })
  }

  onWindowMouseUp = () => {
    if (this.s.positioning || this.s.resizing) {
      this.saveAbsoluteContainer()
    }

    this.setState({positioning: false, resizing: false})
  }

  saveAbsoluteContainer = () => {
    const {heightPixels, heightPercent, leftPixels, leftPercent, topPixels, topPercent, widthPixels, widthPercent} = this.s

    this.p.absoluteContainer.assignAttributes({
      heightPercent,
      heightPixels,
      leftPercent,
      leftPixels,
      topPercent,
      topPixels,
      widthPercent,
      widthPixels
    })
  }
}))

export default ComponentsContentsEditorAbsoluteContainer
