import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {Styling} from "models"
import StylingLink from "components/stylings/link"
export default class ComponentsStylingsTable extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--stylings--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={Styling}
        select={{Styling: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.stylingContent,
      label: Styling.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    }
  ]

  editModelPath = ({styling}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminStylingPath(styling.id())
  }

  viewModelPath = ({styling}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminStylingPath(styling.id())
  }

  stylingContent = ({styling}) => <StylingLink styling={styling} {...linkProps(this.props)} />
}
