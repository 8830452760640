import {memo, shapeComponent, ShapeComponent, Text, useBreakpoint, useI18n, useStyles} from "shared/base"
import React, {useMemo} from "react"
import {StyleSheet, View} from "react-native"
import ActiveStep from "./active-step"
import Button from "components/chalky-layout/button"
import ChalkyLayout from "components/chalky-layout"
import RoleCard from "./role-card"
import StripedPressable from "components/chalky-layout/striped-pressable"

const styles = StyleSheet.create({
  mb15: {
    marginBottom: 15
  },

  rootContainer: {},
  rootContainerMdUp: {
    flexDirection: "row"
  },

  roleCardsContainer: {},
  roleCardsContainerXlUp: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },

  rolesContainer: {},
  rolesContainerMdUp: {
    flex: 1
  },

  roleCard: {},
  roleCardLgDown: {
    marginBottom: 15
  },
  roleCardXlUp: {
    marginBottom: 15,
    width: "49%"
  },

  yourRoleColumn: {
    paddingTop: 37
  },
  yourRoleColumnMdUp: {
    width: 300,
    paddingTop: 161,
    paddingRight: 30
  },
  yourRoleColumnLgUp: {
    width: 500,
    paddingRight: 80
  },

  yourRoleWithDescription: {},
  yourRoleWithDescriptionMdUp: {
    marginTop: 8,
    marginLeft: 30
  },
  yourRoleWithDescriptionLgUp: {
    marginLeft: 52
  },

  yourRoleSmall: {
    marginBottom: 15,
    fontFamily: "DreamChaser",
    fontSize: 64,
    textAlign: "center"
  },
  yourRoleLarge: {
    fontFamily: "DreamChaser",
    fontSize: 64
  }
})

export default memo(shapeComponent(class RoutesUserCreateUserStep1 extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.create_user.step1"})

    this.setInstance(useBreakpoint())
    this.setInstance({t})
    this.useStates({
      activeCard: null,
      readMore: false
    })
  }

  render() {
    const {mdUp, t} = this.tt
    const {activeCard, readMore} = this.s
    const dataSet = useMemo(() => ({route: "user/create-user/step1"}), [])
    const rootContainerStyle = useStyles(styles, ["rootContainer"])
    const yourRoleColumnStyle = useStyles(styles, ["yourRoleColumn"])
    const rolesContainerStyle = useStyles(styles, ["rolesContainer"])
    const yourRoleWithDescriptionStyle = useStyles(styles, ["yourRoleWithDescription"])
    const roleCardsContainerStyle = useStyles(styles, ["roleCardsContainer"])
    const roleCardStyle = useStyles(styles, ["roleCard"])

    return (
      <ChalkyLayout dataSet={dataSet}>
        <View dataSet={{class: "root-container"}} style={rootContainerStyle}>
          <View dataSet={{class: "your-role-column"}} style={yourRoleColumnStyle}>
            <ActiveStep label={t(".step_1")} />
            <View style={yourRoleWithDescriptionStyle}>
              <Text style={mdUp ? styles.yourRoleLarge : styles.yourRoleSmall}>
                {t(".your_role")}
              </Text>
              {!mdUp &&
                <View style={styles.mb15}>
                  <StripedPressable onPress={this.tt.onReadMorePress}>
                    <Text>
                      {readMore ? t(".read_less") : t(".read_more")}
                    </Text>
                  </StripedPressable>
                </View>
              }
              {(mdUp || readMore) &&
                <Text style={styles.mb15}>
                  {t(".your_role_description")}
                </Text>
              }
            </View>
          </View>
          <View dataSet={{class: "roles-container"}} style={rolesContainerStyle}>
            <View style={roleCardsContainerStyle}>
              <View style={roleCardStyle}>
                <RoleCard
                  active={activeCard == "teacher"}
                  description={t(".teacher_description")}
                  header={t(".teacher")}
                  icon="clipboard"
                  identifier="teacher"
                  onCardPress={this.tt.onTeacherCardPress}
                />
              </View>
              <View style={roleCardStyle}>
                <RoleCard
                  active={activeCard == "principal-administrator"}
                  description={t(".principal_administrator_description")}
                  header={t(".principal_administrator")}
                  icon="building"
                  identifier="principal-administrator"
                  onCardPress={this.tt.onPrincipalAdministratorCardPress}
                />
              </View>
              <View style={roleCardStyle}>
                <RoleCard
                  active={activeCard == "well-being-coordinator"}
                  description={t(".well_being_coordinator_description")}
                  header={t(".well_being_coordinator")}
                  icon="heart"
                  identifier="well-being-coordinator"
                  onCardPress={this.tt.onWellBeingCoordinatorCardPress}
                />
              </View>
              <View style={roleCardStyle}>
                <RoleCard
                  active={activeCard == "other"}
                  description={t(".other_description")}
                  header={t(".other")}
                  icon="heart"
                  identifier="other"
                  onCardPress={this.tt.onOtherCardPress}
                />
              </View>
            </View>
            <Button primary title={t(".continue")} />
          </View>
        </View>
      </ChalkyLayout>
    )
  }

  onPrincipalAdministratorCardPress = () => this.triggerActiveCard("principal-administrator")
  onOtherCardPress = () => this.triggerActiveCard("other")
  onReadMorePress = () => this.setState({readMore: !this.s.readMore})
  onTeacherCardPress = () => this.triggerActiveCard("teacher")
  onWellBeingCoordinatorCardPress = () => this.triggerActiveCard("well-being-coordinator")

  triggerActiveCard(cardIdentifier) {
    const {activeCard} = this.s

    if (cardIdentifier == activeCard) {
      this.setState({activeCard: null})
    } else {
      this.setState({activeCard: cardIdentifier})
    }
  }
}))
