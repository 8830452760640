import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClass} from "models"
import SchoolClassLink from "components/school-classes/link"
import SchoolLink from "components/schools/link"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"
export default memo(shapeComponent(class ComponentsSchoolClassesTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.school_classes.table"})

    this.setInstance({t})
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--school-classes--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={SchoolClass}
        preloads={["contact.children_relationships.contact.user"]}
        select={{
          Contact: ["id"],
          ContactRelationship: ["id"],
          School: ["id", "name"],
          SchoolClass: ["id", "interpretedName"],
          User: ["id"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.schoolClassContent,
      label: SchoolClass.humanAttributeName("name"),
      sortKey: "schoolClassCurrentTranslationName"
    },
    {
      attribute: "name",
      content: this.tt.schoolContent,
      label: SchoolClass.humanAttributeName("school"),
      path: ["school"],
      sortKey: "schoolCurrentTranslationName"
    },
    {
      content: this.tt.administratorsContent,
      identifier: "administrators",
      label: this.t(".administrators")
    },
    {
      attribute: "schoolClassCheckInsCount",
      sortKey: "schoolClassCheckInsCount"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    },
    {
      attribute: "updatedAt",
      sortKey: "updatedAt"
    }
  ]

  editModelPath = ({schoolClass}) => {
    const {adminLayout, userLayout} = this.p

    if (adminLayout) return Routes.editAdminSchoolClassPath(schoolClass.id())
    if (userLayout) return Routes.editUserSchoolClassPath(schoolClass.id())
  }

  viewModelPath = ({schoolClass}) => {
    const {adminLayout, userLayout} = this.p

    if (adminLayout) return Routes.adminSchoolClassPath(schoolClass.id())
    if (userLayout) return Routes.userSchoolClassPath(schoolClass.id())
  }

  administratorsContent = ({schoolClass}) => {
    const users = schoolClass
      .contact()
      .childrenRelationships()
      .loaded()
      .filter((contactRelationship) => contactRelationship.relationshipType() == "admin" && contactRelationship.child()?.user())
      .map((contactRelationship) => contactRelationship.child().user())

    return (
      <>
        {users.map((user, userIndex) =>
          <UserLink className={classNames({"ml-1": userIndex > 0})} key={user.id()} user={user} {...linkProps(this.props)} />
        )}
      </>
    )
  }

  schoolContent = ({schoolClass}) => schoolClass.school() && <SchoolLink school={schoolClass.school()} {...linkProps(this.props)} />
  schoolClassContent = ({schoolClass}) => <SchoolClassLink schoolClass={schoolClass} {...linkProps(this.props)} />
}))
