import {Notification, NotificationType} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import NotificationsTable from "components/notifications/table"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNotificationTypesNotifications extends ShapeComponent {
  setup() {
    const params = useParams()
    const {notificationType, notificationTypeId} = useModel(NotificationType, {match: {params}})
    const {t} = useI18n({namespace: "js.routes.admin.notificationTypes.notification_type_audit_triggers"})

    this.notificationsQuery = useMemo(() => Notification.ransack({notification_type_id_eq: notificationTypeId}), [notificationTypeId])
    this.setInstance({notificationType, notificationTypeId, t})
    useAdminLayout()?.setState({active: "notification-types", headerTitle: NotificationType.humanAttributeName("notifications")})
  }

  render() {
    const {notificationType} = this.tt

    return (
      <View dataSet={{route: "admin/notification-types/notifications"}}>
        {notificationType &&
          <Nav active="notifications" className="mb-4" notificationType={notificationType} />
        }
        <NotificationsTable
          adminLayout
          collection={this.tt.notificationsQuery}
        />
      </View>
    )
  }
}))
