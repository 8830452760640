import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import UserLink from "components/users/link"
import {UserRole} from "models"
export default memo(shapeComponent(class ComponentsUserRolesTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--user-roles--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={UserRole}
        preloads={["role", "user"]}
        select={{
          Role: ["translatedIdentifier"],
          UserRole: ["id"],
          User: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.userContent,
      identifier: "user",
      label: UserRole.humanAttributeName("user"),
      sortKey: "userFirstName"
    },
    {
      content: this.tt.roleContent,
      label: UserRole.humanAttributeName("role"),
      sortKey: "role"
    }
  ]

  editModelPath = ({userRole}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminUserRolePath(userRole.id())
  }

  roleContent = ({userRole}) => userRole.role().translatedIdentifier()

  userContent = ({userRole}) => userRole.user() &&
    <UserLink user={userRole.user()} {...linkProps(this.props)} />
}))
