import * as models from "models"
import {dig, digg} from "diggerize"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {Audit} from "models"
import AuditsTable from "components/audits/table"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminAuditsIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.audits.index"})
    const queryParams = useQueryParams()
    const auditClassName = queryParams.audit_class || "Audit"
    const auditClass = digg(models, auditClassName)
    const auditModelClasses = useMemo(
      () => Object.values(models).filter((modelClass) => modelClass.modelClassData().name.endsWith("Audit")),
      []
    )

    this.auditModelClassesOptions = useMemo(
      () => auditModelClasses.map((auditModelClass) => ({
        text: auditModelClass.modelClassData().name,
        value: auditModelClass.modelClassData().name
      })),
      []
    )

    this.setInstance({
      auditClass,
      t
    })

    useAdminLayout()?.setState({active: "audits", headerTitle: Audit.modelName().human({count: 2})})
  }

  render() {
    const {auditClass, auditModelClassesOptions} = this.tt
    const auditClassValues = [auditClass.modelClassData().name]

    return (
      <View dataSet={{route: "admin-audits--index"}}>
        <HayaSelect
          id="audit_model_class"
          onChange={this.tt.onAuditModelClassChanged}
          options={auditModelClassesOptions}
          styles={{
            main: {
              marginBottom: 20,
              maxWidth: 400
            }
          }}
          values={auditClassValues}
        />
        <AuditsTable adminLayout auditClass={this.tt.auditClass} />
      </View>
    )
  }

  onAuditModelClassChanged = (args) => {
    const newAuditClassName = dig(args, "options", 0, "value")

    Params.changeParams({audit_class: newAuditClassName})
  }
}))
