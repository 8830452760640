import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CountriesSelect from "./countries-select"
import {digg} from "diggerize"
import InputContainer from "./input-container"
import InputField from "./input-field"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUtilsPhoneNumber extends ShapeComponent {
  static defaultProps = {
    country: null
  }

  static propTypes = {
    country: PropTypes.object.isRequired,
    countrySelectProps: PropTypes.object,
    dataSet: PropTypes.object,
    number: PropTypes.string.isRequired
  }

  setup() {
    const {country} = this.p

    this.useStates({
      phoneNumber: null,
      width: null
    })
    this.setInstance({
      countryValue: useMemo(() => {
        if (country) {
          return [digg(country, "code")]
        }
      }, [country?.code])
    })
  }

  containerProps() {
    const {dataSet, ...restProps} = this.props

    return restProps
  }

  countrySelectProps() {
    const {onChange, ...restProps} = this.props.countrySelectProps || {}

    return restProps
  }

  inputProps() {
    const {country, countrySelectProps, number, onChangeCountry, onChangeNumber, ...restProps} = this.props

    return restProps
  }

  render() {
    return (
      <InputContainer dataSet={{component: "utils/phone-number"}} onLayout={this.tt.onInputContainerLayout} {...this.containerProps()}>
        <View style={{flexDirection: "row"}}>
          <View dataSet={{class: "phone-number-country-container"}} style={{marginRight: 10}}>
            <CountriesSelect
              onChange={this.tt.onCountryChanged}
              optionsWidth={this.s.width}
              values={this.tt.countryValue}
              woofInputStyling
              {...this.countrySelectProps()}
            />
          </View>
          <View dataSet={{class: "phone-number-input-field-container"}} style={{flex: 1}}>
            <InputField icon="phone" onChangeText={this.p.onChangeNumber} value={this.p.number} {...this.inputProps()} />
          </View>
        </View>
      </InputContainer>
    )
  }

  onCountryChanged = (option) => {
    if (this.props.countrySelectProps?.onChange) {
      this.props.countrySelectProps.onChange(option)
    }

    this.p.onChangeCountry(option)
  }

  onInputContainerLayout = (e) => this.setState({width: digg(e, "nativeEvent", "layout", "width")})
}))
