import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CkeditorPresentation from "ckeditor/presentation"
import CourseLink from "components/courses/link"
import {CourseModule} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import SurveyLink from "components/surveys/link"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"
const selectedCourseModuleAttributes = ["createdAt", "expectedMinutes", "id", "name", "position", "updatedAt"]
  .concat(translatedAttributes(["appetizer", "name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminCourseModulesShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {courseModule, courseModuleId} = useModel(CourseModule, {
      abilities: {
        CourseModule: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["course", "survey"],
      select: {
        Course: ["id", "name"],
        CourseModule: selectedCourseModuleAttributes,
        Survey: ["id", "name"]
      }
    })

    this.setInstance({courseModule, courseModuleId})
    useAdminLayout()?.setState({actions: this.actions(), active: "course-modules", headerTitle: courseModule?.name()})
  }

  render() {
    const {courseModule} = this.tt

    return (
      <View dataSet={{route: "admin--course-modules--show"}}>
        {courseModule &&
          <>
            <AttributeRows attributes={["id", "expectedMinutes", "position", "createdAt", "updatedAt"]} model={courseModule} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${CourseModule.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={courseModule}
                />
                <AttributeRow
                  attribute={`appetizer${inflection.camelize(locale)}`}
                  label={`${CourseModule.humanAttributeName("appetizer")} (${Locales.labelForLocale(locale)})`}
                  model={courseModule}
                >
                  <CkeditorPresentation html={courseModule[`appetizer${inflection.camelize(locale)}`]()} />
                </AttributeRow>
              </React.Fragment>
            )}
            <AttributeRow attribute="course" model={courseModule}>
              {courseModule.course() &&
                <CourseLink adminLayout course={courseModule.course()} />
              }
            </AttributeRow>
            <AttributeRow attribute="survey" model={courseModule}>
              {courseModule.survey() &&
                <SurveyLink adminLayout survey={courseModule.survey()} />
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  actions() {
    const {courseModule} = this.tt

    return (
      <AdminShowActions model={courseModule} />
    )
  }
}))
