import {Platform, Pressable, View} from "react-native"
import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import useEventListener from "@kaspernj/api-maker/build/use-event-listener"

export default memo(shapeComponent(class ComponentsAdminLayoutHeader extends ShapeComponent {
  static propTypes = propTypesExact({
    actions: PropTypes.node,
    onTriggerMenu: PropTypes.func.isRequired,
    title: PropTypes.string
  })

  setup() {
    this.headerActionsRef = useRef()
    this.setInstance({
      breakpoint: useBreakpoint()
    })
    this.useStates({headerActionsActive: false})

    if (Platform.OS == "web") {
      useEventListener(window, "mouseup", this.tt.onWindowMouseUp)
    }
  }

  render() {
    const {breakpoint, headerActionsRef, onGearsClicked} = this.tt
    const {actions, onTriggerMenu, title} = this.props
    const {headerActionsActive} = this.s
    const headerStyle = {
      flexDirection: "row",
      top: 0,
      height: "100px",
      alignItems: "center",
      paddingRight: 30,
      paddingLeft: 30,
      backgroundColor: "#fff"
    }

    if (breakpoint.mdUp) {
      headerStyle.position = "fixed"
      headerStyle.left = 250
      headerStyle.width = "calc(100% - 250px)"
    } else {
      headerStyle.position = "absolute"
      headerStyle.width = "100%"
    }

    if (breakpoint.lgUp) {
      headerStyle.left = 290
      headerStyle.width = "calc(100% - 290px)"
    }

    const headerActionsContainerStyle = {}
    let showHeaderActionsContainer = true

    if (breakpoint.mdUp) {
      headerActionsContainerStyle.marginLeft = "auto"
    } else {
      Object.assign(headerActionsContainerStyle, {
        position: "fixed",
        top: 0,
        left: 0,

        flexDirection: "row",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",

        background: "rgba(#000, .8)"
      })

      if (!headerActionsActive) {
        showHeaderActionsContainer = false
      }
    }

    return (
      <View
        dataSet={{component: "admin/layout/header"}}
        style={headerStyle}
      >
        <Text dataSet={{class: "header-title-container"}} style={{color: "#282a33", fontSize: 22}}>
          {title}
        </Text>
        {actions && showHeaderActionsContainer &&
          <View
            dataSet={{
              active: headerActionsActive,
              class: "header-actions-container"
            }}
            style={headerActionsContainerStyle}
          >
            <div className="header-actions" ref={headerActionsRef}>
              {actions}
            </div>
          </View>
        }
        {breakpoint.mdDown &&
          <View
            dataSet={{class: "burger-menu-container"}}
            style={{flexDirection: "row", alignItems: "center", marginLeft: "auto"}}
          >
            {actions &&
              <Pressable dataSet={{class: "actions-link"}} onPress={onGearsClicked} style={{marginRight: 8}}>
                <FontAwesomeIcon name="gear" style={{color: "#1b1c1e", fontSize: 22}} />
              </Pressable>
            }
            <Pressable dataSet={{class: "burger-menu-link"}} onPress={onTriggerMenu}>
              <FontAwesomeIcon name="bars" style={{color: "#1b1c1e", fontSize: 28}} />
            </Pressable>
          </View>
        }
      </View>
    )
  }

  onGearsClicked = () => {
    this.setState({
      headerActionsActive: !this.s.headerActionsActive
    })
  }

  onWindowMouseUp = (e) => {
    const {headerActionsRef} = this.tt
    const {headerActionsActive} = this.s

    // Close the header actions menu if clicked happened outside
    if (headerActionsActive && headerActionsRef.current && !headerActionsRef.current.contains(e.target)) {
      this.setState({headerActionsActive: false})
    }
  }
}))
