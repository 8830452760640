import {Platform, Pressable, View} from "react-native"
import {PopupMenu, PopupMenuItem} from "components/popup-menu"
import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Devise from "@kaspernj/api-maker/build/devise"
import FlashMessage from "shared/flash-message"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import MenuContent from "./menu-content"
import MenuItem from "./menu-item"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Routes from "shared/routes"
import {Text} from "shared/base"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useEventListener from "@kaspernj/api-maker/build/use-event-listener"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsAdminLayoutMenu extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.string,
    noAccess: PropTypes.bool.isRequired,
    onRequestMenuClose: PropTypes.func.isRequired,
    triggered: PropTypes.bool.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.admin.layout.menu"})

    this.setInstance({
      breakpoint: useBreakpoint(),
      menuUserItemsRef: useRef(),
      rootRef: useRef(),
      t
    })
    this.useStates({
      userMenuItemOpen: false
    })

    if (Platform.OS == "web") {
      useEventListener(window, "mouseup", this.tt.onWindowMouseUp)
    }
  }

  render() {
    const {breakpoint, t} = this.tt
    const {active, noAccess, triggered} = this.props
    const currentUser = useCurrentUser()
    const menuStyle = {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: 9,
      top: 0,
      left: 0,
      height: "100%",
      backgroundColor: "#1b1c1e",
      color: "#fff",
      overflowY: "auto"
    }

    if (breakpoint.lgUp) {
      menuStyle.width = "290px"
    } else if (breakpoint.mdUp) {
      menuStyle.width = "250px"
    } else {
      Object.assign(menuStyle, {
        width: "100%",
        maxWidth: "250px",
        maxHeight: "100vh"
      })

      if (!triggered) {
        menuStyle.display = "none"
      }
    }

    return (
      <div className="components--admin--layout--menu" data-triggered={triggered} ref={this.tt.rootRef} style={menuStyle}>
        <View
          dataSet={{class: "menu-logo"}}
          style={{
            width: "80%",
            marginTop: 25,
            marginRight: "auto",
            marginLeft: "auto",
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          <Link dataSet={{class: "menu-logo-link"}} to={Routes.adminRootPath()}>
            <Text style={{color: "#dededf", fontSize: 42}}>
              Admin
            </Text>
          </Link>
        </View>
        <View dataSet={{class: "menu-items-center"}} style={{marginTop: 25}}>
          {!noAccess &&
            <MenuContent active={active} />
          }
        </View>
        <View dataSet={{flexDirection: "row", class: "menu-items-bottom"}} style={{marginTop: "auto", marginBottom: 25}}>
          {currentUser &&
            <View
              dataSet={{class: "menu-user-section"}}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                marginHorizontal: 25,
                marginBottom: 25
              }}
            >
              <View
                dataSet={{class: "menu-user-icon"}}
                style={{
                  flexDiretion: "row",
                  width: 44,
                  minWidth: 44,
                  maxWidth: 44,
                  height: 44,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#abbcd0"
                }}
              >
                <FontAwesomeIcon name="user" style={{color: "#fff", fontSize: 14}} />
              </View>
              <View dataSet={{class: "menu-user-name"}} style={{flexShrink: 1, marginLeft: 8}}>
                <Text dataSet={{class: "menu-user-name-container"}} style={{overflow: "hidden", maxWidth: 140, color: "#fff"}}>
                  {currentUser.name()}
                </Text>
              </View>
              <div
                className="menu-user-items"
                ref={this.tt.menuUserItemsRef}
                style={{position: "relative", marginLeft: "auto"}}
              >
                {this.s.userMenuItemOpen &&
                  <PopupMenu>
                    <PopupMenuItem
                      children={t("js.components.app_layout.menu.notification_settings")}
                      className="notifications-settings-menu-item"
                      to="#"
                    />
                  </PopupMenu>
                }
                <Pressable dataSet={{class: "menu-user-items-link"}} onPress={this.tt.onUserItemsClicked}>
                  <FontAwesomeIcon name="ellipsis-h" style={{color: "#dededf", fontSize: 14}} />
                </Pressable>
              </div>
            </View>
          }
          {currentUser &&
            <MenuItem
              active
              className="sign-out-menu-item"
              icon="sign-out"
              label={t(".sign_out")}
              onClick={this.tt.onSignOutClicked}
            />
          }
        </View>
      </div>
    )
  }

  onSignOutClicked = async (e) => {
    e.preventDefault()

    try {
      await Devise.signOut()
      FlashMessage.success(this.t(".you_have_been_signed_out"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onUserItemsClicked = (e) => {
    e.preventDefault()
    this.setState({userMenuItemOpen: !this.s.userMenuItemOpen})
  }

  onWindowMouseUp = (e) => {
    // Close the menu if triggered (menu is open on mobile)
    if (this.p.triggered && !this.tt.rootRef.current.contains(e.target)) setTimeout(this.p.onRequestMenuClose)

    // Close the user items menu if clicked happened outside of that
    if (!this.tt.menuUserItemsRef?.current?.contains(e.target)) this.setState({userMenuItemOpen: false})
  }
}))
