import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import UsersConfirmation from "components/users/confirmation"

export default memo(shapeComponent(class RoutesFrontUsersConfirmation extends ShapeComponent {
  render() {
    setLayout("front")

    return (
      <div className="routes--front--users--confirmation">
        <UsersConfirmation />
      </div>
    )
  }
}))
