import React, {useMemo} from "react"
import {SchoolClass, Survey, TeamSurvey, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digg} from "diggerize"
import EventEmitter from "events"
import findOrCreateTeamSurveyStep from "components/survey-steps/find-or-create-team-survey-step"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import {sortedByFunction} from "sorted-by"
import SurveyStepsPresentation from "components/survey-steps/presentation"
import useQueryParams from "on-location-changed/build/use-query-params"

export default memo(shapeComponent(class ComponentsSurveysPresentation extends ShapeComponent {
  static defaultProps = {
    submitButton: true
  }

  static propTypes = {
    className: PropTypes.string,
    events: PropTypes.instanceOf(EventEmitter),
    onAnswersSubmitted: PropTypes.func,
    onSurveyCompleted: PropTypes.func,
    onSurveyStepLoaded: PropTypes.func,
    onTeamSurveyStepLoaded: PropTypes.func,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired,
    submitButton: PropTypes.bool.isRequired,
    survey: PropTypes.instanceOf(Survey).isRequired,
    teamSurvey: PropTypes.instanceOf(TeamSurvey).isRequired
  }

  setup() {
    this.setInstance({
      queryParams: useQueryParams()
    })
    this.useStates({
      surveyStep: undefined,
      teamSurveyStep: undefined
    })

    useMemo(() => {
      this.setState({teamSurveyStep: undefined})
      this.loadSurveyStep()
      this.loadTeamSurveyStep()
    }, [this.queryParams.survey_step_id])
  }

  componentDidMount() {
    this.loadSurveyStep()
    this.loadTeamSurveyStep()
  }

  loadSurveyStep() {
    const {onSurveyStepLoaded} = this.props
    const surveyStep = this.currentSurveyStep()

    if (!surveyStep) throw new Error("No 'surveyStep' could be found")

    if (surveyStep?.id() != this.s.surveyStep?.id()) {
      this.setState({surveyStep})

      if (onSurveyStepLoaded) this.p.onSurveyStepLoaded(surveyStep)
    }
  }

  currentSurveyStep() {
    const {survey} = this.p

    if (this.queryParams.survey_step_id) {
      const foundSurveyStep = survey.steps().loaded().find((surveyStep) => surveyStep.id() == this.queryParams.survey_step_id)

      if (!foundSurveyStep) {
        const stepIds = survey.steps().loaded().map((step) => step.id())

        throw new Error(`No survey step with id ${this.queryParams.survey_step_id} could be found in survey ${survey.id()} with steps: ${stepIds.join(", ")}`)
      }

      return foundSurveyStep
    }

    return sortedByFunction(survey.steps().loaded(), "position")[0]
  }

  async loadTeamSurveyStep() {
    const {teamSurvey} = this.p
    const {surveyStep} = this.s

    if (!surveyStep) throw new Error("No 'surveyStep' given")
    if (!teamSurvey) throw new Error("No 'teamSurvey' given")

    const {onTeamSurveyStepLoaded} = this.props
    const teamSurveyStep = await findOrCreateTeamSurveyStep({surveyStepId: surveyStep.id(), teamSurveyId: teamSurvey.id()})

    if (teamSurveyStep.teamSurveyId() != this.p.teamSurvey.id()) {
      throw new Error("findOrCreateTeamSurveyStep returned a team survey step that didn't belong to the given team survey")
    }

    this.setState({teamSurveyStep})

    if (onTeamSurveyStepLoaded) onTeamSurveyStepLoaded(teamSurveyStep)
  }

  render() {
    const {
      className,
      events,
      onAnswersSubmitted,
      onSurveyCompleted,
      onSurveyStepLoaded,
      onTeamSurveyStepLoaded,
      schoolClass,
      submitButton,
      survey,
      teamSurvey,
      ...restProps
    } = this.props
    const {surveyStep, teamSurveyStep} = this.s

    return (
      <div
        className={classNames("components--surveys--presentation", className)}
        data-survey-id={survey.id()}
        data-survey-step-id={surveyStep?.id()}
        data-team-survey-step-id={teamSurveyStep?.id()}
        {...restProps}
      >
        {schoolClass && surveyStep && teamSurvey && teamSurveyStep &&
          <SurveyStepsPresentation
            events={events}
            onAnswersSubmitted={this.tt.onAnswersSubmitted}
            schoolClass={schoolClass}
            submitButton={submitButton}
            surveyStep={surveyStep}
            teamSurvey={teamSurvey}
            teamSurveyStep={teamSurveyStep}
          />
        }
      </div>
    )
  }

  onAnswersSubmitted = ({after, nextStep, nextTeamSurveyStep}) => {
    const {onAnswersSubmitted, onSurveyCompleted} = this.props

    if (onAnswersSubmitted) {
      onAnswersSubmitted({after, nextStep, nextTeamSurveyStep})
    } else if (after) {
      if (after == "nextStep") {
        Params.changeParams({survey_step_id: nextStep.id()})
      } else if (after == "previousStep") {
        this.redirectToPreviousStep()
      } else if (after == "end") {
        this.completeSurvey()
      } else {
        throw new Error(`Unhandled after: ${after}`)
      }
    } else if (nextStep) {
      Params.changeParams({survey_step_id: nextStep.id()})
    } else if (onSurveyCompleted) {
      this.completeSurvey()
    } else {
      Params.changeParams({survey_done: true})
    }
  }

  async completeSurvey() {
    const {onSurveyCompleted} = this.props
    const {teamSurvey} = this.p

    if (teamSurvey.state() != "completed") {
      await User.stateEvent({model: teamSurvey, state_event: "complete"})
    }

    if (onSurveyCompleted) {
      onSurveyCompleted()
    }
  }

  async redirectToPreviousStep() {
    const previousStepResult = await this.s.teamSurveyStep.previousStep()
    const previousTeamSurveyStep = digg(previousStepResult, "previous_team_survey_step")

    Params.changeParams({survey_step_id: previousTeamSurveyStep.surveyStepId()})
  }
}))
