import {NewsletterTemplate, NewsletterTemplateTableSearch} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "routes/admin/newsletter-templates/nav"
import NewsletterTemplatesTableSearchesTable from "components/newsletter-template-table-searches/table"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesTableSearchesIndex extends ShapeComponent {
  render() {
    setLayout("admin")

    const canCan = useCanCan(() => [[NewsletterTemplateTableSearch, ["create"]]])
    const {locale, t} = useI18n({namespace: "js.routes.admin.newsletter_templates.table_searches.index"})
    const {newsletterTemplate, newsletterTemplateId} = useModel(NewsletterTemplate, {
      match: this.p.match,
      select: {
        NewsletterTemplate: ["id"]
      }
    })
    const actions = useMemo(
      () => <>
        {canCan?.can("create", NewsletterTemplateTableSearch) &&
          <Button
            className="new-newsletter-templates-table-search-button"
            icon="plus"
            label={t(".add_new_table_search_to_newsletter_template")}
            to={Routes.newAdminNewsletterTemplateTableSearchPath(newsletterTemplateId)}
          />
        }
      </>,
      [canCan, locale, newsletterTemplate?.id()]
    )
    const collection = useMemo(
      () => NewsletterTemplateTableSearch.ransack({newsletter_template_id_eq: newsletterTemplateId}),
      [newsletterTemplateId]
    )

    return (
      <AdminLayout
        actions={actions}
        active="newsletter-templates"
        className="routes--admin--newsletter-templates--table-searches--index"
        headerTitle={NewsletterTemplate.modelName().human({count: 2})}
      >
        {newsletterTemplate &&
          <Nav active="table-searches" className="mb-4" newsletterTemplate={newsletterTemplate} />
        }
        <NewsletterTemplatesTableSearchesTable adminLayout collection={collection} />
      </AdminLayout>
    )
  }
}))
