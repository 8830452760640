import presence from "shared/presence"
import selectComponent from "components/select-component"
import {StripePlan} from "models"
const {withInputContainer: StripePlansSelectWithContainer, withMemo: ComponentsStripePlansSelect} = selectComponent({
  className: "components--stripe-plans--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || StripePlan

    query = query
      .ransack({s: "stripe_product_name asc"})
      .preload(["stripe_product"])
      .select({
        StripePlan: ["nickname", "id"],
        StripeProduct: ["name"]
      })
      .limit(20)

    if (searchValue) query.ransack({stripe_product_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const stripePlans = await query.toArray()

    return stripePlans.map((stripePlan) => ({
      text: presence(stripePlan.nickname()) || presence(stripePlan.stripeProduct()?.name()) || stripePlan.id(),
      value: stripePlan.id()
    }))
  }
})

export default ComponentsStripePlansSelect
export {StripePlansSelectWithContainer}
