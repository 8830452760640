import * as models from "models"
import selectComponent from "components/select-component"

const SelectComponentResult = selectComponent({
  className: "components--notification-type-audit-triggers--auditable-type-select",
  optionsCallback: ({searchValue, values}) => {
    let auditModelClasses = Object.values(models)
      .filter((modelClass) => modelClass.modelClassData().name.endsWith("Audit"))
      .map((modelClass) => modelClass.modelClassData().name.replace(/Audit$/, ""))

    if (searchValue) {
      auditModelClasses = auditModelClasses.filter((auditModelClass) => auditModelClass.includes(searchValue))
    }

    if (values) {
      if (typeof values == "string") {
        auditModelClasses = auditModelClasses.filter((auditModelClass) => values == auditModelClass)
      } else {
        auditModelClasses = auditModelClasses.filter((auditModelClass) => values.includes(auditModelClass))
      }
    }

    return auditModelClasses.map((auditModelClass) => ({
      text: auditModelClass,
      value: auditModelClass
    }))
  }
})

const {
  withAdminSelectContainer: AdminSelectNotificationTypeAuditTriggersAuditAuditableType,
  withInputContainer: ComponentsNotificationTypeAuditTriggersAuditAuditableTypeSelectWithContainer,
  withMemo: ComponentsNotificationTypeAuditTriggersAuditAuditableTypeSelect
} = SelectComponentResult

export default ComponentsNotificationTypeAuditTriggersAuditAuditableTypeSelect
export {AdminSelectNotificationTypeAuditTriggersAuditAuditableType, ComponentsNotificationTypeAuditTriggersAuditAuditableTypeSelectWithContainer}
