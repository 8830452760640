import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectScoreFactors} from "components/score-factors/select"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {SurveyStepElementOptionScore} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSurveyStepElementOptionScoresEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.survey_step_element_option_scores.edit"})
    const params = useParams()
    const {surveyStepElementOptionScore, surveyStepElementOptionScoreId} = useModel(SurveyStepElementOptionScore, {
      match: {params},
      newIfNoId: true,
      select: {
        SurveyStepElementOptionScore: ["id", "score", "scoreFactorId", "surveyStepElementOptionId"]
      }
    })

    this.setInstance({surveyStepElementOptionScore, surveyStepElementOptionScoreId, t})
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "surveys", headerTitle: this.headerTitle()})
  }

  render() {
    const {onSubmit, surveyStepElementOptionScore} = this.tt

    return (
      <View dataSet={{route: "admin--survey-step-element-option-scores--edit"}}>
        {surveyStepElementOptionScore &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <AdminSelectScoreFactors attribute="scoreFactorId" model={surveyStepElementOptionScore} />
            </View>
            <AdminInput attribute="score" containerProps={{style: {marginBottom: 15}}} model={surveyStepElementOptionScore} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {surveyStepElementOptionScore, t} = this.tt

    if (surveyStepElementOptionScore?.isNewRecord()) {
      return t(".add_new_score")
    } else if (surveyStepElementOptionScore?.isPersisted()) {
      return t(".edit_score")
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {surveyStepElementOptionScore, t} = this.tt

    if (surveyStepElementOptionScore.isNewRecord() && surveyStepElementOptionScore.hasSurveyStepElementOptionId()) {
      formData.survey_step_element_option_score.survey_step_element_option_id = surveyStepElementOptionScore.surveyStepElementOptionId()
    }

    try {
      await surveyStepElementOptionScore.saveRaw(formData)
      FlashMessage.success(t(".the_score_was_saved"))
      AppHistory.push(Routes.adminSurveyStepElementOptionScorePath(surveyStepElementOptionScore.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
