import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FrontLayout from "components/front-layout"
import memo from "set-state-compare/src/memo"
import PageContentsPresentation from "components/page-contents/presentation"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class LayoutNotFoundPage extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.layout.not_found_page"})

    this.t = t
  }

  render() {
    return (
      <FrontLayout className="components--layout--not-found-page">
        <PageContentsPresentation identifier="layout/not-found-page" />
      </FrontLayout>
    )
  }
}))
