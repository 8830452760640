import {AuditAction} from "models"
import selectComponent from "components/select-component"
const SelectComponentResult = selectComponent({
  className: "components--notification-type-audit-triggers--audit-action-select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = (query?.clone() || AuditAction)
      .ransack({s: "action asc"})
      .select({AuditAction: ["action"]})
      .groupBy("action")
      .limit(20)

    if (searchValue) {
      query.ransack({action_cont_any_word: searchValue})
    }

    if (values) {
      query.ransack({action_eq_any: values})
    }

    const auditActions = await query.toArray()

    return auditActions.map((auditAction) => ({
      text: auditAction.action(),
      value: auditAction.action()
    }))
  }
})

const {
  withAdminSelectContainer: AdminSelectNotificationTypeAuditTriggersAuditAction,
  withInputContainer: ComponentsNotificationTypeAuditTriggersAuditActionSelectWithContainer,
  withMemo: ComponentsNotificationTypeAuditTriggersAuditActionSelect
} = SelectComponentResult

export default ComponentsNotificationTypeAuditTriggersAuditActionSelect
export {AdminSelectNotificationTypeAuditTriggersAuditAction, ComponentsNotificationTypeAuditTriggersAuditActionSelectWithContainer}
