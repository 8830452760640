import {Challenge, ClassStep} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import I18n from "shared/i18n"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import SurveyLink from "components/surveys/link"
import useModel from "@kaspernj/api-maker/build/use-model"
const selectedChallengeAttributes = ["createdAt", "id", "name", "public", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedChallengeAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminChallengesShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {challenge, challengeId} = useModel(Challenge, {
      abilities: {
        Challenge: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["challenge_class_steps.class_step", "survey"],
      select: {
        Challenge: selectedChallengeAttributes,
        ChallengeClassStep: ["id"],
        ClassStep: ["name"],
        Survey: ["id", "name"]
      }
    })

    this.setInstance({challenge, challengeId})
  }

  render() {
    const {challenge} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="challenges"
        className="routes--admin--challenges--show"
        headerTitle={challenge?.name()}
      >
        {challenge &&
          <>
            <AttributeRows attributes={["id", "public", "createdAt", "updatedAt"]} model={challenge} />
            <AttributeRow attribute={"survey"} model={challenge}>
              {challenge.survey() &&
                <SurveyLink adminLayout survey={challenge.survey()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${Challenge.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={challenge}
                />
              </React.Fragment>
            )}
            <AttributeRow label={ClassStep.modelName().human({count: 2})}>
              {challenge.challengeClassSteps().map((challengeClassStep) =>
                <div key={challengeClassStep.id()}>
                  {challengeClassStep.classStep().name()}
                </div>
              )}
              {challenge.challengeClassSteps().loaded().length == 0 &&
                I18n.t("js.shared.none")
              }
            </AttributeRow>
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {challenge} = this.tt

    return (
      <AdminShowActions model={challenge} />
    )
  }
}))
