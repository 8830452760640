import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import {NotificationType} from "models"
import NotificationTypeLink from "components/notification-types/link"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNotificationTypes extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--notification-types--table", className)}
        columns={this.tt.columns}
        editModelPath={this.tt.editModelPath}
        modelClass={NotificationType}
        select={{
          NotificationType: ["id", "name"]
        }}
        viewModelPath={this.tt.viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.notificationTypeContent,
      label: NotificationType.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "active",
      sortKey: "active"
    }
  ]

  editModelPath = ({notificationType}) => {
    if (this.p.adminLayout) return Routes.editAdminNotificationTypePath(notificationType.id())
  }

  viewModelPath = ({notificationType}) => {
    if (this.p.adminLayout) return Routes.adminNotificationTypePath(notificationType.id())
  }

  notificationTypeContent = ({notificationType}) => <NotificationTypeLink notificationType={notificationType} {...linkProps(this.props)} />
}))
