import React, {useMemo} from "react"
import {School, Subscription} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/schools/nav"
import Routes from "shared/routes"
import SubscriptionsTable from "components/subscriptions/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolSubscriptions extends ShapeComponent {
  setup() {
    const currentUser = useCurrentUser()
    const canCan = useCanCan(() => [[Subscription, ["create"]]], [currentUser?.id()])
    const params = useParams()
    const {school, schoolId} = useModel(School, {match: {params}})
    const {locale, t} = useI18n({namespace: "js.routes.admin.schools.subscriptions"})

    const subscriptionsQuery = useMemo(
      () => Subscription.ransack({
        resource_type_eq: "School",
        resource_id_eq: schoolId
      }),
      [schoolId]
    )

    const actions = useMemo(
      () => <>
        {canCan?.can("create", Subscription) &&
          <Button
            className="new-subscription-button"
            icon="plus"
            label={t(".add_new_subscription")}
            to={Routes.newAdminSubscriptionPath({subscription: {resource_id: schoolId, resource_type: "School"}})}
          />
        }
      </>,
      [canCan, locale, schoolId]
    )

    this.setInstance({school, subscriptionsQuery})
    useAdminLayout()?.setState({actions, active: "schools", headerTitle: School.humanAttributeName("subscriptions")})
  }

  render() {
    const {school, subscriptionsQuery} = this.tt

    return (
      <View dataSet={{route: "admin--schools--subscriptions"}}>
        {school &&
          <Nav active="subscriptions" school={school} style={{marginBottom: 15}} />
        }
        <SubscriptionsTable
          adminLayout
          collection={subscriptionsQuery}
        />
      </View>
    )
  }
}))
