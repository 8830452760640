import {ClassStep, NotificationType} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"
const selectedNotificationTypeAttributes = ["active", "id", "name"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminNotificationTypesEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    this.useStates({classStepsCollection: undefined})

    const {locale, t} = useI18n({namespace: "js.routes.admin.notification_types.edit"})
    const {notificationType} = useModel(NotificationType, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        NotificationType: selectedNotificationTypeAttributes
      }
    })

    const headerTitle = useMemo(
      () => {
        if (this.notificationType?.isNewRecord()) {
          return t(".add_new_notification_type")
        } else if (this.notificationType?.isPersisted()) {
          return t(".edit_notification_type", {name: this.notificationType.name()})
        }
      },
      [locale]
    )

    this.setInstance({locale, notificationType, t})
    useAdminLayout()?.setState({active: "notification-types", headerTitle})

    useMemo(() => {
      this.loadClassSteps()
    }, [])
  }

  loadClassSteps = async () => {
    const classSteps = await ClassStep
      .ransack({s: "position"})
      .select({ClassStep: ["id", "name"]})
      .toArray()

    this.setState({
      classStepsCollection: classSteps.map((classStep) => [classStep.name(), classStep.id()])
    })
  }

  render() {
    const {notificationType} = this.tt

    return (
      <View dataSet={{route: "admin--notification-types--edit"}}>
        {notificationType &&
          <form onSubmit={this.tt.onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${NotificationType.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={notificationType}
                />
              </React.Fragment>
            )}
            <Checkbox attribute="active" model={notificationType} />
            <Button primary save />
          </form>
        }
      </View>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)

    if (this.notificationType.isNewRecord()) {
      formData.append("notification_type[new_id]", this.notificationType.id())
    }

    try {
      await this.notificationType.saveRaw(formData, {form})
      FlashMessage.success(this.t(".the_notification_type_was_saved"))
      AppHistory.push(Routes.adminNotificationTypePath(this.notificationType.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
