import {HoverMenu, HoverMenuItem} from "components/hover-menu"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ChangeRoleModal from "components/school-roles/change-role-modal"
import memo from "set-state-compare/src/memo"
import React from "react"
import RemoveRoleModal from "components/school-roles/remove-role-modal"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolRolesUserCircleOptionsButton extends ShapeComponent {
  render() {
    const {schoolRole, style} = this.p
    const {t} = useI18n({namespace: "js.components.school_roles.user_circle.options_button"})
    const actualStyle = Object.assign({position: "relative"}, style)

    this.useStates({showChangeRoleModal: false, showRemoveRoleModal: false})

    const hoverMenuContent = () => <>
      {schoolRole.can("edit") &&
        <HoverMenuItem
          dataSet={{class: "change-role-pressable"}}
          label={t(".change_role")}
          onPress={this.tt.onChangeRolePressed}
        />
      }
      {schoolRole.can("destroy") &&
        <HoverMenuItem
          dataSet={{class: "remove-attachment-from-school-pressable"}}
          label={t(".remove_attachment_from_school")}
          onPress={this.tt.onRemoveRolePressed}
        />
      }
    </>

    return (
      <View
        dataSet={{class: "components--school-roles--options-button", schoolRoleId: schoolRole.id()}}
        style={actualStyle}
      >
        {this.s.showChangeRoleModal && schoolRole.can("edit") &&
          <ChangeRoleModal onRequestClose={this.tt.onRequestCloseChangeRoleModal} schoolRole={schoolRole} />
        }
        {this.s.showRemoveRoleModal && schoolRole.can("destroy") &&
          <RemoveRoleModal onRequestClose={this.tt.onRequestCloseRemoveRoleModal} schoolRole={schoolRole} />
        }
        <HoverMenu content={hoverMenuContent}>
          <View style={{display: "flex", alignItems: "center", justifyContent: "center", width: 25, height: 25}}>
            <i className="fa-solid fa-ellipsis-vertical" style={{fontSize: 20}} />
          </View>
        </HoverMenu>
      </View>
    )
  }

  onChangeRolePressed = () => this.setState({showChangeRoleModal: true})
  onRemoveRolePressed = () => this.setState({showRemoveRoleModal: true})
  onRequestCloseChangeRoleModal = () => this.setState({showChangeRoleModal: false})
  onRequestCloseRemoveRoleModal = () => this.setState({showRemoveRoleModal: false})
}))
