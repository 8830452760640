import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PageContentsPresentation from "components/page-contents/presentation"
import React from "react"
import {Subscription} from "models"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import {View} from "react-native"
const SubscriptionCard = memo(shapeComponent(class SubscriptionCard extends ShapeComponent {
  render() {
    const {subscription} = this.p

    return (
      <View dataSet={{class: "subscription-card"}}>
        <PageContentsPresentation
          identifier="user/admin/subscription/subscription-card-content"
          liquidVariables={{subscription}}
        />
      </View>
    )
  }
}))

export default memo(shapeComponent(class RoutesUserAdminSchoolClasses extends ShapeComponent {
  setup() {
    const preloads = ["plan", "school", "school_class.school"]
    const select = {
      Plan: ["name"],
      School: ["id", "name"],
      SchoolClass: ["id", "interpretedName"],
      Subscription: ["id"]
    }

    const currentUser = useCurrentUser()
    const {subscriptions: schoolSubscriptions} = useCollection({
      groupBy: ["id"],
      modelClass: Subscription,
      preloads,
      ransack: {
        school_contact_children_relationships_child_id_eq: currentUser?.contactId()
      },
      select
    })
    const {subscriptions: schoolClassSubscriptions} = useCollection({
      groupBy: ["id"],
      modelClass: Subscription,
      preloads,
      ransack: {
        school_class_contact_children_relationships_child_id_eq: currentUser?.contactId()
      },
      select
    })

    const schools = {}

    if (schoolSubscriptions) {
      for (const schoolSubscriptions of schoolSubscriptions) {
        const school = schoolSubscriptions.school()

        if (!(school.id() in schools)) schools[school.id()] = {school, classes: {}, subscriptions: []}

        schools[school.id()].subscriptions.push(schoolSubscriptions)
      }
    }

    if (schoolClassSubscriptions) {
      for (const schoolClassSubscriptions of schoolClassSubscriptions) {
        const schoolClass = schoolClassSubscriptions.schoolClass()
        const school = schoolClass.school()

        if (!(school.id() in schools)) schools[school.id()] = {school, classes: {}, subscriptions: []}
        if (!(schoolClass.id() in schools[school.id()].classes)) schools[school.id()].classes[schoolClass.id()] = {schoolClass, subscriptions: []}

        schools[school.id()].classes[schoolClass.id()].subscriptions.push(schoolClassSubscriptions)
      }
    }

    this.setInstance({schools})

    useFrontLayout()?.setState({active: "user-admin-subscription", headerTitle: Subscription.modelName().human({count: 2})})
  }

  render() {
    const {schools} = this.tt

    return (
      <div className="routes--user--admin--subscription">
        {Object.values(schools).length === 0 &&
          <PageContentsPresentation identifier="user/admin/subscription/no-subscriptions-were-found" />
        }
        {Object.values(schools).map(({school, classes, subscriptions}) =>
          <View key={school.id()}>
            <PageContentsPresentation
              identifier="user/admin/subscription/subscriptions-directly-on-school"
              liquidVariables={{school}}
            />
            {subscriptions.map((subscription) =>
              <SubscriptionCard key={subscription.id()} subscription={subscription} />
            )}
            <PageContentsPresentation
              identifier="user/admin/subscription/school-classes-on-school"
              liquidVariables={{school}}
            />
            {Object.values(classes).map(({schoolClass, subscriptions}) =>
              <View key={schoolClass.id()}>
                <PageContentsPresentation
                  identifier="user/admin/subscription/subscriptions-for-school-class"
                  liquidVariables={{school, schoolClass}}
                />
                {subscriptions.map((subscription) =>
                  <SubscriptionCard key={subscription.id()} subscription={subscription} />
                )}
              </View>
            )}
          </View>
        )}
      </div>
    )
  }
}))
