import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import {User} from "models"
import UsersSendEmailPreviewButton from "components/users/send-email-preview-button"
import UsersTable from "components/users/table"

export default memo(shapeComponent(class RoutesAdminUsersIndex extends ShapeComponent {
  setup() {
    this.canCan = useCanCan(() => [[User, ["create"]]])

    const adminLayout = useAdminLayout()
    const actions = useMemo(
      () => this.actions(),
      [this.canCan]
    )

    adminLayout?.setState({actions, active: "users", className: "routes--admin--users--index", headerTitle: User.modelName().human({count: 2})})
  }

  render() {
    return (
      <div className="routes--admin--users--index">
        <UsersTable adminLayout />
      </div>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", User) &&
          <Button className="new-user-button" icon="plus" label={I18n.t("js.routes.admin.users.index.add_new_user")} to={Routes.newAdminUserPath()} />
        }
        <UsersSendEmailPreviewButton />
      </>
    )
  }
}))
