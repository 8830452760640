import {ContactRelationship, SchoolClass} from "models"
import Cycler, {colors} from "shared/cycler"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import useCollection from "@kaspernj/api-maker/build/use-collection"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UserCircle from "components/school-class-roles/user-circle"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserAdminSchoolClassesRolesIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.user.admin.school_classes.roles.index"})
    const params = useParams()
    const {schoolClassId} = useModel(SchoolClass, {
      match: {params},
      select: {
        SchoolClass: ["id"]
      }
    })
    const {contactRelationships} = useCollection({
      abilities: {
        ContactRelationship: ["destroy", "edit"]
      },
      modelClass: ContactRelationship,
      preloads: ["child.user", "contact_relationship_type"],
      ransack: {
        parent_school_class_id_eq: schoolClassId
      },
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        ContactRelationshipType: ["id", "identifier", "translatedIdentifier"],
        User: ["color", "initials", "name"]
      }
    })

    this.setInstance({
      colorCycler: new Cycler(colors),
      contactRelationships,
      t
    })

    useFrontLayout()?.setState({active: "user-admin-school-classes", headerTitle: t(".users_on_your_school")})
  }

  render() {
    const {colorCycler, contactRelationships} = this.tt

    return (
      <View dataSet={{route: "user/admin/school-classes/roles/index"}}>
        <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          {contactRelationships?.map((contactRelationship) =>
            <UserCircle
              backgroundColor={contactRelationship.child().user().color() || colorCycler.cycle()}
              key={contactRelationship.id()}
              schoolClassRole={contactRelationship}
              style={{marginRight: 10, marginBottom: 10}}
            />
          )}
        </View>
      </View>
    )
  }
}))
