import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Background from "components/user/challenges/show/background.jpg"
import EventEmitter from "events"
import {incorporate} from "incorporator"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import SurveysBottomBar from "components/surveys/bottom-bar"
import SurveysPageContainer from "components/surveys/page-container"
import SurveysPresentation from "components/surveys/presentation"
import SurveysSelectedAttributes from "components/surveys/selected-attributes"
import {TeamSurvey} from "models"
import {useFrontLayout} from "components/front-layout"
import useHiddenTawkWidget from "shared/use-hidden-tawk-widget"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import useQueryParams from "on-location-changed/build/use-query-params"

const preload = ["survey.steps.elements.options", "survey.steps.elements.score_factor", "team.school_class"]

for (const surveyPreload of SurveysSelectedAttributes.preload) {
  preload.push(
    `survey.steps.${surveyPreload}`
  )
}

const pageSelect = {
  SchoolClass: ["id"],
  Survey: ["id", "surveyStepsCount"],
  Team: ["schoolClassId"],
  TeamSurvey: ["id", "preview", "state"]
}

const select = incorporate(pageSelect, SurveysSelectedAttributes.select)

export default memo(shapeComponent(class RoutesUserTeamSurveysShow extends ShapeComponent {
  setup() {
    setLayout("user")

    const {t} = useI18n({namespace: "js.routes.user.school_class_courses.show"})
    const {teamSurvey} = useModel(TeamSurvey, {
      match: this.p.match,
      preload,
      select
    })

    this.setInstance({
      queryParams: useQueryParams(),
      t,
      teamSurvey
    })
    this.useStates({
      surveyStep: undefined,
      teamSurvey: undefined,
      teamSurveyStep: undefined
    })

    useHiddenTawkWidget()

    useFrontLayout()?.setState({
      background: Background,
      bottomBar: this.bottomBar(),
      contentCentered: true,
      headTitle: this.s.surveyStep?.name(),
      topLeft: this.topLeft(),
      unicornTrialTopBar: false
    })
  }

  surveyStepsPresentationEvents = new EventEmitter()

  render() {
    const {teamSurvey} = this.tt
    const {surveyStep, teamSurveyStep} = this.s
    const schoolClass = teamSurvey?.team()?.schoolClass()
    const survey = teamSurvey?.survey()

    return (
      <div
        className="routes--user--team-surveys--show"
        data-survey-step-id={surveyStep?.id()}
        data-team-survey-step-id={teamSurveyStep?.id()}
      >
        {schoolClass && survey && teamSurvey &&
          <SurveysPageContainer>
            <SurveysPresentation
              className="w-100"
              events={this.tt.surveyStepsPresentationEvents}
              onSurveyCompleted={this.tt.onSurveyCompleted}
              onSurveyStepLoaded={this.tt.onSurveyStepLoaded}
              onTeamSurveyStepLoaded={this.tt.onTeamSurveyStepLoaded}
              schoolClass={schoolClass}
              submitButton={false}
              survey={survey}
              teamSurvey={teamSurvey}
            />
          </SurveysPageContainer>
        }
      </div>
    )
  }

  bottomBar() {
    const {onCancelClicked, onEndClicked, onNextStepClicked, onPreviousStepClicked, teamSurvey} = this.tt
    const {surveyStep, teamSurveyStep} = this.s
    const survey = teamSurvey?.survey()

    return (
      <>
        {survey && surveyStep && teamSurveyStep &&
          <SurveysBottomBar
            color="white"
            onCancelClicked={onCancelClicked}
            onEndClicked={onEndClicked}
            onNextStepClicked={onNextStepClicked}
            onPreviousStepClicked={onPreviousStepClicked}
            survey={survey}
            surveyStep={surveyStep}
            teamSurvey={teamSurvey}
            teamSurveyStep={teamSurveyStep}
          />
        }
      </>
    )
  }

  topLeft() {
    const {teamSurvey} = this.tt

    return (
      <>
        {teamSurvey?.team() &&
          <Link className="text-white" to={Routes.userSchoolClassDigitalClassRoomPath(teamSurvey.team().schoolClassId())}>
            <i className="fa fa-chevron-left mr-1" />
            {this.t(".go_to_the_space_of_the_class")}
          </Link>
        }
      </>
    )
  }

  onCancelClicked = () => {
    AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.teamSurvey.schoolClass().id()))
  }

  onEndClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "end"})
  }

  onNextStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "nextStep"})
  }

  onPreviousStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "previousStep"})
  }

  onSurveyStepLoaded = (surveyStep) => this.setState({surveyStep})
  onTeamSurveyStepLoaded = (teamSurveyStep) => this.setState({teamSurveyStep})
  onSurveyCompleted = () => AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.teamSurvey.team().schoolClass().id()))
}))
