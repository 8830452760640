import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsActionsDestroy extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.actions.destroy"})

    this.t = t
  }

  render() {
    const {className, model, ...restProps} = this.props

    return (
      <a
        className={classNames("components--actions--destroy", "action-button", className)}
        href="#"
        onClick={this.tt.onDestroyClicked}
        title={this.t("js.shared.delete")}
        {...restProps}
      >
        <i className="fa fa-fw fa-trash" />
        <span className="action-button-label">
          {this.t("js.shared.delete")}
        </span>
      </a>
    )
  }

  onDestroyClicked = async (e) => {
    e.preventDefault()

    if (!confirm(this.t("js.shared.are_you_sure"))) return

    try {
      await loading(this.t("js.shared.loading"), async () => {
        await this.p.model.destroy()
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
