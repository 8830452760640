import React, {useEffect, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
export default memo(shapeComponent(class ComponentsContentsEditorAbsoluteContainerInputFile extends ShapeComponent {
  static propTypes = propTypesExact({
    files: PropTypes.object.isRequired,
    style: PropTypes.object
  })

  setup() {
    this.fileElementRef = useRef()

    useEffect(() => {
      this.setFilesOfInput()
    }, [])
  }

  setFilesOfInput() {
    const {files} = this.props

    this.fileElementRef.current.files = files
  }

  render() {
    const {fileElementRef} = this
    const {className, files, ...restProps} = this.props

    return (
      <input
        className={classNames("components--contents--editor--absolute-container--input-file", className)}
        ref={fileElementRef}
        type="file"
        {...restProps}
      />
    )
  }
}))
