import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SurveyStep} from "models"
import SurveyStepLink from "components/survey-steps/link"
export default memo(shapeComponent(class ComponentsSurveyStepsTeachers extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--survey-steps--table", className)}
        columns={columns}
        defaultParams={{s: "position"}}
        editModelPath={editModelPath}
        modelClass={SurveyStep}
        select={{SurveyStep: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.surveyStepContent,
      label: SurveyStep.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "position",
      sortKey: "position"
    },
    {
      attribute: "surveyStepElementsCount",
      sortKey: "surveyStepElementsCount"
    },
    {
      attribute: "copyable",
      sortKey: "copyable"
    }
  ]

  editModelPath = ({surveyStep}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSurveyStepPath(surveyStep.id())
  }

  surveyStepContent = ({surveyStep}) => <SurveyStepLink surveyStep={surveyStep} {...linkProps(this.props)} />

  viewModelPath = ({surveyStep}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSurveyStepPath(surveyStep.id())
  }
}))
