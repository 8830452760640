import {ContactEmail, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsUsersForgotPassword extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.users.forgot_password"})

    this.t = t

    this.useStates({
      requestSent: false
    })
  }

  render() {
    const {t} = this.tt
    const {className, ...restProps} = this.props

    return (
      <MainCard
        className={classNames("components--users--forgot-password", className)}
        header={t(".reset_password")}
        subHeader={t(".write_your_email_and_follow_instructions")}
        {...restProps}
      >
        {this.s.requestSent &&
          <div className="we-have-sent-an-email-container">
            {t(".we_have_sent_an_email_about_how_to_reset_your_password")}
          </div>
        }
        {!this.s.requestSent &&
          <form onSubmit={this.tt.onSubmit}>
            <Input className="user-email-input" icon="at" label={ContactEmail.humanAttributeName("email")} name="email" />
            <Button label={t(".send_instructions")} primary style={{width: "100%"}} />
          </form>
        }
      </MainCard>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)

    try {
      await User.requestNewPassword(formData)
      this.setState({requestSent: true})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
