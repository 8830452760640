import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import HayaSelect from "components/woof-haya-select"
import {HelperText} from "react-native-paper"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {Text} from "shared/base"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUtilsSelect extends ShapeComponent {
  static defaultProps = {
    multiple: false
  }

  static propTypes = {
    label: PropTypes.string,
    multiple: PropTypes.bool.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChangeSelected: PropTypes.func
  }

  setup() {
    const {wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "input"}})
    const {errors} = digs(wrapperOpts, "errors")
    const {multiple, name, values} = this.props

    this.errors = errors
    this.form = useForm()

    // Set initial value in form
    useMemo(() => {
      if (this.form && name) {
        let value

        if (multiple) {
          value = values
        } else {
          value = values[0]
        }

        this.form.setValue(name, value)
      }
    }, [])
  }

  render() {
    const {errors} = this.tt
    const {label, onChange, onChangeSelected, ...restProps} = this.props

    return (
      <View dataSet={{component: "utils/select"}}>
        {label &&
          <View style={{marginLeft: 10}}>
            <Text style={{color: "#aaa", fontSize: "12px"}}>
              {label}
            </Text>
          </View>
        }
        <HayaSelect onChange={this.tt.onSelectChanged} {...restProps} />
        {errors.length > 0 &&
          <HelperText dataSet={{class: "errors-helper-text"}} type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  onSelectChanged = ({options}) => {
    const {form} = this.tt
    const {multiple} = this.p
    const {name, onChange, onChangeSelected} = this.props
    const optionValues = options.map((option) => option.value)

    if (form && name) {
      form.setValue(name, multiple ? optionValues : optionValues[0])
    }

    if (onChange) {
      onChange(multiple ? options : options[0])
    }

    if (onChangeSelected) {
      onChangeSelected(multiple ? optionValues : optionValues[0])
    }
  }
}))
