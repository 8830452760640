import FrontLayout, {useFrontLayout} from "components/front-layout"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import setLayout from "shared/set-layout"
export default memo(shapeComponent(class ComponentsUserLayout extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    headTitle: PropTypes.string,
    headerTitle: PropTypes.string
  }

  setup() {
    setLayout("user")

    useFrontLayout()?.setState({
      requireUser: true,
      unicornTrialTopBar: true
    })
  }

  render() {
    const {className, ...restProps} = this.props

    return (
      <FrontLayout className={classNames("components--user--layout", className)} layout="user" {...restProps} />
    )
  }
}))
