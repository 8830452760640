import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CkeditorPresentation from "ckeditor/presentation"
import {Course} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import React from "react"
import ScoreFactorLink from "components/score-factors/link"
import {Text} from "shared/base"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedCourseAttributes = ["backgroundImageUrl", "courseModulesCount", "createdAt", "id", "name", "public", "updatedAt", "valuePoints"]
  .concat(translatedAttributes(["description", "name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminCoursesShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.courses.show"})
    const params = useParams()
    const {course, courseId} = useModel(Course, {
      abilities: {
        Course: ["destroy", "edit"]
      },
      match: {params},
      preload: ["class_steps", "score_factor"],
      select: {
        ClassStep: ["id", "name"],
        Course: selectedCourseAttributes,
        ScoreFactor: ["id", "name"]
      }
    })

    this.setInstance({course, courseId, t})
    useAdminLayout()?.setState({actions: this.actions(), active: "courses", headerTitle: course?.name()})
  }

  render() {
    const {course, t} = this.tt

    return (
      <View dataSet={{route: "admin--courses--show"}}>
        {course &&
          <>
            <Nav active="general" course={course} style={{marginBottom: 15}} />
            <AttributeRows attributes={["id", "public", "valuePoints", "createdAt", "updatedAt"]} model={course} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${Course.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={course}
                />
                <AttributeRow
                  attribute={`description${inflection.camelize(locale)}`}
                  label={`${Course.humanAttributeName("description")} (${Locales.labelForLocale(locale)})`}
                  model={course}
                >
                  <CkeditorPresentation html={course[`description${inflection.camelize(locale)}`]()} />
                </AttributeRow>
              </React.Fragment>
            )}
            <AttributeRow attribute="scoreFactor" model={course}>
              {course.scoreFactor() &&
                <ScoreFactorLink adminLayout scoreFactor={course.scoreFactor()} />
              }
            </AttributeRow>
            <AttributeRow attribute="classSteps" model={course}>
              {course.classSteps().map((classStep) =>
                <div key={classStep.id()}>
                  {classStep.name()}
                </div>
              )}
            </AttributeRow>
            <AttributeRow label={t("js.shared.background_image")}>
              {course.hasBackgroundImageUrl() &&
                <a href={course.backgroundImageUrl()} target="_blank">
                  <img src={course.backgroundImageUrl()} style={{maxWidth: "100%", maxHeight: "100%"}} />
                </a>
              }
              {!course.hasBackgroundImageUrl() &&
                <Text>{t("js.shared.no_image")}</Text>
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }

  actions() {
    const {course} = this.tt

    return (
      <AdminShowActions model={course} />
    )
  }
}))
