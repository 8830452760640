import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {Survey} from "models"
import SurveyLink from "components/surveys/link"
export default memo(shapeComponent(class ComponentsSurveysTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--surveys--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={Survey}
        select={{Survey: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.surveyContent,
      label: Survey.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  editModelPath = ({survey}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSurveyPath(survey.id())
  }

  viewModelPath = ({survey}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSurveyPath(survey.id())
  }

  surveyContent = ({survey}) => <SurveyLink survey={survey} {...linkProps(this.props)} />
}))
