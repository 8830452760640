import {Contact, ContactEmail, ContactPhoneNumber} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import ContactTypeLink from "components/contact-types/link"
import CountryLink from "components/countries/link"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import React from "react"
import SchoolClassLink from "components/school-classes/link"
import SchoolLink from "components/schools/link"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import UserLink from "components/users/link"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactsShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.contacts.show"})
    const params = useParams()
    const {contact, contactId} = useModel(Contact, {
      abilities: {
        Contact: ["destroy", "edit"]
      },
      match: {params},
      preload: ["account_manager", "contact_type", "country", "school", "school_class", "user"],
      select: {
        Contact: ["contactPointsCount", "createdAt", "email", "id", "name", "phoneNumber", "points", "updatedAt"],
        ContactType: ["id", "name"],
        Country: ["id", "name"],
        School: ["id", "name"],
        SchoolClass: ["id", "interpretedName"],
        User: ["id", "name"]
      }
    })

    this.setInstance({contact, contactId, t})
    useAdminLayout()?.setState({actions: this.actions(), active: "contacts", headerTitle: contact?.name()})
  }

  render() {
    const {contact, t} = this.tt

    return (
      <View dataSet={{route: "admin/contacts/show"}}>
        {contact &&
          <>
            <Nav active="general" contact={contact} style={{marginBottom: 15}} />
            <AttributeRows attributes={["id"]} model={contact} />
            <AttributeRow attribute="name" label={t(".name")} model={contact} />
            <AttributeRow attribute="email" label={ContactEmail.humanAttributeName("email")} model={contact} />
            <AttributeRow attribute="phoneNumber" label={ContactPhoneNumber.humanAttributeName("phoneNumber")} model={contact} />
            <AttributeRows attributes={["points", "createdAt", "updatedAt"]} model={contact} />
            <AttributeRow attribute="contactType" model={contact}>
              {contact.contactType() &&
                <ContactTypeLink adminLayout contactType={contact.contactType()} />
              }
            </AttributeRow>
            <AttributeRow attribute="accountManager" model={contact}>
              {contact.accountManager() &&
                <UserLink adminLayout user={contact.accountManager()} />
              }
            </AttributeRow>
            {contact.user() &&
              <AttributeRow attribute="user" model={contact}>
                <UserLink adminLayout user={contact.user()} />
              </AttributeRow>
            }
            {contact.school() &&
              <AttributeRow attribute="school" model={contact}>
                <SchoolLink adminLayout school={contact.school()} />
              </AttributeRow>
            }
            {contact.schoolClass() &&
              <AttributeRow attribute="schoolClass" model={contact}>
                <SchoolClassLink adminLayout schoolClass={contact.schoolClass()} />
              </AttributeRow>
            }
            {contact.country() &&
              <AttributeRow attribute="country" model={contact}>
                <CountryLink adminLayout country={contact.country()} />
              </AttributeRow>
            }
          </>
        }
      </View>
    )
  }

  actions() {
    const {contact} = this.tt

    return (
      <AdminShowActions model={contact} />
    )
  }
}))
