import {ContentPieceType} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: ContentPieceTypesSelectWithContainer, withMemo: ComponentsContentPieceTypesSelect} = selectComponent({
  className: "components--content-piece-types--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ContentPieceType

    query = query
      .ransack({s: "position asc"})
      .select({ContentPieceType: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const contentPieceTypes = await query.toArray()

    return contentPieceTypes.map((contentPieceType) => ({
      text: contentPieceType.name(),
      value: contentPieceType.id()
    }))
  }
})

export default ComponentsContentPieceTypesSelect
export {ContentPieceTypesSelectWithContainer}
