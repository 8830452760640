import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {Contact} from "models"
import ContactsTable from "components/contacts/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminContactsIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const canCan = useCanCan(() => [[Contact, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.contacts.index"})

    this.setInstance({canCan, t})

    useAdminLayout()?.setState({actions: this.actions(), active: "contacts", headerTitle: Contact.modelName().human({count: 2})})
  }

  render() {
    return (
      <View dataSet={{route: "admin-contacts--index"}}>
        <ContactsTable adminLayout defaultParams={{s: "points desc"}} />
      </View>
    )
  }

  actions() {
    const {canCan} = this.tt

    return (
      <>
        {canCan?.can("create", Contact) &&
          <Button
            className="new-contact-button"
            icon="plus"
            label={this.t(".add_new_contact")}
            to={Routes.newAdminContactPath()}
          />
        }
      </>
    )
  }
}))
