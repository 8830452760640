import React, {useMemo} from "react"
import {SchoolClass, SchoolClassContentPiece, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CkeditorPresentation from "ckeditor/presentation"
import DogBackground from "components/user/class-profile/dog-background.png"
import HayaDatePickerInput from "haya-date-picker/src/date-picker/input"
import I18n from "shared/i18n"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import MoodSimpleLineChart from "components/score-factor-groups/mood-simple-line-chart"
import Params from "@kaspernj/api-maker/build/params"
import SanitizedHtml from "shared/sanitized-html"
import SchoolClassSelect from "./class-profile-route/school-class-select"
import ScoreFactorChartSlide from "components/user/school-classes/insights/score-factor-chart-slide"
import ScoreFactorGroupsMoodBarChart from "components/score-factor-groups/mood-bar-chart"
import {Text} from "shared/base"
import UnicornCountChart from "components/user/unicorn/count-chart"
import UnicornEffects from "components/user/unicorn/unicorn-effects"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import useAnythingUnicorn from "components/subscriptions/use-anything-unicorn"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useHasUnicorn from "components/subscriptions/use-has-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserClassProfileStatus extends ShapeComponent {
  setup() {
    const currentUser = useCurrentUser()
    const queryParams = useQueryParams()
    const {locale, t} = useI18n({namespace: "js.routes.user.class_profile"})
    const {hasUnicorn} = useHasUnicorn({schoolClassId: queryParams.school_class_id, user: currentUser})
    const {anythingUnicorn} = useAnythingUnicorn()

    const dateFrom = useMemo(() => {
      if (queryParams.date_from) {
        return new Date(Date.parse(queryParams.date_from))
      } else {
        return moment().subtract(12, "months").toDate()
      }
    }, [queryParams.date_from])

    const dateTo = useMemo(() => {
      if (queryParams.date_to) {
        return new Date(Date.parse(queryParams.date_to))
      } else {
        return new Date()
      }
    }, [queryParams.date_to])

    const {schoolClass, schoolClassId} = useModel(SchoolClass, {
      active: Boolean(queryParams.school_class_id),
      loadByQueryParam: ({queryParams}) => queryParams.school_class_id,
      select: {
        SchoolClass: ["hasUnicornSubscription", "id", "interpretedName"]
      }
    })

    const teamSurveysQuery = useMemo(
      () => TeamSurvey.ransack({
        created_at_gteq: I18n.strftime("%Y-%m-%d", dateFrom),
        created_at_lteq: I18n.strftime("%Y-%m-%d", dateTo),
        state_eq: "completed",
        team_school_class_id_eq: schoolClassId
      }),
      [dateFrom, dateTo, schoolClassId]
    )

    this.setInstance({
      anythingUnicorn,
      currentUser,
      dateFrom,
      dateTo,
      hasUnicorn,
      schoolClass,
      schoolClassIds: useMemo(
        () => {
          if (schoolClass) {
            return [schoolClass.id()]
          }
        },
        [schoolClass?.id()]
      ),
      t,
      teamSurveysQuery
    })
    this.useStates({schoolClassContentPieces: null})

    useMemo(() => {
      if (schoolClass) {
        this.loadSchoolClassContentPieces()
      } else {
        this.setState({schoolClassContentPieces: null})
      }
    }, [currentUser?.id(), locale, schoolClass?.id()])

    useFrontLayout()?.setState({active: "user-class-profile"})
  }

  async loadSchoolClassContentPieces() {
    const schoolClassContentPieces = await SchoolClassContentPiece
      .ransack({
        s: "content_piece_type_current_translation_name",
        school_class_id_eq: this.tt.schoolClass.id(),
        latest_for_school_class: true
      })
      .preload("content_piece_type")
      .select({
        ContentPieceType: ["name"],
        SchoolClassContentPiece: ["content", "id"]
      })
      .toArray()

    this.setState({schoolClassContentPieces})
  }

  render() {
    const {anythingUnicorn, currentUser, schoolClass} = this.tt

    return (
      <View dataSet={{route: "user/class-profile"}}>
        {(() => {
          if (!anythingUnicorn || schoolClass?.hasUnicornSubscription() === false) {
            return <UnicornUpgradeOverlay />
          } else if (isUserA(currentUser, "tester")) {
            return this.content()
          } else {
            return this.comingSoonContent()
          }
        })()}
      </View>
    )
  }

  comingSoonContent() {
    const {t} = this.tt

    return (
      <>
        <div className="coming-soon-container" style={{backgroundImage: `url('${DogBackground}')`}}>
          <div className="coming-soon-text-container">
            <div className="coming-soon-header">
              {t(".header")}
            </div>
            <div className="coming-soon-body">
              <SanitizedHtml html={t(".body")} />
            </div>
          </div>
        </div>
      </>
    )
  }

  content() {
    const {dateFrom, dateTo, schoolClass, schoolClassIds, t, teamSurveysQuery} = this.tt

    return (
      <View>
        <View style={{flexDirection: "row"}}>
          <View style={{width: "60%"}} />
          <View style={{width: "20%"}}>
            <SchoolClassSelect />
          </View>
          <View style={{width: "20%", paddingLeft: 15}}>
            <HayaDatePickerInput
              datePickerProps={{dateFrom, dateTo, mode: "dateRange", onSelect: this.tt.onDateRangeSelect}}
              styles={{
                text: {
                  marginLeft: 12,
                  color: "#666",
                  fontSize: 17
                },
                view: {
                  paddingTop: 17,
                  paddingRight: 26,
                  paddingBottom: 17,
                  paddingLeft: 26,
                  backgroundColor: "#fff",
                  border: "1px solid #dcdcdc",
                  borderRadius: 10
                }
              }}
            />
          </View>
        </View>
        {schoolClass &&
          <>
            <UtilsCard header={schoolClass.interpretedName()} style={{marginTop: 15}}>
              <UtilsCard header={t(".mood")}>
                <View style={{width: 877}}>
                  <MoodSimpleLineChart dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
                </View>
              </UtilsCard>
            </UtilsCard>
            <View style={{marginTop: 15}}>
              <UnicornEffects dateFrom={dateFrom} dateTo={dateTo} schoolClassIds={schoolClassIds} />
            </View>
            <View style={{marginTop: 15}}>
              <UtilsCard style={{maxWidth: 700}}>
                <View style={{width: "calc(100% + 50px)", marginLeft: -50}}>
                  <ScoreFactorGroupsMoodBarChart schoolClass={schoolClass} teamSurveysQuery={teamSurveysQuery} />
                </View>
              </UtilsCard>
            </View>
            <View style={{marginTop: 15}}>
              <UnicornCountChart dateFrom={dateFrom} dateTo={dateTo} schoolClassIds={schoolClassIds} />
            </View>
            <View style={{marginTop: 15}}>
              <ScoreFactorChartSlide dateFrom={dateFrom} dateTo={dateTo} schoolClass={schoolClass} />
            </View>
          </>
        }
        {this.s.schoolClassContentPieces?.map((schoolClassContentPiece) =>
          <View
            key={schoolClassContentPiece.id()}
            style={{
              marginTop: 15,
              backgroundColor: "#4c93ff",
              borderRadius: 10,
              padding: 20
            }}
          >
            <Text style={{color: "#fff", fontSize: 24, fontWeight: "bold"}}>
              {schoolClassContentPiece.contentPieceType().name()}
            </Text>
            <View
              style={{
                flex: 0,
                marginTop: 10,
                padding: 20,
                backgroundColor: "#fff",
                borderRadius: 34
              }}
            >
              <CkeditorPresentation html={schoolClassContentPiece.content()} />
            </View>
          </View>
        )}
      </View>
    )
  }

  onDateRangeSelect = ({fromDate, toDate}) => {
    Params.changeParams({date_from: I18n.strftime("%Y-%m-%d", fromDate), date_to: I18n.strftime("%Y-%m-%d", toDate)})
  }
}))
