import React, {useMemo} from "react"
import {SchoolClass, ScoreFactor, TeamSurvey} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import ScoreFactorsPositiveNegativeAreaChart from "components/score-factors/positive-negative-area-chart"
import useQueryParams from "on-location-changed/build/use-query-params"

const ScoreFactorButton = memo(shapeComponent(class ScoreFactorButton extends ShapeComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    scoreFactor: PropTypes.instanceOf(ScoreFactor).isRequired
  }

  render() {
    const {onClick: propsOnClick, scoreFactor, ...restProps} = this.props
    const {onClick} = this.tt

    return (
      <div
        className="score-factor-button"
        data-score-factor-id={scoreFactor.id()}
        onClick={onClick}
        {...restProps}
      >
        {scoreFactor.hasIcon() &&
          <i className={`fa fa-fw fa-${scoreFactor.icon()}`} style={{fontSize: "20px"}} />
        }
        {!scoreFactor.hasIcon() &&
          scoreFactor.name()
        }
      </div>
    )
  }

  onClick = (e) => {
    const {onClick, scoreFactor} = this.p

    onClick({e, scoreFactor})
  }
}))

export default memo(shapeComponent(class ComponentsUserSchoolClassesInsightsScoreFactorChartSlide extends ShapeComponent {
  static propTypes = propTypesExact({
    className: PropTypes.string,
    dateFrom: PropTypes.instanceOf(Date).isRequired,
    dateTo: PropTypes.instanceOf(Date).isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  })

  setup() {
    const {dateFrom, dateTo, schoolClass} = this.p

    this.queryParams = useQueryParams()

    this.useStates({
      scoreFactors: undefined,
      teamSurveys: useMemo(
        () => TeamSurvey.ransack({
          created_at_gteq: dateFrom,
          created_at_lteq: dateTo,
          school_class_check_in_school_class_id_eq: schoolClass.id(),
          state_eq: "completed"
        }),
        [dateFrom, dateTo, schoolClass.id()]
      )
    })

    useMemo(() => {
      this.loadScoreFactors()
    }, [])
  }

  async loadScoreFactors() {
    const {schoolClass} = this.p
    const scoreFactors = await ScoreFactor
      .ransack({
        s: "current_translation_name",
        score_factor_groups_identifier_eq: "mood_bar_chart",
        survey_step_element_option_scores_option_answers_team_survey_school_class_id_eq: schoolClass.id()
      })
      .select({ScoreFactor: ["icon", "id", "name"]})
      .groupBy("id")
      .toArray()

    this.setState({scoreFactors})
  }

  render() {
    const {onScoreFactorClicked} = this.tt
    const {className} = this.props
    const {scoreFactors, teamSurveys} = this.s
    const activeScoreFactor = this.activeScoreFactor()

    return (
      <div className={classNames("components--user--school-classes--insights--score-factor-chart-slide", className)}>
        <div className="score-factors-container">
          {scoreFactors?.map((scoreFactor) =>
            <ScoreFactorButton
              data-active={this.isScoreFactorActive(scoreFactor)}
              key={scoreFactor.id()}
              onClick={onScoreFactorClicked}
              scoreFactor={scoreFactor}
            />
          )}
        </div>
        {activeScoreFactor &&
          <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
            <Card header={activeScoreFactor.name()} style={{width: "100%", maxWidth: "700px", marginTop: "40px"}}>
              <ScoreFactorsPositiveNegativeAreaChart
                dateFrom={this.p.dateFrom}
                dateTo={this.p.dateTo}
                key={activeScoreFactor.id()}
                scoreFactor={activeScoreFactor}
                teamSurveys={teamSurveys}
              />
            </Card>
          </div>
        }
      </div>
    )
  }

  activeScoreFactor() {
    const {queryParams} = this.tt
    const {scoreFactors} = this.s

    if (!scoreFactors) return

    if (queryParams.active_score_factor_id) {
      return scoreFactors.find((loadedScoreFactor) => loadedScoreFactor.id() == queryParams.active_score_factor_id)
    }

    return scoreFactors[0]
  }

  isScoreFactorActive(scoreFactor) {
    const {queryParams} = this.tt
    const {scoreFactors} = this.s

    if (queryParams.active_score_factor_id) {
      if (queryParams.active_score_factor_id == scoreFactor.id()) return true
    } else if (scoreFactors && scoreFactor.id() == scoreFactors[0].id()) {
      return true
    }

    return false
  }

  onScoreFactorClicked = ({e, scoreFactor}) => {
    e.preventDefault()
    Params.changeParams({active_score_factor_id: scoreFactor.id()})
  }
}))
