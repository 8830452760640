import {Contact, ContactEmail} from "models"
import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import ContactEmailsTable from "components/contact-emails/table"
import Icon from "components/icon"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"

export default memo(shapeComponent(class RoutesAdminContactsEmails extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.contacts.emails"})
    const params = useParams()
    const {contact, contactId} = useModel(Contact, {
      match: {params},
      select: {
        Contact: ["contactPointsCount", "id"]
      }
    })
    const contactEmailsQuery = useMemo(() => ContactEmail.ransack({contact_id_eq: contactId}), [contactId])

    this.setInstance({contact, contactEmailsQuery, contactId})

    useAdminLayout()?.setState({actions: this.actions(), active: "contacts", headerTitle: t(".emails")})
  }

  render() {
    const {contact, contactEmailsQuery} = this.tt

    return (
      <View dataSet={{route: "admin--contacts--emails"}}>
        {contact &&
          <Nav active="emails" contact={contact} style={{marginBottom: 10}} />
        }
        <ContactEmailsTable collection={contactEmailsQuery} />
      </View>
    )
  }

  actions = () => <>
    <Pressable dataSet={{class: "add-email-action"}} onPress={this.tt.onAddEmailPress}>
      <Icon icon="pen-solid" />
    </Pressable>
  </>

  onAddEmailPress = () => AppHistory.push(Routes.newAdminContactEmailPath({contact_email: {contact_id: this.tt.contactId}}))
}))
