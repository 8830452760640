import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import {Plan} from "models"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
const selectedPlanAttributes = ["autoRenew", "id", "identifier", "name", "position", "showInSignUpModal", "uniquenessIdentifier"]
  .concat(translatedAttributes(["description", "name", "subTitle"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminPlansEdit extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.plans.edit"})
    const {plan, planId} = useModel(Plan, {
      match: this.props.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        Plan: selectedPlanAttributes
      }
    })

    this.setInstance({plan, planId, t})
  }

  render() {
    const {plan, t} = this.tt

    const headerTitle = useMemo(
      () => {
        if (this.plan?.isNewRecord()) {
          return t(".add_new_plan")
        } else if (this.plan?.isPersisted()) {
          return t(".edit_plan", {name: this.plan.name()})
        }
      },
      [plan?.id()]
    )

    return (
      <AdminLayout active="plans" className="routes--admin--plans--edit" headerTitle={headerTitle}>
        {plan &&
          <form onSubmit={this.tt.onSubmit}>
            <div className="grid grid-cols-2 grid-gap-10">
              {Locales.availableLocales().map((locale) =>
                <React.Fragment key={locale}>
                  <div className="col-span-2 col-span-md-1">
                    <Input
                      attribute={`name${inflection.camelize(locale)}`}
                      label={`${Plan.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                      model={plan}
                    />
                  </div>
                  <div className="col-span-2">
                    <Input
                      attribute={`subTitle${inflection.camelize(locale)}`}
                      label={`${Plan.humanAttributeName("subTitle")} (${Locales.labelForLocale(locale)})`}
                      model={plan}
                      type="ckeditor"
                    />
                  </div>
                  <div className="col-span-2">
                    <Input
                      attribute={`description${inflection.camelize(locale)}`}
                      label={`${Plan.humanAttributeName("description")} (${Locales.labelForLocale(locale)})`}
                      model={plan}
                      type="ckeditor"
                    />
                  </div>
                </React.Fragment>
              )}
              <div className="col-span-2 col-span-md-1">
                <Input attribute="identifier" model={plan} />
              </div>
              <div className="col-span-2 col-span-md-1">
                <Input attribute="uniquenessIdentifier" model={plan} />
              </div>
              <div className="col-span-2 col-span-md-1">
                <Input attribute="position" model={plan} />
              </div>
              <div className="col-span-2 col-span-md-1">
                <Checkbox attribute="showInSignUpModal" model={plan} />
              </div>
              <div className="col-span-2 col-span-md-1">
                <Checkbox attribute="autoRenew" model={plan} />
              </div>
            </div>
            <Button className="mt-4" primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")

    try {
      await this.plan.saveRaw(form)
      FlashMessage.success(this.t(".the_plan_was_saved"))
      AppHistory.push(Routes.adminPlanPath(this.plan.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
