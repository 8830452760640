import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {SchoolClass, SchoolClassCheckIn} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import memo from "set-state-compare/src/memo"
import Params from "@kaspernj/api-maker/build/params"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import Slide from "components/slider/slide"
import Slide1 from "./slide-1"
import Slide2 from "./slide-2"
import Slide3 from "./slide-3"
import Slider from "components/slider"
import {Text} from "shared/base"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"

export default memo(shapeComponent(class RoutesUserInsights extends ShapeComponent {
  setup() {
    setLayout("user")

    const {locale, t} = useI18n({namespace: "js.routes.user.insights"})
    const params = useParams()
    const {schoolClass, schoolClassId} = useModel(SchoolClass, {
      match: {params}
    })
    const {schoolClassCheckIn, schoolClassCheckInNotFound} = useModel(
      SchoolClassCheckIn,
      {
        abilities: {
          SchoolClass: ["invite"]
        },
        match: {params},
        preload: ["school_class.class_step"],
        select: {
          ClassStep: ["primarySchoolStep"],
          SchoolClass: ["id", "interpretedName", "schoolId"],
          SchoolClassCheckIn: ["createdAt", "id", "state"]
        }
      }
    )

    this.setInstance({
      currentUser: useCurrentUser(),
      queryParams: useQueryParams(),
      schoolClass,
      schoolClassId,
      headerTitle: schoolClassCheckIn && t(".this_is_the_result_of_your_check_in"),
      schoolClassCheckIn,
      schoolClassCheckInNotFound,
      t
    })
    this.useStates({
      slide1loaded: false,
      slide2loaded: false,
      slide3loaded: false
    })

    const topLeft = useMemo(
      () => <>
        <Pressable onPress={this.tt.onBackPressed} style={{alignItems: "center", flexDirection: "row"}}>
          <Text style={{marginRight: 10, marginTop: -7, color: "#979797", fontSize: 50}}>
            &lsaquo;
          </Text>
          <Text style={{color: "#4c93ff", fontSize: 20, fontWeight: "bold"}}>
            {t(".go_to_the_space_of_the_class")}
          </Text>
        </Pressable>
      </>,
      [locale, schoolClassId]
    )

    useFrontLayout()?.setState({
      headTitle: this.tt.headerTitle,
      topLeft
    })
  }

  onBackPressed = () => AppHistory.push(Routes.userSchoolClassDigitalClassRoomPath(this.tt.schoolClassId))

  render() {
    const {schoolClass, schoolClassCheckIn} = this.tt
    const activeSlide = this.activeSlide()

    if (activeSlide == 0) {
      this.setState({slide1loaded: true})
    } else if (activeSlide == 1) {
      this.setState({slide2loaded: true})
    } else if (activeSlide == 2) {
      this.setState({slide3loaded: true})
    }

    return (
      <View dataSet={{route: "user--school-classes--school-class-check-ins--insights"}}>
        <Slider active={activeSlide} onSlideChanged={this.tt.onSlideChanged} total={3}>
          <Slide active={activeSlide == 0}>
            {this.s.slide1loaded && schoolClass && schoolClassCheckIn &&
              <Slide1 schoolClass={schoolClass} schoolClassCheckIn={schoolClassCheckIn} />
            }
          </Slide>
          <Slide active={activeSlide == 1}>
            {this.s.slide2loaded && schoolClass && schoolClassCheckIn &&
              <Slide2 schoolClass={schoolClass} schoolClassCheckIn={schoolClassCheckIn} />
            }
          </Slide>
          <Slide active={activeSlide == 2}>
            {this.s.slide3loaded && schoolClass && schoolClassCheckIn &&
              <Slide3 schoolClass={schoolClass} schoolClassCheckIn={schoolClassCheckIn} />
            }
          </Slide>
        </Slider>
      </View>
    )
  }

  activeSlide = () => Number(this.tt.queryParams.slide || 0)
  onSlideChanged = ({number}) => Params.changeParams({slide: number})
}))
