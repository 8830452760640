import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminUsersNav from "components/admin/users/nav"
import AuditsPage from "components/admin/audits/page"
import memo from "set-state-compare/src/memo"
import React from "react"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {User} from "models"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserAudits extends ShapeComponent {
  setup() {
    const adminLayout = useAdminLayout()
    const {user} = useModel(User, {
      match: this.p.match,
      preload: [
        "contact.parent_relationships.parent.school",
        "contact.parent_relationships.parent.school_class",
        "school_classes",
        "schools"
      ],
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        School: ["id"],
        SchoolClass: ["id"],
        User: ["accountUsersCount", "id", "subscriptionsCount", "userRolesCount"]
      }
    })

    this.user = user

    adminLayout?.setState({
      active: "users",
      headerTitle: User.humanAttributeName("audits")
    })
  }

  render() {
    const {user} = this.tt

    return (
      <View dataSet={{route: "admin--users--audits"}}>
        {user &&
          <>
            <AdminUsersNav active="audits" style={{marginBottom: 15}} user={user} />
            <AuditsPage resource={user} />
          </>
        }
      </View>
    )
  }
}))
