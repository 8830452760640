import {CheckIn, ClassStep, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import ApiMakerTable from "shared/api-maker-table"
import CheckInLink from "components/check-ins/link"
import classNames from "classnames"
import ClassStepLink from "components/class-steps/link"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SurveyLink from "components/surveys/link"
import {Text} from "shared/base"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsCheckIns extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    currentUser: PropTypes.instanceOf(User),
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    this.currentUser = useCurrentUser()
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--check-ins--table", className)}
        collection={CheckIn.ransack().groupBy("id")}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={CheckIn}
        preloads={["check_in_class_steps.class_step", "survey"]}
        select={{
          CheckIn: ["id", "name", "public"],
          CheckInClassStep: ["id"],
          ClassStep: ["id", "name"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        content: this.tt.checkInContent,
        label: CheckIn.humanAttributeName("name"),
        sortKey: "currentTranslationName"
      },
      {
        content: this.tt.surveyContent,
        label: CheckIn.humanAttributeName("survey"),
        sortKey: "surveyCurrentTranslationName"
      },
      {
        attribute: "public",
        sortKey: "public"
      },
      {
        content: this.tt.classStepsContent,
        identifier: "class-steps",
        label: ClassStep.modelName().human({count: 2}),
        sortKey: "checkInClassStepsClassStepCurrentTranslationName"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.tt.currentUser)

    return columns
  }

  classStepsContent = ({checkIn}) => {
    return (
      <View style={{flexDirection: "row", flexWrap: "wrap"}}>
        {checkIn.checkInClassSteps().loaded().map((checkInClassStep, checkInClassStepIndex) =>
          <React.Fragment key={checkInClassStep.id()}>
            {checkInClassStepIndex > 0 && <Text>, </Text>}<ClassStepLink classStep={checkInClassStep.classStep()} {...linkProps(this.props)} />
          </React.Fragment>
        )}
      </View>
    )
  }

  editModelPath = ({checkIn}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminCheckInPath(checkIn.id())
  }

  viewModelPath = ({checkIn}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminCheckInPath(checkIn.id())
  }

  checkInContent = ({checkIn}) => <CheckInLink checkIn={checkIn} {...linkProps(this.props)} />
  surveyContent = ({checkIn}) => checkIn.survey() && <SurveyLink survey={checkIn.survey()} {...linkProps(this.props)} />
}))
