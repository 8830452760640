import * as models from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import {Audit} from "models"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import ModelLink from "components/model-link"
import setLayout from "shared/set-layout"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import useQueryParams from "on-location-changed/build/use-query-params"
import UserLink from "components/users/link"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminAuditsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const queryParams = useQueryParams()
    const auditClass = queryParams.audit_class
    const modelClass = digg(models, auditClass)
    const params = useParams()
    const auditId = digg(params, "audit_id")
    const auditQuery = useMemo(() => modelClass.ransack({id_eq: auditId}), [auditClass, auditId])
    const {model: audit} = useModel(modelClass, {
      preload: ["audit_action", "current_user"],
      query: auditQuery,
      select: {
        Audit: ["auditable", "auditedChanges", "createdAt", "extraLiquidVariables", "id", "params", "updatedAt"],
        AuditAction: ["action", "id"],
        User: ["id", "name"]
      }
    })

    this.audit = audit

    useAdminLayout()?.setState({active: "audits", headerTitle: Audit.modelName().human()})
  }

  render() {
    const {audit} = this.tt

    return (
      <View dataSet={{route: "admin--audits--show"}}>
        {audit &&
          <>
            <AttributeRows attributes={["id"]} model={audit} />
            {audit.auditAction() &&
              <AttributeRows attributes={["action"]} model={audit.auditAction()} />
            }
            <AttributeRows attributes={["createdAt", "updatedAt"]} model={audit} />
            <AttributeRow attribute="currentUser" model={audit}>
              {audit.currentUser() &&
                <UserLink adminLayout user={audit.currentUser()} />
              }
            </AttributeRow>
            <AttributeRow attribute="auditable" model={audit}>
              {audit.auditable() &&
                <ModelLink adminLayout model={audit.auditable()} />
              }
            </AttributeRow>
            <AttributeRow attribute="auditedChanges" model={audit}>
              <pre>
                {JSON.stringify(audit.auditedChanges(), null, 2)}
              </pre>
            </AttributeRow>
            <AttributeRow attribute="extraLiquidVariables" model={audit}>
              <pre>
                {JSON.stringify(audit.extraLiquidVariables(), null, 2)}
              </pre>
            </AttributeRow>
            <AttributeRow attribute="params" model={audit}>
              <pre>
                {JSON.stringify(audit.params(), null, 2)}
              </pre>
            </AttributeRow>
          </>
        }
      </View>
    )
  }
}))
