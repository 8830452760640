import {ContactRelationship, School} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import ContactRelationshipsTable from "components/contact-relationships/table"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/schools/nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolsSchoolRoles extends ShapeComponent {
  setup() {
    this.canCan = useCanCan(() => [[ContactRelationship, ["create"]]])

    const {t} = useI18n({namespace: "js.routes.admin.school_classes.school_class_roles"})
    const params = useParams()
    const {school, schoolId} = useModel(School, {match: {params}})

    this.setInstance({
      school,
      schoolId,
      schoolRolesQuery: useMemo(
        () => ContactRelationship.ransack({
          parent_school_id_eq: schoolId,
          child_user_id_not_null: 1
        }),
        [schoolId]
      ),
      t
    })
    useAdminLayout()?.setState({actions: this.actions(), active: "schools", headerTitle: School.humanAttributeName("schoolRoles")})
  }

  render() {
    const {school, schoolRolesQuery} = this.tt

    return (
      <View dataSet={{route: "admin/schools/school-roles"}}>
        {school &&
          <Nav active="school-roles" school={school} style={{marginBottom: 15}} />
        }
        <ContactRelationshipsTable
          adminLayout
          collection={schoolRolesQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, school, t} = this.tt

    return (
      <>
        {canCan?.can("create", ContactRelationship) && school &&
          <Button
            className="new-school-role-button"
            icon="plus"
            label={t(".new_role_on_class")}
            to={Routes.newAdminContactRelationshipPath({contact_relationship: {parent_id: school.contactId()}})}
          />
        }
      </>
    )
  }
}))
