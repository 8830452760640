import {EmailTemplate} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectEmailTemplates,
  withInputContainer: EmailTemplatesSelectWithContainer,
  withMemo: ComponentsEmailTemplatesSelect
} = selectComponent({
  className: "components--email-templates--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || EmailTemplate

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({EmailTemplate: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const emailTemplates = await query.toArray()

    return emailTemplates.map((emailTemplate) => ({
      text: emailTemplate.name(),
      value: emailTemplate.id()
    }))
  }
})

export default ComponentsEmailTemplatesSelect
export {AdminSelectEmailTemplates, EmailTemplatesSelectWithContainer}
