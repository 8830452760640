import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import {CourseGroup} from "models"
import CourseGroupLink from "components/course-groups/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default class ComponentsCourseGroupsTable extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--course-groups--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={CourseGroup}
        select={{CourseGroup: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.courseGroupContent,
      label: CourseGroup.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "identifier",
      sortKey: "identifier"
    }
  ]

  editModelPath = ({courseGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminCourseGroupPath(courseGroup.id())
  }

  viewModelPath = ({courseGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminCourseGroupPath(courseGroup.id())
  }

  courseGroupContent = ({courseGroup}) => <CourseGroupLink courseGroup={courseGroup} {...linkProps(this.props)} />
}
