import CockerImage from "images/dogs/cocker.png"
import HoensImage from "images/dogs/hoens.png"
import PugImage from "images/dogs/pug.png"

export default function componnentsClassStepsDogImage(classStep) {
  if (!classStep?.primarySchoolStep() || classStep?.primarySchoolStep() <= 3) {
    return PugImage
  } else if (classStep?.primarySchoolStep() <= 6) {
    return CockerImage
  }

  return HoensImage
}
