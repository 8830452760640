import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsUserSchoolClassesDigitalClassRoomInstructions extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})

    return (
      <div className="components--user--school-classes--digital-class-room--instructions col-span-3 col-span-xl-2 instructions-container">
        <div className="three-check-ins-text">
          {t(".three_check_ins_text")}
        </div>
      </div>
    )
  }
}))
