import {Contact, ContactEmail} from "models"
export default {
  table: {
    columns: () => [
      {
        attribute: "firstName",
        label: Contact.humanAttributeName("firstName"),
        sortKey: "firstName"
      },
      {
        attribute: "email",
        label: ContactEmail.humanAttributeName("email"),
        sortKey: "email"
      },
      {
        attribute: "signInCount",
        sortKey: "signInCount"
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      },
      {
        attribute: "updatedAt",
        sortKey: "updatedAt"
      }
    ]
  }
}
