import BaseComponent from "components/base-component"
import classNames from "classnames"
import {Course} from "models"
import Link from "@kaspernj/api-maker/build/link"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default class ComponentsCoursesLink extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    course: PropTypes.instanceOf(Course).isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, course, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--courses--link", className)

    if (adminLayout) {
      return (
        <Link className={generatedClassName} to={this.to()} {...restProps}>
          {children || course.name()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || course.name()}
      </span>
    )
  }

  to() {
    const {adminLayout, course} = this.p

    if (adminLayout) {
      return Routes.adminCoursePath(course.id())
    }

    return "#"
  }
}
