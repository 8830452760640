import {Contact, School, Subscription, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import {AdminSelectCountries} from "components/countries/select"
import {AdminSelectPlans} from "components/plans/select"
import {AdminSelectUsers} from "components/users/select"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import CountryLink from "components/countries/link"
import isUserA from "components/users/is-a"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import SchoolClassLink from "components/school-classes/link"
import SchoolLink from "components/schools/link"
import SubscriptionLink from "components/subscriptions/link"
import {Text} from "shared/base"
import uniqunize from "uniqunize"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UserLink from "components/users/link"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsContactsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.contacts.table"})

    this.accountManagersCollection = useMemo(
      () => User.ransack({with_role: ["admin", "hacker"]}).groupBy("id"),
      []
    )
    this.currentContact = useCurrentUser()
    this.t = t
  }

  render() {
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--contacts--table", className)}
        columns={this.tt.columns}
        editModelPath={this.tt.editModelPath}
        filterContent={this.tt.filterContent}
        modelClass={Contact}
        preloads={[
          "account_manager",
          "parent_relationships.parent.school_class",
          "school.active_subscriptions.plan",
          "user.active_subscriptions.plan",
          "user.school_classes.active_subscriptions.plan",
          "user.school_classes.school.active_subscriptions.plan",
          "user.schools.active_subscriptions.plan"
        ]}
        select={{
          Contact: ["createdAt", "email", "id", "name", "updatedAt"],
          ContactRelationship: ["id"],
          Country: ["id"],
          Plan: ["name"],
          School: ["id", "name"],
          SchoolClass: ["id", "interpretedName"],
          Subscription: ["id"],
          User: ["id"]
        }}
        viewModelPath={this.tt.viewModelPath}
        workplace={isUserA(this.tt.currentContact, ["admin", "hacker"])}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        attribute: "name",
        sortKey: "currentTranslationName"
      },
      {
        attribute: "name",
        label: Contact.humanAttributeName("contactType"),
        path: ["contactType"],
        sortKey: "contactTypeCurrentTranslationName"
      },
      {
        attribute: "phoneNumber",
        path: ["primaryPhoneNumber"],
        sortKey: "primaryPhoneNumberPhoneNumber"
      },
      {
        attribute: "email",
        path: ["primaryEmail"],
        sortKey: "primaryEmailEmail"
      },
      {
        attribute: "name",
        content: this.tt.countryContent,
        label: Contact.humanAttributeName("country"),
        path: ["country"],
        sortKey: "countryCurrentTranslationName"
      },
      {
        attribute: "name",
        content: this.tt.accountManagerContent,
        label: Contact.humanAttributeName("accountManager"),
        path: ["accountManager"],
        sortKey: "accountManagerFirstName"
      },
      {
        content: this.tt.schoolsContent,
        label: School.modelName().human({count: 2}),
        sortKey: "userSchoolsCurrentTranslationName"
      },
      {
        attribute: "points",
        sortKey: "points"
      },
      {
        attribute: "pointsLastSevenDays",
        sortKey: "pointsLastSevenDays"
      },
      {
        attribute: "pointsLastThirtyDays",
        sortKey: "pointsLastThirtyDays"
      },
      {
        attribute: "pointsLastNinetyDays",
        sortKey: "pointsLastNinetyDays"
      },
      {
        content: this.tt.subscriptionsContent,
        identifier: "subscriptions",
        label: Subscription.modelName().human({count: 2})
      },
      {
        content: this.tt.schoolClassesCountContent,
        identifier: "school-classes-count",
        label: this.t(".school_classes_count")
      },
      {
        attribute: "createdAt",
        sortKey: "createdAt"
      },
      {
        attribute: "updatedAt",
        sortKey: "updatedAt"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.tt.currentContact)

    return columns
  }

  accountManagerContent = ({contact}) => contact.accountManager() && <UserLink user={contact.accountManager()} {...linkProps(this.props)} />
  countryContent = ({contact}) => contact.country() && <CountryLink country={contact.country()} {...linkProps(this.props)} />

  editModelPath = ({contact}) => {
    if (this.p.adminLayout) return Routes.editAdminContactPath(contact.id())
  }

  filterContent = ({qParams}) =>
    <View>
      <View style={{marginVertical: 10}}>
        <AdminSelectCountries
          attribute="countryId"
          defaultValues={qParams.country_id_eq_any}
          id="country_id_eq_any"
          label={Contact.humanAttributeName("country")}
          multiple
          name="country_id_eq_any"
        />
      </View>
      <View style={{marginVertical: 10}}>
        <AdminSelectUsers
          attribute="accountManagerId"
          defaultValues={qParams.account_manager_id_eq_any}
          id="account_manager_id_eq_any"
          label={Contact.humanAttributeName("accountManager")}
          multiple
          name="account_manager_id_eq_any"
          query={this.tt.accountManagersCollection}
        />
      </View>
      <View style={{marginVertical: 10}}>
        <AdminSelectPlans
          defaultValues={qParams.user_active_subscriptions_plan_id_or_school_active_subscriptions_plan_id_or_school_class_active_subscriptions_plan_id_eq_any}
          id="user_active_subscriptions_plan_id_or_school_active_subscriptions_plan_id_or_school_class_active_subscriptions_plan_id_eq_any"
          label={Subscription.modelName().human()}
          multiple
          name="user_active_subscriptions_plan_id_or_school_active_subscriptions_plan_id_or_school_class_active_subscriptions_plan_id_eq_any"
        />
      </View>
    </View>

  schoolClassesCountContent = ({contact}) => {
    let schoolClassRolesCount = 0

    for (const contactRelationship of contact.parentRelationships().loaded()) {
      if (contactRelationship.parent().schoolClass()) {
        schoolClassRolesCount += 1
      }
    }

    return schoolClassRolesCount
  }

  schoolsContent = ({contact}) =>
    <View style={{flexDirection: "row", flexWrap: "wrap"}}>
      {contact.user()?.schools()?.loaded()?.map((school, schoolIndex) =>
        <React.Fragment key={school.id()}>
          <SchoolLink school={school} {...linkProps(this.props)} />
          {schoolIndex < (contact.user().schools().loaded().length - 1) && <Text>, </Text>}
        </React.Fragment>
      )}
    </View>

  subscriptionsContent = ({contact}) => {
    let subscriptions = []
    const school = contact.school()
    const user = contact.user()

    if (school) {
      for (const subscription of school.activeSubscriptions().loaded()) {
        subscriptions.push({
          school,
          subscription,
          type: "direct-on-school"
        })
      }
    }

    if (user) {
      for (const subscription of user.activeSubscriptions().loaded()) {
        subscriptions.push({
          subscription,
          type: "direct-on-user",
          user
        })
      }

      for (const school of user.schools().loaded()) {
        const schoolActiveSubscriptions = school?.activeSubscriptions()?.loaded() || []

        for (const subscription of schoolActiveSubscriptions) {
          subscriptions.push({
            school,
            subscription,
            type: "school"
          })
        }
      }

      for (const schoolClass of user.schoolClasses().loaded()) {
        const schoolClassActiveSubscriptions = schoolClass?.activeSubscriptions()?.loaded() || []
        const schoolClassSchoolActiveSubscriptions = schoolClass?.school()?.activeSubscriptions()?.loaded() || []

        for (const subscription of schoolClassActiveSubscriptions) {
          subscriptions.push({
            schoolClass,
            subscription,
            type: "school-class"
          })
        }

        for (const subscription of schoolClassSchoolActiveSubscriptions) {
          subscriptions.push({
            school: schoolClass.school(),
            subscription,
            type: "school"
          })
        }
      }
    }

    subscriptions = uniqunize(subscriptions, ({subscription}) => subscription.id())

    return (
      <View style={{flexDirection: "row", flexWrap: "wrap"}}>
        {subscriptions.map(({school, schoolClass, subscription, type}, subscriptionIndex) =>
          <React.Fragment key={subscription.id()}>
            <SubscriptionLink subscription={subscription} {...linkProps(this.props)}>
              {subscription.plan()?.name()}
            </SubscriptionLink>
            {school && type != "direct-on-school" &&
              <Text style={{flexDirection: "row", marginLeft: 3}}>
                (<SchoolLink school={school} {...linkProps(this.props)} />)
              </Text>
            }
            {schoolClass &&
              <Text style={{flexDirection: "row", marginLeft: 3}}>
                (<SchoolClassLink schoolClass={schoolClass} {...linkProps(this.props)} />)
              </Text>
            }
            {subscriptionIndex < (subscriptions.length - 1) &&
              <Text>, </Text>
            }
          </React.Fragment>
        )}
      </View>
    )
  }

  viewModelPath = ({contact}) => {
    if (this.p.adminLayout) return Routes.adminContactPath(contact.id())
  }
}))
