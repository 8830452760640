import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactor} from "models"
import ScoreFactorLink from "components/score-factors/link"
export default class ComponentsScoreFactors extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--score-factors--table", className)}
        columns={columns}
        defaultParams={{s: "current_translation_name"}}
        editModelPath={editModelPath}
        modelClass={ScoreFactor}
        select={{ScoreFactor: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.scoreFactorContent,
      label: ScoreFactor.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "icon",
      sortKey: "icon"
    },
    {
      attribute: "color",
      sortKey: "color"
    },
    {
      attribute: "secondaryColor",
      sortKey: "secondaryColor"
    }
  ]

  editModelPath = ({scoreFactor}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminScoreFactorPath(scoreFactor.id())
  }

  viewModelPath = ({scoreFactor}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminScoreFactorPath(scoreFactor.id())
  }

  scoreFactorContent = ({scoreFactor}) => <ScoreFactorLink scoreFactor={scoreFactor} {...linkProps(this.props)} />
}
