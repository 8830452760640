import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminCheckbox from "components/admin/checkbox"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import Attachment from "components/inputs/attachment"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import FormDataObjectizer from "form-data-objectizer"
import {incorporate} from "incorporator"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SurveyStepElementOption} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedSurveyStepElementOptionAttributes = [
  "fontAwesomeIcon",
  "hideLabel",
  "id",
  "identifier",
  "imageContentType",
  "imageUrl",
  "name",
  "position",
  "surveyStepElementId",
  "textStyle"
]

for (const locale of Locales.availableLocales()) {
  selectedSurveyStepElementOptionAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSurveyStepElementOptionsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.survey_step_element_options.edit"})
    const params = useParams()
    const {surveyStepElementOption, surveyStepElementOptionId} = useModel(SurveyStepElementOption, {
      match: {params},
      newIfNoId: true,
      select: {
        SurveyStepElementOption: selectedSurveyStepElementOptionAttributes
      }
    })

    this.setInstance({
      formRef: useRef(),
      surveyStepElementOption,
      surveyStepElementOptionId,
      t
    })
    this.useStates({form: null})
    useAdminLayout()?.setState({active: "surveys", headerTitle: this.headerTitle()})
  }

  render() {
    const {formRef, onSubmit, surveyStepElementOption} = this.tt
    let textStyle

    if (surveyStepElementOption?.hasTextStyle()) {
      textStyle = JSON.stringify(surveyStepElementOption.textStyle(), null, 2)
    }

    return (
      <View dataSet={{route: "admin--survey-step-element-options--edit"}}>
        {surveyStepElementOption &&
          <Form formRef={formRef}onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AdminInput
                  attribute={`name${inflection.camelize(locale)}`}
                  containerProps={{style: {marginBottom: 15}}}
                  label={`${SurveyStepElementOption.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={surveyStepElementOption}
                />
              </React.Fragment>
            )}
            <AdminInput attribute="identifier" containerProps={{style: {marginBottom: 15}}} model={surveyStepElementOption} />
            <AdminInput attribute="position" containerProps={{style: {marginBottom: 15}}} model={surveyStepElementOption} />
            <AdminInput attribute="fontAwesomeIcon" containerProps={{style: {marginBottom: 15}}} model={surveyStepElementOption} />
            <Attachment attribute="image" defaultValue={null} label={this.t("js.shared.image")} model={surveyStepElementOption} />
            <AdminCheckbox attribute="hideLabel" containerProps={{style: {marginBottom: 15}}} model={surveyStepElementOption} />
            <AdminInput
              attribute="textStyle"
              autoRows
              containerProps={{style: {marginBottom: 15}}}
              defaultValue={textStyle}
              model={surveyStepElementOption}
              multiline
            />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {surveyStepElementOption} = this.tt

    if (surveyStepElementOption?.isNewRecord()) {
      return this.t(".add_new_option")
    } else if (surveyStepElementOption?.isPersisted()) {
      return this.t(".edit_option", {name: surveyStepElementOption.name()})
    }
  }

  onSubmit = async () => {
    const {surveyStepElementOption} = this.tt
    const form = this.tt.formRef.current
    const formData = new FormData(form)
    const data = incorporate({}, this.s.form.asObject(), FormDataObjectizer.toObject(formData))

    if (surveyStepElementOption.isNewRecord() && surveyStepElementOption.hasSurveyStepElementId()) {
      data.survey_step_element_option.survey_step_element_id = surveyStepElementOption.surveyStepElementId()
    }

    try {
      await surveyStepElementOption.saveRaw(data)
      FlashMessage.success(this.t(".the_option_was_saved"))
      AppHistory.push(Routes.adminSurveyStepElementOptionPath(surveyStepElementOption.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
