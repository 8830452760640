import {PlanResource} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: PlanResourcesSelectWithContainer, withMemo: ComponentsPlanResourcesSelect} = selectComponent({
  className: "components--plan-resources--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || PlanResource

    query = query
      .ransack({s: "resource_name asc"})
      .select({PlanResource: ["id", "resourceName"]})
      .limit(20)

    if (searchValue) query.ransack({resource_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const planResources = await query.toArray()

    return planResources.map((planResource) => ({
      text: planResource.resourceName(),
      value: planResource.id()
    }))
  }
})

export default ComponentsPlanResourcesSelect
export {PlanResourcesSelectWithContainer}
