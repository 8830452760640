import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Account} from "models"
import AccountLink from "components/accounts/link"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsAccountsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--accounts--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={Account}
        select={{Account: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.accountContent,
      label: Account.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    }
  ]

  accountContent = ({account}) => <AccountLink account={account} {...linkProps(this.props)} />
  editModelPath = ({account}) => this.p.adminLayout && Routes.editAdminAccountPath(account.id())
  viewModelPath = ({account}) => this.p.adminLayout && Routes.adminAccountPath(account.id())
}))
