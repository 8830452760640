import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import EmailTemplateLink from "components/email-templates/link"
import memo from "set-state-compare/src/memo"
import {NotificationTypeAction} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNotificationTypeActionsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--notification-type-actions--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={NotificationTypeAction}
        select={{
          EmailTemplate: ["id"],
          NotificationTypeAction: ["translatedActionType"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      sortKey: "currentTranslationName"
    },
    {
      attribute: "actionType",
      content: this.tt.actionTypeContent,
      sortKey: "actionType"
    },
    {
      attribute: "name",
      content: this.tt.emailTemplateContent,
      label: NotificationTypeAction.humanAttributeName("emailTemplate"),
      path: ["emailTemplate"],
      sortKey: "emailTemplateCurrentTranslationName"
    },
    {
      attribute: "delay",
      sortKey: "delay"
    }
  ]

  actionTypeContent = ({notificationTypeAction}) => notificationTypeAction.translatedActionType()

  editModelPath = ({notificationTypeAction}) => {
    if (this.p.adminLayout) return Routes.editAdminNotificationTypeActionPath(notificationTypeAction.id())
  }

  emailTemplateContent = ({notificationTypeAction}) => notificationTypeAction.emailTemplate() &&
    <EmailTemplateLink adminLayout emailTemplate={notificationTypeAction.emailTemplate()} />
}))
