import {defaultSchoolIds, filterParams, filterParamsList} from "components/user/unicorn/filter-changed"
import {Image, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import CountChart from "components/user/unicorn/count-chart"
import Filter from "components/user/unicorn/filter"
import FrequencyFactors from "components/user/unicorn/frequency-factors"
import HelloDogImage from "components/user/unicorn/hello-dog.png"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import MoodDevelopmentLineChart from "components/score-factors/mood-development-line-chart"
import ProfileBox from "components/user/unicorn/profile-box"
import {Text} from "shared/base"
import UnicornEffects from "components/user/unicorn/unicorn-effects"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import useAnythingUnicorn from "components/subscriptions/use-anything-unicorn"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"

export default memo(shapeComponent(class RoutesUserUnicorn extends ShapeComponent {
  firstLoad = true

  setup() {
    const {anythingUnicorn} = useAnythingUnicorn()
    const currentUser = useCurrentUser()
    const {t} = useI18n({namespace: "js.routes.user.unicorn"})

    this.setInstance({
      anythingUnicorn,
      breakpoint: useBreakpoint(),
      currentUser,
      queryParams: useQueryParams(),
      t
    })
    this.useStates({
      classStepIds: null,
      dateFrom: null,
      dateTo: null,
      loaded: false,
      schoolClassIds: null,
      schoolClassGroupIds: null,
      schoolIds: null
    })

    const filterQueryParams = []

    for (const filterParamName of filterParamsList) {
      filterQueryParams.push(this.queryParams[filterParamName])
    }

    useMemo(() => {
      if (this.tt.firstLoad) {
        this.firstLoad = false
        this.setDataFromDefaults()
      } else {
        this.setDataFromQueryParams()
      }
    }, filterQueryParams)

    useFrontLayout()?.setState({active: "user-unicorn", headerTitle: t(".analysis")})
  }

  setDataFromDefaults = async () => {
    const {dateFrom, dateTo, classStepIds, schoolClassIds, schoolClassGroupIds, schoolIds} = await defaultSchoolIds(this.tt.queryParams)

    this.setState({
      classStepIds,
      dateFrom,
      dateTo,
      loaded: true,
      schoolClassIds,
      schoolClassGroupIds,
      schoolIds
    })
  }

  setDataFromQueryParams = () => {
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = filterParams(this.tt.queryParams)

    this.setState({
      classStepIds,
      dateFrom,
      dateTo,
      loaded: true,
      schoolClassIds,
      schoolClassGroupIds,
      schoolIds
    })
  }

  render() {
    const {anythingUnicorn, breakpoint, currentUser, t} = this.tt
    const {classStepIds, dateFrom, dateTo, loaded, schoolClassIds, schoolClassGroupIds, schoolIds} = this.s
    const filterProps = {
      classStepIds,
      dateFrom,
      dateTo,
      schoolClassIds,
      schoolClassGroupIds,
      schoolIds
    }
    const hasAnyModelsSelected = this.hasAnyModelsSelected()

    return (
      <View dataSet={{route: "user--unicorn"}}>
        {anythingUnicorn === false &&
          <UnicornUpgradeOverlay />
        }
        {anythingUnicorn === true && loaded &&
          <>
            <View
              dataSet={{class: "unicorn-header"}}
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 30
              }}
            >
              <View
                dataSet={{class: "unicorn-go-go-container"}}
                style={{
                  width: breakpoint.xlUp ? 250 : 300,
                  padding: 50,
                  borderRadius: "50%",
                  backgroundColor: "#fff"
                }}
              >
                <Text
                  style={{
                    fontSize: breakpoint.xlUp ? 28 : 36,
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {t(".you_are_so_cool_unicorn_go_go")}
                </Text>
              </View>
              <Image
                dataSet={{class: "unicorn-header-dog-image"}}
                source={HelloDogImage}
                style={{
                  alignSelf: "stretch",
                  width: breakpoint.xlUp ? 170 : 230,
                  marginLeft: 30,
                  resizeMode: "contain"
                }}
              />
              <View
                dataSet={{class: "filter-container"}}
                style={{
                  marginTop: breakpoint.xlUp ? 0 : 30,
                  marginLeft: "auto",

                  flexShrink: breakpoint.xlUp ? 1 : undefined,
                  flexWrap: breakpoint.xlUp ? "wrap" : undefined,
                  justifyContent: breakpoint.xlUp ? "center" : undefined
                }}
              >
                <Filter {...filterProps} />
              </View>
            </View>
            {!hasAnyModelsSelected &&
              <Text>
                {t(".please_select_a_school_or_a_class")}
              </Text>
            }
            {hasAnyModelsSelected &&
              <>
                <View
                  dataSet={{class: "unicorn-effects-and-count-chart"}}
                  style={{flexDirection: "row", marginBottom: 50}}
                >
                  <UnicornEffects {...filterProps} />
                  <View style={{flexGrow: 1}}>
                    <CountChart {...filterProps} />
                  </View>
                </View>
                <View style={{flexDirection: "row"}}>
                  <View style={{width: 700}}>
                    <MoodDevelopmentLineChart {...filterProps} />
                  </View>
                  <View style={{width: 350, marginLeft: 30}}>
                    <FrequencyFactors {...filterProps} />
                  </View>
                  {isUserA(currentUser, "tester") &&
                    <View style={{width: 350, marginLeft: 30}}>
                      <ProfileBox />
                    </View>
                  }
                </View>
              </>
            }
          </>
        }
      </View>
    )
  }

  hasAnyModelsSelected() {
    if (this.s.classStepIds?.length > 0 || this.s.schoolClassIds?.length > 0 || this.s.schoolClassGroupIds?.length > 0 || this.s.schoolIds?.length > 0) {
      return true
    }

    return false
  }
}))
