import selectComponent from "components/select-component"
import {SurveyStep} from "models"
const {withInputContainer: SurveyStepsSelectWithContainer, withMemo: ComponentsSurveyStepsSelect} = selectComponent({
  className: "components--survey-steps--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || SurveyStep

    query = query
      .ransack({s: "position asc"})
      .select({SurveyStep: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const surveySteps = await query.toArray()

    return surveySteps.map((surveyStep) => ({
      text: surveyStep.name(),
      value: surveyStep.id()
    }))
  }
})

export default ComponentsSurveyStepsSelect
export {SurveyStepsSelectWithContainer}
