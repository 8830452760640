import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import {ContactRelationshipType} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import React from "react"
import {Text} from "shared/base"

export default {
  show: {
    extraContent: ({contactRelationshipType}) => (
      <>
        {Locales.availableLocales().map((locale) =>
          <AttributeRow key={locale} label={`${ContactRelationshipType.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}>
            <Text>
              {contactRelationshipType[`name${inflection.camelize(locale)}`]()}
            </Text>
          </AttributeRow>
        )}
      </>
    ),
    extraSelect: {
      ContactRelationshipType: Locales.availableLocales().map((locale) => `name${inflection.camelize(locale)}`)
    }
  },
  edit: {
    attributes: [
      {attribute: "name", translated: true},
      {attribute: "identifier"}
    ]
  }
}
