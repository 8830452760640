import {Contact, School} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectCountries} from "components/countries/select"
import {AdminSelectCountryStates} from "components/country-states/select"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.schools.edit"})
    const selectedSchoolAttributes = [
      "countryId", "countryStateId", "id", "email", "name", "principalName"
    ].concat(translatedAttributes(["name"], Locales.availableLocales()))
    const params = useParams()
    const {school} = useModel(School, {
      match: {params},
      newIfNoId: true,
      select: {
        School: selectedSchoolAttributes
      }
    })

    let headerTitle

    if (school?.isNewRecord()) {
      headerTitle = t(".add_new_school")
    } else if (school?.isPersisted()) {
      headerTitle = t(".edit_school", {name: school.name()})
    }

    this.setInstance({school, t})
    this.useStates({form: null})

    useAdminLayout()?.setState({active: "schools", headerTitle})
  }

  render() {
    const {school} = this.tt

    return (
      <View dataSet={{route: "admin--schools--edit"}}>
        {school &&
          <Form onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <AdminInput
                attribute={`name${inflection.camelize(locale)}`}
                containerProps={{style: {marginBottom: 15}}}
                key={`input-name-${locale}`}
                label={`${Contact.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={school}
              />
            )}
            <View style={{marginBottom: 15}}>
              <AdminSelectCountries attribute="countryId" label={School.humanAttributeName("country")} model={school} />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectCountryStates attribute="countryStateId" label={School.humanAttributeName("countryState")} model={school} />
            </View>
            <AdminInput attribute="email" containerProps={{style: {marginBottom: 15}}} model={school} />
            <AdminInput attribute="principalName" containerProps={{style: {marginBottom: 15}}} model={school} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()

    try {
      await this.tt.school.saveRaw(formData)
      FlashMessage.success(this.t(".the_school_was_saved"))
      AppHistory.push(Routes.adminSchoolPath(this.tt.school.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
