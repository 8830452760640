import React, {useMemo} from "react"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Card from "components/card"
import EmailTemplateLink from "components/email-templates/link"
import memo from "set-state-compare/src/memo"
import {Newsletter} from "models"
import NewsletterTemplateLink from "components/newsletter-templates/link"
import setLayout from "shared/set-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import UserLink from "components/users/link"
import useShape from "set-state-compare/src/use-shape"
const RoutesAdminNewslettersShow = (props) => {
  setLayout("admin")

  const {locale} = useI18n({namespace: "js.routes.admin.newsletters.show"})
  const s = useShape(props)
  const {newsletter, newsletterId} = useModel(Newsletter, {
    abilities: {
      Newsletter: ["destroy", "edit"]
    },
    match: props.match,
    preload: ["email_template", "newsletter_template", "user"],
    select: {
      EmailTemplate: ["id", "name"],
      Newsletter: ["createdAt", "id", "updatedAt"],
      NewsletterTemplate: ["id", "name"],
      User: ["id", "name"]
    }
  })

  s.updateMeta({newsletter, newsletterId})

  const actions = useMemo(
    () => <AdminShowActions model={s.m.newsletter} />,
    [locale, s.m.newsletter?.cacheKey()]
  )

  return (
    <AdminLayout actions={actions} active="newsletters" className="routes--admin--newsletters--show" headerTitle={Newsletter.modelName().human()}>
      <Card header={Newsletter.modelName().human()}>
        {newsletter &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={newsletter} />
            <AttributeRow attribute="newsletterTemplate" model={newsletter}>
              {newsletter.user() &&
                <UserLink adminLayout user={newsletter.user()} />
              }
            </AttributeRow>
            <AttributeRow attribute="newsletterTemplate" model={newsletter}>
              {newsletter.newsletterTemplate() &&
                <NewsletterTemplateLink adminLayout newsletterTemplate={newsletter.newsletterTemplate()} />
              }
            </AttributeRow>
            <AttributeRow attribute="emailTemplate" model={newsletter}>
              {newsletter.emailTemplate() &&
                <EmailTemplateLink adminLayout emailTemplate={newsletter.emailTemplate()} />
              }
            </AttributeRow>
          </>
        }
      </Card>
    </AdminLayout>
  )
}

export default memo(RoutesAdminNewslettersShow)
