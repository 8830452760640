import React, {useEffect} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import DogDockerImage from "./dog.png"
import I18n from "shared/i18n"
import InfoIcon from "components/user/unicorn/info-icon"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import SanitizedHtml from "shared/sanitized-html"
import {SchoolClassCheckInEvent} from "models"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserUnicornUnicornEffects extends ShapeComponent {
  static defaultProps = {
    classStepIds: null,
    dateFrom: null,
    dateTo: null,
    schoolClassIds: null,
    schoolClassGroupIds: null,
    schoolIds: null
  }

  static propTypes = propTypesExact({
    className: PropTypes.string,
    classStepIds: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    schoolClassIds: PropTypes.array,
    schoolClassGroupIds: PropTypes.array,
    schoolIds: PropTypes.array
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.user.unicorn.unicorn_effects"})
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const filterQueryParams = [classStepIds?.join("--"), dateFrom, dateTo, schoolClassIds?.join("--"), schoolClassGroupIds?.join("--"), schoolIds?.join("--")]

    this.setInstance({t})
    this.useStates({count: undefined})

    useEffect(() => {
      if (this.isFilterLoaded()) {
        this.countUnicornEffects()
      }
    }, filterQueryParams)
  }

  isFilterLoaded() {
    const {classStepIds, schoolClassIds, schoolClassGroupIds, schoolIds} = this.props

    if (classStepIds || schoolClassIds || schoolClassGroupIds || schoolIds) return true

    return false
  }

  countUnicornEffects = async () => {
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const countQuery = SchoolClassCheckInEvent.ransack({event_type_eq: "unicorn_effect"})

    if (classStepIds) countQuery.ransack({school_class_check_in_school_class_class_step_id_eq_any: classStepIds})
    if (dateFrom) countQuery.ransack({school_class_check_in_created_at_gteq: I18n.strftime("%Y-%m-%d", dateFrom)})
    if (dateTo) countQuery.ransack({school_class_check_in_created_at_lteq: I18n.strftime("%Y-%m-%d", dateTo)})
    if (schoolClassGroupIds) countQuery.ransack({school_class_check_in_school_class_school_class_groups_id_eq_any: schoolClassGroupIds})
    if (schoolClassIds) countQuery.ransack({school_class_check_in_school_class_id_eq_any: schoolClassIds})
    if (schoolIds) countQuery.ransack({school_class_check_in_school_class_school_id_eq_any: schoolIds})

    await loading(this.t(".unicorn_effects"), async () => {
      const count = await countQuery.count()

      this.setState({count})
    })
  }

  render() {
    const {t} = this.tt
    const {className} = this.props
    const {count} = this.s

    return (
      <View dataSet={{class: className, component: "user--unicorn--unicorn-effects"}}>
        <View
          dataSet={{class: "unicorn-effects-this-school-year-card"}}
          style={{
            width: 350,
            padding: 30,
            borderRadius: 20,
            marginRight: 20,
            backgroundColor: "#fff"
          }}
        >
          <View>
            <InfoIcon style={{marginLeft: "auto"}}>
              <SanitizedHtml html={t(".info_box")} />
            </InfoIcon>
          </View>
          <View
            dataSet={{class: "unicorn-effects-header"}}
            style={{
              width: 200,
              marginRight: "auto",
              marginBottom: 30,
              marginLeft: "auto"
            }}
          >
            <Text style={{fontSize: 20, textAlign: "center"}}>
              {t(".unicorn_effects_this_school_year")}
            </Text>
          </View>
          <View dataSet={{class: "unicorn-effects-dog-image-and-count"}} style={{flexDirection: "row", alignItems: "center"}}>
            <img className="unicorn-effects-dog-image" src={DogDockerImage} style={{maxWidth: "60%"}} />
            <View className="unicorn-effects-count" style={{marginLeft: 20}}>
              <Text style={{color: "#59adff", fontSize: 100, fontWeight: "bold"}}>
                {count}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }
}))
