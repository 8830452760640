import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useWoofRouter} from "shared/woof-router"
export default memo(shapeComponent(class RoutesAdminUsersRoute extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="users">
        <Route component="edit" exact path="new" />
        <Route component="account-users" exact path=":user_id/account_users" />
        <Route exact path=":user_id/audits" />
        <Route component="school-class-roles" exact path=":user_id/school_class_roles" />
        <Route component="school-roles" exact path=":user_id/school_roles" />
        <Route exact path=":user_id/subscriptions" />
        <Route component="user-roles" exact path=":user_id/user_roles" />
        <Route exact path=":user_id/edit" />
        <Route component="show" exact path=":user_id" />
        <Route component="index" exact />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))
