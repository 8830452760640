import React, {useEffect} from "react"
import {SchoolClass, SchoolClassCheckIn} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import HayaDatePicker from "haya-date-picker/src/date-picker"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsScoreFactorGroupsMoodBarChartWeekPicker extends ShapeComponent {
  static propTypes = {
    activeDate: PropTypes.instanceOf(Date),
    activeDates: PropTypes.array,
    onSelectWeek: PropTypes.func.isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    this.useStates({
      weeksAvailable: undefined
    })

    useEffect(() => {
      this.loadAvailableWeeks()
    }, [])
  }

  async loadAvailableWeeks() {
    const {schoolClass} = this.p
    const schoolClassCheckIns = await SchoolClassCheckIn
      .ransack({s: "created_at", school_class_id_eq: schoolClass.id(), state_eq: "closed"})
      .select({SchoolClassCheckIn: ["createdAt"]})
      .toArray()

    const weeksAvailable = {}

    for (const schoolClassCheckIn of schoolClassCheckIns) {
      const year = schoolClassCheckIn.createdAt().getFullYear()
      const week = moment(schoolClassCheckIn.createdAt()).isoWeek()

      if (!(year in weeksAvailable)) weeksAvailable[year] = {}
      if (!(week in weeksAvailable[year])) weeksAvailable[year][week] = true
    }

    this.setState({weeksAvailable})
  }

  render() {
    const {activeDate, activeDates, onSelectWeek} = this.props
    const {weeksAvailable} = this.s

    return (
      <View dataSet={{component: "score-factor-groups--mood-bar-chart--week-picker"}}>
        <HayaDatePicker
          activeDates={activeDates}
          defaultCurrentDate={activeDate}
          mode="week"
          onSelect={onSelectWeek}
          weeksAvailable={weeksAvailable}
        />
      </View>
    )
  }
}))
