import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {ContactRelationship} from "models"
import {initialsForUser} from "shared/initials"
import memo from "set-state-compare/src/memo"
import OptionsButton from "./options-button"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserAdminSchoolClassesIndexUserCircle extends ShapeComponent {
  static propTypes = propTypesExact({
    backgroundColor: PropTypes.string,
    schoolClassRole: PropTypes.instanceOf(ContactRelationship).isRequired
  })

  render() {
    const {schoolClassRole} = this.p
    const {backgroundColor, style} = this.props
    const actualStyle = Object.assign(
      {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 252,
        height: 252,
        backgroundColor,
        borderRadius: "50%"
      },
      style
    )

    return (
      <View dataSet={{class: "role-circle", roleId: schoolClassRole.id()}} style={actualStyle}>
        <OptionsButton schoolClassRole={schoolClassRole} style={{position: "absolute", right: 25, bottom: 72}} />
        <Text style={{color: "#fff", fontSize: 70}}>
          {initialsForUser(schoolClassRole.child().user())}
        </Text>
        <Text style={{marginTop: 30, color: "#fff", fontSize: 16}}>
          {schoolClassRole.child().user().name()}
        </Text>
        <Text dataSet={{class: "school-class-role-translated-role"}} style={{marginTop: 6, fontSize: 18}}>
          {schoolClassRole.contactRelationshipType().translatedIdentifier()}
        </Text>
      </View>
    )
  }
}))
