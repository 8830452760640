import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import ScoreFactorGroupLink from "components/score-factor-groups/link"
import {ScoreFactorGroupScoreFactor} from "models"
import ScoreFactorLink from "components/score-factors/link"
export default class ComponentsScoreFactorGroupScoreFactors extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--score-factor-group-score-factors--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={ScoreFactorGroupScoreFactor}
        preloads={["score_factor", "score_factor_group"]}
        select={{
          ScoreFactor: ["id", "name"],
          ScoreFactorGroup: ["id", "name"],
          ScoreFactorGroupScoreFactor: ["id"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.scoreFactorGroupContent,
      label: ScoreFactorGroupScoreFactor.humanAttributeName("scoreFactorGroup"),
      sortKey: "scoreFactorGroupCurrentTranslationName"
    },
    {
      content: this.t.scoreFactorContent,
      label: ScoreFactorGroupScoreFactor.humanAttributeName("scoreFactor"),
      sortKey: "scoreFactorCurrentTranslationName"
    }
  ]

  editModelPath = ({scoreFactorGroupScoreFactor}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminScoreFactorGroupScoreFactorPath(scoreFactorGroupScoreFactor.id())
  }

  scoreFactorContent = ({scoreFactorGroupScoreFactor}) => scoreFactorGroupScoreFactor.scoreFactor() &&
    <ScoreFactorLink scoreFactor={scoreFactorGroupScoreFactor.scoreFactor()} {...linkProps(this.props)} />

  scoreFactorGroupContent = ({scoreFactorGroupScoreFactor}) => scoreFactorGroupScoreFactor.scoreFactorGroup() &&
    <ScoreFactorGroupLink scoreFactorGroup={scoreFactorGroupScoreFactor.scoreFactorGroup()} {...linkProps(this.props)} />
}
