import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {Survey} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"
const selectedSurveyAttributes = ["id", "name"]

for (const locale of Locales.availableLocales()) {
  selectedSurveyAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default memo(shapeComponent(class RoutesAdminSurveysEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.surveys.edit"})
    const params = useParams()
    const {survey, surveyId} = useModel(Survey, {
      match: {params},
      newIfNoId: true,
      select: {
        Survey: selectedSurveyAttributes
      }
    })

    this.setInstance({survey, surveyId, t})
    this.useStates({
      form: null
    })

    useAdminLayout()?.setState({
      active: "surveys",
      headerTitle: this.headerTitle()
    })
  }

  render() {
    const {onSubmit, survey} = this.tt

    return (
      <View dataSet={{route: "admin--surveys--edit"}}>
        {survey &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            {Locales.availableLocales().map((locale) =>
              <AdminInput
                attribute={`name${inflection.camelize(locale)}`}
                containerProps={{style: {marginBottom: 15}}}
                key={`input-name-${locale}`}
                label={`${Survey.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                model={survey}
              />
            )}
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {survey} = this.tt

    if (survey?.isNewRecord()) {
      return this.t(".add_new_survey")
    } else if (survey?.isPersisted()) {
      return this.t(".edit_survey", {name: survey.name()})
    }
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {survey} = this.tt

    try {
      await survey.saveRaw(formData)
      FlashMessage.success(this.t(".the_survey_was_saved"))
      AppHistory.push(Routes.adminSurveyPath(survey.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
