import {BrainBreak, Challenge, SchoolClassCheckIn} from "models"
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import InfoIcon from "components/user/unicorn/info-icon"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import SanitizedHtml from "shared/sanitized-html"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsCard from "components/utils/card"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserUnicornCountChart extends ShapeComponent {
  static defaultProps = {
    classStepIds: null,
    dateFrom: null,
    dateTo: null,
    schoolClassIds: null,
    schoolClassGroupIds: null,
    schoolIds: null
  }

  static propTypes = propTypesExact({
    classStepIds: PropTypes.array,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    schoolClassIds: PropTypes.array,
    schoolClassGroupIds: PropTypes.array,
    schoolIds: PropTypes.array
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.user.unicorn.count_chart"})
    const {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds} = this.p
    const filterQueryParams = [classStepIds?.join("--"), dateFrom, dateTo, schoolClassIds?.join("--"), schoolClassGroupIds?.join("--"), schoolIds?.join("--")]

    this.setInstance({t})
    this.useStates({data: undefined, label: undefined})

    useMemo(() => {
      this.loadChartData()
    }, filterQueryParams)
  }

  render() {
    const {t} = this.tt

    return (
      <UtilsCard dataSet={{component: "user/unicorn/count-chart"}}>
        <View>
          <InfoIcon style={{marginLeft: "auto"}}>
            <SanitizedHtml html={t(".info_box")} />
          </InfoIcon>
        </View>
        <View style={{height: 300}}>
          {this.s.data &&
            <View dataSet={{class: "count-chart-container"}} style={{width: "100%", height: "100%"}}>
              <ResponsiveContainer height="100%" width="100%">
                <LineChart
                  data={this.s.data}
                  height={300}
                  margin={{
                    bottom: 5,
                    left: 20,
                    right: 30,
                    top: 5
                  }}
                  width={500}
                >
                  <CartesianGrid horizontal={false} strokeDasharray="1 0" />
                  <XAxis dataKey="dateLabel" />
                  <YAxis domain={[0, 10]} type="number" />
                  <Tooltip wrapperStyle={{outline: "none"}} />
                  <Legend />
                  <Line
                    activeDot={{stroke: "#205a96"}}
                    connectNulls
                    dataKey={BrainBreak.modelName().human({count: 2})}
                    dot={false}
                    stroke="#bc6de8"
                    strokeWidth={5}
                    type="monotone"
                  />
                  <Line
                    activeDot={{stroke: "#205a96"}}
                    connectNulls
                    dataKey={Challenge.modelName().human({count: 2})}
                    dot={false}
                    stroke="#e7bd15"
                    strokeWidth={5}
                    type="monotone"
                  />
                  <Line activeDot={{stroke: "#205a96"}} connectNulls dataKey={this.s.label} dot={false} stroke="#4c93ff" strokeWidth={5} type="monotone" />
                </LineChart>
              </ResponsiveContainer>
            </View>
          }
        </View>
      </UtilsCard>
    )
  }

  hasFilters = () => {
    if (
      this.p.classStepIds?.length > 0 ||
      this.p.schoolIds?.length > 0 ||
      this.p.schoolClassIds?.length > 0 ||
      this.p.schoolClassGroupIds?.length > 0
    ) {
      return true
    }
  }

  loadChartData = async () => {
    if (!this.p.dateFrom || !this.p.dateTo || !this.hasFilters()) {
      return this.resetData()
    }

    await loading(this.t(".unicorn_count_chart"), async () => {
      const result = await SchoolClassCheckIn.countChart({
        date_from: this.p.dateFrom,
        date_to: this.p.dateTo,
        class_step_ids: this.p.classStepIds,
        label: this.t(".woofs"),
        school_ids: this.p.schoolIds,
        school_class_ids: this.p.schoolClassIds,
        school_class_group_ids: this.p.schoolClassGroupIds
      })
      const countChart = digg(result, "count_chart")

      this.setState({data: countChart, label: this.t(".woofs")})
    })
  }

  resetData = () => {
    this.setState({
      data: undefined,
      label: undefined
    })
  }
}))
