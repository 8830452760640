import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import CourseLink from "components/courses/link"
import {CourseModule} from "models"
import CourseModuleLink from "components/course-modules/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SurveyLink from "components/surveys/link"
export default class ComponentsCourseModules extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--course-moduless--table", className)}
        columns={columns}
        defaultParams={{s: "position"}}
        editModelPath={editModelPath}
        modelClass={CourseModule}
        preloads={["course", "survey"]}
        select={{
          Course: ["id", "name"],
          CourseModule: ["id", "name"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.t.nameContent,
      sortKey: "currentTranslationName"
    },
    {
      content: this.t.courseContent,
      label: CourseModule.humanAttributeName("course"),
      sortKey: "courseCurrentTranslationName"
    },
    {
      content: this.t.surveyContent,
      label: CourseModule.humanAttributeName("survey"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "position",
      sortKey: "position"
    }
  ]

  editModelPath = ({courseModule}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminCourseModulePath(courseModule.id())
  }

  nameContent = ({courseModule}) => <CourseModuleLink courseModule={courseModule} {...linkProps(this.props)} />

  viewModelPath = ({courseModule}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminCourseModulePath(courseModule.id())
  }

  courseContent = ({courseModule}) => courseModule.course() && <CourseLink course={courseModule.course()} {...linkProps(this.props)} />
  surveyContent = ({courseModule}) => courseModule.survey() && <SurveyLink survey={courseModule.survey()} {...linkProps(this.props)} />
}
