import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import inflection from "inflection"
import Input from "components/inputs/input"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorGroup} from "models"
import {v4 as uuidv4} from "uuid"
import withModel from "components/wooftech-with-model"
class RoutesAdminScoreFactorGroupsEdit extends PageComponent {
  static propTypes = {
    scoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup),
    scoreFactorGroupId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {scoreFactorGroup} = this.p

    return (
      <AdminLayout active="score-factor-groups" className="routes--admin--score-factor-groups--edit" headerTitle={this.headerTitle()}>
        {scoreFactorGroup &&
          <form onSubmit={onSubmit}>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <Input
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ScoreFactorGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={scoreFactorGroup}
                />
              </React.Fragment>
            )}
            <Input attribute="identifier" model={scoreFactorGroup} />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {scoreFactorGroup} = this.p

    if (scoreFactorGroup?.isNewRecord()) {
      return I18n.t("js.routes.admin.score_factor_groups.edit.add_new_score_factor_group")
    } else if (scoreFactorGroup?.isPersisted()) {
      return I18n.t("js.routes.admin.score_factor_groups.edit.edit_score_factor_group", {name: scoreFactorGroup.name()})
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {scoreFactorGroup} = this.p

    if (scoreFactorGroup.isNewRecord()) formData.append("score_factor_group[id]", scoreFactorGroup.id())

    try {
      await scoreFactorGroup.saveRaw(formData, {form})
      FlashMessage.success(I18n.t("js.routes.admin.score_factor_groups.edit.the_score_factor_group_was_saved"))
      AppHistory.push(Routes.adminScoreFactorGroupPath(scoreFactorGroup.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedScoreFactorGroupAttributes = ["id", "identifier", "name"]

for (const locale of Locales.availableLocales()) {
  selectedScoreFactorGroupAttributes.push(`name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`)
}

export default withModel(
  RoutesAdminScoreFactorGroupsEdit,
  ScoreFactorGroup,
  {
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    },
    select: {
      ScoreFactorGroup: selectedScoreFactorGroupAttributes
    }
  }
)
