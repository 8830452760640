import I18n from "shared/i18n"
import isUserA from "components/users/is-a"
import linkProps from "shared/link-props"
import React from "react"
import UserLink from "components/users/link"
const createAuditColumn = (props) => {
  const content = ({model}) => model.createAudit()?.currentUser() && <UserLink user={model.createAudit().currentUser()} {...linkProps(props)} />

  return {
    content,
    identifier: "create-audit-user",
    label: I18n.t("js.shared.user_who_created"),
    path: ["create_audit.current_user"],
    sortKey: "createAuditCurrentUserFirstName"
  }
}

const addCreateAuditColumnIfAdmin = (props, columns, currentUser) => {
  if (isUserA(currentUser, ["admin", "hacker"])) {
    columns.push(createAuditColumn(props))
  }
}

export {addCreateAuditColumnIfAdmin}
export default createAuditColumn
