import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digg} from "diggerize"
import inflection from "inflection"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"

const modelLinksRequireContext = require.context("./", true, /\/link\.jsx$/)

export default memo(shapeComponent(class ComponentsModelLink extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object.isRequired
  }

  setup() {
    this.useStates({
      model: () => this.initialModel()
    })
  }

  initialModel = () => {
    if (this.p.model.name && !this.p.model.isAttributeLoaded("name")) {
      this.loadModelWithName()
    } else {
      return this.p.model
    }
  }

  loadModelWithName = async () => {
    const modelClass = this.p.model.constructor
    const modelName = modelClass.modelClassData().name
    const select = {}

    select[modelName] = ["id"]

    if (modelName == "SchoolClass") {
      select[modelName].push("interpretedName")
    } else {
      select[modelName].push("name")
    }

    const newModel = await modelClass
      .ransack({id_eq: this.p.model.id()})
      .select(select)
      .first()

    this.setState({model: newModel})
  }

  render() {
    const {className, model, ...restProps} = this.props
    const modelName = digg(model.modelClassData(), "name")
    const linkProps = {}
    let LinkComponent

    linkProps[inflection.camelize(modelName, true)] = this.s.model

    const linkComponentFileName = `./${inflection.dasherize(inflection.pluralize(inflection.underscore(modelName)))}/link.jsx`

    try {
      LinkComponent = modelLinksRequireContext(linkComponentFileName).default
    } catch (e) {
      if (!e.message.includes("Cannot find module")) {
        throw e
      }
    }

    return (
      <>
        {!LinkComponent &&
          `${model.constructor.modelName().human()} ${model.id()}`
        }
        {LinkComponent && this.s.model &&
          <LinkComponent className={classNames("components--model-link", className)} {...linkProps} {...restProps} />
        }
      </>
    )
  }
}))
