import Checkboxes from "@kaspernj/api-maker/build/inputs/checkboxes"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import React from "react"
import useInput from "@kaspernj/api-maker/build/use-input"
import {withInputContainer} from "components/inputs/input-container"

const ComponentsInputsCheckboxes = memo((props) => {
  const {inputProps, restProps} = useInput({props})
  const {className, ...actualRestProps} = restProps
  const {ref, type, ...restInputProps} = inputProps

  return (
    <div className={classNames("components--inputs--checkboxes", className)}>
      <div style={{padding: "15px"}}>
        <Checkboxes {...restInputProps} {...actualRestProps} />
      </div>
    </div>
  )
})

export {ComponentsInputsCheckboxes as CheckBoxes}
export default withInputContainer(ComponentsInputsCheckboxes)
