import {ContactRelationship, SchoolClass} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import SchoolClassRolesTable from "components/school-classes/roles-table"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassesSchoolClassRolesIndex extends ShapeComponent {
  setup() {
    const params = useParams()
    const {schoolClass} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["invite"]
      },
      match: {params},
      select: {
        SchoolClass: ["contactId", "id"]
      }
    })

    const rolesQuery = useMemo(
      () => ContactRelationship.ransack({
        parent_id_eq: schoolClass?.contactId() || "no-school-class"
      }),
      [schoolClass?.contactId()]
    )

    this.setInstance({rolesQuery, schoolClass})
  }

  render() {
    const {rolesQuery} = this.tt

    return (
      <View dataSet={{route: "user/school-classes/roles/index"}}>
        <SchoolClassRolesTable collection={rolesQuery}/>
      </View>
    )
  }
}))
