import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import {User} from "models"
export default memo(shapeComponent(class FrontUsersApplyForgottenPasswordRequest extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.front.users.apply_forgotten_password_request"})

    this.passwordConfirmationInputRef = useRef()
    this.passwordInputRef = useRef()
    this.queryParams = useQueryParams()
    this.t = t
  }

  render() {
    const {t} = this.tt

    return (
      <div className="routes--front--users--apply-forgotten-password-request">
        <form onSubmit={this.tt.onSubmit}>
          <Input
            id="user_password"
            inputRef={this.tt.passwordInputRef}
            label={t(".new_password")}
            type="password"
          />
          <Input
            id="user_password_confirmation"
            inputRef={this.tt.passwordConfirmationInputRef}
            label={t(".confirm_password")}
            type="password"
          />
          <Button label={t(".change_my_password")} primary />
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {passwordInputRef, passwordConfirmationInputRef, queryParams, t} = this.tt
    const password = digg(passwordInputRef, "current", "value")
    const passwordConfirmation = digg(passwordConfirmationInputRef, "current", "value")
    const resetPasswordToken = digg(queryParams, "reset_password_token")

    if (!resetPasswordToken) {
      throw new Error("No reset token could be read")
    }

    try {
      await User.applyForgottenPasswordRequest({
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken
      })
      FlashMessage.success(t(".your_password_was_changed"))
      AppHistory.push(Routes.rootPath())
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
