import {v4 as uuidv4} from "uuid"
export default {
  edit: {
    attributes: [
      {attribute: "name", translated: true},
      {attribute: "identifier"}
    ],
    newIfNoId: {
      defaults: () => ({id: uuidv4()})
    }
  }
}
