import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import {EmailTemplate} from "models"
import EmailTemplateLink from "components/email-templates/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default class ComponentsEmailTemplates extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--email-templates--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={EmailTemplate}
        select={{
          EmailTemplate: ["id", "name"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.emailTemplateContent,
      label: EmailTemplate.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "subject",
      sortKey: "currentTranslationSubject"
    }
  ]

  editModelPath = ({emailTemplate}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminEmailTemplatePath(emailTemplate.id())
  }

  viewModelPath = ({emailTemplate}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminEmailTemplatePath(emailTemplate.id())
  }

  emailTemplateContent = ({emailTemplate}) => <EmailTemplateLink emailTemplate={emailTemplate} {...linkProps(this.props)} />
}
