import AdminLayout from "components/admin/layout"
import {options} from "shared/sanitized-html"
import PageComponent from "components/page-component"
import React from "react"
import setLayout from "shared/set-layout"

export default class RoutesAdminTestsSanitizedHtml extends PageComponent {
  render() {
    setLayout("admin")

    return (
      <AdminLayout className="routes--admin--tests--sanitized-html">
        <pre className="white-list">
          {JSON.stringify(options.whiteList, null, 2)}
        </pre>
      </AdminLayout>
    )
  }
}
