import {memo, PropTypes, propTypesExact, shapeComponent, ShapeComponent, useBreakpoint, View} from "shared/base"
import React, {useMemo} from "react"
import {StyleSheet} from "react-native"
import {WithDefaultStyle} from "@kaspernj/api-maker/build/utils/default-style"

const styles = StyleSheet.create({
  root: {
    backgroundColor: "#fff",
    borderRadius: 48,
    padding: 32
  },
  rootActive: {
    backgroundColor: "#5253ff"
  },
  rootLarge: {
    padding: 48
  },
  textBlue: {
    color: "#2c2c96"
  },
  textWhite: {
    color: "#fff"
  }
})

export default memo(shapeComponent(class ComponentsChalkyLayoutCard extends ShapeComponent {
  static defaultProps = {
    active: false
  }

  static propTypes = propTypesExact({
    active: PropTypes.bool.isRequired,
    children: PropTypes.node
  })

  setup() {
    this.setInstance(useBreakpoint())
  }

  render() {
    const {mdUp} = this.tt
    const {active, children} = this.p
    const rootStyles = useMemo(
      () => {
        const rootStyles = [styles.root]

        if (active) rootStyles.push(styles.rootActive)
        if (mdUp) rootStyles.push(styles.rootLarge)

        return rootStyles
      },
      [active, mdUp]
    )

    const defaultStyle = useMemo(
      () => {
        if (active) {
          return {Text: [styles.textWhite]}
        } else {
          return {Text: [styles.textBlue]}
        }
      },
      [active]
    )

    const dataSet = useMemo(() => ({class: "chalky-layout/card"}), [])

    return (
      <View dataSet={dataSet} style={rootStyles}>
        <WithDefaultStyle style={defaultStyle}>
          {children}
        </WithDefaultStyle>
      </View>
    )
  }
}))
