import {Pressable, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
const SliderLink = memo(shapeComponent(class SliderLink extends ShapeComponent {
  render() {
    const {active, number, style} = this.p
    let color

    if (active) {
      color = "#666"
    } else {
      color = "#cac9c9"
    }

    return (
      <Pressable dataSet={{active, class: "slider-link", number}} onPress={this.tt.onPress} style={style}>
        <Text style={{color, fontSize: 16}}>
          &#11044;
        </Text>
      </Pressable>
    )
  }

  onPress = () => this.p.onClick({number: this.p.number})
}))

export default memo(shapeComponent(class ComponentsSlider extends ShapeComponent {
  static propTypes = {
    active: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    onSlideChanged: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired
  }

  render() {
    const {active, children, className, onSlideChanged, total, ...restProps} = this.props

    return (
      <View
        dataSet={{class: className, component: "slider"}}
        style={{paddingLeft: 70, paddingRight: 70}}
        {...restProps}
      >
        <Pressable
          dataSet={{class: "previous-slide-button"}}
          onPress={this.tt.onPreviousSlidePressed}
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: 0,
            width: 53,
            height: 53,
            backgroundColor: "#4c93ff",
            borderRadius: "50%"
          }}
        >
          <Text style={{color: "#fff"}}>
            ❮
          </Text>
        </Pressable>
        <Pressable
          dataSet={{class: "next-slide-button"}}
          onPress={this.tt.onNextSlidePressed}
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 1,
            top: "50%",
            right: 0,
            width: 53,
            height: 53,
            backgroundColor: "#4c93ff",
            borderRadius: "50%"
          }}
        >
          <Text style={{color: "#fff"}}>
            ❯
          </Text>
        </Pressable>
        {children}
        <View dataSet={{class: "slide-links-container"}} style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 40}}>
          <View dataSet={{class: "slide-links"}} style={{display: "flex", flexDirection: "row"}}>
            {Array.from(Array(total)).map((_, number) =>
              <SliderLink
                active={active == number}
                key={number}
                number={number}
                onClick={this.tt.onSliderLinkClicked}
                style={{marginLeft: number === 0 ? undefined : 5}}
              />
            )}
          </View>
        </View>
      </View>
    )
  }

  onNextSlidePressed = () => {
    let number = this.p.active + 1

    if (number > this.p.total - 1) {
      number = 0
    }

    this.p.onSlideChanged({number})
  }

  onPreviousSlidePressed = () => {
    let number = this.p.active - 1

    if (number < 0) {
      number = this.p.total - 1
    }

    this.p.onSlideChanged({number})
  }

  onSliderLinkClicked = ({number}) => this.p.onSlideChanged({number})
}))
