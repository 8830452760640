import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import Nav from "components/admin/score-factor-groups/nav"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import {ScoreFactorGroup} from "models"
import withModel from "components/wooftech-with-model"

class RoutesAdminScoreFactorGroupsShow extends PageComponent {
  static propTypes = {
    scoreFactorGroup: PropTypes.instanceOf(ScoreFactorGroup),
    scoreFactorGroupId: PropTypes.string.isRequired
  }

  render() {
    const {scoreFactorGroup} = this.p

    return (
      <AdminLayout
        actions={<AdminShowActions model={scoreFactorGroup} />}
        active="score-factor-groups"
        className="routes--admin--score-factor-groups--show"
        headerTitle={scoreFactorGroup?.name()}
      >
        {scoreFactorGroup &&
          <>
            <Nav active="general" className="mb-4" scoreFactorGroup={scoreFactorGroup} />
            <AttributeRows attributes={["id", "identifier", "createdAt", "updatedAt"]} model={scoreFactorGroup} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ScoreFactorGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={scoreFactorGroup}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }
}

const selectedScoreFactorGroupAttributes = ["createdAt", "id", "identifier", "name", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedScoreFactorGroupAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default withModel(
  RoutesAdminScoreFactorGroupsShow,
  ScoreFactorGroup,
  {
    abilities: {
      ScoreFactorGroup: ["destroy", "edit"]
    },
    select: {
      ScoreFactorGroup: selectedScoreFactorGroupAttributes,
      Survey: ["id", "name"]
    }
  }
)
