import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import {ClassStep} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import React from "react"
import setLayout from "shared/set-layout"
import useModel from "@kaspernj/api-maker/build/use-model"
const selectedClassStepAttributes = ["createdAt", "id", "name", "primarySchoolStep", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedClassStepAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminClassStepsShow extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {classStep, classStepId} = useModel(ClassStep, {
      abilities: {
        ClassStep: ["destroy", "edit"]
      },
      match: this.p.match,
      select: {
        ClassStep: selectedClassStepAttributes
      }
    })

    this.setInstance({classStep, classStepId})
  }

  render() {
    const {classStep} = this.tt

    return (
      <AdminLayout
        actions={this.actions()}
        active="class-steps"
        className="routes--admin--class-steps--show"
        headerTitle={classStep?.name()}
      >
        {classStep &&
          <>
            <AttributeRows attributes={["id", "primarySchoolStep", "createdAt", "updatedAt"]} model={classStep} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ClassStep.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={classStep}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {classStep} = this.tt

    return (
      <AdminShowActions model={classStep} />
    )
  }
}))
