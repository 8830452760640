import {AccountUser, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AccountUsersTable from "components/account-users/table"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/users/nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserAccountUsers extends ShapeComponent {
  setup() {
    const adminLayout = useAdminLayout()
    const {t} = useI18n({namespace: "js.routes.admin.users.school_roles"})
    const params = useParams()
    const {user, userId} = useModel(User, {
      match: {params},
      preload: [
        "contact.parent_relationships.parent.school",
        "contact.parent_relationships.parent.school_class",
        "school_classes",
        "schools"
      ],
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        School: ["id"],
        SchoolClass: ["id"],
        User: ["accountUsersCount", "id", "subscriptionsCount", "userRolesCount"]
      }
    })

    this.setInstance({
      accountUsersQuery: useMemo(
        () => AccountUser.ransack({
          user_id_eq: userId
        }),
        [userId]
      ),
      canCan: useCanCan(() => [[AccountUser, ["create"]]]),
      t,
      user,
      userId
    })

    adminLayout?.setState({
      actions: this.actions(),
      active: "users",
      headerTitle: User.humanAttributeName("accountUsers")
    })
  }

  render() {
    const {accountUsersQuery, user} = this.tt

    return (
      <View dataSet={{route: "admin--users--account-users"}}>
        {user &&
          <Nav active="account-users" style={{marginBottom: 15}} user={user} />
        }
        <AccountUsersTable adminLayout collection={accountUsersQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, t, userId} = this.tt

    return (
      <>
        {canCan?.can("create", AccountUser) &&
          <Button
            className="new-account-user-button"
            icon="plus"
            label={t(".new_account_for_user")}
            to={Routes.newAdminAccountUserPath({account_user: {user_id: userId}})}
          />
        }
      </>
    )
  }
}))
