import {Course, User} from "models"
import {addCreateAuditColumnIfAdmin} from "components/audits/create-audit-column"
import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import CourseLink from "components/courses/link"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import withCurrentUser from "@kaspernj/api-maker/build/with-current-user"

class ComponentsCourses extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    currentUser: PropTypes.instanceOf(User),
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, currentUser, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--courses--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={Course}
        select={{Course: ["id", "name"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => {
    const columns = [
      {
        attribute: "id",
        defaultVisible: false,
        sortKey: "id"
      },
      {
        content: this.t.courseContent,
        label: Course.humanAttributeName("name"),
        sortKey: "currentTranslationName"
      },
      {
        attribute: "public",
        sortKey: "public"
      },
      {
        attribute: "valuePoints",
        sortKey: "valuePoints"
      }
    ]

    addCreateAuditColumnIfAdmin(this.props, columns, this.p.currentUser)

    return columns
  }

  editModelPath = ({course}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminCoursePath(course.id())
  }

  viewModelPath = ({course}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminCoursePath(course.id())
  }

  courseContent = ({course}) => <CourseLink course={course} {...linkProps(this.props)} />
}

export default withCurrentUser(ComponentsCourses)
