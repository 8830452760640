import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import Routes from "shared/routes"
import StripeForm from "components/stripe/form"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import UtilsButton from "components/utils/button"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesCreditCard extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.add_credit_card"})
    const queryParams = useQueryParams()

    this.setInstance({t})
    this.useStates({mode: "form"})

    useMemo(() => {
      if (queryParams.add_credit_card == "success") {
        this.setState({mode: "success"})

        if (window.opener?.onSuccessfullCallback) {
          window.opener.onSuccessfullCallback({queryParams})
        }

        window.close()
      }
    }, [queryParams.add_credit_card])
  }

  render() {
    const {mode} = this.s

    return (
      <View dataSet={{route: "add-credit-card"}} style={{padding: 20}}>
        <Modal>
          {mode == "form" &&
            <StripeForm returnUrl={Routes.addCreditCardUrl({add_credit_card: "success"})} />
          }
          {mode == "success" &&
            <>
              <Text>
                {this.t(".thank_you_for_adding_your_credit_card")}
              </Text>
              <UtilsButton onPress={this.tt.onCloseWindowPressed} title={this.t(".click_here_to_close_the_window")} />
            </>
          }
        </Modal>
      </View>
    )
  }

  onCloseWindowPressed = () => window.close()
}))
