import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
export default memo(shapeComponent(class RoutesFrontAccounts extends ShapeComponent {
  render() {
    return (
      <Switch name="accounts">
        <Route component="new" path="new" />
        <Route component="created" path=":account_id/created" />
      </Switch>
    )
  }
}))
