import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import EmailTemplateLink from "components/email-templates/link"
import I18n from "shared/i18n"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import {NewsletterTemplate} from "models"
import {Text} from "shared/base"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

const selectedNewsletterTemplateAttributes = [
  "createdAt",
  "cron",
  "id",
  "liquidSearch",
  "name",
  "sendAt",
  "sendAtWithoutTimeZone",
  "translatedState",
  "updatedAt"
].concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesShow extends ShapeComponent {
  setup() {
    const {newsletterTemplate, newsletterTemplateId} = useModel(NewsletterTemplate, {
      abilities: {
        NewsletterTemplate: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["email_template"],
      select: {
        EmailTemplate: ["id", "name"],
        NewsletterTemplate: selectedNewsletterTemplateAttributes
      }
    })
    const {locale} = useI18n({namespace: "js.routes.admin.newsletter_templates.show"})

    this.setInstance({locale, newsletterTemplate, newsletterTemplateId})

    const actions = useMemo(
      () => <AdminShowActions model={newsletterTemplate} />,
      [locale, newsletterTemplate?.id()]
    )

    useAdminLayout()?.setState({actions, active: "newsletter-templates", headerTitle: newsletterTemplate?.name()})
  }

  render() {
    const {newsletterTemplate} = this.tt

    return (
      <View dataSet={{route: "admin--newsletter-templates--show"}}>
        {newsletterTemplate &&
          <>
            <Nav active="general" className="mb-4" newsletterTemplate={newsletterTemplate} />
            <AttributeRows attributes={["id", "cron", "createdAt", "updatedAt"]} model={newsletterTemplate} />
            <AttributeRow attribute="sendAt" model={newsletterTemplate}>
              {newsletterTemplate.sendAtWithoutTimeZone() && I18n.l("time.formats.default", newsletterTemplate.sendAtWithoutTimeZone())}
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${NewsletterTemplate.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={newsletterTemplate}
                />
              </React.Fragment>
            )}
            <AttributeRow attribute="state" model={newsletterTemplate}>
              {newsletterTemplate.translatedState()}
            </AttributeRow>
            <AttributeRow attribute="emailTemplate" model={newsletterTemplate}>
              {newsletterTemplate.emailTemplate() &&
                <EmailTemplateLink adminLayout emailTemplate={newsletterTemplate.emailTemplate()} />
              }
            </AttributeRow>
            <AttributeRow attribute="liquidSearch" model={newsletterTemplate}>
              <Text>{newsletterTemplate.liquidSearch()}</Text>
            </AttributeRow>
          </>
        }
      </View>
    )
  }
}))
