import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {Subscription, User} from "models"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import Nav from "components/admin/users/nav"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import SubscriptionsTable from "components/subscriptions/table"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminUserSubscriptions extends ShapeComponent {
  setup() {
    setLayout("admin")

    const currentUser = useCurrentUser()
    const canCan = useCanCan(() => [[Subscription, ["create"]]], [currentUser?.id()])
    const {user, userId} = useModel(User, {
      match: this.p.match,
      preload: [
        "contact.parent_relationships.parent.school",
        "contact.parent_relationships.parent.school_class",
        "school_classes",
        "schools"
      ],
      select: {
        Contact: ["id"],
        ContactRelationship: ["id"],
        School: ["id"],
        SchoolClass: ["id"],
        User: ["accountUsersCount", "id", "subscriptionsCount", "userRolesCount"]
      }
    })
    const {locale, t} = useI18n({namespace: "js.routes.admin.users.subscriptions"})

    this.setInstance({canCan, locale, user, userId, t})

    const actions = useMemo(
      () => <>
        {this.tt.canCan?.can("create", Subscription) &&
          <Button
            className="new-subscription-button"
            icon="plus"
            label={t(".add_new_subscription")}
            to={Routes.newAdminSubscriptionPath({subscription: {resource_id: this.tt.userId, resource_type: "User"}})}
          />
        }
      </>,
      [canCan, locale, userId]
    )

    useAdminLayout()?.setState({
      actions,
      active: "users",
      headerTitle: User.humanAttributeName("subscriptions")
    })
  }

  render() {
    const {user, userId} = this.tt
    const subscriptionsQuery = useMemo(
      () => Subscription.ransack({
        resource_type_eq: "User",
        resource_id_eq: userId
      }),
      [userId]
    )

    return (
      <View dataSet={{route: "admin--users--subscriptions"}}>
        {user &&
          <Nav active="subscriptions" style={{marginBottom: 15}} user={user} />
        }
        <SubscriptionsTable
          adminLayout
          collection={subscriptionsQuery}
        />
      </View>
    )
  }
}))
