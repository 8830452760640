import {SchoolClass, SchoolClassChallenge} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import CircleIconButton from "components/circle-icon-button"
import classNames from "classnames"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import UnicornUpgradeOverlay from "components/unicorn/upgrade-overlay"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useHasUnicorn from "components/subscriptions/use-has-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserSchoolClassesDigitalClassRoomChallengeButton extends ShapeComponent {
  static propTypes = {
    className: PropTypes.string,
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const currentUser = useCurrentUser()
    const {t} = useI18n({namespace: "js.routes.user.school_classes.digital_class_room"})
    const {hasUnicorn} = useHasUnicorn({schoolClassId: this.p.schoolClass.id(), user: currentUser})

    this.setInstance({hasUnicorn, t})
    this.useStates({
      challengesLastMonthCount: undefined,
      disabled: false,
      unicornSubscriptionModal: undefined
    })
  }

  render() {
    const {className, disabled, schoolClass, ...restProps} = this.props
    const {hasUnicorn, t} = this.tt

    return (
      <>
        {this.s.unicornSubscriptionModal &&
          <UnicornUpgradeOverlay onRequestClose={this.tt.onUnicornSubscriptionOverlayRequestClose} schoolClass={schoolClass} />
        }
        {hasUnicorn !== undefined &&
          <CircleIconButton
            className={classNames("components--user--school-classes--digital-class-room--challenge-button", className)}
            disabled={this.s.disabled || disabled || false}
            icon="cubes-stacked"
            label={t(".challenge")}
            onClick={this.tt.onClick}
            {...restProps}
          />
        }
      </>
    )
  }

  async countChallengesLastMonth() {
    const weekAgo = moment().subtract(1, "month").toDate()
    const challengesLastMonthCount = await SchoolClassChallenge
      .ransack({
        created_at_gteq: weekAgo,
        school_class_id_eq: this.p.schoolClass.id()
      })
      .count()

    this.setState({challengesLastMonthCount})
  }

  onClick = async (e) => {
    e.preventDefault()

    await this.countChallengesLastMonth()

    const {challengesLastMonthCount} = this.s

    if (this.tt.hasUnicorn === false && challengesLastMonthCount >= 1) {
      this.setState({unicornSubscriptionModal: true})
    } else {
      try {
        this.setState({disabled: true})

        const result = await this.p.schoolClass.randomChallenge()
        const schoolClassChallenge = digg(result, "school_class_challenge")
        const teamSurvey = digg(result, "team_survey")

        AppHistory.push(Routes.userSchoolClassChallengePath(schoolClassChallenge.id(), {team_survey_id: teamSurvey.id()}))
      } catch (error) {
        FlashMessage.errorResponse(error)
      } finally {
        this.setState({disabled: false})
      }
    }
  }

  onUnicornSubscriptionOverlayRequestClose = () => {
    this.setState({unicornSubscriptionModal: false})
  }
}))
