import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsActionsSuperAdmin extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.components.actions.super_admin"})
    const {className, ...restProps} = this.props

    return (
      <Link className={classNames("components--actions--super-admin", "action-button", className)} title={t(".super_admin")} {...restProps}>
        <i className="fa fa-fw fa-wheelchair-move" />
        <span className="action-button-label">
          {t(".super_admin")}
        </span>
      </Link>
    )
  }
}))
