import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import Input from "components/inputs/input"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import React from "react"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
export default memo(shapeComponent(class RoutesFrontUsersResendConfirmationEmail extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.users.resend_confirmation_email"})

    this.t = t
    this.useStates({
      user: new User()
    })

    useFrontLayout()?.setState({
      headTitle: t(".resend_confirmation_email")
    })
  }

  render() {
    const {t} = this.tt

    return (
      <div className="routes--front--users--resend-confirmation-email">
        <form onSubmit={this.tt.onSubmit}>
          <MainCard header={t(".resend_confirmation_email")} subHeader={t(".if_you_havent_gotten_a_confirmation_email")}>
            <Input attribute="email" model={this.s.user} />
            <Button label={t(".resend_confirmation_email")} primary style={{width: "100%"}} />
          </MainCard>
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {t} = this.tt
    const form = digg(e, "target")

    try {
      await User.resendConfirmationEmail(form)
      FlashMessage.success(t(".if_a_user_with_such_an_email_address_exists_we_have_resent_confirmation_email"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
