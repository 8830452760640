import BaseComponent from "components/base-component"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

export default class ComponentsComingSoon extends BaseComponent {
  static defaultProps = {
    active: true,
    small: false
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    small: PropTypes.bool.isRequired
  }

  render() {
    const {active, children, className, small, ...restProps} = this.props

    return (
      <div className={classNames("components--coming-soon", className)} data-active={active} data-small={small} {...restProps}>
        {active &&
          <div className="coming-soon-overlay">
            <div className="coming-soon-label-container">
              <div className="coming-soon-label">
                <i className="fa fa-lock" />
              </div>
            </div>
          </div>
        }
        <div className="coming-soon-children">
          {children}
        </div>
      </div>
    )
  }
}
