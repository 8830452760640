import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {SurveyStepElementOption, SurveyStepElementOptionScore} from "models"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import Button from "components/inputs/button"
import ElementInstance from "components/survey-step-elements/element-instance"
import ImageOptionsOptionElement from "components/survey-step-elements/partial-types/image-options/option-element"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import RadioButtonsOptionElement from "components/survey-step-elements/partial-types/radio-buttons/option-element"
import Routes from "shared/routes"
import ScoresTable from "components/survey-step-element-option-scores/table"
import SurveyStepElementLink from "components/survey-step-elements/link"
import {Text} from "shared/base"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedSurveyStepElementOptionAttributes = [
  "createdAt",
  "fontAwesomeIcon",
  "hideLabel",
  "id",
  "identifier",
  "imageUrl",
  "name",
  "position",
  "textStyle",
  "updatedAt"
]

for (const locale of Locales.availableLocales()) {
  selectedSurveyStepElementOptionAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default memo(shapeComponent(class RoutesAdminSurveyStepElementOptionsShow extends ShapeComponent {
  setup() {
    const canCan = useCanCan(() => [[SurveyStepElementOptionScore, ["create"]]])
    const {t} = useI18n({namespace: "js.routes.admin.survey_step_element_options.show"})
    const params = useParams()
    const {surveyStepElementOption, surveyStepElementOptionId} = useModel(SurveyStepElementOption, {
      abilities: {
        SurveyStepElementOption: ["destroy", "edit"]
      },
      match: {params},
      preload: ["element"],
      select: {
        SurveyStepElement: ["elementType", "id", "name"],
        SurveyStepElementOption: selectedSurveyStepElementOptionAttributes
      }
    })

    const elementInstance = useMemo(
      () => new ElementInstance(),
      [surveyStepElementOption?.id()]
    )

    elementInstance.set({elementIndex: 0})

    const scoresQuery = useMemo(
      () => SurveyStepElementOptionScore.ransack({
        s: "created_at asc",
        survey_step_element_option_id_eq: surveyStepElementOptionId
      }),
      [surveyStepElementOptionId]
    )

    this.setInstance({
      canCan,
      elementInstance,
      scoresQuery,
      surveyStepElementOption,
      surveyStepElementOptionId,
      t
    })
    this.useStates({checked: false})
    useAdminLayout()?.setState({actions: this.actions(), active: "surveys", headerTitle: surveyStepElementOption?.name()})
  }

  render() {
    const {elementInstance, scoresQuery, surveyStepElementOption, t} = this.tt

    return (
      <View dataSet={{route: "admin--survey-step-element-options--show"}}>
        {surveyStepElementOption &&
          <>
            <AttributeRows
              attributes={["id", "identifier", "fontAwesomeIcon", "hideLabel", "position", "createdAt", "updatedAt"]}
              model={surveyStepElementOption}
            />
            <AttributeRow attribute="element" model={surveyStepElementOption}>
              {surveyStepElementOption.element() &&
                <SurveyStepElementLink adminLayout surveyStepElement={surveyStepElementOption.element()} />
              }
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${SurveyStepElementOption.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={surveyStepElementOption}
                />
              </React.Fragment>
            )}
            <AttributeRow label={t("js.shared.image")}>
              {surveyStepElementOption.hasImageUrl() &&
                <a href={surveyStepElementOption.imageUrl()} target="_blank">
                  <img src={surveyStepElementOption.imageUrl()} style={{maxWidth: "100%", maxHeight: "100%"}} />
                </a>
              }
              {!surveyStepElementOption.hasImageUrl() &&
                <Text>{this.t("js.shared.no_image")}</Text>
              }
            </AttributeRow>
            <AttributeRow attribute="textStyle" model={surveyStepElementOption}>
              <pre>
                {surveyStepElementOption.hasTextStyle() && JSON.stringify(surveyStepElementOption.textStyle(), null, 2)}
              </pre>
            </AttributeRow>
            <AttributeRow label={t("js.shared.preview")}>
              {surveyStepElementOption?.element()?.elementType() == "image_options" &&
                <ImageOptionsOptionElement
                  answer={null}
                  checked={this.s.checked}
                  elementInstance={elementInstance}
                  onChange={this.tt.onOptionChecked}
                  option={surveyStepElementOption}
                  optionIndex={0}
                />
              }
              {surveyStepElementOption?.element()?.elementType() == "radio_buttons" &&
                <RadioButtonsOptionElement
                  answer={null}
                  checked={this.s.checked}
                  elementInstance={elementInstance}
                  onChange={this.tt.onOptionChecked}
                  option={surveyStepElementOption}
                  optionIndex={0}
                />
              }
            </AttributeRow>
          </>
        }
        <ScoresTable adminLayout collection={scoresQuery} />
      </View>
    )
  }

  actions() {
    const {canCan, surveyStepElementOption, surveyStepElementOptionId, t} = this.tt

    return (
      <>
        {canCan?.can("create", SurveyStepElementOptionScore) &&
          <Button
            className="new-score-button"
            icon="plus"
            label={t(".add_new_score")}
            to={Routes.newAdminSurveyStepElementOptionScorePath({survey_step_element_option_score: {survey_step_element_option_id: surveyStepElementOptionId}})}
          />
        }
        <AdminShowActions model={surveyStepElementOption} />
      </>
    )
  }

  onOptionChecked = ({checked}) => {
    this.setState({checked})
  }
}))
