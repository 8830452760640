import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {Survey, SurveyStep, TeamSurvey, TeamSurveyStep} from "models"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsSurveysBottomBar extends ShapeComponent {
  static propTypes = {
    color: PropTypes.string,
    onCancelClicked: PropTypes.func.isRequired,
    onEndClicked: PropTypes.func.isRequired,
    onNextStepClicked: PropTypes.func.isRequired,
    onPreviousStepClicked: PropTypes.func.isRequired,
    survey: PropTypes.instanceOf(Survey).isRequired,
    surveyStep: PropTypes.instanceOf(SurveyStep).isRequired,
    teamSurvey: PropTypes.instanceOf(TeamSurvey).isRequired,
    teamSurveyStep: PropTypes.instanceOf(TeamSurveyStep).isRequired
  }

  render() {
    const {t} = useI18n({namespace: "js.routes.user.school_class_course_modules.show"})
    const {
      className,
      color,
      onCancelClicked,
      onEndClicked,
      onNextStepClicked,
      onPreviousStepClicked,
      survey,
      surveyStep,
      teamSurvey,
      teamSurveyStep,
      ...restProps
    } = this.props

    let colorStyling = ""

    if (color == "white") colorStyling = "text-white"

    return (
      <div className={classNames("components--surveys--bottom-bar", className)} {...restProps}>
        <div className="next-previous-slide-container">
          {teamSurveyStep && surveyStep && this.isFirstStep() &&
            <a className={`cancel-survey-link ${colorStyling} text-nowrap`} href="#" onClick={this.tt.onCancelClicked}>
              <i className="fa fa-remove mr-2" />
              <span className="d-up-to-md-none">
                {t(".cancel_module")}
              </span>
              <span className="d-up-from-md-none">
                {t(".cancel")}
              </span>
            </a>
          }
          {teamSurveyStep && surveyStep && !this.isFirstStep() &&
            <a className={`previous-step-link ${colorStyling} text-nowrap`} href="#" onClick={this.tt.onPreviousStepClicked}>
              <i className="fa fa-chevron-left mr-2" />
              <span className="d-up-to-md-none">
                {t(".previous_slide")}
              </span>
              <span className="d-up-from-md-none">
                {t(".previous")}
              </span>
            </a>
          }
        </div>
        <div className="progress-bar-container">
          <div className="progress-bar-outer">
            {teamSurveyStep && surveyStep &&
              <div className="progress-bar-progress" style={{width: this.progressBarWidth()}} />
            }
          </div>
        </div>
        <div className="next-previous-slide-container">
          {teamSurveyStep && surveyStep && this.isLastStep() &&
            <a className={`end-survey-link ${colorStyling} text-nowrap`} href="#" onClick={this.tt.onEndClicked}>
              <span className="d-up-to-md-none">
                {t(".end_module")}
              </span>
              <span className="d-up-from-md-none">
                {t(".end")}
              </span>
              <i className="fa fa-chevron-right ml-2" />
            </a>
          }
          {teamSurveyStep && surveyStep && !this.isLastStep() &&
            <a className={`next-step-link ${colorStyling} text-nowrap`} href="#" onClick={this.tt.onNextStepClicked}>
              <span className="d-up-to-md-none">
                {t(".next_slide")}
              </span>
              <span className="d-up-from-md-none">
                {t(".next")}
              </span>
              <i className="fa fa-chevron-right ml-2" />
            </a>
          }
        </div>
      </div>
    )
  }

  isFirstStep = () => this.p.surveyStep.position() == 1
  isLastStep = () => this.p.surveyStep.position() >= this.p.survey.surveyStepsCount()
  progressBarWidth = () => {
    const totalSteps = this.p.survey.surveyStepsCount()
    const currentStep = this.p.surveyStep.position()
    const percentageWidth = (parseFloat(currentStep) / parseFloat(totalSteps)) * 100.0

    return `${percentageWidth}%`
  }

  onCancelClicked = (e) => {
    e.preventDefault()
    this.p.onCancelClicked()
  }

  onEndClicked = (e) => {
    e.preventDefault()
    this.p.onEndClicked()
  }

  onNextStepClicked = (e) => {
    e.preventDefault()
    this.p.onNextStepClicked()
  }

  onPreviousStepClicked = (e) => {
    e.preventDefault()
    this.p.onPreviousStepClicked()
  }
}))
