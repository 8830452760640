import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import AppHistory from "shared/history"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import {Plan} from "models"
import PlanLink from "components/plans/link"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsPlansTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired
  }

  render() {
    const {locale, t} = useI18n({namespace: "js.components.plans.table"})
    const {adminLayout, className, userLayout, ...restProps} = this.props

    const columns = useMemo(
      () => [
        {
          attribute: "name",
          content: this.tt.nameContent,
          sortKey: "currentTranslationName"
        },
        {
          attribute: "identifier",
          sortKey: "identifier"
        },
        {
          attribute: "position",
          sortKey: "position"
        },
        {
          attribute: "showInSignUpModal",
          sortKey: "showInSignUpModal"
        },
        {
          attribute: "createdAt",
          sortKey: "createdAt"
        }
      ],
      [locale]
    )

    return (
      <ApiMakerTable
        appHistory={AppHistory}
        className={classNames("components--plans--table", className)}
        columns={columns}
        defaultParams={{s: "position"}}
        destroyMessage={t(".the_plan_was_deleted")}
        editModelPath={this.tt.editModelPath}
        modelClass={Plan}
        select={{Plan: ["createdAt", "id", "identifier", "name"]}}
        viewModelPath={this.tt.viewModelPath}
        {...restProps}
      />
    )
  }

  editModelPath = ({plan}) => this.p.adminLayout && Routes.editAdminPlanPath(plan.id())
  viewModelPath = ({plan}) => this.p.adminLayout && Routes.adminPlanPath(plan.id())
  nameContent = ({plan}) => <PlanLink plan={plan} {...linkProps(this.props)} />
}))
