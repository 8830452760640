import React, {createContext} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import {HelperText} from "react-native-paper"
import memo from "set-state-compare/src/memo"
import {Text} from "shared/base"
import useInput from "@kaspernj/api-maker/build/use-input"
import {View} from "react-native"

const AdminSelectContainerContext = createContext(null)
const styles = {
  selectContainer: {
    paddingTop: 4,
    paddingRight: 16,
    paddingBottom: 6,
    paddingLeft: 16,
    border: 0,
    background: "transparent"
  }
}

const ComponentsAdminSelectContainer = memo(shapeComponent(class ComponentsAdminSelectContainer extends ShapeComponent {
  setup() {
    const {wrapperOpts} = useInput({props: this.props})
    const {errors} = digs(wrapperOpts, "errors")

    this.setInstance({errors, wrapperOpts})
  }

  render() {
    const {errors, wrapperOpts} = this.tt
    const {children, containerProps} = this.props

    return (
      <View {...containerProps}>
        <View
          style={{
            backgroundColor: "rgb(231, 224, 236)",
            borderBottom: "1px solid rgb(73, 69, 79)",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
          }}
        >
          <Text
            style={{
              paddingTop: 8,
              paddingLeft: 16,
              color: "rgb(73, 69, 79)",
              fontSize: 12
            }}
          >
            {wrapperOpts.label}
          </Text>
          <AdminSelectContainerContext.Provider value="withinAdminSelectContainer">
            {children}
          </AdminSelectContainerContext.Provider>
        </View>
        {errors.length > 0 &&
          <HelperText type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  errorMessages () {
    const {errors} = this.tt
    const errorMessages = []

    for (const error of errors) {
      for (const errorMessage of error.getErrorMessages()) {
        errorMessages.push(errorMessage)
      }
    }

    return errorMessages
  }
}))

const withSelectContainer = (WrappedComponent) => memo((props) => {
  const {containerProps, ...restProps} = props

  return (
    <ComponentsAdminSelectContainer {...props}>
      <WrappedComponent {...restProps} />
    </ComponentsAdminSelectContainer>
  )
})

export {AdminSelectContainerContext, styles, withSelectContainer}
export default ComponentsAdminSelectContainer
