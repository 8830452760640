import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorGroup} from "models"
import ScoreFactorGroupsTable from "components/score-factor-groups/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n"

export default memo(shapeComponent(class RoutesAdminScoreFactorGroupsIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.score_factor_groups.index"})

    this.canCan = useCanCan(() => [[ScoreFactorGroup, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="score-factor-groups"
        className="routes--admin-score-factor-groups--index"
        headerTitle={ScoreFactorGroup.modelName().human({count: 2})}
      >
        <ScoreFactorGroupsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", ScoreFactorGroup) &&
          <Button
            className="new-score-factor-group-button"
            icon="plus"
            label={t(".add_new_score_factor_group")}
            to={Routes.newAdminScoreFactorGroupPath()}
          />
        }
      </>
    )
  }
}))
