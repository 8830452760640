import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import Route from "@kaspernj/api-maker/build/router/route"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useWoofRouter} from "shared/woof-router"
export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesRoute extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="newsletter-templates">
        <Route component="index" exact />
        <Route component="edit" exact path="new" />
        <Route component="edit" exact path=":newsletter_template_id/edit" />
        <Route component="audits" exact path=":newsletter_template_id/audits" />
        <Route component="newsletters" exact path=":newsletter_template_id/newsletters" />
        <Route component="newsletter-template-time-zone-states" exact path=":newsletter_template_id/newsletter_template_time_zone_states" />
        <Route component="users-to-receive" exact path=":newsletter_template_id/users_to_receive" />
        <Route component="show" exact path=":newsletter_template_id" />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))
