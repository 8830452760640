import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import {Newsletter} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsNewslettersLink extends ShapeComponent {
  static propTypes = {
    adminLayout: PropTypes.bool,
    className: PropTypes.string,
    newsletter: PropTypes.instanceOf(Newsletter).isRequired,
    userLayout: PropTypes.bool
  }

  render() {
    const {adminLayout = false, children, className, newsletter, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--newsletters--link", className)
    let to

    if (adminLayout) {
      to = Routes.adminNewsletterPath(newsletter.id())
    } else {
      to = "#"
    }

    if (to) {
      return (
        <Link className={generatedClassName} to={to} {...restProps}>
          {children || newsletter.id()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || newsletter.id()}
      </span>
    )
  }
}))
