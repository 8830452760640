import {Portal, PortalHost} from "conjointment"
import React, {createContext, useContext, useMemo} from "react"
import {SchoolClass, User} from "models"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digg} from "diggerize"
import globalState from "components/front-layout/side-bar-menu-button/global-state"
import LayoutBase from "components/layout/base"
import memo from "set-state-compare/src/memo"
import PlansSignUpModal from "components/plans/sign-up-modal"
import PropTypes from "prop-types"
import SideBarMenuButton from "./side-bar-menu-button"
import {SideBarMenuOpenedContext} from "components/front-layout/use-side-bar-menu-opened"
import TopMenu from "./top-menu"
import UnicornTopBar from "components/user/unicorn/top-bar"
import useBreakpoint from "@kaspernj/api-maker/build/use-breakpoint"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import UsersSignIn from "components/users/sign-in"
import {View} from "react-native"

const FrontLayoutContext = createContext(null)
const useFrontLayout = () => useContext(FrontLayoutContext)

export {useFrontLayout}

export default memo(shapeComponent(class ComponentsFrontLayout extends ShapeComponent {
  static defaultProps = {
    contentCentered: false,
    darkBackground: false,
    headerTitleCentered: false,
    layout: "front",
    logo: true,
    requireUser: false,
    unicornTrialTopBar: false,
    userMenu: true
  }

  static propTypes = {
    actions: PropTypes.node,
    active: PropTypes.string,
    background: PropTypes.string,
    bottomBar: PropTypes.node,
    contentCentered: PropTypes.bool.isRequired,
    currentUser: PropTypes.instanceOf(User),
    darkBackground: PropTypes.bool.isRequired,
    headTitle: PropTypes.string,
    headerStyle: PropTypes.object,
    headerTitle: PropTypes.string,
    headerTitleCentered: PropTypes.bool.isRequired,
    layout: PropTypes.string.isRequired,
    leftActions: PropTypes.node,
    logo: PropTypes.bool.isRequired,
    requireUser: PropTypes.bool.isRequired,
    schoolClass: PropTypes.instanceOf(SchoolClass),
    subHeader: PropTypes.node,
    topLeft: PropTypes.node,
    topRight: PropTypes.node,
    userMenu: PropTypes.bool.isRequired
  }

  setup() {
    this.setInstance({
      breakpoint: useBreakpoint(),
      currentUser: useCurrentUser()
    })
    this.useStates({
      actions: null,
      active: null,
      background: null,
      bottomBar: null,
      className: null,
      contentCentered: false,
      darkBackground: false,
      headerTitle: null,
      headerTitleCentered: false,
      headerStyle: null,
      headTitle: null,
      requireUser: false,
      schoolClass: null,
      sideBarMenuOpened: digg(globalState, "menuOpened"),
      showUpgrade: false,
      subHeader: null,
      topLeft: null,
      topRight: null,
      unicornTrialTopBar: false
    })

    useMemo(() => {
      this.updateSideBarMenuOpenedOnBody()
    }, [])
  }

  render() { // eslint-disable-line complexity
    const {
      actions,
      active,
      background,
      bottomBar,
      children,
      className,
      contentCentered,
      darkBackground,
      headerStyle,
      headerTitle,
      headerTitleCentered,
      headTitle,
      layout,
      leftActions,
      logo,
      requireUser,
      schoolClass,
      subHeader,
      style,
      topLeft,
      topRight,
      unicornTrialTopBar,
      userMenu,
      ...restProps
    } = this.props
    const {breakpoint} = this.tt
    const hasDarkBackground = darkBackground || this.s.darkBackground
    const outerStyle = {
      flex: 1,
      flexDirection: "column"
    }
    const headerStyleToUse = Object.assign({}, headerStyle, this.s.headerStyle)
    const subHeaderStyle = {}
    const actualStyle = Object.assign(
      {
        width: "100%",
        minHeight: "100%"
      },
      style
    )

    if (background || this.s.background) {
      outerStyle.backgroundImage = `url('${background || this.s.background}')`
      outerStyle.backgroundPosition = "center center"
      outerStyle.backgroundSize = "cover"
    }

    if (hasDarkBackground) {
      actualStyle.backgroundColor = "#1b1c1e"
    } else {
      actualStyle.backgroundColor = "#f6f4f4"
    }

    if (headerTitleCentered || this.s.headerTitleCentered) {
      headerStyleToUse.textAlign = "center"
      subHeaderStyle.textAlign = "center"
    }

    if (this.s.sideBarMenuOpened) {
      actualStyle.width = "calc(100% - 250px)"
      actualStyle.marginLeft = 250
    }

    return (
      <SideBarMenuOpenedContext.Provider value={this.s.sideBarMenuOpened}>
        <LayoutBase
          className={classNames("components--front-layout", className, this.s.className)}
          dataSet={{
            darkBackground: hasDarkBackground,
            sideBarMenuOpened: this.s.sideBarMenuOpened
          }}
          headTitle={headTitle || this.s.headTitle}
          headerTitle={headerTitle || this.s.headerTitle}
          layout={layout}
          schoolClass={schoolClass || this.s.schoolClass}
          style={actualStyle}
          {...restProps}
        >
          <Portal name="side-bar-menu-button">
            <SideBarMenuButton
              active={active || this.s.active}
              onClosed={this.tt.onSideBarMenuClosed}
              onOpened={this.tt.onSideBarMenuOpened}
              onUpgradePressed={this.tt.onUpgradePressed}
            />
          </Portal>
          {this.s.showUpgrade &&
            <PlansSignUpModal onRequestClose={this.tt.onRequestClosePlansSignUpModal} />
          }
          {(unicornTrialTopBar || this.s.unicornTrialTopBar) &&
            <UnicornTopBar onUpgradePressed={this.tt.onUpgradePressed} />
          }
          <View dataSet={{class: "front-layout-outer"}} style={outerStyle}>
            <PortalHost name="content">
              <View
                dataSet={{class: "front-layout-content-container"}}
                style={{
                  flex: 1,
                  padding: breakpoint.mdDown ? 15 : 30
                }}
              >
                {(topLeft || this.s.topLeft || topRight || this.s.topRight) &&
                  <View
                    dataSet={{class: "front-layout-actions"}}
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      marginBottom: 8
                    }}
                  >
                    <div>
                      {topLeft || this.s.topLeft}
                    </div>
                    <div>
                      {topRight || this.s.topRight}
                    </div>
                  </View>
                }
                {!this.tt.showSignIn() &&
                  <>
                    <TopMenu actions={actions || this.s.actions} layout={layout} leftActions={leftActions} userMenu={userMenu} />
                    {headerTitle &&
                      <h1 className="font-layout-header" style={headerStyleToUse}>{headerTitle}</h1>
                    }
                    {(subHeader || this.s.subHeader) &&
                      <div className="sub-header-content" style={subHeaderStyle}>
                        {subHeader || this.s.subHeader}
                      </div>
                    }
                    <View
                      dataSet={{class: "children-outer-container"}}
                      style={{
                        flex: 1,
                        alignItems: (contentCentered || this.s.contentCentered) ? "center" : undefined,
                        justifyContent: (contentCentered || this.s.contentCentered) ? "center" : undefined
                      }}
                    >
                      <FrontLayoutContext.Provider value={this.tt.frontLayoutContext}>
                        {children}
                      </FrontLayoutContext.Provider>
                    </View>
                  </>
                }
                {this.tt.showSignIn() &&
                  <UsersSignIn />
                }
              </View>
              {(bottomBar || this.s.bottomBar) &&
                <div className="front-layout-bottom-bar">
                  {bottomBar || this.s.bottomBar}
                </div>
              }
            </PortalHost>
          </View>
        </LayoutBase>
      </SideBarMenuOpenedContext.Provider>
    )
  }

  frontLayoutContext = ({
    setState: (states) => {
      this.setState(states)
    }
  })

  onRequestClosePlansSignUpModal = () => this.setState({showUpgrade: false})
  onSideBarMenuClosed = () => this.setState({sideBarMenuOpened: false})
  onSideBarMenuOpened = () => this.setState({sideBarMenuOpened: true})
  onUpgradePressed = () => this.setState({showUpgrade: true})

  setSideBarMenuOpened = (value) => {
    this.setState({sideBarMenuOpened: value})
    this.updateSideBarMenuOpenedOnBody()
  }

  updateSideBarMenuOpenedOnBody = () => {
    document.body.dataset.sideBarMenuOpened = this.s.sideBarMenuOpened
  }

  showSignIn = () => {
    if ((this.p.requireUser || this.s.requireUser) && !this.tt.currentUser) return true

    return false
  }
}))
