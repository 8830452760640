import {Contact, NotificationSetting, SchoolClassInvitation, User} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import Routes from "shared/routes"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useQueryParams from "on-location-changed/build/use-query-params"
import UsersAcceptTermsHint from "components/users/accept-terms-hint"
import usersUpdateSession from "components/users/update-session"

export default memo(shapeComponent(class RoutesFrontSchoolClassInvitations extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.front.school_class_invitations.accept"})

    this.useStates({
      invitation: undefined,
      invitationNotFound: undefined,
      notificationSetting: undefined,
      user: undefined,
      userMode: "new-user"
    })
    this.setInstance({
      queryParams: useQueryParams(),
      t
    })

    useMemo(() => {
      this.tt.loadInvitation()
    }, [])
    useFrontLayout()?.setState({headerTitle: t(".accept_invite_to_class")})
  }

  loadInvitation = async () => {
    const token = digg(this.tt.queryParams, "token")
    const result = await SchoolClassInvitation.findByToken({token})
    const invitation = digg(result, "invitation")
    const createdUser = digg(result, "created_user")
    let notificationSetting = digg(result, "notification_setting")

    if (invitation) {
      let user

      if (createdUser) {
        user = createdUser
      } else {
        user = new User({email: invitation.email()})
      }

      if (!notificationSetting) notificationSetting = new NotificationSetting()

      this.setState({invitation, invitationNotFound: false, notificationSetting, user})
    } else {
      this.setState({invitationNotFound: true})
    }
  }

  render() {
    const {t} = this.tt

    return (
      <div className="routes--front--school-class-invitations--accept">
        asd
        {this.s.invitation &&
          <form onSubmit={this.tt.onAcceptInviteSubmit}>
            <input name="token" type="hidden" value={this.tt.queryParams.token} />
            <input name="user_mode" type="hidden" value={this.s.userMode} />
            <Nav style={{marginBottom: "50px"}}>
              <NavItem
                active={this.s.userMode}
                identifier="new-user"
                label={t(".new_user")}
                onClick={this.tt.onNewUserClicked}
              />
              <NavItem
                active={this.s.userMode}
                identifier="existing-user"
                label={t(".existing_user")}
                onClick={this.tt.onExistingUserClicked}
              />
            </Nav>
            {this.s.userMode == "new-user" &&
              <>
                <Input attribute="email" model={this.s.user} />
                <Input attribute="firstName" label={Contact.humanAttributeName("firstName")} model={this.s.user} />
                <Input attribute="lastName" label={Contact.humanAttributeName("lastName")} model={this.s.user} />
                <Input attribute="password" defaultValue="" label={t("js.shared.password")} model={this.s.user} type="password" />
                <Input attribute="passwordConfirmation" defaultValue="" label={t("js.shared.password_confirmation")} model={this.s.user} type="password" />
                {this.s.notificationSetting.isPersisted() &&
                  <input
                    name="user[notification_setting_attributes][id]"
                    type="hidden"
                    value={this.s.notificationSetting.id()}
                  />
                }
                <Checkbox
                  attribute="acceptTerms"
                  hint={<UsersAcceptTermsHint />}
                  model={this.s.notificationSetting}
                  name="user[notification_setting_attributes][accept_terms]"
                />
                <Checkbox
                  attribute="acceptMarketing"
                  hint={t(".you_can_always_unsubscribe")}
                  model={this.s.notificationSetting}
                  name="user[notification_setting_attributes][accept_marketing]"
                />
                <Button label={t(".create_user_and_accept_invite")} primary />
              </>
            }
            {this.s.userMode == "existing-user" &&
              <>
                <Input attribute="email" model={this.s.user} />
                <Input attribute="password" defaultValue="" label={I18n.t("js.shared.password")} model={this.s.user} type="password" />
                <Button label={t(".sign_in_and_accept_invite")} primary />
              </>
            }
          </form>
        }
        {this.s.invitationNotFound &&
          t(".invitation_couldnt_be_found")
        }
      </div>
    )
  }

  onAcceptInviteSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)

    try {
      const result = await SchoolClassInvitation.accept(formData)
      const user = digg(result, "user")

      await usersUpdateSession(user.id())
      FlashMessage.success(this.t(".the_invitation_was_accepted"))
      AppHistory.push(Routes.userRootPath())
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onNewUserClicked = (e) => {
    e.preventDefault()

    this.setState({
      userMode: "new-user"
    })
  }

  onExistingUserClicked = (e) => {
    e.preventDefault()

    this.setState({
      userMode: "existing-user"
    })
  }
}))
