export default class Cycler {
  constructor(list) {
    this.list = list
    this.index = 0
  }

  cycle() {
    const result = this.list[this.index]

    this.index += 1

    if (this.index >= (this.list.length - 1)) {
      this.index = 0
    }

    return result
  }
}

const colors = [
  "#ea31e3",
  "#ea5231",
  "#64bbec",
  "#6a5eec"
]

export {colors}
