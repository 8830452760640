import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {View} from "react-native"
export default memo(shapeComponent(class ComponentsSliderSlide extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string
  })

  render() {
    const {active, children, className} = this.props

    return (
      <View
        children={children}
        dataSet={{
          active,
          class: className,
          component: "slider--slide"
        }}
        style={{display: active ? "flex" : "none"}}
      />
    )
  }
}))
