import {CourseGroup} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectCourseGroups,
  withInputContainer: CourseGroupsSelectWithContainer,
  withMemo: ComponentsCourseGroupsSelect
} = selectComponent({
  className: "components--course-groups--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || CourseGroup

    query = query
      .ransack({s: "position asc"})
      .select({CourseGroup: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const courseGroups = await query.toArray()

    return courseGroups.map((courseGroup) => ({
      text: courseGroup.name(),
      value: courseGroup.id()
    }))
  }
})

export default ComponentsCourseGroupsSelect
export {AdminSelectCourseGroups, CourseGroupsSelectWithContainer}
