import {Contact} from "models"
import presence from "shared/presence"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectContacts,
  withInputContainer: ContactsSelectWithContainer,
  withMemo: ComponentsContactsSelect
} = selectComponent({
  className: "components--contacts--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Contact

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Contact: ["firstName", "id", "lastName", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const contacts = await query.toArray()

    return contacts.map((contact) => ({
      text: presence(contact.name()) || `${contact.firstName()} ${contact.lastName()}`,
      value: contact.id()
    }))
  }
})

export default ComponentsContactsSelect
export {AdminSelectContacts, ContactsSelectWithContainer}
