import {Newsletter, NewsletterTemplate} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import NewslettersTable from "components/newsletters/table"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplatesNewsletters extends ShapeComponent {
  setup() {
    const {newsletterTemplate, newsletterTemplateId} = useModel(NewsletterTemplate, {
      match: this.p.match,
      select: {
        NewsletterTemplate: ["id"]
      }
    })

    const query = useMemo(() => Newsletter.ransack({newsletter_template_id_eq: newsletterTemplateId}), [newsletterTemplateId])

    this.setInstance({newsletterTemplate, query})
    useAdminLayout()?.setState({active: "newsletter-templates", headerTitle: Newsletter.modelName().human({count: 2})})
  }

  render() {
    const {newsletterTemplate, query} = this.tt

    return (
      <View dataSet={{route: "admin-newsletter-templates/newsletters"}}>
        {newsletterTemplate &&
          <Nav active="newsletters" className="mb-4" newsletterTemplate={newsletterTemplate} />
        }
        <NewslettersTable adminLayout collection={query} />
      </View>
    )
  }
}))
