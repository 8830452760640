import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AppHistory from "shared/history"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import FormDataObjectizer from "form-data-objectizer"
import {incorporate} from "incorporator"
import Input from "components/inputs/input"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SubscriptionPeriod} from "models"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSubscriptionPeriodsEdit extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.subscription_periods.edit"})
    const params = useParams()
    const {subscriptionPeriod, subscriptionPeriodId} = useModel(SubscriptionPeriod, {
      match: {params},
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        SubscriptionPeriod: ["endsAt", "id", "startsAt", "subscriptionId"]
      }
    })

    this.setInstance({formRef: useRef(), subscriptionPeriod, subscriptionPeriodId, t})
    this.useStates({form: null})

    useAdminLayout()?.setState({active: "subscriptions", headerTitle: this.headerTitle()})
  }

  render() {
    const {subscriptionPeriod} = this.tt

    return (
      <View dataSet={{class: "admin--subscription-periods--edit"}}>
        {subscriptionPeriod &&
          <Form formRef={this.tt.formRef} onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
            <Input attribute="startsAt" model={subscriptionPeriod} type="date" />
            <Input attribute="endsAt" model={subscriptionPeriod} type="date" />
            <AdminButton primary save style={{marginTop: 15}} submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {subscriptionPeriod, t} = this.tt

    if (subscriptionPeriod?.isNewRecord()) {
      return t(".add_new_subscription_period")
    } else if (subscriptionPeriod?.isPersisted()) {
      return t(".edit_subscription_period")
    }
  }

  onSubmit = async () => {
    const formData = new FormData(this.tt.formRef.current)
    const data = incorporate({}, this.s.form.asObject(), FormDataObjectizer.toObject(formData))
    const {subscriptionPeriod, t} = this.tt

    data.subscription_period.subscription_id = subscriptionPeriod.subscriptionId()

    if (subscriptionPeriod.isNewRecord()) {
      data.subscription_period.id = subscriptionPeriod.id()
    }

    try {
      await subscriptionPeriod.saveRaw(data)
      FlashMessage.success(t(".the_subscription_period_was_saved"))
      AppHistory.push(Routes.adminSubscriptionPeriodPath(subscriptionPeriod.id()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
