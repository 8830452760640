import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import TextComponentReplace from "shared/text-component-replace"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsUserClassStatusRow extends ShapeComponent {
  static propTypes = {
    brainBreaksCount: PropTypes.number,
    challengesCount: PropTypes.number,
    model: PropTypes.object,
    modelClass: PropTypes.string,
    modelId: PropTypes.string,
    moodLabel: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    unicornEffectsCount: PropTypes.number
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.components.user.class_status.row"})

    this.setInstance({
      locale,
      t
    })
  }

  render() {
    const {locale, t} = this.tt
    const {
      brainBreaksCount,
      challengesCount,
      cardDesign = true,
      children,
      className,
      expanded,
      hasUnicorn,
      identifier,
      indentationLevel = 0,
      label,
      model,
      modelClass,
      modelId,
      mood,
      moodLabel,
      onCollapse,
      onExpand,
      unicornPath,
      unicornEffectsCount,
      woofs,
      ...restProps
    } = this.props
    const paddingLeft = indentationLevel * 25
    const style = {paddingLeft: `${paddingLeft}px`}

    return (
      <>
        <tr
          className={classNames("components--user--class-status--row", "row-card", className)}
          data-card-design={cardDesign}
          data-expanded={Boolean(expanded)}
          data-has-unicorn={hasUnicorn}
          {...restProps}
        >
          <td className="expand-button-column" style={style}>
            <div className="expand-button-container">
              {children &&
                <a className="expand-button" href="#" onClick={this.tt.onOpenClicked}>
                  <i className={`fa fa-chevron-${expanded ? "up" : "down"}`} />
                </a>
              }
              <div className="row-label">
                {label}
              </div>
            </div>
          </td>
          <td>
            {hasUnicorn &&
              <NumberWithText
                icon="paw"
                iconCircle
                number={woofs}
                text={t(".woofs")}
                width={"150px"}
              />
            }
          </td>
          <td data-class="brain-breaks-column">
            {hasUnicorn &&
              <NumberWithText
                icon="umbrella"
                iconCircle
                number={brainBreaksCount}
                text={t(".brain_breaks")}
                width="170px"
              />
            }
          </td>
          <td data-class="challenges-column">
            {hasUnicorn &&
              <NumberWithText
                icon="users"
                iconCircle
                number={challengesCount}
                text={t(".challenges")}
                width="170px"
              />
            }
          </td>
          <td>
            {hasUnicorn &&
              <NumberWithText
                icon="star"
                iconCircle
                number={unicornEffectsCount}
                text={t(".unicorn_effects")}
                width="170px"
              />
            }
          </td>
          <td>
            {hasUnicorn && mood !== null &&
              <MoodInWeek label={moodLabel} number={mood.toLocaleString(locale, {maximumFractionDigits: 1, minimumFractionDigits: 0})} />
            }
          </td>
          <td style={{whiteSpace: "nowrap"}}>
            {unicornPath &&
              <Link className="open-unicorn-button" to={unicornPath}>
                {t(".view_analysis")}
              </Link>
            }
          </td>
        </tr>
        {children && expanded && children}
      </>
    )
  }

  onOpenClicked = (e) => {
    e.preventDefault()

    const {expanded, identifier, onExpand, onCollapse, modelClass, modelId} = this.p

    if (expanded) {
      onCollapse({identifier, modelClass, modelId})
    } else {
      onExpand({identifier, modelClass, modelId})
    }
  }
}))

const NumberWithText = memo((props) => {
  const replaces = [
    {
      component: (
        <span className="number-in-number-of-woofs" key="number-of-woofs">
          {props.number}
        </span>
      ),
      text: "%{number}"
    }
  ]

  return (
    <div className="number-with-text" data-color={props.color || "blue"} style={{width: props.width}}>
      {props.iconCircle &&
        <IconWithCircle color={props.color || "blue"} icon={props.icon} />
      }
      {!props.iconCircle &&
        <i className={`fa fa-${props.icon}`} style={{fontSize: "28px", marginRight: "6px"}} />
      }
      <TextComponentReplace
        replaces={replaces}
        text={props.text}
      />
    </div>
  )
})

const IconWithCircle = memo((props) =>
  <div className="circle-icon" style={{marginRight: "6px"}}>
    <FontAwesomeIcon name={props.icon} style={{color: "#fff", fontSize: 19}} />
  </div>
)

const MoodInWeek = memo(({label, number}) =>
  <NumberWithText
    icon="face-smile"
    number={number}
    text={label}
    width="180px"
  />
)
