import EventEmitterListener from "@kaspernj/api-maker/build/event-emitter-listener"
import fetchingObject from "fetching-object"
import globalEvents from "shared/events"
import I18n from "shared/i18n"
import React from "react"
import Shape from "set-state-compare/src/shape"
import shouldComponentUpdate from "set-state-compare/src/should-component-update"

export default class BaseComponent extends React.Component {
  originalComponentDidMount = this.componentDidMount
  originalRender = this.render
  p = fetchingObject(() => this.props)
  s = fetchingObject(() => this.shape || this.state)
  t = fetchingObject(this)

  componentDidMount = () => {
    if (this.shape) {
      this.shape.currentLocale = I18n.locale
    } else {
      this.shape = new Shape(this, {
        currentLocale: I18n.locale
      })
    }

    if (this.originalComponentDidMount) this.originalComponentDidMount()
  }

  render = () =>
    <>
      <EventEmitterListener event="localeChanged" events={globalEvents} onCalled={this.t.onLocaleChanged} />
      {this.originalRender()}
    </>

  onLocaleChanged = () => {
    this.shape.set({
      currentLocale: I18n.locale
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState)
  }
}
