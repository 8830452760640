import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import {Styling} from "models"
import withModel from "components/wooftech-with-model"
class RoutesAdminStylingsShow extends PageComponent {
  static propTypes = {
    styling: PropTypes.instanceOf(Styling),
    stylingId: PropTypes.string.isRequired
  }

  render() {
    const {styling} = this.p

    return (
      <AdminLayout
        actions={<AdminShowActions model={styling} />}
        active="stylings"
        className="routes--admin--stylings--show"
        headerTitle={styling?.name()}
      >
        {styling &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={styling} />
            <AttributeRow attribute="styling" model={styling}>
              <pre>{styling.styling() && JSON.stringify(styling.styling(), null, 2)}</pre>
            </AttributeRow>
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${Styling.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={styling}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }
}

const selectedStylingAttributes = ["createdAt", "id", "name", "styling", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedStylingAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default withModel(
  RoutesAdminStylingsShow,
  Styling,
  {
    abilities: {
      Styling: ["destroy", "edit"]
    },
    select: {
      Styling: selectedStylingAttributes
    }
  }
)
