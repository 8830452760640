import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Checkbox from "components/inputs/checkbox"
import classNames from "classnames"
import {ContentPieceTypesSelectWithContainer} from "components/content-piece-types/select"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {SurveyStepElement} from "models"

export default memo(shapeComponent(class ComponentsAdminSurveyStepElementsPartialTypesContentPiece extends ShapeComponent {
  static propTypes = {
    surveyStepElement: PropTypes.instanceOf(SurveyStepElement).isRequired
  }

  render() {
    const {className, surveyStepElement, ...restProps} = this.props

    return (
      <div className={classNames("components--admin--survey-step-elements--partial-types--content-piece", className)} {...restProps}>
        <Checkbox attribute="editable" model={surveyStepElement} />
        <ContentPieceTypesSelectWithContainer
          attribute="contentPieceTypeId"
          label={SurveyStepElement.humanAttributeName("contentPieceType")}
          model={surveyStepElement}
        />
      </div>
    )
  }
}))
