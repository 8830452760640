import React, {useMemo} from "react"
import classNames from "classnames"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import {SchoolClassInvitation} from "models"
import TranslatedCollections from "@kaspernj/api-maker/build/translated-collections"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {withInputContainer} from "components/inputs/input-container"
const ComponentsSchoolRolesRolesSelect = memo((props) => {
  const {locale} = useI18n({namespace: "js.components.school_roles.roles_select"})
  const roleOptions = useMemo(
    () => TranslatedCollections.get(SchoolClassInvitation, "role").map(({translation, value}) => ({text: translation, value})),
    [locale]
  )
  const {className, ...restProps} = props

  return (
    <HayaSelect className={classNames("components--school-roles--roles-select", className)} options={roleOptions} {...restProps} />
  )
})

const RolesSelectWithContainer = withInputContainer(ComponentsSchoolRolesRolesSelect)

export {RolesSelectWithContainer}
export default ComponentsSchoolRolesRolesSelect
