import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import {ScoreFactor} from "models"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import withModel from "components/wooftech-with-model"
class RoutesAdminScoreFactorsShow extends PageComponent {
  static propTypes = {
    scoreFactor: PropTypes.instanceOf(ScoreFactor),
    scoreFactorId: PropTypes.string.isRequired
  }

  render() {
    const {scoreFactor} = this.p

    return (
      <AdminLayout
        actions={<AdminShowActions model={scoreFactor} />}
        active="score-factors"
        className="routes--admin--score-factors--show"
        headerTitle={scoreFactor?.name()}
      >
        {scoreFactor &&
          <>
            <AttributeRows attributes={["id", "createdAt", "updatedAt", "icon", "color", "secondaryColor"]} model={scoreFactor} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${ScoreFactor.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={scoreFactor}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }
}

const selectedScoreFactorAttributes = ["color", "createdAt", "icon", "id", "name", "secondaryColor", "updatedAt"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default withModel(
  RoutesAdminScoreFactorsShow,
  ScoreFactor,
  {
    abilities: {
      ScoreFactor: ["destroy", "edit"]
    },
    select: {
      ScoreFactor: selectedScoreFactorAttributes
    }
  }
)
