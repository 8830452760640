import {Account, Contact, ContactEmail, ContactPhoneNumber, Country, CountryState, NotificationSetting, School, User} from "models"
import {dig, digg, digs} from "diggerize"
import React, {useMemo, useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import Checkbox from "components/inputs/checkbox"
import {CountriesSelectWithContainer} from "components/countries/select"
import {countryOptions} from "components/utils/countries-select"
import {CountryStatesSelectWithContainer} from "components/country-states/select"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import FormDataObjectizer from "form-data-objectizer"
import {incorporate} from "incorporator"
import Link from "@kaspernj/api-maker/build/link"
import MainCard from "components/main-card"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {SchoolsTypeaheadWithContainer} from "components/schools/typeahead"
import useCurrentCountry from "components/utils/use-current-country"
import {useFrontLayout} from "components/front-layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UsersAcceptTermsHint from "components/users/accept-terms-hint"
import usersUpdateSession from "components/users/update-session"
import UtilsInput from "components/utils/input"
import UtilsPhoneNumber from "components/utils/phone-number"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesFrontAccountsNew extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.front.accounts.new"})
    const currentCountry = useCurrentCountry()

    this.notificationSetting = useMemo(() => new NotificationSetting(), [])
    this.user = useMemo(() => new User(), [])
    this.useStates({
      countryCode: currentCountry?.countryCode(),
      countryId: currentCountry?.id(),
      countryStateId: undefined,
      email: "",
      firstName: "",
      form: null,
      lastName: "",
      password: "",
      passwordConfirmation: "",
      phoneCountry: () => {
        if (currentCountry) {
          const phoneCountry = countryOptions.find((country) => digg(country, "code") == currentCountry.countryCode())

          if (!phoneCountry) {
            throw new Error(
              `No phone country could be found for ${currentCountry.countryCode()} in ${countryOptions.map((country) => country.code).join(", ")}`
            )
          }

          return phoneCountry
        }
      },
      phoneNumber: "",
      schoolName: "",
      selectedSchoolOption: undefined
    })
    this.schoolsQuery = useMemo(() => School.ransack({country_id_eq: this.s.countryId}), [this.s.countryId])
    this.setInstance({
      formRef: useRef(),
      t
    })

    useFrontLayout()?.setState({headTitle: t(".new_account")})
  }

  render() {
    const {notificationSetting, schoolsQuery, t} = this.tt
    const {countryCode, countryId, countryStateId, phoneCountry} = this.s

    return (
      <View dataSet={{route: "front/accounts/new"}}>
        <Form formRef={this.tt.formRef} onSubmit={this.tt.onSubmit} setForm={this.setStates.form}>
          {this.s.selectedSchoolOption &&
            <input name="school[id]" type="hidden" value={digg(this.s.selectedSchoolOption, "value")} />
          }
          <input name="school[name]" type="hidden" value={this.s.schoolName} />
          <input name="user[contact_attributes][country_id]" type="hidden" value={this.s.countryId} />
          <MainCard
            footer={this.signInWithUniCContent()}
            header={t(".new_account")}
            subHeader={t(".fill_out_the_fields_below")}
          >
            <CountriesSelectWithContainer
              defaultValue={countryId}
              id="country_id"
              label={Country.modelName().human()}
              name="school[country_id]"
              onChange={this.tt.onCountryChanged}
            />
            {countryCode == "US" &&
              <CountryStatesSelectWithContainer
                defaultValue={countryStateId}
                id="country_state_id"
                label={CountryState.modelName().human()}
                limit={null}
                name="school[country_state_id]"
                onChange={this.tt.onCountryStateChanged}
              />
            }
            <SchoolsTypeaheadWithContainer
              icon="house"
              inputProps={{id: "school_name"}}
              label={School.modelName().human()}
              onChangeText={this.tt.onChangeSchoolName}
              onOptionChosen={this.tt.onSchoolChosen}
              query={schoolsQuery}
              value={this.s.schoolName}
            />
            <UtilsInput
              containerProps={{dataSet: {class: "email-input-container"}}}
              dataSet={{class: "email-input"}}
              icon="at"
              label={ContactEmail.humanAttributeName("email")}
              name="user[contact_attributes][contact_emails_attributes][0][email]"
              onChangeText={this.setStates.email}
              required
              styles={{container: {marginTop: 10, marginBottom: 10}}}
              value={this.s.email}
            />
            <UtilsInput
              dataSet={{class: "first-name-input"}}
              icon="user"
              label={Contact.humanAttributeName("firstName")}
              name="user[contact_attributes][first_name]"
              onChangeText={this.setStates.firstName}
              styles={{container: {marginTop: 10, marginBottom: 10}}}
              value={this.s.firstName}
            />
            <UtilsInput
              dataSet={{class: "last-name-input"}}
              icon="user"
              label={Contact.humanAttributeName("lastName")}
              name="user[contact_attributes][last_name]"
              onChangeText={this.setStates.lastName}
              styles={{container: {marginTop: 10, marginBottom: 10}}}
              value={this.s.lastName}
            />
            <UtilsPhoneNumber
              country={phoneCountry}
              countrySelectProps={{
                id: "phone-number-country-select",
                values: phoneCountry ? [digg(phoneCountry, "code")] : null
              }}
              dataSet={{class: "phone-number-input"}}
              label={ContactPhoneNumber.humanAttributeName("phoneNumber")}
              name="user[contact_attributes][contact_phone_numbers_attributes][0][phone_number]"
              number={this.s.phoneNumber}
              onChangeCountry={this.tt.onChangePhoneCountry}
              onChangeNumber={this.tt.onChangePhoneNumber}
              styles={{container: {marginTop: 10, marginBottom: 10}}}
            />
            <UtilsInput
              dataSet={{class: "password-input"}}
              icon="lock"
              label={t("js.shared.password")}
              name="user[password]"
              onChangeText={this.setStates.password}
              required
              secureTextEntry={true}
              styles={{container: {marginTop: 10, marginBottom: 10}}}
              value={this.s.password}
            />
            <UtilsInput
              dataSet={{class: "password-confirmation-input"}}
              icon="lock"
              label={t("js.shared.password_confirmation")}
              name="user[password_confirmation]"
              onChangeText={this.setStates.passwordConfirmation}
              required
              secureTextEntry={true}
              styles={{container: {marginTop: 10, marginBottom: 10}}}
              value={this.s.passwordConfirmation}
            />
            <Checkbox
              attribute="acceptTerms"
              hint={<UsersAcceptTermsHint />}
              model={notificationSetting}
              name="user[notification_setting_attributes][accept_terms]"
            />
            <Checkbox
              attribute="acceptMarketing"
              hint={t(".you_can_always_unsubscribe")}
              model={notificationSetting}
              name="user[notification_setting_attributes][accept_marketing]"
            />
            <Button label={t(".create_account")} primary style={{width: "100%"}} />
            <View style={{marginTop: 20, textAlign: "center"}}>
              <Link to={Routes.rootPath()}>
                {t(".if_you_already_have_an_account_then_click_here")}
              </Link>
            </View>
          </MainCard>
        </Form>
      </View>
    )
  }

  onChangePhoneCountry = (phoneCountry) => {
    if (phoneCountry) {
      this.setState({phoneCountry})
    }
  }

  onChangePhoneNumber = (phoneNumber) => this.setState({phoneNumber})

  signInWithUniCContent = () => {
    // Disabled until we implement UNI login
    return null

    /*
    return (
      <div className="uni-login-content">
        <div style={{textAlign: "center"}}>
          {t(".or_sign_in_with_uni_login")}
        </div>
        <Button className="mt-2" darkPrimary label={t(".uni_login")} style={{width: "100%"}} to="#" />
      </div>
    )
    */
  }

  onChangeSchoolName = (value) => this.setState({schoolName: value || ""})
  onCountryStateChanged = ({options}) => this.setState({countryStateId: dig(options, 0, "value")})

  onCountryChanged = (args) => {
    this.setState({
      countryCode: dig(args, "options", 0, "country").countryCode(),
      countryId: dig(args, "options", 0, "value")
    })
  }

  onSchoolChosen = ({option}) => this.setState({schoolName: option.text, selectedSchoolOption: option})

  onSubmit = async () => {
    const formData = new FormData(this.tt.formRef.current)
    const data = incorporate({}, this.s.form.asObject(), FormDataObjectizer.toObject(formData))
    const {phoneCountry, phoneNumber} = this.s

    if (phoneNumber == "") {
      delete data.user.contact_attributes.contact_phone_numbers_attributes
    } else {
      const dialCode = digg(phoneCountry, "dialCode")

      data.user.contact_attributes.contact_phone_numbers_attributes = [{
        phone_number: `${dialCode}${phoneNumber}`,
        primary: true
      }]
    }

    data.user.contact_attributes.contact_emails_attributes["0"].primary = true

    try {
      const response = await Account.createNew(data)
      const {account, school, user} = digs(response, "account", "school", "user")

      await usersUpdateSession(user.id())
      FlashMessage.success(this.t(".your_account_was_created"))
      AppHistory.push(Routes.frontAccountCreatedPath(account.id(), {school_id: school.id()}))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
