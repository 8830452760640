import {Course} from "models"
import selectComponent from "components/select-component"
const {withAdminSelectContainer: AdminSelectCourses, withInputContainer: CoursesSelectWithContainer, withMemo: ComponentsCoursesSelect} = selectComponent({
  className: "components--courses--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || Course

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({Course: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const courses = await query.toArray()

    return courses.map((course) => ({
      text: course.name(),
      value: course.id()
    }))
  }
})

export default ComponentsCoursesSelect
export {AdminSelectCourses, CoursesSelectWithContainer}
