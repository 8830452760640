import AdminLayout from "components/admin/layout"
import AppHistory from "shared/history"
import Button from "components/inputs/button"
import {ClassStepsSelectWithContainer} from "components/class-steps/select"
import {digg} from "diggerize"
import FlashMessage from "shared/flash-message"
import I18n from "shared/i18n"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SchoolClassGroupClassStep} from "models"
import {SchoolClassGroupsSelectWithContainer} from "components/school-class-groups/select"
import withModel from "components/wooftech-with-model"

class RoutesAdminSchoolClassGroupClassStepsEdit extends PageComponent {
  static propTypes = {
    schoolClassGroupClassStep: PropTypes.instanceOf(SchoolClassGroupClassStep),
    schoolClassGroupClassStepId: PropTypes.string
  }

  render() {
    const {onSubmit} = this.t
    const {schoolClassGroupClassStep} = this.p

    return (
      <AdminLayout active="school-class-groups" className="routes--admin--school-class-group-class-steps--edit" headerTitle={this.headerTitle()}>
        {schoolClassGroupClassStep &&
          <form onSubmit={onSubmit}>
            <SchoolClassGroupsSelectWithContainer
              attribute="schoolClassGroupId"
              label={SchoolClassGroupClassStep.humanAttributeName("schoolClassGroup")}
              model={schoolClassGroupClassStep}
            />
            <ClassStepsSelectWithContainer
              attribute="classStepId"
              label={SchoolClassGroupClassStep.humanAttributeName("classStep")}
              model={schoolClassGroupClassStep}
            />
            <Button primary save />
          </form>
        }
      </AdminLayout>
    )
  }

  headerTitle() {
    const {schoolClassGroupClassStep} = this.p

    if (schoolClassGroupClassStep?.isNewRecord()) {
      return I18n.t("js.routes.admin.school_class_group_class_steps.edit.add_class_step_to_group")
    } else if (schoolClassGroupClassStep?.isPersisted()) {
      return I18n.t("js.routes.admin.school_class_group_class_steps.edit.edit_class_step_in_group")
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const {schoolClassGroupClassStep} = this.p

    try {
      await schoolClassGroupClassStep.saveRaw(form)
      FlashMessage.success(I18n.t("js.routes.admin.school_class_group_class_steps.edit.the_class_step_group_was_saved"))
      AppHistory.push(Routes.adminSchoolClassGroupClassStepsPath(schoolClassGroupClassStep.schoolClassGroupId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}

const selectedSchoolClassGroupClassStepAttributes = ["id", "classStepId", "schoolClassGroupId"]

export default withModel(
  RoutesAdminSchoolClassGroupClassStepsEdit,
  SchoolClassGroupClassStep,
  {
    newIfNoId: true,
    select: {
      SchoolClassGroupClassStep: selectedSchoolClassGroupClassStepAttributes
    }
  }
)
