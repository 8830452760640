import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNewsletterTemplatesNav from "./nav"
import AuditsPage from "components/admin/audits/page"
import memo from "set-state-compare/src/memo"
import {NewsletterTemplate} from "models"
import React from "react"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNewsletterTemplateAudits extends ShapeComponent {
  setup() {
    const {newsletterTemplate} = useModel(NewsletterTemplate, {match: this.p.match})

    this.newsletterTemplate = newsletterTemplate

    useAdminLayout()?.setState({active: "newsletter-templates", headerTitle: NewsletterTemplate.humanAttributeName("audits")})
  }

  render() {
    const {newsletterTemplate} = this.tt

    return (
      <View dataSet={{route: "admin/newsletter-templates/audits"}}>
        {newsletterTemplate &&
          <>
            <AdminNewsletterTemplatesNav active="audits" className="mb-4" newsletterTemplate={newsletterTemplate} />
            <AuditsPage resource={newsletterTemplate} />
          </>
        }
      </View>
    )
  }
}))
