import {Notification, NotificationType} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminNotificationTypeNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    notificationType: PropTypes.instanceOf(NotificationType).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.notification_types.nav"})

    this.t = t
    this.useStates({
      notificationsCount: null
    })

    useMemo(() => {
      this.countNotifications()
    }, [this.p.notificationType.id()])
  }

  async countNotifications() {
    const notificationsCount = await Notification
      .ransack({notification_type_id_eq: this.p.notificationType.id()})
      .count()

    this.setState({notificationsCount})
  }

  render() {
    const {t} = this.tt
    const {active, className, notificationType, ...restProps} = this.props

    return (
      <Nav className={classNames("routes--admin--notification-types--nav", className)} {...restProps}>
        <NavItem active={active} identifier="general" label={this.t("js.shared.general")} to={Routes.adminNotificationTypePath(notificationType.id())} />
        <NavItem
          active={active}
          identifier="actions"
          label={`${NotificationType.humanAttributeName("actions")} (${notificationType.notificationTypeActionsCount()})`}
          to={Routes.actionsAdminNotificationTypePath(notificationType.id())}
        />
        <NavItem
          active={active}
          identifier="audit-triggers"
          label={`${NotificationType.humanAttributeName("auditTriggers")} (${notificationType.notificationTypeAuditTriggersCount()})`}
          to={Routes.auditTriggersAdminNotificationTypePath(notificationType.id())}
        />
        <NavItem
          active={active}
          identifier="audit-test"
          label={t(".audit_test")}
          to={Routes.auditTestAdminNotificationTypePath(notificationType.id())}
        />
        <NavItem
          active={active}
          identifier="notifications"
          label={`${NotificationType.humanAttributeName("notifications")} (${this.s.notificationsCount})`}
          to={Routes.notificationsAdminNotificationTypePath(notificationType.id())}
        />
      </Nav>
    )
  }
}))
