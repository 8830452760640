import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import ClassStepLink from "components/class-steps/link"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import {SchoolClass} from "models"
import SchoolLink from "components/schools/link"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminSchoolClassesShow extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.school_classes.show"})
    const {schoolClass} = useModel(SchoolClass, {
      abilities: {
        SchoolClass: ["destroy", "edit"]
      },
      match: this.p.match,
      preload: ["class_step", "school"],
      select: {
        ClassStep: ["id", "name"],
        School: ["id", "name"],
        SchoolClass: ["createdAt", "hasUnicornSubscription", "id", "interpretedName", "schoolClassCheckInsCount", "updatedAt"]
      }
    })
    const {locale} = useI18n({namespace: "js.routes.admin.school_classes.show"})
    const actions = useMemo(
      () => <AdminShowActions model={schoolClass} />,
      [locale, schoolClass?.cacheKey()]
    )

    this.setInstance({schoolClass, t})
    useAdminLayout()?.setState({actions, active: "school-classes", headerTitle: schoolClass?.interpretedName()})
  }

  render() {
    const {schoolClass, t} = this.tt

    return (
      <View dataSet={{route: "admin/school-classes/show"}}>
        {schoolClass &&
          <>
            <Nav active="general" schoolClass={schoolClass} style={{marginBottom: 15}} />
            <AttributeRow attribute="name" model={schoolClass}>
              {schoolClass.interpretedName()}
            </AttributeRow>
            <AttributeRows attributes={["id", "schoolClassCheckInsCount", "createdAt", "updatedAt"]} model={schoolClass} />
            <AttributeRow label={t(".has_unicorn_subscription")}>
              {schoolClass.hasUnicornSubscription() ? t("js.shared.yes") : t("js.shared.no")}
            </AttributeRow>
            <AttributeRow label={SchoolClass.humanAttributeName("school")}>
              {schoolClass.school() &&
                <SchoolLink adminLayout school={schoolClass.school()} />
              }
            </AttributeRow>
            <AttributeRow label={SchoolClass.humanAttributeName("classStep")}>
              {schoolClass.classStep() &&
                <ClassStepLink adminLayout classStep={schoolClass.classStep()} />
              }
            </AttributeRow>
          </>
        }
      </View>
    )
  }
}))
