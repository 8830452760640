import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminNavSegmented from "components/admin/nav-segmented"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import {School} from "models"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsAdminSchoolsNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    school: PropTypes.instanceOf(School).isRequired
  }

  setup() {
    const {locale, t} = useI18n({namespace: "js.components.admin.schools.nav"})
    const {school} = this.p

    this.t = t
    this.buttons = useMemo(
      () => [
        {
          value: "general",
          label: t("js.shared.general"),
          to: Routes.adminSchoolPath(school.id())
        },
        {
          value: "account-schools",
          label: School.humanAttributeName("accounts"),
          to: Routes.adminSchoolAccountSchoolsPath(school.id())
        },
        {
          value: "audits",
          label: School.humanAttributeName("audits"),
          to: Routes.auditsAdminSchoolPath(school.id())
        },
        {
          value: "school-roles",
          label: t("js.shared.school_roles"),
          to: Routes.schoolRolesAdminSchoolPath(school.id())
        },
        {
          value: "subscriptions",
          label: School.humanAttributeName("subscriptions"),
          to: Routes.subscriptionsAdminSchoolPath(school.id())
        }
      ],
      [locale]
    )
  }

  render() {
    const {buttons} = this.tt
    const {active, className, school, ...restProps} = this.props

    return (
      <AdminNavSegmented
        active={active}
        buttons={buttons}
        dataSet={{class: classNames("components--admin--schools--nav", className)}}
        {...restProps}
      />
    )
  }
}))
