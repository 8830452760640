import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {SurveyStepElement} from "models"
import SurveyStepElementLink from "components/survey-step-elements/link"
export default memo(shapeComponent(class ComponentsSurveyStepElementsTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--survey-step-elements--table", className)}
        columns={columns}
        defaultParams={{s: "position"}}
        editModelPath={editModelPath}
        modelClass={SurveyStepElement}
        select={{SurveyStepElement: ["id", "translatedElementType"]}}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      content: this.tt.idContent,
      sortKey: "id"
    },
    {
      attribute: "elementType",
      content: this.tt.elementTypeContent,
      sortKey: "elementType"
    },
    {
      attribute: "position",
      sortKey: "position"
    }
  ]

  elementTypeContent = ({surveyStepElement}) => surveyStepElement.translatedElementType()
  idContent = ({surveyStepElement}) =>
    <SurveyStepElementLink surveyStepElement={surveyStepElement} {...linkProps(this.props)}>
      {surveyStepElement.id()}
    </SurveyStepElementLink>

  editModelPath = ({surveyStepElement}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminSurveyStepElementPath(surveyStepElement.id())
  }

  viewModelPath = ({surveyStepElement}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminSurveyStepElementPath(surveyStepElement.id())
  }
}))
