import {useEffect, useMemo} from "react"
import {digg} from "diggerize"
import ModelEvents from "@kaspernj/api-maker/build/model-events"
import {Subscription} from "models"
import unsupportedProps from "components/utils/unsupported-props"
import useShape from "set-state-compare/src/use-shape"

const loadHasUnicorn = async (s) => {
  const result = await Subscription.hasUnicorn({school_id: s.p.schoolId, school_class_id: s.p.schoolClassId, user_id: s.m.userId})
  const hasUnicorn = digg(result, "has_unicorn")
  const expiredTrialSubscription = digg(result, "unicorn_expired_trial_subscription")
  const userSubscription = digg(result, "user_subscription")

  s.set({expiredTrialSubscription, loaded: true, hasUnicorn, userSubscription})
}

const useHasUnicorn = ({schoolId, schoolClassId, user, ...restProps}) => {
  unsupportedProps(restProps)

  const s = useShape({schoolId, schoolClassId, user})

  s.useStates({
    expiredTrialSubscription: undefined,
    hasUnicorn: undefined,
    lastChanged: () => new Date(),
    loaded: false,
    userSubscription: undefined
  })

  s.updateMeta({userId: user?.id()})

  useMemo(() => {
    if (s.m.userId) {
      loadHasUnicorn(s)
    } else if (s.s.hasUnicorn !== undefined) {
      s.set({hasUnicorn: false})
    }
  }, [schoolId, schoolClassId, s.m.userId, s.s.lastChanged])

  useEffect(() => {
    if (s.p.user) {
      const webSocketSubscription = ModelEvents.connect(s.p.user, "start_unicorn_user_trial", () => s.set({lastChanged: new Date()}))

      return () => {
        webSocketSubscription.unsubscribe()
      }
    }
  }, [s.p.user?.id()])

  return {
    expiredTrialSubscription: s.s.expiredTrialSubscription,
    hasUnicorn: s.s.hasUnicorn,
    loaded: s.s.loaded,
    userSubscription: s.s.userSubscription
  }
}

export default useHasUnicorn
