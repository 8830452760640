import React, {useMemo} from "react"
import {SchoolClassCourseModule, User} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import {digg} from "diggerize"
import EventEmitter from "events"
import findOrCreateTeamSurveyStep from "components/survey-steps/find-or-create-team-survey-step"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import SurveysBottomBar from "components/surveys/bottom-bar"
import SurveysPageContainer from "components/surveys/page-container"
import SurveyStepsPresentation from "components/survey-steps/presentation"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import {useFrontLayout} from "components/front-layout"
import useHiddenTawkWidget from "shared/use-hidden-tawk-widget"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesUserSchoolClassCourseModules extends ShapeComponent {
  setup() {
    this.useStates({
      teamSurveyStep: undefined
    })

    const currentUser = useCurrentUser()
    const params = useParams()
    const {schoolClassCourseModule, schoolClassCourseModuleId} = useModel(
      SchoolClassCourseModule,
      {
        match: {params},
        preload: ["school_class_course.course", "school_class_course.school_class", "course_module", "team_survey.survey"],
        select: {
          Course: ["backgroundImageUrl", "courseModulesCount"],
          CourseModule: ["position"],
          SchoolClass: ["id", "schoolId"],
          SchoolClassCourse: ["courseId", "id", "state"],
          SchoolClassCourseModule: ["id", "state"],
          Survey: ["surveyStepsCount"],
          TeamSurvey: ["id", "preview", "state"]
        }
      }
    )
    const schoolClassCourse = schoolClassCourseModule?.schoolClassCourse()
    const course = schoolClassCourse?.course()
    const courseModule = schoolClassCourseModule?.courseModule()
    const surveyStep = this.s.teamSurveyStep?.surveyStep()
    const survey = schoolClassCourseModule?.teamSurvey()?.survey()
    const teamSurvey = schoolClassCourseModule?.teamSurvey()
    const teamSurveyStepId = digg(this.p.match, "params", "team_survey_step_id")

    this.setInstance({
      course,
      courseModule,
      currentUser,
      schoolClassCourse,
      schoolClassCourseModule,
      schoolClassCourseModuleId,
      survey,
      surveyStep,
      surveyStepsPresentationEvents: useMemo(() => new EventEmitter(), []),
      teamSurvey,
      teamSurveyStepId
    })

    useMemo(() => {
      if (currentUser && schoolClassCourseModule) {
        this.loadTeamSurveyStep()
      } else {
        this.setState({teamSurveyStep: undefined})
      }
    }, [currentUser?.id(), schoolClassCourseModule, teamSurveyStepId])

    useHiddenTawkWidget()

    const bottomBar = useMemo(
      () => <>
        {this.tt.schoolClassCourseModule && this.tt.surveyStep && this.s.teamSurveyStep &&
          <SurveysBottomBar
            color="white"
            onCancelClicked={this.tt.onCancelClicked}
            onEndClicked={this.tt.onEndClicked}
            onNextStepClicked={this.tt.onNextStepClicked}
            onPreviousStepClicked={this.tt.onPreviousStepClicked}
            survey={this.tt.schoolClassCourseModule.teamSurvey().survey()}
            surveyStep={this.tt.surveyStep}
            teamSurvey={this.tt.schoolClassCourseModule.teamSurvey()}
            teamSurveyStep={this.s.teamSurveyStep}
          />
        }
      </>,
      [[schoolClassCourseModule?.id(), surveyStep?.id(), this.s.teamSurveyStep?.id()]]
    )

    useFrontLayout()?.setState({
      background: course?.backgroundImageUrl(),
      bottomBar,
      contentCentered: true,
      darkBackground: true,
      headTitle: surveyStep?.name(),
      schoolClass: schoolClassCourse?.schoolClass(),
      unicornTrialTopBar: false
    })
  }

  loadTeamSurveyStep = async () => {
    const teamSurveyStep = await findOrCreateTeamSurveyStep({
      teamSurveyId: this.tt.schoolClassCourseModule.teamSurvey().id(),
      teamSurveyStepId: this.tt.teamSurveyStepId,
      withQuery: ({query}) => query.ransack({team_survey_school_class_course_module_id_eq: this.tt.schoolClassCourseModuleId})
    })

    this.setState({teamSurveyStep})
  }

  render() {
    const {schoolClassCourseModule, surveyStep, surveyStepsPresentationEvents, teamSurvey} = this.tt

    return (
      <View
        dataSet={{
          route: "user/school-class-course-modules/show",
          surveyStepId: surveyStep?.id(),
          teamSurveyStepId: this.s.teamSurveyStep?.id()
        }}
      >
        {schoolClassCourseModule && surveyStep && this.s.teamSurveyStep &&
          <div className="content-container">
            <SurveysPageContainer>
              <SurveyStepsPresentation
                events={surveyStepsPresentationEvents}
                key={`survey-steps-presentation-${schoolClassCourseModule.id()}-${surveyStep.id()}`}
                onAnswersSubmitted={this.tt.onAnswersSubmitted}
                schoolClass={schoolClassCourseModule.schoolClassCourse().schoolClass()}
                submitButton={false}
                surveyStep={surveyStep}
                teamSurvey={teamSurvey}
                teamSurveyStep={this.s.teamSurveyStep}
              />
            </SurveysPageContainer>
          </div>
        }
      </View>
    )
  }

  endModule = async () => {
    const promises = []

    if (this.isLastStep() && this.tt.schoolClassCourseModule.state() != "completed" && !this.tt.teamSurvey.preview()) {
      promises.push(
        User.stateEvent({model: this.tt.schoolClassCourseModule, state_event: "complete"})
      )
    }

    if (this.isLastStep() && this.tt.teamSurvey.state() != "completed") {
      promises.push(
        User.stateEvent({model: this.tt.teamSurvey, state_event: "complete"})
      )
    }

    if (this.isLastModule() && this.tt.schoolClassCourse.state() != "completed" && !this.tt.teamSurvey.preview()) {
      promises.push(
        User.stateEvent({model: this.tt.schoolClassCourse, state_event: "complete"})
      )
    }

    await Promise.all(promises)

    AppHistory.push(Routes.userCoursePath(this.tt.schoolClassCourse.courseId(), {school_class_course_id: this.tt.schoolClassCourse.id()}))
  }

  isLastModule = () => {
    if (this.tt.courseModule.position() == this.tt.course.courseModulesCount()) return true
  }

  isLastStep = () => {
    if (this.s.teamSurveyStep.surveyStep().position() >= this.tt.survey.surveyStepsCount()) return true
  }

  onAnswersSubmitted = ({after, nextTeamSurveyStep}) => {
    if (after == "end") {
      this.endModule()
    } else if (after == "nextStep") {
      this.redirectToNextStep({nextTeamSurveyStep})
    } else if (after == "previousStep") {
      this.redirectToPreviousStep()
    } else {
      throw new Error(`Unhanded submit mode: ${after}`)
    }
  }

  onCancelClicked = () => {
    AppHistory.push(
      Routes.userCoursePath(
        this.tt.schoolClassCourseModule.schoolClassCourse().courseId(),
        {school_class_course_id: this.tt.schoolClassCourseModule.schoolClassCourse().id()}
      )
    )
  }

  onEndClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "end"})
  }

  onNextStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "nextStep"})
  }

  onPreviousStepClicked = () => {
    this.tt.surveyStepsPresentationEvents.emit("submit", {after: "previousStep"})
  }

  redirectToNextStep = ({nextTeamSurveyStep}) => {
    AppHistory.push(Routes.userSchoolClassCourseModuleStepPath(this.tt.schoolClassCourseModule.id(), nextTeamSurveyStep.id()))
  }

  redirectToPreviousStep = async () => {
    const previousStepResult = await this.s.teamSurveyStep.previousStep()
    const previousTeamSurveyStep = digg(previousStepResult, "previous_team_survey_step")

    AppHistory.push(Routes.userSchoolClassCourseModuleStepPath(this.tt.schoolClassCourseModule.id(), previousTeamSurveyStep.id()))
  }
}))
