import {anythingDifferent} from "set-state-compare/src/diff-utils"
import currentDate from "shared/current-date"
import currentSchools from "components/schools/current-schools"
import moment from "shared/moment"

const filterParamsList = [
  "class_steps",
  "date_from",
  "date_to",
  "schools",
  "school_classes",
  "school_class_groups"
]

const defaultSchoolIds = async (queryParams) => {
  const defaultIds = []
  const classStepIds = queryParams.class_steps
  const schoolClassIds = queryParams.school_classes
  const schoolClassGroupIds = queryParams.school_class_groups
  let dateFrom, dateTo, schoolIds

  if (queryParams.date_from) {
    dateFrom = new Date(queryParams.date_from)
  } else {
    dateFrom = moment().subtract(1, "month").toDate()
  }

  if (queryParams.date_to) {
    dateTo = new Date(queryParams.date_to)
  } else {
    dateTo = currentDate()
  }

  if (queryParams.class_steps) defaultIds.push(...queryParams.class_steps)
  if (queryParams.schools) {
    schoolIds = queryParams.schools
    defaultIds.push(...queryParams.schools)
  }
  if (queryParams.school_classes) defaultIds.push(...queryParams.school_classes)
  if (queryParams.school_class_groups) defaultIds.push(...queryParams.school_class_groups)

  // Default current users schools chosen
  if (!hasFilterInQueryParams(queryParams)) {
    const schools = await currentSchools()

    schoolIds = schools.filter((school) => school.hasUnicornSubscription()).map((school) => school.id())
    defaultIds.push(...schoolIds)
  }

  return {
    classStepIds,
    dateFrom,
    dateTo,
    defaultIds,
    schoolClassGroupIds,
    schoolClassIds,
    schoolIds
  }
}

const filterParams = (queryParams) => {
  let dateFrom, dateTo

  if (queryParams.date_from) dateFrom = new Date(queryParams.date_from)
  if (queryParams.date_to) dateTo = new Date(queryParams.date_to)

  const classStepIds = queryParams.class_steps
  const schoolClassIds = queryParams.school_classes
  const schoolClassGroupIds = queryParams.school_class_groups
  const schoolIds = queryParams.schools

  return {classStepIds, dateFrom, dateTo, schoolClassIds, schoolClassGroupIds, schoolIds}
}

const filterParamsCacheKey = (queryParams) => {
  const filterParamsCacheKey = []

  for (const filterParamName of filterParamsList) {
    filterParamsCacheKey.push(queryParams[filterParamName])
  }

  return filterParamsCacheKey
}

const hasFilterInQueryParams = (queryParams) => {
  if (
    queryParams.date_from ||
    queryParams.date_to ||
    queryParams.class_steps ||
    queryParams.schools ||
    queryParams.school_classes ||
    queryParams.school_class_groups
  ) {
    return true
  }

  return false
}

const filterChanged = (prevProps, props) => {
  for (const filterParam of filterParamsList) {
    const prevProp = prevProps.queryParams[filterParam]
    const prop = props.queryParams[filterParam]

    if (anythingDifferent(prevProp, prop)) return true
  }

  return false
}

export {defaultSchoolIds, filterChanged, filterParams, filterParamsCacheKey, filterParamsList, hasFilterInQueryParams}
export default filterChanged
