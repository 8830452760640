import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import classNames from "classnames"
import ContactLink from "components/contacts/link"
import {ContactPhoneNumber} from "models"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsContactPhoneNumbersTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--contact-phone-numbers--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={ContactPhoneNumber}
        preload={["contact"]}
        select={{
          Contact: ["id"],
          ContactPhoneNumber: ["id"]
        }}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.contactContent,
      label: ContactPhoneNumber.humanAttributeName("contact"),
      path: ["contact"],
      sortKey: "contactName"
    },
    {
      attribute: "phoneNumber",
      sortKey: "phoneNumber"
    },
    {
      attribute: "primary",
      sortKey: "primary"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  childContent = ({contactPhoneNumber}) => <ContactLink adminLayout contact={contactPhoneNumber.child()} />
  contactContent = ({contactPhoneNumber}) => contactPhoneNumber.contact() && <ContactLink contact={contactPhoneNumber.contact()} {...linkProps(this.props)} />

  editModelPath = ({contactPhoneNumber}) => {
    if (this.p.adminLayout) return Routes.editAdminContactPhoneNumberPath(contactPhoneNumber.id())
  }
}))
