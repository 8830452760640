import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {TextKey, TextValue} from "models"
import Button from "components/inputs/button"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import TextKeyAndValue from "./text-key-and-value"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {v4 as uuidv4} from "uuid"

export default memo(shapeComponent(class ComponentsTextKeysPartial extends ShapeComponent {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    cacheKey: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onTextKeysChanged: PropTypes.func,
    resource: PropTypes.object.isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.text_keys.partial"})

    this.setInstance({t})
    this.useStates({
      cacheKey: () => this.p.resource.fullCacheKey()
    })
  }

  render() {
    const {attributeName, cacheKey, className, content, name, onTextKeysChanged, resource, ...restProps} = this.props

    return (
      <div className={classNames("components--text-keys--partial", className)} {...restProps}>
        <Button
          className="update-text-keys-button"
          icon="refresh"
          label={this.t(".update_text_keys")}
          onClick={this.tt.onUpdateTextKeysClicked}
        />
        {resource.textKeys().map((textKey) =>
          <div className="text-key-container" data-text-key-id={textKey.id()} key={textKey.key()}>
            {Locales.availableLocales().map((locale) =>
              <TextKeyAndValue
                key={locale}
                locale={locale}
                onChange={this.tt.onTextKeyAndValueChanged}
                textKey={textKey}
                textValue={this.textValueForTextKeyAndLocale(textKey, locale)}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  textValueForTextKeyAndLocale(textKey, locale) {
    let textValue = textKey.textValues().find((textValue) => textValue.locale() == locale)

    if (!textValue) {
      textValue = new TextValue({
        a: {
          id: uuidv4(),
          locale,
          text_key_id: textKey.id(),
          value: "",
          value_type: "plain"
        },
        isNewRecord: true
      })

      textKey.textValues().push(textValue)
    }

    return textValue
  }

  onTextKeyAndValueChanged = () => {
    const {onTextKeysChanged} = this.props

    this.setState({cacheKey: this.p.resource.fullCacheKey()})

    if (onTextKeysChanged) onTextKeysChanged()
  }

  onUpdateTextKeysClicked = (event) => {
    event.preventDefault()

    const {attributeName, content, resource} = this.p
    const {onTextKeysChanged} = this.props
    const regexMatches = content?.matchAll(/\{\{\s*('([^']+?)'|"([^"]+?)")\s*\|\s*tk\s*\}\}/g) || []
    const matches = [...regexMatches]
    const textKeys = []

    for (const match of matches) {
      const key = match[2] || match[3]
      let textKey = resource.textKeys().loaded().find((aTextKey) => aTextKey.key() == key)

      if (!textKey) {
        textKey = new TextKey({
          a: {
            attributeName,
            id: uuidv4(),
            key,
            resourceId: resource.id(),
            resourceType: resource.modelClass().name
          },
          isNewRecord: true
        })

        for (const locale of Locales.availableLocales()) {
          const textValue = new TextValue({
            a: {
              id: uuidv4(),
              locale,
              textKeyId: textKey.id(),
              value: "",
              valueType: "plain"
            },
            isNewRecord: true
          })

          textKey.textValues().push(textValue)
        }
      }

      textKeys.push(textKey)
    }

    resource.textKeys().set(textKeys)

    this.setState({cacheKey: resource.fullCacheKey()})

    if (onTextKeysChanged) onTextKeysChanged()

    FlashMessage.success(this.t(".text_keys_were_updated"))
  }
}))
