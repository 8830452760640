import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import AppHistory from "shared/history"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import NewsletterTemplateLink from "components/newsletter-templates/link"
import {NewsletterTemplateTimeZoneState} from "models"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SendButton from "components/newsletter-time-zone-states/send-button"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class ComponentsNewsletterTemplateTimeZoneStatesTable extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {t} = useI18n({namespace: "js.components.newsletter_time_zone_states.table"})
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        abilities={{NewsletterTemplateTimeZoneState: ["send"]}}
        actionsContent={this.tt.actionsContent}
        appHistory={AppHistory}
        className={classNames("components--newsletter-template-time-zone-states--table", className)}
        columns={this.tt.columns}
        destroyMessage={t(".the_newsletter_template_time_zone_state_was_deleted")}
        modelClass={NewsletterTemplateTimeZoneState}
        select={{
          NewsletterTemplate: ["id"],
          NewsletterTemplateTimeZoneState: ["translatedState"]
        }}
        viewModelPath={this.tt.viewPath}
        {...restProps}
      />
    )
  }

  actionsContent = ({newsletterTemplateTimeZoneState}) => <>
    {newsletterTemplateTimeZoneState?.can("send") &&
      <SendButton newsletterTemplateTimeZoneState={newsletterTemplateTimeZoneState} />
    }
  </>

  columns = () => [
    {
      attribute: "id",
      sortKey: "id"
    },
    {
      attribute: "name",
      content: this.tt.newsletterTemplateContent,
      label: NewsletterTemplateTimeZoneState.humanAttributeName("newsletterTemplate"),
      path: ["newsletterTemplate"],
      sortKey: "newsletterTemplateCurrentTranslationName"
    },
    {
      attribute: "timeZoneName",
      sortKey: "timeZoneName"
    },
    {
      attribute: "state",
      content: this.tt.stateContent,
      sortKey: "state"
    },
    {
      attribute: "scheduledAt",
      sortKey: "scheduledAt"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    },
    {
      attribute: "updatedAt",
      sortKey: "updatedAt"
    }
  ]

  newsletterTemplateContent = ({newsletterTemplateTimeZoneState}) => newsletterTemplateTimeZoneState.newsletterTemplate() &&
    <NewsletterTemplateLink newsletterTemplate={newsletterTemplateTimeZoneState.newsletterTemplate()} {...linkProps(this.props)} />

  stateContent = ({newsletterTemplateTimeZoneState}) => newsletterTemplateTimeZoneState.translatedState()
  viewPath = ({newsletterTemplateTimeZoneState}) => Routes.adminNewsletterTemplateTimeZoneStatePath(newsletterTemplateTimeZoneState.id())
}))
