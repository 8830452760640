import {CourseGroup, CourseGroupCourse} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import CourseGroupCoursesTable from "components/course-group-courses/table"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCourseGroupCourses extends ShapeComponent {
  setup() {
    const {locale, t} = useI18n({namespace: "js.routes.admin.course_groups.course_group_courses"})
    const params = useParams()
    const {courseGroup, courseGroupId} = useModel(CourseGroup, {match: {params}})

    this.setInstance({
      canCan: useCanCan(() => [[CourseGroupCourse, ["create"]]]),
      courseGroup,
      courseGroupId,
      courseGroupCoursesQuery: useMemo(
        () => CourseGroupCourse.ransack({
          course_group_id_eq: courseGroupId
        }),
        [courseGroupId, locale]
      ),
      t
    })
    useAdminLayout()?.setState({actions: this.actions(), active: "course-groups", headerTitle: CourseGroup.humanAttributeName("courses")})
  }

  render() {
    const {courseGroup} = this.tt

    return (
      <View dataSet={{route: "admin--course-groups--course-group-courses"}}>
        {courseGroup &&
          <Nav active="courses" courseGroup={courseGroup} style={{marginBottom: 15}} />
        }
        <CourseGroupCoursesTable
          adminLayout
          collection={this.t.courseGroupCoursesQuery}
        />
      </View>
    )
  }

  actions() {
    const {canCan, courseGroupId, t} = this.tt

    return (
      <>
        {canCan?.can("create", CourseGroupCourse) &&
          <Button
            className="new-course-group-course-button"
            icon="plus"
            label={t(".new_course")}
            to={Routes.newAdminCourseGroupCoursePath({course_group_course: {course_group_id: courseGroupId}})}
          />
        }
      </>
    )
  }
}))
