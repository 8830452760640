import {AuditAction, AuditAuditableType, NotificationTypeAuditTrigger} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminButton from "components/admin/button"
import AdminInput from "components/admin/input"
import {AdminSelectNotificationTypeAuditTriggersAuditAction} from "components/notification-type-audit-triggers/audit-action-select"
import {AdminSelectNotificationTypeAuditTriggersAuditAuditableType} from "components/notification-type-audit-triggers/auditable-type-select"
import AppHistory from "shared/history"
import {dig} from "diggerize"
import FlashMessage from "shared/flash-message"
import {Form} from "@kaspernj/api-maker/build/form"
import inflection from "inflection"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import Routes from "shared/routes"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useModel from "@kaspernj/api-maker/build/use-model"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminNotificationTypeAuditTriggersEdit extends ShapeComponent {
  static propTypes = {
    notificationTypeAuditTrigger: PropTypes.instanceOf(NotificationTypeAuditTrigger),
    notificationTypeAuditTriggerId: PropTypes.string
  }

  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.notification_type_audit_triggers.edit"})
    const {notificationTypeAuditTrigger, notificationTypeAuditTriggerId} = useModel(NotificationTypeAuditTrigger, {
      match: this.p.match,
      newIfNoId: {
        defaults: () => ({id: uuidv4()})
      },
      select: {
        NotificationTypeAuditTrigger: ["auditableType", "auditAction", "id", "notificationTypeId", "targetUserLiquid"]
      }
    })

    this.useStates({
      auditAction: notificationTypeAuditTrigger?.auditAction(),
      auditActionQuery: undefined,
      auditableType: notificationTypeAuditTrigger?.auditableType(),
      auditableTypeQuery: undefined,
      form: null
    })
    this.setInstance({notificationTypeAuditTrigger, notificationTypeAuditTriggerId, t})

    useMemo(() => {
      if (notificationTypeAuditTrigger) {
        this.setQueries()
        this.setState({
          auditAction: notificationTypeAuditTrigger.auditAction(),
          auditableType: notificationTypeAuditTrigger.auditableType()
        })
      }
    }, [notificationTypeAuditTrigger?.id()])

    useAdminLayout()?.setState({active: "notification-types", headerTitle: this.headerTitle()})
  }

  setQueries() {
    const {auditableType} = this.s
    const auditActionQuery = AuditAction.ransack()
    const auditableTypeQuery = AuditAuditableType.ransack()

    if (auditableType) {
      const auditRelationshipName = `${inflection.underscore(auditableType)}_audits`
      const ransackParams = {}

      ransackParams[`${auditRelationshipName}_id_not_null`] = 1

      auditActionQuery.ransack(ransackParams)
    }

    this.setState({
      auditActionQuery,
      auditableTypeQuery
    })
  }

  render() {
    const {notificationTypeAuditTrigger, onSubmit} = this.tt
    const {auditActionQuery, auditableTypeQuery} = this.s

    return (
      <View dataSet={{route: "admin/notification-type-audit-triggers/edit"}}>
        {auditActionQuery && auditableTypeQuery && notificationTypeAuditTrigger &&
          <Form onSubmit={onSubmit} setForm={this.setStates.form}>
            <View style={{marginBottom: 15}}>
              <AdminSelectNotificationTypeAuditTriggersAuditAction
                attribute="auditAction"
                model={notificationTypeAuditTrigger}
                onChange={this.tt.onAuditActionChanged}
                query={auditActionQuery}
              />
            </View>
            <View style={{marginBottom: 15}}>
              <AdminSelectNotificationTypeAuditTriggersAuditAuditableType
                attribute="auditableType"
                model={notificationTypeAuditTrigger}
                onChange={this.tt.onAuditableTypeChanged}
                query={auditableTypeQuery}
              />
            </View>
            <AdminInput attribute="targetUserLiquid" autoRows model={notificationTypeAuditTrigger} multiline style={{marginBottom: 15}} />
            <AdminButton primary save submit />
          </Form>
        }
      </View>
    )
  }

  headerTitle() {
    const {notificationTypeAuditTrigger, t} = this.tt

    if (notificationTypeAuditTrigger?.isNewRecord()) {
      return t(".add_new_notification_type_audit_trigger")
    } else if (notificationTypeAuditTrigger?.isPersisted()) {
      return t(".edit_notification_type_audit_trigger", {name: notificationTypeAuditTrigger.id()})
    }
  }

  onAuditActionChanged = ({options}) => {
    this.setState({
      auditAction: dig(options, 0, "value")
    })
    this.setQueries()
  }

  onAuditableTypeChanged = ({options}) => {
    this.setState({
      auditableType: dig(options, 0, "value")
    })
    this.setQueries()
  }

  onSubmit = async () => {
    const formData = this.s.form.asObject()
    const {notificationTypeAuditTrigger, t} = this.tt

    if (notificationTypeAuditTrigger.isNewRecord()) {
      formData.notification_type_audit_trigger.new_id = notificationTypeAuditTrigger.id()
      formData.notification_type_audit_trigger.notification_type_id = notificationTypeAuditTrigger.notificationTypeId()
    }

    try {
      await notificationTypeAuditTrigger.saveRaw(formData)
      FlashMessage.success(t(".the_notification_type_audit_trigger_was_saved"))
      AppHistory.push(Routes.auditTriggersAdminNotificationTypePath(notificationTypeAuditTrigger.notificationTypeId()))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
