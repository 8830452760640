import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import React from "react"
import SelectContainer from "./select-container"
import useInput from "@kaspernj/api-maker/build/use-input"

export default memo(shapeComponent(class ComponentsAdminSelect extends ShapeComponent {
  setup() {
    const {inputProps, restProps, wrapperOpts} = useInput({props: this.props})
    const {containerProps, ...actualRestProps} = restProps

    this.setInstance({
      inputProps,
      restProps: actualRestProps,
      wrapperOpts
    })
  }

  render() {
    const {inputProps, restProps} = this.tt
    const {ref, type, ...restInputProps} = inputProps

    return (
      <SelectContainer {...this.props}>
        <HayaSelect {...restInputProps} {...restProps} />
      </SelectContainer>
    )
  }
}))
