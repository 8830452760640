import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import Route from "@kaspernj/api-maker/build/router/route"
import setLayout from "shared/set-layout"
import Switch from "@kaspernj/api-maker/build/router/switch"
import {useFrontLayout} from "components/front-layout"
import usePath from "on-location-changed/build/use-path"
import UserLayout from "components/user-layout"
import {useWoofRouter} from "shared/woof-router"
const UserRouter = memo(shapeComponent(class UserRouter extends ShapeComponent {
  setup() {
    this.woofRouter = useWoofRouter()
  }

  render() {
    return (
      <Switch name="user">
        <Route component="admin/school-classes/roles/edit" exact path="admin/school_classes/:school_class_id/school_class_roles/new" />
        <Route component="admin/school-classes/roles/index" exact path="admin/school_classes/:school_class_id/school_class_roles" />
        <Route component="admin/school-classes/edit" exact path="admin/school_classes/:school_class_id/edit" />
        <Route component="admin/school-classes/edit" exact path="admin/school_classes/new" />
        <Route component="admin/school-classes/index" exact path="admin/school_classes" />
        <Route component="admin/subscription" exact path="admin/subscription" />
        <Route component="admin/users" exact path="admin/users" />
        <Route component="class-profile" exact path="class_profile" />
        <Route component="class-status" exact path="class_status" />
        <Route component="profile" exact path="profile" />
        <Route component="school-classes/edit" exact path="school_classes/new" />
        <Route component="school-classes" path="school_classes/:school_class_id" />
        <Route component="school-class-brain-breaks/show" exact path="school_class_brain_breaks/:school_class_brain_break_id" />
        <Route component="school-class-challenges/show" exact path="school_class_challenges/:school_class_challenge_id" />
        <Route
          component="school-class-course-modules/show"
          exact
          path="school_class_course_modules/:school_class_course_module_id/steps/:team_survey_step_id"
        />
        <Route component="courses/show" exact path="courses/:course_id" />
        <Route component="team-surveys/show" exact path="team_surveys/:team_survey_id" />
        <Route component="unicorn" exact path="unicorn" />
        <Route component="unsubscribed" exact path="unsubscribed" />
        <Route component="dashboard" exact />
        <Route componentPath="fallback" fallback onMatch={this.tt.onFallbackMatch} />
      </Switch>
    )
  }

  onFallbackMatch = () => this.woofRouter.setPageNotFound(true)
}))

const DefaultSetter = memo(shapeComponent(class DefaultSetter extends ShapeComponent {
  setup() {
    const path = usePath()
    const frontLayout = useFrontLayout()

    useMemo(() => {
      // Reset front layout so settings doesn't survive switching pages
      frontLayout?.setState({
        actions: null,
        active: null,
        background: null,
        bottomBar: null,
        className: null,
        contentCentered: false,
        darkBackground: false,
        headerTitle: null,
        headerTitleCentered: false,
        headerStyle: null,
        headTitle: null,
        requireUser: true,
        schoolClass: null,
        subHeader: null,
        topLeft: null,
        topRight: null,
        unicornTrialTopBar: true
      })
    }, [path])
  }

  render = () => this.props.children
}))

export default memo(shapeComponent(class RoutesUser extends ShapeComponent {
  setup() {
    setLayout("user")
  }

  render() {
    return (
      <UserLayout dataSet={{route: "user"}} layout="user">
        <DefaultSetter>
          <UserRouter />
        </DefaultSetter>
      </UserLayout>
    )
  }
}))
