import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminLayout from "components/admin/layout"
import Button from "components/inputs/button"
import memo from "set-state-compare/src/memo"
import React from "react"
import Routes from "shared/routes"
import setLayout from "shared/set-layout"
import {Translation} from "models"
import TranslationsTable from "components/translations/table"
import useCanCan from "@kaspernj/api-maker/build/use-can-can"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminTranslationsIndex extends ShapeComponent {
  setup() {
    setLayout("admin")

    const {t} = useI18n({namespace: "js.routes.admin.translations.index"})

    this.canCan = useCanCan(() => [[Translation, ["create"]]])
    this.t = t
  }

  render() {
    return (
      <AdminLayout
        actions={this.actions()}
        active="translations"
        className="routes--admin--translations--index"
        headerTitle={Translation.modelName().human({count: 2})}
      >
        <TranslationsTable adminLayout />
      </AdminLayout>
    )
  }

  actions() {
    const {canCan, t} = this.tt

    return (
      <>
        {canCan?.can("create", Translation) &&
          <Button className="new-translation-button" icon="plus" label={t(".add_new_translation")} to={Routes.newAdminTranslationPath()} />
        }
      </>
    )
  }
}))
