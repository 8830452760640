import React, {useCallback} from "react"
import {PlanResource} from "models"
import {PlansSelectWithContainer} from "components/plans/select"

export default {
  edit: {
    attributes: [
      {
        attribute: "planId",
        content: ({inputProps, onChangeValue}) => {
          const onChange = useCallback(({options}) => {
            onChangeValue(options[0]?.value)
          }, [onChangeValue])

          return (
            <PlansSelectWithContainer
              label={PlanResource.humanAttributeName("plan")}
              name={null}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      },
      {attribute: "resourceName"}
    ]
  }
}
