import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import {ContactRelationship} from "models"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolRolesRemoveRoleModal extends ShapeComponent {
  static propTypes = propTypesExact({
    onRequestClose: PropTypes.bool.isRequired,
    schoolRole: PropTypes.instanceOf(ContactRelationship).isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.school_roles.remove_role_modal"})

    this.t = t
  }

  render() {
    const {t} = this.tt
    const {schoolRole, ...restProps} = this.props

    return (
      <Modal {...restProps}>
        <View>
          <Text style={{marginBottom: 30, fontSize: 32, textAlign: "center"}}>
            {t("js.shared.are_you_sure")}
          </Text>
        </View>
        <View>
          <Text style={{marginBottom: 30, fontSize: 20, textAlign: "center"}}>
            {t(".confirm_you_wish_to_remove_the_attachment_to_the_school_for_the_user")}
          </Text>
        </View>
        <View>
          <Text style={{marginBottom: 30, fontSize: 20, fontWeight: "bold", textAlign: "center"}}>
            {this.p.schoolRole.child().user().name()}
          </Text>
        </View>
        <Button
          color="red"
          dataSet={{class: "yes-remove-the-person-button"}}
          fontStyle={{fontSize: 20}}
          onPress={this.tt.onRemovePressed}
          style={{marginBottom: 15}}
          title={t(".yes_remove_the_person_from_the_school")}
        />
        <Button
          color="grey"
          fontStyle={{fontSize: 20}}
          onPress={this.tt.onGoBackPressed}
          title={t(".go_back")}
        />
      </Modal>
    )
  }

  onGoBackPressed = () => this.p.onRequestClose()

  onRemovePressed = async () => {
    try {
      await this.p.schoolRole.destroy()
      FlashMessage.success(this.t(".the_user_was_removed_from_the_school"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
