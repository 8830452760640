import selectComponent from "components/select-component"
import {User} from "models"
const {withAdminSelectContainer: AdminSelectUsers, withInputContainer: UsersSelectWithContainer, withMemo: ComponentsUsersSelect} = selectComponent({
  className: "components--users--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || User

    query = query
      .ransack({s: "contact_first_name asc"})
      .select({User: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({contact_contact_emails_email_or_contact_first_name_or_contact_last_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const users = await query.toArray()

    return users.map((user) => ({
      text: user.name(),
      value: user.id()
    }))
  }
})

export default ComponentsUsersSelect
export {AdminSelectUsers, UsersSelectWithContainer}
