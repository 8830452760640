import AdminLayout from "components/admin/layout"
import AdminShowActions from "components/admin/show-actions"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import inflection from "inflection"
import Locales from "shared/locales"
import Nav from "components/admin/school-class-groups/nav"
import PageComponent from "components/page-component"
import PropTypes from "prop-types"
import React from "react"
import {SchoolClassGroup} from "models"
import withModel from "components/wooftech-with-model"

class RoutesAdminSchoolClassGroupsShow extends PageComponent {
  static propTypes = {
    schoolClassGroup: PropTypes.instanceOf(SchoolClassGroup),
    schoolClassGroupId: PropTypes.string.isRequired
  }

  render() {
    const {schoolClassGroup} = this.p

    return (
      <AdminLayout
        actions={this.actions()}
        active="school-class-groups"
        className="routes--admin--school-class-groups--show"
        headerTitle={schoolClassGroup?.name()}
      >
        {schoolClassGroup &&
          <>
            <Nav active="general" className="mb-4" schoolClassGroup={schoolClassGroup} />
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={schoolClassGroup} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${SchoolClassGroup.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={schoolClassGroup}
                />
              </React.Fragment>
            )}
          </>
        }
      </AdminLayout>
    )
  }

  actions() {
    const {schoolClassGroup} = this.p

    return (
      <AdminShowActions model={schoolClassGroup} />
    )
  }
}

const selectedSchoolClassGroupAttributes = ["createdAt", "id", "name", "updatedAt"]

for (const locale of Locales.availableLocales()) {
  selectedSchoolClassGroupAttributes.push(
    `name${locale.substring(0, 1).toUpperCase()}${locale.substring(1, 99)}`
  )
}

export default withModel(
  RoutesAdminSchoolClassGroupsShow,
  SchoolClassGroup,
  {
    abilities: {
      SchoolClassGroup: ["destroy", "edit"]
    },
    select: {
      SchoolClassGroup: selectedSchoolClassGroupAttributes,
      Survey: ["id", "name"]
    }
  }
)
