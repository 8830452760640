import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {ContactRelationship} from "models"
import {initialsForUser} from "shared/initials"
import memo from "set-state-compare/src/memo"
import OptionsButton from "./options-button"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsSchoolRolesUserCircle extends ShapeComponent {
  static propTypes = propTypesExact({
    backgroundColor: PropTypes.string,
    schoolRole: PropTypes.instanceOf(ContactRelationship).isRequired,
    style: PropTypes.object
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.school_roles.user_circle"})

    this.t = t
  }

  render() {
    const {t} = this.tt
    const {backgroundColor, schoolRole, style} = this.props
    const actualStyle = Object.assign(
      {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 252,
        height: 252,
        backgroundColor,
        borderRadius: "50%"
      },
      style
    )

    const role = schoolRole.contactRelationshipType().identifier()

    return (
      <View dataSet={{class: "role-circle", roleId: schoolRole.id()}} style={actualStyle}>
        <OptionsButton schoolRole={schoolRole} style={{position: "absolute", right: 25, bottom: 72}} />
        <Text style={{color: "#fff", fontSize: 70}}>
          {initialsForUser(schoolRole.child().user())}
        </Text>
        <Text style={{marginTop: 30, color: "#fff", fontSize: 16}}>
          {schoolRole.child().user().name()}
        </Text>
        <Text dataSet={{class: "school-role-translated-role", schoolRoleId: schoolRole.id()}} style={{marginTop: 6, fontSize: 18}}>
          {role == "admin" && t(".teacher")}
          {role != "admin" && schoolRole.contactRelationshipType().translatedIdentifier()}
        </Text>
      </View>
    )
  }
}))
