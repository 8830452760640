import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {EmailTemplate} from "models"
import memo from "set-state-compare/src/memo"
import Nav from "components/nav"
import NavItem from "components/nav/item"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"

export default memo(shapeComponent(class RoutesAdminEmailTemplatesNav extends ShapeComponent {
  static propTypes = {
    active: PropTypes.string,
    className: PropTypes.string,
    emailTemplate: PropTypes.instanceOf(EmailTemplate).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.admin.email_templates.nav"})

    this.t = t
  }

  render() {
    const {active, className, emailTemplate, ...restProps} = this.props

    return (
      <Nav className={classNames("routes--admin--email-templates--nav", className)} {...restProps}>
        <NavItem active={active} identifier="general" label={this.t("js.shared.general")} to={Routes.adminEmailTemplatePath(emailTemplate.id())} />
        <NavItem
          active={active}
          identifier="previews"
          label={EmailTemplate.humanAttributeName("previews")}
          to={Routes.previewsAdminEmailTemplatePath(emailTemplate.id())}
        />
      </Nav>
    )
  }
}))
