import ApiMakerTable from "@kaspernj/api-maker/build/table/table"
import memo from "set-state-compare/src/memo"
import React from "react"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"

const SharedApiMakerTable = (props) => {
  const currentUser = useCurrentUser()

  return (
    <>
      {currentUser &&
        <ApiMakerTable
          currentUser={currentUser}
          defaultDateFormatName="date.formats.short"
          defaultDateTimeFormatName="time.formats.short"
          {...props}
        />
      }
      {!currentUser &&
        <div>
          Need user to view this table
        </div>
      }
    </>
  )
}

export default memo(SharedApiMakerTable)
