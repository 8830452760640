import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AdminShowActions from "components/admin/show-actions"
import AppHistory from "shared/history"
import AttributeRow from "@kaspernj/api-maker/build/bootstrap/attribute-row"
import AttributeRows from "@kaspernj/api-maker/build/bootstrap/attribute-rows"
import CloneButton from "components/email-templates/clone-button"
import {EmailTemplate} from "models"
import inflection from "inflection"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import Nav from "./nav"
import React from "react"
import Routes from "shared/routes"
import translatedAttributes from "@kaspernj/api-maker/build/translated-attributes.js"
import {useAdminLayout} from "components/admin/layout"
import useModel from "@kaspernj/api-maker/build/use-model"
import {useParams} from "@kaspernj/api-maker/build/router/route"
import {View} from "react-native"

const selectedEmailTemplateAttributes = ["createdAt", "id", "name", "updatedAt"]
  .concat(translatedAttributes(["name"], Locales.availableLocales()))

export default memo(shapeComponent(class RoutesAdminEmailTemplatesShow extends ShapeComponent {
  setup() {
    const params = useParams()
    const {emailTemplate} = useModel(EmailTemplate, {
      abilities: {
        EmailTemplate: ["clone", "destroy", "edit"]
      },
      match: {params},
      select: {
        EmailTemplate: selectedEmailTemplateAttributes
      }
    })

    this.setInstance({emailTemplate})
    useAdminLayout()?.setState({actions: this.actions(), active: "email-templates", headerTitle: emailTemplate?.name()})
  }

  render() {
    const {emailTemplate} = this.tt

    return (
      <View dataSet={{route: "admin--email-templates--show"}}>
        {emailTemplate &&
          <>
            <Nav active="general" className="mb-4" emailTemplate={emailTemplate} />
            <AttributeRows attributes={["id", "createdAt", "updatedAt"]} model={emailTemplate} />
            {Locales.availableLocales().map((locale) =>
              <React.Fragment key={locale}>
                <AttributeRow
                  attribute={`name${inflection.camelize(locale)}`}
                  label={`${EmailTemplate.humanAttributeName("name")} (${Locales.labelForLocale(locale)})`}
                  model={emailTemplate}
                />
              </React.Fragment>
            )}
          </>
        }
      </View>
    )
  }

  actions() {
    const {emailTemplate} = this.tt

    return (
      <>
        {emailTemplate?.can("clone") &&
          <CloneButton emailTemplate={emailTemplate} onCloned={this.tt.onEmailTemplateCloned} />
        }
        <AdminShowActions model={emailTemplate} />
      </>
    )
  }

  onEmailTemplateCloned = ({clonedEmailTemplate}) => AppHistory.push(Routes.adminEmailTemplatePath(clonedEmailTemplate.id()))
}))
