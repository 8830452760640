import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import Link from "@kaspernj/api-maker/build/link"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
export default memo(shapeComponent(class ComponentsAuditsLink extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    audit: PropTypes.object.isRequired,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {adminLayout, children, className, audit, userLayout, ...restProps} = this.props
    const generatedClassName = classNames("components--audits--link", className)

    if (adminLayout) {
      return (
        <Link className={generatedClassName} to={this.to()} {...restProps}>
          {children || audit.id()}
        </Link>
      )
    }

    return (
      <span className={generatedClassName} {...restProps}>
        {children || audit.id()}
      </span>
    )
  }

  to() {
    const {adminLayout, audit} = this.p

    if (adminLayout) {
      return Routes.adminAuditPath(audit.id(), {audit_class: audit.modelClassData().name})
    }

    return "#"
  }
}))
