import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import ApiMakerTable from "shared/api-maker-table"
import {BrainBreak} from "models"
import BrainBreakLink from "components/brain-breaks/link"
import classNames from "classnames"
import linkProps from "shared/link-props"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import SurveyLink from "components/surveys/link"
export default memo(shapeComponent(class ComponentsBrainBreaks extends ShapeComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.tt
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--brain-breaks--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={BrainBreak}
        preloads={["survey"]}
        select={{
          BrainBreak: ["id", "name", "public"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.tt.brainBreakContent,
      label: BrainBreak.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      content: this.tt.surveyContent,
      label: BrainBreak.humanAttributeName("survey"),
      sortKey: "surveyCurrentTranslationName"
    },
    {
      attribute: "public",
      sortKey: "public"
    }
  ]

  editModelPath = ({brainBreak}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminBrainBreakPath(brainBreak.id())
  }

  viewModelPath = ({brainBreak}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminBrainBreakPath(brainBreak.id())
  }

  brainBreakContent = ({brainBreak}) => <BrainBreakLink brainBreak={brainBreak} {...linkProps(this.props)} />
  surveyContent = ({brainBreak}) => brainBreak.survey() && <SurveyLink survey={brainBreak.survey()} {...linkProps(this.props)} />
}))
