import ApiMakerTable from "shared/api-maker-table"
import BaseComponent from "components/base-component"
import classNames from "classnames"
import linkProps from "shared/link-props"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import {ScoreFactorGroup} from "models"
import ScoreFactorGroupLink from "components/score-factor-groups/link"
import SurveyLink from "components/surveys/link"
export default class ComponentsScoreFactorGroups extends BaseComponent {
  static defaultProps = {
    adminLayout: false,
    userLayout: false
  }

  static propTypes = {
    adminLayout: PropTypes.bool.isRequired,
    className: PropTypes.string,
    userLayout: PropTypes.bool.isRequired
  }

  render() {
    const {columns, editModelPath, viewModelPath} = this.t
    const {adminLayout, className, userLayout, ...restProps} = this.props

    return (
      <ApiMakerTable
        className={classNames("components--score-factor-groups--table", className)}
        columns={columns}
        editModelPath={editModelPath}
        modelClass={ScoreFactorGroup}
        select={{
          ScoreFactorGroup: ["id", "identifier", "name"],
          Survey: ["id", "name"]
        }}
        viewModelPath={viewModelPath}
        {...restProps}
      />
    )
  }

  columns = () => [
    {
      attribute: "id",
      defaultVisible: false,
      sortKey: "id"
    },
    {
      content: this.t.scoreFactorGroupContent,
      label: ScoreFactorGroup.humanAttributeName("name"),
      sortKey: "currentTranslationName"
    },
    {
      attribute: "identifier",
      sortKey: "identifier"
    }
  ]

  editModelPath = ({scoreFactorGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.editAdminScoreFactorGroupPath(scoreFactorGroup.id())
  }

  viewModelPath = ({scoreFactorGroup}) => {
    const {adminLayout} = this.p

    if (adminLayout) return Routes.adminScoreFactorGroupPath(scoreFactorGroup.id())
  }

  scoreFactorGroupContent = ({scoreFactorGroup}) => <ScoreFactorGroupLink scoreFactorGroup={scoreFactorGroup} {...linkProps(this.props)} />
  surveyContent = ({scoreFactorGroup}) => scoreFactorGroup.survey() && <SurveyLink survey={scoreFactorGroup.survey()} {...linkProps(this.props)} />
}
