import {SchoolClass} from "models"
import selectComponent from "components/select-component"
const {
  withAdminSelectContainer: AdminSelectSchoolClasses,
  withInputContainer: SchoolClassesSelectWithContainer,
  withMemo: ComponentsSchoolClassesSelect
} = selectComponent({
  className: "components--school-classes--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || SchoolClass

    query = query
      .ransack({s: "current_translation_name asc"})
      .select({SchoolClass: ["id", "interpretedName"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})

    if (values && values.length == 0) {
      query.ransack({id_eq: "no-values-given"})
    } else {
      query.ransack({id_eq_any: values})
    }

    const schoolClasses = await query.toArray()

    return schoolClasses.map((schoolClass) => ({
      schoolClass,
      text: schoolClass.interpretedName(),
      value: schoolClass.id()
    }))
  }
})

export default ComponentsSchoolClassesSelect
export {AdminSelectSchoolClasses, SchoolClassesSelectWithContainer}
