import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import {useAdminLayout} from "components/admin/layout"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {User} from "models"
import UsersTable from "components/users/table"
import {View} from "react-native"

export default memo(shapeComponent(class RoutesAdminCrmIndex extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.routes.admin.crm.index"})

    this.usersCollection = useMemo(
      () => User.ransack({
        anonymous_false: 1,
        without_roles: JSON.stringify({roles: ["admin", "hacker", "student"]})
      }),
      []
    )

    useAdminLayout().setState({active: "crm", headerTitle: t(".crm")})
  }

  render() {
    return (
      <View dataSet={{route: "admin/crm/index"}}>
        <UsersTable adminLayout collection={this.tt.usersCollection} />
      </View>
    )
  }
}))
