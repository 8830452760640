import {ScoreFactorGroup} from "models"
import selectComponent from "components/select-component"
const {withInputContainer: ScoreFactorGroupsSelectWithContainer, withMemo: ComponentsScoreFactorGroupsSelect} = selectComponent({
  className: "components--score-factor-groups--select",
  optionsCallback: async ({query, searchValue, values}) => {
    query = query?.clone() || ScoreFactorGroup

    query = query
      .ransack({s: "position asc"})
      .select({ScoreFactorGroup: ["id", "name"]})
      .limit(20)

    if (searchValue) query.ransack({current_translation_name_cont_any_word: searchValue})
    if (values) query.ransack({id_eq_any: values})

    const scoreFactorGroups = await query.toArray()

    return scoreFactorGroups.map((scoreFactorGroup) => ({
      text: scoreFactorGroup.name(),
      value: scoreFactorGroup.id()
    }))
  }
})

export default ComponentsScoreFactorGroupsSelect
export {ScoreFactorGroupsSelectWithContainer}
